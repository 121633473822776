import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Dropdown,
  NavItem,
  NavLink,
  Card,
  Alert,
  Badge,
  Spinner,
  ButtonGroup,
  Modal,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";
import {
  BiCheckCircle,
  BiCloudDownload,
  BiDotsVerticalRounded,
  BiHistory,
  BiHourglass,
  BiInfoCircle,
  BiLock,
  BiTrash,
} from "react-icons/bi";
import { useUser } from "reactfire";
import { useEffect, useState } from "react";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const testFileList = [
  {
    fileName: "test1",
    dateCreated: "2021-01-01",
    fileLocation: "test1",
    fileType: "text/csv",
  },
  {
    fileName: "test2",
    dateCreated: "2021-01-01",
    fileLocation: "test2",
    fileType: "text/csv",
  },
];

const CollateralReportsComponent = () => {
  const [fileList, setFileList] = useState([]);
  const [dateBoundaries, setDateBoundaries] = useState({
    min: null,
    max: null,
  });
  const [fileListFiltered, setFileListFiltered] = useState([]);
  const [fileCategories, setFileCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingDownloadLink, setIsGeneratingDownloadLink] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoadingSelectedFileHistory, setIsLoadingSelectedFileHistory] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedFileDownloadHistory, setSelectedFileDownloadHistory] =
    useState([]);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const user = useUser();

  const handleGenerateDownloadLink = async (file) => {
    // Set loading state to true
    setIsGeneratingDownloadLink((prevState) => ({
      ...prevState,
      [file.id]: true,
    }));

    // Add new document to firestore in "secure-download-request" collection
    const docRef = firestore.collection("secure-download-request");
    const docData = {
      fileData: file,
      createdAt: dayjs().unix(),
      expiresAt: dayjs().add(5, "minute").unix(),
      createdByUID: user.data.uid,
      createdByEmail: user.data.email,
      createdByName: user.data.displayName,
      bucketName:
        "vehicle-imaging.appspot.com/private/finance/cba_collateral_reports",
    };
    await docRef
      .add(docData)
      .then(() => {
        toast.warning(
          "Secure download link hsa been generated and sent to your email address"
        );
      })
      .catch((error) => {
        console.error("Error: ", error);
        toast.error("Error generating secure download link");
      });

    setIsGeneratingDownloadLink((prevState) => ({
      ...prevState,
      [file.id]: false,
    }));
  };

  //   Get file list from firestore for this month
  useEffect(() => {
    const getFiles = async () => {
      setIsLoading(true);
      const startOfMonthUnix = dayjs()
        .subtract(1, "month")
        .startOf("month")
        .unix();
      const endOfMonthUnix = dayjs().endOf("month").unix();

      setDateBoundaries({
        min: dayjs().subtract(1, "month").startOf("month").format("DD-MM-YYYY"),
        max: dayjs().endOf("month").format("DD-MM-YYYY"),
      });

      const files = firestore
        .collection("finance-cba-collateral-reports")
        .where("runAsDateUnix", ">=", startOfMonthUnix)
        .where("runAsDateUnix", "<=", endOfMonthUnix)
        .onSnapshot((snapshot) => {
          const tmpFileList = [];
          snapshot.forEach((doc) => {
            const d = doc.data();
            const id = doc.id;
            tmpFileList.push({ ...d, id });
          });
          setIsLoading(false);
          //   Set file ids to loading state object
          let tmpLoadingState = {};
          tmpFileList.forEach((file) => {
            tmpLoadingState[file.id] = false;
          });
          tmpFileList.sort((a, b) => b.createdAt - a.createdAt);
          setIsGeneratingDownloadLink(tmpLoadingState);
          setFileList(tmpFileList);
        });
      // unsubscribe from snapshot listener
      return () => files();
    };
    getFiles();
  }, []);

  //  Get download history for selected file
  useEffect(() => {
    const getDownloadHistory = async () => {
      setIsLoadingSelectedFileHistory(true);
      const downloadHistory = firestore
        .collection("secure-download-request")
        .where("fileData.id", "==", selectedFile?.id)
        .onSnapshot((snapshot) => {
          const tmpDownloadHistory = [];
          snapshot.forEach((doc) => {
            const d = doc.data();
            const id = doc.id;
            tmpDownloadHistory.push({ ...d, id });
          });
          // Sort by created at date desc
          tmpDownloadHistory.sort((a, b) => b.createdAt - a.createdAt);
          setSelectedFileDownloadHistory(tmpDownloadHistory);
          setIsLoadingSelectedFileHistory(false);
        });
      // unsubscribe from snapshot listener
      return () => downloadHistory();
    };
    if (selectedFile) {
      getDownloadHistory();
    }
  }, [selectedFile]);

  // Get file categories from fileList map
  useEffect(() => {
    const getFileCategories = () => {
      const tmpFileCategories = [];
      fileList.forEach((file) => {
        if (!tmpFileCategories.includes(file.categoryName)) {
          tmpFileCategories.push(file.categoryName);
        }
      });
      setFileCategories(tmpFileCategories);
    };
    getFileCategories();
  }, [fileList]);

  // Filter file list by category
  useEffect(() => {
    const filterFileList = () => {
      const tmpFileList = [];
      fileList.forEach((file) => {
        if (file.categoryName === selectedCategory) {
          tmpFileList.push(file);
        }
      });
      setFileListFiltered(tmpFileList);
    };
    if (selectedCategory !== null && selectedCategory !== undefined) {
      filterFileList();
    } else {
      setFileListFiltered(fileList);
    }
  }, [fileList, selectedCategory]);

  return (
    <Container fluid>
      <ToastContainer />
      <Row>
        <Col>
          <h5>Collateral Reports - File list</h5>
          <h6>
            Files with 'run as' dates between {dateBoundaries.min} and{" "}
            {dateBoundaries.max}
          </h6>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col sm={4}>
          <Alert variant="info" dismissible>
            <Row style={{ fontSize: 14 }} className="text-left">
              <Col sm={1} md={1}>
                <BiInfoCircle size={30} />
              </Col>
              <Col>
                Click on the cloud download icon to generate a secure link.
                <br />
                This will generate a short lived security token and send a
                downloadable link to your email address.
              </Col>
            </Row>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Dropdown
            className="mb-3"
            onSelect={(eventKey) => setSelectedCategory(eventKey)}
          >
            <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
              Filter by category
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey={null}>Clear filter</Dropdown.Item>
              {fileCategories.map((category, idx) => {
                return (
                  <Dropdown.Item key={idx} eventKey={category}>
                    {category}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Table striped style={{ fontSize: 12 }} className="m-2">
          <thead>
            <tr>
              <th>File type</th>
              <th>File id</th>
              <th>File location</th>
              <th>Run as</th>
              <th>Generated</th>
              {/* <th>Created by</th> */}
              <th>Download</th>
              <th>History</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={7} className="text-center">
                  <Spinner animation="border" />
                </td>
              </tr>
            ) : null}
            {fileListFiltered.map((file, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <Badge style={{ fontSize: 12 }} variant="success">
                      {file.categoryName}
                    </Badge>
                  </td>
                  <td>{file.id}</td>
                  <td>{"Cloud server"}</td>
                  <td>{file.runAsDate}</td>
                  <td>
                    {dayjs.unix(file.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                  </td>
                  {/* <td>{file.createdByName}</td> */}
                  <td>
                    <Button
                      variant="outline-dark"
                      size="sm"
                      onClick={() => handleGenerateDownloadLink(file)}
                      disabled={isGeneratingDownloadLink?.[file.id]}
                    >
                      {isGeneratingDownloadLink?.[file.id] ? (
                        <Spinner
                          animation="border"
                          size="sm"
                          variant="primary"
                        />
                      ) : (
                        <BiCloudDownload />
                      )}
                    </Button>
                  </td>
                  <td>
                    <Button
                      size="sm"
                      onClick={() => setSelectedFile(file)}
                      variant="outline-primary"
                    >
                      <BiHistory />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {/* {fileListFiltered.map((file, idx) => {
          return (
            <Col key={idx} md={3} sm={6} lg={3}>
              <Card
                border="dark"
                // key={idx}
                className="mt-3"
                style={{ fontSize: 14 }}
              >
                <Card.Body>
                  <Row>
                    <Col>
                      <Badge
                        className="mb-2"
                        style={{ fontSize: 18 }}
                        variant="success"
                      >
                        {file.categoryName}
                      </Badge>
                      <br />
                      File id: {file.id}
                    </Col>
                    <Col>
                      <Card.Text></Card.Text>
                    </Col>
                    <Col>
                      <Button
                        style={{ float: "right" }}
                        className="mt-2"
                        variant="outline-dark"
                        onClick={() => handleGenerateDownloadLink(file)}
                        disabled={isGeneratingDownloadLink?.[file.id]}
                      >
                        {isGeneratingDownloadLink?.[file.id] ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            variant="primary"
                          />
                        ) : (
                          <BiCloudDownload />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col className="text-left">
                      <i>
                        Run as: {file.runAsDate}
                        <br />
                        Generated:{" "}
                        {dayjs.unix(file.createdAt).format("YYYY-MM-DD")}
                        <br />
                        {dayjs.unix(file.createdAt).format("HH:mm:ss")}
                      </i>
                    </Col>

                    <Col>
                      <ButtonGroup style={{ float: "right" }}>
                        <Button variant="outline-danger" disabled={true}>
                          <BiTrash size={18} />
                        </Button>
                        <Button
                          onClick={() => setSelectedFile(file)}
                          variant="outline-primary"
                        >
                          <BiHistory />
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          );
        })} */}
      </Row>
      {/* Download history moday */}
      <Modal
        show={selectedFile ? true : false}
        onHide={() => setSelectedFile(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Download history{" "}
            {isLoadingSelectedFileHistory ? (
              <Spinner animation="border" />
            ) : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="mb-2">
              <span style={{ fontSize: 12 }}>File id: {selectedFile?.id}</span>
              {/* <Alert variant="info" dismissible>
                <p style={{ fontSize: 14 }} className="text-left">
                  <BiInfoCircle size={18} />
                  <br />
                  This is a list of all the download links that have been
                  generated for this file.
                  <br />
                  Click on the lock icon to revoke a link.
                </p>
              </Alert> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <Tbl data={selectedFileDownloadHistory} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default CollateralReportsComponent;

const Tbl = ({ data }) => {
  return (
    <Table striped bordered hover size="sm" style={{ fontSize: 12 }}>
      <thead>
        <tr>
          <th>Requested By</th>
          <th>Requested Date </th>
          <th>Request Status</th>
        </tr>
      </thead>

      <tbody>
        {data.length === 0 ? (
          <tr>
            <td colSpan={3} className="text-center">
              No download history
            </td>
          </tr>
        ) : null}
        {data.map((row, idx) => {
          return (
            <tr key={idx}>
              <td>{row.createdByName}</td>
              <td>{dayjs.unix(row.createdAt).format("DD-MM-YYYY HH:mm:ss")}</td>
              <td>
                {row.status === "COMPLETED" ? (
                  <BiCheckCircle style={{ color: "green" }} size={15} />
                ) : (
                  <BiHourglass style={{ color: "orange" }} size={15} />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
