import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import NumberFormat from "react-number-format";
import { useEffect, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useUser } from "reactfire";
import { ToastContainer, toast } from "react-toastify";
import Table from "react-bootstrap/Table";
import dayjs from "dayjs";
import Badge from "react-bootstrap/Badge";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const MyBookingCard = ({ data, nextStage }) => {
  const [mailToString, setMailToString] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const user = useUser();

  const getHistory = async () => {
    firestore
      .collection("freight-bookings")
      .doc(data?.id)
      .collection("audit-log");
  };

  const setStage = async () => {
    // console.log(`user: ${user.data.uid}`);
    // console.log(`Change status to ${nextStage}`);
    setIsUpdating(true);
    await firestore
      .collection("freight-bookings")
      .doc(data?.id)
      .set(
        {
          status: nextStage,
          lastUpdatedDate: dayjs().unix(),
          lastUpdatedBy: user.data.uid,
        },
        { merge: true }
      )
      .then((r) => {
        toast.success(`Updated status to ${nextStage}`);
      });

    setIsUpdating(false);
  };

  useEffect(() => {
    var tmpStr = `Hi%0D%0A%0D%0APlease book the following for pickup and delivery ASAP.%0D%0A%0D%0A`;
    tmpStr = `${tmpStr}%0D%0APickup%0D%0A${data.pickup?.location?.address}%0D%0A${data.pickup?.name} ${data.pickup?.mobile}`;
    tmpStr = `${tmpStr}%0D%0A%0D%0ADelivery%0D%0A${data.dropOff?.location?.address}%0D%0A${data.dropOff?.name} ${data.dropOff?.mobile}`;

    data.vehicleItem.forEach((v, idx) => {
      tmpStr = `${tmpStr}%0D%0A%0D%0AVehicle ${idx + 1}%0D%0A${v.make} ${
        v.model
      } ${v.VIN}`;
    });

    tmpStr = `${tmpStr}%0D%0A%0D%0AHow much will this cost and when do you intend to collect/deliver?%0D%0A%0D%0AThank you.`;

    setMailToString(`ENTEREMAIL?subject=NEW BOOKING CHARGED TO&body=${tmpStr}`);
  }, [JSON.stringify(data)]);

  return (
    <>
      <Container fluid style={{ paddingTop: 15 }}>
        <ToastContainer />
        <Col>
          <Row className="justify-content-md-center">
            <Card
              border={data?.TrueProfit <= 0 ? "danger" : "info"}
              bg={data?.TrueProfit <= 0 ? "light" : "light"}
              style={{ width: "100%" }}
            >
              <Card.Img variant="top" src={data?.image} />
              <Card.Header>
                New booking by {data.displayName}
                &nbsp;
                <Badge
                  pill
                  bg="danger"
                  style={{ backgroundColor: "#6371de", color: "#FFF" }}
                >
                  {data.vehicleItem.length} car
                  {data.vehicleItem.length > 1 ? "s" : ""} in booking
                </Badge>
              </Card.Header>
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    {/* <tr>
                      <th>Property</th>
                      <th>Value</th>
                    </tr> */}
                  </thead>
                  <tbody>
                    <tr>
                      <td>Booking ID</td>
                      <td>{data.id}</td>
                    </tr>
                    <tr>
                      <td>Booking Created</td>
                      <td>
                        {dayjs
                          .unix(data.createdAt?.seconds)
                          .format("DD-MM-YYYY HH:MM")}
                      </td>
                    </tr>
                    <tr>
                      <td>Current Staus</td>
                      <td>{data.status}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <b>PICKUP</b>
                      </td>
                    </tr>

                    <tr>
                      <td>Address</td>
                      <td>
                        <a
                          target="_blank"
                          href={`https://www.google.com/maps/place/${data.pickup?.location.address}/@${data.pickup?.location?.coordinates?.lat},${data.pickup?.location?.coordinates?.lng},15z/`}
                        >
                          {data.pickup?.location.address}
                        </a>
                      </td>
                    </tr>
                    {data.pickup?.clientObj?.label?.length > 0 ? (
                      <tr>
                        <td>Client</td>
                        <td>{data.pickup?.clientObj?.label}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>Contact</td>
                      <td>
                        {data.pickup?.name} -&nbsp;
                        <a href={`tel:${data.pickup?.mobile}`}>
                          {data.pickup?.mobile}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Comments</td>
                      <td>{data.pickup?.comments}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <b>DROP OFF</b>
                      </td>
                    </tr>

                    <tr>
                      <td>Address</td>
                      <td>
                        <a
                          target="_blank"
                          href={`https://www.google.com/maps/place/${data.dropOff?.location.address}/@${data.dropOff?.location?.coordinates?.lat},${data.dropOff?.location?.coordinates?.lng},15z/`}
                        >
                          {data.dropOff?.location.address}
                        </a>
                      </td>
                    </tr>
                    {data.dropOff?.clientObj?.label?.length > 0 ? (
                      <tr>
                        <td>Dealership</td>
                        <td>{data.dropOff?.clientObj?.label}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>Contact</td>
                      <td>
                        {data.dropOff?.name} -&nbsp;
                        <a href={`tel:${data.dropOff?.mobile}`}>
                          {data.dropOff?.mobile}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Comments</td>
                      <td>{data.dropOff?.comments}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <b>VEHICLES</b>
                      </td>
                    </tr>
                    {data.vehicleItem?.map((veh, idx) => {
                      return (
                        <>
                          <tr>
                            <td>Eclipse Stock Number</td>
                            <td>{veh.EclipseStockNumber}</td>
                          </tr>
                          <tr>
                            <td>VIN</td>
                            <td>{veh.VIN}</td>
                          </tr>
                          <tr>
                            <td>Make</td>
                            <td>{veh.make}</td>
                          </tr>
                          <tr>
                            <td>Model</td>
                            <td>{veh.model}</td>
                          </tr>
                          <tr>
                            <td>Comments</td>
                            <td>{veh.comments}</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <Button
                                size="sm"
                                block
                                style={{ borderRadius: 0 }}
                                variant="outline-primary"
                                onClick={() => {
                                  window.open(`/vin/${veh?.VIN}`, "_blank");
                                }}
                              >
                                Show current stock card
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    <tr>
                      <td>Charge To</td>
                      <td>{data.chargeTo?.name}</td>
                    </tr>

                    {/* <tr>
                      <td>Branch / Dealership</td>
                      <td>{veh.branch_desc}</td>
                    </tr>
                    <tr>
                      <td>Current location / yard</td>
                      <td>{veh.VEHYard}</td>
                    </tr>
                    <tr>
                      <td>Buyer</td>
                      <td>{veh.Buyer}</td>
                    </tr>
                    <tr>
                      <td>VIN</td>
                      <td>{data.VIN}</td>
                    </tr>
                    <tr>
                      <td>Age in stock</td>
                      <td>{veh.Age < 0 ? "**" : veh.Age} day(s)</td>
                    </tr> */}
                    {data.carrierData ? (
                      <>
                        <tr>
                          <td colSpan={2}>
                            <b>CARRIER</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Carrier</td>
                          <td>{data.carrierData?.carrier?.carrier}</td>
                        </tr>
                        <tr>
                          <td>Estimated Cost</td>
                          <td>
                            <NumberFormat
                              displayType="text"
                              thousandSeparator={true}
                              prefix={`$ `}
                              value={data.carrierData?.estimatedCost}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Estimated Collection Date</td>
                          <td>{data.carrierData?.estimatedCollectionDate}</td>
                        </tr>
                        <tr>
                          <td>Estimated Delivery Date</td>
                          <td>{data.carrierData?.estimatedDeliveryDate}</td>
                        </tr>
                        <tr>
                          <td>Carrier Booking Reference</td>
                          <td>{data.carrierData?.carrierBookingReference}</td>
                        </tr>
                        <tr>
                          <td>Carrier comments</td>
                          <td>{data.carrierData?.comments}</td>
                        </tr>
                      </>
                    ) : null}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <ButtonGroup>
                  <Button
                    onClick={() => {
                      toast.info("Coming soon");
                    }}
                    variant="info"
                  >
                    Show history
                  </Button>

                  {/* <Button onClick={setStage} variant="warning">
                    Cancel
                  </Button> */}
                </ButtonGroup>
              </Card.Footer>
            </Card>
          </Row>
        </Col>
      </Container>
    </>
  );
};

export default MyBookingCard;
