import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";

import {
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  StorageProvider,
  FunctionsProvider,
  AppCheckProvider,
  SuspenseWithPerf,
  useFirebaseApp,
  useSigninCheck,
} from "reactfire";
import RouterComponent from "./components/RouterComponent";
import LoginFormComponent from "./components/LoginFormComponent";
import * as Sentry from "@sentry/react";

// @COMPATIBILITY
import firebase from "firebase/compat/app";

const firebaseConfig = require("./config/firebaseConfig.json");

// @COMPATIBILITY
firebase.initializeApp(firebaseConfig);

let APP_CHECK_TOKEN = "";
if (process.env.NODE_ENV === "production") {
  const appCheckConfig = require("./config/appCheckConfigProd.json");
  APP_CHECK_TOKEN = appCheckConfig.prodToken;
} else {
  const appCheckConfig = require("./config/appCheckConfigDev.json");
  APP_CHECK_TOKEN = appCheckConfig.devToken;
}

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://091061c7fe6940acbd2eb5f2fd861ac6@o1334655.ingest.sentry.io/4505084631056384",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const PrimaryApp = () => {
  const app = useFirebaseApp();
  const functions = getFunctions(app);
  const storage = getStorage(app);
  const firestore = getFirestore(app);
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: true,
  });

  const { status, data: signInCheckResult } = useSigninCheck();

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (signInCheckResult?.signedIn === true) {
    return (
      <AppCheckProvider sdk={appCheck}>
        <FunctionsProvider sdk={functions}>
          <FirestoreProvider sdk={firestore}>
            <StorageProvider sdk={storage}>
              <RouterComponent />
            </StorageProvider>
          </FirestoreProvider>
        </FunctionsProvider>
      </AppCheckProvider>
    );
  } else {
    return (
      <AppCheckProvider sdk={appCheck}>
        <LoginFormComponent />
      </AppCheckProvider>
    );
  }
};

const MainApp = () => {
  const app = useFirebaseApp();
  const auth = getAuth(app);

  return (
    <AuthProvider sdk={auth}>
      <PrimaryApp />
    </AuthProvider>
  );
};

function App() {
  return (
    <div className="App">
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <SuspenseWithPerf
          fallback={`Loading...`}
          traceId={"loading-backdrop-status"}
        >
          <MainApp />
        </SuspenseWithPerf>
      </FirebaseAppProvider>
    </div>
  );
}

export default App;
