import { ToastContainer, toast } from "react-toastify";
import BackdropComponent from "../BackdropComponent";
import {
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  Container,
  Spinner,
  Table,
  Badge,
  ButtonGroup,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { useFunctions } from "reactfire";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import * as cheerio from "cheerio";

const stickyTableHeaderStyle = {
  position: "sticky",
  margin: "0 0 0 0",
  top: -1,
  backgroundColor: "#566573",
  color: "white",
  fontSize: 12,
};

const initialLeadValues = {
  firstName: "",
  lastName: "",
  location: {},
  email1: "",
  phone_mobile: "",
  Address: "",
  sugarLeadId: "",
  vehicleInfo: {},
};

const carsalesImageSuffix =
  "?pxc_size=500,380&pxc_method=fitfill&pxc_bgcolor=000000";
const SUGARCRM_LEAD_PREFIX = "https://crm.sellyourcarfast.com.au/#Leads/";

// const TEST_URL =
//   "https://www.carsales.com.au/cars/details/2020-toyota-landcruiser-sahara-auto-4x4/SSE-AD-17556086/";

const TEST_URL =
  "https://www.carsales.com.au/cars/details/2021-ford-ranger-fx4-px-mkiii-auto-4x4-my21-75-double-cab/SSE-AD-17745668/";

const daysSinceLastUpdated = (lastUpdated, daysOnMarket, delisted) => {
  if (delisted) {
    return "N/A";
  }
  const now = dayjs();
  const lastUpdatedDate = dayjs.unix(lastUpdated);
  return `${
    now.diff(lastUpdatedDate, "days") + daysOnMarket
  } days, Data below was last updated ${lastUpdatedDate.fromNow()}`;
};

const renderPriceChangeText = (currentPrice, CSHistory) => {
  if (!CSHistory) {
    return null;
  }
  if (CSHistory.length === 1) {
    return (
      <NumberFormat
        value={currentPrice}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    );
  }
  // If history has price changes
  let hasPriceChanges = false;
  let priceChanges = [];

  CSHistory?.forEach((item) => {
    const tmpHasPriceChanges = item?.changedFields?.filter((i) =>
      ["advertisedPrice"].includes(i)
    );
    hasPriceChanges = hasPriceChanges || tmpHasPriceChanges?.length > 0;
    if (tmpHasPriceChanges?.length > 0) {
      priceChanges.push({
        price: item.advertisedPrice,
        date: dayjs.unix(item.lastUpdated).format("DD/MM/YYYY"),
      });
    }
  });

  if (hasPriceChanges) {
    return (
      <>
        <NumberFormat
          value={currentPrice}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
        <br />
        <small>
          <b>Price changes</b>
          <br />
          {priceChanges.map((item) => (
            <span key={item.date}>
              {item.date}:{" "}
              <NumberFormat
                value={item.price}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
              <br />
            </span>
          ))}
        </small>
      </>
    );
  }
};

const SugarLeadGenerationComponent = () => {
  const [loadingFromUrl, setLoadingFromUrl] = useState(false);
  const [loadingFromSource, setLoadingFromSource] = useState(false);
  const [savingToSugar, setSavingToSugar] = useState(false);
  const [loadedFromUrl, setLoadedFromUrl] = useState(false);
  const [noDataFoundFromUrl, setNoDataFoundFromUrl] = useState(false);
  const [noDataFoundFromSource, setNoDataFoundFromSource] = useState(false);

  const [url, setUrl] = useState(TEST_URL);
  const [source, setSource] = useState("");

  const [CSADID, setCSADID] = useState("");
  const [CSData, setCSData] = useState(null);
  const [CSDataHistory, setCSDataHistory] = useState([]);

  const [sugarLeadData, setSugarLeadData] = useState(initialLeadValues);
  const [sugarLeadId, setSugarLeadId] = useState(null);

  const functions = useFunctions();

  const clearValues = () => {
    setCSData(null);
    setCSADID("");
    setUrl("");
    setSource("");
    setSugarLeadData(initialLeadValues);
    setSugarLeadId(null);
    setLoadedFromUrl(false);
    setNoDataFoundFromUrl(false);
    setNoDataFoundFromSource(false);
  };

  const splitUrlAndReturnAdId = (url) => {
    const urlParts = url.split("/");
    // Convert to uppercase to make it case insensitive
    const urlPartsUpper = urlParts.map((part) => part.toUpperCase());

    // Find the ad id from the url parts with the pattern "XYZ-AD-12345678"
    return urlPartsUpper.find((part) => part.includes("-AD-"));
  };

  const getDataFromUrl = () => {
    if (url === "") {
      toast.error("URL is required");
      return;
    }

    setLoadingFromUrl(true);
    const adId = splitUrlAndReturnAdId(url);
    if (!adId) {
      toast.error("Invalid URL");
      setLoadingFromUrl(false);
      return;
    }
    setCSADID(adId);

    console.log(`Searching for ad id: ${adId}`);

    // Call the cloud function to get the data
    const getAdDataFromAdId = httpsCallable(functions, "getAdDataFromAdId");

    getAdDataFromAdId({ adId: adId })
      .then((result) => {
        console.log(result.data);
        if (!result.data) {
          toast.error("No data found");
          setLoadingFromUrl(false);
          setLoadedFromUrl(false);
          setNoDataFoundFromUrl(true);
          setCSData(null);
          return;
        }
        setCSDataHistory(result.data);
        const data = result.data;
        if (data?.length === 0) {
          toast.error("No data found");
          setLoadingFromUrl(false);
          setLoadedFromUrl(false);
          setNoDataFoundFromUrl(true);
          setCSData(null);
          return;
        }

        // Sort by last updated and set the first item
        setCSData(data.sort((a, b) => b.lastUpdated - a.lastUpdated)[0]);

        setCSDataHistory(data);
        setLoadingFromUrl(false);
        setLoadedFromUrl(true);
        setNoDataFoundFromSource(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error getting data from URL");
        setLoadingFromUrl(false);
        setNoDataFoundFromSource(false);
        setLoadedFromUrl(false);
      });

    return null;
  };

  const getDataFromSource = () => {
    if (source === "") {
      toast.error("Source is required");
      return;
    }

    // Helper function to extract text after a specific label
    function extractTextAfterLabel($, label) {
      return $(`span:contains("${label}")`).next().text().trim();
    }

    // Helper function to extract the price
    function extractPrice($) {
      let priceText = $('div:contains("Excl. Govt. Charges")').text().trim();
      console.log(priceText);
      if (priceText.includes("$")) {
        return priceText.match(/\$\d{1,3}(,\d{1,3})*(\.\d{2})?/)[0];
        // return priceText;
      }
      return "Price not found";
    }

    setLoadingFromSource(true);

    const $ = cheerio.load(source);

    const vehicleData = {};

    // Extracting title
    vehicleData.title = $("title").text().trim();

    // Extracting price
    vehicleData.price = extractPrice($);
    // Extracting various details
    vehicleData.odometer = extractTextAfterLabel($, "Odometer");
    vehicleData.fuel = extractTextAfterLabel($, "Fuel");
    vehicleData.transmission = extractTextAfterLabel($, "Transmission");
    vehicleData.seller_type = extractTextAfterLabel($, "Seller type");
    vehicleData.condition = extractTextAfterLabel($, "New/Used");
    vehicleData.location = extractTextAfterLabel($, "Location");
    vehicleData.exterior_colour = extractTextAfterLabel($, "Exterior Colour");
    vehicleData.interior_colour = extractTextAfterLabel($, "Interior Colour");
    vehicleData.body_type = extractTextAfterLabel($, "Body type");
    vehicleData.engine = extractTextAfterLabel($, "Engine");
    vehicleData.fuel_consumption_combined = extractTextAfterLabel(
      $,
      "Fuel consumption combined"
    );
    vehicleData.ancap_safety_rating = extractTextAfterLabel(
      $,
      "ANCAP safety rating"
    );
    vehicleData.registration_plate = extractTextAfterLabel(
      $,
      "Registration Plate"
    );
    vehicleData.registration_expiry = extractTextAfterLabel(
      $,
      "Registration Expiry"
    );
    vehicleData.build_date = extractTextAfterLabel($, "Build Date");
    vehicleData.compliance_date = extractTextAfterLabel($, "Compliance Date");
    vehicleData.model_year = extractTextAfterLabel($, "Model Year");
    vehicleData.roadworthy_certificate = extractTextAfterLabel(
      $,
      "Roadworthy certificate"
    );
    vehicleData.last_modified = extractTextAfterLabel($, "Last Modified");
    vehicleData.carsales_network_id = extractTextAfterLabel(
      $,
      "carsales Network ID"
    );

    // Extracting comments
    vehicleData.comments = $('div:contains("Comments from the seller")')
      .text()
      .trim();

    // Extracting images
    vehicleData.images = [];
    $("img").each((i, elem) => {
      const src = $(elem).attr("src");
      if (src && src.includes("http")) {
        vehicleData.images.push(src);
      }
    });

    setLoadingFromSource(false);
    console.log(vehicleData);
    return vehicleData;
  };

  const saveToSugar = async () => {
    if (CSData === null) {
      toast.error("No data to save");
      return;
    }

    setSavingToSugar(true);

    const payload = {
      vehicleInfo: CSData,
      leadData: {
        // firstName: values.firstName,
        // lastName: values.lastName,
        // phone_mobile: values.phone_mobile,
        // email1: values.email1,
      },
    };

    const createVehicleInfoIntoNewSugarCrmLead = httpsCallable(
      functions,
      "createVehicleInfoIntoNewSugarCrmLead"
    );

    const sugarLeadId = await createVehicleInfoIntoNewSugarCrmLead(payload)
      .then((result) => {
        // Read result of the Cloud Function.
        console.log(result);
        var sanitizedMessage = result.data;
        if (sanitizedMessage === false) {
          throw "Error";
        } else {
          if (sanitizedMessage.error?.length > 0) {
            toast.warning(sanitizedMessage.error);
            if (sanitizedMessage.errorCode === 933) {
              //Multiple Redbook codes found
              // setMultipleRedbookFound(sanitizedMessage.rbcodes);
            }
          } else {
            toast.success("Created lead");
            console.log(sanitizedMessage);
            setSugarLeadId(sanitizedMessage);
            return sanitizedMessage;
          }
        }
      })
      .catch((e) => {
        toast.error(e);
        console.log(e);
      });

    // Search by new lead id
    if (sugarLeadId === false) {
      return;
    }

    setSavingToSugar(false);
  };

  useEffect(() => {
    if (url === "") {
      clearValues();
    }
  }, [url]);

  return (
    <>
      <BackdropComponent loading={false} />
      <ToastContainer />
      <Container>
        <Row>
          <Col>
            <h5>Sugar Lead Generation</h5>
            <p>Enter url</p>
          </Col>
        </Row>
        <Row style={{ paddingBottom: 15 }}>
          <Col>
            <InputGroup>
              <Form.Control
                disabled={
                  loadingFromUrl ||
                  loadingFromSource ||
                  savingToSugar ||
                  loadedFromUrl ||
                  noDataFoundFromUrl
                }
                style={{ fontSize: 12 }}
                type="text"
                placeholder="https://carsales.com.au/..."
                onChange={(e) => setUrl(e.target.value)}
                value={url}
              ></Form.Control>
              <InputGroup.Append>
                <Button
                  disabled={
                    loadingFromUrl ||
                    loadingFromSource ||
                    savingToSugar ||
                    loadedFromUrl ||
                    noDataFoundFromUrl
                  }
                  size="sm"
                  variant="dark"
                  onClick={() => getDataFromUrl()}
                >
                  Search
                </Button>
                <Button
                  disabled={
                    loadingFromUrl || loadingFromSource || savingToSugar
                  }
                  size="sm"
                  variant="danger"
                  onClick={() => setUrl("")}
                >
                  Clear
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {noDataFoundFromUrl ? (
              <>
                <p>No data found from URL, please enter source</p>
                <InputGroup>
                  <Form.Control
                    style={{ fontSize: 12 }}
                    as="textarea"
                    rows={20}
                    placeholder="Source"
                    onChange={(e) => setSource(e.target.value)}
                    value={source}
                  ></Form.Control>
                </InputGroup>
                <br />
                <ButtonGroup>
                  <Button
                    size="sm"
                    variant="dark"
                    onClick={() => getDataFromSource()}
                  >
                    Search
                  </Button>
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() => setSource("")}
                  >
                    Clear
                  </Button>
                </ButtonGroup>
              </>
            ) : null}
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            {CSADID && url.length > 0 && loadingFromUrl ? (
              <p>
                Valid url, searching... {CSADID}{" "}
                <Spinner animation="border" size="sm" />{" "}
              </p>
            ) : null}
          </Col>
        </Row>
      </Container>

      <Container
        style={{
          border: "1px solid #ccc",
          borderRadius: 5,
          padding: 10,
          marginTop: 10,
          display: CSData ? "block" : "none",
        }}
      >
        <Row>
          <Col>
            <h5>Found 1 result</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* Add to a table of results */}

            <Table striped bordered hover size="sm">
              <thead
                style={{
                  ...stickyTableHeaderStyle,
                }}
              >
                <tr>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody
                style={{
                  textAlign: "left",
                  fontSize: 12,
                }}
              >
                {CSData?.delisted ? (
                  <tr>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      <Badge style={{ fontSize: 20 }} variant="danger">
                        Delisted
                      </Badge>
                    </td>
                  </tr>
                ) : null}
                {CSDataHistory?.length > 1 ? (
                  <tr>
                    <td></td>
                  </tr>
                ) : null}
                <tr>
                  <td>Days on market</td>
                  <td>
                    {daysSinceLastUpdated(
                      CSData?.lastUpdated,
                      CSData?.daysOnMarket,
                      CSData?.delisted
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Make</td>
                  <td>{CSData?.specData?.make}</td>
                </tr>
                <tr>
                  <td>Model</td>
                  <td>{CSData?.specData?.model}</td>
                </tr>
                <tr>
                  <td>Variant</td>
                  <td>{CSData?.specData?.description}</td>
                </tr>

                <tr>
                  <td>Adv. Price</td>
                  <td>
                    {renderPriceChangeText(
                      CSData?.advertisedPrice,
                      CSDataHistory
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Colour</td>
                  <td>{CSData?.colour}</td>
                </tr>
                <tr>
                  <td>Region</td>
                  <td>{CSData?.region}</td>
                </tr>
                <tr>
                  <td>VIN</td>
                  <td>{CSData?.vin}</td>
                </tr>
                <tr>
                  <td>Rego</td>
                  <td>{CSData?.rego}</td>
                </tr>

                <tr>
                  <td>Rego Expiry</td>
                  <td>{CSData?.regoExpiry.split("T")?.[0] || ""}</td>
                </tr>
                <tr>
                  <td>Contact Name</td>
                  <td>{CSData?.contactName}</td>
                </tr>
                {/* <tr>
                  <td>Comments</td>
                  <td>{CSData?.comments}</td>
                </tr> */}
              </tbody>
            </Table>
          </Col>

          <Col>
            {/* Image */}
            {CSData?.photos ? (
              <img
                src={CSData.photos[0]?.resourcePath + carsalesImageSuffix}
                alt="Car"
                style={{ maxWidth: 300, maxHeight: 300 }}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonGroup>
              <Button
                size="sm"
                variant="outline-primary"
                disabled={savingToSugar || CSData === null || CSData?.delisted}
                onClick={() => {
                  saveToSugar();
                }}
              >
                Create lead in SugarCRM
              </Button>
              <Button
                size="sm"
                variant={
                  sugarLeadId?.length === 0 ||
                  typeof sugarLeadId === "undefined"
                    ? "outline-warning"
                    : "warning"
                }
                type="reset"
                disabled={
                  sugarLeadId?.length === 0 ||
                  typeof sugarLeadId === "undefined" ||
                  sugarLeadId === null ||
                  savingToSugar ||
                  CSData === null ||
                  CSData?.delisted
                }
                onClick={() => {
                  window.open(
                    `${SUGARCRM_LEAD_PREFIX}${sugarLeadId}`,
                    "_blank"
                  );
                }}
              >
                Open lead in SugarCRM
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SugarLeadGenerationComponent;
