import { useUser } from "reactfire";
import { groupBy as rowGrouper } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useMemo, useCallback } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContainer from "react-bootstrap/TabContainer";
import { Formik } from "formik";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import { useEffect } from "react";
import { useRef } from "react";
import NumberFormat from "react-number-format";
import DataGrid from "react-data-grid";
import dayjs from "dayjs";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import BookingCard from "./BookingCard";
import PageViewLoggerComponent from "../PageViewLoggerComponent";
// import { FaTruckMoving } from "react-icons/fa";
import { HiOutlineTruck } from "react-icons/hi";
import { IoGridSharp } from "react-icons/io5";
import { IoListSharp } from "react-icons/io5";
import Badge from "react-bootstrap/Badge";
import MyBookingCard from "./MyBookingCard";
import { useHistory } from "react-router";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import BookingDesktopView from "./BookingDesktopView";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// const topProfitDataSet = require("../../assets/testdata/top_profit_test.json");
// const topVolumeDataSet = require("../../assets/testdata/top_volume_test.json");
// const topLeadsDataSet = require("../../assets/testdata/top_leads_test.json");

const numericalASCProfit = (a, b) => {
  if (a.TrueProfit < b.TrueProfit) {
    return -1;
  }
  if (a.TrueProfit > b.TrueProfit) {
    return 1;
  }
  return 0;
};

const FreightBookingItem = (props) => {
  const [freightBookingItem, setFreightBookingItem] = useState([]);
  const [stage, setStage] = useState("Request");
  // const [viewTypeRequest, setViewTypeRequest] = useState(1); //0 LIST, 1 CARD
  // const [viewTypeBooked, setViewTypeBooked] = useState(0); //0 LIST, 1 CARD
  // const [viewTypeTransit, setViewTypeTransit] = useState(0); //0 LIST, 1 CARD
  // const [viewTypeFinalised, setViewTypeFinalised] = useState(0); //0 LIST, 1 CARD
  const [isLoading, setIsLoading] = useState(false);
  const [claims, setClaims] = useState(null);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const { data: user } = useUser();

  // const claims = useIdTokenResult(user || null, true);
  // const claims = false;
  const history = useHistory();
  const { height, width } = useWindowDimensions();

  const updateStage = (e) => {
    setStage(e);
  };
  // Top Profit Data Set
  useEffect(() => {
    const bookingId = props.match.params.bookingId;
    setIsLoading(true);
    if (user) {
      if (bookingId.length === 0) {
        toast.warning("No booking id found");
        setFreightBookingItem([]);
        return;
      }

      console.log("loading booking id: " + bookingId);
      setFreightBookingItem([]);
      const unsubscribe = firestore
        .collection("freight-bookings")
        .doc(bookingId)
        .onSnapshot(
          (doc) => {
            if (doc.exists) {
              console.log(`loading booking id: ${bookingId}`);
              const data = doc.data();
              const payload = { id: doc.id, ...data };
              // console.log("Document data:", payload);
              setFreightBookingItem([payload]);
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
              toast.info("No request found");
            }
          },
          (error) => {
            if (error.code === "permission-denied") {
              setIsLoading(false);
              toast.warning("Not authorised to view this booking");
              setFreightBookingItem([]);
            } else {
              toast.info("No request found");
              setIsLoading(false);
              setFreightBookingItem([]);
            }
          }
        );
      return () => unsubscribe;
    }
  }, [props.match.params.bookingId, user]);

  useEffect(() => {
    if (user) {
      try {
        user?.getIdTokenResult(true).then((claims) => {
          console.log("claims");
          console.log(claims);
          console.log("freight item");
          console.log(freightBookingItem);
          setClaims(claims);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [user]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Freight List Item</Breadcrumb.Item>
      </Breadcrumb>
      <Navbar
        // sticky="top"
        bg="light"
        variant="secondary"
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        {/* <Button
          variant="outline-secondary"
          onClick={() => {
            console.log(history?.location);
            if (history?.location?.state?.origin === "ws") {
              history.push("/freight-booking-list-wholesale");
            } else {
              history.push("/freight-booking-list");
            }
          }}
        >
          Back to list
        </Button> */}
      </Navbar>
      {/* START TOP PROFIT */}
      <Navbar
        // sticky="top"
        bg="dark"
        variant="dark"
        style={{ marginBottom: 10, marginTop: 10 }}
      ></Navbar>
      {/* <pre>{JSON.stringify(history, null, 2)}</pre> */}
      {freightBookingItem.map((f, idx) => {
        if (claims?.claims?.freight_cru) {
          if (width > 1000) {
            return (
              <BookingDesktopView
                key={idx}
                data={f}
                stage="Request"
                nextStage="Booked"
              />
            );
          } else {
            return (
              <BookingCard
                key={idx}
                data={f}
                stage="Request"
                nextStage="Booked"
              />
            );
          }
        } else if (
          (claims?.claims?.freight_c && !claims?.claims?.freight_cru) ||
          claims?.claims?.freight_list_ro
        ) {
          return (
            <MyBookingCard
              key={idx}
              data={f}
              stage="Request"
              nextStage="Booked"
            />
          );
        }
      })}
      <ToastContainer />
      <PageViewLoggerComponent />
    </>
  );
};

export default FreightBookingItem;
