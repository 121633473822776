import { ToastContainer, toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dayjs from "dayjs";
import bookedTruckIcon from "../../assets/images/truck-icon.svg";

import { FaCalendarCheck } from "react-icons/fa";
import BackdropComponent from "../BackdropComponent";
import Image from "react-bootstrap/Image";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Button } from "react-bootstrap";
import { AnimatePresence } from "framer-motion/dist/framer-motion";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const cardColumnStyle = { padding: 20 };
const scrollOffset = 11; //Number of cards on a screen (offsetting the jump to next "fold")

// Changed address from Grieve PDE to Bridge St 210824
// "445 Grieve Parade, Altona North VIC 3025, Australia"
const WHOLESALE_ADDRESS = "339 Bridge Street, Port Melbourne VIC, Australia";

const cardVariants = {
  offscreen: {
    y: -300,
  },
  onscreen: {
    y: 0,
    rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const WallboardWholesaleNew = ({ type }) => {
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const { height, width } = useWindowDimensions();
  const [counter, setCounter] = useState(0);
  const [scrollSpeed, setScollSpeed] = useState(5000);
  // const [scrollInterval, setScrollInterval] = useState(null);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const scrollToNextFold = () => {
    // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    // Make sure that there is no screen overflow, if not, scroll forward
    if (data.length > scrollOffset) {
      let scrollNext = 0;
      if (counter < data.length / scrollOffset) {
        scrollNext = scrollOffset * counter;
      } else {
        setCounter(0);
      }
      document
        .getElementById(`endRow-${scrollNext}`)
        .scrollIntoView({ behavior: "smooth" });
    } else {
      setCounter(0);
      // clearInterval(scrollInterval);
    }
  };

  // Loop to scroll page counter
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, scrollSpeed);
    // setScrollInterval(interval);
    return () => clearInterval(interval);
  }, [data.length, scrollSpeed]);

  // Set scroll speed
  useEffect(() => {
    const unsub = firestore
      .collection("settings")
      .doc("wallboard-scroll-speed")
      .onSnapshot((doc) => {
        const data = doc.data();
        setScollSpeed(data["dg-buying"]);
        console.log("updating scrollSpeed", data);
      });
    return () => unsub();
  }, []);

  // Loop to scroll page counter
  useEffect(() => {
    scrollToNextFold();
  }, [counter]);

  useEffect(() => {
    setData([]);
    setIsLoading(true);
    let address = "";

    if (type === "arrivals") {
      address = "dropOff.location.address";
    } else if (type === "departures") {
      address = "pickup.location.address";
    }
    const unsubscribe = firestore
      .collection("freight-bookings")
      .where(address, "==", WHOLESALE_ADDRESS)
      .where("status", "in", ["Booked", "Transit"])
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          toast.info("No data found");
          setIsLoading(false);
          setData([]);
          return;
        }
        let tmpCarriers = [];

        snapshot.forEach((doc) => {
          let tmpData = doc.data();
          const d1 = tmpData.carrierData?.estimatedDeliveryDate;
          const now = dayjs();
          const diff = now.diff(d1, "days", true) * -1;
          let overdue = diff < 0 ? "red" : "";
          let relativeDays = dayjs().to(
            dayjs(tmpData.carrierData?.estimatedDeliveryDate)
          );

          const today = dayjs().format("YYYY-MM-DD");
          const tomorrow = dayjs().add(1, "day").format("YYYY-MM-DD");
          const yesterday = dayjs().add(-1, "day").format("YYYY-MM-DD");
          if (d1 === today) {
            relativeDays = "Today";
            overdue = "green";
          } else if (d1 === tomorrow) {
            relativeDays = "Tomorrow";
          } else if (d1 === yesterday) {
            relativeDays = "Yesterday";
          }

          tmpData = {
            ...tmpData,
            relativeDays,
            dayDiffNowETD: diff,
            overdue,
          };
          tmpCarriers.push({ ...tmpData, id: doc.id });
        });

        // Sort by days to arrive from ETD
        tmpCarriers = tmpCarriers.sort((a, b) => {
          return a.dayDiffNowETD - b.dayDiffNowETD;
        });

        setData(tmpCarriers);
        setIsLoading(false);
      });

    return () => unsubscribe;
  }, [type]);

  if (width > 1000) {
    return (
      <div>
        <BackdropComponent loading={loading} />
        <h1>Wholesale Arrivals</h1>
        <h6>Last updated: now</h6>
        <Button
          style={{
            position: "fixed",
            top: height / 2,
            left: 0,
            zIndex: 99999,
            width: 40,
            height: 150,
            textOrientation: "upright",
            writingMode: "vertical-rl",
          }}
        >
          {`${counter + 1} of ${Math.floor(data.length / scrollOffset) + 1}`}
        </Button>
        <ToastContainer />
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            fontSize: "1.2em",
            fontWeight: "bolder",
          }}
        >
          {data?.map((r, idx) => {
            return r.vehicleItem.map((v, idxinner) => {
              return (
                <AnimatePresence>
                  <Row
                    key={`${idx}${idxinner}`}
                    style={{
                      width: "100%",
                      height: 150,
                      backgroundColor: "#161616",
                      color: "#FFF",
                      margin: 2,
                      borderRadius: 2,
                    }}
                  >
                    <Col style={{ ...cardColumnStyle, maxWidth: 150 }}>
                      {v.EclipseStockNumber}
                      <br />
                      {r.status === "Transit" ? (
                        <Image
                          src={bookedTruckIcon}
                          width={65}
                          style={{ padding: 5, marginTop: 15 }}
                          rounded
                        />
                      ) : null}
                      {r.status === "Booked" ? (
                        <FaCalendarCheck
                          fontSize={55}
                          style={{
                            padding: 5,
                            marginTop: 15,
                            color: "#FFF",
                          }}
                        />
                      ) : null}
                    </Col>
                    <Col
                      style={{
                        ...cardColumnStyle,
                        maxWidth: 250,
                        textAlign: "left",
                      }}
                    >
                      {v.model}
                      <br />
                      {`Buyer TBA`}
                      <br />
                      <br />
                      {r.carrierData?.carrier?.carrier}
                    </Col>
                    <Col
                      style={{
                        ...cardColumnStyle,
                        maxWidth: 350,
                        textAlign: "left",
                      }}
                    >
                      {v.colour}
                      <br />
                      <br />
                      <span style={{ color: "#54c45e" }}>
                        P:{" "}
                        {r.pickup?.location?.address?.length > 0
                          ? r.pickup?.location?.address
                          : "NA"}
                      </span>
                    </Col>
                    <Col
                      style={{
                        ...cardColumnStyle,
                        maxWidth: 200,
                        textAlign: "center",
                      }}
                    >
                      {r?.displayName?.split(" ")[0]}
                      <br />
                      <br />
                      <span style={{ color: "#54c45e" }}>
                        {r.carrierData?.estimatedCollectionDate}
                      </span>
                      <br />
                      {r.carrierData?.estimatedDeliveryDate}
                    </Col>
                    <Col
                      style={{
                        backgroundColor: r.overdue,
                        height: "100%",
                        borderRadius: 2,
                        maxWidth: 200,
                        ...cardColumnStyle,
                        textAlign: "center",
                      }}
                    >
                      <span style={{ position: "relative", top: "30%" }}>
                        {r.relativeDays}
                      </span>
                    </Col>

                    <div id={`endRow-${idx}`} />
                  </Row>
                </AnimatePresence>
              );
            });
          })}
        </div>
      </div>
    );
  } else {
    return (
      <h3 style={{ marginTop: 200 }}>
        This wallboard is only visible on screens greater than 1000px in width
      </h3>
      // <Table
      //   style={{
      //     color: "#FFF",
      //     borderColor: "#000",
      //     border: 0,
      //     fontSize: "1.5em",
      //   }}
      // >
      //   <thead>
      //     <tr>
      //       <th>Status</th>
      //       <th>Stock#</th>
      //       <th>Model</th>
      //       <th>Colour</th>
      //       <th>Carrier</th>
      //       <th>Buyer</th>
      //       <th>Booked by</th>
      //       <th>ETC</th>
      //       <th>ETD</th>
      //       <th>Time to ETD</th>
      //     </tr>
      //   </thead>
      //   <tbody>
      //     {data?.map((r) => {
      //       return r.vehicleItem.map((v) => {
      //         return (
      //           <>
      //             <tr>
      //               <td>{r.status}</td>
      //               <td>{v.EclipseStockNumber}</td>
      //               <td>{v.model}</td>
      //               <td>{v.colour}</td>
      //               <td>{r.carrierData?.carrier?.carrier}</td>
      //               <td>N/A</td>
      //               <td>{r.displayName}</td>
      //               <td>{r.carrierData?.estimatedCollectionDate}</td>
      //               <td>{r.carrierData?.estimatedDeliveryDate}</td>
      //               <td style={{ backgroundColor: r.overdue }}>
      //                 {r.relativeDays}
      //               </td>
      //             </tr>
      //           </>
      //         );
      //       });
      //     })}
      //   </tbody>
      // </Table>
    );
  }
};

export default WallboardWholesaleNew;
