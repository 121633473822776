import { useStorageDownloadURL, useStorage } from "reactfire";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import ImageModalComponent from "./ImageModalComponent";
import { Camera } from "react-bootstrap-icons";
import ProgressBar from "react-bootstrap/ProgressBar";

import InputGroup from "react-bootstrap/InputGroup";
import { useEffect } from "react";
import { useRef } from "react";
import { createWorker } from "tesseract.js";
import NumberFormat from "react-number-format";
import vision from "react-cloud-vision-api";
import Canvas from "./CanvasComponent";
import PageViewLoggerComponent from "./PageViewLoggerComponent";
import dayjs from "dayjs";
import copy from "copy-to-clipboard";
import { HiClipboardCheck } from "react-icons/hi";
import VehiclePhotos from "./VehiclePhotos";
import Barcode from "react-barcode";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import StockCardSummary from "./helpers/StockCardSummary";

const dev = true;
var initState = { vin: "", rego: "" };
if (dev) {
  initState.vin = "";
  initState.rego = "";
  initState.stockId = "";
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// var workerStatus = "";
// const worker = createWorker({
//   logger: (m) => {
//     console.log(m);
//     workerStatus = m;
//   },
// });

function VehicleSearchComponent() {
  const [vin, setVin] = useState(initState.vin);
  const [stockId, setStockId] = useState(initState.stockId);
  const [rego, setRego] = useState(initState.rego);
  const [photos, setPhotos] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [cvBoundingPoly, setCVBoundinPoly] = useState();

  const [modalShow, setModalShow] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [vehicleInfoObj, setVehicleInfoObj] = useState({
    make: "",
    model: "",
    variant: "",
    odo: "",
    price: "",
    vin: "",
  });
  const [isLoadingVinImageRecog, setIsLoadingVinImageRecog] = useState(false);
  const [isLoadingRegoImageRecog, setIsLoadingRegoImageRecog] = useState(false);
  const [vinImageRecogProgress, setVinImageRecogProgress] = useState(0);
  const [isLoadingVicroadsSearch, setIsLoadinVicroadsSearch] = useState(false);
  const [modalDetailsShow, setModalDetailsShow] = useState(false);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const storage = useStorage();
  const vinImageRef = useRef(null);
  const regoImageRef = useRef(null);

  const numericalASC = (a, b) => {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  };

  // const getDataByRego = async () => {
  //   if (rego.length === 0 || rego.length < 5) {
  //     toast.warning("Rego doesn't seem correct, please check");
  //     return;
  //   }
  //   var vehicles = db
  //     .collectionGroup("assets")
  //     .where("metadata.regoNo", "==", rego);
  //   vehicles
  //     .get()
  //     .then(function (querySnapshot) {
  //       if (querySnapshot.size > 0) {
  //         // querySnapshot
  //         //   const vehData = doc.data();
  //         toast.success(`Found ${querySnapshot.size} images`);
  //         const images = [];
  //         querySnapshot.forEach((doc) => {
  //           const data = doc.data();
  //           images.push({
  //             position: parseInt(data.metadata.photoPosition),
  //             src: data.mediaLink,
  //             vehicleInfo: data.metadata,
  //           });

  //           //   Sort by photo position
  //           images.sort(numericalASC);
  //         });
  //         // Set photo array
  //         setPhotos(images);

  //         //   console.log(`${vehData}`);
  //       } else {
  //         toast.error("No images found");
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e.message);
  //       toast.error(e.message);
  //     });
  // };
  const draw = (ctx) => {
    const examplePoly = [
      {
        x: 815,
        y: 474,
      },
      {
        x: 1270,
        y: 519,
      },
      {
        x: 1266,
        y: 554,
      },
      {
        x: 812,
        y: 509,
      },
    ];
    // if (modalImage !== null) ctx.drawImage(null, 0, 0);
    // if (modalImage !== null) {
    //   var image = new Image();
    //   image.onload = function () {
    //     ctx.drawImage(image, 0, 0);
    //   };
    //   image.src = modalImage;
    // }

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.beginPath();
    ctx.rect(0, 0, 200, 100);
    ctx.lineWidth = 2;
    ctx.strokeStyle = "green";
    ctx.stroke();
  };

  const copyToClipboard = (v) => {
    copy(v);
  };

  const getData = async (searchByStockId = 0) => {
    var vehicles = null;
    var searchFullVIN = true;
    // If not searching by stock id (searching by VIN)
    if (searchByStockId === 0) {
      if (vin.length === 0 || vin.length < 15) {
        if (vin.length === 6) {
          // If 6 digits, search truncated VIN (last 6 digits)
          searchFullVIN = false;
        } else {
          toast.warning("VIN doesn't seem correct, please check");
          return;
        }
      }

      setSearchResults([]);

      if (searchFullVIN) {
        vehicles = firestore
          .collection("inventory-summary")
          .where("VEHVIN", "==", vin);
      } else {
        vehicles = firestore
          .collection("inventory-summary")
          .where("VINTrunc", "==", vin);
      }
    } else {
      console.log("searching by stock Id", stockId);
      // If searching by stock id
      if (stockId.length === 0) {
        toast.warning("Please enter a stock ID");
        return;
      }

      vehicles = firestore
        .collection("inventory-summary")
        .where("VEHStockNumber", "==", parseInt(stockId));
    }

    await vehicles
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          // querySnapshot
          //   const vehData = doc.data();
          toast.success(`Found ${querySnapshot.size} vehicle in stock`);
          const vehTmp = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            vehTmp.push(data);
          });
          // Set photo array
          setSearchResults(vehTmp);

          //   console.log(`${vehData}`);
        } else {
          toast.error("No vehicle found in stock, check VIN");
        }
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const showCurrentImageModal = (src) => {
    setModalImage(src);
    setModalShow(true);
  };

  const handleVinChange = (e) => {
    setVin(e.target.value);
  };

  const handleStockIdChange = (e) => {
    setStockId(e.target.value);
  };

  const handleRegoChange = (e) => {
    setRego(e.target.value);
  };

  const handleModalDetails = () => {
    setModalDetailsShow(!modalDetailsShow);
  };

  const handleVinImageChange = async (event) => {
    setVin("");
    setSearchResults([]);
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    console.log(file);
    let fileB64 = null;
    setIsLoadingVinImageRecog(true);
    // Send to GV to detect text
    const ocrResult = null;

    const reader = new FileReader();
    reader.onloadend = async () => {
      // Load img for canvas

      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      // log to console
      // logs wL2dvYWwgbW9yZ...
      console.log("converted to base64");
      fileB64 = base64String;
      setModalImage(URL.createObjectURL(file));
      let req = new vision.Request({
        image: new vision.Image({
          base64: fileB64,
        }),
        features: [new vision.Feature("TEXT_DETECTION")],
      });

      await vision.annotate(req).then((res) => {
        console.log(res);

        let VINLabelIdx = 0;
        let VINValueIdx = 0;
        let VINValue = "";

        res.responses[0].textAnnotations.forEach((ta, idx) => {
          // Search by VIN label order
          // if (ta.description === "VIN") {
          //   VINLabelIdx = idx;
          //   VINValueIdx = idx + 1;
          //   VINValue = res.responses[0].textAnnotations[idx + 1].description;
          // }

          // Search by finding WDC1660242A704804 (17 digit)
          if (ta?.description?.length === 17) {
            VINValue = ta.description;
            setVin(VINValue);
            setCVBoundinPoly(ta.boundingPoly.vertices);
            toast.success(
              "Converted image to VIN, please check and then hit Search"
            );
          }
        });
        setIsLoadingVinImageRecog(false);
        if (VINValue === "") {
          toast.warning(
            "Could not find VIN in image, please try again or enter manually"
          );
        }
      });
    };
    reader.readAsDataURL(file);
  };

  const handleRegoImageChange = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    setIsLoadingRegoImageRecog(true);
    // Send to GV to detect text
    await sleep(4000);
    setIsLoadingRegoImageRecog(false);
    toast.warning(
      "Could not find rego in photo, please try again or type in manually"
    );
    console.log(file);
  };

  const vinImageRefClick = () => {
    if (vinImageRef !== null) {
      vinImageRef.current.click();
    }
  };

  const regoImageRefClick = () => {
    //
    if (regoImageRef !== null) {
      regoImageRef.current.click();
    }
  };

  const getVicroadsSerial = async () => {
    const req = { vin: "WV2ZZZ7HZ8H131755" };
    setIsLoadinVicroadsSearch(true);
    await fetch("http://localhost:3123/getvicroadsserial", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: JSON.stringify(req),
    })
      .then((r) => r.json())
      .then((r) => {
        console.log(r);
        toast.success(`Found: ${r.serial}`);
        const tmpV = [...searchResults];
        tmpV[0]["vicroadsserial"] = r.serial;
        setSearchResults(tmpV);
        setIsLoadinVicroadsSearch(false);
      })
      .catch((e) => {
        console.log(e.message);
        setIsLoadinVicroadsSearch(false);
      });
  };

  // Update vehicle info array when photos array changes
  // useEffect(() => {
  //   // Set vehicle info array
  //   if (photos.length > 0 && photos[0].vehicleInfo) {
  //     var vehicleProperties = [];
  //     const tmpVehicleInfo = photos[0].vehicleInfo;
  //     setVehicleInfoObj(tmpVehicleInfo);
  //     for (let prop in tmpVehicleInfo) {
  //       const tmpProp = { key: prop, value: tmpVehicleInfo[prop] };
  //       vehicleProperties.push(tmpProp);
  //     }
  //     setVehicleInfo(vehicleProperties);
  //   }
  // }, [photos]);

  useEffect(() => {
    vision.init({ auth: "AIzaSyBYIpmrTo_qzFvAiG4FAZA4J9AhWGUS4AQ" });
  });

  // Initialise tesseract worker on init load
  // useEffect(() => {
  //   const initWorker = async () => {
  //     await worker.load();
  //     await worker.loadLanguage("eng");
  //     await worker.initialize("eng");
  //   };
  //   initWorker();
  // }, []);

  // useEffect(() => {
  //   toast.info(workerStatus);
  // }, [workerStatus]);

  return (
    <>
      {/* START VIN SEARCH FIELD */}
      <Container>
        <Row style={{ paddingBottom: 15 }}>
          <Col>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="VIN - Search Full VIN or last 6 digits"
                onChange={(e) => handleVinChange(e)}
                value={vin}
              ></Form.Control>
              <InputGroup.Append>
                <Button variant="info" onClick={vinImageRefClick}>
                  {isLoadingVinImageRecog ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <>
                      <Camera />
                    </>
                  )}
                  <input
                    disabled={isLoadingVinImageRecog}
                    type="file"
                    id="vin-image"
                    ref={vinImageRef}
                    style={{ display: "none" }}
                    onChange={handleVinImageChange}
                  />
                </Button>

                <Button variant="dark" onClick={() => getData(0)}>
                  Search by VIN
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
      </Container>
      {/* END VIN SEARCH FIELD */}

      {/* START STOCK ID SEARCH FIELD */}
      <Container>
        <Row style={{ paddingBottom: 15 }}>
          <Col>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Stock ID"
                onChange={(e) => handleStockIdChange(e)}
                value={stockId}
              ></Form.Control>
              <InputGroup.Append>
                {/* <Button variant="info" onClick={vinImageRefClick}>
                  {isLoadingVinImageRecog ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <>
                      <Camera />
                    </>
                  )}
                  <input
                    disabled={isLoadingVinImageRecog}
                    type="file"
                    id="vin-image"
                    ref={vinImageRef}
                    style={{ display: "none" }}
                    onChange={handleVinImageChange}
                  />
                </Button> */}

                <Button variant="dark" onClick={() => getData(1)}>
                  Search by Stock ID
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
      </Container>
      {/* END STOCK ID SEARCH FIELD */}

      {/* START Vehicle details modal */}
      {/* <Modal
        show={modalDetailsShow}
        size="lg"
        onHide={() => setModalDetailsShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Vehicle information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Property</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {vehicleInfo.map((prop, idx) => {
                return (
                  <tr key={idx}>
                    <td>{prop.key}</td>
                    <td>{prop.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal> */}
      {/* <Button variant="primary" onClick={handleModalDetails} size="lg">
        Show details
      </Button> */}
      {/* END Vehicle details modal */}

      <ToastContainer />
      <Row>
        <Col>
          <Image src={modalImage} fluid width={300} />
        </Col>
      </Row>

      <br />
      <Container fluid>
        {searchResults.map((veh, idx) => {
          return <StockCardSummary veh={veh} key={idx} />;
        })}
      </Container>
      <PageViewLoggerComponent />
    </>
  );
}

export default VehicleSearchComponent;
