import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const styles = {
  dropzone: {
    textAlign: "center",
    padding: 30,
    border: "3px dashed #eeeeee",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    cursor: "pointer",
    marginBottom: 20,
  },
};

function FileDropZone() {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log(acceptedFiles);
  }, []);
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p style={styles.dropzone}>
          Drag 'n' drop some files here, or click to select files
        </p>
      )}
    </div>
  );
}

export default FileDropZone;
