import { useEffect, useState } from "react";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export function useCarrierList() {
  const [carrierList, setCarrierList] = useState([]);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  // Get carrier list
  useEffect(() => {
    firestore
      .collection("freight-carriers")
      .where("active", "==", true)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          setCarrierList([]);
          return;
        }
        const tmpCarriers = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpCarriers.push({ ...tmpData, id: doc.id });
        });
        setCarrierList(tmpCarriers);
      });
  }, []);

  return [carrierList];
}
