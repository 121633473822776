import { useEffect, useState } from "react";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export function useClientList() {
  const [clientList, setClientList] = useState([]);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  // Get client list
  useEffect(() => {
    firestore
      .collection("freight-location-addressbook")
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          setClientList([]);
          return;
        }
        let tmpClients = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpClients.push({ ...tmpData, id: doc.id });
        });

        tmpClients.sort((a, b) => a.name.localeCompare(b.name));
        tmpClients = tmpClients.filter((r) => {
          if (typeof r.active === "undefined") {
            return true;
          }
          return r.active;
        });

        setClientList(tmpClients);
      });
  }, []);

  return [clientList];
}
