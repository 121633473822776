import { useStorageDownloadURL, useStorage } from "reactfire";
import { groupBy as rowGrouper } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useMemo, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import ImageModalComponent from "./ImageModalComponent";
import { Formik, useField } from "formik";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import { useEffect } from "react";
import { useRef } from "react";
import { useVEHMakeModel } from "../hooks/useVEHMakeModel";
import NumberFormat from "react-number-format";
import DataGrid from "react-data-grid";
import dayjs from "dayjs";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import { useQuery } from "../hooks/useQuery";
import EmptyRowsRenderer from "./EmptyRowsRenderer";
import Badge from "react-bootstrap/Badge";
import PageViewLoggerComponent from "./PageViewLoggerComponent";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

/*
Unused imports
import Image from "react-bootstrap/Image";
import { Camera } from "react-bootstrap-icons";
import FormControl from "react-bootstrap/FormControl";
import Dropdown from "react-bootstrap/Dropdown";
import ProgressBar from "react-bootstrap/ProgressBar";
*/
// import { createWorker } from "tesseract.js";

const highlightClassname = `
  .rdg-cell {
    background-color: #7093db;
    color: white;
  }
  &:hover .rdg-cell {
    background-color: #7093db;
  }
`;

const dev = true;

let SALE_WINDOW = 7;

var initState = { vin: "", rego: "" };
if (dev) {
  initState.vin = "";
  initState.rego = "";
  initState.make = "";
  initState.model = "";
  initState.variant = "";
  // SALE_WINDOW = 1;
}

const inStockColumns = [
  {
    key: "VEHMake",
    name: "Make",
    groupFormatter({ childRows }) {
      return (
        <>
          {childRows[0].VEHMake}({childRows.reduce((prev) => prev + 1, 0)})
        </>
      );
    },
  },
  {
    key: "VEHModel",
    name: "Model",
    groupFormatter({ childRows }) {
      return (
        <>
          {childRows[0].VEHModel}({childRows.reduce((prev) => prev + 1, 0)})
        </>
      );
    },
  },
  {
    key: "VEHModelYear",
    name: "Year",
    // groupFormatter({ childRows }) {
    //   return (
    //     <>
    //       {Math.floor(
    //         childRows.reduce(
    //           (prev, { VEHModelYear }) => prev + VEHModelYear,
    //           0
    //         ) / childRows.length
    //       )}
    //     </>
    //   );
    // },
  },
  {
    key: "Variant & Series",
    name: "Variant",
    width: 200,
  },
  {
    key: "VEHColour1",
    name: "Colour",
  },
  {
    key: "VEHOdo",
    name: "Odo",
    formatter({ row }) {
      return (
        <>
          <NumberFormat
            value={row.VEHOdo}
            thousandSeparator={true}
            decimalScale={0}
            displayType="text"
          />
        </>
      );
    },
  },
  {
    key: "Asking Price",
    name: "Asking",
    formatter({ row }) {
      return (
        <>
          <NumberFormat
            value={row["Asking Price"]}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={0}
            displayType="text"
          />
        </>
      );
    },
  },
  {
    key: "branch_desc",
    name: "Branch",
  },
  {
    key: "Age",
    name: "Age",
  },
  {
    key: "VEHStockNumber",
    name: "Stock No",
  },
  {
    key: "CountOfLeads",
    name: "Leads",
    groupFormatter({ childRows }) {
      return (
        <>
          {childRows.reduce((prev, { CountOfLeads }) => prev + CountOfLeads, 0)}
        </>
      );
    },
  },
];

const recentSalesColumns = [
  {
    key: "VEHMake",
    name: "Make",
    groupFormatter({ childRows }) {
      return (
        <>
          {childRows[0].VEHMake}({childRows.reduce((prev) => prev + 1, 0)})
        </>
      );
    },
  },
  {
    key: "VEHModel",
    name: "Model",
    groupFormatter({ childRows }) {
      return (
        <>
          {childRows[0].VEHModel}({childRows.reduce((prev) => prev + 1, 0)})
        </>
      );
    },
  },
  {
    key: "VEHModelYear",
    name: "Year",
    // groupFormatter({ childRows }) {
    //   return (
    //     <>
    //       {Math.floor(
    //         childRows.reduce(
    //           (prev, { VEHModelYear }) => prev + VEHModelYear,
    //           0
    //         ) / childRows.length
    //       )}
    //     </>
    //   );
    // },
  },
  {
    key: "Variant & Series",
    name: "Variant",
    width: 200,
  },
  {
    key: "VEHColour1",
    name: "Colour",
  },
  {
    key: "VEHOdoOut",
    name: "Odo Out",
    formatter({ row }) {
      return (
        <>
          <NumberFormat
            value={row.VEHOdoOut}
            thousandSeparator={true}
            decimalScale={0}
            displayType="text"
          />
        </>
      );
    },
  },
  {
    key: "SalePriceInc",
    name: "Sale Price",
    formatter({ row }) {
      return (
        <>
          <NumberFormat
            value={row.SalePriceInc}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={0}
            displayType="text"
          />
        </>
      );
    },

    groupFormatter({ childRows }) {
      return (
        <>
          <NumberFormat
            value={
              childRows.reduce(
                (prev, { SalePriceInc }) => prev + SalePriceInc,
                0
              ) / childRows.length
            }
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={0}
            displayType="text"
          />
        </>
      );
    },
  },
  {
    key: "Division",
    name: "Branch",
  },
  {
    key: "Sale Date EPOC",
    name: "Sale Date",
    formatter({ row }) {
      return <>{dayjs.unix(row["Sale Date EPOC"]).format("DD-MMM")}</>;
    },
  },
  {
    key: "Stock No",
    name: "Stock No",
  },
  {
    key: "sale_class",
    name: "Sale Class",
  },
  {
    key: "CustName",
    name: "Name",
    width: 200,
    formatter({ row }) {
      return (
        <>{`${row.FirstName === null ? "" : row.FirstName} ${row.LastName}`}</>
      );
    },
  },
  {
    key: "EmailAddress",
    name: "Email",
    width: 200,
  },
  {
    key: "Mobile",
    name: "Mobile",
    width: 200,
  },
  // {
  //   key: "CountOfLeads",
  //   name: "Leads",
  //   groupFormatter({ childRows }) {
  //     return (
  //       <>
  //         {childRows.reduce((prev, { CountOfLeads }) => prev + CountOfLeads, 0)}
  //       </>
  //     );
  //   },
  // },
];

const optionsInstockSummary = [
  {
    value: "VEHMake",
    label: "Make",
  },
  {
    value: "VEHModel",
    label: "Model",
  },
];

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function RecentlySoldComponent() {
  const [photos, setPhotos] = useState([]);
  const [saleType, setSaleType] = useState([]);
  const [queryParams, setQueryParams] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [inStockVehicles, setInStockVehicles] = useState([]);
  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [searchResultsInStockInventory, setSearchResultsInStockInventory] =
    useState([]);
  const [searchResultSalesSummary, setSearchResultSalesSummary] = useState([]);
  const [vehicleInfoObj, setVehicleInfoObj] = useState({
    make: "",
    model: "",
    variant: "",
    odo: "",
    price: "",
  });
  const [isLoadingVinImageRecog, setIsLoadingVinImageRecog] = useState(false);
  const [isLoadingRegoImageRecog, setIsLoadingRegoImageRecog] = useState(false);
  const [vinImageRecogProgress, setVinImageRecogProgress] = useState(0);
  const [modalDetailsShow, setModalDetailsShow] = useState(false);
  const [selectedMake, setSelectedMake] = useState({});
  const [dependentModels, setDependentModels] = useState([]);

  // Datagrid states
  const [selectedRowsISI, setSelectedRowsISI] = useState(() => new Set());
  const [selectedRowsRS, setSelectedRowsRS] = useState(() => new Set());
  const [selectedOptions, setSelectedOptions] = useState([
    optionsInstockSummary[0],
    optionsInstockSummary[1],
  ]);
  const [filteredRowsRS, setFilteredRowsRS] = useState(() => new Set());
  const [expandedGroupIds, setExpandedGroupIds] = useState(() => new Set());
  const [[sortColumnSalesSummary, sortDirectionSalesSummary], setSortSales] =
    useState(["Sale Date EPOC", "DESC"]);
  const [
    [sortColumnInventorySummary, sortDirectionInventorySummary],
    setSortInventory,
  ] = useState(["Age", "DESC"]);

  const groupBy = useMemo(
    () =>
      Array.isArray(selectedOptions)
        ? selectedOptions.map((o) => o.value)
        : undefined,
    [selectedOptions]
  );

  // HOOKS
  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const storage = useStorage();
  const vinImageRef = useRef(null);
  const regoImageRef = useRef(null);
  const [makes, models] = useVEHMakeModel();
  // const { query } = useQuery();

  const numericalASC = (a, b) => {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  };

  function rowKeyGetter(row) {
    return row.id;
  }

  const submitForm = () => {
    // console.log(values);
    // return;

    // setSearchResultsInStockInventory([]);
    setSearchResultSalesSummary([]);

    // var vehicles = db.collectionGroup("inventory-summary");
    var sales = firestore.collectionGroup("salesSummary");
    const daysAgo = dayjs().add(-SALE_WINDOW, "day").unix();

    toast.warning("Searching database");
    sales = sales.where("Sale Date EPOC", ">=", parseInt(daysAgo));

    sales
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.size > 0) {
          // querySnapshot
          //   const vehData = doc.data();
          toast.success(`Found ${querySnapshot.size} sales`);
          const tmpSearchResultSalesSummary = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            // console.log("in data", data);
            tmpSearchResultSalesSummary.push({
              ...data,
              stockNumber: doc.id,
              id: doc.id,
            });
            //   Sort by position
            // tmpSearchResultsInStockInventory.sort(numericalASC);
          });
          // Set in stock array
          return setSearchResultSalesSummary(tmpSearchResultSalesSummary);
          // console.log(`Results`, tmpSearchResultsInStockInventory);
        } else {
          toast.error("No sales found");
          return;
        }
      })
      .then(() => {
        setSaleType(["R", "O", "W"]);
      })
      .catch((e) => {
        console.log(e.message);
        toast.error(e.message);
      });
  };

  // START SORT LOGIC

  const sortedRowsSalesSummary = useMemo(() => {
    if (sortDirectionSalesSummary === "NONE") return searchResultSalesSummary;

    let sortedRows = [...searchResultSalesSummary];

    switch (sortColumnSalesSummary) {
      case "Sale Date EPOC":
        sortedRows = sortedRows.sort((a, b) =>
          a[sortColumnSalesSummary]
            .toString()
            .localeCompare(b[sortColumnSalesSummary])
        );
        break;
      // case "available":
      //   sortedRows = sortedRows.sort((a, b) =>
      //     a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1
      //   );
      //   break;
      // case "id":

      default:
    }

    return sortDirectionSalesSummary === "DESC"
      ? sortedRows.reverse()
      : sortedRows;
  }, [
    searchResultSalesSummary,
    sortDirectionSalesSummary,
    sortColumnSalesSummary,
  ]);

  const handleSortInventory = useCallback((columnKey, direction) => {
    setSortInventory([columnKey, direction]);
  }, []);

  const handleSortSales = useCallback((columnKey, direction) => {
    setSortSales([columnKey, direction]);
  }, []);

  const handleChangeSaleType = (selectedValues) => {
    setSaleType(selectedValues);
  };

  const handleFilterSales = () => {
    const tmpFilterData = searchResultSalesSummary.filter((r) =>
      saleType.includes(r.sale_class)
    );
    setFilteredRowsRS(tmpFilterData);
  };

  // END SORT LOGIC

  const handleModalDetails = () => {
    setModalDetailsShow(!modalDetailsShow);
  };

  // Update vehicle info array when photos array changes
  useEffect(() => {
    // Set vehicle info array
    if (photos.length > 0 && photos[0].vehicleInfo) {
      var vehicleProperties = [];
      const tmpVehicleInfo = photos[0].vehicleInfo;
      setVehicleInfoObj(tmpVehicleInfo);
      for (let prop in tmpVehicleInfo) {
        const tmpProp = { key: prop, value: tmpVehicleInfo[prop] };
        vehicleProperties.push(tmpProp);
      }
      setVehicleInfo(vehicleProperties);
    }
  }, [photos]);

  // Update when make changes to filter dependent dropdown for model
  useEffect(() => {
    // Has a selected make
    if (selectedMake && models.length > 0) {
      const tmpModelFilter = models.filter((model) => {
        return model.MakeCode === selectedMake.MakeCode ? true : false;
      });

      setDependentModels(tmpModelFilter);
    } else {
    }
  }, [JSON.stringify(selectedMake)]);

  // Initial get sales on mount
  useEffect(() => {
    submitForm();
  }, []);

  // Update on change of sale class
  useEffect(() => {
    handleFilterSales();
  }, [saleType]);

  return (
    <>
      <Container>
        <ToggleButtonGroup
          type="checkbox"
          value={saleType}
          onChange={handleChangeSaleType}
        >
          <ToggleButton
            variant={
              saleType.includes("R") ? "outline-dark" : "outline-secondary"
            }
            value={"R"}
          >
            Retail
          </ToggleButton>
          <ToggleButton
            variant={
              saleType.includes("O") ? "outline-dark" : "outline-secondary"
            }
            value={"O"}
          >
            Other
          </ToggleButton>
          <ToggleButton
            variant={
              saleType.includes("W") ? "outline-dark" : "outline-secondary"
            }
            value={"W"}
          >
            Wholesale
          </ToggleButton>
        </ToggleButtonGroup>
        <br />
        <Badge pill variant="primary">
          {`${saleType?.length} sale class${
            saleType?.length > 1 ? "es" : ""
          } selected`}
        </Badge>
      </Container>
      {/* START RECENT SALES SUMMARY TABLE */}
      <Container>
        <br />
        <h5>Recently sold (last {SALE_WINDOW} days)</h5>
        <span style={{ textAlign: "right" }}>
          <Badge pill variant="info">
            {`showing ${filteredRowsRS.length} row${
              filteredRowsRS.length > 1 ? "s" : ""
            } out of ${searchResultSalesSummary.length}`}
          </Badge>
        </span>
        <DataGrid
          rowKeyGetter={rowKeyGetter}
          columns={recentSalesColumns}
          rows={filteredRowsRS}
          selectedRows={selectedRowsRS}
          onSelectedRowsChange={setSelectedRowsRS}
          // groupBy={groupBy}
          emptyRowsRenderer={EmptyRowsRenderer}
          rowGrouper={rowGrouper}
          expandedGroupIds={expandedGroupIds}
          onExpandedGroupIdsChange={setExpandedGroupIds}
          defaultColumnOptions={{ resizable: true }}
          onSort={handleSortSales}
          rowClass={(row) =>
            row.sale_class === "R" ? highlightClassname : undefined
          }
        />
      </Container>
      {/* END RECENT SALES SUMMARY TABLE */}
      {/* START Vehicle details modal */}
      <Modal
        show={modalDetailsShow}
        size="lg"
        onHide={() => setModalDetailsShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Vehicle information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Property</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {vehicleInfo.map((prop, idx) => {
                return (
                  <tr key={idx}>
                    <td>{prop.key}</td>
                    <td>{prop.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      {/* END Vehicle details modal */}
      <ToastContainer />
      <ImageModalComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        src={modalImage}
      />
      <br />
      <Container fluid></Container>
      <PageViewLoggerComponent />
    </>
  );
}

export default RecentlySoldComponent;
