import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  NavLink,
} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NotPublishedAMWComponent from "./NotPublishedAMWComponent";
import NotPublishedD1Component from "./NotPublishedD1Component";
import PageViewLoggerComponent from "../PageViewLoggerComponent";

function NotPublishedIndex() {
  let { path, url } = useRouteMatch();

  return (
    <>
      <Nav varaint="pills" activeKey={url}>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/not-published/amw">
            AMW
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/not-published/duttonone">
            Dutton One
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <hr />
      <Switch>
        <Route exact path={path}>
          <h6>Please select a brand.</h6>
        </Route>
        <Route path={`${path}/amw`}>
          <NotPublishedAMWComponent />
        </Route>
        <Route path={`${path}/duttonone`}>
          <NotPublishedD1Component />
        </Route>
      </Switch>
      <PageViewLoggerComponent />
    </>
  );
}

export default NotPublishedIndex;
