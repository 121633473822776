import { useEffect, useState } from "react";
import { useUser, useFunctions } from "reactfire";
import { ToastContainer, toast } from "react-toastify";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import * as Yup from "yup";
import TextField from "../FreightBooking/TextField";
import InputGroup from "react-bootstrap/InputGroup";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import NumberFormat from "react-number-format";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  Formik,
  ErrorMessage,
  FieldArray,
  useFormikContext,
  useField,
  Field,
} from "formik";
import useFirestoreQuery from "../../hooks/useFirestoreQuery";
import { FaAddressBook, FaPencilAlt, FaPlus } from "react-icons/fa";
import { Card } from "react-bootstrap";
import { useVEHMakeModel } from "../../hooks/useVEHMakeModel";
import { useYearsList } from "../../hooks/useYearsList";
import { FaSearch, FaRegNewspaper, FaInfoCircle } from "react-icons/fa";
import { serverTimestamp } from "firebase/firestore";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

let initialValues = {
  name: "",
  phone: "",
  email: "",
  sfLeadID: "",
  make: "",
  model: "",
  minYear: 0,
  maxYear: 0,
  minPrice: 0,
  maxPrice: 0,
  notes: "",
  status: "open",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  // email: Yup.string().required("Required"),
  make: Yup.string().required("Required"),
  model: Yup.string().required("Required"),

  minYear: Yup.number()
    .required("Required")
    .moreThan(0, "Must not be zero")
    .lessThan(Yup.ref("maxYear", "Must be less than Max Year")),

  maxYear: Yup.number()
    .required("Required")
    .moreThan(0, "Must not be zero")
    .moreThan(Yup.ref("minYear", "Must be greater than Min Year")),

  minPrice: Yup.number()
    .required("Required")
    .lessThan(Yup.ref("maxPrice"), "Must be less than maximum price"),
  maxPrice: Yup.number()
    .required("Required")
    .moreThan(Yup.ref("minPrice"), "Must be greater than minimum price"),
});

const MyConciergeRequests = () => {
  const [carrierList, setCarrierList] = useState([]);
  const [q, setQ] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [showNewRequestModal, setShowNewRequestModal] = useState(false);
  const [showMatchingVehicleModal, setShowMatchingVehicleModal] =
    useState(false);

  const [showUpdateAddressModal, setShowUpdateAddressModal] = useState(false);
  const [selectedMake, setSelectedMake] = useState({});
  const [dependentModels, setDependentModels] = useState([]);
  const [selectedMinYear, setSelectedMinYear] = useState();
  const [filteredMinYearList, setFilteredMinYearList] = useState();
  const [selectedMaxYear, setSelectedMaxYear] = useState();
  const [filteredMaxYearList, setFilteredMaxYearList] = useState([]);
  const [isLoadedFromSF, setIsLoadedFromSF] = useState(false);
  const [isLoadingFromSF, setIsLoadingFromSF] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [currentMatchingVehicles, setCurrentMatchingVehicles] = useState([]);
  const [currentSelectedRequest, setCurrentSelectedRequest] = useState({});
  const [currentSelectedVehicle, setCurrentSelectedVehicle] = useState({});

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const functions = useFunctions();
  const [yearsList] = useYearsList();
  const { data: user } = useUser();

  // Subscribe to Firestore document
  const { data, status, error } = useFirestoreQuery(
    firestore
      .collection("retail-concierge-requests")
      .where("status", "==", "open")
  );

  const handleCloseNewRequestModal = () => setShowNewRequestModal(false);
  const handleShowNewRequestModal = () => setShowNewRequestModal(true);

  const handleCloseMatchingVehicleModal = () =>
    setShowMatchingVehicleModal(false);
  const handleShowMatchingVehicleModal = () =>
    setShowMatchingVehicleModal(true);

  const [makes, models] = useVEHMakeModel();

  const loadDataFromSF = async (currentFormValues) => {
    toast.info("Coming soon");
    return;
    if (currentFormValues.sfLeadID.length < 7) {
      toast.error("Please enter a valid Salesforce ID");
      return;
    }
    setIsLoadingFromSF(true);
    // getLeadsFromVehicleIdInSalesForce
    // Get salesforce leads already assigned to a vehicle by stock id
    var getLeads = functions.httpsCallable("getLeadsFromLeadIdInSalesForce");
    const sId = currentFormValues.sfLeadID;
    const leadData = await getLeads(sId)
      .then((result) => {
        // Read result of the Cloud Function.
        console.log(result);
        if (result.data === null) {
          toast.error(`No lead found in Salesforce`);
          return;
        }

        return result;
      })
      .catch((e) => {
        if (e.code === "internal") {
          toast.error("Lead not found in Salesforce");
        }
        return null;
      });

    if (leadData) {
      const lead = leadData.data;
      const tmpContactData = {
        name: lead.Name,
        phone: lead.Phone,
        email: lead.Email,
      };

      initialValues = { ...currentFormValues, ...tmpContactData };
      setIsLoadedFromSF(true);
      console.log(initialValues);
    }
    setIsLoadingFromSF(false);
  };

  const toggleStatusOfRequest = async (id, status) => {
    const newStatus = status === "open" ? "closed" : "open";
    setIsUpdatingStatus(true);

    await firestore
      .collection("retail-concierge-requests")
      .doc(id)
      .set({ status: newStatus }, { merge: true })
      .then((r) => {
        if (newStatus === "closed" && id === currentSelectedRequest?.id) {
          // clear current selected request
          clearCurrentMatchingVehicle();
        }
        return;
      })
      .catch((e) => {
        toast.error("Cannot modify requests other than your own");
        return;
      });

    setIsUpdatingStatus(false);
  };

  const clearCurrentMatchingVehicle = () => {
    setCurrentSelectedRequest({});
    setCurrentMatchingVehicles([]);
    setCurrentSelectedVehicle({});
  };

  const getMatchingVehiclesList = async (selection) => {
    const id = selection.id;
    await firestore
      .collection("retail-concierge-requests")
      .doc(id)
      .collection("matches")
      .get()
      .then((qS) => {
        if (qS.empty) {
          return null;
        } else {
          const tmpMatchingVehicles = [];
          qS.forEach((v) => {
            tmpMatchingVehicles.push({ id: v.id, ...v.data() });
          });
          setCurrentMatchingVehicles(tmpMatchingVehicles);
          setCurrentSelectedRequest(selection);
        }
      });
  };

  const setCurrentDetailVehicleAndShowModal = (veh) => {
    setCurrentSelectedVehicle(veh);
    handleShowMatchingVehicleModal();
  };

  // const handleCloseUpdateAddressModal = () => setShowUpdateAddressModal(false);
  // const handleShowUpdateAddressModal = () => setShowUpdateAddressModal(true);

  // Update when make changes to filter dependent dropdown for model
  useEffect(() => {
    // Has a selected make
    if (selectedMake && models.length > 0) {
      const tmpModelFilter = models.filter((model) => {
        return model.MakeCode === selectedMake.MakeCode ? true : false;
      });

      setDependentModels(tmpModelFilter);
    } else {
    }
  }, [JSON.stringify(selectedMake)]);

  useEffect(() => {
    // Update Max Year list to be > selected min year
    if (selectedMinYear >= 0) {
      const filteredList = yearsList.filter(
        (y) => parseInt(y.id) > selectedMinYear
      );
      setFilteredMaxYearList(filteredList);
    }
  }, [selectedMinYear, selectedMaxYear]);

  useEffect(() => {
    setFilteredMinYearList(yearsList);
    setFilteredMaxYearList(yearsList);
  }, [yearsList]);

  useEffect(() => {
    // Update Min Year list to be < selected max year
    if (selectedMaxYear >= 0) {
      const filteredList = yearsList.filter(
        (y) => parseInt(y.id) < selectedMaxYear
      );
      setFilteredMinYearList(filteredList);
    }
  }, [selectedMaxYear, selectedMinYear]);

  // //   Load all requests
  useEffect(() => {
    setCarrierList([]);
    setIsLoading(true);
    // var t0 = performance.now();

    let tmpFilter = [];
    if (data?.length > 0) {
      if (q.length > 0) {
        tmpFilter = data?.filter((c) => {
          let foundObj = false;
          const arrKeys = Object.keys(c);
          for (var i = 0; i < arrKeys.length; i++) {
            const f = arrKeys[i];
            if (c[f].length > 0) {
              const arrayValue = c[f].toLowerCase();
              const searchpos = arrayValue.search(q);
              if (searchpos >= 0) {
                foundObj = true;
                break;
              }
            }
          }

          return foundObj;
        });
      } else {
        tmpFilter = data;
      }
      tmpFilter.sort((a, b) => a.name.localeCompare(b.name));
      setCarrierList(tmpFilter);
    }
    // var t1 = performance.now();
    // console.log("Filter  took " + (t1 - t0) + " milliseconds.");

    setIsLoading(false);
  }, [data, q, status]);

  return (
    <>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      {status === "loading" ? "Loading" : error?.message}
      <Container fluid>
        <Row>
          <Col xs={12} md={6}>
            <Alert variant="info">
              <FaInfoCircle /> &nbsp;When a vehicle comes into stock and is
              matched with your request, you will be notified via email
            </Alert>
          </Col>
          <Col>
            <Alert variant="warning">
              <FaInfoCircle /> &nbsp;Requests made by others are also visible
              for now to help create transperancy on demand within our company
              and across business units
            </Alert>
          </Col>
        </Row>

        <Button
          block
          variant="outline-primary"
          onClick={handleShowNewRequestModal}
        >
          <FaPlus />
          &nbsp; Create new request
        </Button>
        <br />
        <Form.Control
          type="text"
          name="q"
          placeholder="Search"
          onChange={(e) => setQ(e.target.value.toLowerCase())}
        ></Form.Control>
        <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              <th>Matched vehicles (in-stock)</th>
              <th>Requested By</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Interested Vehicle Make</th>
              <th>Model</th>
              <th>Min Price</th>
              <th>Max Price</th>
              <th>Status</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {carrierList.map((c, idx) => {
              return (
                <tr
                  key={c.id}
                  style={
                    currentSelectedRequest?.id === c.id
                      ? { backgroundColor: "#D7AEFB" }
                      : null
                  }
                >
                  <td>
                    <Button
                      onClick={() => getMatchingVehiclesList(c)}
                      variant="outline-dark"
                    >
                      <Badge
                        pill
                        bg="danger"
                        style={
                          c.numberOfMatchedVehicles > 0
                            ? { backgroundColor: "#6371de", color: "#FFF" }
                            : null
                        }
                      >
                        {c.numberOfMatchedVehicles}
                      </Badge>
                      &nbsp;
                      <FaRegNewspaper />
                    </Button>
                  </td>
                  <td>{c.createdUserDisplayName}</td>
                  <td>{c.name}</td>
                  <td>{c.phone}</td>
                  <td>{c.email}</td>
                  <td>{c.make}</td>
                  <td>{c.model}</td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      value={c.minPrice}
                      thousandSeparator={true}
                      prefix={`$`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      value={c.maxPrice}
                      thousandSeparator={true}
                      prefix={`$`}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="switch"
                      id={`status-switch-${c.id}`}
                      checked={c.status === "open" ? true : false}
                      onChange={() => {
                        toggleStatusOfRequest(c.id, c.status);
                      }}
                      label={c.status === "open" ? "Open" : "Closed"}
                      disabled={isUpdatingStatus}
                    />
                  </td>
                  <td>{c.notes}</td>

                  {/* <td>{JSON.stringify(c.routes, null, 2)}</td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
      {/* START NEW REQUEST MODAL */}
      <Modal
        show={showNewRequestModal}
        onHide={handleCloseNewRequestModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add new request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            // validateOnChange={true}
            // validateOnBlur={true}
            onSubmit={async (values, { resetForm, errors }) => {
              // values.Address = values.location.address;
              // values.label = values.name;
              values.numberOfMatchedVehicles = 0;
              values.createdBy = user.uid;
              values.createdUserEmail = user.email;
              values.createdUserDisplayName = user.displayName;
              values.createdUserPhone = user.phoneNumber;
              values.createdAt = serverTimestamp();
              // firebaseconst.firestore.FieldValue.serverTimestamp();
              firestore
                .collection("retail-concierge-requests")
                .add(values)
                .then((r) => {
                  resetForm();
                  handleCloseNewRequestModal();
                  toast.success(`New request created.`);
                });
            }}
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              isSubmitting,
              setFieldValue,
              resetForm,
            }) => (
              <Form autocomplete="off">
                <>
                  {/* <pre>
                    <code></code> {JSON.stringify(values, null, 2)}
                  </pre> */}
                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  <Card>
                    <Card.Header>Interested vehicle info</Card.Header>
                    <Card.Body>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Make<sup>*</sup>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Typeahead
                          id="onclear-make"
                          name="make"
                          options={makes}
                          onChange={(selected) => {
                            if (selected.length > 0) {
                              setFieldValue("make", selected[0].label);
                              setSelectedMake(selected[0]);
                            } else if (selected.length === 0) {
                              setFieldValue("make", "");
                              setSelectedMake([]);
                            }
                          }}
                          value={values.make}
                          placeholder="Choose a make..."
                        >
                          {({ onClear, selected }) => (
                            <div className="rbt-aux">
                              {!!selected.length && (
                                <ClearButton onClick={onClear} />
                              )}
                              {!selected.length && (
                                <Spinner animation="grow" size="sm" />
                              )}
                            </div>
                          )}
                        </Typeahead>
                        <ErrorMessage name="make">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </InputGroup>
                      <br />
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon2">
                            Model<sup>*</sup>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Typeahead
                          id="onclear-model"
                          name="model"
                          options={dependentModels}
                          onChange={(selected) => {
                            if (selected.length > 0) {
                              setFieldValue("model", selected[0].label);
                            } else if (selected.length === 0) {
                              setFieldValue("model", "");
                            }
                          }}
                          value={values.model}
                          placeholder="Choose a model..."
                        >
                          {({ onClear, selected }) => (
                            <div className="rbt-aux">
                              {!!selected.length && (
                                <ClearButton onClick={onClear} />
                              )}
                              {!selected.length && (
                                <Spinner animation="grow" size="sm" />
                              )}
                            </div>
                          )}
                        </Typeahead>
                        <ErrorMessage name="model">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </InputGroup>
                      <br />
                      <Row>
                        <Col>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon3">
                                Year (min)<sup>*</sup>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Typeahead
                              id="onclear-minyear"
                              name="minYear"
                              options={filteredMinYearList}
                              // labelKey="label"
                              onChange={(selected) => {
                                if (selected.length > 0) {
                                  setFieldValue(
                                    "minYear",
                                    parseInt(selected[0].label)
                                  );
                                  setSelectedMinYear(
                                    parseInt(selected[0].label)
                                  );
                                } else if (selected.length === 0) {
                                  setFieldValue("minYear", 0);
                                  setSelectedMinYear(0);
                                }
                              }}
                              value={values.minYear}
                              placeholder="select..."
                            >
                              {({ onClear, selected }) => (
                                <div className="rbt-aux">
                                  {!!selected.length && (
                                    <ClearButton onClick={onClear} />
                                  )}
                                  {!selected.length && (
                                    <Spinner animation="grow" size="sm" />
                                  )}
                                </div>
                              )}
                            </Typeahead>
                            <ErrorMessage name="minYear">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </InputGroup>
                        </Col>
                        <Col>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon4">
                                Year (max)<sup>*</sup>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Typeahead
                              id="onclear-maxyear"
                              name="maxYear"
                              options={filteredMaxYearList}
                              // labelKey="label"
                              onChange={(selected) => {
                                if (selected.length > 0) {
                                  setFieldValue(
                                    "maxYear",
                                    parseInt(selected[0].label)
                                  );
                                  setSelectedMaxYear(
                                    parseInt(selected[0].label)
                                  );
                                } else if (selected.length === 0) {
                                  setFieldValue("maxYear", 0);
                                  setSelectedMaxYear(0);
                                }
                              }}
                              value={values.minYear}
                              placeholder="select..."
                            >
                              {({ onClear, selected }) => (
                                <div className="rbt-aux">
                                  {!!selected.length && (
                                    <ClearButton onClick={onClear} />
                                  )}
                                  {!selected.length && (
                                    <Spinner animation="grow" size="sm" />
                                  )}
                                </div>
                              )}
                            </Typeahead>
                            <br />
                            <ErrorMessage name="maxYear">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </InputGroup>
                        </Col>
                      </Row>

                      <br />

                      <Row>
                        <Col>
                          <Form.Group controlId={`form-minPrice`}>
                            <Form.Label>Min Price</Form.Label>
                            <NumberFormat
                              className="form-control"
                              /* This name property is used to access the value of the form element via values.nameOfElement */
                              name={`minPrice`}
                              /* Set onChange to handleChange */
                              onValueChange={(v) => {
                                setFieldValue("minPrice", v.value);
                              }}
                              /* Set onBlur to handleBlur */
                              onBlur={handleBlur}
                              /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                              value={values.minPrice}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                            <ErrorMessage name="minPrice">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId={`form-maxPrice`}>
                            <Form.Label>Max Price</Form.Label>
                            <NumberFormat
                              className="form-control"
                              /* This name property is used to access the value of the form element via values.nameOfElement */
                              name={`maxPrice`}
                              /* Set onChange to handleChange */
                              onValueChange={(v) => {
                                setFieldValue("maxPrice", v.value);
                              }}
                              /* Set onBlur to handleBlur */
                              onBlur={handleBlur}
                              /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                              value={values.maxPrice}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                            <ErrorMessage name="maxPrice">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <br />
                  <Card>
                    <Card.Header>Customer Info</Card.Header>
                    <Card.Body>
                      <Form.Group controlId={`form-sfLeadID`}>
                        <Form.Label>Salesforce ID</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type={"text"}
                            disabled
                            /* This name property is used to access the value of the form element via values.nameOfElement */
                            name="sfLeadID"
                            placeholder="coming soon..."
                            /* Set onChange to handleChange */
                            onChange={handleChange}
                            /* Set onBlur to handleBlur */
                            onBlur={handleBlur}
                            /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                            value={values.sfLeadID}
                            /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
                          />

                          <Button
                            variant="outline-primary"
                            disabled
                            onClick={() => loadDataFromSF(values)}
                          >
                            <FaSearch />
                          </Button>
                        </InputGroup>
                      </Form.Group>

                      <TextField
                        fieldname={`name`}
                        label="Customer name"
                        placeholder="John"
                        type="text"
                        values={values}
                        errors={errors}
                        touched={touched}
                        disabled={isLoadedFromSF}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />

                      <TextField
                        fieldname={`phone`}
                        label="Phone"
                        placeholder="0413 132 123"
                        type="text"
                        values={values}
                        errors={errors}
                        touched={touched}
                        disabled={isLoadedFromSF}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                      <TextField
                        fieldname={`email`}
                        label="Email"
                        placeholder="john@email.com"
                        type="text"
                        values={values}
                        errors={errors}
                        touched={touched}
                        disabled={isLoadedFromSF}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </Card.Body>
                  </Card>

                  <br />
                  <Card>
                    <Card.Header>Other</Card.Header>
                    <Card.Body>
                      <TextField
                        fieldname={`notes`}
                        label="Notes"
                        placeholder=""
                        type="textarea"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </Card.Body>
                  </Card>
                  <br />
                  <ButtonGroup block>
                    <Button
                      variant="outline-danger"
                      type="reset"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                    <Button variant="outline-success" onClick={handleSubmit}>
                      Submit
                      {isSubmitting ? <Spinner animation="grow" /> : null}
                    </Button>
                  </ButtonGroup>
                </>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNewRequestModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* END NEW REQUEST MODAL */}

      {/* START MATCHING VEHICLE PREVIEW MODAL */}

      <Modal
        show={showMatchingVehicleModal}
        onHide={handleCloseMatchingVehicleModal}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Vehicle details - {currentSelectedVehicle.VEHStockNumber}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tbody>
              <tr>
                <td>Description</td>
                <td>{`${currentSelectedVehicle.VEHModelYear} ${currentSelectedVehicle.VEHMake} ${currentSelectedVehicle.VEHModel}`}</td>
              </tr>
              <tr>
                <td>Variant</td>
                <td>{currentSelectedVehicle["Variant & Series"]}</td>
              </tr>

              <tr>
                <td>Rego</td>
                <td>{currentSelectedVehicle.VEHRegn}</td>
              </tr>

              <tr>
                <td>Colour</td>
                <td>{currentSelectedVehicle.VEHColour1}</td>
              </tr>

              <tr>
                <td>Odo</td>
                <td>{currentSelectedVehicle.VEHOdo}</td>
              </tr>

              <tr>
                <td>Current Status</td>
                <td>{currentSelectedVehicle.CurrentStatus}</td>
              </tr>

              <tr>
                <td>Asking Price</td>
                <td>
                  <NumberFormat
                    displayType="text"
                    value={currentSelectedVehicle["Asking Price"]}
                    thousandSeparator={true}
                    prefix={`$`}
                  />
                </td>
              </tr>

              <tr>
                <td>Branch</td>
                <td>{currentSelectedVehicle.branch_desc}</td>
              </tr>

              <tr>
                <td>Yard</td>
                <td>{currentSelectedVehicle.VEHYard}</td>
              </tr>

              <tr>
                <td>Buyer</td>
                <td>{currentSelectedVehicle.Buyer}</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseMatchingVehicleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* END MATCHING VEHICLE PREVIEW CONTAINER */}

      <br />
      {currentSelectedRequest?.id?.length > 0 ? (
        <Container>
          <Card>
            <Card.Header>
              Preview of matched vehicles for {currentSelectedRequest?.name}
              <Button
                onClick={clearCurrentMatchingVehicle}
                variant="outline"
                className="close"
              >
                x
              </Button>
            </Card.Header>
            <Card.Body>
              <Table fluid responsive striped hover>
                <thead>
                  <tr>
                    <th>Stock #</th>
                    <th>Vehicle</th>
                    <th>Rego</th>
                    <th>Colour</th>
                    <th>Odo</th>
                    <th>Status</th>
                    <th>Asking price</th>
                    <th>Branch</th>
                  </tr>
                </thead>
                <tbody>
                  {currentMatchingVehicles.map((mv) => {
                    return (
                      <tr>
                        <td>
                          <Button
                            onClick={() =>
                              setCurrentDetailVehicleAndShowModal(mv)
                            }
                          >
                            {mv.VEHStockNumber}
                          </Button>
                        </td>
                        <td>{`${mv.VEHModelYear} ${mv.VEHMake} ${mv.VEHModel}`}</td>
                        <td>{mv.VEHRegn}</td>
                        <td>{mv.VEHColour1}</td>
                        <td>{mv.VEHOdo}</td>
                        <td>{mv.CurrentStatus}</td>
                        <td>
                          <NumberFormat
                            displayType="text"
                            value={mv["Asking Price"]}
                            thousandSeparator={true}
                            prefix={`$`}
                          />
                        </td>
                        <td>{mv.branch_desc}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Container>
      ) : null}
      <ToastContainer />
    </>
  );
};

export default MyConciergeRequests;
