import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Container,
  Table,
  Card,
  Spinner,
  Alert,
  ButtonGroup,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";
import { useFunctions, useUser } from "reactfire";
import { useRecentlySoldCars } from "../../hooks/useRecentlySoldCars";

import copy from "copy-to-clipboard";
import { HiClipboardCheck } from "react-icons/hi";
import {
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  CartesianGrid,
  ReferenceLine,
} from "recharts";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import NumberFormat from "react-number-format";
import { httpsCallable } from "firebase/functions";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// const makeModels = require("../../assets/json/mm.json");
const currentYear = dayjs().year();
const yearsToSearch = [
  currentYear - 2,
  currentYear - 5,
  currentYear - 8,
  // currentYear - 11,
];

dayjs.extend(require("dayjs/plugin/relativeTime"));

const TARGET_DATA_POINTS_PER_MINUTE = 150;
const ROWS = 10;
const COLS = 5;

const AG2DGEmailMap = [
  {
    agEmail: "garrets-salon.04@icloud.com",
    dgEmail: "tharushi@duttonlabs.com.au",
    displayName: "Tharushi Thilakarathne",
    uid: "OYvEUH5nr1dFrTiPWGTvCnI0AMs2",
  },
  {
    agEmail: "minimal.travel0u@icloud.com",
    dgEmail: "dulanjali@duttonlabs.com.au",
    displayName: "Dulanjali Withanage",
    uid: "dIXN06w1LhVz6fo4QXFrOzhJncE3",
  },
  {
    agEmail: "hubbubs.grandee-0w@icloud.com",
    dgEmail: "hussain@duttonlabs.com.au",
    displayName: "Hussain Khaleel",
    uid: "OVSjg2oMBjNN1tRn1B9vBqAekPc2",
  },
];

const headers = {
  year: "Year",
  badge: "Badge",
  seller: "Seller",
  state: "State",
  colour: "Colour",
  dap: "DAP",
  egc: "EGC",
  kms: "Kms",
  delisted: "Delisted",
  watched: "Watched",
  leadratio: "Lead Ratio",
  programs: "Programs",
  tags: "Tags",
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const copyToClipboard = (v) => {
  copy(v);
};

const DailyActions = ({ completedSearches }) => {
  return (
    <Container
      style={{ backgroundColor: "#D6DBDF", borderRadius: 5, marginBottom: 15 }}
    >
      <Row>
        <Col>Searches completed for: {dayjs().format("DD-MM-YYYY")}</Col>
      </Row>
      <Row>
        <Table>
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Make</th>
              <th>Model</th>
              <th>Badge</th>
              <th>Year</th>
              <th>Items loaded</th>
            </tr>
          </thead>
          <tbody>
            {completedSearches?.map((vehicle, idx) => {
              const {
                make,
                model,
                year,
                competitiveSetArray,
                timestamp,
                badge,
              } = vehicle;
              return (
                <tr key={idx}>
                  <td>{dayjs.unix(timestamp).fromNow()}</td>
                  <td>{make}</td>
                  <td>{model}</td>
                  <td>{badge}</td>
                  <td>{year}</td>
                  <td>{competitiveSetArray}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};

const ShowListenerData = ({ data }) => {
  const getSearchResultsText = () => {
    if (data.length === 0) {
      return "No results received";
    }
    const startingAccumulator =
      data[0].totalCompetitiveSetResults > data[0].pageSize ? 0 : 0;
    return (
      data.reduce((acc, cur) => {
        return acc + cur.competitiveSetArray;
      }, startingAccumulator) +
      " out of " +
      data[0]?.totalCompetitiveSetResults
    );
  };

  return (
    <>
      <Row>
        <Col>
          <b>Search results received</b>
        </Col>
        <Col>{getSearchResultsText()}</Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>Make</th>
                <th>Model</th>
                <th>Year</th>
                <th>Total</th>
                <th>Sub total</th>
                <th>Current page</th>
                {/* <th>Data received</th> */}
              </tr>
            </thead>
            <tbody>
              {data?.map((log, idx) => {
                return (
                  <tr key={idx}>
                    <td>{log.make}</td>
                    <td>{log.model}</td>
                    <td>{log.year}</td>
                    <td>{log.totalCompetitiveSetResults}</td>
                    <td>{log.competitiveSetArray}</td>
                    <td>{log.pageNum}</td>
                    {/* <td> */}
                    {/* {dayjs.unix(log.timestamp).format("DD-MM-YYYY HH:mm:ss")} */}
                    {/* </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

const BuyingDataComponent = () => {
  const [src, setSrc] = useState("");
  const [output, setOutput] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStage, setStage] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [countArray, setCountArray] = useState({});
  const [message, setMessage] = useState("");
  const [vehiclesToSearch, setVehiclesToSearch] = useState([]);
  const [completedSearches, setCompletedSearches] = useState([]);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [currentTicketListenerData, setCurrentTicketListenerData] =
    useState(null);
  const [
    graphicalDataOfCompletedSearches,
    setGraphicalDataOfCompletedSearches,
  ] = useState([]);
  const [
    graphicalDataOfAverageCompletedSearches,
    setGraphicalDataOfAverageCompletedSearches,
  ] = useState(0);
  const [
    graphicalDataOfTotalCompletedSearches,
    setGraphicalDataOfTotalCompletedSearches,
  ] = useState(0);

  const [lastReceivedSearch, setLastReceivedSearch] = useState(null);
  const [groupedMakeModelSearchHistory, setGroupedMakeModelSearchHistory] =
    useState(null);

  const [showNextTicketCard, setShowNextTicketCard] = useState(false);
  const [timeFrameOfGraph, setTimeFramOfGraph] = useState(null);
  const [perfGraphDateSubtract, setPerfGraphDateSubtract] = useState(0);

  const handleCloseNextTicketCard = () => setShowNextTicketCard(false);
  const handleShowNextTicketCard = () => setShowNextTicketCard(true);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const functions = useFunctions();
  const user = useUser();
  const windowDimensions = useWindowDimensions();
  console.log(windowDimensions);

  const handleGetNextTicket = async () => {
    setIsLoading(true);
    setCurrentTicket(null);
    // Randomise the order of the vehicles to search
    // Create list of cars to search
    const vehiclesToSearch = [];

    // Call getNextSearchResultFromMakeModelYearSearchList firebase function

    const getNextSearchResultFromMakeModelYearSearchList = httpsCallable(
      functions,
      "getNextSearchResultFromMakeModelYearSearchList"
    );
    // const getNextSearchResultFromMakeModelYearSearchList = firebase
    //   .functions()
    //   .httpsCallable("getNextSearchResultFromMakeModelYearSearchList");
    await getNextSearchResultFromMakeModelYearSearchList().then((r) => {
      // console.log(r);
      if (r.data?.length === 0) {
        toast.warning("No ticket found. Please try again later.");
        return;
      }
      const carToSearch = `${r?.data?.searchYear} ${r?.data?.MakeDescription} ${r?.data?.Description}`;
      const payload = {
        carToSearch,
        VEHModelYear: r?.data?.searchYear,
        VEHMake: r?.data?.MakeDescription,
        VEHModel: r?.data?.Description,
      };
      setCurrentTicket(payload);
      // console.log("currentTickets", payload);
    });
    handleShowNextTicketCard();
    setIsLoading(false);

    // const completedSearchesCopy = [...completedSearches];
    // completedSearchesCopy.push(payload);
    // setCompletedSearches(completedSearchesCopy);
  };

  // const generateFullListOfMakeModelYears = () => {
  //   const makeModelYears = [];
  //   makeModels.forEach((type) => {
  //     yearsToSearch.forEach((year) => {
  //       if (year >= type.StartYear && year <= type.LatestYear) {
  //         makeModelYears.push({ ...type, searchYear: year });
  //       }
  //     });
  //   });
  //   console.log("makeModelYears", makeModelYears);
  // };

  const [topProfitDataSet, inventoryImages, inventoryData] =
    useRecentlySoldCars({
      branches: [
        "AMW - MELBOURNE",
        "AMW - FAIRFIELD",
        "AMW - WESTGATE",
        "AMW - BRISBANE",
        "AMW - ALBION",
        "AMW - PLYMPTON",
        "AMW - DANDENONG",
      ],
    });

  // Handle date subtract for performance graph
  const handleDateSubtractDecrement = () => {
    setPerfGraphDateSubtract(perfGraphDateSubtract + 1);
  };

  const handleDateSubtractIncrement = () => {
    setPerfGraphDateSubtract(perfGraphDateSubtract - 1);
  };

  // Convert completed searches to graph format data set
  const convertCompletedSearchesToGraphData = (d) => {
    // Group by time stamp minute
    const groupedByMinute = d.reduce((acc, cur) => {
      const timestamp = dayjs.unix(cur.timestamp).format("DD MMM HH:mm");
      if (typeof acc[timestamp] === "undefined") {
        acc[timestamp] = [];
      }
      acc[timestamp].push(cur);
      return acc;
    }, {});

    // Fill in missing minutes
    // const firstMinute = Object.keys(groupedByMinute)[0];
    // Start of day
    // const firstMinute = dayjs().startOf("day").format("DD MMM HH:mm");

    // Start of day 8am
    const firstMinute = dayjs()
      // .startOf("day")
      .subtract(1, "hour")
      .format("DD MMM HH:mm");

    const lastMinute = dayjs().format("DD MMM HH:mm");
    const firstMinuteUnix = dayjs(firstMinute, "DD MMM HH:mm").unix();
    const lastMinuteUnix = dayjs(lastMinute, "DD MMM HH:mm").unix();

    setTimeFramOfGraph({
      firstMinute,
      lastMinute,
    });

    for (let i = firstMinuteUnix; i <= lastMinuteUnix; i += 60) {
      const minute = dayjs.unix(i).format("DD MMM HH:mm");
      if (typeof groupedByMinute[minute] === "undefined") {
        groupedByMinute[minute] = [];
      }
    }

    const graphData = Object.keys(groupedByMinute).map((key) => {
      const data = groupedByMinute[key];
      const totalCompetitiveSetResults = data.reduce((acc, cur) => {
        return acc + cur.totalCompetitiveSetResults;
      }, 0);
      const competitiveSetArray = data.reduce((acc, cur) => {
        return acc + cur.competitiveSetArray;
      }, 0);
      return {
        xa: key,
        "Data points": competitiveSetArray,
        totalCompetitiveSetResults,
      };
    });

    // Order by timestamp
    graphData.sort((a, b) => {
      const aUnix = dayjs(a.xa, "DD MMM HH:mm").unix();
      const bUnix = dayjs(b.xa, "DD MMM HH:mm").unix();
      return aUnix - bUnix;
    });

    // Drop data before firstMinute
    for (let i = 0; i < graphData.length; i++) {
      const data = graphData[i];
      const dataUnix = dayjs(data.xa, "DD MMM HH:mm").unix();
      if (dataUnix < firstMinuteUnix) {
        graphData.splice(i, 1);
        i--;
      }
    }

    // Get total data points
    const totalDataPoints = graphData.reduce((acc, cur) => {
      return acc + cur["Data points"];
    }, 0);

    // Get average of completed searches
    const averageCompletedSearches =
      graphData.reduce((acc, cur) => {
        return acc + cur["Data points"];
      }, 0) / graphData.length;

    setGraphicalDataOfAverageCompletedSearches(averageCompletedSearches);
    setGraphicalDataOfTotalCompletedSearches(totalDataPoints);

    setGraphicalDataOfCompletedSearches(graphData);
  };

  useEffect(() => {}, []);

  // Get log of completed searches
  useEffect(() => {
    if (user) {
      // const dayMinusOne = dayjs().subtract(1, "day").unix();
      let query = null;
      console.log("user", user);
      if (user.data.email === "j.jayaram@duttongroup.com.au") {
        query = firestore
          .collection("competitive-set-ag-logs")
          // .where("userEmail", "==", user.data.email)
          .where(
            "timestamp",
            ">",
            dayjs().startOf("day").subtract(perfGraphDateSubtract, "day").unix()
          )
          .where(
            "timestamp",
            "<",
            dayjs().endOf("day").subtract(perfGraphDateSubtract, "day").unix()
          )
          // .where("timestamp", ">", 1705385048)

          .where("logType", "==", "user-activity");
      } else {
        query = firestore
          .collection("competitive-set-ag-logs")
          .where("userEmail", "==", user.data.email)
          .where("timestamp", ">", dayjs().startOf("day").unix())
          // .where("timestamp", ">", 1705385048)

          .where("logType", "==", "user-activity");
      }
      const unsubscribe = query.onSnapshot((querySnapshot) => {
        const d = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          d.push(data);
        });
        setCompletedSearches(d);
        convertCompletedSearchesToGraphData(d);
        // Last received search
        // Sort by timestamp
        const dTmp = [...d];
        console.log(dTmp);
        dTmp.sort((a, b) => {
          return a.timestamp - b.timestamp;
        });
        const lastSearch = dTmp[0];
        setLastReceivedSearch(lastSearch);

        // Group by make model
        const tmpGroupedMakeModelSearchHistory = d.reduce((acc, cur) => {
          const key = `${cur.make} ${cur.model} ${cur.year}`;
          if (typeof acc[key] === "undefined") {
            acc[key] = 0;
          }
          acc[key] += cur.competitiveSetArray;
          return acc;
        }, {});
        setGroupedMakeModelSearchHistory(tmpGroupedMakeModelSearchHistory);
      });

      return () => unsubscribe();
    }
  }, [perfGraphDateSubtract]);

  useEffect(() => {
    if (currentTicket) {
      const tmpListener = completedSearches.filter((vlog) => {
        if (
          currentTicket.VEHMake === vlog.make &&
          currentTicket.VEHModel === vlog.model &&
          parseInt(currentTicket.VEHModelYear) === parseInt(vlog.year)
        ) {
          return true;
        }
      });
      console.log("tmpListener", tmpListener);
      setCurrentTicketListenerData(tmpListener);
    }
  }, [JSON.stringify(completedSearches), currentTicket]);

  // useEffect(() => {
  //   generateFullListOfMakeModelYears();
  // }, []);

  return (
    <Container>
      <ToastContainer />
      <Row>{/* <DailyActions completedSearches={completedSearches} /> */}</Row>

      <Row>
        <Col>
          <b>
            Performance graph - {dayjs().startOf("day").format("DD-MM-YYYY")}
          </b>
          {user && user.data.email === "j.jayaram@duttongroup.com.au" && (
            <Alert variant="info">
              <b>Admin view</b>
              {/* Left and right arrow to navigate date */}
              <p>
                Scroll dates{" "}
                <ButtonGroup>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={handleDateSubtractDecrement}
                  >
                    {"<"}
                  </Button>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={handleDateSubtractIncrement}
                  >
                    {">"}
                  </Button>
                </ButtonGroup>{" "}
                {dayjs()
                  .startOf("day")
                  .subtract(perfGraphDateSubtract, "day")
                  .format("ddd DD-MM-YYYY")}
              </p>
            </Alert>
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>User</th>
                <th>Data points</th>
              </tr>
            </thead>
            <tbody>
              {AG2DGEmailMap.map((user, idx) => {
                const userLogs = completedSearches?.filter((log) => {
                  return log.userEmail === user.agEmail;
                });
                const totalDataPoints = userLogs.reduce((acc, cur) => {
                  return acc + cur.competitiveSetArray;
                }, 0);
                return (
                  <tr key={idx}>
                    <td>{user.displayName}</td>
                    <td>{totalDataPoints}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>Make / Model</th>
                <th>Data points</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(groupedMakeModelSearchHistory || {}).map(
                (key, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{key}</td>
                      <td>{groupedMakeModelSearchHistory[key]}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Col>
          <BarChart
            width={Math.min(windowDimensions?.width * 0.7, 1000)}
            height={250}
            data={graphicalDataOfCompletedSearches}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="xa" />
            <YAxis />
            <ReferenceLine
              y={graphicalDataOfAverageCompletedSearches}
              stroke="red"
              // label="Average per minute"
              isFront={true}
              strokeDasharray="3 3"
            />
            <ReferenceLine
              y={TARGET_DATA_POINTS_PER_MINUTE}
              stroke="green"
              // label="Average per minute"
              isFront={true}
              strokeDasharray="3 3"
            />
            <Tooltip />
            {/* <Legend /> */}
            <Bar dataKey="Data points" fill="#8884d8" />
          </BarChart>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            color:
              graphicalDataOfAverageCompletedSearches <
              TARGET_DATA_POINTS_PER_MINUTE
                ? "red"
                : "green",
          }}
        >
          Average data points per minute:{" "}
          <b>{Math.floor(graphicalDataOfAverageCompletedSearches)}</b>
        </Col>
      </Row>
      <Row>
        <Col>
          Target data points per minute:{" "}
          <b> {Math.floor(TARGET_DATA_POINTS_PER_MINUTE)}</b>
        </Col>
      </Row>

      <Row>
        <Col>
          Total data points between{" "}
          {`${timeFrameOfGraph?.firstMinute} and ${timeFrameOfGraph?.lastMinute}`}
          :{" "}
          <b>
            <NumberFormat
              value={graphicalDataOfTotalCompletedSearches}
              displayType={"text"}
              thousandSeparator={true}
            />
          </b>
        </Col>
      </Row>

      <Row style={{ marginTop: 15 }}>
        <Col>
          <Alert variant="info">
            Last received result:{" "}
            {`${dayjs
              .unix(lastReceivedSearch?.timestamp)
              .format("DD MMM HH:mm")}`}{" "}
            <br />
            <i>{`${lastReceivedSearch?.make} ${lastReceivedSearch?.model} ${lastReceivedSearch?.year}`}</i>
            <br />
            <b>{`Received ${lastReceivedSearch?.competitiveSetArray} data points`}</b>
          </Alert>
        </Col>
      </Row>
      <Card
        size="xl"
        show={showNextTicketCard}
        onHide={handleCloseNextTicketCard}
        style={{ marginTop: 15 }}
      >
        <Card.Header>
          <Card.Title>
            <Row>
              <Col>
                {currentTicket
                  ? `Ticket: ${currentTicket?.VEHMake} ${currentTicket?.VEHModel}`
                  : "No ticket selected"}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  style={{ float: "right" }}
                  onClick={handleGetNextTicket}
                  variant="outline-success"
                >
                  {isLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Pick up next ticket
                </Button>
              </Col>
            </Row>
          </Card.Title>
        </Card.Header>
        <Card.Body hidden={!currentTicket}>
          Search for the following vehicle and enter the results below:
          <br />
          <Table>
            <thead>
              <tr>
                <th>Make</th>
                <th>Model</th>
                <th>Year</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{currentTicket?.VEHMake}</td>
                <td>{currentTicket?.VEHModel}</td>
                <td>{currentTicket?.VEHModelYear}</td>
              </tr>
              <tr>
                <td colSpan={3}></td>
              </tr>
            </tbody>
          </Table>
          <Row>
            <Col style={{ textAlign: "left" }}>
              Instructions:
              <br />
              <ul>
                <li>
                  Go to Firefox and search for a{" "}
                  {`${currentTicket?.VEHMake} ${currentTicket?.VEHModel} ${currentTicket?.VEHModelYear}`}{" "}
                  <Button
                    style={{
                      backgroundColor: "#138D75",
                      color: "white",
                    }}
                    onClick={() =>
                      copyToClipboard(
                        `${currentTicket?.VEHMake} ${currentTicket?.VEHModel}`
                      )
                    }
                    size="sm"
                    variant="outline-dark"
                  >
                    Click here to copy search term to clipboard
                    <HiClipboardCheck size={20} />
                  </Button>
                </li>
                <li>
                  Ensure that you have set to show a{" "}
                  <b>maximum of 50 results per page</b>
                </li>
                <li>
                  Set the following filters using the 'Filters button'
                  <ul>
                    <li>Australia wide</li>
                    <li>Radius 0km</li>
                    <li>Maximum delisted days: "365 days"</li>
                    <li>Enable "Include surrounding years" switch</li>
                    <li>Wait for green light</li>
                    <li>
                      Go to next page until you have reached the last page
                    </li>
                  </ul>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              {currentTicketListenerData === null ||
              typeof currentTicketListenerData === "undefined" ? (
                <>
                  Listening for data...
                  <Spinner animation="border" role="status"></Spinner>
                </>
              ) : (
                <ShowListenerData data={currentTicketListenerData || {}} />
              )}
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer hidden={!currentTicket}>
          <Button variant="outline-success" onClick={handleGetNextTicket}>
            Get Next Ticket
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default BuyingDataComponent;
