import { useFunctions, useUser } from "reactfire";
import { groupBy as rowGrouper } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useLayoutEffect,
  useEffect,
} from "react";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import {
  Card,
  OverlayTrigger,
  Popover,
  ProgressBar,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import BackdropComponent from "../BackdropComponent";
import { AMWRetailKPITargets } from "./AMWRetailKPITargets";
import { BiDownvote, BiUpvote } from "react-icons/bi";
import dsimg from "../../assets/images/ds_1.png";
import dsimg_02 from "../../assets/images/ds_1__2.png";
import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
} from "recharts";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { httpsCallable } from "firebase/functions";

const styles = {
  card: {
    fontSize: 14,
    width: "100%",
    marginBottom: 25,
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
  },
  toggleButton: {
    borderColor: "#D2B4DE",
    color: "black",
    maxWidth: 250,
    // backgroundColor: "#D2B4DE",
  },
  toggleButtonGroup: {
    width: "100%",
    marginBottom: 10,
    justifyContent: "center",
  },
  cardHeader: { backgroundColor: "#5DADE2", fontWeight: "bold" },
  radialBar: {
    top: 0,
    left: 0,
    transform: "translate(10%, 0)",
    lineHeight: "15px",
  },
};

const ringsColours = {
  mtd_target: "#991ad6",
  actual: "#201c4f",
  month_target: "#FFF",
};

const numericalASCProfit = (a, b) => {
  if (a.TrueProfit < b.TrueProfit) {
    return -1;
  }
  if (a.TrueProfit > b.TrueProfit) {
    return 1;
  }
  return 0;
};

const sortDESCDate = (a, b) => {
  if (a["Sale Date EPOC"] < b["Sale Date EPOC"]) {
    return 1;
  }
  if (a["Sale Date EPOC"] > b["Sale Date EPOC"]) {
    return -1;
  }
  return 0;
};

const sortASCDivision = (a, b) => {
  if (a["Division"] > b["Division"]) {
    return 1;
  }
  if (a["Division"] < b["Division"]) {
    return -1;
  }
  return 0;
};

const brands = {
  AMW: [
    "AMW - MELBOURNE",
    "AMW - FAIRFIELD",
    "AMW - WESTGATE",
    "AMW - BRISBANE",
    "AMW - ALBION",
    "AMW - PLYMPTON",
    "AMW - DANDENONG",
    "AMW - MOORABBIN",
  ],
};

// Site manager names
const siteManagersAMW = {
  "AMW - PLYMPTON": "Sebastian Harris",
  "AMW - DANDENONG": "Manan Sood",
  "AMW - MOORABBIN": "Sohan Ekbote",
};

const salesForceSiteNameLookup = [
  {
    ddpName: "AMW - MELBOURNE",
    salesForceName: "Automegawarehouse",
  },
  {
    ddpName: "AMW - FAIRFIELD",
    salesForceName: "AMW_Fairfield",
  },
  {
    ddpName: "AMW - WESTGATE",
    salesForceName: "AMW_Westgate",
  },
  {
    ddpName: "AMW - ALBION",
    salesForceName: "AMW_Albion",
  },
  {
    ddpName: "AMW - PLYMPTON",
    salesForceName: "AMW_Plympton",
  },
  {
    ddpName: "AMW - DANDENONG",
    salesForceName: "AMW_Dandenong",
  },
  {
    ddpName: "AMW - MOORABBIN",
    salesForceName: "AMW_Moorabbin",
  },
];

const defaultReatilKpiMetrics = {
  totalSaleUnits: 0,
  totalSaleDollars: 0,
  leadConversionPercentage: 17,
  totalFinancePenetrationPercentage: 30,
  totalInsurancePenetrationPercentage: 0,
  totalWarrantyPenetrationPercentage: 35,
  totalGrossProfitDollars: 0,
  totalGrossProfitPercentage: 0,
  totalTrueProfitDollars: 0,
  totalTrueProfitPercentage: 0,
  vehiclesUnder90DaysOldPercentage: 10,
};

const defaultRetailKpis = [
  {
    name: "Site manager",
    id: "siteManager",
    units: "name",
    active: false,
  },
  {
    name: "*Total True Profit $",
    id: "totalTrueProfitDollars",
    units: "$",
    active: true,
  },
  {
    name: "Total Retail Delivered",
    id: "totalSaleUnits",
    units: "units",
    active: true,
  },
  {
    name: "Total Sales Dollars",
    id: "totalSaleDollars",
    units: "$",
    active: false,
  },
  {
    name: "Average Lead Conversion %",
    id: "averageLeadConversionPercentage",
    units: "%",
    active: false,
  },
  {
    name: "Total Finance Pen Units",
    id: "totalFinancePenetrationUnits",
    units: "units",
    active: false,
  },
  {
    name: "Total Finance Pen %",
    id: "totalFinancePenetrationPercentage",
    units: "%",
    active: true,
  },

  {
    name: "Total Insurance Pen %",
    id: "totalInsurancePenetrationPercentage",
    units: "%",
    active: false,
  },
  {
    name: "Total Warranty Pen Units",
    id: "totalWarrantyPenetrationUnits",
    units: "units",
    active: false,
  },
  {
    name: "Total Warranty Pen %",
    id: "totalWarrantyPenetrationPercentage",
    units: "%",
    active: true,
  },
  {
    name: "Total Gross Profit $",
    id: "totalGrossProfitDollars",
    units: "$",
    active: false,
  },
  {
    name: "Total Gross Profit %",
    id: "totalGrossProfitPercentage",
    units: "%",
    active: false,
  },

  {
    name: "Total True Profit %",
    id: "totalTrueProfitPercentage",
    units: "%",
    active: false,
  },
  {
    name: "Vehicles Under 90 Days Old %",
    id: "vehiclesUnder90DaysOldPercentage",
    units: "%",
    active: false,
  },
  {
    name: "Lead Conversion %",
    id: "leadConversionPercentage",
    units: "%",
    active: true,
  },
  {
    name: "Aged stock over 90 days %",
    id: "agedStockOver90Days",
    units: "%",
    active: false,
  },
];

const ageBucketsTemplate = [
  {
    bucketName: "0-30",
    bucketMax: 30,
    bucketMin: 0,
    count: 0,
    percentageOfTotal: 0,
  },
  {
    bucketName: "31-60",
    bucketMax: 60,
    bucketMin: 31,
    count: 0,
    percentageOfTotal: 0,
  },
  {
    bucketName: "61-90",
    bucketMax: 90,
    bucketMin: 61,
    count: 0,
    percentageOfTotal: 0,
  },
  {
    bucketName: "91+",
    bucketMax: 99999,
    bucketMin: 91,
    count: 0,
    percentageOfTotal: 0,
  },
  {
    bucketName: "All",
    bucketMax: 99999,
    bucketMin: 0,
    count: 0,
    percentageOfTotal: 100,
  },
];

// Date definitions
const startOfMonthUnix = dayjs().startOf("month").unix();
const endOfMonthUnix = dayjs().endOf("month").unix();
const startOfMonthReadable = dayjs().startOf("month").format("DD/MM/YYYY");
const endOfMonthReadable = dayjs().endOf("month").format("DD/MM/YYYY");
// Start of last month unix
const startOfLastMonthUnix = dayjs().add(-1, "month").startOf("month").unix();
// End of last month unix
const endOfLastMonthUnix = dayjs().add(-1, "month").endOf("month").unix();
// Start of last month readable
const startOfLastMonthReadable = dayjs()
  .add(-1, "month")
  .startOf("month")
  .format("DD/MM/YYYY");
// End of last month readable
const endOfLastMonthReadable = dayjs()
  .add(-1, "month")
  .endOf("month")
  .format("DD/MM/YYYY");
// Start of last month readable month on month day

// 9am of current day
const lastRefreshedTimeStamp = dayjs()
  .startOf("day")
  .add(9, "hour")
  .format("DD/MM/YYYY HH:mm");

const AllDivisions = "AMW - All";

// Today in redable format
const todayReadable = dayjs().format("DD MMM");
// Last month in redable format
const lastMonthReadable = dayjs().add(-1, "month").format("MMM");
// Last month readable month on month day
const lastMonthReadableMonthOnMonthDay = dayjs()
  .add(-1, "month")
  .format("DD MMM");

// Last month unix month on month day
const lastMonthUnixMonthOnMonthDay = dayjs().add(-1, "month").unix();

const RetailSalesKPIComponent = ({ brand }) => {
  const [topProfitDataSet, setTopProfitDataSet] = useState([]);
  const [inventoryImages, setInventoryImages] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [topVolumeDataSet, setTopVolumeDataSet] = useState([]);
  const [salesSummaryData, setSalesSummaryData] = useState([]);
  const [salesSummaryLastMonthData, setSalesSummaryLastMonthData] = useState(
    []
  );
  const [
    salesSummaryLastMonthDataAllMonth,
    setSalesSummaryLastMonthDataAllMonth,
  ] = useState([]);

  const [salesSummaryGraphData, setSalesSummaryGraphData] = useState([]);
  const [ringsData, setRingsData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [retailKpi, setRetailKpi] = useState({});
  const [retailKpiLastMonthMTD, setRetailKpiLastMonthMTD] = useState({});
  const [branchFilter, setBranchFilter] = useState(AllDivisions);
  const [branchFilterShort, setBranchFilterShort] = useState("All");
  const [leadsByDivision, setLeadsByDivision] = useState({});
  const [leadsByDivisionLastMonthMTD, setLeadsByDivisionLastMonthMTD] =
    useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLeads, setIsLoadingLeads] = useState(false);

  // STATE
  const [toggleRange, setToggleRange] = React.useState(false);
  const [range, setRange] = React.useState([
    dayjs().add(-1, "day").format("YYYY-MM-DD"),
    dayjs().format("YYYY-MM-DD"),
  ]);
  const [dataSet, setDataSet] = React.useState([]);
  const [groupedData, setGroupedData] = React.useState([]);

  const allDivisionsInSelectedBrand = brands[brand] || [];
  allDivisionsInSelectedBrand.sort();

  // REFS

  const progressBarCurrent = useRef(new Array());
  const progressBarRemaining = useRef(new Array());
  const progressBarExpectedForMTD = useRef(new Array());
  const toggleButtonRefArray = useMemo(
    () => allDivisionsInSelectedBrand.map(() => React.createRef()),
    []
  );

  // Hooks
  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const functions = useFunctions();
  const user = useUser();

  const calculateAgeBuckets = (inventoryData, divisionFilter) => {
    const tmpBuckets = [...ageBucketsTemplate];
    let d = [];
    if (divisionFilter === null) {
      d = [...inventoryData];
    } else {
      d = inventoryData.filter((d) => {
        const currentBranch = d?.Branch;
        return divisionFilter === currentBranch;
      });
    }

    for (var j = 0; j < tmpBuckets.length; j++) {
      const bucketMax = tmpBuckets[j].bucketMax;
      const bucketMin = tmpBuckets[j].bucketMin;
      tmpBuckets[j].count = 0;

      for (var i = 0; i < d.length; i++) {
        const vAge = d[i].Age;
        if (vAge <= bucketMax && vAge >= bucketMin) {
          tmpBuckets[j].count++;
        }
      }
    }
    let total = 0;
    tmpBuckets[tmpBuckets.length - 1].count = 0;
    tmpBuckets.forEach((b) => {
      total = total + b.count;
    });

    tmpBuckets[tmpBuckets.length - 1].count = total;
    tmpBuckets.forEach((b) => {
      b.percentageOfTotal = Math.floor(
        (b.count / tmpBuckets[tmpBuckets.length - 1].count) * 100
      );
    });
    return tmpBuckets;
  };

  //   Calculate Retail KPIs this month to date
  useEffect(() => {
    if (salesSummaryData.length === 0 || inventoryData.length === 0) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    // Calculate Total Sales Units by divisions
    const totalSaleUnits = salesSummaryData.reduce((acc, curr) => {
      if (!acc[curr.Division]) {
        acc[curr.Division] = 0;
      }
      acc[curr.Division] += 1;
      return acc;
    }, {});

    // Add grand total
    totalSaleUnits[AllDivisions] = Object.values(totalSaleUnits).reduce(
      (a, b) => a + b
    );

    // Calculate Total Sales Dollars by divisions
    const totalSaleDollars = salesSummaryData.reduce((acc, curr) => {
      if (!acc[curr.Division]) {
        acc[curr.Division] = 0;
      }
      acc[curr.Division] += curr["SalePriceInc"];
      return acc;
    }, {});

    // Add grand total
    totalSaleDollars[AllDivisions] = Object.values(totalSaleDollars).reduce(
      (a, b) => a + b
    );

    // Calculate Total Gross Profit Dollars by divisions
    const totalTrueProfitDollars = salesSummaryData.reduce((acc, curr) => {
      if (!acc[curr.Division]) {
        acc[curr.Division] = 0;
      }
      acc[curr.Division] += curr["TrueProfit"];
      return acc;
    }, {});

    // Add grand total
    totalTrueProfitDollars[AllDivisions] = Object.values(
      totalTrueProfitDollars
    ).reduce((a, b) => a + b);

    // Calculate Total Finance Penetration Units by divisions
    const totalFinancePenetrationUnits = salesSummaryData.reduce(
      (acc, curr) => {
        if (!acc[curr.Division]) {
          acc[curr.Division] = 0;
        }
        acc[curr.Division] += curr["Finance_Units"];
        return acc;
      },
      {}
    );

    // Add grand total
    totalFinancePenetrationUnits[AllDivisions] = Object.values(
      totalFinancePenetrationUnits
    ).reduce((a, b) => a + b);

    // Calculate Total Warranty Penetration Units by divisions
    const totalWarrantyPenetrationUnits = salesSummaryData.reduce(
      (acc, curr) => {
        if (!acc[curr.Division]) {
          acc[curr.Division] = 0;
        }
        acc[curr.Division] += curr["Warranty_Units"];
        return acc;
      },
      {}
    );

    // Add grand total
    totalWarrantyPenetrationUnits[AllDivisions] = Object.values(
      totalWarrantyPenetrationUnits
    ).reduce((a, b) => a + b);

    // Calculate Total Finance Penetration Percentage by divisions
    const totalFinancePenetrationPercentage = Object.keys(
      totalFinancePenetrationUnits
    ).reduce((acc, curr) => {
      if (!acc[curr]) {
        acc[curr] = 0;
      }
      acc[curr] =
        (totalFinancePenetrationUnits[curr] / totalSaleUnits[curr]) * 100;
      return acc;
    }, {});

    // Add grand total percentage
    totalFinancePenetrationPercentage[AllDivisions] =
      (totalFinancePenetrationUnits[AllDivisions] /
        totalSaleUnits[AllDivisions]) *
      100;

    //   Calculate Total Warranty Penetration Percentage by divisions
    const totalWarrantyPenetrationPercentage = Object.keys(
      totalWarrantyPenetrationUnits
    ).reduce((acc, curr) => {
      if (!acc[curr]) {
        acc[curr] = 0;
      }
      acc[curr] =
        (totalWarrantyPenetrationUnits[curr] / totalSaleUnits[curr]) * 100;
      return acc;
    }, {});

    // Add grand total percentage
    totalWarrantyPenetrationPercentage[AllDivisions] =
      (totalWarrantyPenetrationUnits[AllDivisions] /
        totalSaleUnits[AllDivisions]) *
      100;

    //   Calculate True Profit Percentage by divisions
    const totalTrueProfitPercentage = Object.keys(
      totalTrueProfitDollars
    ).reduce((acc, curr) => {
      if (!acc[curr]) {
        acc[curr] = 0;
      }
      acc[curr] = (totalTrueProfitDollars[curr] / totalSaleDollars[curr]) * 100;
      return acc;
    }, {});

    // Add grand total percentage
    totalTrueProfitPercentage[AllDivisions] =
      (totalTrueProfitDollars[AllDivisions] / totalSaleDollars[AllDivisions]) *
      100;

    // Calculate age buckets by divisions
    let ageBucketsByDivision = {};
    for (var i = 0; i < brands[brand].length; i++) {
      const division = brands[brand][i];
      const tmpBuckets = calculateAgeBuckets(inventoryData, division);
      const over90DaysBucket = tmpBuckets[3];
      ageBucketsByDivision[division] = { ...over90DaysBucket };
    }

    // Total aged stock over 90 days
    const tmpTotalAgeStockOver90Days = calculateAgeBuckets(inventoryData, null);

    ageBucketsByDivision[AllDivisions] = tmpTotalAgeStockOver90Days[3];

    // Calculate lead conversion percentage by division
    const leadConversionPercentage = Object.keys(leadsByDivision).reduce(
      (acc, curr) => {
        if (!acc[curr]) {
          acc[curr] = 0;
        }
        acc[curr] = (totalSaleUnits[curr] / leadsByDivision[curr]) * 100;
        return acc;
      },
      {}
    );

    // Add grand total percentage
    leadConversionPercentage[AllDivisions] =
      (totalSaleUnits[AllDivisions] / leadsByDivision[AllDivisions]) * 100;

    // Calculate leading sites

    let leadingSite = {};
    // Divison that has the highest total true profit dollars
    let leadingSiteTmp = Object.keys(totalTrueProfitDollars).reduce((a, b) => {
      if (a !== AllDivisions && b !== AllDivisions) {
        return totalTrueProfitDollars[a] > totalTrueProfitDollars[b] ? a : b;
      }
      return a;
    });

    leadingSite["totalTrueProfitDollars"] = leadingSiteTmp;

    // Divison that has the highest total sale units
    leadingSiteTmp = Object.keys(totalSaleUnits).reduce((a, b) => {
      if (a !== AllDivisions && b !== AllDivisions) {
        return totalSaleUnits[a] > totalSaleUnits[b] ? a : b;
      }
      return a;
    });

    leadingSite["totalSaleUnits"] = leadingSiteTmp;

    // Division that has the highest finance penetration percentage
    leadingSiteTmp = Object.keys(totalFinancePenetrationPercentage).reduce(
      (a, b) => {
        if (a !== AllDivisions && b !== AllDivisions) {
          return totalFinancePenetrationPercentage[a] >
            totalFinancePenetrationPercentage[b]
            ? a
            : b;
        }
        return a;
      }
    );

    leadingSite["totalFinancePenetrationPercentage"] = leadingSiteTmp;

    // Division that has the highest warranty penetration percentage
    leadingSiteTmp = Object.keys(totalWarrantyPenetrationPercentage).reduce(
      (a, b) => {
        if (a !== AllDivisions && b !== AllDivisions) {
          return totalWarrantyPenetrationPercentage[a] >
            totalWarrantyPenetrationPercentage[b]
            ? a
            : b;
        }
        return a;
      }
    );

    leadingSite["totalWarrantyPenetrationPercentage"] = leadingSiteTmp;

    // Division that has the lowest aged stock over 90 days
    leadingSiteTmp = Object.keys(ageBucketsByDivision).reduce((a, b) => {
      if (a !== AllDivisions && b !== AllDivisions) {
        return ageBucketsByDivision[a].count < ageBucketsByDivision[b].count
          ? a
          : b;
      }
      return a;
    });

    leadingSite["agedStockOver90Days"] = leadingSiteTmp;

    // Division that has the highest lead conversion
    leadingSiteTmp = Object.keys(leadConversionPercentage).reduce((a, b) => {
      if (a !== AllDivisions && b !== AllDivisions) {
        return leadConversionPercentage[a] > leadConversionPercentage[b]
          ? a
          : b;
      }
      return a;
    });

    leadingSite["leadConversionPercentage"] = leadingSiteTmp;

    // Set Retail KPI
    setRetailKpi((prevState) => ({
      ...prevState,
      totalTrueProfitDollars: totalTrueProfitDollars,
      totalSaleDollars: totalSaleDollars,
      totalSaleUnits: totalSaleUnits,
      totalFinancePenetrationUnits: totalFinancePenetrationUnits,
      totalWarrantyPenetrationUnits: totalWarrantyPenetrationUnits,
      totalFinancePenetrationPercentage: totalFinancePenetrationPercentage,
      totalWarrantyPenetrationPercentage: totalWarrantyPenetrationPercentage,
      totalTrueProfitPercentage: totalTrueProfitPercentage,
      targets: AMWRetailKPITargets,
      leadingSite: leadingSite,
      agedStockOver90Days: ageBucketsByDivision,
      leadConversionPercentage: leadConversionPercentage,
    }));
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    setIsLoading(false);
  }, [
    JSON.stringify(salesSummaryData),
    JSON.stringify(inventoryData),
    JSON.stringify(leadsByDivision),
  ]);

  // Calculate rings data
  useEffect(() => {
    if (retailKpi.targets === undefined) {
      return;
    }
    let tmpRingsData = [...ringsData];
    // console.log(retailKpi);

    let mtdTargetRing;
    let actualRing;
    let monthTargetRing;
    // Set TP ring
    const tpRing = [];
    mtdTargetRing = {
      name: "MTD Target",
      pv: (533500 / 25) * 13,
      fill: ringsColours.mtd_target,
    };

    actualRing = {
      name: "Actual",
      pv: 118353,
      fill: ringsColours.actual,
    };

    monthTargetRing = {
      name: "Month Target",
      pv: 533500,
      fill: ringsColours.month_target,
    };
    tpRing[0] = mtdTargetRing;
    tpRing[1] = actualRing;
    tpRing[2] = monthTargetRing;

    tmpRingsData["trueProfit"] = tpRing;

    // Set Delivered ring
    const deliveredRing = [];
    mtdTargetRing = {
      name: "MTD Target",
      pv: (246 / 25) * 13,
      fill: ringsColours.mtd_target,
    };

    actualRing = {
      name: "Actual",
      pv: 78,
      fill: ringsColours.actual,
    };

    monthTargetRing = {
      name: "Month Target",
      pv: 246,
      fill: ringsColours.month_target,
    };
    deliveredRing[0] = mtdTargetRing;
    deliveredRing[1] = actualRing;
    deliveredRing[2] = monthTargetRing;

    // Set Leads ring
    const leadsRing = [];
    mtdTargetRing = {
      name: "MTD Target",
      pv: (246 / 0.17 / 25) * 13,
      fill: ringsColours.mtd_target,
    };

    actualRing = {
      name: "Actual",
      pv: 518,
      fill: ringsColours.actual,
    };

    monthTargetRing = {
      name: "Month Target",
      pv: 246 / 0.17,
      fill: ringsColours.month_target,
    };
    leadsRing[0] = mtdTargetRing;
    leadsRing[1] = actualRing;
    leadsRing[2] = monthTargetRing;

    tmpRingsData["trueProfit"] = tpRing;
    tmpRingsData["delivered"] = deliveredRing;
    tmpRingsData["leads"] = leadsRing;
    setRingsData(tmpRingsData);

    // console.log(tmpRingsData, "ring data");
  }, [JSON.stringify(retailKpi)]);
  //   Calculate Retail KPIs LAST month to date
  useEffect(() => {
    if (salesSummaryLastMonthData.length === 0) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    // Calculate Total Sales Units by divisions
    const totalSaleUnits = salesSummaryLastMonthData.reduce((acc, curr) => {
      if (!acc[curr.Division]) {
        acc[curr.Division] = 0;
      }
      acc[curr.Division] += 1;
      return acc;
    }, {});

    // Add grand total
    totalSaleUnits[AllDivisions] = Object.values(totalSaleUnits).reduce(
      (a, b) => a + b
    );

    // Calculate Total Sales Dollars by divisions
    const totalSaleDollars = salesSummaryLastMonthData.reduce((acc, curr) => {
      if (!acc[curr.Division]) {
        acc[curr.Division] = 0;
      }
      acc[curr.Division] += curr["SalePriceInc"];
      return acc;
    }, {});

    // Add grand total
    totalSaleDollars[AllDivisions] = Object.values(totalSaleDollars).reduce(
      (a, b) => a + b
    );

    // Calculate Total Gross Profit Dollars by divisions
    const totalTrueProfitDollars = salesSummaryLastMonthData.reduce(
      (acc, curr) => {
        if (!acc[curr.Division]) {
          acc[curr.Division] = 0;
        }
        acc[curr.Division] += curr["TrueProfit"];
        return acc;
      },
      {}
    );

    // Add grand total
    totalTrueProfitDollars[AllDivisions] = Object.values(
      totalTrueProfitDollars
    ).reduce((a, b) => a + b);

    // Calculate Total Finance Penetration Units by divisions
    const totalFinancePenetrationUnits = salesSummaryLastMonthData.reduce(
      (acc, curr) => {
        if (!acc[curr.Division]) {
          acc[curr.Division] = 0;
        }
        acc[curr.Division] += curr["Finance_Units"];
        return acc;
      },
      {}
    );

    // Add grand total
    totalFinancePenetrationUnits[AllDivisions] = Object.values(
      totalFinancePenetrationUnits
    ).reduce((a, b) => a + b);

    // Calculate Total Warranty Penetration Units by divisions
    const totalWarrantyPenetrationUnits = salesSummaryLastMonthData.reduce(
      (acc, curr) => {
        if (!acc[curr.Division]) {
          acc[curr.Division] = 0;
        }
        acc[curr.Division] += curr["Warranty_Units"];
        return acc;
      },
      {}
    );

    // Add grand total
    totalWarrantyPenetrationUnits[AllDivisions] = Object.values(
      totalWarrantyPenetrationUnits
    ).reduce((a, b) => a + b);

    // Calculate Total Finance Penetration Percentage by divisions
    const totalFinancePenetrationPercentage = Object.keys(
      totalFinancePenetrationUnits
    ).reduce((acc, curr) => {
      if (!acc[curr]) {
        acc[curr] = 0;
      }
      acc[curr] =
        (totalFinancePenetrationUnits[curr] / totalSaleUnits[curr]) * 100;
      return acc;
    }, {});

    // Add grand total percentage
    totalFinancePenetrationPercentage[AllDivisions] =
      (totalFinancePenetrationUnits[AllDivisions] /
        totalSaleUnits[AllDivisions]) *
      100;

    //   Calculate Total Warranty Penetration Percentage by divisions
    const totalWarrantyPenetrationPercentage = Object.keys(
      totalWarrantyPenetrationUnits
    ).reduce((acc, curr) => {
      if (!acc[curr]) {
        acc[curr] = 0;
      }
      acc[curr] =
        (totalWarrantyPenetrationUnits[curr] / totalSaleUnits[curr]) * 100;
      return acc;
    }, {});

    // Add grand total percentage
    totalWarrantyPenetrationPercentage[AllDivisions] =
      (totalWarrantyPenetrationUnits[AllDivisions] /
        totalSaleUnits[AllDivisions]) *
      100;

    //   Calculate True Profit Percentage by divisions
    const totalTrueProfitPercentage = Object.keys(
      totalTrueProfitDollars
    ).reduce((acc, curr) => {
      if (!acc[curr]) {
        acc[curr] = 0;
      }
      acc[curr] = (totalTrueProfitDollars[curr] / totalSaleDollars[curr]) * 100;
      return acc;
    }, {});

    // Add grand total percentage
    totalTrueProfitPercentage[AllDivisions] =
      (totalTrueProfitDollars[AllDivisions] / totalSaleDollars[AllDivisions]) *
      100;

    // Calculate lead conversion percentage by division
    const leadConversionPercentage = Object.keys(
      leadsByDivisionLastMonthMTD
    ).reduce((acc, curr) => {
      if (!acc[curr]) {
        acc[curr] = 0;
      }
      acc[curr] =
        (totalSaleUnits[curr] / leadsByDivisionLastMonthMTD[curr]) * 100;
      return acc;
    }, {});

    // Add grand total percentage
    leadConversionPercentage[AllDivisions] =
      (totalSaleUnits[AllDivisions] /
        leadsByDivisionLastMonthMTD[AllDivisions]) *
      100;

    // Calculate leading sites

    let leadingSite = {};
    // Divison that has the highest total true profit dollars
    let leadingSiteTmp = Object.keys(totalTrueProfitDollars).reduce((a, b) => {
      if (a !== AllDivisions && b !== AllDivisions) {
        return totalTrueProfitDollars[a] > totalTrueProfitDollars[b] ? a : b;
      }
      return a;
    });

    leadingSite["totalTrueProfitDollars"] = leadingSiteTmp;

    // Divison that has the highest total sale units
    leadingSiteTmp = Object.keys(totalSaleUnits).reduce((a, b) => {
      if (a !== AllDivisions && b !== AllDivisions) {
        return totalSaleUnits[a] > totalSaleUnits[b] ? a : b;
      }
      return a;
    });

    leadingSite["totalSaleUnits"] = leadingSiteTmp;

    // Division that has the highest finance penetration percentage
    leadingSiteTmp = Object.keys(totalFinancePenetrationPercentage).reduce(
      (a, b) => {
        if (a !== AllDivisions && b !== AllDivisions) {
          return totalFinancePenetrationPercentage[a] >
            totalFinancePenetrationPercentage[b]
            ? a
            : b;
        }
        return a;
      }
    );

    leadingSite["totalFinancePenetrationPercentage"] = leadingSiteTmp;

    // Division that has the highest warranty penetration percentage
    leadingSiteTmp = Object.keys(totalWarrantyPenetrationPercentage).reduce(
      (a, b) => {
        if (a !== AllDivisions && b !== AllDivisions) {
          return totalWarrantyPenetrationPercentage[a] >
            totalWarrantyPenetrationPercentage[b]
            ? a
            : b;
        }
        return a;
      }
    );

    leadingSite["totalWarrantyPenetrationPercentage"] = leadingSiteTmp;

    leadingSite["agedStockOver90Days"] = leadingSiteTmp;

    // Division that has the highest lead conversion
    leadingSiteTmp = Object.keys(leadConversionPercentage).reduce((a, b) => {
      if (a !== AllDivisions && b !== AllDivisions) {
        return leadConversionPercentage[a] > leadConversionPercentage[b]
          ? a
          : b;
      }
      return a;
    });

    leadingSite["leadConversionPercentage"] = leadingSiteTmp;

    // Set Retail KPI
    setRetailKpiLastMonthMTD((prevState) => ({
      ...prevState,
      totalTrueProfitDollars: totalTrueProfitDollars,
      totalSaleDollars: totalSaleDollars,
      totalSaleUnits: totalSaleUnits,
      totalFinancePenetrationUnits: totalFinancePenetrationUnits,
      totalWarrantyPenetrationUnits: totalWarrantyPenetrationUnits,
      totalFinancePenetrationPercentage: totalFinancePenetrationPercentage,
      totalWarrantyPenetrationPercentage: totalWarrantyPenetrationPercentage,
      totalTrueProfitPercentage: totalTrueProfitPercentage,
      targets: AMWRetailKPITargets,
      leadingSite: leadingSite,
      leadConversionPercentage: leadConversionPercentage,
    }));
    // console.log(retailKpiLastMonthMTD, "retailKpiLastMonthMTD");
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    setIsLoading(false);
  }, [
    JSON.stringify(salesSummaryLastMonthData),
    JSON.stringify(leadsByDivisionLastMonthMTD),
  ]);

  //   When new data is available, group by division
  useEffect(() => {
    const groupByDivision = (data) => {
      const groupedData = data.reduce((acc, item) => {
        const key = item["Division"];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {});
      return groupedData;
    };

    let groupedData = groupByDivision(salesSummaryData);

    setGroupedData(groupedData);
  }, [JSON.stringify(salesSummaryData)]);

  //   Sales summary data current month to date (using eom as end date)
  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = firestore
      .collection("salesSummary")
      .where("Sale Date EPOC", ">=", startOfMonthUnix)
      .where("Sale Date EPOC", "<=", endOfMonthUnix)
      .where("Division", "in", allDivisionsInSelectedBrand)
      .where("sale_class", "==", "R")
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          toast.info("No summary available at this time");
          setIsLoading(false);
          return;
        }
        const tmpSalesSummary = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpSalesSummary.push({ ...tmpData, id: doc.id });
        });
        tmpSalesSummary.sort(numericalASCProfit).reverse();
        tmpSalesSummary.sort(sortDESCDate);
        // tmpProfit.sort(sortASCDivision);
        setSalesSummaryData(tmpSalesSummary);
      });
    //   Sleep for 2 seconds

    //   Unsubscribe
    return () => unsubscribe;
  }, []);

  //   Sales summary data LAST month to date
  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = firestore
      .collection("salesSummary")
      .where("Sale Date EPOC", ">=", startOfLastMonthUnix)
      .where("Sale Date EPOC", "<=", endOfLastMonthUnix)
      .where("Division", "in", allDivisionsInSelectedBrand)
      .where("sale_class", "==", "R")
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          toast.info("No summary available at this time");
          setIsLoading(false);
          return;
        }
        const tmpSalesSummary = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();

          tmpSalesSummary.push({ ...tmpData, id: doc.id });
        });
        tmpSalesSummary.sort(numericalASCProfit).reverse();
        tmpSalesSummary.sort(sortDESCDate);

        setSalesSummaryLastMonthDataAllMonth(tmpSalesSummary);
        // Filter for days in month < lastMonthUnixMonthOnMonthDay
        const lastMonthMonthOnMonthDay = tmpSalesSummary.filter((s) => {
          return s["Sale Date EPOC"] < lastMonthUnixMonthOnMonthDay;
        });

        setSalesSummaryLastMonthData(lastMonthMonthOnMonthDay);
        //
      });
    //   Sleep for 2 seconds

    //   Unsubscribe
    return () => unsubscribe;
  }, []);

  //   Get instock inventory
  useEffect(() => {
    const unsubscribe = firestore
      .collection("vehicle-health-amw")
      .where("CurrentStatus", "==", "IN-STOCK")
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          toast.info("No summary available at this time");
          return;
        }
        const tmpInventory = [];
        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpInventory.push({ ...tmpData, id: doc.id });
        });
        setInventoryData(tmpInventory);
      });
    //   Unsubscribe
    return () => unsubscribe;
  }, []);

  // Get leads current month MTD from on call function
  useEffect(() => {
    // call firebase function oncall
    setIsLoadingLeads(true);
    httpsCallable(
      functions,
      "getSalesforceReport"
    )({ reportId: "00OOm0000004f2zMAA" })
      .then((result) => {
        const data = result.data;
        const leadsByDivision = data?.columns?.map((c) => {
          // Match column name to division mapped in salesForceSiteNameLookup
          const division = salesForceSiteNameLookup.find(
            (d) => d.salesForceName === c.value
          )?.ddpName;
          const key = c.key;
          // Get value from data rows matching column key
          const value = data?.rows.find((r) => r.key === key)?.value;
          return { division: division, value: value };
        });

        // Convert to object
        const leadsByDivisionObject = leadsByDivision.reduce((acc, curr) => {
          acc[curr.division] = curr.value;
          return acc;
        }, {});

        // Add grand total
        leadsByDivisionObject[AllDivisions] = Object.values(
          leadsByDivisionObject
        ).reduce((a, b) => a + b);

        setLeadsByDivision(leadsByDivisionObject);

        setIsLoadingLeads(false);
      })
      .catch((e) => {
        toast.error("Error getting leads data");
        setIsLoadingLeads(false);
      });
  }, []);

  // Get leads LAST month MTD from on call function
  useEffect(() => {
    // call firebase function oncall
    setIsLoadingLeads(true);
    httpsCallable(
      functions,
      "getSalesforceReport"
    )({
      reportId: "00OOm0000004ld3MAA",
      details: true,
    })
      .then((result) => {
        const data = result.data;
        let detailRowNames = [];
        let detailRowDateKey;
        // Get key of Created_Date column to match later
        if (data?.hasDetailRows) {
          detailRowNames = data?.detailColumnNames;
          detailRowDateKey = detailRowNames.findIndex(
            (d) => d === "CREATED_DATE"
          );
        }
        const leadsByDivision = data?.columns?.map((c) => {
          // Match column name to division mapped in salesForceSiteNameLookup
          const division = salesForceSiteNameLookup.find(
            (d) => d.salesForceName === c.value
          )?.ddpName;
          const key = c.key;
          // Get value from data rows matching column key
          const detailRows = data?.rows.find((r) => r.key === key)?.detailRows;
          // Parse through detail rows to filter out dates > today from last month
          const rowsRelativeMoM = detailRows?.reduce((acc, curr) => {
            const date = curr[detailRowDateKey];
            const dateUnix = dayjs(date.value).unix();
            if (
              dateUnix > startOfLastMonthUnix &&
              dateUnix < lastMonthUnixMonthOnMonthDay
            ) {
              acc.push(curr);
            }
            return acc;
          }, []);
          const value = rowsRelativeMoM?.length || 0;

          return { division: division, value: value };
        });

        // console.log(leadsByDivision, "leadsByDivision");

        // Convert to object
        const leadsByDivisionObject = leadsByDivision.reduce((acc, curr) => {
          acc[curr.division] = curr.value;
          return acc;
        }, {});

        // Add grand total
        leadsByDivisionObject[AllDivisions] = Object.values(
          leadsByDivisionObject
        ).reduce((a, b) => a + b);

        setLeadsByDivisionLastMonthMTD(leadsByDivisionObject);

        setIsLoadingLeads(false);
      })
      .catch((e) => {
        toast.error("Error getting last months lead data");
        setIsLoadingLeads(false);
      });
  }, []);

  // Set toggle button style when branch filter changes
  useEffect(() => {
    if (toggleButtonRefArray.length === 0) return;
    toggleButtonRefArray.forEach((t) => {
      if (
        t?.current?.textContent?.toUpperCase() ===
        branchFilterShort.toUpperCase()
      ) {
        t?.current?.style?.setProperty(
          "background-color",
          "#D2B4DE",
          "important"
        );
      } else {
        t?.current?.style?.setProperty("background-color", "", "important");
      }
      return;
    });
  }, [branchFilterShort]);

  // Group salesSummaryData by date (this month and last month) and generate graph data
  useEffect(() => {
    const groupByDate = (data) => {
      const groupedData = data.reduce((acc, item) => {
        // Format Sale Date EPOC to YYYY-MM-DD from unix using dayjs
        const key = dayjs.unix(item["Sale Date EPOC"]).format("DD");
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {});
      return groupedData;
    };
    const dThisMonth = groupByDate(salesSummaryData);
    const dLastMonth = groupByDate(salesSummaryLastMonthDataAllMonth);

    setSalesSummaryGraphData({ thisMonth: dThisMonth, lastMonth: dLastMonth });

    // Get an array of numbers from 1 to 31
    const uniqueDatesThisMonth = [];
    for (var i = 0; i < dayjs().endOf("month").format("DD"); i++) {
      if (i === 0) continue;
      if (i < 10) {
        uniqueDatesThisMonth.push(`0${i}`);
        continue;
      }
      uniqueDatesThisMonth.push(`${i}`);
    }

    // console.log(uniqueDatesThisMonth, "lmu");

    // Get cumulative sales units this month
    const salesUnitsThisMonthByDayAllMonth = uniqueDatesThisMonth.reduce(
      (acc, curr) => {
        if (!acc[curr]) {
          acc[curr] = [];
        }
        // console.log(dThisMonth, "dThisMonth[curr]");
        // console.log(curr, "curr");
        acc[curr] = dThisMonth[curr]?.length || 0;
        return acc;
      },
      {}
    );

    // Get cumulative sales units last month
    // const salesUnitsLastMonthByDayAllMonth = uniqueDatesThisMonth.reduce(
    //   (acc, curr) => {
    //     if (!acc[curr]) {
    //       acc[curr] = [];
    //     }
    //     acc[curr] = dLastMonth[curr]?.length || 0;
    //     return acc;
    //   },
    //   {}
    // );

    // // Get cumulative sales units this month
    // const salesUnitsThisMonthByDay = salesUnitsThisMonthByDayAllMonth.reduce(
    //   (acc, curr) => {
    //     if (!acc[curr]) {
    //       acc[curr] = [];
    //     }
    //     acc = curr + acc;
    //     return acc;
    //   },
    //   {}
    // );
    setGraphData(salesUnitsThisMonthByDayAllMonth, "sau");

    // Build graph data for recharts
    const graphData = uniqueDatesThisMonth.map((d) => {
      return {
        name: d,
        tm: dThisMonth[d]?.length || null,
        lm: dLastMonth[d]?.length || null,
      };
    });

    // // console.log(graphData);

    setGraphData(graphData);
  }, [
    JSON.stringify(salesSummaryData),
    JSON.stringify(salesSummaryLastMonthDataAllMonth),
  ]);

  // Customise layout of progress bar
  useLayoutEffect(() => {
    progressBarCurrent?.current?.forEach((p) => {
      p?.style.setProperty("background-color", "#2ECC71", "important");
    });

    progressBarRemaining?.current?.forEach((p) => {
      p?.style.setProperty("background-color", "#FADBD8", "important");
    });
  }, []);

  const renderFormattedValue = (value, units) => {
    return ["%", "$"].includes(units) ? (
      <>
        {units === "%" ? (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
            suffix={"%"}
            decimalScale={1}
          />
        ) : (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={0}
          />
        )}
      </>
    ) : (
      <>{value}</>
    );
  };

  const renderProgressBar = (kpiActual, kpiTarget) => {
    const nowVal = (kpiActual / kpiTarget) * 100;
    const remainingVal = 100 - nowVal;
    return (
      <ProgressBar
        style={{
          borderRadius: 0,
          borderColor: "black",
          marginRight: 10,
          // width: "80%",
        }}
      >
        <ProgressBar
          // striped
          ref={(el) => progressBarCurrent.current.push(el)}
          now={nowVal}
          key={1}
        />
        <ProgressBar
          ref={(el) => progressBarRemaining.current.push(el)}
          now={remainingVal}
          key={2}
        />
      </ProgressBar>
    );
  };

  const renderComparisonArrow = (currentVal, lastMonthVal) => {
    if (lastMonthVal < currentVal) {
      return <BiUpvote size={20} color="green" />;
    } else {
      return <BiDownvote size={20} color="red" />;
    }
  };

  return (
    <>
      <Navbar
        // sticky="top"
        bg="dark"
        variant="dark"
        className="justify-content-center"
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        <Navbar.Brand>MTD Tracker</Navbar.Brand>
      </Navbar>
      <Container>
        <ToggleButtonGroup
          style={styles.toggleButtonGroup}
          name="site_filter"
          size="sm"
          onChange={(value) => {
            setBranchFilter(`AMW - ${value}`);
            setBranchFilterShort(value);
          }}
          value={branchFilterShort}
        >
          {/* <ToggleButton
            variant="outline"
            ref={toggleButtonRefArray[0]}
            style={styles.toggleButton}
            value="MELBOURNE"
          >
            Melbourne
          </ToggleButton> */}
          {/* <ToggleButton
            ref={toggleButtonRefArray[1]}
            style={styles.toggleButton}
            value="WESTGATE"
            variant="outline"
          >
            Westgate
          </ToggleButton> */}
          {/* <ToggleButton
            ref={toggleButtonRefArray[2]}
            style={styles.toggleButton}
            value="FAIRFIELD"
            variant="outline"
          >
            Fairfield
          </ToggleButton> */}
          <ToggleButton
            ref={toggleButtonRefArray[3]}
            style={styles.toggleButton}
            value="DANDENONG"
            variant="outline"
          >
            Dandenong
          </ToggleButton>
          <ToggleButton
            ref={toggleButtonRefArray[5]}
            style={styles.toggleButton}
            value="MOORABBIN"
            variant="outline"
          >
            Moorabbin
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          style={styles.toggleButtonGroup}
          name="site_filter"
          size="sm"
          onChange={(value) => {
            setBranchFilter(`AMW - ${value}`);
            setBranchFilterShort(value);
          }}
          value={branchFilterShort}
        >
          <ToggleButton
            ref={toggleButtonRefArray[4]}
            style={styles.toggleButton}
            value="PLYMPTON"
            variant="outline"
          >
            Plympton
          </ToggleButton>

          <ToggleButton
            ref={toggleButtonRefArray[6]}
            style={styles.toggleButton}
            value="All"
            variant="outline"
          >
            All
          </ToggleButton>
        </ToggleButtonGroup>
      </Container>
      {/* <pre>{JSON.stringify(filteredListOfVehicles, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(ageBuckets, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(branchFilter, null, 2)}</pre>  */}

      <Card>
        <Card.Title>
          <Row className="mt-2">
            <Col>
              <h6>
                {branchFilterShort !== "All"
                  ? `Site manager:  ${siteManagersAMW[branchFilter]}`
                  : null}
              </h6>
            </Col>
          </Row>
        </Card.Title>

        <Table
          // responsive
          style={{ fontSize: 10, verticalAlign: "middle !important" }}
          striped
          bordered
          hover
          size="sm"
          // className="table-responsive"
        >
          <thead>
            <tr
              className="align-self-center"
              style={{ verticalAlign: "middle" }}
            >
              <th style={{ verticalAlign: "middle" }}>KPI</th>
              <th style={{ verticalAlign: "middle" }}>
                Actual for {todayReadable}
              </th>
              <th style={{ verticalAlign: "middle" }}>Month Target</th>
              <th style={{ verticalAlign: "middle" }}>Variance</th>
              <th style={{ verticalAlign: "middle" }}>
                Comparison to
                <br /> {lastMonthReadableMonthOnMonthDay}
              </th>
            </tr>
          </thead>
          <tbody style={{ fontSize: 12 }}>
            {defaultRetailKpis
              .filter((k) => k.active)
              .map((kpi, idx) => {
                const kpiActual = retailKpi?.[kpi.id]?.[branchFilter] || 0;
                const kpiActualLastMonthMTD =
                  retailKpiLastMonthMTD?.[kpi.id]?.[branchFilter] || 0;
                const kpiTarget =
                  retailKpi?.["targets"]?.[branchFilter]?.[kpi.id] || 0;
                const kpiVariance = kpiActual - kpiTarget;

                // First three characters of branch filter
                const leadingSite = retailKpi?.["leadingSite"]?.[kpi.id]
                  ?.replace(`${brand} -`, "")
                  .substring(0, 4);
                return (
                  <tr key={idx}>
                    <td>
                      <Row>
                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          style={{ textAlign: "left", marginLeft: 10 }}
                        >
                          {kpi.name}
                        </Col>
                        <Col>
                          {kpi.id !== "agedStockOver90Days"
                            ? renderProgressBar(kpiActual, kpiTarget)
                            : null}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <sub>{kpi.description || ""}</sub>
                        </Col>
                      </Row>
                    </td>
                    {/* ACTUALS */}
                    <td>
                      {renderFormattedValue(kpiActual, kpi.units)}
                      {kpi.id === "leadConversionPercentage" &&
                      isLoadingLeads ? (
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="sm"
                        />
                      ) : null}
                    </td>
                    {/* TARGETS */}
                    <td>{renderFormattedValue(kpiTarget, kpi.units)}</td>
                    {/* VARIANCE */}
                    <td>
                      {kpiVariance > 0 ? (
                        <span style={{ color: "green" }}>
                          {renderFormattedValue(kpiVariance, kpi.units)}
                        </span>
                      ) : (
                        <span style={{ color: "red" }}>
                          {renderFormattedValue(kpiVariance, kpi.units)}
                        </span>
                      )}
                    </td>
                    {/* Comparison */}
                    <td>
                      <Row>
                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          style={{ textAlign: "center" }}
                        >
                          {renderFormattedValue(
                            kpiActualLastMonthMTD,
                            kpi.units
                          )}
                          {kpi.id === "leadConversionPercentage" &&
                          isLoadingLeads ? (
                            <Spinner
                              animation="border"
                              variant="primary"
                              size="sm"
                            />
                          ) : null}
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          style={{ textAlign: "center" }}
                        >
                          {renderComparisonArrow(
                            kpiActual,
                            kpiActualLastMonthMTD
                          )}
                        </Col>
                      </Row>
                    </td>
                    {/* Leading Site */}
                    {/* <td>{leadingSite}</td> */}
                  </tr>
                );
              })}
            <tr>
              <td>
                <Row>
                  <Col style={{ textAlign: "left", marginLeft: 10 }}>
                    Aged stock over 90 days %
                  </Col>
                </Row>
              </td>
              <td>
                <NumberFormat
                  value={
                    retailKpi?.agedStockOver90Days?.[branchFilter]
                      .percentageOfTotal || 0
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix={"%"}
                  decimalScale={1}
                />
              </td>
              <td>10 %</td>
              <td>
                {retailKpi?.agedStockOver90Days?.[branchFilter]
                  .percentageOfTotal -
                  10 <=
                0 ? (
                  <span style={{ color: "green" }}>
                    {renderFormattedValue(
                      10 -
                        retailKpi?.agedStockOver90Days?.[branchFilter]
                          .percentageOfTotal || 0,
                      "%"
                    )}
                  </span>
                ) : (
                  <span style={{ color: "red" }}>
                    {renderFormattedValue(
                      10 -
                        retailKpi?.agedStockOver90Days?.[branchFilter]
                          .percentageOfTotal,
                      "%"
                    )}
                  </span>
                )}

                {/* <NumberFormat
                  value={
                    10 -
                      retailKpi?.agedStockOver90Days?.[branchFilter]
                        .percentageOfTotal || 0
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix={"%"}
                  decimalScale={1}
                /> */}
              </td>
              <td>
                <Row>
                  <Col style={{ textAlign: "right" }}></Col>
                  <Col></Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </Table>
        <Card.Footer>
          <small className="text-muted">
            Last updated{" "}
            {dayjs()
              .startOf("day")
              .add(parseInt(dayjs().format("H")) - 1, "hour")
              .format("DD/MM/YYYY HH:mm:ss")}
          </small>
          <br />
          <small className="text-muted">
            *Metal margin and dealer delivery and warranty
          </small>
        </Card.Footer>
      </Card>
      {/* <Card>
        <Card.Body>
          <LineChart
            width={500}
            height={300}
            data={graphData}
            // margin={{
            //   top: 5,
            //   right: 30,
            //   left: 20,
            //   bottom: 5,
            // }}
          >
            <XAxis dataKey="name"></XAxis>
            <YAxis />
            <Line type="monotone" dataKey="tm" stroke="#8884d8" />
            <Line type="monotone" dataKey="lm" stroke="#82ca9d" />
          </LineChart>
        </Card.Body>
      </Card> */}
      {/* <pre>{JSON.stringify(salesSummaryGraphData, null, 2)}</pre> */}
      <Card hidden className="mt-2">
        <Card.Body>
          <Row className="justify-content-left">
            <Col>
              <h6>Profit $</h6>
              <RadialBarChart
                cx="50%"
                cy="40%"
                width={400}
                height={300}
                innerRadius="50%"
                outerRadius="80%"
                barSize={10}
                data={ringsData?.trueProfit}
                startAngle={90}
                endAngle={450}
              >
                <RadialBar
                  minAngle={15}
                  // label={{ position: "insideStart", fill: "#fff" }}
                  clockWise={true}
                  dataKey="pv"
                  background
                />
                <Legend
                  iconSize={10}
                  layout="vertical"
                  verticalAlign="middle"
                  wrapperStyle={styles.radialBar}
                />
              </RadialBarChart>
            </Col>
            <Col>
              <h6>Delivered Units</h6>
              <RadialBarChart
                cx="50%"
                cy="40%"
                width={400}
                height={300}
                innerRadius="50%"
                outerRadius="80%"
                barSize={10}
                data={ringsData?.delivered}
                startAngle={90}
                endAngle={450}
              >
                <RadialBar
                  minAngle={15}
                  // label={{ position: "insideStart", fill: "#fff" }}
                  clockWise={true}
                  dataKey="pv"
                  background
                  animationBegin={3000}
                  animationDuration={5000}
                  animationEasing="ease-in-out"
                />
                {/* <Legend
                  iconSize={10}
                  layout="vertical"
                  verticalAlign="middle"
                  wrapperStyle={styles.radialBar}
                /> */}
              </RadialBarChart>
            </Col>
            <Col>
              <h6>Leads #</h6>
              <RadialBarChart
                cx="50%"
                cy="40%"
                width={400}
                height={300}
                innerRadius="50%"
                outerRadius="80%"
                barSize={10}
                data={ringsData?.leads}
                startAngle={90}
                endAngle={450}
              >
                <RadialBar
                  minAngle={15}
                  // label={{ position: "insideStart", fill: "#fff" }}
                  clockWise={true}
                  dataKey="pv"
                  background
                />
                {/* <Legend
                  iconSize={10}
                  layout="vertical"
                  verticalAlign="middle"
                  wrapperStyle={styles.radialBar}
                /> */}
              </RadialBarChart>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ToastContainer />
      <BackdropComponent loading={isLoading} />
    </>
  );
};

export default RetailSalesKPIComponent;
