import { useEffect, useState } from "react";

export function useYearsList() {
  const [years, setYears] = useState([]);

  //List of year going back 30 years
  const yearList = () => {
    const years = [];
    var d = new Date();
    var n = d.getFullYear();
    for (var i = n; i > n - 30; i--) {
      years.push({ label: i.toString(), id: i.toString() });
    }
    return years;
  };

  // Get client list
  useEffect(() => {
    setYears(yearList());
  }, []);

  return [years];
}
