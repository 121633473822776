import { useEffect } from "react";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

function PageViewLoggerComponent() {
  const analytics = firebase.analytics();
  const location = useLocation();

  // By passing `location.pathname` to the second argument of `useEffect`,
  // we only log on first render and when the `pathname` changes
  useEffect(() => {
    analytics.logEvent("page-view", { path_name: location?.pathname });
  }, [location?.pathname]);

  return null;
}

export default PageViewLoggerComponent;
