export const AMWRetailKPITargets = {
  "AMW - ALBION": {
    division: "AMW - ALBION",
    totalSaleUnits: 35,
    totalSaleDollars: 0,
    leadConversionPercentage: 17,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 35,
    totalGrossProfitDollars: 78750,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 78750,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "AMW - BRISBANE": {
    division: "AMW - BRISBANE",
    totalSaleUnits: 35,
    totalSaleDollars: 0,
    leadConversionPercentage: 17,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 35,
    totalGrossProfitDollars: 78750,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 78750,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "AMW - DANDENONG": {
    division: "AMW - DANDENONG",
    totalSaleUnits: 45,
    totalSaleDollars: 0,
    leadConversionPercentage: 17,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 35,
    totalGrossProfitDollars: 101250,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 101250,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "AMW - FAIRFIELD": {
    division: "AMW - FAIRFIELD",
    totalSaleUnits: 43,
    totalSaleDollars: 0,
    leadConversionPercentage: 17,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 35,
    totalGrossProfitDollars: 96750,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 96750,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "AMW - MELBOURNE": {
    division: "AMW - MELBOURNE",
    totalSaleUnits: 47,
    totalSaleDollars: 0,
    leadConversionPercentage: 17,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 35,
    totalGrossProfitDollars: 105750,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 105750,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "AMW - PLYMPTON": {
    division: "AMW - PLYMPTON",
    totalSaleUnits: 33,
    totalSaleDollars: 0,
    leadConversionPercentage: 17,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 35,
    totalGrossProfitDollars: 74250,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 74250,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "AMW - WESTGATE": {
    division: "AMW - WESTGATE",
    totalSaleUnits: 43,
    totalSaleDollars: 0,
    leadConversionPercentage: 17,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 35,
    totalGrossProfitDollars: 96750,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 96750,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "AMW - MOORABBIN": {
    division: "AMW - MOORABBIN",
    totalSaleUnits: 50,
    totalSaleDollars: 0,
    leadConversionPercentage: 20,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 35,
    totalGrossProfitDollars: 125000,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 125000,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 5,
  },
  "AMW - All": {
    division: "AMW - All",
    totalSaleUnits: 246,
    totalSaleDollars: 0,
    leadConversionPercentage: 17,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 35,
    totalGrossProfitDollars: 553500,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 553500,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
};
