import { useFunctions } from "reactfire";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import ImageModalComponent from "./ImageModalComponent";
import { Camera, Search } from "react-bootstrap-icons";
import ProgressBar from "react-bootstrap/ProgressBar";

import InputGroup from "react-bootstrap/InputGroup";
import { useEffect } from "react";
import { useRef } from "react";
import { createWorker } from "tesseract.js";
import NumberFormat from "react-number-format";
import vision from "react-cloud-vision-api";
import Canvas from "./CanvasComponent";
import PageViewLoggerComponent from "./PageViewLoggerComponent";
import dayjs from "dayjs";
import copy from "copy-to-clipboard";
import { HiClipboardCheck } from "react-icons/hi";
import VehiclePhotos from "./VehiclePhotos";
import BackdropComponent from "./BackdropComponent";
import {
  Accordion,
  Badge,
  ButtonGroup,
  Card,
  Tab,
  Tabs,
} from "react-bootstrap";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { Field, Formik } from "formik";
import TextField from "./FreightBooking/TextField";
import FormikAutoCompleteComponentNewLocation from "./FormikAutoCompleteComponentNewLocation";
import useDebounce from "../hooks/useDebounce";
import FileDropZone from "./FileDropZone";
import { httpsCallable } from "firebase/functions";
import { Input } from "postcss/lib/postcss";
import { set } from "firebase/database";

const dev = true;
var initState = { vin: "", rego: "" };
if (dev) {
  initState.vin = "";
  initState.rego = "";
  initState.stockId = "";
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// var workerStatus = "";
// const worker = createWorker({
//   logger: (m) => {
//     console.log(m);
//     workerStatus = m;
//   },
// });

const testVIN = "WP0ZZZ99ZGS194467";

const initialLeadValues = {
  firstName: "",
  lastName: "",
  location: {},
  email1: "",
  phone_mobile: "",
  Address: "",
  sugarLeadId: "",
  vehicleInfo: {},
};

function VINDecodeComponent() {
  const [vin, setVin] = useState(initState.vin);
  const [stockId, setStockId] = useState(initState.stockId);
  const [rego, setRego] = useState(initState.rego);
  const [photos, setPhotos] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [cvBoundingPoly, setCVBoundinPoly] = useState();
  const [searchLeadResults, setSearchLeadResults] = useState([]);
  const [selectedLeadDetails, setSelectedLeadDetails] = useState({});
  const [sugarLeadData, setSugarLeadData] = useState(initialLeadValues);
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [leadCreated, setLeadCreated] = useState(false);

  const [loading, setLoading] = useState(false);
  // Use debounce hook to delay calling api when typing
  const [leadNameSearchQuery, setLeadNameSearchQuery] = useDebounce("", 500);

  const [modalShow, setModalShow] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [vehicleInfoObj, setVehicleInfoObj] = useState({
    make: "",
    model: "",
    variant: "",
    odo: "",
    price: "",
  });
  const [stdSpecs, setStdSpecs] = useState([]);
  const [Specifications, setSpecifications] = useState([]);
  const [FactoryOptions, setFactoryOptions] = useState([]);
  const [FactoryEquipment, setFactoryEquipment] = useState([]);

  const [filteredStdSpecs, setFilteredStdSpecs] = useState([]);
  const [filteredSpecifications, setFilteredSpecifications] = useState([]);
  const [filteredFactoryOptions, setFilteredFactoryOptions] = useState([]);
  const [filteredFactoryEquipment, setFilteredFactoryEquipment] = useState([]);

  const [filterStdSpecs, setFilterStdSpecs] = useState("");
  const [filterSpecifications, setFilterSpecifications] = useState("");
  const [filterFactoryOptions, setFilterFactoryOptions] = useState("");
  const [filterFactoryEquipment, setFilterFactoryEquipment] = useState("");

  const [isLoadingVinImageRecog, setIsLoadingVinImageRecog] = useState(false);
  const [isLoadingRegoImageRecog, setIsLoadingRegoImageRecog] = useState(false);
  const [vinImageRecogProgress, setVinImageRecogProgress] = useState(0);
  const [modalDetailsShow, setModalDetailsShow] = useState(false);
  const [multipleRedbookFound, setMultipleRedbookFound] = useState([]);
  const [showUploadFilesModal, setShowUploadFilesModal] = useState(false);

  const functions = useFunctions();
  const vinImageRef = useRef(null);
  const regoImageRef = useRef(null);
  const { height, width } = useWindowDimensions();

  const numericalASC = (a, b) => {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  };

  const draw = (ctx) => {
    const examplePoly = [
      {
        x: 815,
        y: 474,
      },
      {
        x: 1270,
        y: 519,
      },
      {
        x: 1266,
        y: 554,
      },
      {
        x: 812,
        y: 509,
      },
    ];
    // if (modalImage !== null) ctx.drawImage(null, 0, 0);
    // if (modalImage !== null) {
    //   var image = new Image();
    //   image.onload = function () {
    //     ctx.drawImage(image, 0, 0);
    //   };
    //   image.src = modalImage;
    // }

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.beginPath();
    ctx.rect(0, 0, 200, 100);
    ctx.lineWidth = 2;
    ctx.strokeStyle = "green";
    ctx.stroke();
  };

  const copyToClipboard = (v) => {
    copy(v);
  };

  const getData = async (searchByRego = 0) => {
    var vehicles = null;
    var searchFullVIN = true;
    // If not searching by stock id (searching by VIN)
    if (searchByRego === 0) {
      if (vin.length === 0 || vin.length < 15) {
        toast.warning("VIN doesn't seem correct, please check");
        return;
      }
      setLoading(true);
      setVehicleInfo(null);
      setSearchResults([]);
      setMultipleRedbookFound([]);
      setStdSpecs([]);
      setSpecifications([]);
      setFactoryOptions([]);
      setFactoryEquipment([]);
      setFilteredFactoryEquipment([]);
      setFilteredFactoryOptions([]);
      setFilteredSpecifications([]);
      setFilteredStdSpecs([]);
      setFilterFactoryEquipment("");
      setFilterFactoryOptions("");
      setFilterSpecifications("");
      setFilterStdSpecs("");

      var decodeVIN = httpsCallable(functions, "decodeVIN");
      await decodeVIN({ vin })
        .then((result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result.data;
          if (sanitizedMessage === null) {
            throw "Decode failed";
          } else {
            if (sanitizedMessage.error?.length > 0) {
              toast.warning(sanitizedMessage.error);
              if (sanitizedMessage.errorCode === 933) {
                //Multiple Redbook codes found
                setMultipleRedbookFound(sanitizedMessage.rbcodes);
              }
            } else {
              toast.success("Returned decoded vehicle");
              console.log(sanitizedMessage);
              setVehicleInfo(sanitizedMessage);
            }
          }
        })
        .catch((e) => {
          toast.error(e);
          console.log(e);
        });
      setLoading(false);
    }
  };

  const showCurrentImageModal = (src) => {
    setModalImage(src);
    setModalShow(true);
  };

  const handleVinChange = (e) => {
    setVin(e.target.value);
  };

  const handleStockIdChange = (e) => {
    setStockId(e.target.value);
  };

  const handleRegoChange = (e) => {
    setRego(e.target.value);
  };

  const handleShowUploadFilesModal = () => {
    setShowUploadFilesModal(true);
  };

  const handleHideUploadFilesModal = () => {
    setShowUploadFilesModal(false);
  };

  const hideCustomerInformationModal = () => {
    setLeadNameSearchQuery("");
    setSearchLeadResults([]);
    setSugarLeadData({});
    setSearchCompleted(false);
    setModalDetailsShow(false);
  };

  const handleVinImageChange = async (event) => {
    setVin("");
    setLoading(true);
    setSearchResults([]);
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    console.log(file);
    let fileB64 = null;
    setIsLoadingVinImageRecog(true);
    setLoading(true);
    // Send to GV to detect text
    const ocrResult = null;

    const reader = new FileReader();
    reader.onloadend = async () => {
      // Load img for canvas

      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      // log to console
      // logs wL2dvYWwgbW9yZ...
      console.log("converted to base64");
      fileB64 = base64String;
      setModalImage(URL.createObjectURL(file));
      let req = new vision.Request({
        image: new vision.Image({
          base64: fileB64,
        }),
        features: [new vision.Feature("TEXT_DETECTION")],
      });

      await vision.annotate(req).then((res) => {
        console.log(res);

        let VINLabelIdx = 0;
        let VINValueIdx = 0;
        let VINValue = "";

        res.responses[0].textAnnotations.forEach((ta, idx) => {
          // Search by VIN label order
          // if (ta.description === "VIN") {
          //   VINLabelIdx = idx;
          //   VINValueIdx = idx + 1;
          //   VINValue = res.responses[0].textAnnotations[idx + 1].description;
          // }

          // Search by finding WDC1660242A704804 (17 digit)
          if (ta?.description?.length === 17) {
            VINValue = ta.description;
            setVin(VINValue);
            setCVBoundinPoly(ta.boundingPoly.vertices);
            toast.success(
              "Converted image to VIN, please check and then hit Search"
            );
          }
        });
        setIsLoadingVinImageRecog(false);
        setLoading(false);
        if (VINValue === "") {
          toast.warning(
            "Could not find VIN in image, please try again or enter manually"
          );
        }
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSearchSugarByLeadName = async (q) => {
    console.log(q);
    if (q.length > 3) {
      setSearchLeadResults([]);
      setSearchCompleted(false);
      setSelectedLeadDetails(initialLeadValues);

      setLoading(true);
      var searchSugar = functions.httpsCallable("searchForLeadByNameInSugar");
      await searchSugar({ q })
        .then((result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result.data;
          if (sanitizedMessage === null) {
            throw "No results";
          } else {
            if (sanitizedMessage.error?.length > 0) {
              toast.warning(sanitizedMessage.error);
              if (sanitizedMessage.errorCode === 933) {
                //Multiple Redbook codes found
                // setMultipleRedbookFound(sanitizedMessage.rbcodes);
              }
            } else {
              // toast.success("Search successful");
              // console.log(sanitizedMessage);
              setSearchCompleted(true);
              setSearchLeadResults(sanitizedMessage.records);
            }
          }
        })
        .catch((e) => {
          toast.error(e);
          console.log(e);
        });
      setLoading(false);
    }
  };

  const handleRegoImageChange = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    setIsLoadingRegoImageRecog(true);
    // Send to GV to detect text
    await sleep(4000);
    setIsLoadingRegoImageRecog(false);
    toast.warning(
      "Could not find rego in photo, please try again or type in manually"
    );
    console.log(file);
  };

  const vinImageRefClick = () => {
    if (vinImageRef !== null) {
      vinImageRef.current.click();
    }
  };

  const regoImageRefClick = () => {
    //
    if (regoImageRef !== null) {
      regoImageRef.current.click();
    }
  };

  // Update vehicle info array when photos array changes
  // useEffect(() => {
  //   // Set vehicle info array
  //   if (photos.length > 0 && photos[0].vehicleInfo) {
  //     var vehicleProperties = [];
  //     const tmpVehicleInfo = photos[0].vehicleInfo;
  //     setVehicleInfoObj(tmpVehicleInfo);
  //     for (let prop in tmpVehicleInfo) {
  //       const tmpProp = { key: prop, value: tmpVehicleInfo[prop] };
  //       vehicleProperties.push(tmpProp);
  //     }
  //     setVehicleInfo(vehicleProperties);
  //   }
  // }, [photos]);

  useEffect(() => {
    vision.init({ auth: "AIzaSyBYIpmrTo_qzFvAiG4FAZA4J9AhWGUS4AQ" });
  });

  useEffect(() => {
    if (Object.keys(selectedLeadDetails).length > 0) {
      const payload = {
        firstName: selectedLeadDetails?.first_name,
        lastName: selectedLeadDetails?.last_name,
        location: {},
        email1:
          selectedLeadDetails?.email?.length > 0
            ? selectedLeadDetails?.email[0].email_address
            : "",
        phone_mobile: selectedLeadDetails.phone_mobile,
        Address: "",
        sugarLeadId: selectedLeadDetails?.id,
        vehicleInfo: vehicleInfo,
      };
      setSugarLeadData(payload);
    } else {
      const payload = {
        firstName: "",
        lastName: "",
        location: {},
        email: "",
        phone: "",
        Address: "",
        sugarLeadId: "",
        vehicleInfo: vehicleInfo,
      };
      setSugarLeadData(payload);
    }
  }, [selectedLeadDetails, vehicleInfo, modalDetailsShow]);

  // Run on change of search query with delayed debounce
  useEffect(() => {
    handleSearchSugarByLeadName(leadNameSearchQuery);
  }, [leadNameSearchQuery]);

  // Run on change of vehicleInfo
  useEffect(() => {
    if (vehicleInfo) {
      setStdSpecs(vehicleInfo?.CarSpecs?.stdSpec);
      setSpecifications(vehicleInfo?.CarSpecs?.Specifications);
      setFactoryOptions(vehicleInfo?.CarSpecs["Factory Options"]);
      setFactoryEquipment(vehicleInfo?.CarSpecs["Factory Equipment"]);
    }
  }, [JSON.stringify(vehicleInfo)]);

  // Run on change of filterStdSpecs to search for any word in the array of stdSpecs
  useEffect(() => {
    if (stdSpecs?.length > 0) {
      const filtered = stdSpecs.filter((spec) =>
        spec.toLowerCase().includes(filterStdSpecs.toLowerCase())
      );
      setFilteredStdSpecs(filtered);
    }

    if (Specifications?.length > 0) {
      const filtered = Specifications.filter((spec) =>
        spec.Description.toLowerCase().includes(
          filterSpecifications.toLowerCase()
        )
      );
      setFilteredSpecifications(filtered);
    }

    if (FactoryOptions?.length > 0) {
      const filtered = FactoryOptions.filter((spec) =>
        spec.Description.toLowerCase().includes(
          filterFactoryOptions.toLowerCase()
        )
      );
      setFilteredFactoryOptions(filtered);
    }

    if (FactoryEquipment?.length > 0) {
      const filtered = FactoryEquipment.filter((spec) =>
        spec.Description.toLowerCase().includes(
          filterFactoryEquipment.toLowerCase()
        )
      );
      setFilteredFactoryEquipment(filtered);
    }
  }, [
    filterStdSpecs,
    filterSpecifications,
    filterFactoryOptions,
    filterFactoryEquipment,
    JSON.stringify(stdSpecs),
    JSON.stringify(Specifications),
    JSON.stringify(FactoryOptions),
    JSON.stringify(FactoryEquipment),
  ]);

  // Initialise tesseract worker on init load
  // useEffect(() => {
  //   const initWorker = async () => {
  //     await worker.load();
  //     await worker.loadLanguage("eng");
  //     await worker.initialize("eng");
  //   };
  //   initWorker();
  // }, []);

  // useEffect(() => {
  //   toast.info(workerStatus);
  // }, [workerStatus]);

  return (
    <>
      {/* START VIN SEARCH FIELD */}
      <BackdropComponent loading={loading} />
      <Container>
        <Row style={{ paddingBottom: 15 }}>
          <Col>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="VIN - Search Full VIN"
                onChange={(e) => handleVinChange(e)}
                value={vin}
              ></Form.Control>
              <InputGroup.Append>
                <Button variant="info" onClick={vinImageRefClick}>
                  {isLoadingVinImageRecog ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <>
                      <Camera />
                    </>
                  )}
                  <input
                    disabled={isLoadingVinImageRecog}
                    type="file"
                    id="vin-image"
                    ref={vinImageRef}
                    style={{ display: "none" }}
                    onChange={handleVinImageChange}
                  />
                </Button>

                <Button variant="dark" onClick={() => getData(0)}>
                  Decode VIN
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
      </Container>
      {/* END VIN SEARCH FIELD */}

      {/* START HEADER INFO SECTION */}
      {width > 1000 ? (
        <Container>
          {/* Start If vehicle data is found */}
          {vehicleInfo ? (
            <Card
              style={{
                fontSize: 14,
                marginBottom: 25,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              }}
            >
              <Table size="sm">
                <thead>
                  <tr>
                    <th>VIN</th>
                    <th>Description</th>
                    <th>Compliance Date</th>
                    <th>Decoded at</th>
                    <th>Decoded by</th>
                  </tr>
                </thead>

                <tbody>
                  <td>{vehicleInfo?.VinID}</td>
                  <td>{vehicleInfo?.Description}</td>
                  <td>{vehicleInfo?.CompMth + " / " + vehicleInfo?.CompYr}</td>
                  <td>
                    <Badge variant="success" style={{ padding: 5 }}>
                      {dayjs.unix(vehicleInfo.created_at).format("DD/MM/YYYY")}
                    </Badge>
                  </td>
                  <td>{vehicleInfo?.callerName}</td>
                </tbody>
              </Table>
            </Card>
          ) : null}
          {/* End If vehicle data is found */}

          {/* Start if multiple rbcodes are found */}
          {multipleRedbookFound.length > 0
            ? `${multipleRedbookFound.length} variants found for this VIN, an option to select the correct one is coming soon`
            : null}

          {/* End if multiple rbcodes are found */}
        </Container>
      ) : (
        <Container>
          {/* Start If vehicle data is found */}
          {vehicleInfo ? (
            <Card
              style={{
                fontSize: 14,
                marginBottom: 25,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              }}
            >
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Description</th>
                  </tr>
                </thead>

                <tbody>
                  <td>{vehicleInfo?.Description}</td>
                </tbody>
              </Table>
            </Card>
          ) : null}
          {/* End If vehicle data is found */}

          {/* Start if multiple rbcodes are found */}
          {multipleRedbookFound.length > 0
            ? `${multipleRedbookFound.length} variants found for this VIN, an option to select the correct one is coming soon`
            : null}

          {/* End if multiple rbcodes are found */}
        </Container>
      )}

      {/* END HEADER INFO SECTION */}

      {/* START ACTION BUTTONS SECTION */}
      <Container style={{ marginBottom: 10 }}>
        {/* <ButtonGroup size="sm">
          {vehicleInfo ? (
            <Button
              onClick={() => setModalDetailsShow(true)}
              variant="outline-primary"
            >
              Create/update lead in Sugar
            </Button>
          ) : null}
        </ButtonGroup> */}
      </Container>
      {/* END ACTION BUTTONS SECTION */}

      {/* START Vehicle details Section */}
      {vehicleInfo ? (
        <Container>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Property</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Make</td>
                <td>{vehicleInfo?.Make}</td>
              </tr>
              <tr>
                <td>Model</td>
                <td>{vehicleInfo?.Model}</td>
              </tr>
              <tr>
                <td>Series</td>
                <td>{vehicleInfo?.Series}</td>
              </tr>
              <tr>
                <td>Badge</td>
                <td>{vehicleInfo?.Variant}</td>
              </tr>
              <tr>
                <td>Body</td>
                <td>{vehicleInfo?.Body}</td>
              </tr>
              <tr>
                <td>Transmission</td>
                <td>{vehicleInfo?.Transmission}</td>
              </tr>
              <tr>
                <td>Engine</td>
                <td>{vehicleInfo?.Engine}</td>
              </tr>
              <tr>
                <td>Build Date</td>
                <td>{vehicleInfo?.BuildMth + " / " + vehicleInfo?.BuildYr}</td>
              </tr>
              <tr>
                <td>Compliance Date</td>
                <td>{vehicleInfo?.CompMth + " / " + vehicleInfo?.CompYr}</td>
              </tr>
              <tr>
                <td>Model Year</td>
                <td>{vehicleInfo?.Year}</td>
              </tr>
              <tr>
                <td>Fuel</td>
                <td>{vehicleInfo?.Fuel}</td>
              </tr>
              <tr>
                <td>Seats</td>
                <td>{vehicleInfo?.Seats}</td>
              </tr>
            </tbody>
          </Table>
          <br />
          <Tabs defaultActiveKey="specifications" id="uncontrolled-tab-specs">
            <Tab eventKey="specifications" title="Specifications">
              <Table striped bordered hover size="sm">
                <tbody style={{ fontSize: 13 }}>
                  <tr>
                    <td colSpan={2}>
                      {/* Search */}
                      <Form.Group>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Start typing to search..."
                            value={filterStdSpecs}
                            onChange={(e) => setFilterStdSpecs(e.target.value)}
                          ></Form.Control>
                          <InputGroup.Append>
                            <Button
                              onClick={() => {
                                setFilterStdSpecs("");
                              }}
                            >
                              x
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </td>
                  </tr>
                  {filteredStdSpecs?.map((spec, idx) => {
                    return (
                      <tr key={idx}>
                        <td colSpan={2} style={{ textAlign: "left" }}>
                          {spec}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey="standard_specs" title="Standard Specs">
              <Table striped bordered hover size="sm">
                <tbody style={{ fontSize: 13 }}>
                  <tr>
                    <td colSpan={2}>
                      {/* Search */}
                      <Form.Group>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Start typing to search..."
                            value={filterSpecifications}
                            onChange={(e) =>
                              setFilterSpecifications(e.target.value)
                            }
                          ></Form.Control>
                          <InputGroup.Append>
                            <Button
                              onClick={() => {
                                setFilterSpecifications("");
                              }}
                            >
                              x
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </td>
                  </tr>

                  {filteredSpecifications?.map((spec, idx) => {
                    return (
                      <tr key={idx}>
                        <td colSpan={2} style={{ textAlign: "left" }}>
                          {spec.Description || ""}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey="factory_options" title="Factory Options">
              <Table striped bordered hover size="sm">
                <tbody style={{ fontSize: 13 }}>
                  <tr>
                    <td colSpan={2}>
                      {/* Search */}
                      <Form.Group>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Start typing to search..."
                            value={filterFactoryOptions}
                            onChange={(e) =>
                              setFilterFactoryOptions(e.target.value)
                            }
                          ></Form.Control>
                          <InputGroup.Append>
                            <Button
                              onClick={() => {
                                setFilterFactoryOptions("");
                              }}
                            >
                              x
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </td>
                  </tr>
                  {filteredFactoryOptions?.map((spec, idx) => {
                    return (
                      <tr key={idx}>
                        <td colSpan={2} style={{ textAlign: "left" }}>
                          {spec.Description || ""}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey="factory_equipment" title="Factory Equipment">
              <Table striped bordered hover size="sm">
                <tbody style={{ fontSize: 13 }}>
                  <tr>
                    <td colSpan={2}>
                      {/* Search */}
                      <Form.Group>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Start typing to search..."
                            value={filterFactoryEquipment}
                            onChange={(e) =>
                              setFilterFactoryEquipment(e.target.value)
                            }
                          ></Form.Control>
                          <InputGroup.Append>
                            <Button
                              onClick={() => {
                                setFilterFactoryEquipment("");
                              }}
                            >
                              x
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </td>
                  </tr>
                  {filteredFactoryEquipment?.map((spec, idx) => {
                    return (
                      <tr key={idx}>
                        <td colSpan={2} style={{ textAlign: "left" }}>
                          {spec.Description || ""}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </Container>
      ) : null}

      {/* END Vehicle details Section */}

      {/* START New Lead in Sugar Customer Modal  */}
      <Modal
        show={modalDetailsShow}
        size="lg"
        onHide={hideCustomerInformationModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Customer information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ paddingBottom: 15 }}>
            <Col>
              <Form.Group>
                <Form.Label>
                  <h5>Search existing lead by customer name</h5>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Start typing customer's name..."
                    onChange={(e) => setLeadNameSearchQuery(e.target.value)}
                    // value={leadNameSearchQuery}
                  ></Form.Control>
                  <InputGroup.Append>
                    <Button
                      disabled={loading}
                      onClick={() =>
                        handleSearchSugarByLeadName(leadNameSearchQuery)
                      }
                      variant="info"
                    >
                      <Search />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          {searchCompleted ? (
            <Row>
              <Col>
                <Card style={{ marginBottom: 15, padding: 15 }}>
                  {searchLeadResults?.length > 0 ? (
                    <>
                      <h4>Search results: </h4>
                      <h6>
                        Please select a lead from the results below if you want
                        to update the vehicle information rather than create a
                        new lead
                      </h6>
                    </>
                  ) : (
                    <>
                      <h4>Search results: </h4>
                      <h6>Could not find any leads...</h6>
                    </>
                  )}
                  {searchLeadResults?.map((s, idx) => {
                    return (
                      <Card
                        key={idx}
                        onClick={() => {
                          console.log(searchLeadResults[idx]);
                          setSelectedLeadDetails(s);
                        }}
                        style={{
                          cursor: "pointer",
                          marginBottom: 5,
                          padding: 5,
                          backgroundColor:
                            s.id === selectedLeadDetails?.id ? "#d4edda" : "",
                          borderColor:
                            s.id === selectedLeadDetails?.id ? "#d4edda" : "",
                          color:
                            s.id === selectedLeadDetails?.id ? "#155724" : "",
                          boxShadow:
                            s.id === selectedLeadDetails?.id
                              ? "0 3px 10px rgb(0 0 0 / 0.2)"
                              : "",
                        }}
                      >
                        {idx + 1} - {s.name}
                      </Card>
                    );
                  })}
                </Card>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <Card style={{ padding: 15 }}>
                <h4>Lead information: </h4>
                <Formik
                  initialValues={sugarLeadData}
                  enableReinitialize={true}
                  disabled
                  // validationSchema={validationSchema}
                  // validateOnChange={true}
                  // validateOnBlur={true}
                  onSubmit={async (values, { resetForm, errors }) => {
                    if (values?.sugarLeadId?.length > 0) {
                      // Update
                      const payload = {
                        vehicleInfo: values.vehicleInfo,
                        leadData: {},
                        sugarLeadId: values.sugarLeadId,
                      };

                      setLoading(true);
                      var updateLead = functions.httpsCallable(
                        "updateVehicleInfoIntoExistingSugarCrmLead"
                      );
                      await updateLead(payload)
                        .then((result) => {
                          // Read result of the Cloud Function.
                          var sanitizedMessage = result.data;
                          if (sanitizedMessage === false) {
                            throw "Error";
                          } else {
                            if (sanitizedMessage.error?.length > 0) {
                              toast.warning(sanitizedMessage.error);
                              if (sanitizedMessage.errorCode === 933) {
                                //Multiple Redbook codes found
                                // setMultipleRedbookFound(sanitizedMessage.rbcodes);
                              }
                            } else {
                              toast.success("Update lead", values.sugarLeadId);
                              // console.log(sanitizedMessage);
                              // setSearchLeadResults(sanitizedMessage.records);
                            }
                          }
                        })
                        .catch((e) => {
                          toast.error(e);
                          console.log(e);
                        });
                      setLoading(false);
                    } else {
                      if (
                        values.firstName.length === 0 ||
                        values.lastName.length === 0 ||
                        values.phone_mobile.length === 0 ||
                        values.email1.length === 0
                      ) {
                        toast.error("Please enter lead info");
                        return;
                      }
                      // Create
                      const payload = {
                        vehicleInfo: values.vehicleInfo,
                        leadData: {
                          firstName: values.firstName,
                          lastName: values.lastName,
                          phone_mobile: values.phone_mobile,
                          email1: values.email1,
                        },
                      };
                      console.log(payload);

                      setLoading(true);
                      var createLead = functions.httpsCallable(
                        "createVehicleInfoIntoNewSugarCrmLead"
                      );
                      const sugarLeadId = await createLead(payload)
                        .then((result) => {
                          // Read result of the Cloud Function.
                          console.log(result);
                          var sanitizedMessage = result.data;
                          if (sanitizedMessage === false) {
                            throw "Error";
                          } else {
                            if (sanitizedMessage.error?.length > 0) {
                              toast.warning(sanitizedMessage.error);
                              if (sanitizedMessage.errorCode === 933) {
                                //Multiple Redbook codes found
                                // setMultipleRedbookFound(sanitizedMessage.rbcodes);
                              }
                            } else {
                              toast.success("Created lead");
                              console.log(sanitizedMessage);
                              setLeadCreated(true);
                              return sanitizedMessage;
                            }
                          }
                        })
                        .catch((e) => {
                          toast.error(e);
                          console.log(e);
                        });

                      // Search by new lead id
                      if (sugarLeadId === false) {
                        return;
                      }

                      var searchLead = functions.httpsCallable(
                        "searchForLeadByIdInSugar"
                      );
                      await searchLead({ sugarLeadId })
                        .then((result) => {
                          // Read result of the Cloud Function.
                          console.log(result);
                          var sanitizedMessage = result.data;
                          if (sanitizedMessage === false) {
                            throw "Error";
                          } else {
                            if (sanitizedMessage.error?.length > 0) {
                              toast.warning(sanitizedMessage.error);
                              if (sanitizedMessage.errorCode === 933) {
                                //Multiple Redbook codes found
                                // setMultipleRedbookFound(sanitizedMessage.rbcodes);
                              }
                            } else {
                              console.log(sanitizedMessage);
                              setSelectedLeadDetails(sanitizedMessage);
                            }
                          }
                        })
                        .catch((e) => {
                          toast.error(e);
                          console.log(e);
                        });

                      setLoading(false);
                    }
                  }}
                >
                  {/* Callback function containing Formik state and helpers that handle common form actions */}
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    isSubmitting,
                    resetForm,
                  }) => (
                    <Form autocomplete="off">
                      <>
                        {/* <pre>
                          <code></code> {JSON.stringify(values, null, 2)}
                        </pre> */}
                        {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                        <TextField
                          fieldname={`firstName`}
                          label="First name"
                          placeholder="John"
                          disabled={values?.sugarLeadId?.length > 0}
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />

                        {/* <ErrorMessage name={`location.address`}>
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage> */}
                        <TextField
                          fieldname={`lastName`}
                          label="Last name"
                          disabled={values?.sugarLeadId?.length > 0}
                          placeholder="Smith"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />

                        {values?.sugarLeadId?.length > 0 ? null : (
                          <Field
                            name="location"
                            component={FormikAutoCompleteComponentNewLocation}
                          />
                        )}

                        <TextField
                          fieldname={`phone_mobile`}
                          label="Phone"
                          disabled={values?.sugarLeadId?.length > 0}
                          placeholder="(03) 8722 1234"
                          type="tel"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                        <TextField
                          fieldname={`email1`}
                          label="Email"
                          disabled={values?.sugarLeadId?.length > 0}
                          // placeholder="510123"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />

                        <ButtonGroup block style={{ width: "100%" }}>
                          <Button
                            variant="danger"
                            type="reset"
                            onClick={() => {
                              setSelectedLeadDetails(initialLeadValues);
                              setSugarLeadData(initialLeadValues);
                              handleReset();
                            }}
                          >
                            Clear lead information
                          </Button>
                          <Button variant="success" onClick={handleSubmit}>
                            {values?.sugarLeadId
                              ? "Update vehicle information"
                              : "Create new lead in Sugar"}
                            {isSubmitting ? <Spinner animation="grow" /> : null}
                          </Button>
                        </ButtonGroup>
                        <ButtonGroup block style={{ width: "100%" }}>
                          <Button
                            // disabled={
                            //   values?.sugarLeadId?.length === 0 ||
                            //   typeof values?.sugarLeadId === "undefined"
                            // }
                            disabled
                            onClick={handleShowUploadFilesModal}
                            // variant={
                            //   values?.sugarLeadId?.length === 0 ||
                            //   typeof values?.sugarLeadId === "undefined"
                            //     ? "outline-primary"
                            //     : "primary"
                            // }
                            variant="outline-primary"
                          >
                            Add vehicle photos
                          </Button>
                          <Button
                            variant={
                              values?.sugarLeadId?.length === 0 ||
                              typeof values?.sugarLeadId === "undefined"
                                ? "outline-warning"
                                : "warning"
                            }
                            type="reset"
                            disabled={
                              values?.sugarLeadId?.length === 0 ||
                              typeof values?.sugarLeadId === "undefined"
                            }
                            onClick={() => {
                              window.open(
                                `https://crm.sellyourcarfast.com.au/#Leads/${values?.sugarLeadId}`,
                                "_blank"
                              );
                            }}
                          >
                            Open lead in SugarCRM
                          </Button>
                        </ButtonGroup>
                      </>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup size="md">
            {vehicleInfo ? (
              <Button
                onClick={hideCustomerInformationModal}
                variant="outline-danger"
              >
                Cancel
              </Button>
            ) : null}{" "}
          </ButtonGroup>
        </Modal.Footer>
      </Modal>

      {/* END New Lead in Sugar Customer Modal  */}

      {/* START Add files modal */}
      <Modal show={showUploadFilesModal} onHide={handleHideUploadFilesModal}>
        <Modal.Header>
          <h4>Add files</h4>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ marginTop: 10 }}>
            <Col>
              <FileDropZone />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success">Save files to Sugar</Button>
        </Modal.Footer>
      </Modal>
      {/* END Add files modal */}

      <ToastContainer />
      {vehicleInfo ? null : (
        <Container>
          <Row>
            {/* <Col md={{ span: 3, offset: 4 }}> */}
            <Col>
              <Image src={modalImage} fluid width={300} />
              {/* <Canvas draw={draw} /> */}
            </Col>
          </Row>
        </Container>
      )}
      <br />

      <PageViewLoggerComponent />
    </>
  );
}

export default VINDecodeComponent;
