import Table from "react-bootstrap/Table";
import copy from "copy-to-clipboard";
import dayjs from "dayjs";
import { HiClipboardCheck } from "react-icons/hi";
import Button from "react-bootstrap/Button";
import NumberFormat from "react-number-format";
import VehiclePhotos from "../VehiclePhotos";

const StockCardSummary = ({ veh }) => {
  const copyToClipboard = (v) => {
    copy(v);
  };

  if (!veh) return null;

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Property</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody style={{ fontSize: 12 }}>
        <tr>
          <td>Stock No</td>
          <td>{veh.VEHStockNumber}</td>
        </tr>
        <tr>
          <td>Current Status</td>
          <td
            style={{
              backgroundColor:
                veh?.CurrentStatus === "IN-STOCK" ? "#2ECC71" : "#D4E6F1",
              color: "black",
            }}
          >
            {veh.CurrentStatus}
          </td>
        </tr>
        <tr>
          <td>Year</td>
          <td>{veh.VEHModelYear}</td>
        </tr>
        <tr>
          <td>Make</td>
          <td>{veh.VEHMake}</td>
        </tr>
        <tr>
          <td>Model</td>
          <td>{veh.VEHModel}</td>
        </tr>
        <tr>
          <td>Variant</td>
          <td>{veh["Variant & Series"]}</td>
        </tr>
        <tr>
          <td>Colour</td>
          <td>{veh.VEHColour1}</td>
        </tr>
        <tr>
          <td>Odometer</td>
          <td>
            <NumberFormat
              displayType="text"
              thousandSeparator={true}
              value={veh.VEHOdo}
            />
          </td>
        </tr>
        <tr>
          <td>Branch / Dealership</td>
          <td>{veh.branch_desc}</td>
        </tr>
        <tr>
          <td>Current location / yard</td>
          <td>{veh.VEHYard}</td>
        </tr>
        <tr>
          <td>Buyer</td>
          <td>{veh.Buyer}</td>
        </tr>
        <tr>
          <td>VIN</td>
          <td>
            {veh.VEHVIN}{" "}
            <Button
              onClick={() => copyToClipboard(veh.VEHVIN)}
              size="sm"
              variant="outline-dark"
            >
              <HiClipboardCheck size={20} />
            </Button>
          </td>
        </tr>
        <tr>
          <td>Age in stock</td>
          <td>
            {dayjs().diff(dayjs.unix(veh.Purchase_Date.seconds), "day", false)}
            &nbsp;day(s)
          </td>
        </tr>
        <tr>
          <td>Vicroads serial</td>
          <td>{veh.vicroadsserial}</td>
        </tr>
        <tr>
          <td colSpan={2}>
            <VehiclePhotos stockNumber={veh?.VEHStockNumber} />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default StockCardSummary;
