import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import Alert from "react-bootstrap/Alert";
import VehiclePhotos from "../VehiclePhotos";
import {
  CheckSquare,
  EmojiExpressionless,
  EmojiFrown,
  EmojiNeutral,
  EmojiSmile,
} from "react-bootstrap-icons";
import { Table } from "react-bootstrap";

// Colour codes by site
const siteColours = {
  "AMW - ALBION": "#E74C3C",
  "AMW - PLYMPTON": "#9B59B6",
  "AMW - DANDENONG": "#2980B9",
  "AMW - MELBOURNE": "#1ABC9C",
  "AMW - WESTGATE": "#F1C40F",
  "AMW - FAIRFIELD": "#DC7633",
};

const renderEmoji = (age) => {
  if (age <= 45) {
    return <EmojiSmile color="black" size={24} />;
  }
  if (age > 45 && age <= 90) {
    return <EmojiNeutral color="black" size={24} />;
  }
  if (age > 90) {
    return <EmojiFrown color="black" size={24} />;
  }
};

const renderEmojiBackground = (age) => {
  if (age <= 45) {
    return "#ABEBC6";
  }
  if (age > 45 && age <= 90) {
    return "#FAE5D3";
  }
  if (age > 90) {
    return "#F5B7B1";
  }
};

const InsightFeatureCard = ({ data, images, inventoryData }) => {
  const renderInStockComparisons = (model, year) => {
    const inStock = inventoryData.filter((item) => item["VEHmodel"] === model);
    // Unique years
    const uniqueYears = [
      ...new Set(inStock.map((item) => item["VEHModelYear"])),
    ];
    // Sort years
    uniqueYears.sort((a, b) => a - b);
    // console.log(uniqueYears);

    return (
      <Table size="sm" striped>
        <thead>
          <tr>
            <th>Year / Model</th>
            <th># In Stock</th>
          </tr>
        </thead>
        <tbody>
          {uniqueYears.length === 0 ? (
            <tr>
              <td colSpan={2}>No stock found</td>
            </tr>
          ) : (
            uniqueYears.map((year, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    {year} {model}
                  </td>
                  <td>
                    {
                      inStock.filter((item) => item["VEHModelYear"] === year)
                        .length
                    }
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <Container fluid>
        <Col>
          <Row className="justify-content-md-center">
            <Card
              // border={siteColours[data?.Division] || "light"}
              bg={data?.TrueProfit <= 0 ? "light" : "light"}
              style={{
                width: "100%",
                border: `1px solid ${siteColours[data?.Division]}`,
              }}
            >
              {/* <VehiclePhotos stockNumber={data["Stock No"]} /> */}

              <Card.Header
                style={{
                  backgroundColor: siteColours[data?.Division] || "light",
                }}
              >
                {data?.Division}
              </Card.Header>
              <Card.Img
                variant="top"
                src={images[data["Stock No"]]?.photos[0]}
                style={{ width: "100%" }}
              />
              <Card.Body>
                <Card.Title
                  style={{
                    color: data?.Age < 45 ? "green" : null,
                  }}
                >
                  {data["Stock No"] +
                    " - " +
                    data?.VEHModelYear +
                    " " +
                    data?.VEHMake +
                    " " +
                    data?.VEHModel}
                </Card.Title>

                <Card.Subtitle>
                  {typeof data?.Variant_and_Series === "undefined"
                    ? ""
                    : data?.Variant_and_Series}
                </Card.Subtitle>
                <Card.Text>
                  <Col>
                    <Row className="justify-content-md-center">
                      Colour: &nbsp;
                      {data?.VEHColour1}
                    </Row>
                  </Col>
                  <Col>
                    <Row className="justify-content-md-center">
                      Variant: &nbsp;
                      {data["Variant & Series"] || ""}
                    </Row>
                  </Col>
                  <Col>
                    <Row className="justify-content-md-center">
                      Odo: &nbsp;
                      <NumberFormat
                        // prefix=""
                        suffix=" kms"
                        thousandSeparator={true}
                        decimalScale={0}
                        displayType="text"
                        value={data?.VEHOdoOut}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row className="justify-content-md-center">
                      Sale Price inc. GST: &nbsp;
                      <NumberFormat
                        prefix=" $"
                        thousandSeparator={true}
                        decimalScale={0}
                        displayType="text"
                        value={data?.SalePriceInc}
                      />
                    </Row>
                  </Col>

                  <Col>
                    <Row className="justify-content-md-center">
                      True Profit ex. GST: &nbsp;
                      <NumberFormat
                        prefix=" $"
                        thousandSeparator={true}
                        decimalScale={0}
                        displayType="text"
                        value={data?.TrueProfit}
                        style={{
                          color: data?.TrueProfit <= 0 ? "red" : "green",
                        }}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row className="justify-content-md-center">
                      True Profit inc. GST: &nbsp;
                      <NumberFormat
                        prefix=" $"
                        thousandSeparator={true}
                        decimalScale={0}
                        displayType="text"
                        value={data?.TrueProfit * 1.1}
                        style={{
                          color: data?.TrueProfit * 1.1 <= 0 ? "red" : "green",
                        }}
                      />
                    </Row>
                  </Col>

                  <Col>
                    <Row className="justify-content-md-center">
                      Number of leads: &nbsp;
                      <NumberFormat
                        prefix=""
                        thousandSeparator={true}
                        decimalScale={0}
                        displayType="text"
                        value={Math.floor(data?.CountOfLeads / 2)}
                      />
                    </Row>
                  </Col>

                  <Col>
                    <Row className="justify-content-md-center">
                      Sold in: &nbsp;
                      <NumberFormat
                        prefix=""
                        suffix=" days"
                        thousandSeparator={true}
                        decimalScale={0}
                        displayType="text"
                        value={data?.Age}
                        style={{
                          color: data?.Age >= 45 ? "red" : "green",
                        }}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row className="justify-content-md-center">
                      Buyer: &nbsp;
                      {data?.VEH_Buyer === "House Sale"
                        ? "Wholesale"
                        : data?.Veh_Buyer}
                    </Row>
                  </Col>

                  <Col>
                    <Row className="justify-content-md-center">
                      Sale date: &nbsp;
                      {dayjs.unix(data["Sale Date EPOC"]).format("DD-MM-YYYY")}
                    </Row>
                  </Col>

                  <Col>
                    <Row className="justify-content-md-center">
                      {data?.Finance_Units ? (
                        <Alert variant="info">F</Alert>
                      ) : null}
                      {data?.Insurance_Units ? (
                        <Alert variant="warning">I</Alert>
                      ) : null}
                      {data?.Warranty_Units ? (
                        <Alert variant="success">W</Alert>
                      ) : null}
                    </Row>
                  </Col>
                  <Col>
                    <Row className="justify-content-md-center">
                      {renderInStockComparisons(
                        data?.VEHModel,
                        data?.VEHModelYear
                      )}
                    </Row>
                  </Col>
                </Card.Text>
                {/* <Button variant="primary">Search...</Button> */}
              </Card.Body>
              <Card.Footer
                style={{ backgroundColor: renderEmojiBackground(data?.Age) }}
              >
                {renderEmoji(data?.Age)}
              </Card.Footer>
            </Card>
          </Row>
        </Col>
      </Container>
    </>
  );
};

export default InsightFeatureCard;
