import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  Form,
  Button,
  Container,
  ButtonGroup,
  Spinner,
  Col,
  Card,
  InputGroup,
} from "react-bootstrap";

const google = window.google;

const searchOptions = {
  location: new google.maps.LatLng(-34, 151),
  radius: 2000,
  types: ["address"],
};

class FormikAutoCompleteComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.field.name,
      address: "",
    };
  }

  handleError = (error) => {
    this.props.form.setFieldError(this.state.name, error);
  };

  handleChange = (address) => {
    this.setState(() => {
      this.props.form.setFieldTouched(`${this.state.name}.value`);
      this.props.form.setFieldTouched(`${this.state.name}.address`);
      this.props.form.setFieldValue(this.state.name, { value: address });
      return { address };
    });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState(() => {
          this.props.form.setFieldValue(this.state.name, {
            value: address,
            address,
            coordinates: latLng,
          });
          this.props.form.setFieldValue();
          return { address };
        });
      })
      .catch((error) => this.props.form.setFieldError(this.state.name, error));
  };

  render() {
    const {
      field: { name, ...field }, // { name, value, onChange, onBlur }
      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      classes,
      label,
      ...props
    } = this.props;

    const error = errors[name];
    const touch = touched[name];
    // console.log(this.props.field.name);
    // console.log(errors);
    return (
      <Form.Group controlId={props.name}>
        <Form.Label>Address</Form.Label>
        <Form.Row>
          <Col>
            <PlacesAutocomplete
              name={name}
              id={name}
              {...props}
              value={this.state.address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
              onError={this.handleError}
              highlightFirstSuggestion={true}
              searchOptions={searchOptions}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: "Search Address ...",
                      className: "location-search-input form-control",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                  {errors[this.state.name]?.address?.length > 0 ? (
                    <div style={{ color: "red" }}>
                      {errors.location.address} - please select from drop down
                      list which provides accurate address geo-location
                    </div>
                  ) : null}
                </div>
              )}
            </PlacesAutocomplete>
          </Col>
        </Form.Row>
      </Form.Group>
    );
  }
}

export default FormikAutoCompleteComponent;
