import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  Line,
  ComposedChart,
} from "recharts";
import useTransformToRechartsFormat from "../../../../hooks/useTransformToRechartsFormat";

const CompoundChartDash = ({ os_graph_data, chartInfo }) => {
  const chartData = useTransformToRechartsFormat(os_graph_data, chartInfo);

  return (
    // <ResponsiveContainer width={500} height={400}>
    <ComposedChart
      width={500}
      height={300}
      data={chartData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 15,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={"name"} />
      {chartInfo.yaxis.axese?.map((y, idx) => {
        return (
          <YAxis
            yAxisId={y.yAxisId}
            key={idx}
            domain={[
              0,
              (dataMax) => {
                const dM = dataMax * 1.2;
                return Math.floor(dM);
              },
            ]}
            padding={{ top: 20 }}
          />
        );
      })}
      <Tooltip />
      <Legend verticalAlign="top" align="right" />
      {chartInfo.xaxis.totalSeries.map((s, idx) => {
        if (s.chartType === "Line") {
          return (
            <Line
              yAxisId={s.yAxisId}
              dataKey={s.name}
              stroke={s.colour}
              key={idx}
            >
              <LabelList dataKey={s.name} position="top" />
            </Line>
          );
        } else if (s.chartType === "Bar") {
          return (
            <Bar yAxisId={s.yAxisId} dataKey={s.name} fill={s.colour} key={idx}>
              <LabelList dataKey={s.name} position="top" />
            </Bar>
          );
        } else {
          return (
            <Bar yAxisId={s.yAxisId} dataKey={s.name} fill={s.colour} key={idx}>
              <LabelList dataKey={s.name} position="top" />
            </Bar>
          );
        }
      })}
    </ComposedChart>
    // </ResponsiveContainer>
  );
};

export default CompoundChartDash;
