import { useStorageDownloadURL, useStorage, firestore } from "reactfire";
import { groupBy as rowGrouper } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useMemo, useCallback } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContainer from "react-bootstrap/TabContainer";
import { Formik } from "formik";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import { useEffect } from "react";
import { useRef } from "react";
import NumberFormat from "react-number-format";
import DataGrid from "react-data-grid";
import dayjs from "dayjs";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import BookingCard from "./BookingCard";
import PageViewLoggerComponent from "../PageViewLoggerComponent";
// import { FaTruckMoving } from "react-icons/fa";
import { HiOutlineTruck } from "react-icons/hi";
import { BsBoxArrowInRight, BsExclamationCircle } from "react-icons/bs";
import { FaSearch, FaFilter, FaRegTimesCircle } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory } from "react-router";
import { CgDetailsMore } from "react-icons/cg";
import TextField from "./TextField";
import Accordion from "react-bootstrap/Accordion";
import { Vinyl } from "react-bootstrap-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  useParams,
} from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import BackdropComponent from "../BackdropComponent";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const numericalASCProfit = (a, b) => {
  if (a.TrueProfit < b.TrueProfit) {
    return -1;
  }
  if (a.TrueProfit > b.TrueProfit) {
    return 1;
  }
  return 0;
};

const style = {
  dataGridFill: {
    height: "70vh",
  },
  searchCard: {
    margin: 5,
    padding: 5,
    border: "1px solid #efefef",
    borderRadius: 3,
    cursor: "pointer",
  },
};

const SearcResultMakeFieldSID = (r) => {
  const [veh, setVeh] = useState({});

  useEffect(() => {
    const tmpVeh = r.vehicleItem.find(
      (v) => v.EclipseStockNumber === parseInt(r.serachQStockId)
    );

    if (tmpVeh) {
      setVeh(tmpVeh);
    }
  }, [r]);

  return <p>{veh?.make}</p>;
};

const SearcResultMakeFieldVIN = (r) => {
  const [veh, setVeh] = useState({});

  useEffect(() => {
    const tmpVeh = r.vehicleItem.find((v) => v.VIN === r.searchQVin);

    if (tmpVeh) {
      setVeh(tmpVeh);
    }
  }, [r]);

  return <p>{veh?.make}</p>;
};

const SearchResults = ({ results, searchQ }) => {
  const history = useHistory();
  return (
    <>
      <h4>Search results</h4>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Created</th>
            <th>Make</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {results.map((r) => {
            return (
              <OverlayTrigger
                placement="auto-start"
                delay={{ show: 10, hide: 50 }}
                overlay={<Tooltip id="button-tooltip-2">{r.id}</Tooltip>}
              >
                <tr
                  onClick={() => history.push(`/freight-booking-list/${r.id}`)}
                >
                  <td>{dayjs.unix(r.createdAt?.seconds).format("DD-MM-YY")}</td>
                  {/* <td>{`Click here to open BID:${r.id}`}</td> */}
                  <td>
                    {r.searchQVin === null
                      ? SearcResultMakeFieldSID(r)
                      : SearcResultMakeFieldVIN(r)}
                  </td>
                  <td>{r.status}</td>
                </tr>
              </OverlayTrigger>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

function FreightListFilteredTableView() {
  const [freightBookingList, setFreightBookingList] = useState([]);
  const [querySearchResults, setQuerySearchResults] = useState([]);
  const [createdOriginSlice, setCreatedOriginSlice] = useState("");
  const [viewTypeRequest, setViewTypeRequest] = useState(1); //0 LIST, 1 CARD
  const [viewTypeBooked, setViewTypeBooked] = useState(0); //0 LIST, 1 CARD
  const [viewTypeTransit, setViewTypeTransit] = useState(0); //0 LIST, 1 CARD
  const [viewTypeFinalised, setViewTypeFinalised] = useState(0); //0 LIST, 1 CARD
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [[sortColumn, sortDirection], setSort] = useState(["id", "NONE"]);

  const tableColumns = [
    {
      key: "actions",
      name: "Actions",
      width: 100,
      formatter({ row }) {
        return (
          <>
            {row.queuedActionsOutstanding ? (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id={row.id}>
                    There are queued actions currently processing in Eclipse
                  </Tooltip>
                }
              >
                <BsExclamationCircle style={{ backgroundColor: "yellow" }} />
              </OverlayTrigger>
            ) : null}
            {row.vehicleItem.length > 1 ? (
              <Badge
                pill
                bg="danger"
                style={{ backgroundColor: "#6371de", color: "#FFF" }}
              >
                {row.vehicleItem.length}
              </Badge>
            ) : null}
            {/* <Button
              variant="link"
              onClick={() => {
                history.push(`/freight-booking-list/${row.id}`);
              }}
            >
              <CgDetailsMore style={{ color: "green" }} />
            </Button> */}
            <Link target={"_blank"} to={`/freight-booking-list/${row.id}`}>
              <CgDetailsMore style={{ color: "green" }} />
            </Link>
          </>
        );
      },
    },

    { key: "id", name: "Booking Id" },
    {
      key: "createdAt",
      name: "Created",
      formatter({ row }) {
        return (
          <>{dayjs.unix(row["createdAt"].seconds).format("DD-MMM-YY HH:MM")}</>
        );
      },
      width: 160,
    },
    { key: "displayName", name: "Created By" },
    // bu_divisions_leads_1_name
    {
      key: "extraDetails.sugarCrmLeadData.assigned_user_name",
      name: "Sugar Buyer",
      formatter({ row }) {
        return <>{row.extraDetails?.sugarCrmLeadData?.assigned_user_name}</>;
      },
      // width: 300,
    },
    {
      key: "extraDetails.sugarCrmLeadData.bu_divisions_leads_1_name",
      name: "Sugar BU",
      formatter({ row }) {
        return (
          <>{row.extraDetails?.sugarCrmLeadData?.bu_divisions_leads_1_name}</>
        );
      },
      // width: 300,
    },
    { key: "status", name: "Status" },
    {
      key: "pickup.location.address",
      name: "From",
      formatter({ row }) {
        return <>{row.pickup?.location?.address}</>;
      },
      width: 300,
    },
    {
      key: "dropOff.location.address",
      name: "To",
      formatter({ row }) {
        return <>{row.dropOff?.location?.address}</>;
      },
      width: 300,
    },

    {
      key: "vehicle.stock",
      name: "Stock #",
      formatter({ row }) {
        return <>{row.vehicleItem[0]?.EclipseStockNumber}</>;
      },
    },
    {
      key: "vehicle.branch",
      name: "Origin branch",
      formatter({ row }) {
        return <>{row.vehicleItem[0]?.branch}</>;
      },
      width: 150,
    },
    {
      key: "vehicle.make",
      name: "Make",
      formatter({ row }) {
        return <>{row.vehicleItem[0]?.make}</>;
      },
    },
    {
      key: "vehicle.model",
      name: "Model",
      formatter({ row }) {
        return <>{row.vehicleItem[0]?.model}</>;
      },
    },
    {
      key: "directions.distance",
      name: "Distance (km)",
      formatter({ row }) {
        return <>{row.directions[0]?.distance}</>;
      },
    },
    {
      key: "carrierData.carrier.carrier",
      name: "Carrier",
      formatter({ row }) {
        return <>{row.carrierData?.carrier?.carrier}</>;
      },
    },
    {
      key: "carrierData.estimatedCost",
      name: "Carrier $EST",
      formatter({ row }) {
        return (
          <>
            <NumberFormat
              thousandSeparator={true}
              displayType="text"
              prefix={`$ `}
              value={row.carrierData?.estimatedCost}
            />
          </>
        );
      },
    },
    {
      key: "carrierData.estimatedCollectionDate",
      name: "ETC",
      formatter({ row }) {
        return <>{row.carrierData?.estimatedCollectionDate}</>;
      },
      width: 100,
    },
    {
      key: "carrierData.estimatedDeliveryDate",
      name: "ETD",
      formatter({ row }) {
        return <>{row.carrierData?.estimatedDeliveryDate}</>;
      },
      width: 100,
    },
    {
      key: "carrierData.actualDeliveredDate",
      name: "ADD",
      formatter({ row }) {
        return <>{row.carrierData?.actualDeliveredDate}</>;
      },
      width: 100,
    },
    {
      key: "carrierData.eclipseArrivalDate",
      name: "EAD",
      formatter({ row }) {
        return <>{row.carrierData?.eclipseArrivalDate}</>;
      },
      width: 100,
    },
    {
      key: "carrierData.daysToDelivered",
      name: "DTD",
      formatter({ row }) {
        const ad = dayjs(
          row.carrierData?.actualDeliveredDate || undefined,
          "YYYY-MM-DD"
        );
        const cdu = row.createdAt.seconds;
        const cd = dayjs.unix(cdu);

        const dtd = ad.diff(cd, "day");
        if (!isNaN(dtd)) {
          return <>{dtd}</>;
        } else {
          return <>N/A</>;
        }
      },
      width: 100,
    },
  ];

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const history = useHistory();

  // SORT rows
  const sortedRows = useMemo(() => {
    if (sortDirection === "NONE") return rows;

    let sortedRows = [...rows];

    switch (sortColumn) {
      case "createdAt":
        sortedRows = sortedRows.sort(
          (a, b) => a[sortColumn].seconds > b[sortColumn].seconds
        );
        break;

      case "id":
      case "status":
      case "displayName":
      case "carrierData.carrier.carrier":
        sortedRows = sortedRows.sort(
          (a, b) =>
            a[sortColumn]?.carrierData?.carrier?.carrier -
            b[sortColumn]?.carrierData?.carrier?.carrier
        );
        break;
      default:
    }

    return sortDirection === "DESC" ? sortedRows.reverse() : sortedRows;
  }, [rows, sortDirection, sortColumn]);

  const handleSort = useCallback((columnKey, direction) => {
    console.log("s", columnKey, direction);
    setSort([columnKey, direction]);
  }, []);

  let { stage } = useParams();

  // Load dataset on mount
  useEffect(() => {
    // Filter by created origin (SugarCRM ...)
    if (createdOriginSlice === "SugarCRM") {
      setFreightBookingList([]);
      setIsLoading(true);
      const unsubscribe = firestore
        .collection("freight-bookings")
        .where("status", "==", stage)
        .where("extraDetails.apiOrigin", "==", createdOriginSlice)
        .orderBy("createdAt", "asc")
        //   .get()
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            toast.info("No open requests available at this time");
            setIsLoading(false);
            setFreightBookingList([]);
            return;
          }
          const tmpBookings = [];

          snapshot.forEach((doc) => {
            const tmpData = doc.data();
            tmpBookings.push({ ...tmpData, id: doc.id });
            //   console.log(`Getting bookings`);
          });
          // console.log(tmpBookings);
          setFreightBookingList(tmpBookings);
          setIsLoading(false);
        });

      return () => unsubscribe;
    } else {
      setFreightBookingList([]);
      setIsLoading(true);
      const unsubscribe = firestore
        .collection("freight-bookings")
        .where("status", "==", stage)
        .orderBy("createdAt", "asc")
        //   .get()
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            toast.info("No open requests available at this time");
            setIsLoading(false);
            setFreightBookingList([]);
            return;
          }
          const tmpBookings = [];

          snapshot.forEach((doc) => {
            const tmpData = doc.data();
            tmpBookings.push({ ...tmpData, id: doc.id });
            //   console.log(`Getting bookings`);
          });
          // console.log(tmpBookings);
          setFreightBookingList(tmpBookings);
          setIsLoading(false);
        });

      return () => unsubscribe;
    }
  }, [stage, createdOriginSlice]);

  useEffect(() => {
    setRows(freightBookingList);
  }, [freightBookingList]);
  return (
    <>
      <BackdropComponent loading={isLoading} />
      <DataGrid
        defaultColumnOptions={{ resizable: true, sortable: true }}
        columns={tableColumns}
        style={style.dataGridFill}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        rows={sortedRows}
        onSort={handleSort}
      />
    </>
  );
}

export default FreightListFilteredTableView;
