import { groupBy as rowGrouper } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useMemo, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
// import ImageModalComponent from "./ImageModalComponent";
import { Formik, useField } from "formik";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import { useEffect } from "react";
import { useRef } from "react";
// import { useVEHMakeModel } from "../hooks/useVEHMakeModel";
import NumberFormat from "react-number-format";
import DataGrid from "react-data-grid";
import dayjs from "dayjs";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import EmptyRowsRenderer from "../EmptyRowsRenderer";
import Badge from "react-bootstrap/Badge";
import PageViewLoggerComponent from "../PageViewLoggerComponent";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

/*
Unused imports
import Image from "react-bootstrap/Image";
import { Camera } from "react-bootstrap-icons";
import FormControl from "react-bootstrap/FormControl";
import Dropdown from "react-bootstrap/Dropdown";
import ProgressBar from "react-bootstrap/ProgressBar";
*/
// import { createWorker } from "tesseract.js";

const highlightClassname = `
  .rdg-cell {
    background-color: #7093db;
    color: white;
  }
  &:hover .rdg-cell {
    background-color: #7093db;
  }
`;

const dev = true;

let SALE_WINDOW = 7;

var initState = { vin: "", rego: "" };
if (dev) {
  initState.vin = "";
  initState.rego = "";
  initState.make = "";
  initState.model = "";
  initState.variant = "";
  // SALE_WINDOW = 1;
}

const inStockColumns = [
  {
    key: "publishingStatus",
    name: "Publishing Status",
    formatter({ row }) {
      return (
        <>
          <Badge pill variant="success">
            {row.publishingStatus}
          </Badge>
        </>
      );
    },
  },
  {
    key: "Purchase_Date",
    name: "Purchased",
    formatter({ row }) {
      return (
        <>
          <p>{dayjs.unix(row.Purchase_Date.seconds).format("DD-MM-YYYY")}</p>
        </>
      );
    },
  },
  // {
  //   key: "Purchase_Date",
  //   name: "Age",
  //   formatter({ row }) {
  //     return (
  //       <>
  //         <p>
  //           {dayjs()
  //             .to(dayjs.unix(row.Purchase_Date.seconds))
  //             .format("DD-MM-YYYY")}
  //         </p>
  //       </>
  //     );
  //   },
  // },
  {
    key: "branch_desc",
    name: "Branch",
    // width: "100",
  },
  {
    key: "VEHMake",
    name: "Make",
    groupFormatter({ childRows }) {
      return (
        <>
          {childRows[0].VEHMake}({childRows.reduce((prev) => prev + 1, 0)})
        </>
      );
    },
  },
  {
    key: "VEHModel",
    name: "Model",
    groupFormatter({ childRows }) {
      return (
        <>
          {childRows[0].VEHModel}({childRows.reduce((prev) => prev + 1, 0)})
        </>
      );
    },
  },
  {
    key: "VEHModelYear",
    name: "Year",
    // groupFormatter({ childRows }) {
    //   return (
    //     <>
    //       {Math.floor(
    //         childRows.reduce(
    //           (prev, { VEHModelYear }) => prev + VEHModelYear,
    //           0
    //         ) / childRows.length
    //       )}
    //     </>
    //   );
    // },
  },
  {
    key: "Variant & Series",
    name: "Variant",
    width: 200,
  },
  {
    key: "VEHColour1",
    name: "Colour",
  },

  {
    key: "Asking Price",
    name: "Asking",
    formatter({ row }) {
      return (
        <>
          <NumberFormat
            value={row["Asking Price"]}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={0}
            displayType="text"
          />
        </>
      );
    },
  },

  {
    key: "VEHStockNumber",
    name: "Stock No",
  },
];

const optionsInstockSummary = [
  {
    value: "VEHMake",
    label: "Make",
  },
  {
    value: "VEHModel",
    label: "Model",
  },
];

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function NotPublishedAMWComponent() {
  const [showDetails, setShowDetails] = useState(false);
  const [saleType, setSaleType] = useState([]);
  const [branchTable, setBranchTable] = useState([]);
  const [branchTableOnline, setBranchTableOnline] = useState([]);
  const [branchTableAge, setBranchTableAge] = useState([]);
  const [branchKeys, setBranchKeys] = useState([]);
  const [queryParams, setQueryParams] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [inStockVehicles, setInStockVehicles] = useState([]);
  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [searchResultsInStockInventory, setSearchResultsInStockInventory] =
    useState([]);
  const [searchResultSalesSummary, setSearchResultSalesSummary] = useState([]);
  const [vehicleInfoObj, setVehicleInfoObj] = useState({
    make: "",
    model: "",
    variant: "",
    odo: "",
    price: "",
  });
  const [modalDetailsShow, setModalDetailsShow] = useState(false);

  // Datagrid states
  const [selectedRowsISI, setSelectedRowsISI] = useState(() => new Set());
  const [selectedRowsRS, setSelectedRowsRS] = useState(() => new Set());
  const [selectedOptions, setSelectedOptions] = useState([
    optionsInstockSummary[0],
    optionsInstockSummary[1],
  ]);
  const [filteredRowsRS, setFilteredRowsRS] = useState(() => new Set());
  const [expandedGroupIds, setExpandedGroupIds] = useState(() => new Set());
  const [[sortColumnSalesSummary, sortDirectionSalesSummary], setSortSales] =
    useState(["branch_desc", "DESC"]);
  const [
    [sortColumnInventorySummary, sortDirectionInventorySummary],
    setSortInventory,
  ] = useState(["Age", "DESC"]);

  const groupBy = useMemo(
    () =>
      Array.isArray(selectedOptions)
        ? selectedOptions.map((o) => o.value)
        : undefined,
    [selectedOptions]
  );

  // HOOKS
  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  // const [makes, models] = useVEHMakeModel();
  // const { query } = useQuery();

  const numericalASC = (a, b) => {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  };

  function rowKeyGetter(row) {
    return row.id;
  }

  const submitFormDetails = () => {
    // console.log(values);
    // return;

    // setSearchResultsInStockInventory([]);
    setSearchResultSalesSummary([]);

    var vehicles = firestore.collectionGroup("inventory-summary");
    vehicles = vehicles
      .where("CurrentStatus", "==", "IN-STOCK")
      .where("branch_desc", "in", ["AMW - ALTONA", "AMW - BRISBANE"]);
    // .orderBy("Purchased_Date", "desc");

    const daysAgo = dayjs().add(-SALE_WINDOW, "day").unix();

    toast.warning("Searching database");
    // sales = sales.where("Sale Date EPOC", ">=", parseInt(daysAgo));

    vehicles
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.size > 0) {
          // querySnapshot
          //   const vehData = doc.data();
          toast.success(`Found ${querySnapshot.size} vehicles`);
          const tmpSearchResultSalesSummary = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            // console.log("in data", data);
            // data.branch_desc = data.branch_desc.replace(/\s/g, "");
            tmpSearchResultSalesSummary.push({
              ...data,
              stockNumber: doc.id,
              id: doc.id,
            });
            //   Sort by position
            // tmpSearchResultsInStockInventory.sort(numericalASC);
          });
          // Set in stock array
          return setSearchResultSalesSummary(tmpSearchResultSalesSummary);
          // console.log(`Results`, tmpSearchResultsInStockInventory);
        } else {
          toast.error("No vehicles found");
          return;
        }
      })
      .then(() => {
        setSaleType(["AMW - BRISBANE", "AMW - ALTONA"]);
      })
      .catch((e) => {
        console.log(e.message);
        toast.error(e.message);
      });
  };

  // START SORT LOGIC

  const sortedRowsSalesSummary = useMemo(() => {
    if (sortDirectionSalesSummary === "NONE") return searchResultSalesSummary;

    let sortedRows = [...searchResultSalesSummary];

    switch (sortColumnSalesSummary) {
      case "Sale Date EPOC":
        sortedRows = sortedRows.sort((a, b) =>
          a[sortColumnSalesSummary]
            .toString()
            .localeCompare(b[sortColumnSalesSummary])
        );
        break;
      // case "available":
      //   sortedRows = sortedRows.sort((a, b) =>
      //     a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1
      //   );
      //   break;
      // case "id":

      default:
    }

    return sortDirectionSalesSummary === "DESC"
      ? sortedRows.reverse()
      : sortedRows;
  }, [
    searchResultSalesSummary,
    sortDirectionSalesSummary,
    sortColumnSalesSummary,
  ]);

  const handleSortInventory = useCallback((columnKey, direction) => {
    setSortInventory([columnKey, direction]);
  }, []);

  const handleSortSales = useCallback((columnKey, direction) => {
    setSortSales([columnKey, direction]);
  }, []);

  const handleChangeSaleType = (selectedValues) => {
    setSaleType(selectedValues);
  };

  const handleFilterSales = () => {
    const tmpFilterData = searchResultSalesSummary.filter((r) => {
      return saleType.includes(r.branch_desc);
    });
    setFilteredRowsRS(tmpFilterData);
  };

  // END SORT LOGIC

  const handleModalDetails = () => {
    setModalDetailsShow(!modalDetailsShow);
  };

  // // Update when make changes to filter dependent dropdown for model
  // useEffect(() => {
  //   // Has a selected make
  //   if (selectedMake && models.length > 0) {
  //     const tmpModelFilter = models.filter((model) => {
  //       return model.MakeCode === selectedMake.MakeCode ? true : false;
  //     });

  //     setDependentModels(tmpModelFilter);
  //   } else {
  //   }
  // }, [JSON.stringify(selectedMake)]);

  // Initial get sales on mount
  useEffect(() => {
    // submitForm();
  }, []);

  const loadDetails = () => {
    if (!showDetails) {
      submitFormDetails();
      setShowDetails(true);
    } else {
      setSearchResultsInStockInventory([]);
      setShowDetails(false);
    }
  };

  // Update on change of sale class
  useEffect(() => {
    handleFilterSales();
  }, [saleType]);

  // Calculations
  useEffect(() => {
    // Get branch table array and count of cars
    const inStockByBranch = [];
    const onlineByBranch = [];
    // Parse calculations for vehicles
    for (var i = 0; i < searchResultSalesSummary.length; i++) {
      const e = searchResultSalesSummary[i];

      // Count of all vehicles by branch
      if (typeof inStockByBranch[e.branch_desc] === "undefined") {
        inStockByBranch[e.branch_desc] = 0;
      }
      inStockByBranch[e.branch_desc]++;

      // Count of online vehicles
      if (e.hasOwnProperty("publishingStatus")) {
        if (e.publishingStatus === "Online") {
          if (typeof onlineByBranch[e.branch_desc] === "undefined") {
            onlineByBranch[e.branch_desc] = 0;
          }
          onlineByBranch[e.branch_desc]++;
        }
      }

      // // Bucket age of vehicle
      // // 0,15,30,45,60,75,90+
      // // mod 7 < 30 then 30+
      // const vehicleAge = [];
      // // Count of online vehicles
      // if (!e.hasOwnProperty("publishingStatus")) {
      //   if (typeof onlineByBranch[e.branch_desc] === "undefined") {
      //     onlineByBranch[e.branch_desc] = 1;
      //   }
      //   onlineByBranch[e.branch_desc]++;
      // }
    }

    const tmpBranchKeys = [];
    for (var k in inStockByBranch) {
      tmpBranchKeys.push(k);
    }
    console.log(tmpBranchKeys);
    setBranchKeys(tmpBranchKeys);
    setBranchTable(inStockByBranch);
    setBranchTableOnline(onlineByBranch);
  }, [JSON.stringify(searchResultSalesSummary)]);

  // useEffect(() => {
  //   const bKeys = branchTable.keys();
  //   console.log(bKeys);
  //   setBranchKeys(bKeys);
  // }, [branchTable]);

  return (
    <>
      <Container>
        {/* {JSON.stringify(saleType)} */}
        <ToggleButtonGroup
          type="checkbox"
          value={saleType}
          onChange={handleChangeSaleType}
        >
          <ToggleButton
            variant={
              saleType.includes("AMW - ALTONA")
                ? "outline-dark"
                : "outline-secondary"
            }
            value={"AMW - ALTONA"}
          >
            AMW Altona
          </ToggleButton>
          <ToggleButton
            variant={
              saleType.includes("AMW - BRISBANE")
                ? "outline-dark"
                : "outline-secondary"
            }
            value={"AMW - BRISBANE"}
          >
            AMW Brisbane
          </ToggleButton>
          {/* <ToggleButton
            variant={
              saleType.includes("DG") ? "outline-dark" : "outline-secondary"
            }
            value={"DG"}
          >
            Dutton Garage
          </ToggleButton> */}
        </ToggleButtonGroup>
        <br />
        <Badge pill variant="primary">
          {`${saleType?.length} branch${
            saleType?.length > 1 ? "es" : ""
          } selected`}
        </Badge>
      </Container>
      {/* START RECENT SALES SUMMARY TABLE */}
      <Container>
        <br />
        <h5>In Stock - AMW</h5>
        <span style={{ textAlign: "right" }}>
          <Badge pill variant="info">
            {`showing ${filteredRowsRS.length} row${
              filteredRowsRS.length > 1 ? "s" : ""
            } out of ${searchResultSalesSummary.length}`}
          </Badge>
        </span>
        <br />

        <h5>Summary</h5>
        <Table>
          <thead>
            <tr>
              <td>Branch</td>
              <td>Total</td>
              <td>Online</td>
              <td>Online %</td>
            </tr>
          </thead>
          <tbody>
            {branchKeys.map((k) => {
              return (
                <tr>
                  <td>{k}</td>
                  <td>{branchTable[k]}</td>
                  <td>{branchTableOnline[k]}</td>
                  <td>
                    <NumberFormat
                      suffix="%"
                      decimalScale={0}
                      displayType="text"
                      value={(branchTableOnline[k] / branchTable[k]) * 100}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button block variant="outline-primary" onClick={loadDetails}>
          {showDetails ? "Hide " : "Show "} Details
        </Button>
        <br />
        <br />
        {showDetails ? (
          <>
            <DataGrid
              rowKeyGetter={rowKeyGetter}
              columns={inStockColumns}
              rows={filteredRowsRS}
              selectedRows={selectedRowsRS}
              onSelectedRowsChange={setSelectedRowsRS}
              // groupBy={groupBy}
              emptyRowsRenderer={EmptyRowsRenderer}
              rowGrouper={rowGrouper}
              expandedGroupIds={expandedGroupIds}
              onExpandedGroupIdsChange={setExpandedGroupIds}
              defaultColumnOptions={{ resizable: true }}
              onSort={handleSortSales}
              rowClass={(row) =>
                row.sale_class === "R" ? highlightClassname : undefined
              }
            />
          </>
        ) : null}
      </Container>
      {/* END RECENT SALES SUMMARY TABLE */}
      {/* START Vehicle details modal */}
      <Modal
        show={modalDetailsShow}
        size="lg"
        onHide={() => setModalDetailsShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Vehicle information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Property</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {vehicleInfo.map((prop, idx) => {
                return (
                  <tr key={idx}>
                    <td>{prop.key}</td>
                    <td>{prop.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      {/* END Vehicle details modal */}
      <ToastContainer />

      <br />
      <Container fluid></Container>
      <PageViewLoggerComponent />
    </>
  );
}

export default NotPublishedAMWComponent;
