import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";

function ImageModalComponent(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Image src={props.src} alt="test" fluid />
      </Modal.Body>
    </Modal>
  );
}

export default ImageModalComponent;
