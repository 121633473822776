import { useEffect, useState } from "react";
import dayjs from "dayjs";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const numericalASCProfit = (a, b) => {
  if (a.TrueProfit < b.TrueProfit) {
    return -1;
  }
  if (a.TrueProfit > b.TrueProfit) {
    return 1;
  }
  return 0;
};

const sortDESCDate = (a, b) => {
  if (a["Sale Date EPOC"] < b["Sale Date EPOC"]) {
    return 1;
  }
  if (a["Sale Date EPOC"] > b["Sale Date EPOC"]) {
    return -1;
  }
  return 0;
};

const sortASCDivision = (a, b) => {
  if (a["Division"] > b["Division"]) {
    return 1;
  }
  if (a["Division"] < b["Division"]) {
    return -1;
  }
  return 0;
};

export function useRecentlySoldCars({ branches }) {
  const [topProfitDataSet, setTopProfitDataSet] = useState([]);
  const [inventoryImages, setInventoryImages] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [topVolumeDataSet, setTopVolumeDataSet] = useState([]);
  // const [topProfitDataSet, setTopProfitDataSet] = useState([]);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const currentYear = dayjs().year();
  const yearsToSearch = [currentYear - 2, currentYear - 5, currentYear - 8];

  // Top Profit Data Set
  useEffect(() => {
    if (branches.length === 0) return;
    const unsubscribe = firestore
      .collection("salesSummary")
      .where("Sale Date EPOC", ">", dayjs().add(-14, "day").unix())
      .where("Division", "in", branches)
      .where("sale_class", "==", "R")
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return;
        }
        const tmpProfit = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpProfit.push({ ...tmpData, id: doc.id });
        });
        tmpProfit.sort(numericalASCProfit).reverse();
        tmpProfit.sort(sortDESCDate);
        // tmpProfit.sort(sortASCDivision);
        setTopProfitDataSet(tmpProfit);
      });

    //   unsubscribe from firestore
    return () => unsubscribe;
  }, []);

  // Get instock inventory
  useEffect(() => {
    firestore
      .collection("vehicle-health-amw")
      .where("CurrentStatus", "==", "IN-STOCK")
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return;
        }
        const tmpInventory = [];
        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpInventory.push({ ...tmpData, id: doc.id });
        });
        setInventoryData(tmpInventory);
      });
  }, []);

  // Get Images For Top Profit Data
  useEffect(() => {
    let tmpImages = {};
    topProfitDataSet.forEach((tp) => {
      firestore
        .collection("inventory-photos")
        .doc(tp["Stock No"].toString())
        .get()
        .then((doc) => {
          if (doc.exists) {
            const tmpData = doc.data();
            const id = doc.id;

            tmpImages[id] = tmpData;
          }
        });
    });
    setInventoryImages(tmpImages);
  }, [topProfitDataSet]);

  return [topProfitDataSet, inventoryImages, inventoryData];
}
