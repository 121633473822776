import { useStorageDownloadURL, useStorage } from "reactfire";
import { groupBy as rowGrouper } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useMemo, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import ImageModalComponent from "./ImageModalComponent";
import { Formik, useField } from "formik";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import { useEffect } from "react";
import { useRef } from "react";
import { useVEHMakeModel } from "../hooks/useVEHMakeModel";
import NumberFormat from "react-number-format";
import DataGrid from "react-data-grid";
import dayjs from "dayjs";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import { useQuery } from "../hooks/useQuery";
import PageViewLoggerComponent from "./PageViewLoggerComponent";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

/*
Unused imports
import Image from "react-bootstrap/Image";
import { Camera } from "react-bootstrap-icons";
import FormControl from "react-bootstrap/FormControl";
import Dropdown from "react-bootstrap/Dropdown";
import ProgressBar from "react-bootstrap/ProgressBar";
*/
// import { createWorker } from "tesseract.js";

const highlightClassname = `
  .rdg-cell {
    background-color: #7093db;
    color: white;
  }
  &:hover .rdg-cell {
    background-color: #7093db;
  }
`;

const dev = true;
let SALE_WINDOW = 14;

var initState = { vin: "", rego: "" };
if (dev) {
  initState.vin = "";
  initState.rego = "";
  initState.make = "";
  initState.model = "";
  initState.variant = "";
}

const inStockColumns = [
  {
    key: "VEHMake",
    name: "Make",
    groupFormatter({ childRows }) {
      return (
        <>
          {childRows[0].VEHMake}({childRows.reduce((prev) => prev + 1, 0)})
        </>
      );
    },
  },
  {
    key: "VEHModel",
    name: "Model",
    groupFormatter({ childRows }) {
      return (
        <>
          {childRows[0].VEHModel}({childRows.reduce((prev) => prev + 1, 0)})
        </>
      );
    },
  },
  {
    key: "VEHModelYear",
    name: "Year",
    // groupFormatter({ childRows }) {
    //   return (
    //     <>
    //       {Math.floor(
    //         childRows.reduce(
    //           (prev, { VEHModelYear }) => prev + VEHModelYear,
    //           0
    //         ) / childRows.length
    //       )}
    //     </>
    //   );
    // },
  },
  {
    key: "Variant & Series",
    name: "Variant",
    width: 200,
  },
  {
    key: "VEHColour1",
    name: "Colour",
  },
  {
    key: "VEHOdo",
    name: "Odo",
    formatter({ row }) {
      return (
        <>
          <NumberFormat
            value={row.VEHOdo}
            thousandSeparator={true}
            decimalScale={0}
            displayType="text"
          />
        </>
      );
    },
  },
  {
    key: "Asking Price",
    name: "Asking",
    formatter({ row }) {
      return (
        <>
          <NumberFormat
            value={row["Asking Price"]}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={0}
            displayType="text"
          />
        </>
      );
    },
  },
  {
    key: "branch_desc",
    name: "Branch",
  },
  {
    key: "Age",
    name: "Age",
    formatter({ row }) {
      return (
        <>
          {/* <NumberFormat
            value={dayjs(row["Purchase_Date"]).format("DD-MM-YYYY")}
            thousandSeparator={true}
            decimalScale={0}
            displayType="text"
          /> */}
          {/* {dayjs(row["Purchase_Date"]).format("DD-MM-YYYY")} */}*
        </>
      );
    },
  },
  {
    key: "VEHStockNumber",
    name: "Stock No",
  },
  // { key: "CurrentStatus", name: "Status" },
  {
    key: "CountOfLeads",
    name: "Leads",
    groupFormatter({ childRows }) {
      return (
        <>
          {childRows.reduce((prev, { CountOfLeads }) => prev + CountOfLeads, 0)}
        </>
      );
    },
  },
];

const recentSalesColumns = [
  {
    key: "VEHMake",
    name: "Make",
    groupFormatter({ childRows }) {
      return (
        <>
          {childRows[0].VEHMake}({childRows.reduce((prev) => prev + 1, 0)})
        </>
      );
    },
  },
  {
    key: "VEHModel",
    name: "Model",
    groupFormatter({ childRows }) {
      return (
        <>
          {childRows[0].VEHModel}({childRows.reduce((prev) => prev + 1, 0)})
        </>
      );
    },
  },
  {
    key: "VEHModelYear",
    name: "Year",
    // groupFormatter({ childRows }) {
    //   return (
    //     <>
    //       {Math.floor(
    //         childRows.reduce(
    //           (prev, { VEHModelYear }) => prev + VEHModelYear,
    //           0
    //         ) / childRows.length
    //       )}
    //     </>
    //   );
    // },
  },
  {
    key: "Variant & Series",
    name: "Variant",
    width: 200,
  },
  {
    key: "VEHColour1",
    name: "Colour",
  },
  {
    key: "VEHOdoOut",
    name: "Odo Out",
    formatter({ row }) {
      return (
        <>
          <NumberFormat
            value={row.VEHOdoOut}
            thousandSeparator={true}
            decimalScale={0}
            displayType="text"
          />
        </>
      );
    },
  },
  {
    key: "SalePriceInc",
    name: "Sale Price",
    formatter({ row }) {
      return (
        <>
          <NumberFormat
            value={row.SalePriceInc}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={0}
            displayType="text"
          />
        </>
      );
    },

    groupFormatter({ childRows }) {
      return (
        <>
          <NumberFormat
            value={
              childRows.reduce(
                (prev, { SalePriceInc }) => prev + SalePriceInc,
                0
              ) / childRows.length
            }
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={0}
            displayType="text"
          />
        </>
      );
    },
  },
  {
    key: "Division",
    name: "Branch",
  },
  {
    key: "Sale Date EPOC",
    name: "Sale Date",
    formatter({ row }) {
      return <>{dayjs.unix(row["Sale Date EPOC"]).format("DD-MMM")}</>;
    },
  },
  {
    key: "Stock No",
    name: "Stock No",
  },
  {
    key: "sale_class",
    name: "Sale Class",
  },
  {
    key: "CountOfLeads",
    name: "Leads",
    groupFormatter({ childRows }) {
      return (
        <>
          {childRows.reduce((prev, { CountOfLeads }) => prev + CountOfLeads, 0)}
        </>
      );
    },
  },
];

const optionsInstockSummary = [
  {
    value: "VEHMake",
    label: "Make",
  },
  {
    value: "VEHModel",
    label: "Model",
  },
];

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function InStockSummaryComponent() {
  const [photos, setPhotos] = useState([]);
  const [queryParams, setQueryParams] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [inStockVehicles, setInStockVehicles] = useState([]);
  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [searchResultsInStockInventory, setSearchResultsInStockInventory] =
    useState([]);
  const [searchResultSalesSummary, setSearchResultSalesSummary] = useState([]);
  const [vehicleInfoObj, setVehicleInfoObj] = useState({
    make: "",
    model: "",
    variant: "",
    odo: "",
    price: "",
  });
  const [isLoadingVinImageRecog, setIsLoadingVinImageRecog] = useState(false);
  const [isLoadingRegoImageRecog, setIsLoadingRegoImageRecog] = useState(false);
  const [vinImageRecogProgress, setVinImageRecogProgress] = useState(0);
  const [modalDetailsShow, setModalDetailsShow] = useState(false);
  const [selectedMake, setSelectedMake] = useState({});
  const [dependentModels, setDependentModels] = useState([]);

  // Datagrid states
  const [selectedRowsISI, setSelectedRowsISI] = useState(() => new Set());
  const [selectedRowsRS, setSelectedRowsRS] = useState(() => new Set());
  const [selectedOptions, setSelectedOptions] = useState([
    optionsInstockSummary[0],
    optionsInstockSummary[1],
  ]);
  const [expandedGroupIds, setExpandedGroupIds] = useState(() => new Set());
  const [[sortColumnSalesSummary, sortDirectionSalesSummary], setSortSales] =
    useState(["Sale Date EPOC", "DESC"]);
  const [
    [sortColumnInventorySummary, sortDirectionInventorySummary],
    setSortInventory,
  ] = useState(["Age", "DESC"]);

  const groupBy = useMemo(
    () =>
      Array.isArray(selectedOptions)
        ? selectedOptions.map((o) => o.value)
        : undefined,
    [selectedOptions]
  );

  // HOOKS
  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const storage = useStorage();
  const vinImageRef = useRef(null);
  const regoImageRef = useRef(null);
  const [makes, models] = useVEHMakeModel();
  // const { query } = useQuery();

  const numericalASC = (a, b) => {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  };

  function rowKeyGetter(row) {
    return row.id;
  }

  const urlParamSearch = (values) => {
    // console.log(values);
    // return;
    var makeSearch = values?.make.length > 0 ? true : false;
    var modelSearch = values?.model.length > 0 ? true : false;

    setSearchResultsInStockInventory([]);
    setSearchResultSalesSummary([]);

    var vehicles = firestore.collectionGroup("inventory-summary");
    var sales = firestore.collectionGroup("salesSummary");
    const thiryDaysAgo = dayjs().add(-90, "day").unix();

    if (!makeSearch) {
      toast.warning("Please enter vehicle make");
      // setSubmitting(false);
      return;
    }

    if (makeSearch && !modelSearch) {
      toast.warning(`Searching for make: ${values.make} only`);
      values.make = values.make.toUpperCase();

      vehicles = vehicles.where("VEHMake", "==", values.make);
      sales = sales.where("VEHMake", "==", values.make);
      // .where("Sale Date EPOC", ">=", parseInt(thiryDaysAgo));
    } else if (!makeSearch && modelSearch) {
      toast.error("Please enter vehicle make");
      // setSubmitting(false);
      return;
    } else if (makeSearch && modelSearch) {
      toast.warning("Searching for make and model");
      values.make = values.make.toUpperCase();
      values.model = values.model.toUpperCase();
      vehicles = vehicles
        .where("VEHMake", "==", values.make)
        .where("VEHModel", "==", values.model)
        .where("CurrentStatus", "==", "IN-STOCK");
      sales = sales
        .where("VEHMake", "==", values.make)
        .where("VEHModel", "==", values.model)
        .where("Sale Date EPOC", ">=", parseInt(thiryDaysAgo));
    }

    vehicles
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.size > 0) {
          // querySnapshot
          //   const vehData = doc.data();
          toast.success(`Found ${querySnapshot.size} vehicles`);
          const tmpSearchResultsInStockInventory = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            // console.log("in data", data);
            tmpSearchResultsInStockInventory.push({
              ...data,
              stockNumber: doc.id,
            });
            //   Sort by position
            // tmpSearchResultsInStockInventory.sort(numericalASC);
          });
          // Set in stock array
          setSearchResultsInStockInventory(tmpSearchResultsInStockInventory);
          // console.log(`Results`, tmpSearchResultsInStockInventory);
        } else {
          toast.error("No vehicles found");
        }
        // setSubmitting(false);
      })
      .catch((e) => {
        console.log(e.message);
        toast.error(e.message);
        // setSubmitting(false);
      });

    sales
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.size > 0) {
          // querySnapshot
          //   const vehData = doc.data();
          toast.success(`Found ${querySnapshot.size} sales`);
          const tmpSearchResultSalesSummary = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            // console.log("in data", data);
            tmpSearchResultSalesSummary.push({
              ...data,
              stockNumber: doc.id,
              id: doc.id,
            });
            //   Sort by position
            // tmpSearchResultsInStockInventory.sort(numericalASC);
          });
          // Set in stock array
          setSearchResultSalesSummary(tmpSearchResultSalesSummary);
          // console.log(`Results`, tmpSearchResultsInStockInventory);
        } else {
          toast.error("No sales found");
        }
        // setSubmitting(false);
      })
      .catch((e) => {
        console.log(e.message);
        toast.error(e.message);
        // setSubmitting(false);
      });
  };

  const submitForm = (values, setSubmitting) => {
    // console.log(values);
    // return;
    var makeSearch = values.make.length > 0 ? true : false;
    var modelSearch = values.model.length > 0 ? true : false;

    setSearchResultsInStockInventory([]);
    setSearchResultSalesSummary([]);

    var vehicles = firestore.collectionGroup("inventory-summary");
    var sales = firestore.collectionGroup("salesSummary");
    const daysAgo = dayjs().add(-SALE_WINDOW, "day").unix();

    if (!makeSearch) {
      toast.warning("Please enter vehicle make");
      setSubmitting(false);
      return;
    }

    if (makeSearch && !modelSearch) {
      toast.warning(`Searching for make: ${values.make} only`);
      values.make = values.make.toUpperCase();
      vehicles = vehicles
        .where("VEHMake", "==", values.make)
        .where("CurrentStatus", "==", "IN-STOCK");
      sales = sales
        .where("VEHMake", "==", values.make)
        .where("Sale Date EPOC", ">=", parseInt(daysAgo));
    } else if (!makeSearch && modelSearch) {
      toast.error("Please enter vehicle make");
      setSubmitting(false);
      return;
    } else if (makeSearch && modelSearch) {
      toast.warning("Searching for make and model");
      values.make = values.make.toUpperCase();
      values.model = values.model.toUpperCase();
      vehicles = vehicles
        .where("VEHMake", "==", values.make)
        .where("VEHModel", "==", values.model)
        .where("CurrentStatus", "==", "IN-STOCK");
      sales = sales
        .where("VEHMake", "==", values.make)
        .where("VEHModel", "==", values.model)
        .where("Sale Date EPOC", ">=", parseInt(daysAgo));
    }

    vehicles
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.size > 0) {
          // querySnapshot
          //   const vehData = doc.data();
          toast.success(`Found ${querySnapshot.size} vehicles`);
          const tmpSearchResultsInStockInventory = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            // console.log("in data", data);
            tmpSearchResultsInStockInventory.push({
              ...data,
              stockNumber: doc.id,
            });
            //   Sort by position
            // tmpSearchResultsInStockInventory.sort(numericalASC);
          });
          // Set in stock array
          setSearchResultsInStockInventory(tmpSearchResultsInStockInventory);
          // console.log(`Results`, tmpSearchResultsInStockInventory);
        } else {
          toast.error("No vehicles found");
        }
        setSubmitting(false);
      })
      .catch((e) => {
        console.log(e.message);
        toast.error(e.message);
        setSubmitting(false);
      });

    sales
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.size > 0) {
          // querySnapshot
          //   const vehData = doc.data();
          toast.success(`Found ${querySnapshot.size} sales`);
          const tmpSearchResultSalesSummary = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            // console.log("in data", data);
            tmpSearchResultSalesSummary.push({
              ...data,
              stockNumber: doc.id,
              id: doc.id,
            });
            //   Sort by position
            // tmpSearchResultsInStockInventory.sort(numericalASC);
          });
          // Set in stock array
          setSearchResultSalesSummary(tmpSearchResultSalesSummary);
          // console.log(`Results`, tmpSearchResultsInStockInventory);
        } else {
          toast.error("No sales found");
        }
        setSubmitting(false);
      })
      .catch((e) => {
        console.log(e.message);
        toast.error(e.message);
        setSubmitting(false);
      });
  };

  // START SORT LOGIC

  const sortedRowsSalesSummary = useMemo(() => {
    if (sortDirectionSalesSummary === "NONE") return searchResultSalesSummary;

    let sortedRows = [...searchResultSalesSummary];

    switch (sortColumnSalesSummary) {
      case "Sale Date EPOC":
        sortedRows = sortedRows.sort((a, b) =>
          a[sortColumnSalesSummary]
            .toString()
            .localeCompare(b[sortColumnSalesSummary])
        );
        break;
      // case "available":
      //   sortedRows = sortedRows.sort((a, b) =>
      //     a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1
      //   );
      //   break;
      // case "id":

      default:
    }

    return sortDirectionSalesSummary === "DESC"
      ? sortedRows.reverse()
      : sortedRows;
  }, [
    searchResultSalesSummary,
    sortDirectionSalesSummary,
    sortColumnSalesSummary,
  ]);

  const handleSortInventory = useCallback((columnKey, direction) => {
    setSortInventory([columnKey, direction]);
  }, []);

  const handleSortSales = useCallback((columnKey, direction) => {
    setSortSales([columnKey, direction]);
  }, []);

  // END SORT LOGIC

  const getDataByRego = async () => {
    // if (rego.length === 0 || rego.length < 5) {
    //   toast.warning("Rego doesn't seem correct, please check");
    //   return;
    // }
    // var vehicles = db
    //   .collectionGroup("assets")
    //   .where("metadata.regoNo", "==", rego);
    // vehicles
    //   .get()
    //   .then(function (querySnapshot) {
    //     if (querySnapshot.size > 0) {
    //       // querySnapshot
    //       //   const vehData = doc.data();
    //       toast.success(`Found ${querySnapshot.size} images`);
    //       const images = [];
    //       querySnapshot.forEach((doc) => {
    //         const data = doc.data();
    //         images.push({
    //           position: parseInt(data.metadata.photoPosition),
    //           src: data.mediaLink,
    //           vehicleInfo: data.metadata,
    //         });
    //         //   Sort by photo position
    //         images.sort(numericalASC);
    //       });
    //       // Set photo array
    //       setPhotos(images);
    //       //   console.log(`${vehData}`);
    //     } else {
    //       toast.error("No images found");
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e.message);
    //     toast.error(e.message);
    //   });
  };

  const handleModalDetails = () => {
    setModalDetailsShow(!modalDetailsShow);
  };

  // Get query params
  // useEffect(() => {
  //   const make = query.get("make");
  //   const model = query.get("model");
  //   setQueryParams({ make, model });
  // }, []);

  // Update query if query params set
  // useEffect(() => {
  //   urlParamSearch(queryParams);
  // }, [JSON.stringify(queryParams)]);

  // Update vehicle info array when photos array changes
  useEffect(() => {
    // Set vehicle info array
    if (photos.length > 0 && photos[0].vehicleInfo) {
      var vehicleProperties = [];
      const tmpVehicleInfo = photos[0].vehicleInfo;
      setVehicleInfoObj(tmpVehicleInfo);
      for (let prop in tmpVehicleInfo) {
        const tmpProp = { key: prop, value: tmpVehicleInfo[prop] };
        vehicleProperties.push(tmpProp);
      }
      setVehicleInfo(vehicleProperties);
    }
  }, [photos]);

  // Update when make changes to filter dependent dropdown for model
  useEffect(() => {
    // Has a selected make
    if (selectedMake && models.length > 0) {
      const tmpModelFilter = models.filter((model) => {
        return model.MakeCode === selectedMake.MakeCode ? true : false;
      });

      setDependentModels(tmpModelFilter);
    } else {
    }
  }, [JSON.stringify(selectedMake)]);

  return (
    <>
      <Container>
        <Formik
          initialValues={initState}
          onSubmit={(values, { setSubmitting }) =>
            submitForm(values, setSubmitting)
          }
        >
          {({
            values,
            isSubmitting,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Row xs={12}>
                <Col xs={12} md={4} style={{ padding: 10 }}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        Make<sup>*</sup>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Typeahead
                      id="onclear-make"
                      name="make"
                      options={makes}
                      onChange={(selected) => {
                        if (selected.length > 0) {
                          setFieldValue("make", selected[0].label);
                          setSelectedMake(selected[0]);
                        } else if (selected.length === 0) {
                          setFieldValue("make", "");
                          setSelectedMake([]);
                        }
                      }}
                      value={values.make}
                      placeholder="Choose a make..."
                    >
                      {({ onClear, selected }) => (
                        <div className="rbt-aux">
                          {!!selected.length && (
                            <ClearButton onClick={onClear} />
                          )}
                          {!selected.length && (
                            <Spinner animation="grow" size="sm" />
                          )}
                        </div>
                      )}
                    </Typeahead>
                  </InputGroup>
                </Col>
                <Col xs={12} md={4} style={{ padding: 10 }}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        Model<sup>*</sup>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Typeahead
                      id="onclear-model"
                      name="model"
                      options={dependentModels}
                      onChange={(selected) => {
                        if (selected.length > 0) {
                          setFieldValue("model", selected[0].label);
                        } else if (selected.length === 0) {
                          setFieldValue("model", "");
                        }
                      }}
                      value={values.model}
                      placeholder="Choose a model..."
                    >
                      {({ onClear, selected }) => (
                        <div className="rbt-aux">
                          {!!selected.length && (
                            <ClearButton onClick={onClear} />
                          )}
                          {!selected.length && (
                            <Spinner animation="grow" size="sm" />
                          )}
                        </div>
                      )}
                    </Typeahead>
                  </InputGroup>
                </Col>
                <Col xs={12} md={4} style={{ padding: 10 }}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        Variant
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      placeholder="Variant"
                      name="variant"
                      disabled
                      onChange={handleChange}
                      value={values.variant}
                    ></Form.Control>
                    <InputGroup.Append>
                      <Button
                        variant="outline-secondary"
                        onClick={() => {
                          setFieldValue("variant", "");
                        }}
                      >
                        x
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col>
                  <Button
                    variant="secondary"
                    type="submit"
                    id="frm-submit-btn"
                    block
                    disabled={isSubmitting}
                    size="lg"
                  >
                    Search
                  </Button>
                </Col>
              </Row>
              <Collapse in={isSubmitting}>
                <Row style={{ marginTop: 10 }}>
                  <Col>
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </Col>
                </Row>
              </Collapse>
            </form>
          )}
        </Formik>
      </Container>
      {/* START IN STOCK SUMMARY TABLE */}
      <Container>
        <br />
        <h5>Vehicles currently in stock</h5>
        <DataGrid
          rowKeyGetter={rowKeyGetter}
          columns={inStockColumns}
          rows={searchResultsInStockInventory}
          selectedRows={selectedRowsISI}
          onSelectedRowsChange={setSelectedRowsISI}
          groupBy={groupBy}
          rowGrouper={rowGrouper}
          expandedGroupIds={expandedGroupIds}
          onExpandedGroupIdsChange={setExpandedGroupIds}
          defaultColumnOptions={{ resizable: true }}
          onSort={handleSortInventory}
        />
      </Container>
      {/* END IN STOCK SUMMARY TABLE */}
      {/* START RECENT SALES SUMMARY TABLE */}
      <Container>
        <br />
        <h5>Recently sold (last {SALE_WINDOW} days)</h5>
        <DataGrid
          rowKeyGetter={rowKeyGetter}
          columns={recentSalesColumns}
          rows={sortedRowsSalesSummary}
          selectedRows={selectedRowsRS}
          onSelectedRowsChange={setSelectedRowsRS}
          groupBy={groupBy}
          rowGrouper={rowGrouper}
          expandedGroupIds={expandedGroupIds}
          onExpandedGroupIdsChange={setExpandedGroupIds}
          defaultColumnOptions={{ resizable: true }}
          onSort={handleSortSales}
          rowClass={(row) =>
            row.sale_class === "R" ? highlightClassname : undefined
          }
        />
      </Container>
      {/* END RECENT SALES SUMMARY TABLE */}

      {/* START Vehicle details modal */}
      <Modal
        show={modalDetailsShow}
        size="lg"
        onHide={() => setModalDetailsShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Vehicle information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Property</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {vehicleInfo.map((prop, idx) => {
                return (
                  <tr key={idx}>
                    <td>{prop.key}</td>
                    <td>{prop.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      {/* END Vehicle details modal */}

      <ToastContainer />
      <ImageModalComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        src={modalImage}
      />
      <br />
      <Container fluid></Container>
      <PageViewLoggerComponent />
    </>
  );
}

export default InStockSummaryComponent;
