import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { HiClipboardCheck } from "react-icons/hi";
import NumberFormat from "react-number-format";
import { Container } from "react-trello";
import copy from "copy-to-clipboard";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const copyToClipboard = (v) => {
  copy(v);
};

const StockCardComoponent = (props) => {
  const [stockCard, setStockCard] = useState({});

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  useEffect(() => {
    const stockId = props.match.params.stocknumber;

    if (stockId.length > 3) {
      firestore
        .collection("inventory-summary")
        .where("VEHStockNumber", "==", parseInt(stockId))
        .get()
        .then((doc) => {
          console.log(doc);
          if (doc.exists) {
            const tmpStockCard = doc.data();
            setStockCard(tmpStockCard);
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  }, [props.match.params.stocknumber]);

  return (
    <>
      {/* <pre>{JSON.stringify(stockCard, null, 2)}</pre> */}
      <Container>
        <Row>
          <Col>
            <Table bordered hover style={{ fontSize: "12px" }}>
              <thead>
                <tr style={{ backgroundColor: "#5DADE2", color: "black" }}>
                  <th>Property</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Stock No</td>
                  <td>{stockCard?.VEHStockNumber}</td>
                </tr>
                <tr style={{ backgroundColor: "#D4E6F1" }}>
                  <td>Current Status</td>
                  <td>{stockCard?.CurrentStatus}</td>
                </tr>
                <tr>
                  <td>Year</td>
                  <td>{stockCard?.VEHModelYear}</td>
                </tr>
                <tr style={{ backgroundColor: "#D4E6F1" }}>
                  <td>Make</td>
                  <td>{stockCard?.VEHMake}</td>
                </tr>
                <tr>
                  <td>Model</td>
                  <td>{stockCard?.VEHModel}</td>
                </tr>
                <tr style={{ backgroundColor: "#D4E6F1" }}>
                  <td>Variant</td>
                  <td>{stockCard["Variant & Series"]}</td>
                </tr>
                <tr>
                  <td>Colour</td>
                  <td>{stockCard?.VEHColour1}</td>
                </tr>
                <tr style={{ backgroundColor: "#D4E6F1" }}>
                  <td>Odometer</td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator={true}
                      value={stockCard?.VEHOdo}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Branch / Dealership</td>
                  <td>{stockCard?.branch_desc}</td>
                </tr>
                <tr style={{ backgroundColor: "#D4E6F1" }}>
                  <td>Current location / yard</td>
                  <td>{stockCard?.VEHYard}</td>
                </tr>
                <tr>
                  <td>Buyer</td>
                  <td>{stockCard?.Buyer}</td>
                </tr>
                <tr style={{ backgroundColor: "#D4E6F1" }}>
                  <td>VIN</td>
                  <td>
                    {stockCard?.VEHVIN}{" "}
                    <Button
                      style={{ backgroundColor: "#138D75", color: "white" }}
                      onClick={() => copyToClipboard(stockCard?.VEHVIN)}
                      size="sm"
                      variant="outline-dark"
                    >
                      <HiClipboardCheck size={20} />
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>Age in stock</td>
                  <td>
                    {dayjs().diff(
                      dayjs.unix(stockCard?.Purchase_Date?.seconds),
                      "day",
                      false
                    )}
                    &nbsp;day(s)
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StockCardComoponent;
