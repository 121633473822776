import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { HiClipboardCheck } from "react-icons/hi";
import NumberFormat from "react-number-format";
import { Container } from "react-trello";
import { useFunctions } from "reactfire";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import VehiclePhotos from "./VehiclePhotos";
import { httpsCallable } from "firebase/functions";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import StockCardSummary from "./helpers/StockCardSummary";

const copyToClipboard = (v) => {
  copy(v);
};

const StockCardByVinComponent = (props) => {
  const [results, setResults] = useState([]);
  const [stockCard, setStockCard] = useState({});
  const [loading, setLoading] = useState(false);
  const [inspectionReport, setInspectionReport] = useState(null);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const functions = useFunctions();

  const getInspectionReport = async (vin) => {
    if (vin.length < 16) {
      toast.warning(
        "Something went wrong, could not retrieve inspection report"
      );
      console.log("VIN missing");
      return;
    }
    try {
      setLoading(true);
      console.log("Attempting to retrieve inspection report");
      const payload = { vin };
      var getSugarCRMInspectionReport = httpsCallable(
        functions,
        "getSugarCRMInspectionReport"
      );
      // var getSugarCRMInspectionReport = firebase
      //   .functions()
      //   .httpsCallable("getSugarCRMInspectionReport");
      setInspectionReport(null);
      await getSugarCRMInspectionReport(payload).then((r) => {
        if (r?.data === false) {
          toast.error("Not authorised");
          return;
        }
        if (r?.error) {
          toast.error(r.error[0].message);
          return;
        }
        if (r?.data?.length > 0) {
          toast.success(`Found reports`);
        }

        setInspectionReport(r.data);
      });
    } catch (e) {
      toast.warning(e.message);
    }
    setLoading(false);
  };

  const downloadAttachmentFromSugar = async (attachmentId) => {
    const fileUrl = `https://crm.sellyourcarfast.com.au/rest/v11_16/Notes/${attachmentId}/file/filename?force_download=1&platform=base`;
    window.open(fileUrl, "_blank");
    setLoading(false);
  };

  useEffect(() => {
    const vin = props.match.params.vin;
    console.log(vin);

    if (vin.length > 6) {
      firestore
        .collection("inventory-summary")
        .where("VEHVIN", "==", vin)
        .get()
        .then((snapshot) => {
          let tmpResults = [];
          snapshot.forEach((d) => {
            const data = d.data();
            tmpResults.push(data);
          });
          tmpResults = tmpResults.sort((a, b) => {
            return parseInt(a.VEHStockNumber) > parseInt(b.VEHStockNumber)
              ? -1
              : 1;
          });
          setResults(tmpResults);
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  }, [props.match.params.vin]);

  return (
    <>
      <ToastContainer />
      <Container>
        <Row>
          <Col>
            {results?.map((stockCard, idx) => {
              return (
                <Card style={{ marginBottom: 15 }} key={idx}>
                  <Card.Header>
                    <b>
                      Vehicle details for {stockCard?.VEHStockNumber} -{" "}
                      {stockCard?.branch_desc}
                    </b>
                  </Card.Header>
                  <Card.Body>
                    <StockCardSummary veh={stockCard} />
                  </Card.Body>
                </Card>
              );
            })}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StockCardByVinComponent;
