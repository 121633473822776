import { Button, Card, Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const sections = [
  {
    name: "Freight",
    id: "freight-section",
    components: [
      {
        name: "New Freight Booking",
        description: "Use this form to create a new freight booking request",
        link: "/freight-booking-form",
      },
    ],
  },
  {
    name: "Tools",
    id: "tools-section",
    components: [
      {
        name: "VIN / Stock Number Search",
        description:
          "A global search for vehicles by VIN or Eclipse Stock Number",
        link: "/vin-rego-search",
      },
    ],
  },
];
export default function Start() {
  const history = useHistory();
  return (
    <>
      <Container>
        <Row>
          {sections.map((s, keyi) => {
            return s.components.map((c, keyj) => {
              return (
                <Col xs={12} sm={7} md={5} key={`${keyi}-${keyj}`}>
                  <Card style={{ width: "18rem", margin: "2em" }}>
                    <Card.Title style={{ padding: 10 }}>{c.name}</Card.Title>
                    <Card.Body>{c.description}</Card.Body>
                    <Card.Footer>
                      <Button onClick={() => history.push(c.link)}>Go</Button>
                    </Card.Footer>
                  </Card>
                </Col>
              );
            });
          })}
        </Row>
      </Container>
    </>
  );
}
