import { useEffect, useState } from "react";
import dayjs from "dayjs";

const useTransformToRechartsFormat = (os_graph_data, chartInfo) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // Transform into recharts dataset
    const tmpChartData = [];
    // Loop through aggs (for now only 1)
    const rootAggPos = 1;
    const parentAggString = `agg${rootAggPos}`;
    for (
      var i = 0;
      i < os_graph_data?.aggregations[parentAggString]?.buckets.length;
      i++
    ) {
      const item = os_graph_data?.aggregations[parentAggString]?.buckets[i];
      const xaxisLabel = item?.key;
      let xaxisLabelFormatted = xaxisLabel;

      switch (chartInfo?.xaxis.type) {
        case "date":
          xaxisLabelFormatted = dayjs
            .unix(item?.key / 1000)
            .format(chartInfo?.xaxis.format);
          break;

        default:
          xaxisLabelFormatted = xaxisLabel;
      }
      let chartPayload = {
        name: xaxisLabelFormatted,
        totalSeries: 0,
      };

      for (var j = 0; j < chartInfo.xaxis.series.length; j++) {
        const series = chartInfo.xaxis.series[j];
        chartPayload = {
          ...chartPayload,
          [series.name]: item?.doc_count,
        };

        // Make recursive but now just 1_1 levels deep

        // console.log("checking nested aggregations", chartInfo.xaxis.series[j]);
        if (chartInfo.xaxis.series[j]?.series?.length > 0) {
          const nextAggKey = `${parentAggString}_1`;
          for (var k = 0; k < chartInfo.xaxis.series[j].series.length; k++) {
            const series_series = chartInfo.xaxis.series[j].series[k];
            const nested_item = item[nextAggKey];

            chartPayload = {
              ...chartPayload,
              [series_series.name]: nested_item?.value,
            };
          }
        }
      }

      tmpChartData.push(chartPayload);
    }
    setChartData(tmpChartData);
    // console.log(tmpChartData);
  }, [os_graph_data, chartInfo]);

  return chartData;
};

export default useTransformToRechartsFormat;
