import React, { useState, useEffect, useMemo } from "react";
import { useFunctions, useUser } from "reactfire";
import useFirestoreQuery from "../../hooks/useFirestoreQuery";

import {
  Card,
  Table,
  Badge,
  Spinner,
  Row,
  Col,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Dropdown,
  Button,
  ButtonGroup,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Carousel,
  Alert,
  DropdownButton,
  Tab,
  Tabs,
} from "react-bootstrap";
import {
  BiDollarCircle,
  BiExit,
  BiIdCard,
  BiPrinter,
  BiSearch,
  BiWindowClose,
  BiX,
} from "react-icons/bi";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Legend,
  CartesianGrid,
  Label,
  Area,
  ComposedChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { CheckCircle, CheckCircleFill } from "react-bootstrap-icons";
import agMapping from "../../assets/json/ag_to_eclipse_mapping.json";
import { httpsCallable } from "firebase/functions";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
const ageBucketsTemplate = [
  {
    bucketName: "0-30",
    bucketMax: 30,
    bucketMin: 0,
    count: 0,
    percentageOfTotal: 0,
  },
  {
    bucketName: "31-60",
    bucketMax: 60,
    bucketMin: 31,
    count: 0,
    percentageOfTotal: 0,
  },
  {
    bucketName: "61-90",
    bucketMax: 90,
    bucketMin: 61,
    count: 0,
    percentageOfTotal: 0,
  },
  {
    bucketName: "91+",
    bucketMax: 99999,
    bucketMin: 91,
    count: 0,
    percentageOfTotal: 0,
  },
  {
    bucketName: "All",
    bucketMax: 99999,
    bucketMin: 0,
    count: 0,
    percentageOfTotal: 100,
  },
];

const styles = {
  card: {
    fontSize: 14,
    width: "100%",
    marginBottom: 25,
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
  },
  toggleButton: {
    borderColor: "black",
    color: "black",
    maxWidth: 250,
  },
  toggleButtonGroup: {
    width: "100%",
    marginBottom: 10,
    justifyContent: "center",
  },
  cardHeader: { backgroundColor: "#5DADE2", fontWeight: "bold" },
};

const internalSiteGUID = agMapping.map((item) => item.guid);

// Colour codes by site
const siteColours = {
  "AMW - ALBION": "#E74C3C",
  "AMW - PLYMPTON": "#9B59B6",
  "AMW - DANDENONG": "#2980B9",
  "AMW - MELBOURNE": "#1ABC9C",
  "AMW - WESTGATE": "#F1C40F",
  "AMW - FAIRFIELD": "#DC7633",
};

let branchesList = require("../../assets/json/branches-erp.json");
// const laneBase = require("../../assets/json/lane_base.json");
const activeYards = require("../../assets/json/yards_amw.json");

// Stock ceiling working cap

const WORKING_CAP_MELBOURNE = 3600000;
const WORKING_CAP_WESTGATE = 2200000;
const WORKING_CAP_DANDENONG = 2700000;
const WORKING_CAP_FAIRFIELD = 2700000;
const WORKING_CAP_BRISBANE = 2200000;
const WORKING_CAP_PLYMPTON = 1900000;
const WORKING_CAP_ALBION = 2200000;
const WORKING_CAP_RECON = 2300000;

branchesList = branchesList.filter((d) => d.branch.search("D1") !== -1);

// Show photo badge if yard log for photos exists
const YardLogBadges = ({ yardLogs, currentYard, photosList, stockNumber }) => {
  const [logs, setLogs] = useState([]);

  // const getLane = (yard) => laneBase?.lanes?.find((l) => l.id === yard);

  // const getBgColour = (yard) => {
  //   const lane = getLane(yard);
  //   const colours = laneBase?.categoryColours[lane?.category];
  //   return colours;
  // };

  // const isYardActive = (yard) => {
  //   const lane = getLane(yard);
  //   return lane?.active;
  // };

  // Get count of photos from photosList matching the stock number
  const photoCount = useMemo(() => {
    const key = photosList?.findIndex(
      (p) => parseInt(p.stockNumber) === parseInt(stockNumber)
    );
    return key ? photosList[key]?.photos?.length : 0;
  }, [photosList, stockNumber]);

  useEffect(() => {
    if (yardLogs) {
      const tmpYardLogs = yardLogs?.filter((yl) => yl.VEHYard === "Photo");
      if (tmpYardLogs.length > 0) {
        // console.log(tmpYardLogs);
        setLogs(tmpYardLogs);
      }
    }
  }, [JSON.stringify(yardLogs)]);

  return (
    <>
      <h6>
        {logs.length > 0 ? (
          <Badge pill style={{ backgroundColor: "#2ECC71" }}>
            {logs[0]?.VEHYard} {photoCount > 0 ? `(${photoCount})` : null}
          </Badge>
        ) : null}
      </h6>
    </>
  );
};

const InventoryHealth = (props) => {
  const [showActionCard, setShowActionCard] = useState(false);
  const [showActionsModal, setShowActionsModal] = useState(false);
  const [isLoadingActions, setIsLoadingActions] = useState(false);
  const [isLoadingCallable, setIsLoadingCallable] = useState(false);
  const [isLoadingYardLocationUpdate, setIsLoadingYardLocationUpdate] =
    useState(false);
  const [isRepricing, setIsRepricing] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [selectedVehiclePhotos, setSelectedVehiclePhotos] = useState([]);
  const [inStockPhotos, setInStockPhotos] = useState([]);
  const [selectedVehicleActions, setSelectedVehicleActions] = useState([]);
  const [selectedVehicleLeadPriceLife, setSelectedVehicleLeadPriceLife] =
    useState([]);

  const [customRepriceValue, setCustomRepriceValue] = useState(0);
  const [filteredListOfVehicles, setFilteredListOfVehicles] = useState([]);
  const [branchFilter, setBranchFilter] = useState("All");
  const [ageBuckets, setAgeBuckets] = useState(ageBucketsTemplate);
  const [stockSearch, setStockSearch] = useState("");
  const [workingCapitalBranch, setWorkingCapitalBranch] = useState({});
  const [totalVehicleCount, setTotalVehicleCount] = useState(0);

  const [transferBranch, setTransferBranch] = useState({});
  const [transferAskingPrice, setTransferAskingPrice] = useState("");

  // const [activeYards, setActiveYards] = useState([]);
  const [showYardLocationModal, setShowYardLocationModal] = useState(false);
  const [yardLocation, setYardLocation] = useState("");

  const [isLoadingLeads, setIsLoadingLeads] = useState(false);
  const [leadsFromSalesforce, setLeadsFromSalesforce] = useState([]);
  const [showLeadsModal, setShowLeadsModal] = useState(false);

  const [leadSpreadMatrix, setLeadSpreadMatrix] = useState([]);
  const [leadSpreadMatrixFiltered, setLeadSpreadMatrixFiltered] = useState([]);
  const [isLoadingLeadSpread, setIsLoadingLeadSpread] = useState(true);
  const [filteredLeadSpreadInventoryList, setFilteredLeadSpreadInventoryList] =
    useState([]);
  const [isFilteredByLeadSpread, setIsFilteredByLeadSpread] = useState(false);
  const [leadSpreadWithStockNumberasKey, setLeadSpreadWithStockNumberasKey] =
    useState({});
  const [vehiclesOnlineByBranch, setVehiclesOnlineByBranch] = useState({});

  const [additional_actions_event_key, setAdditionalActionsEventKey] =
    useState("event_sale");

  const [profitByAgeByBranchChartData, setProfitByAgeByBranchChartData] =
    useState([]);
  const [
    loadingProfitByAgeByBranchChartData,
    setLoadingProfitByAgeByBranchChartData,
  ] = useState(false);
  const [chartLineVisibility, setChartLineVisibility] = useState({});

  const [sugarVehicleData, setSugarVehicleData] = useState({});
  const [isLoadingSuggestedRetailPrice, setIsLoadingSuggestedRetailPrice] =
    useState(false);

  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfBase64, setPdfBase64] = useState("");
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  const [agData, setAgData] = useState([]);

  const history = useHistory();
  const user = useUser();
  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const functions = useFunctions();
  const { data, status, error } = useFirestoreQuery(
    firestore
      .collection("vehicle-health-d1-nsw")
      // .where("CurrentStatus", "==", "IN-STOCK")
      .orderBy("Age", "desc")
  );

  const handleShowActionCard = (v) => {
    setSelectedVehicle(v);
    setShowActionCard(true);
  };

  const handleHideActionCard = () => {
    setSelectedVehicle({});
    setShowActionCard(false);
  };

  const calculateAgeBuckets = (d) => {
    const tmpBuckets = [...ageBucketsTemplate];
    for (var j = 0; j < tmpBuckets.length; j++) {
      const bucketMax = tmpBuckets[j].bucketMax;
      const bucketMin = tmpBuckets[j].bucketMin;
      tmpBuckets[j].count = 0;

      for (var i = 0; i < d.length; i++) {
        const vAge = d[i].Age;
        if (vAge <= bucketMax && vAge >= bucketMin) {
          tmpBuckets[j].count++;
        }
      }
    }
    let total = 0;
    tmpBuckets[tmpBuckets.length - 1].count = 0;
    tmpBuckets.forEach((b) => {
      total = total + b.count;
    });

    tmpBuckets[tmpBuckets.length - 1].count = total;
    tmpBuckets.forEach((b) => {
      b.percentageOfTotal = Math.floor(
        (b.count / tmpBuckets[tmpBuckets.length - 1].count) * 100
      );
    });
    // console.log(tmpBuckets);
    setAgeBuckets(tmpBuckets);
  };

  // Get photos of selected vehicle
  const getPhotos = async () => {
    if (selectedVehicle) {
      await firestore
        .collection("inventory-photos")
        .doc(selectedVehicle?.VEHStockNumber.toString())
        .get()
        .then((doc) => {
          if (!doc.empty) {
            // console.log(doc.data());
            setSelectedVehiclePhotos(doc.data());
          }
        });
    } else {
      toast.warning("No vehicle selected");
    }
  };

  // // Get actions history of selected vehicle
  // Move to useEffect on selectedVehicle
  const getActionsHistoryOfSelectedVehicle = async () => {
    if (selectedVehicle) {
      await firestore
        .collection("inventory-health-actions")
        .doc(selectedVehicle?.VEHStockNumber.toString())
        .collection("actions-log")
        .get()
        .then((s) => {
          const actions = [];
          s.forEach((doc) => {
            actions.push(doc.data());
          });
          actions.sort((a, b) => a.createdDate - b.createdDate);
          setSelectedVehicleActions(actions);
        });
    } else {
      toast.warning("No vehicle selected");
    }
  };

  // const createInventoryHealthAction = async (v, action) => {
  //   if (isNaN(parseInt(v.VEHStockNumber))) {
  //     toast.warning("Please select inventory item first");
  //     return false;
  //   }
  //   const actionPayload = {
  //     action: action,
  //     owner: "Site Admin",
  //     created: dayjs().unix(),
  //     status: "Open",
  //     escalated: false,
  //     sentNotification: false,
  //   };

  //   await firestore
  //     .collection("vehicle-health-amw")
  //     .doc(v.id)
  //     .collection("healthAction")
  //     .add(actionPayload)
  //     .then((r) => {
  //       toast.success("New action created");
  //     })
  //     .catch((e) => {
  //       toast.error(e.message);
  //     });
  // };

  // Update state of asking price for transfer branch vehicle
  const updateTransferAskingPrice = (price) => {
    setTransferAskingPrice(price);
  };

  // Send transfer request
  const sendBranchTransferRequest = async (method) => {
    return;
    if (method === "sale") {
      if (transferAskingPrice?.length === 0) {
        toast.error("Price required");
        return;
      }

      if (transferBranch?.branch?.length === 0) {
        toast.error("Branch required");
        return;
      }
      const payload = {
        transferBranch,
        selectedVehicle,
        transferAskingPrice: transferAskingPrice,
        method,
      };
      setIsLoadingCallable(true);
      const sendAMWSaleRequest = httpsCallable(
        functions,
        "sendAMWTransferRequest"
      );
      // var sendAMWSaleRequest = firebase
      //   .functions()
      //   .httpsCallable("sendAMWTransferRequest");
      await sendAMWSaleRequest(payload).then((r) => {
        if (!r) {
          toast.error("Not authorised");
        }
      });
    } else if (method === "transfer") {
      if (transferBranch?.branch?.length === 0) {
        toast.error("Branch required");
        return;
      }
      const payload = {
        transferBranch,
        selectedVehicle,
        transferAskingPrice: null,
        method,
      };
      setIsLoadingCallable(true);
      const sendAMWTransferRequest = httpsCallable(
        functions,
        "sendAMWTransferRequest"
      );
      // var sendAMWTransferRequest = firebase
      //   .functions()
      //   .httpsCallable("sendAMWTransferRequest");
      await sendAMWTransferRequest(payload).then((r) => {
        if (!r) {
          toast.error("Not authorised");
        }
      });
    }

    // console.log(payload);
    setShowActionsModal(false);
    setIsLoadingCallable(false);
  };

  const filterByStockNumber = (stockNumber) => {
    setBranchFilter("All");
    setStockSearch(stockNumber);
    // return;
    const tmpList = [...data];
    const tmpFilteredList = tmpList?.filter((v) => {
      if (v.VEHStockNumber.toString().search(stockNumber) >= 0) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredListOfVehicles(tmpFilteredList);
    calculateAgeBuckets(tmpFilteredList);
  };

  const clearStockSearch = () => {
    setStockSearch("");
    setBranchFilter("All");
    setFilteredListOfVehicles(data);
    calculateAgeBuckets(data);
  };

  const showLeads = (stockNumber) => {
    setShowLeadsModal(true);
    getLeadsFromSalesforceByStockNumber(stockNumber);
  };

  const clearLeads = () => {
    setShowLeadsModal(false);
    setLeadsFromSalesforce([]);
  };

  // Get leads for selected vehicle from getLeadsFromVehicleIdInSalesForce oncall function
  const getLeadsFromSalesforceByStockNumber = async (stockNumber) => {
    if (stockNumber.length === 0) {
      toast.warning("Please enter stock number");
      return;
    }
    setIsLoadingLeads(true);
    const getLeadsFromVehicleIdInSalesForce = httpsCallable(
      functions,
      "getLeadsFromVehicleIdInSalesForce"
    );
    // const getLeadsFromVehicleIdInSalesForce = firebase
    //   .functions()
    //   .httpsCallable("getLeadsFromVehicleIdInSalesForce");
    await getLeadsFromVehicleIdInSalesForce(stockNumber).then((r) => {
      console.log(r);
      if (r.data?.length === 0) {
        toast.warning("No leads found");
        return;
      }
      setLeadsFromSalesforce(r?.data);
      setIsLoadingLeads(false);
    });
  };

  // Request yard location change
  // Push yard location to action queue which is picked up in Eclipse
  const pushYardLocationToEclipse = async () => {
    return;
    // Validate

    if (yardLocation.length === 0) {
      toast.warning("Please choose yard location");
      return;
    }

    setIsLoadingYardLocationUpdate(true);

    // // Check if pending yard log update is already queued
    // const queueEmpty = await firestore
    //   .collection("on-prem-action-queue")
    //   .where("actioned", "==", false)
    //   .where("action", "==", "GENERAL_YARD_LOG_UPDATE")
    //   .where("VEHStockNumber", "==", selectedVehicle.VEHStockNumber)
    //   .get()
    //   .then((doc) => {
    //     console.log(doc.empty);

    //     return doc.empty;
    //   });

    // if (queueEmpty === false) {
    //   toast.error(
    //     "An update to Eclipse is still pending, please wait for this to finish and trying again"
    //   );
    //   setIsLoadingYardLocationUpdate(false);
    //   return;
    // }

    // let branchObj = getBranchObjFromBranches(data.vehicleItem[0]?.branch);
    // let carrierIdObj = getCarrierObjectFromId(data.carrierData?.carrier.id);

    const yardLocationComments = `Changed by ${user.data.displayName} from ${selectedVehicle?.VEHYard} to ${yardLocation}`;
    // Add data to insert queue

    const payload = {
      selectedVehicle,
      comments: yardLocationComments,
      createdAt: dayjs().format("YYYY-MM-DD"),
      createdBy: user.data.displayName,
      VEHStockNumber: selectedVehicle?.VEHStockNumber,
      VIN: selectedVehicle?.VEHVIN,
      originalBranch: selectedVehicle?.Branch,
      yardLocation: yardLocation,
      EclipseDBName: selectedVehicle?.db,
      // EclipseBranchID: branch_id,

      actioned: false,
      actionStatus: null,
      actionedTimestamp: null,
      action: "GENERAL_YARD_LOG_UPDATE",
    };

    // Callable function
    const yardChangeLogRequestAMW = httpsCallable(
      functions,
      "yardChangeLogRequestAMW"
    );
    // var yardChangeLogRequestAMW = firebase
    //   .functions()
    //   .httpsCallable("yardChangeLogRequestAMW");
    await yardChangeLogRequestAMW({ yardChangeData: payload, selectedVehicle })
      .then((r) => {
        console.log(r);
        if (r.data.status === "success") {
          toast.success("Yard location update queued");
          toast.info("Yard log should update in Eclipse in 5 minutes");
        } else {
          toast.error("Yard location update failed");
        }
      })
      .catch((e) => {
        toast.error(e.message);
      });

    setIsLoadingYardLocationUpdate(false);
    setShowYardLocationModal(false);
  };

  // Request advertised price change
  // Pushadvertised price change to action queue which is picked up in Eclipse
  const pushAdvetisedPriceChangeToEclipse = async () => {
    toast.warning(
      "This feature is currently disabled because you don't have access"
    );
    return;
    // Validate

    if (customRepriceValue.length === 0) {
      toast.warning("Please set reprice value");
      return;
    }

    if (isNaN(customRepriceValue)) {
      toast.warning("Please enter a valid number");
      return;
    }

    if (parseInt(customRepriceValue) === 0) {
      toast.warning("Please set reprice value");
      return;
    }

    setIsRepricing(true);

    // // Check if pending yard log update is already queued
    // const queueEmpty = await firestore
    //   .collection("on-prem-action-queue")
    //   .where("actioned", "==", false)
    //   .where("action", "==", "GENERAL_YARD_LOG_UPDATE")
    //   .where("VEHStockNumber", "==", selectedVehicle.VEHStockNumber)
    //   .get()
    //   .then((doc) => {
    //     console.log(doc.empty);

    //     return doc.empty;
    //   });

    // if (queueEmpty === false) {
    //   toast.error(
    //     "An update to Eclipse is still pending, please wait for this to finish and trying again"
    //   );
    //   setIsLoadingYardLocationUpdate(false);
    //   return;
    // }

    // let branchObj = getBranchObjFromBranches(data.vehicleItem[0]?.branch);
    // let carrierIdObj = getCarrierObjectFromId(data.carrierData?.carrier.id);

    const priceChangeCommments = `Changed by ${user.data.displayName} from ${selectedVehicle["VEHAskingPrice"]} to ${customRepriceValue}`;
    // Add data to insert queue

    const payload = {
      selectedVehicle,
      comments: priceChangeCommments,
      createdAt: dayjs().format("YYYY-MM-DD"),
      createdBy: user.data.displayName,
      VEHStockNumber: selectedVehicle?.VEHStockNumber,
      VIN: selectedVehicle?.VEHVIN,
      originalBranch: selectedVehicle?.Branch,
      yardLocation: "PRICE_CHANGE",
      EclipseDBName: selectedVehicle?.db,
      advertisedPrice: parseInt(customRepriceValue),
      originalAdvertisedPrice: selectedVehicle["VEHAskingPrice"],
      reprice:
        parseInt(selectedVehicle["VEHAskingPrice"]) -
        parseInt(customRepriceValue),
      // EclipseBranchID: branch_id,

      actioned: false,
      actionStatus: null,
      actionedTimestamp: null,
      action: "ADVERTISED_PRICE_UPDATE",
    };

    // Callable function
    const advertisedPriceChangeRequestAMW = httpsCallable(
      functions,
      "advertisedPriceChangeRequestAMW"
    );
    // var advertisedPriceChangeRequestAMW = firebase
    //   .functions()
    //   .httpsCallable("advertisedPriceChangeRequestAMW");
    await advertisedPriceChangeRequestAMW({
      priceChangeData: payload,
      selectedVehicle,
    })
      .then((r) => {
        console.log(r);
        if (r.data.status === "success") {
          toast.success("Price change update queued");
          toast.info("Asking price should update in Eclipse in 5 minutes");
        } else {
          toast.error("Price change update failed");
        }
      })
      .catch((e) => {
        toast.error(e.message);
      });

    setIsRepricing(false);
    setShowYardLocationModal(false);
  };

  // Pivot lead spread matrix
  const pivotLeadSpreadMatrix = (data, rName, cName, aggretation) => {
    const pivotData = [];
    let rowNames = [...new Set(data.map((v) => v[rName]))];
    let columnNames = [...new Set(data.map((v) => v[cName]))];
    // Order columns by age inlcuding sign
    columnNames = columnNames.sort((a, b) => {
      let aNum = parseInt(
        a.replace("Days", "").replace("<", "").replace(">", "")
      );
      let bNum = parseInt(
        b.replace("Days", "").replace("<", "").replace(">", "")
      );
      let aSign = a.includes("<") ? -1 : 1;
      let bSign = b.includes("<") ? -1 : 1;

      // console.log(aNum, bNum, aSign, bSign);

      if (aSign === -1 && bSign === -1) {
        return aNum - bNum;
      } else if (aSign === -1 && bSign === 1) {
        return -1;
      } else if (aSign === 1 && bSign === -1) {
        return 1;
      } else {
        return bNum - aNum;
      }
    });

    // Order rows by age inlcuding sign
    rowNames = rowNames.sort((a, b) => {
      let aNum = parseInt(
        a.replace("Days", "").replace("<", "").replace(">", "")
      );
      let bNum = parseInt(
        b.replace("Days", "").replace("<", "").replace(">", "")
      );
      let aSign = a.includes("<") ? -1 : 1;
      let bSign = b.includes("<") ? -1 : 1;

      if (aSign === -1 && bSign === -1) {
        return aNum - bNum;
      } else if (aSign === -1 && bSign === 1) {
        return -1;
      } else if (aSign === 1 && bSign === -1) {
        return 1;
      } else {
        return bNum - aNum;
      }
    });

    rowNames.reverse();

    // console.log("row", rowNames);
    // console.log("cols", columnNames);

    for (var i = 0; i < rowNames.length; i++) {
      const rowName = rowNames[i];
      // console.log("pivoting row", rowName);
      const row = { [rowName]: {} };
      for (var j = 0; j < columnNames.length; j++) {
        const columnName = columnNames[j];
        // console.log("counting column", columnName);
        const column = { [columnName]: 0 };
        const filteredData = data.filter(
          (v) => v[rName] === rowName && v[cName] === columnName
        );
        // console.log("filtered", filteredData);
        const count = filteredData.length;

        column[columnName] = count;
        row[rowName] = { ...row[rowName], ...column };
      }
      pivotData.push(row);
    }

    pivotData.reverse();
    // console.log(pivotData);
    return pivotData;
  };

  // calculate vehicles online by branch
  const calculateVehiclesOnlineByBranch = (data) => {
    const branches = [];
    for (var i = 0; i < data.length; i++) {
      const b = data[i];
      branches.push(b.Branch);
    }

    //   Remove duplicate branches
    const uniqBranchList = [...new Set(branches)];

    // Loop through branches and count vehicles online
    let tmpVehiclesOnline = {};
    uniqBranchList.forEach((b) => {
      const tmpBranch = { [b]: 0 };
      tmpVehiclesOnline = { ...tmpVehiclesOnline, ...tmpBranch };
    });

    for (var i = 0; i < data.length; i++) {
      const v = data[i];
      tmpVehiclesOnline[v.Branch] = tmpVehiclesOnline[v.Branch] + 1;
    }

    setVehiclesOnlineByBranch(tmpVehiclesOnline);
  };

  // Calculate profit by age by branch
  const calculateProfitByAgeByBranch = (data) => {
    const branches = [];
    for (var i = 0; i < data.length; i++) {
      const b = data[i];
      branches.push(b.Branch);
    }

    //   Remove duplicate branches
    const uniqBranchList = [...new Set(branches)];

    // calculate max age
    const maxAge = Math.max(...data.map((v) => v.Age));

    // for each page from 0 to Max page, calculate average profit
    const chartData = [];
    for (var i = 0; i <= maxAge; i++) {
      const filteredData = data.filter(
        (v) => v.Age === i && parseInt(v.VEHAskingPrice) > 0
      );
      const averageProfit =
        filteredData.reduce((a, b) => {
          const totalCost = b.costLogs?.reduce((a, b) => a + b.Cost_Inc_GST, 0);
          return a + (b.VEHAskingPrice - totalCost);
        }, 0) / filteredData.length;

      // Average profit percentage
      const averageProfitPercentage =
        (averageProfit /
          filteredData.reduce((a, b) => a + b.VEHAskingPrice, 0)) *
        100;

      const tmpChartObj = {
        age: i,
        averageProfit,
        averageProfitPercentage,
        estimatedGrossProfitPercentage: averageProfitPercentage,
        count: filteredData.length,
        belowZero: 0,
      };
      // If there are enough results to provide a meaningful average
      chartData.push(tmpChartObj);
    }

    let chartDataGroupedByAgeBuckets = [];
    let chartDataGroupedByAgeBucketsAll = [];
    // Max age bucket
    const maxAgeBucket = Math.max(...chartData.map((v) => v.age));
    // const ageBuckets = [0, 30, 60, 90, 120, maxAgeBucket];
    // age buckets array increment by 10 starting from 0
    const ageBuckets = Array.from(
      { length: Math.ceil(maxAgeBucket / 10) },
      (_, i) => i * 10
    );
    // console.log(ageBuckets);
    // console.log(ageBuckets.length);

    for (var i = 1; i < ageBuckets.length; i++) {
      // console.log(
      //   `ageBucket ${i}: ${ageBuckets[i]} - ${ageBuckets[i - 1] || 0}`
      // );
      const ageBucket = ageBuckets[i];
      const previousAgeBucket = ageBuckets[i - 1] || 0;
      const filteredData = data.filter(
        (v) =>
          v.Age > previousAgeBucket &&
          v.Age <= ageBucket &&
          parseInt(v.VEHAskingPrice) > 0
      );
      // console.log(`${filteredData.length} results for ageBucket ${i}`);
      const averageProfit =
        filteredData.reduce((a, b) => {
          const totalCost = b.costLogs?.reduce((a, b) => a + b.Cost_Inc_GST, 0);
          return a + (b.VEHAskingPrice - totalCost);
        }, 0) / filteredData.length;
      // console.log("filteredData", filteredData);
      // Average profit percentage
      const averageProfitPercentage =
        (averageProfit /
          filteredData.reduce((a, b) => a + b.VEHAskingPrice, 0)) *
        100;

      const tmpChartObj = {
        age: ageBucket,
        expectedGrossProfit: Math.floor(averageProfit),
        averageProfit,
        averageProfitPercentage,
        estimatedGrossProfitPercentage: averageProfitPercentage,
        numberOfCars: filteredData.length,
        count: filteredData.length,
        belowZero: 0,
        numberOfBuckets: ageBuckets.length,
      };

      // Check if there are enough results to provide a meaningful average
      // if (filteredData.length > 1)
      chartDataGroupedByAgeBucketsAll.push(tmpChartObj);
    }

    // Calulate expected gp by branch by age
    const expectedGpByBranchByAge = [];
    uniqBranchList.forEach((b) => {
      chartDataGroupedByAgeBuckets = [];
      for (var i = 1; i < ageBuckets.length; i++) {
        // console.log(
        //   `ageBucket ${i}: ${ageBuckets[i]} - ${ageBuckets[i - 1] || 0}`
        // );
        const ageBucket = ageBuckets[i];
        const previousAgeBucket = ageBuckets[i - 1] || 0;
        const filteredData = data.filter(
          (v) =>
            v.Age > previousAgeBucket &&
            v.Age <= ageBucket &&
            parseInt(v.VEHAskingPrice) > 0 &&
            v.Branch === b
        );
        // console.log(`${filteredData.length} results for ageBucket ${i}`);
        const averageProfit = Math.floor(
          filteredData.reduce((a, b) => {
            const totalCost = b.costLogs?.reduce(
              (a, b) => a + b.Cost_Inc_GST,
              0
            );
            return a + (b.VEHAskingPrice - totalCost);
          }, 0) / filteredData.length
        );
        // console.log("filteredData", filteredData);
        // Average profit percentage
        const averageProfitPercentage =
          (averageProfit /
            filteredData.reduce((a, b) => a + b.VEHAskingPrice, 0)) *
          100;

        const tmpChartObj = {
          age: ageBucket,
          [b]: Math.floor(averageProfit),
          averageProfit,
          averageProfitPercentage,
          estimatedGrossProfitPercentage: averageProfitPercentage,
          numberOfCars: filteredData.length,
          count: filteredData.length,
          belowZero: 0,
          numberOfBuckets: ageBuckets.length,
        };

        // Check if there are enough results to provide a meaningful average
        // if (filteredData.length > 1)
        chartDataGroupedByAgeBuckets.push(tmpChartObj);
      }
      expectedGpByBranchByAge.push({
        branch: b,
        data: chartDataGroupedByAgeBuckets,
      });
    });
    // console.log("expectedGpByBranchByAge", expectedGpByBranchByAge);

    const cData = [];

    for (var i = 0; i < ageBuckets.length; i++) {
      const ageBucket = ageBuckets[i];
      let averages = {};

      for (var j = 0; j < expectedGpByBranchByAge.length; j++) {
        const branch = expectedGpByBranchByAge[j].branch;
        const average = {
          [branch]: expectedGpByBranchByAge[j]?.data[i]?.averageProfit || null,
        };
        averages = { ...averages, ...average };
      }
      const tmpChartObj = {
        age: ageBucket,
        ALL:
          Math.floor(chartDataGroupedByAgeBucketsAll[i]?.averageProfit) || null,
        ...averages,
      };
      cData.push(tmpChartObj);
    }

    // Initialise chart line visibility state to true
    const tmpChartLineVisibility = {};
    uniqBranchList.forEach((b) => {
      tmpChartLineVisibility[b] = true;
    });
    tmpChartLineVisibility["ALL"] = true;
    setChartLineVisibility(tmpChartLineVisibility);

    // console.log(cData);
    // console.log(tmpChartLineVisibility);

    setProfitByAgeByBranchChartData(cData);
  };

  // Calculate working cap by branch
  const calculateWorkingCapByBranch = (data) => {
    const branches = [];
    for (var i = 0; i < data.length; i++) {
      const b = data[i];
      branches.push(b.Branch);
    }

    //   Remove duplicate branches
    const uniqBranchList = [...new Set(branches)];
    // console.log("de dup to", uniqBranchList.length);

    // Loop through branches and set cumulative cost to 0
    let tmpWorkingCap = {};
    uniqBranchList.forEach((b) => {
      const tmpBranch = { [b]: { cost: 0, countOfVehicles: 0 } };
      tmpWorkingCap = { ...tmpWorkingCap, ...tmpBranch };
    });

    for (var i = 0; i < data.length; i++) {
      const v = data[i];
      // loop through all costs for each vehicle
      for (var j = 0; j < v.costLogs?.length; j++) {
        const tmpCost = v.costLogs[j];

        // accumulate cost to appropriate branch
        tmpWorkingCap[v.Branch].cost =
          tmpWorkingCap[v.Branch].cost + tmpCost.Cost_Inc_GST;

        // accumulate count of vehicles appropriate branch
        // tmpWorkingCap[v.Branch].countOfVehicles =
        //   tmpWorkingCap[v.Branch].countOfVehicles + 1;
      }
      tmpWorkingCap[v.Branch].countOfVehicles++;
      // console.log(v.Branch);
      // console.log(tmpWorkingCap[v.Branch].countOfVehicles);
    }

    // console.log(tmpWorkingCap);
    if (tmpWorkingCap) {
      const tmpTotalVehicleCount = Object.keys(tmpWorkingCap)
        .map((b) => tmpWorkingCap[b].countOfVehicles)
        .reduce((a, b) => a + b, 0);
      setTotalVehicleCount(tmpTotalVehicleCount);
    }

    setWorkingCapitalBranch(tmpWorkingCap);
  };

  // Filter inventory list by leadSpreadMatrix
  const subFilterByLeadSpread = async (
    daysOnlineFilter,
    daysSinceLastLeadFilter
  ) => {
    toast.info(
      `Filtering by ${daysOnlineFilter} days online and ${daysSinceLastLeadFilter} days since last lead`
    );
    // Get stock numbers from lead spread matrix
    const stockNumbers = [];
    const filteredData = leadSpreadMatrix.filter(
      (v) =>
        v["Days_Online"] === daysOnlineFilter &&
        v["Days_Since_Last_Lead"] === daysSinceLastLeadFilter
    );
    if (filteredData.length === 0) {
      toast.warning("No vehicles found");
      return;
    }
    for (var i = 0; i < filteredData.length; i++) {
      const v = filteredData[i];
      stockNumbers.push(v.Stocknumber);
    }

    // Filter inventory list by stock numbers
    const filteredInventory = data.filter((v) =>
      stockNumbers.includes(v.VEHStockNumber)
    );

    // toast(`Filtering ${filteredInventory.length} vehicles`);
    // console.log(filteredInventory);
    // Check if branch filter is applied and filter accordingly
    if (branchFilter !== "All") {
      const filteredByBranch = filteredInventory.filter(
        (v) => v.Branch.replace("D1 - ", "") === branchFilter
      );
      setFilteredListOfVehicles(filteredByBranch);
    } else {
      setFilteredListOfVehicles(filteredInventory);
    }
    setIsFilteredByLeadSpread(true);
  };

  // Clear lead spread filter and reset inventory list
  const clearLeadSpreadFilter = () => {
    setFilteredListOfVehicles(data);
    setIsFilteredByLeadSpread(false);
  };

  // Open lead health in Salesforce
  const openLeadHealthInSalesforce = () => {
    var leadHealthReportId = "";
    switch (branchFilter) {
      case "All":
        toast.warning("Please select an active branch");
        return;

      case "DANDENONG":
        leadHealthReportId = "00O9o000000LBdREAW";
        break;

      case "MELBOURNE":
        leadHealthReportId = "00O9o000000LBcsEAG";
        break;

      case "WESTGATE":
        leadHealthReportId = "00O9o000000LBj0EAG";
        break;

      case "FAIRFIELD":
        leadHealthReportId = "00O9o000000LBj5EAG";
        break;

      case "ALBION":
        leadHealthReportId = "00O9o000000LBjFEAW";
        break;

      case "PLYMPTON":
        leadHealthReportId = "00O9o000000LBjAEAW";
        break;

      default:
        toast.warning("Please select a branch");
        return;
    }

    if (leadHealthReportId.length === 0) {
      toast.warning("Lead health report not available for this branch");
      return;
    }
    const url = `https://duttongarages.lightning.force.com/lightning/r/Report/${leadHealthReportId}/view?queryScope=userFolders`;
    window.open(url, "_blank");
  };

  // set the Gradient colour for the lead spread matrix
  function getGradientColor(x, y, x_max, y_max) {
    const colours = [
      [76, 175, 80], //green
      [255, 235, 59], //yellowish...
      [229, 57, 53], //red
    ];
    const offset = -0.3;
    const ratio = x / x_max + y / y_max + offset;
    let colorIndex = Math.floor(ratio * (colours.length - 1));
    if (colorIndex < 0) {
      colorIndex = 0;
    } else if (colorIndex >= colours.length - 1) {
      colorIndex = colours.length - 2;
    }
    const startColor = colours[colorIndex];
    const endColor = colours[colorIndex + 1];
    const result = [];
    for (let i = 0; i < 3; i++) {
      result.push(
        Math.round(
          startColor[i] +
            (ratio - colorIndex / (colours.length - 1)) *
              (endColor[i] - startColor[i])
        )
      );
    }
    return `rgb(${result.join(",")})`;
  }

  // set the font colour for the lead spread matrix
  function getFontColor(x, y, x_max, y_max) {
    const colours = [
      [76, 175, 80], //green
      [255, 235, 59], //yellowish...
      [229, 57, 53], //red
    ];
    const offset = -0.3;
    const ratio = x / x_max + y / y_max + offset;
    let colorIndex = Math.floor(ratio * (colours.length - 1));
    if (colorIndex < 0) {
      colorIndex = 0;
    } else if (colorIndex >= colours.length - 1) {
      colorIndex = colours.length - 2;
    }
    const startColor = colours[colorIndex];
    const endColor = colours[colorIndex + 1];
    const result = [];
    for (let i = 0; i < 3; i++) {
      result.push(
        Math.round(
          startColor[i] +
            (ratio - colorIndex / (colours.length - 1)) *
              (endColor[i] - startColor[i])
        )
      );
    }
    const brightness =
      (299 * result[0] + 587 * result[1] + 114 * result[2]) / 1000;
    return brightness > 128 ? "black" : "white";
  }

  // Render lead spread matrix cell
  function renderLeadSpreadMatrixCell(
    x,
    y,
    x_max,
    y_max,
    value,
    filterX,
    filterY
  ) {
    const style = {
      backgroundColor: getGradientColor(x, y, x_max, y_max),
      color: getFontColor(x, y, x_max, y_max),
      cursor: "pointer",
      transition: "box-shadow 0.3s ease-in-out",
      boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
    };

    return (
      <td
        style={style}
        onClick={() => subFilterByLeadSpread(filterX, filterY)}
        key={y}
      >
        {value}
      </td>
    );
  }

  // Print L Form
  const printLForm = async () => {
    if (selectedVehicle) {
      setIsLoadingPdf(true);
      setPdfBase64("");

      const payload = {
        formForAusState: "VIC",
        inputData: {
          urlForQR:
            "https://duttonone.com.au/products/mercedes-benz-glc-class-c253-glc300-coupe-5dr-9g-tronic-9sp-4matic-20t-da64ac9c-430c-4643-94c6-4279c7b00193",
          seller: {
            lmctNo: "4219173",
            name: "DUTTON ONE BRISBANE",
            address: "75 Sandgate Road",
            abn: "21 155 253 311",
            phone: "0734870500",
            postcode: "4010",
            suburb: "ALBION",
            state: "QLD",
            traderName: "",
            signDate: "19/09/2023",
          },
          vehicle: {
            lctAmount: "",
            make: "Mercedes-Benz",
            model: "GLC-Class",
            year: 2020,
            kms: 24168,
            builtDate: "2019/12",
            compliedDate: "2020/2",
            registrationNo: "983HY9",
            chassisNo: "W1N2533842F771730",
            hasWarranty: true,
            isSoldWithRegistration: true,
            isWrittenOff: false,
            price: 79990,
            feeTransfer: 42,
            feeDelivery: 1695,
            feeDuty: 4160,
            priceTotal: 85887,
          },
          isSellerSignDate: true,
          buyer: { name: "", signDate: "19/09/2023" },
          isBuyerSignDate: true,
        },
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "User-Agent": "Insomnia/2023.5.6",
        },
        body: JSON.stringify(payload),
      };

      const tmpPdfB64 = await fetch(
        "https://mi1bw3rxk1.execute-api.ap-southeast-2.amazonaws.com/prod/generate-pdf-base64",
        options
      )
        .then((r) => r.text())
        .then((response) => {
          return response;
        })
        .catch((err) => console.error(err));
      setPdfBase64(tmpPdfB64);
      setIsLoadingPdf(false);
      setShowPdfModal(true);
    }
  };

  // Set calcs on data
  useEffect(() => {
    if (data) {
      calculateWorkingCapByBranch(data);
      if (data[0]?.costLogs) {
        calculateProfitByAgeByBranch(data);
        setLoadingProfitByAgeByBranchChartData(false);
      } else {
        setLoadingProfitByAgeByBranchChartData(true);
      }
    }
  }, [JSON.stringify(data)]);

  // Get inventory listing data
  useEffect(() => {
    const unsub = firestore
      .collection("autogate-inventory-distribution")
      .where("SaleStatus", "==", "For Sale")
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          // toast.info("No actions found");
          setAgData([]);
          return;
        }
        const td = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();

          if (internalSiteGUID.includes(tmpData.Seller?.Identifier)) {
            td.push({
              id: doc.id,
              ...tmpData,
            });
          }
        });
        // console.log("ag data", td);
        // Transform data to map by VIN
        const tdMap = [];
        td.forEach((v) => {
          const vin = v.Identification.find((i) => i.Type === "VIN")?.Value;
          tdMap[vin] = v;
        });

        // Group by branch

        // console.log("ag data map", tdMap);
        setAgData(tdMap);
      });
    return () => unsub();
  }, []);

  // calculate vehicles online by branch
  useEffect(() => {
    if (leadSpreadMatrix) {
      calculateVehiclesOnlineByBranch(leadSpreadMatrix);
    }
  }, [JSON.stringify(leadSpreadMatrix)]);

  // Filter data from FS
  useEffect(() => {
    if (data) {
      if (branchFilter === "All") {
        calculateAgeBuckets(data);

        const latestYardLog = data.map((v) => {
          if (v.yardLogs?.length > 1) {
            const max = v.yardLogs?.reduce((a, b) => {
              // console.log("a", a);
              // console.log("b", b);
              return Math.max(a.Yard_Change_Date_EPOC, b.Yard_Change_Date_EPOC);
            }, 0);
            // console.log("latest yl", max);
          } else {
            return v;
          }
        });
        setFilteredListOfVehicles(data);
      } else {
        const tmpFilter = data.filter((d) => {
          const currentBranch = d?.Branch.replace("D1 ", "");
          return branchFilter === currentBranch;
        });
        calculateAgeBuckets(tmpFilter);
        setFilteredListOfVehicles(tmpFilter);
      }
    }
  }, [JSON.stringify(data), branchFilter]);

  //Get photos where cars are in-stock from inventory-photos
  useEffect(() => {
    if (selectedVehicle) {
      setIsLoadingActions(true);
      const unsub = firestore
        .collection("inventory-photos")
        .where("status", "==", "In-Stock")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            // toast.info("No actions found");
            setInStockPhotos([]);
            return;
          }
          const tmpActions = [];

          snapshot.forEach((doc) => {
            const tmpData = doc.data();
            tmpActions.push({ ...tmpData, id: doc.id });
          });
          if (tmpActions.length > 0) {
            // toast.success(`Found ${tmpActions.length} actions`);
          }
          setInStockPhotos(tmpActions);
          setIsLoadingActions(false);
        });
      return () => unsub();
    }
  }, [JSON.stringify(data)]);

  //Update vehicle actions on selected vehicle
  // useEffect(() => {
  //   if (selectedVehicle) {
  //     setIsLoadingActions(true);
  //     const unsub = firestore
  //       .collection("inventory-health-actions")
  //       .doc(selectedVehicle?.VEHStockNumber?.toString)
  //       .collection("actions-log")
  //       .onSnapshot((snapshot) => {
  //         if (snapshot.empty) {
  //           // toast.info("No actions found");
  //           setSelectedVehicleActions([]);
  //           return;
  //         }
  //         const tmpActions = [];

  //         snapshot.forEach((doc) => {
  //           const tmpData = doc.data();
  //           tmpActions.push({ ...tmpData, id: doc.id });
  //         });
  //         if (tmpActions.length > 0) {
  //           // toast.success(`Found ${tmpActions.length} actions`);
  //         }
  //         setSelectedVehicleActions(tmpActions);
  //         setIsLoadingActions(false);
  //       });
  //     return () => unsub();
  //   }
  // }, [selectedVehicle]);

  // Check for sugar vehicle data when selected vehicle changes
  useEffect(() => {
    if (selectedVehicle) {
      setSugarVehicleData({});
      setIsLoadingSuggestedRetailPrice(true);
      // Callable function getSugarVehicleDataFromFreightBookings
      const getSugarVehicleDataFromFreightBookings = httpsCallable(
        functions,
        "getSugarVehicleDataFromFreightBookings"
      );
      // const getSugarVehicleDataFromFreightBookings = firebase
      //   .functions()
      //   .httpsCallable("getSugarVehicleDataFromFreightBookings");
      getSugarVehicleDataFromFreightBookings({
        stockNumber: selectedVehicle?.VEHStockNumber,
        vin: selectedVehicle?.VEHVIN,
      })
        .then((result) => {
          // Read result of the Cloud Function.
          const { data } = result;
          if (data) {
            console.log("sugar vehicle data", data);
            setSugarVehicleData(data);
            setIsLoadingSuggestedRetailPrice(false);
          } else {
            console.log("no sugar vehicle data");
            setSugarVehicleData(null);
            setIsLoadingSuggestedRetailPrice(false);
          }
        })
        .catch((error) => {
          // Getting the Error details.
          const { code, message, details } = error;
          console.log("error", code, message, details);
          setIsLoadingSuggestedRetailPrice(false);
        });
    }
  }, [selectedVehicle]);

  //Update lead price life on on selected vehicle
  useEffect(() => {
    if (selectedVehicle) {
      setIsLoadingActions(true);
      firestore
        .collection("lead-price-life-d1-nsw")
        .where("VEHStockNumber", "==", selectedVehicle?.VEHStockNumber || "")
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            // toast.info("No price changes found");
            setSelectedVehicleLeadPriceLife([]);
            return;
          }
          const tmpActions = [];

          snapshot.forEach((doc) => {
            const tmpData = doc.data();
            const linechartset = {
              name: dayjs.unix(tmpData.Date_Recorded).format("DD-MM"),
              price: tmpData.Advertise_Price,
              leads: tmpData.Leads,
              stockId: tmpData.VEHStockNumber,
            };
            tmpActions.push({ ...linechartset, id: doc.id });
          });
          if (tmpActions.length > 0) {
            // toast.success(`Loaded price change graph`);
          }
          setSelectedVehicleLeadPriceLife(tmpActions);
          setIsLoadingActions(false);
        });

      setIsLoadingActions(false);
    }
  }, [selectedVehicle]);

  // Get leadspread data
  useEffect(() => {
    setIsLoadingLeadSpread(true);
    // console.log("getting lead spread data");
    const unsub = firestore
      .collection("lead-spread-matrix-d1-nsw")
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          // toast.info("No lead spread data found");
          setLeadSpreadMatrix([]);
          return;
        }
        // console.log("lead spread data found");
        // console.log(snapshot.size);
        const tmpMartix = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpMartix.push({ ...tmpData, id: doc.id });
        });
        // console.log(tmpMartix) ;

        setLeadSpreadMatrix(tmpMartix);

        // Create object with stock number as key
        const leadSpreadObject = {};
        tmpMartix.forEach((v) => {
          leadSpreadObject[v.Stocknumber] = v;
        });

        setLeadSpreadWithStockNumberasKey(leadSpreadObject);
      });

    return () => unsub();
  }, []);

  // Filter lead spread matrix and calculate pivot

  useEffect(() => {
    setIsLoadingLeadSpread(true);
    let pivotedMatrix = [];
    if (branchFilter === "All") {
      pivotedMatrix = pivotLeadSpreadMatrix(
        leadSpreadMatrix,
        ["Days_Online"],
        ["Days_Since_Last_Lead"],
        "Leads"
      );
    } else {
      const tmpLeadSpread = leadSpreadMatrix?.filter((v) => {
        const trimmedMatrixBranch = v?.Branch?.replace("D1 ", "");
        return branchFilter === trimmedMatrixBranch;
      });
      pivotedMatrix = pivotLeadSpreadMatrix(
        tmpLeadSpread,
        ["Days_Online"],
        ["Days_Since_Last_Lead"],
        "Leads"
      );
    }
    // console.log("completed pivot");
    // console.log(pivotedMatrix);
    setLeadSpreadMatrixFiltered(pivotedMatrix);
    setIsLoadingLeadSpread(false);
  }, [JSON.stringify(leadSpreadMatrix), branchFilter]);

  return (
    <>
      <ToastContainer />
      {/* Inner route for different branches?  */}

      {/* START BRANCH SELECTOR */}
      <Container></Container>

      {/* END BRANCH SELECTOR */}

      <Container>
        <Row>
          <Col>
            {/* START BRANCH FILTER CARD */}
            <Card style={styles.card}>
              <Card.Header style={styles.cardHeader}>Branch Filter</Card.Header>
              <Card.Body>
                <ToggleButtonGroup
                  style={styles.toggleButtonGroup}
                  name="site_filter"
                  size="sm"
                  onChange={(value) => {
                    setStockSearch("");
                    clearLeadSpreadFilter();
                    setBranchFilter(value);
                  }}
                  value={branchFilter}
                >
                  <ToggleButton
                    style={styles.toggleButton}
                    value="MASCOT"
                    variant="outline-warning"
                  >
                    Mascot
                  </ToggleButton>
                  <ToggleButton
                    style={styles.toggleButton}
                    value="CHATSWOOD"
                    variant="outline-warning"
                  >
                    Chatswood
                  </ToggleButton>
                  <ToggleButton
                    style={styles.toggleButton}
                    value="BROOKVALE"
                    variant="outline-warning"
                  >
                    Brookvale
                  </ToggleButton>
                  <ToggleButton
                    style={styles.toggleButton}
                    value="CARINGBAH"
                    variant="outline-warning"
                  >
                    Caringbah
                  </ToggleButton>
                  <ToggleButton
                    style={styles.toggleButton}
                    value="CASTLE HILL"
                    variant="outline-warning"
                  >
                    Castle Hill
                  </ToggleButton>
                  <ToggleButton
                    style={styles.toggleButton}
                    value="All"
                    variant="outline-warning"
                  >
                    All
                  </ToggleButton>
                </ToggleButtonGroup>
              </Card.Body>
            </Card>

            {/* END BRANCH FILTER CARD */}
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col>
                {/* START INVENTORY COUNTS */}
                <Card style={styles.card}>
                  <Card.Header style={styles.cardHeader}>
                    Inventory Summary
                  </Card.Header>
                  <Card.Body>
                    <Table size="sm">
                      <thead>
                        <tr style={{ fontWeight: "bold" }}>
                          <td
                            style={{
                              width: 15,
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                branchFilter === "MASCOT" ? "#ffc107" : null,
                            }}
                          >
                            Mascot
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                branchFilter === "CHATSWOOD" ? "#ffc107" : null,
                            }}
                          >
                            Chatswood
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                branchFilter === "BROOKVALE" ? "#ffc107" : null,
                            }}
                          >
                            Brookvale
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                branchFilter === "CARINGBAH" ? "#ffc107" : null,
                            }}
                          >
                            Caringbah{" "}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                branchFilter === "CASTLE HILL"
                                  ? "#ffc107"
                                  : null,
                            }}
                          >
                            Castle Hill{" "}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                  <td>
                    <b>Budget</b>
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={WORKING_CAP_MELBOURNE}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={WORKING_CAP_WESTGATE}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={WORKING_CAP_DANDENONG}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={WORKING_CAP_FAIRFIELD}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={WORKING_CAP_BRISBANE}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={WORKING_CAP_PLYMPTON}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={WORKING_CAP_ALBION}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={WORKING_CAP_RECON}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>In Stock Value</b>
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={`${Math.ceil(
                        workingCapitalBranch["AMW - MELBOURNE"]?.cost
                      )}`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={`${Math.ceil(
                        workingCapitalBranch["AMW - WESTGATE"]?.cost
                      )}`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={`${Math.ceil(
                        workingCapitalBranch["AMW - DANDENONG"]?.cost
                      )}`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={`${Math.ceil(
                        workingCapitalBranch["AMW - FAIRFIELD"]?.cost
                      )}`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={`${Math.ceil(
                        workingCapitalBranch["AMW - BRISBANE"]?.cost
                      )}`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={`${Math.ceil(
                        workingCapitalBranch["AMW - PLYMPTON"]?.cost
                      )}`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={`${Math.ceil(
                        workingCapitalBranch["AMW - ALBION"]?.cost || 0
                      )}`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={`${Math.ceil(
                        workingCapitalBranch["AMW - BUYING/RECONDITIONING"]
                          ?.cost
                      )}`}
                    />
                  </td>
                </tr> */}
                        {/* <tr>
                  <td>
                    <b>Remaining</b>
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      style={{
                        color:
                          WORKING_CAP_MELBOURNE -
                            workingCapitalBranch["AMW - MELBOURNE"]?.cost <=
                          0
                            ? "red"
                            : null,
                      }}
                      value={`${Math.ceil(
                        WORKING_CAP_MELBOURNE -
                          workingCapitalBranch["AMW - MELBOURNE"]?.cost
                      )}`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      style={{
                        color:
                          WORKING_CAP_WESTGATE -
                            workingCapitalBranch["AMW - WESTGATE"]?.cost <=
                          0
                            ? "red"
                            : null,
                      }}
                      prefix="$"
                      value={`${Math.ceil(
                        WORKING_CAP_WESTGATE -
                          workingCapitalBranch["AMW - WESTGATE"]?.cost
                      )}`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      style={{
                        color:
                          WORKING_CAP_DANDENONG -
                            workingCapitalBranch["AMW - DANDENONG"]?.cost <=
                          0
                            ? "red"
                            : null,
                      }}
                      value={`${Math.ceil(
                        WORKING_CAP_DANDENONG -
                          workingCapitalBranch["AMW - DANDENONG"]?.cost
                      )}`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      style={{
                        color:
                          WORKING_CAP_FAIRFIELD -
                            workingCapitalBranch["AMW - FAIRFIELD"]?.cost <=
                          0
                            ? "red"
                            : null,
                      }}
                      value={`${Math.ceil(
                        WORKING_CAP_FAIRFIELD -
                          workingCapitalBranch["AMW - FAIRFIELD"]?.cost
                      )}`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      style={{
                        color:
                          WORKING_CAP_BRISBANE -
                            workingCapitalBranch["AMW - BRISBANE"]?.cost <=
                          0
                            ? "red"
                            : null,
                      }}
                      value={`${Math.ceil(
                        WORKING_CAP_BRISBANE -
                          workingCapitalBranch["AMW - BRISBANE"]?.cost
                      )}`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      style={{
                        color:
                          WORKING_CAP_PLYMPTON -
                            workingCapitalBranch["AMW - PLYMPTON"]?.cost <=
                          0
                            ? "red"
                            : null,
                      }}
                      value={`${Math.ceil(
                        WORKING_CAP_PLYMPTON -
                          workingCapitalBranch["AMW - PLYMPTON"]?.cost
                      )}`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      style={{
                        color:
                          WORKING_CAP_ALBION -
                            workingCapitalBranch["AMW - ALBION"]?.cost <=
                          0
                            ? "red"
                            : null,
                      }}
                      value={`${Math.ceil(
                        WORKING_CAP_ALBION -
                          (workingCapitalBranch["AMW - ALBION"]?.cost || 0)
                      )}`}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      style={{
                        color:
                          WORKING_CAP_RECON -
                            workingCapitalBranch["AMW - BUYING/RECONDITIONING"]
                              ?.cost <=
                          0
                            ? "red"
                            : null,
                      }}
                      value={`${Math.ceil(
                        WORKING_CAP_RECON -
                          workingCapitalBranch["AMW - BUYING/RECONDITIONING"]
                            ?.cost
                      )}`}
                    />
                  </td>
                </tr> */}
                        {/* Total vehicles */}
                        <tr>
                          <td
                            style={{
                              borderRight: "1px solid black",
                            }}
                          >
                            T
                          </td>
                          <td>
                            {workingCapitalBranch["D1 MASCOT"]
                              ?.countOfVehicles || 0}
                          </td>
                          <td>
                            {workingCapitalBranch["D1 CHATSWOOD"]
                              ?.countOfVehicles || 0}
                          </td>
                          <td>
                            {workingCapitalBranch["D1 BROOKVALE"]
                              ?.countOfVehicles || 0}
                          </td>
                          <td>
                            {workingCapitalBranch["D1 CARINGBAH"]
                              ?.countOfVehicles || 0}
                          </td>
                          <td>
                            {workingCapitalBranch["D1 CASTLE HILL"]
                              ?.countOfVehicles || 0}
                          </td>
                        </tr>
                        {/* Vehicles online */}
                        <tr>
                          <td
                            style={{
                              borderRight: "1px solid black",
                            }}
                          >
                            O
                          </td>
                          <td>{vehiclesOnlineByBranch["D1 MASCOT"] || 0}</td>
                          <td>{vehiclesOnlineByBranch["D1 CHATSWOOD"] || 0}</td>
                          <td>{vehiclesOnlineByBranch["D1 BROOKVALE"] || 0}</td>
                          <td>{vehiclesOnlineByBranch["D1 CARINGBAH"] || 0}</td>
                          <td>
                            {vehiclesOnlineByBranch["D1 CASTLE HILL"] || 0}
                          </td>
                        </tr>
                        {/* Vehicles online percentage */}
                        <tr>
                          <td
                            style={{
                              borderRight: "1px solid black",
                            }}
                          >
                            O%
                          </td>
                          <td>
                            {Math.floor(
                              (vehiclesOnlineByBranch["D1 CARINGBAH"] /
                                workingCapitalBranch["D1 CARINGBAH"]
                                  ?.countOfVehicles) *
                                1000
                            ) / 10}
                            %
                          </td>
                          <td>
                            {Math.floor(
                              (vehiclesOnlineByBranch["D1 CHATSWOOD"] /
                                workingCapitalBranch["D1 CHATSWOOD"]
                                  ?.countOfVehicles) *
                                1000
                            ) / 10}
                            %
                          </td>
                          <td>
                            {Math.floor(
                              (vehiclesOnlineByBranch["D1 BROOKVALE"] /
                                workingCapitalBranch["D1 BROOKVALE"]
                                  ?.countOfVehicles) *
                                1000
                            ) / 10}
                            %
                          </td>
                          <td>
                            {Math.floor(
                              (vehiclesOnlineByBranch["D1 CARINGBAH"] /
                                workingCapitalBranch["D1 CARINGBAH"]
                                  ?.countOfVehicles) *
                                1000
                            ) / 10}
                            %
                          </td>
                          <td>
                            {Math.floor(
                              (vehiclesOnlineByBranch["D1 CASTLE HILL"] /
                                workingCapitalBranch["D1 CASTLE HILL"]
                                  ?.countOfVehicles) *
                                1000
                            ) / 10}
                            %
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={6}>&nbsp;</td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              backgroundColor:
                                branchFilter === "All" ? "#ffc107" : null,
                            }}
                          >
                            All
                          </td>
                        </tr>
                        {/*  total vehicles */}
                      </tbody>
                      <tfoot
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        <tr>
                          <td colSpan={6}>
                            <i>
                              T: Total, O: Online, O%: Online as a percentage of
                              Total
                            </i>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </Card.Body>
                </Card>
                {/* END INVENTORY COUNTS */}
              </Col>
            </Row>
            <Row>
              {/* START INVENTORY AGE CARD */}
              <Col>
                <Card style={styles.card}>
                  <Card.Header style={styles.cardHeader}>
                    Inventory Age Buckets
                  </Card.Header>
                  <Card.Body>
                    <Table size="sm">
                      <tbody>
                        <tr>
                          {ageBuckets.map((b, idx) => {
                            let colour = "";
                            switch (idx) {
                              case 0:
                                colour = "#82E0AA";
                                break;

                              case 1:
                                colour = "#C39BD3";
                                break;

                              case 2:
                                colour = "#F8C471";
                                break;

                              case 3:
                                colour = "#F1948A";
                                break;

                              case 4:
                                colour = "#BFC9CA";
                                break;
                              default:
                            }
                            return (
                              <th key={idx} style={{ backgroundColor: colour }}>
                                {b.bucketName}
                              </th>
                            );
                          })}
                          <th style={{ backgroundColor: "#F9E79F" }}>
                            # Freight In
                          </th>
                        </tr>
                        <tr>
                          {ageBuckets.map((b, idx) => {
                            let colour = "";
                            switch (idx) {
                              case 0:
                                colour = "#28B463";
                                break;

                              case 1:
                                colour = "#8E44AD";
                                break;

                              case 2:
                                colour = "#F39C12";
                                break;

                              case 3:
                                colour = "#E74C3C";
                                break;
                              default:
                            }
                            return (
                              <td key={idx} style={{ color: colour }}>
                                {b.count}
                              </td>
                            );
                          })}
                          <td>
                            {
                              filteredListOfVehicles?.filter((v) =>
                                ["FreightIn", "FREIGHTIN"].includes(v.VEHYard)
                              ).length
                            }
                          </td>
                        </tr>
                        <tr>
                          {ageBuckets.map((b, idx) => {
                            let colour = "";
                            switch (idx) {
                              case 0:
                                colour = "#28B463";
                                break;

                              case 1:
                                colour = "#8E44AD";
                                break;

                              case 2:
                                colour = "#F39C12";
                                break;

                              case 3:
                                colour = "#E74C3C";
                                break;
                              default:
                            }
                            return (
                              <td style={{ color: colour }} key={idx}>
                                {isNaN(b.percentageOfTotal)
                                  ? "0"
                                  : b.percentageOfTotal}
                                %
                              </td>
                            );
                          })}
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
              {/* END INVENTORY AGE CARD */}
            </Row>
          </Col>

          <Col>
            {/* START LEAD SPREAD CARD */}
            <Card style={styles.card}>
              <Card.Header style={styles.cardHeader}>
                Lead Spread Matrix
              </Card.Header>
              <Card.Body>
                {leadSpreadMatrixFiltered.length === 0 ? (
                  <p>No data to display</p>
                ) : null}
                {isLoadingLeadSpread ? (
                  <Spinner size="sm" animation="border" />
                ) : null}
                {leadSpreadMatrixFiltered.length === 0 ? null : (
                  <Table size="sm">
                    {/* Display leadSpreadMatrix as a table */}
                    <thead>
                      <tr>
                        <td colSpan={6}>
                          <i>
                            <sup>
                              The numbers represent the count of cars in a given
                              bucket
                            </sup>
                          </i>
                        </td>
                      </tr>
                      <tr>
                        <th>&nbsp;</th>
                        <th
                          colSpan={
                            Object.keys(
                              Object.values(
                                leadSpreadMatrixFiltered[0] || []
                              )[0] || []
                            ).length
                          }
                        >
                          Days since last lead
                        </th>
                      </tr>
                      <tr>
                        <th>&nbsp;</th>
                        {Object.keys(
                          Object.values(leadSpreadMatrixFiltered[0] || [])[0] ||
                            []
                        )?.map((k, idx) => {
                          return <th key={idx}>{k}</th>;
                        })}
                      </tr>
                      <tr>
                        <th>Days online</th>
                        <th
                          colSpan={
                            Object.keys(
                              Object.values(
                                leadSpreadMatrixFiltered[0] || null
                              )[0] || null
                            ).length
                          }
                        >
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {leadSpreadMatrixFiltered.map((row, idx_x) => {
                        return (
                          <tr key={idx_x}>
                            <td>{Object.keys(row)}</td>
                            {/* <td>
                        {JSON.stringify(
                          Object.keys(Object.values(row)[0]),
                          null,
                          2
                        )}
                      </td> */}
                            {Object.keys(Object.values(row)[0]).map(
                              (k, idx_y) => {
                                return renderLeadSpreadMatrixCell(
                                  idx_x,
                                  idx_y,
                                  leadSpreadMatrixFiltered.length,
                                  Object.keys(Object.values(row)[0] || [])
                                    .length,
                                  Object.values(row)[0][k],
                                  Object.keys(row)[0],
                                  k
                                );
                                // <td key={idx_y}>
                                //   <p
                                //     onClick={() =>
                                //       subFilterByLeadSpread(
                                //         Object.keys(row)[0],
                                //         k
                                //       )
                                //     }
                                //     style={style}
                                //   >
                                //     {Object.values(row)[0][k]}
                                //   </p>
                                // </td>
                                // );
                              }
                            )}

                            {/* {Object.values(row).map((v, idx) => {
                        return <td key={idx}>{v}</td>;
                      })} */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                <h6
                  style={{
                    textAlign: "center",
                    marginTop: 15,
                  }}
                >
                  Expected GP by Age (no branch filter)
                </h6>
                {loadingProfitByAgeByBranchChartData ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  <ResponsiveContainer width={"100%"} height={300}>
                    <ComposedChart
                      width={500}
                      height={300}
                      data={profitByAgeByBranchChartData || []}
                      margin={{ top: 15, right: 30, left: 20, bottom: 10 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />

                      {Object.keys(profitByAgeByBranchChartData[0] || []).map(
                        (d, idx) => {
                          if (d !== "age") {
                            return (
                              <Line
                                type="monotone"
                                dataKey={d}
                                stroke={siteColours[d]}
                                dot={false}
                                strokeWidth={2}
                                connectNulls={true}
                                animationDuration={500}
                                yAxisId={0}
                                key={idx}
                                hide={d.replace("D1 ", "") !== branchFilter}
                                name={d.replace("D1 ", "")}
                              />
                            );
                          } else {
                            return null;
                          }
                        }
                      )}
                      <Line
                        type="monotone"
                        dataKey={`ALL`}
                        // stroke={siteColours["AMW - DANDENONG"]}
                        strokeWidth={4}
                        dot={false}
                        connectNulls={true}
                        animationDuration={500}
                        yAxisId={0}
                        hide={"All" !== branchFilter}
                        name="ALL"
                        // key={idx}
                      />

                      {/* <Bar
                      dataKey="numberOfCars"
                      barSize={20}
                      animationDuration={500}
                      fill="#413ea0"
                      yAxisId={1}
                    /> */}
                      <XAxis
                        dataKey="age"
                        type="number"
                        angle={-30}
                        // padding={{ top: 40 }}
                        // tickCount={
                        //   profitByAgeByBranchChartData[0]?.numberOfBuckets
                        // }
                        // interval={0}
                      >
                        <Label
                          value="Days In Stock"
                          offset={-4}
                          position="insideBottom"
                        />
                      </XAxis>
                      <YAxis
                        yAxisId={0}
                        label={{
                          value: "Expected GP $",
                          angle: -90,
                          position: "insideLeft",
                        }}
                      />
                      {/* <YAxis
                      yAxisId={1}
                      label={{
                        value: "Number of cars",
                        angle: 90,
                        position: "insideRight",
                      }}
                      orientation="right"
                    /> */}
                      <Legend
                        verticalAlign="top"
                        height={50}
                        content={({ payload }) => {
                          return payload.map((entry, index) => {
                            return (
                              <span
                                key={`item-${index}`}
                                style={{ marginRight: 10 }}
                              >
                                <span
                                  style={{
                                    backgroundColor: entry.color,
                                    width: 10,
                                    height: 10,
                                    display: "inline-block",
                                    marginRight: 5,
                                  }}
                                />
                                {entry.value}
                              </span>
                            );
                          });
                        }}
                      />

                      <Tooltip />
                      <ReferenceLine
                        y={0}
                        // label="Zero"
                        stroke="red"
                        strokeDasharray="3 3"
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                )}
              </Card.Body>
            </Card>
            {/* END LEAD SPREAD CARD */}
          </Col>
        </Row>
      </Container>

      <Card>
        <Row>
          <Col md={3} xs={12}>
            {/* <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-Search">
                  <BiSearch />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Stock # Search (All branches)"
                aria-label="Search"
                aria-describedby="basic-Search"
                value={stockSearch}
                onChange={(e) => filterByStockNumber(e.target.value)}
              />
              <InputGroup.Append>
                <Button onClick={() => clearStockSearch()}>
                  <BiX />
                </Button>
              </InputGroup.Append>
            </InputGroup> */}
            {isFilteredByLeadSpread ? (
              <Badge
                variant="warning"
                onClick={() => {
                  clearLeadSpreadFilter();
                }}
                style={{ cursor: "pointer", fontSize: 12 }}
              >
                <b>
                  <i>x&nbsp;</i>
                </b>
                Filtered by lead spread:&nbsp;
                {filteredListOfVehicles.length} vehicles filtered
              </Badge>
            ) : null}
          </Col>
          <Col>
            <span style={{ float: "right" }}>
              <Button
                variant="outline-primary"
                onClick={() => openLeadHealthInSalesforce()}
              >
                Lead health in Salesforce
              </Button>
            </span>
          </Col>
        </Row>
      </Card>

      {/* Two columns */}
      <Container fluid>
        <Row>
          {/* Left column */}
          {/* Table of in stock inventory */}
          <Col>
            {/* <pre>{JSON.stringify(data ? data[0] : {}, null, 2)}</pre> */}
            <Table hover style={{ fontSize: "12px" }}>
              <thead>
                <tr style={{ backgroundColor: "#5DADE2", color: "black" }}>
                  <th></th>
                  <th>#</th>
                  <th>Make</th>
                  <th>Year/Model/Variant</th>

                  {/* <th>Variant</th> */}
                  <th>Cost</th>
                  <th>Asking</th>
                  <th>Yard</th>
                  <th>Branch</th>
                  <th>Age</th>
                  <th>Online</th>

                  <th>L7</th>
                  <th>L14</th>
                  <th>LT</th>
                  {/* Only when Recon filter is applied, show logs column */}
                  {branchFilter === "BUYING/RECONDITIONING" ? (
                    <th>Photos</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {filteredListOfVehicles?.map((v, idx) => {
                  // console.log("purchase date", v.Purchase_Date);
                  // const pd = dayjs.unix(v.Purchase_Date.seconds || undefined);
                  // const today = dayjs();

                  const age = v.Age;
                  const leads = v.Leads;
                  const l7Leads = v.L7_Leads;
                  const l14Leads = v.L14_Leads;
                  const cost = Math.ceil(
                    v.costLogs?.reduce((a, b) => a + b.Cost_Inc_GST, 0)
                  );
                  // const asking = v["VEHAskingPrice"];
                  const asking = agData[v.VEHVIN]?.PriceList[0]?.Amount;

                  const isLoss = parseInt(asking) <= parseInt(cost);

                  let ageTL = "#82E0AA";
                  if (age > 30 && age <= 60) {
                    ageTL = "#C39BD3";
                  } else if (age > 60 && age <= 90) {
                    ageTL = "#F8C471";
                  } else if (age > 90) {
                    ageTL = "#F1948A";
                  }

                  let leadsTLColour = "";
                  let leadsTLFontWeight = "normal";
                  if (leads >= 9) {
                    leadsTLColour = "#cc3232";
                    leadsTLFontWeight = "bold";
                  }

                  let leads7Colour = "";
                  let leads7FontWeight = "normal";
                  if (l7Leads >= 3) {
                    leads7Colour = "#cc3232";
                    leads7FontWeight = "bold";
                  }

                  let leads14Colour = "";
                  let leads14FontWeight = "normal";
                  if (l14Leads >= 6) {
                    leads14Colour = "#cc3232";
                    leads14FontWeight = "bold";
                  }

                  let askingColour = "";
                  let askingFontWeight = "normal";
                  if (isLoss) {
                    askingColour = "#cc3232";
                    askingFontWeight = "bold";
                  }

                  let isSelected = false;
                  if (v.VEHStockNumber === selectedVehicle.VEHStockNumber) {
                    isSelected = true;
                  }

                  // Calculate online age from AG
                  let AGOnlinedaysFromToday = "N/O";
                  if (agData[v.VEHVIN]?.CreatedUtc) {
                    AGOnlinedaysFromToday = dayjs().diff(
                      agData[v.VEHVIN]?.CreatedUtc,
                      "day"
                    );
                  }

                  return (
                    <tr
                      style={{
                        backgroundColor: idx % 2 ? "#D4E6F1" : null,
                        border: isSelected ? "2px solid #ff6b00" : null,
                        fontWeight: isSelected ? "bold" : null,
                      }}
                      key={v.id}
                      onClick={() => handleShowActionCard(v)}
                    >
                      <td>
                        {/* <Badge
                          pill
                          bg="danger"
                          style={{ backgroundColor: "#6371de", color: "#FFF" }}
                        >
                          {}
                        </Badge> */}
                        {idx + 1}
                        &nbsp;
                        <BiIdCard
                          style={{ fontSize: "15px" }}
                          onClick={() => {
                            window.open(`/vin/${v.VEHVIN}`, "_blank");
                          }}
                        />
                      </td>
                      <td>{v.id}</td>
                      <td>{v.VEHMake}</td>
                      <td>
                        {v.VEHModelYear} {v.VEHmodel} {v["Variant"]}
                      </td>

                      {/* <td>{v["Variant & Series"]}</td> */}
                      <td>
                        <NumberFormat
                          displayType="text"
                          thousandSeparator
                          prefix="$"
                          value={`${Math.ceil(
                            v.costLogs?.reduce((a, b) => a + b.Cost_Inc_GST, 0)
                          )}`}
                        />
                      </td>
                      <td
                        style={{
                          color: askingColour,
                          fontWeight: askingFontWeight,
                        }}
                      >
                        <NumberFormat
                          prefix="$"
                          displayType="text"
                          thousandSeparator
                          value={asking}
                        />
                      </td>
                      <td>{v.VEHYard}</td>
                      <td>{v.Branch.replace("D1 ", "")}</td>
                      <td style={{ backgroundColor: ageTL }}>{age}</td>
                      <td>{AGOnlinedaysFromToday}</td>

                      <td
                        style={{
                          color: leads7Colour,
                          fontWeight: leads7FontWeight,
                        }}
                      >
                        {v.L7_Leads}
                      </td>
                      <td
                        style={{
                          color: leads14Colour,
                          fontWeight: leads14FontWeight,
                        }}
                      >
                        {v.L14_Leads}
                      </td>
                      <td
                        style={{
                          color: leadsTLColour,
                          fontWeight: leadsTLFontWeight,
                        }}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => showLeads(v?.VEHStockNumber)}
                        >
                          {v.Leads}
                        </span>
                      </td>
                      {/* Only when Recon filter is applied, show logs column */}
                      {branchFilter === "BUYING/RECONDITIONING" ? (
                        <td>
                          <YardLogBadges
                            yardLogs={v.yardLogs}
                            currentYard={v.VEHYard}
                            photosList={inStockPhotos}
                            stockNumber={v.VEHStockNumber}
                          />
                        </td>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>

          {/* Right column */}
          {/* Actions on inventory */}
          <Col>
            <Row
              style={{
                position: " -webkit-sticky",
                position: "sticky",
                top: 0,
              }}
            >
              <Card style={{ borderColor: "#138D75" }}>
                <Card.Header
                  style={{
                    backgroundColor: "#138D75",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Actions {selectedVehicle?.id}
                </Card.Header>
                <Card.Body>
                  <h4>Price changes</h4>
                  <LineChart
                    width={400}
                    height={200}
                    data={selectedVehicleLeadPriceLife}
                  >
                    <Line
                      type="monotone"
                      dataKey="price"
                      stroke="#82ca9d"
                      dot={false}
                      strokeWidth={3}
                      connectNulls={true}
                      animationDuration={500}
                    />
                    {/* <Line type="monotone" dataKey="leads" stroke="#8884d8" /> */}
                    {/* ref line max price */}
                    <ReferenceLine
                      strokeDasharray="5 5"
                      y={Math.max(
                        ...selectedVehicleLeadPriceLife.map((l) => l.price)
                      )}
                      stroke="red"
                      label={Math.max(
                        ...selectedVehicleLeadPriceLife.map((l) => l.price)
                      )}
                    />
                    {/* ref line min price detected */}
                    <ReferenceLine
                      y={Math.min(
                        ...selectedVehicleLeadPriceLife.map((l) => l.price)
                      )}
                      stroke="red"
                      label={Math.min(
                        ...selectedVehicleLeadPriceLife.map((l) => l.price)
                      )}
                    />

                    {/* ref line when a lead is detected */}
                    {selectedVehicleLeadPriceLife.map((l, idx) => {
                      if (l.leads === 1) {
                        return (
                          <ReferenceLine
                            id={l.id}
                            key={l.id}
                            x={idx}
                            stroke="blue"
                          />
                        );
                      } else {
                        return null;
                      }
                    })}

                    <XAxis dataKey="name" />
                    <YAxis domain={["dataMin-100", "dataMax+100"]} />
                    {/* <Legend /> */}
                    <Tooltip />
                  </LineChart>
                  <br />
                  <Table size="sm">
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Description</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr>
                        <td>Reprice</td>
                        <td>drop by $500</td>
                        <td>
                          <Button
                            variant="outline-primary"
                            onClick={() => {
                              createInventoryHealthAction(
                                selectedVehicle,
                                "Reprice down $500"
                              );
                            }}
                          >
                            <BiDollarCircle />
                          </Button>
                        </td>
                      </tr> */}
                      {/* <tr>
                        <td>Reprice</td>
                        <td>drop by $1,000</td>
                        <td>
                          <Button
                            variant="outline-primary"
                            onClick={() => {
                              createInventoryHealthAction(
                                selectedVehicle,
                                "Reprice down $1,000"
                              );
                            }}
                          >
                            <BiDollarCircle />
                          </Button>
                        </td>
                      </tr> */}
                      <tr>
                        <td>Asking:</td>
                        <td>
                          <NumberFormat
                            prefix="$"
                            displayType="text"
                            thousandSeparator
                            value={selectedVehicle["VEHAskingPrice"] || ""}
                          />
                        </td>
                      </tr>
                      {/* <tr>
                        <td>Reprice - reduce by:</td>
                        <td>
                          <NumberFormat
                            prefix="$"
                            displayType="input"
                            thousandSeparator
                            // value={customRepriceValue}
                            // onValueChange={({ value }) => {
                            //   setCustomRepriceValue(value);
                            // }}
                          />
                        </td>
                        <td>
                          <Button
                            variant="outline-primary"
                            onClick={() => {
                              // createInventoryHealthAction(
                              //   selectedVehicle,
                              //   `Reprice down $${customRepriceValue}`
                              // );
                            }}
                          >
                            <BiDollarCircle />
                          </Button>
                        </td>
                      </tr> */}
                      {/* <tr>
                        <td>New asking:</td>
                        <td>
                          <NumberFormat
                            prefix="$"
                            displayType="text"
                            thousandSeparator
                            value={
                              typeof selectedVehicle["VEHAskingPrice"] ===
                                "undefined" ||
                              selectedVehicle["VEHAskingPrice"] === null ||
                              selectedVehicle["VEHAskingPrice"] === ""
                                ? "Advertised price is missing"
                                : selectedVehicle["VEHAskingPrice"] -
                                  customRepriceValue
                            }
                          />
                        </td>
                      </tr> */}
                      <tr>
                        <td>Re-order photos</td>
                        <td>Swap primary photo</td>
                        <td>
                          {/* <Button variant="outline-primary">
                            <MdPhotoLibrary />
                          </Button> */}
                        </td>
                      </tr>

                      <tr>
                        <td>Exit</td>
                        <td>Trigger pressure release</td>
                        <td>
                          {/* <Button variant="outline-danger">
                            <MdNewReleases />
                          </Button> */}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={2}>
                          <Button
                            style={{ backgroundColor: "#138D75" }}
                            disabled={!selectedVehicle?.id?.length > 0}
                            // disabled={true}
                            onClick={() => {
                              setTransferBranch({});
                              setTransferAskingPrice("");
                              setShowActionsModal(true);
                              getPhotos();
                              getActionsHistoryOfSelectedVehicle();
                            }}
                          >
                            Additional Actions
                          </Button>
                        </td>

                        <td>
                          {/* <Button variant="outline-danger">

                            <MdNewReleases />
                          </Button> */}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <Button
                            style={{ backgroundColor: "#138D75" }}
                            disabled={!selectedVehicle?.id?.length > 0}
                            // disabled={true}
                            onClick={() => {
                              printLForm(selectedVehicle);
                            }}
                          >
                            {isLoadingPdf ? (
                              <Spinner size="sm" animation="border" />
                            ) : (
                              <>
                                <BiPrinter /> &nbsp; Print L-Form
                              </>
                            )}
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <Button
                            style={{ backgroundColor: "#138D75" }}
                            disabled={!selectedVehicle?.id?.length > 0}
                            // disabled={true}
                            onClick={() => {
                              setShowYardLocationModal(true);
                            }}
                          >
                            Change Yard Locations
                          </Button>
                        </td>
                      </tr>
                      <tr style={{ fontWeight: "bold" }}>
                        <td>Yard location</td>
                        <td>Changed by</td>
                        <td>Changed date</td>
                      </tr>
                      {selectedVehicle?.yardLogs?.map((yl, idxyl) => {
                        // REVERSE to be most recent change at the top
                        return (
                          <tr key={idxyl}>
                            <td>{yl.VEHYard}</td>
                            <td>{yl.desc}</td>
                            <td>
                              {dayjs
                                .unix(yl.Yard_Change_Date_EPOC)
                                .format("DD/MM/YYYY")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Row>
            {/* <Row>
              <Col>
                <h4>Actions List for {selectedVehicle?.id}</h4>
                <Table>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Action</th>
                      <th>Owner</th>
                      <th>Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoadingActions ? <Spinner animation="border" /> : null}
                    {selectedVehicleActions.map((a) => {
                      return (
                        <tr>
                          <td>{a.status}</td>
                          <td>{a.action}</td>
                          <td>{a.owner}</td>
                          <td>
                            {dayjs.unix(a.created).format("YYYY-MM-DD HH:mm")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row> */}
          </Col>
        </Row>
        {/* START ADDITIONAL ACTIONS MODAL  */}
        <Modal
          show={showActionsModal}
          onHide={() => setShowActionsModal(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Additional Actions: {selectedVehicle?.id}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              defaultActiveKey="event_sale"
              onSelect={(k) => setAdditionalActionsEventKey(k)}
              id="additional-actions-tab"
            >
              {/* START SALE */}
              <Tab eventKey="event_sale" title="Sell">
                {selectedVehicleActions?.map((sva, idx) => {
                  return (
                    <Row key={idx}>
                      <Col>
                        {sva?.actionTypeId === 10 ? (
                          <Alert variant="danger">
                            {sva?.actionType} already created:
                            <br />
                            by {sva?.createdByName}
                            <br />
                            {dayjs
                              .unix(sva?.createdDate)
                              .format("ddd DD MMM HH:mma")}
                            <br />
                            Selling to {
                              sva?.actionData?.transferBranch?.branch
                            }{" "}
                            @ ${sva?.actionData?.transferAskingPrice} EGC
                          </Alert>
                        ) : null}
                      </Col>
                    </Row>
                  );
                })}

                <Row className="mt-2">
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        View photos (
                        {selectedVehiclePhotos?.photos?.length || 0})
                      </Form.Label>
                      <Form.Row>
                        <Carousel fade>
                          {selectedVehiclePhotos?.photos?.map((p, idx) => {
                            return (
                              <Carousel.Item key={idx}>
                                <img
                                  className="d-block w-100"
                                  src={p}
                                  alt={`vehicle-${idx}`}
                                  style={{
                                    borderRadius: 10,
                                  }}
                                />
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </Form.Row>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    Send notification to <b>SELL</b> vehicle internally
                  </Col>
                  <Col>
                    {selectedVehicle?.VEHModelYear} {selectedVehicle?.VEHMake}{" "}
                    {selectedVehicle?.VEHmodel} {selectedVehicle["Variant"]}
                    <br />
                    <NumberFormat
                      value={selectedVehicle?.VEHOdo}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Odo: "}
                      suffix=" kms"
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>Current branch:</Col>
                  <Col>
                    <Button size="sm" variant="outline-warning">
                      {selectedVehicle?.Branch?.replace("D1 ", "")}
                    </Button>
                  </Col>
                </Row>
                <br />
                <Row className="mt-2">
                  <Col>Change to:</Col>

                  <Col>
                    <Dropdown
                      as={ButtonGroup}
                      onSelect={(v) => {
                        const tmpBranch = branchesList.find((b) => {
                          return b.branch_id === parseInt(v);
                        });
                        setTransferBranch(tmpBranch);
                      }}
                    >
                      <Button size="sm" variant="outline-success">
                        {transferBranch?.branch?.length > 0
                          ? transferBranch?.branch
                          : "Choose destination branch"}
                      </Button>

                      <Dropdown.Toggle
                        split
                        variant="success"
                        id="dropdown-split-basic"
                      />
                      <Dropdown.Menu>
                        {branchesList.map((s, idx) => {
                          if (s.branch !== selectedVehicle?.Branch) {
                            return (
                              <Dropdown.Item key={idx} eventKey={s.branch_id}>
                                {s.branch}
                              </Dropdown.Item>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>Sugggested site:</Col>
                  <Col>
                    {isLoadingSuggestedRetailPrice ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <span>
                        <i>
                          {sugarVehicleData?.suggestedRetailLocation?.toUpperCase() ||
                            "N/A"}
                        </i>
                      </span>
                    )}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    Cost Price (inc GST):
                    <br />
                    <span>
                      <sup>inc Buying Margin, Warranty, Load</sup>
                    </span>
                  </Col>
                  <Col>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={Math.ceil(
                        selectedVehicle?.costLogs?.reduce(
                          (a, b) => a + b.Cost_Inc_GST,
                          610 + 100 + 600 // 610 is the default buying margin, 100 is warranty provision, 600 is load
                        )
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>SRP:</Col>
                  <Col style={{ cursor: "pointer", marginBottom: 5 }}>
                    {isLoadingSuggestedRetailPrice ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <>
                        <NumberFormat
                          displayType="text"
                          thousandSeparator
                          prefix="$"
                          value={
                            Math.floor(
                              parseInt(sugarVehicleData?.suggestedRetailPrice)
                            ) || "NA"
                          }
                        />
                        &nbsp;
                        <Button
                          size="sm"
                          variant={
                            Math.floor(
                              parseInt(sugarVehicleData?.suggestedRetailPrice)
                            ) === transferAskingPrice
                              ? "success"
                              : "outline-success"
                          }
                          onClick={() => {
                            updateTransferAskingPrice(
                              Math.floor(
                                parseInt(sugarVehicleData?.suggestedRetailPrice)
                              )
                            );
                          }}
                        >
                          {Math.floor(
                            parseInt(sugarVehicleData?.suggestedRetailPrice)
                          ) === transferAskingPrice ? (
                            <CheckCircleFill size={15} />
                          ) : (
                            <CheckCircle size={15} />
                          )}
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>Asking price (EGC)</Col>
                  <Col>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        // disabled
                        aria-label="AskingPrice"
                        type="tel"
                        onChange={(e) => {
                          updateTransferAskingPrice(e.target.value);
                        }}
                        value={transferAskingPrice}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>Estimated Profit (inc Load):</Col>
                  <Col>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={
                        parseInt(transferAskingPrice) -
                        Math.ceil(
                          selectedVehicle?.costLogs?.reduce(
                            (a, b) => a + b.Cost_Inc_GST,
                            610 + 100 + 600 // 610 is the default buying margin, 100 is warranty provision, 600 is load
                          )
                        )
                      }
                    />
                    &nbsp;<span style={{ color: "blueviolet" }}>@</span>&nbsp;
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      suffix="%"
                      value={Math.round(
                        ((parseInt(transferAskingPrice) -
                          selectedVehicle?.costLogs?.reduce(
                            (a, b) => a + b.Cost_Inc_GST,
                            610 + 100 + 600 // 610 is the default buying margin, 100 is warranty provision, 600 is load
                          )) /
                          selectedVehicle?.costLogs?.reduce(
                            (a, b) => a + b.Cost_Inc_GST,
                            610 + 100 + 600 // 610 is the default buying margin, 100 is warranty provision, 600 is load
                          )) *
                          100
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>Estimated Profit (ex Load):</Col>
                  <Col>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={
                        parseInt(transferAskingPrice) -
                        Math.ceil(
                          selectedVehicle?.costLogs?.reduce(
                            (a, b) => a + b.Cost_Inc_GST,
                            610 + 100 // 610 is the default buying margin, 100 is warranty provision, 600 is load
                          )
                        )
                      }
                    />
                    &nbsp;<span style={{ color: "blueviolet" }}>@</span>&nbsp;
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      suffix="%"
                      value={Math.round(
                        ((parseInt(transferAskingPrice) -
                          selectedVehicle?.costLogs?.reduce(
                            (a, b) => a + b.Cost_Inc_GST,
                            610 + 100 // 610 is the default buying margin, 100 is warranty provision, 600 is load
                          )) /
                          selectedVehicle?.costLogs?.reduce(
                            (a, b) => a + b.Cost_Inc_GST,
                            610 + 100 // 610 is the default buying margin, 100 is warranty provision, 600 is load
                          )) *
                          100
                      )}
                    />
                  </Col>
                </Row>
                <Row style={{ float: "right", marginTop: 15 }}>
                  <Col>
                    <ButtonGroup>
                      <Button
                        variant="outline-primary"
                        onClick={() => sendBranchTransferRequest("sale")}
                      >
                        {isLoadingCallable ? (
                          <Spinner animation="border" />
                        ) : (
                          "Send internal SALE request"
                        )}
                      </Button>
                      <Button
                        variant="outline-danger"
                        onClick={() => setShowActionsModal(false)}
                      >
                        Close
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Tab>
              {/* END SALE */}
              {/* START TRANSFER */}
              <Tab eventKey="event_transfer" title="Transfer">
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        View photos (
                        {selectedVehiclePhotos?.photos?.length || 0})
                      </Form.Label>
                      <Form.Row>
                        <Carousel fade>
                          {selectedVehiclePhotos?.photos?.map((p, idx) => {
                            return (
                              <Carousel.Item key={idx}>
                                <img
                                  className="d-block w-100"
                                  src={p}
                                  alt={`vehicle-${idx}`}
                                  style={{
                                    borderRadius: 10,
                                  }}
                                />
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </Form.Row>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Send notification to <b>TRANSFER</b> vehicle internally
                  </Col>
                  <Col>
                    {selectedVehicle?.VEHModelYear} {selectedVehicle?.VEHMake}{" "}
                    {selectedVehicle?.VEHmodel} {selectedVehicle["Variant"]}
                    <br />
                    <NumberFormat
                      value={selectedVehicle?.VEHOdo}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Odo: "}
                      suffix=" kms"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>Current branch:</Col>
                  <Col>
                    <Button size="sm" variant="outline-warning">
                      {selectedVehicle?.Branch?.replace("D1 ", "")}
                    </Button>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>Change to:</Col>

                  <Col>
                    <Dropdown
                      as={ButtonGroup}
                      onSelect={(v) => {
                        const tmpBranch = branchesList.find((b) => {
                          return b.branch_id === parseInt(v);
                        });
                        setTransferBranch(tmpBranch);
                      }}
                    >
                      <Button size="sm" variant="outline-success">
                        {transferBranch?.branch?.length > 0
                          ? transferBranch?.branch
                          : "Choose destination branch"}
                      </Button>

                      <Dropdown.Toggle
                        split
                        variant="success"
                        id="dropdown-split-basic"
                      />
                      <Dropdown.Menu>
                        {branchesList.map((s, idx) => {
                          if (
                            s.branch !== selectedVehicle?.Branch &&
                            ![
                              "AMW - BUYING SERVICE",
                              "AMW - BUYING/RECONDITIONING",
                            ].includes(s.branch)
                          ) {
                            return (
                              <Dropdown.Item key={idx} eventKey={s.branch_id}>
                                {s.branch}
                              </Dropdown.Item>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
                <Row style={{ float: "right", marginTop: 15 }}>
                  <Col>
                    <ButtonGroup>
                      <Button
                        variant="outline-primary"
                        onClick={() => sendBranchTransferRequest("transfer")}
                      >
                        {isLoadingCallable ? (
                          <Spinner animation="border" />
                        ) : (
                          "Send internal TRANSFER request"
                        )}
                      </Button>
                      <Button
                        variant="outline-danger"
                        onClick={() => setShowActionsModal(false)}
                      >
                        Close
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Tab>
              {/* END TRANSFER */}
              {/* START PRICE CHANGE */}
              <Tab eventKey="event_price_change" title="Price change">
                <Row style={{ marginTop: 10 }}>
                  <Col>
                    {selectedVehicleActions?.map((sva, idx) => {
                      return (
                        <Row key={idx}>
                          <Col>
                            {sva?.actionTypeId === 30 ? (
                              <Alert
                                style={{ fontSize: 11 }}
                                variant={
                                  sva?.actionData?.priceChangeData
                                    ?.originalAdvertisedPrice >
                                  sva?.actionData?.priceChangeData
                                    ?.advertisedPrice
                                    ? "danger"
                                    : "success"
                                }
                              >
                                {sva?.actionType} by {sva?.createdByName}{" "}
                                {dayjs.unix(sva?.createdDate).fromNow()}
                                <br />
                                From:{" "}
                                <NumberFormat
                                  value={
                                    sva?.actionData?.priceChangeData
                                      ?.originalAdvertisedPrice
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />{" "}
                                To:{" "}
                                <NumberFormat
                                  value={
                                    sva?.actionData?.priceChangeData
                                      ?.advertisedPrice
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </Alert>
                            ) : null}
                          </Col>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        View photos (
                        {selectedVehiclePhotos?.photos?.length || 0})
                      </Form.Label>
                      <Form.Row>
                        <Carousel fade>
                          {selectedVehiclePhotos?.photos?.map((p, idx) => {
                            return (
                              <Carousel.Item key={idx}>
                                <img
                                  className="d-block w-100"
                                  src={p}
                                  alt={`vehicle-${idx}`}
                                  style={{
                                    borderRadius: 10,
                                  }}
                                />
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </Form.Row>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <i>Change advertised price of</i>
                  </Col>
                  <Col>
                    {selectedVehicle?.VEHModelYear} {selectedVehicle?.VEHMake}{" "}
                    {selectedVehicle?.VEHmodel} {selectedVehicle["Variant"]}
                    <br />
                    <NumberFormat
                      value={selectedVehicle?.VEHOdo}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Odo: "}
                      suffix=" kms"
                    />
                    <br />
                  </Col>
                </Row>
                <Row>
                  <Col>Current branch:</Col>
                  <Col>{selectedVehicle?.Branch}</Col>
                </Row>
                <Row>
                  <Col>Cost Price (inc GST):</Col>
                  <Col>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={Math.ceil(
                        selectedVehicle?.costLogs?.reduce(
                          (a, b) => a + b.Cost_Inc_GST,
                          0
                        )
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>SRP:</Col>
                  <Col>
                    {isLoadingSuggestedRetailPrice ? (
                      <Spinner animation="border" size="sm" />
                    ) : null}
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={
                        Math.floor(
                          parseInt(sugarVehicleData?.suggestedRetailPrice)
                        ) || "NA"
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>Current adv. price (EGC):</Col>
                  <Col>
                    {selectedVehicle["VEHAskingPrice"] === null ? (
                      <span>No asking price set</span>
                    ) : (
                      <NumberFormat
                        displayType="text"
                        thousandSeparator
                        prefix="$ "
                        value={
                          parseInt(selectedVehicle["VEHAskingPrice"]) || "NA"
                        }
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>Reprice * set the new price</Form.Label>
                  </Col>
                  <Col>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        // disabled
                        aria-label="AskingPrice"
                        type="tel"
                        onChange={(e) => {
                          setCustomRepriceValue(parseInt(e.target.value));
                        }}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Repricing{" "}
                    {parseInt(selectedVehicle["VEHAskingPrice"]) <
                    parseInt(customRepriceValue)
                      ? "increase"
                      : "decrease"}{" "}
                    by:
                  </Col>
                  <Col
                    style={{
                      color:
                        parseInt(selectedVehicle["VEHAskingPrice"]) <
                        parseInt(customRepriceValue)
                          ? "green"
                          : "red",
                    }}
                  >
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={Math.abs(
                        parseInt(customRepriceValue) -
                          parseInt(selectedVehicle["VEHAskingPrice"])
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>Estimated profit:</Col>
                  <Col
                    style={{
                      color:
                        parseInt(customRepriceValue) -
                          Math.ceil(
                            selectedVehicle?.costLogs?.reduce(
                              (a, b) => a + b.Cost_Inc_GST,
                              0
                            )
                          ) >
                        0
                          ? "green"
                          : "red",
                    }}
                  >
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={
                        parseInt(customRepriceValue) -
                        Math.ceil(
                          selectedVehicle?.costLogs?.reduce(
                            (a, b) => a + b.Cost_Inc_GST,
                            0
                          )
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>Estimated Profit:</Col>
                  <Col
                    style={{
                      color:
                        parseInt(customRepriceValue) -
                          Math.ceil(
                            selectedVehicle?.costLogs?.reduce(
                              (a, b) => a + b.Cost_Inc_GST,
                              0
                            )
                          ) >
                        0
                          ? "green"
                          : "red",
                    }}
                  >
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      suffix="%"
                      value={Math.round(
                        ((parseInt(customRepriceValue) -
                          selectedVehicle?.costLogs?.reduce(
                            (a, b) => a + b.Cost_Inc_GST,
                            0 // 610 is the default buying margin
                          )) /
                          selectedVehicle?.costLogs?.reduce(
                            (a, b) => a + b.Cost_Inc_GST,
                            0 // 610 is the default buying margin
                          )) *
                          100
                      )}
                    />
                  </Col>
                </Row>
                <br />
                <Row style={{ float: "right", marginTop: 15 }}>
                  <Col>
                    <ButtonGroup>
                      <Button
                        variant="outline-primary"
                        onClick={() => pushAdvetisedPriceChangeToEclipse()}
                      >
                        {isRepricing ? (
                          <Spinner animation="border" />
                        ) : (
                          "Reprice in Eclipse"
                        )}
                      </Button>
                      <Button
                        variant="outline-danger"
                        onClick={() => setShowActionsModal(false)}
                      >
                        Close
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Tab>
              {/* END PRICE CHANGE */}
            </Tabs>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        {/* END  ADDITIONAL ACTIONS MODAL  */}
        {/* START YARD LOCATION MODAL  */}
        <Modal
          show={showYardLocationModal}
          onHide={() => setShowYardLocationModal(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update yard location in Eclipse</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="info">
              <i>Please review before proceeding</i>
            </Alert>
            <Table size="sm">
              <tbody>
                <tr>
                  <td>Current yard location</td>
                  <td>{selectedVehicle?.VEHYard}</td>
                </tr>
                <tr>
                  <td>New yard location</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-yard-location"
                      >
                        {yardLocation.length === 0
                          ? "Choose yard location"
                          : yardLocation}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {activeYards?.map((yard, idx) => {
                          return (
                            <Dropdown.Item
                              key={idx}
                              onClick={() => {
                                setYardLocation(yard.id);
                              }}
                            >
                              {yard.id} : ({yard.title})
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>

                <tr>
                  <td>Branch</td>
                  <td>{selectedVehicle?.Branch}</td>
                </tr>
                <tr>
                  <td>Database</td>
                  <td>{selectedVehicle?.db}</td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup style={{ float: "right" }}>
              <Button
                disabled={isLoadingYardLocationUpdate}
                onClick={() => pushYardLocationToEclipse()}
              >
                Push to Eclipse&nbsp;
                {isLoadingYardLocationUpdate ? (
                  <Spinner size="sm" animation="border" />
                ) : null}
              </Button>
              <Button
                variant="outline-danger"
                onClick={() => {
                  setShowYardLocationModal(false);
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
        {/* END YARD LOCATION  MODAL  */}

        {/* START LEADS LIST MODAL  */}
        <Modal
          show={showLeadsModal}
          onHide={() => clearLeads()}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Leads from Salesforce</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoadingLeads ? (
              <Spinner animation="border" />
            ) : (
              <Alert variant="info">
                <i>{leadsFromSalesforce.rows?.length} leads found</i>
              </Alert>
            )}
            <Table striped size="sm" style={{ fontSize: 14 }}>
              <thead>
                <tr>
                  <th>Days since lead</th>
                  <th style={{ width: 150 }}>Created Date</th>
                  <th style={{ width: 150 }}>Name</th>
                  {/* <th>Phone</th> */}
                  {/* <th>Email</th> */}
                  <th>Lead Owner</th>
                  <th>Status</th>
                  {/* <th>Lead Source</th> */}
                  <th style={{ width: 400 }}>Latest Note</th>

                  {/* <th>Record Type Id</th> */}
                </tr>
              </thead>
              <tbody>
                {leadsFromSalesforce &&
                  leadsFromSalesforce?.rows?.map((lead, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{dayjs().diff(dayjs(lead.CreatedDate), "days")}</td>
                        <td>
                          {dayjs(lead.CreatedDate).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                        <td>
                          <a
                            target="_blank"
                            href={`https://duttongarages.lightning.force.com/lightning/r/Lead/${lead?.Id}/view`}
                          >
                            {lead.Name}
                          </a>
                        </td>
                        {/* <td>{lead.Phone}</td> */}
                        {/* <td>{lead.Email}</td> */}
                        <td>{lead.LeadOwner}</td>
                        <td>
                          {lead.Status}
                          {lead.Status === "Disqualified" ? (
                            <p>
                              <b>
                                <i>Disqualified Reason: {lead.Lost_Reason}</i>
                              </b>
                            </p>
                          ) : null}
                        </td>
                        {/* <td>{lead.LeadSource}</td> */}
                        <td>
                          {lead.LatestNote?.replace(/(<([^>]+)>)/gi, "") || ""}
                        </td>
                        {/* <td>{lead.RecordTypeId}</td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup style={{ float: "right" }}>
              <Button
                variant="outline-danger"
                onClick={() => {
                  clearLeads();
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
        {/* END LEADS LIST  MODAL  */}

        {/* START PDF VIEWER MODAL  */}
        <Modal
          show={showPdfModal}
          onHide={() => setShowPdfModal(false)}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>PDF Viewer TEST ONLY</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              // style={{ width: '563px', height: '666px' }}
              style={{ width: "100%", height: "1000px" }}
              src={`data:application/pdf;base64,${pdfBase64}`}
              type="application/pdf"
              title="title of pdf"
            />
            {/* {pdfBase64} */}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        {/* END PDF VIEWER  MODAL  */}
      </Container>
    </>
  );
};

export default InventoryHealth;
