import Board from "react-trello";
import React, { useState, useEffect, useRef } from "react";

import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Legend,
  CartesianGrid,
  Label,
  Area,
  ComposedChart,
  Bar,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Cell,
  ZAxis,
} from "recharts";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const IMAGE_HEIGHT = 250;

const carsalesStockLocatorUrlPrefix =
  "https://stocklocator.carsalesnetwork.com.au/details/";

const NewCardForm = () => {
  return (
    <>
      <Card style={{ paddingTop: 15 }}>
        <Card.Title>Add</Card.Title>
        <Card.Body>
          <Form>
            <Form.Group controlId="Year">
              <Form.Control
                as="input"
                type="tel"
                placeholder="Year"
                name="Year"
              />
            </Form.Group>
            <Form.Group controlId="Make">
              <Form.Control as="input" placeholder="Make" name="Make" />
            </Form.Group>
            <Form.Group controlId="Model">
              <Form.Control as="input" placeholder="Model" name="Model" />
            </Form.Group>
            <Form.Group controlId="Target Purchase Price">
              <Form.Control
                as="input"
                type="tel"
                placeholder="Target Purchase Price"
                name="TPP"
              />
            </Form.Group>
            <Form.Group controlId="Buy price">
              <Form.Control
                as="input"
                type="tel"
                placeholder="Buy price"
                name="BP"
              />
            </Form.Group>
            <Form.Group controlId="Link">
              <Form.Control as="input" placeholder="Link" name="Link" />
            </Form.Group>
            <ButtonGroup>
              <Button variant="primary" type="submit">
                Add Vehicle
              </Button>
              <Button variant="secondary" type="reset">
                Reset
              </Button>
            </ButtonGroup>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

const userLanes = [
  {
    id: "JknoVlb6Uic1SSjouHBqp2mjNH92",
    title: "NSW Buying Team",
    label: "1/5",
    cards: [
      // {
      //   id: "685461984",
      //   title: "2019 Audi A5",
      //   description: "Target Price: $30,000\nBuy Price: $30,500",
      //   label: `Open Link >`,
      //   cardStyle: {
      //     borderRadius: 6,
      //     boxShadow: "0 0 6px 1px #E08521",
      //     marginBottom: 15,
      //   },
      //   cardColor: "#BD3B36",
      //   draggable: true,
      //   tags: [
      //     {
      //       bgcolor: "green",
      //       color: "white",
      //       title: "Negotiating",
      //     },
      //   ],
      // },
    ],

    category: "0",
    active: 1,
  },
  {
    id: "JknoVlb6Uic1SSjouHBqp2mjNH92s",
    title: "VIC Buying Team",
    label: "1/5",
    cards: [
      // {
      //   id: "685461984",
      //   title: "2019 Audi A5",
      //   description: "Target Price: $30,000\nBuy Price: $30,500",
      //   label: ``,
      //   cardStyle: {
      //     borderRadius: 6,
      //     boxShadow: "0 0 6px 1px #E08521",
      //     marginBottom: 15,
      //   },
      //   cardColor: "#BD3B36",
      //   draggable: true,
      //   tags: [
      //     {
      //       bgcolor: "green",
      //       color: "white",
      //       title: "Negotiating",
      //     },
      //   ],
      // },
    ],

    category: "0",
    active: 1,
  },
  {
    id: "JknoVlb6Uic1SSjouHBqp2mjNH92sd",
    title: "QLD Buying Team",
    label: "1/5",
    cards: [
      // {
      //   id: "685461984",
      //   title: "2019 Audi A5",
      //   description: "Target Price: $30,000\nBuy Price: $30,500",
      //   label: ``,
      //   cardStyle: {
      //     borderRadius: 6,
      //     boxShadow: "0 0 6px 1px #E08521",
      //     marginBottom: 15,
      //   },
      //   cardColor: "#BD3B36",
      //   draggable: true,
      //   tags: [
      //     {
      //       bgcolor: "green",
      //       color: "white",
      //       title: "Negotiating",
      //     },
      //   ],
      // },
    ],

    category: "0",
    active: 1,
  },
  {
    id: "999",
    title: "Pool",
    label: "0/0",
    cards: [],

    category: "999",
    active: 1,
  },
];

let eventBus = undefined;

// const setEventBus = (handle) => {
//   eventBus = handle;
// };
// //To add a card
// eventBus.publish({
//   type: "ADD_CARD",
//   laneId: "COMPLETED",
//   card: {
//     id: "M1",
//     title: "Buy Milk",
//     label: "15 mins",
//     description: "Also set reminder",
//   },
// });

// //To update a card
// eventBus.publish({
//   type: "UPDATE_CARD",
//   laneId: "COMPLETED",
//   card: {
//     id: "M1",
//     title: "Buy Milk (Updated)",
//     label: "20 mins",
//     description: "Also set reminder (Updated)",
//   },
// });

// //To remove a card
// eventBus.publish({ type: "REMOVE_CARD", laneId: "PLANNED", cardId: "M1" });

// //To move a card from one lane to another. index specifies the position to move the card to in the target lane
// eventBus.publish({
//   type: "MOVE_CARD",
//   fromLaneId: "PLANNED",
//   toLaneId: "WIP",
//   cardId: "Plan3",
//   index: 0,
// });

// //To update the lanes
// eventBus.publish({ type: "UPDATE_LANES", lanes: newLaneData });

const components = {
  AddCardLink: () => <button>New Card</button>,
  //   LaneHeader: CustomLaneHeader,
  //
  //   NewLaneSection: NewLane,
  //   Card: CustomCard,
};

const DGBuyingBoard = () => {
  const [reconTrello, setReconTrello] = useState(userLanes);
  const [loading, setLoading] = useState(true);
  const [isLoadingRecentlySoldCars, setIsLoadingRecentlySoldCars] =
    useState(false);
  const [isLoadingRecentlySoldCarsCS, setIsLoadingRecentlySoldCarsCS] =
    useState(false);
  const [isLoadingInStockCars, setIsLoadingInStockCars] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [activeCards, setActiveCards] = useState([]);
  const [recentlySoldCarsForActiveCard, setRecentlySoldCarsForActiveCard] =
    useState([]);

  const [recentlySoldCarsCSForActiveCard, setRecentlySoldCarsCSForActiveCard] =
    useState([]);

  const [pricingGraphDataDutton, setPricingGraphDataDutton] = useState([]);
  const [pricingGraphDataAG, setPricingGraphDataAG] = useState([]);
  const [pricingGraphData, setPricingGraphData] = useState([]);

  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(1);

  const [carsInStock, setCarsInStock] = useState([]);

  const [show, setShow] = useState(true);
  const [limitToLoad, setLimitToLoad] = useState(5);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const handleIncrementCurrentIndex = () => {
    if (currentIndex === data.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
    console.log("currentIndex", currentIndex);
  };

  const handleDecrementCurrentIndex = () => {
    if (currentIndex === 0) {
      setCurrentIndex(data.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const getRecentlySoldCars = async (make, model, year) => {
    if (!make || !model || !year) return;
    setIsLoadingRecentlySoldCars(true);
    setRecentlySoldCarsForActiveCard([]);
    const collectionRef = firestore.collection("salesSummary");
    const yearMinusOne = parseInt(year) - 1;
    const yearPlusOne = parseInt(year) + 1;
    const soldDateMinus180days = dayjs().subtract(180, "days").format("X");
    console.log("soldDateMinus180days", soldDateMinus180days);
    let tmpRecentlySoldCars = await collectionRef
      .where("VEHMake", "==", make)
      .where("VEHModel", "==", model)
      // .where("VEHModelYear", ">=", yearMinusOne)
      // .where("VEHModelYear", "<=", yearPlusOne)
      .where("Sale Date EPOC", ">=", parseInt(soldDateMinus180days))
      .where("sale_class", "==", "R")
      // .limit(10)
      .get()
      .then((querySnapshot) => {
        const documents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(documents);
        return documents;
      });

    // Sort by days on market
    tmpRecentlySoldCars = tmpRecentlySoldCars.sort((a, b) => {
      return b.daysOnMarket < a.daysOnMarket;
    });
    console.log("tmpRecentlySoldCars", tmpRecentlySoldCars);
    // Filter for year >= year - 1 && year <= year + 1
    tmpRecentlySoldCars = tmpRecentlySoldCars.filter((car) => {
      return (
        car.VEHModelYear >= yearMinusOne && car.VEHModelYear <= yearPlusOne
      );
    });
    console.log("tmpRecentlySoldCars", tmpRecentlySoldCars);

    setRecentlySoldCarsForActiveCard(tmpRecentlySoldCars);
    setIsLoadingRecentlySoldCars(false);
  };

  const getRecentlySoldCarsCS = async (make, model, year, description) => {
    if (!make || !model || !year) return;
    setIsLoadingRecentlySoldCarsCS(true);
    setRecentlySoldCarsCSForActiveCard([]);
    const collectionRef = firestore.collection("competitive-set-ag");
    const yearMinusOne = parseInt(year) - 1;
    const yearPlusOne = parseInt(year) + 1;
    let tmpRecentlySoldCars = await collectionRef
      .where("specData.make", "==", make)
      .where("specData.model", "==", model)
      .where("specData.year", ">=", yearMinusOne)
      .where("specData.year", "<=", yearPlusOne)
      .where("specData.description", "==", description)
      .where("sellerType", "in", ["I", "F"])
      // .limit(100)
      .get()
      .then((querySnapshot) => {
        const documents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("CS", documents);
        return documents;
      });

    // Sort by days on market
    tmpRecentlySoldCars = tmpRecentlySoldCars.sort((a, b) => {
      return a.daysOnMarket - b.daysOnMarket;
    });
    setRecentlySoldCarsCSForActiveCard(tmpRecentlySoldCars);
    setIsLoadingRecentlySoldCarsCS(false);
  };

  const getCarsInStock = async (make, model, year) => {
    if (!make || !model || !year) return;
    setIsLoadingInStockCars(true);
    const collectionRef = firestore.collection("inStockInventory");
    const yearMinusOne = parseInt(year) - 1;
    const yearPlusOne = parseInt(year) + 1;
    const tmpRecentlySoldCars = await collectionRef
      .where("VEHMake", "==", make)
      .where("VEHModel", "==", model)
      .where("VEHModelYear", ">=", yearMinusOne)
      .where("VEHModelYear", "<=", yearPlusOne)
      // .limit(10)
      .get()
      .then((querySnapshot) => {
        const documents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(documents);
        return documents;
      });
    setCarsInStock(tmpRecentlySoldCars);
    setIsLoadingInStockCars(false);
  };

  const calculatePricingGraphData = () => {
    if (!activeCard) return;
    // [
    //   {
    //     name: "priceChange",
    //     price: activeCard?.advertisedPrice,
    //     duttonSoldPriceInc: 46880,
    //     date: "Jan",
    //   },
    //   {
    //     name: "priceChange",
    //     price: activeCard?.advertisedPrice * 0.98,
    //     duttonSoldPriceInc: 42200,
    //     date: "Feb",
    //   },
    //   {
    //     name: "priceChange",
    //     price: activeCard?.advertisedPrice * 0.88,
    //     date: "Feb",
    //   },
    //   {
    //     name: "priceChange",
    //     price: activeCard?.advertisedPrice * 0.76,
    //     date: "Feb",
    //   },
    //   {
    //     name: "priceChange",
    //     price: activeCard?.advertisedPrice * 0.71,
    //     date: "Feb",
    //   },
    //   {
    //     name: "priceChange",
    //     price: activeCard?.advertisedPrice * 0.88,
    //     date: "Feb",
    //   },
    //   {
    //     name: "priceChange",
    //     price: activeCard?.advertisedPrice * 0.75,
    //     date: "Feb",
    //   },
    // ]

    const tmpPricingGraphDataDutton = [];
    const tmpPricingGraphData = [];
    if (recentlySoldCarsForActiveCard.length > 0) {
      recentlySoldCarsForActiveCard
        .sort((a, b) => {
          return a.Age - b.Age;
        })
        // .reverse()
        .forEach((car) => {
          const duttonSoldPriceInc = car["SalePriceInc"];
          const date = car["Age"];
          tmpPricingGraphDataDutton.push({
            name: "Dutton Sold Price Inc",
            soldPriceInc: duttonSoldPriceInc,
            colour: "#82ca9d",
            date: date,
            dateSold: dayjs.unix(car["Sale Date EPOC"]).format("DD/MM/YYYY"),
          });
        });
    }
    const tmpPricingGraphDataAG = [];
    if (recentlySoldCarsCSForActiveCard.length > 0) {
      recentlySoldCarsCSForActiveCard
        .sort((a, b) => {
          return a.daysOnMarket - b.daysOnMarket;
        })
        .reverse()
        .filter((car) => car.dateDelisted !== null)
        .forEach((car) => {
          const csSoldPrice = car.advertisedPrice;
          const date = car.daysOnMarket;
          tmpPricingGraphDataAG.push({
            name: "AG Adv Price Inc",
            colour: "#8884d8",
            soldPriceInc: csSoldPrice,
            date: date,
          });
        });
    }

    setPricingGraphDataDutton(tmpPricingGraphDataDutton);
    setPricingGraphDataAG(tmpPricingGraphDataAG);
    // setPricingGraphData(tmpPricingGraphData);
  };

  useEffect(() => {
    const collectionRef = firestore.collection("competitive-set-ag");
    const daysToLoad = dayjs().subtract(20, "days").format("X");
    console.log("daysToLoad", daysToLoad);
    setLoading(true);
    const unsubscribe = collectionRef
      .where("sellerType", "==", "P")
      .where("daysOnMarket", "<", 2)
      // .where("timestamp", ">=", daysToLoad)
      .limit(limitToLoad)
      // .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        const documents = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // console.log(documents);
        setData(documents);
        let poolLaneCards = documents.map((doc) => {
          let sellerContactDetails = "N/A";
          const status = Math.random() >= 0.5 ? "Discussion" : "Not started";
          const viewsTag = doc.views > 3000 ? "green" : "red";
          const advertisedPrice = (
            <NumberFormat
              value={doc.advertisedPrice}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          );
          let mobile = "N/A";
          if (doc.sellerContactDetails === null) {
            return false;
          } else {
            if (doc.sellerContactDetails) {
              mobile = doc.sellerContactDetails?.contactMethods[0]
                ? doc.sellerContactDetails.contactMethods[0]?.value
                : "N/A";
              sellerContactDetails = `${doc.sellerContactDetails?.name}`;
            }
            return {
              id: doc.sourceId,
              title: `${doc.specData?.year} ${doc.specData?.make} ${doc.specData?.model}  ${doc.specData?.badge} - ${doc.daysOnMarket} days on market`,
              description: `${sellerContactDetails} - ${mobile}: $${doc.advertisedPrice}
              `,
              label: "Open link >",
              metadata: {
                id: doc.sourceId,
                state: doc.state,
              },
              cardStyle: {
                borderRadius: 6,
                boxShadow: "0 0 6px 1px #E08521",
                marginBottom: 15,
              },
              cardColor: "#BD3B36",
              draggable: true,
              tags: [
                {
                  bgcolor: status === "Discussion" ? "orange" : "green",
                  color: "white",
                  title: status,
                },
                {
                  bgcolor: viewsTag,
                  color: "white",
                  title: doc.views + " views",
                },
                {
                  bgcolor: "black",
                  color: "white",
                  title: doc.watches + " watches",
                },
                {
                  bgcolor: "blue",
                  color: "white",
                  title: doc.leads + " leads",
                },
                { bgcolor: "green", color: "white", title: doc.state },
              ],
            };
          }
        });
        poolLaneCards = poolLaneCards.filter((card) => card !== false);
        poolLaneCards = poolLaneCards.sort((a, b) => {
          return b.daysOnMarket - a.daysOnMarket;
        });
        poolLaneCards.forEach((card) => {
          const state = card.metadata.state;
          if (state === "NSW") {
            userLanes[0].cards.push(card);
          } else if (state === "VIC") {
            userLanes[1].cards.push(card);
          } else if (state === "QLD") {
            userLanes[2].cards.push(card);
          } else {
            userLanes[3].cards.push(card);
          }
        });

        setReconTrello(userLanes);
        setLoading(false);
      });
    return () => unsubscribe();
  }, [limitToLoad]);

  useEffect(() => {
    console.log("data", data);
    setActiveCard(data[currentIndex]);
    getRecentlySoldCars(
      data[currentIndex]?.specData?.make,
      data[currentIndex]?.specData?.model,
      data[currentIndex]?.specData?.year
    );
    getRecentlySoldCarsCS(
      data[currentIndex]?.specData?.make,
      data[currentIndex]?.specData?.model,
      data[currentIndex]?.specData?.year,
      data[currentIndex]?.specData?.description
    );
    getCarsInStock(
      data[currentIndex]?.specData?.make,
      data[currentIndex]?.specData?.model,
      data[currentIndex]?.specData?.year
    );
  }, [data, currentIndex]);

  useEffect(() => {
    calculatePricingGraphData();
  }, [
    activeCard,
    currentIndex,
    recentlySoldCarsCSForActiveCard,
    recentlySoldCarsForActiveCard,
    carsInStock,
  ]);

  return (
    <>
      <ToastContainer />
      {/* {loading && <Spinner animation="border" variant="primary" />} */}
      <ButtonGroup>
        <Button
          variant={limitToLoad === 20 ? "primary" : "outline-primary"}
          onClick={handleDecrementCurrentIndex}
        >
          Previous
        </Button>
        <Button
          variant={limitToLoad === 50 ? "primary" : "outline-primary"}
          onClick={handleIncrementCurrentIndex}
        >
          Next
        </Button>
      </ButtonGroup>

      {/* <Board
        style={{ backgroundColor: "#E9F7EF", scrollBehavior: "smooth" }}
        // onCardClick={(cardId, metadata, laneId) => {
        //   // console.log(metadata);
        //   window.open(`/vin/${metadata?.veh?.VEHVIN}`, "_blank");
        //   // history.push(`/vin/${metadata?.veh?.VEHVIN}`);
        // }}
        data={{ lanes: userLanes }}
        components={{
          //   AddCardLink: () => <button>New Card</button>,
          NewCardForm: NewCardForm,
        }}
        editable
        onCardClick={(cardId, metadata, laneId) => {
          console.log(cardId);
          // window.open(
          //   `${carsalesStockLocatorUrlPrefix}${metadata?.id}`,
          //   "_blank"
          // );
          // history.push(`/vin/${metadata?.veh?.VEHVIN}`);

          const card = data.find((doc) => doc.sourceId === cardId);
          console.log(card);
          setActiveCard(card);
          handleShow();
        }}
        onCardAdd={(a, b) => {
          console.log(a);
          console.log(b);
        }}
        // eventBusHandle={setEventBus}
      /> */}
      <Card>
        <Card.Body>
          <Row>
            <Col xs={3}>
              <Card style={{ backgroundColor: "#172b4d", color: "white" }}>
                <Card.Title style={{ marginTop: 20 }}>
                  {loading && <Spinner animation="border" variant="primary" />}

                  <h5>{`${activeCard?.specData?.year} ${activeCard?.specData?.make} ${activeCard?.specData?.model} ${activeCard?.specData?.description}`}</h5>
                  <h6>
                    {activeCard?.colour}&nbsp; | Adv:{" "}
                    <b>
                      <NumberFormat
                        value={activeCard?.advertisedPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </b>
                    | Odo: {activeCard?.odometer}| Age:{" "}
                    {activeCard?.daysOnMarket}| Views: {activeCard?.views} |
                    Leads: {activeCard?.leads} | Watched: {activeCard?.watches}{" "}
                    |{" "}
                    {activeCard?.tags?.map((tag) => {
                      const tagColour =
                        tag === "TOPSPOT" ? "success" : "warning";
                      return (
                        <Badge
                          style={{ marginRight: 5 }}
                          pill
                          variant={tagColour}
                          // text="light"
                        >
                          {tag}
                        </Badge>
                      );
                    })}
                    {/* <span style={{ float: "right", fontSize: 12 }}> */}
                    <a
                      href={`${carsalesStockLocatorUrlPrefix}${activeCard?.sourceId}`}
                      target="_blank"
                      style={{ fontSize: 12, color: "#3498DB" }}
                    >
                      Open in Carsales
                    </a>
                    {/* </span> */}
                  </h6>
                </Card.Title>
                <Card.Body>
                  <Row>
                    <Col xs={6}>
                      <Carousel
                        infinite={true}
                        showDots={true}
                        responsive={responsive}
                        slidesToSlide={1}
                        style={{ backgroundColor: "black" }}
                        itemClass="carousel-item-padding-40-px"
                        containerClass="carousel-container"
                        focusOnSelect
                      >
                        {activeCard?.photos?.length > 0 &&
                          activeCard?.photos?.map((photo, idx) => {
                            return (
                              <Image
                                key={idx}
                                fluid
                                className="d-block w-100"
                                src={photo.resourcePath}
                                alt="First slide"
                                style={{
                                  maxHeight: IMAGE_HEIGHT,
                                  objectFit: "scale-down",
                                  backgroundColor: "black",
                                }}
                                onClick={() =>
                                  console.log("clicked", photo.resourcePath)
                                }
                              />
                            );
                          })}
                      </Carousel>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Table
                          striped
                          bordered
                          hover
                          size="sm"
                          style={{ backgroundColor: "#6c757d", color: "white" }}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <b>CUSTOMER INFO</b>
                                <br />
                                {activeCard?.sellerContactDetails?.name}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>

                      <Row>
                        <Table
                          striped
                          bordered
                          hover
                          size="sm"
                          style={{ backgroundColor: "#6c757d", color: "white" }}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <b>PHONE</b>
                                <br />
                                <a
                                  style={{ color: "white" }}
                                  href={`"tel:${activeCard?.sellerContactDetails?.contactMethods[0]?.value}`}
                                >
                                  {
                                    activeCard?.sellerContactDetails
                                      ?.contactMethods[0]?.value
                                  }
                                </a>
                              </td>
                              <td>
                                <b>STATE</b>
                                <br />
                                {activeCard?.state}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                      <Row>
                        {/* <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                  width={300}
                  height={300}
                >
                  <XAxis dataKey="date" />
                  <YAxis
                    dataKey="soldPriceInc"
                    type="number"
                    domain={[0, "dataMax"]}
                  />
                  <ZAxis dataKey="dateSold" />

                  <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                  <Scatter data={pricingGraphDataDutton} fill="#8884d8" />
                  <Scatter data={pricingGraphDataAG} fill="#82ca9d" />
                </ScatterChart> */}
                        <Table
                          striped
                          bordered
                          hover
                          size="sm"
                          style={{ backgroundColor: "#6c757d", color: "white" }}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <LineChart
                                  width={200}
                                  height={85}
                                  data={[
                                    {
                                      y: activeCard?.advertisedPrice,
                                    },
                                    {
                                      y:
                                        activeCard?.advertisedPrice *
                                        Math.random() *
                                        0.9,
                                    },
                                    {
                                      y:
                                        activeCard?.advertisedPrice *
                                        Math.random() *
                                        0.9,
                                    },
                                    {
                                      y:
                                        activeCard?.advertisedPrice *
                                        Math.random() *
                                        0.9,
                                    },
                                    {
                                      y:
                                        activeCard?.advertisedPrice *
                                        Math.random() *
                                        0.9,
                                    },
                                    {
                                      y:
                                        activeCard?.advertisedPrice *
                                        Math.random() *
                                        0.9,
                                    },
                                    {
                                      y:
                                        activeCard?.advertisedPrice *
                                        Math.random() *
                                        0.9,
                                    },
                                    {
                                      y:
                                        activeCard?.advertisedPrice *
                                        Math.random() *
                                        0.9,
                                    },
                                    {
                                      y:
                                        activeCard?.advertisedPrice *
                                        Math.random() *
                                        0.9,
                                    },
                                  ]}
                                  margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                  }}
                                >
                                  <YAxis
                                    dataKey="y"
                                    domain={[0, "dataMax"]}
                                    hide
                                  />
                                  <Line
                                    type="monotone"
                                    dataKey="y"
                                    stroke="#FFF"
                                    strokeWidth={2}
                                    dot={true}
                                  />
                                </LineChart>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ButtonGroup className="mb-2">
                        <Button
                          style={{
                            border: "1px solid white",
                            width: 105,
                            backgroundColor: "#2ECC71",
                          }}
                        >
                          Buy
                        </Button>
                        <Button
                          style={{
                            border: "1px solid white",
                            width: 105,
                            backgroundColor: "#E74C3C",
                          }}
                        >
                          Dont Buy
                        </Button>
                        <Button
                          style={{
                            border: "1px solid white",
                            width: 105,
                            backgroundColor: "#F1C40F",
                          }}
                        >
                          Pass
                        </Button>
                        <Button
                          style={{
                            border: "1px solid white",
                            width: 105,
                            backgroundColor: "#3498DB",
                          }}
                        >
                          Allocation
                        </Button>
                      </ButtonGroup>
                    </Col>
                    <Col>
                      <ButtonGroup className="mb-2">
                        <Button
                          style={{
                            width: 105,
                            backgroundColor: "#6c757d",
                            border: "1px solid white",
                          }}
                        >
                          -$3,000
                        </Button>
                        <Button
                          style={{
                            border: "1px solid white",
                            width: 105,
                            backgroundColor: "#6c757d",
                          }}
                        >
                          -$4,000
                        </Button>
                        <Button
                          style={{
                            border: "1px solid white",
                            width: 105,
                            backgroundColor: "#6c757d",
                          }}
                        >
                          -$5,000
                        </Button>
                        <Button
                          style={{
                            border: "1px solid white",
                            width: 105,
                            backgroundColor: "#9B59B6",
                          }}
                        >
                          Custom $
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={3}>
              <Card>
                <Card.Title>
                  {loading && <Spinner animation="border" variant="primary" />}

                  <h5>{`${activeCard?.specData?.year} ${activeCard?.specData?.make} ${activeCard?.specData?.model} ${activeCard?.specData?.description}`}</h5>
                  <h6>
                    {activeCard?.colour}&nbsp; | Adv:{" "}
                    <b>
                      <NumberFormat
                        value={activeCard?.advertisedPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </b>
                    | Odo: {activeCard?.odometer}| Age:{" "}
                    {activeCard?.daysOnMarket}| Views: {activeCard?.views} |
                    Leads: {activeCard?.leads} | Watched: {activeCard?.watches}{" "}
                    |{" "}
                    {activeCard?.tags?.map((tag) => {
                      const tagColour =
                        tag === "TOPSPOT" ? "success" : "warning";
                      return (
                        <Badge
                          style={{ marginRight: 5 }}
                          pill
                          variant={tagColour}
                          // text="light"
                        >
                          {tag}
                        </Badge>
                      );
                    })}
                    {/* <span style={{ float: "right", fontSize: 12 }}> */}
                    <a
                      href={`${carsalesStockLocatorUrlPrefix}${activeCard?.sourceId}`}
                      target="_blank"
                      style={{ fontSize: 12 }}
                    >
                      Open in Carsales
                    </a>
                    {/* </span> */}
                  </h6>
                </Card.Title>
                <Card.Body>
                  <Row>
                    <Col xs={6}>
                      <Carousel
                        infinite={true}
                        showDots={true}
                        responsive={responsive}
                        slidesToSlide={1}
                        style={{ backgroundColor: "black" }}
                        itemClass="carousel-item-padding-40-px"
                        containerClass="carousel-container"
                        focusOnSelect
                      >
                        {activeCard?.photos?.length > 0 &&
                          activeCard?.photos?.map((photo, idx) => {
                            return (
                              <Image
                                key={idx}
                                fluid
                                className="d-block w-100"
                                src={photo.resourcePath}
                                alt="First slide"
                                style={{
                                  maxHeight: IMAGE_HEIGHT,
                                  objectFit: "scale-down",
                                  backgroundColor: "black",
                                }}
                                onClick={() =>
                                  console.log("clicked", photo.resourcePath)
                                }
                              />
                            );
                          })}
                      </Carousel>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Table striped bordered hover size="sm">
                          <tbody>
                            <tr>
                              <td colSpan={3} style={{ fontWeight: "bold" }}>
                                CUSTOMER INFO
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Name: {activeCard?.sellerContactDetails?.name}{" "}
                                John Smith
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>

                      <Row>
                        <Table striped bordered hover size="sm">
                          <tbody>
                            <tr>
                              <td>
                                Phone:&nbsp;
                                <a
                                  href={`"tel:${activeCard?.sellerContactDetails?.contactMethods[0]?.value}"0412 123 456`}
                                >
                                  {
                                    activeCard?.sellerContactDetails
                                      ?.contactMethods[0]?.value
                                  }
                                  +61 412 123 456
                                </a>{" "}
                              </td>
                              <td>
                                State: {activeCard?.state} 3122 @TODO SUBURB
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                      <Row>
                        {/* <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                  width={300}
                  height={300}
                >
                  <XAxis dataKey="date" />
                  <YAxis
                    dataKey="soldPriceInc"
                    type="number"
                    domain={[0, "dataMax"]}
                  />
                  <ZAxis dataKey="dateSold" />

                  <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                  <Scatter data={pricingGraphDataDutton} fill="#8884d8" />
                  <Scatter data={pricingGraphDataAG} fill="#82ca9d" />
                </ScatterChart> */}
                        <LineChart
                          width={200}
                          height={50}
                          data={[
                            {
                              y: activeCard?.advertisedPrice,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                          ]}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <YAxis dataKey="y" domain={[0, "dataMax"]} hide />
                          <Line
                            type="monotone"
                            dataKey="y"
                            stroke="#8884d8"
                            strokeWidth={2}
                            dot={true}
                          />
                        </LineChart>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ButtonGroup className="mb-2">
                        <Button variant="success">Buy</Button>
                        <Button variant="danger">Dont Buy</Button>
                        <Button variant="warning">Pass</Button>
                        <Button variant="outline-warning">Allocation</Button>
                      </ButtonGroup>
                      <ButtonGroup className="mb-2">
                        <Button variant="outline-primary">-$3,000</Button>
                        <Button variant="outline-primary">-$4,000</Button>
                        <Button variant="outline-primary">-$5,000</Button>
                        <Button variant="outline-warning">Custom $</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={3}>
              <Card>
                <Card.Title>
                  {loading && <Spinner animation="border" variant="primary" />}

                  <h5>{`${activeCard?.specData?.year} ${activeCard?.specData?.make} ${activeCard?.specData?.model} ${activeCard?.specData?.description}`}</h5>
                  <h6>
                    {activeCard?.colour}&nbsp; | Adv:{" "}
                    <b>
                      <NumberFormat
                        value={activeCard?.advertisedPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </b>
                    | Odo: {activeCard?.odometer}| Age:{" "}
                    {activeCard?.daysOnMarket}| Views: {activeCard?.views} |
                    Leads: {activeCard?.leads} | Watched: {activeCard?.watches}{" "}
                    |{" "}
                    {activeCard?.tags?.map((tag) => {
                      const tagColour =
                        tag === "TOPSPOT" ? "success" : "warning";
                      return (
                        <Badge
                          style={{ marginRight: 5 }}
                          pill
                          variant={tagColour}
                          // text="light"
                        >
                          {tag}
                        </Badge>
                      );
                    })}
                    {/* <span style={{ float: "right", fontSize: 12 }}> */}
                    <a
                      href={`${carsalesStockLocatorUrlPrefix}${activeCard?.sourceId}`}
                      target="_blank"
                      style={{ fontSize: 12 }}
                    >
                      Open in Carsales
                    </a>
                    {/* </span> */}
                  </h6>
                </Card.Title>
                <Card.Body>
                  <Row>
                    <Col xs={6}>
                      <Carousel
                        infinite={true}
                        showDots={true}
                        responsive={responsive}
                        slidesToSlide={1}
                        style={{ backgroundColor: "black" }}
                        itemClass="carousel-item-padding-40-px"
                        containerClass="carousel-container"
                        focusOnSelect
                      >
                        {activeCard?.photos?.length > 0 &&
                          activeCard?.photos?.map((photo, idx) => {
                            return (
                              <Image
                                key={idx}
                                fluid
                                className="d-block w-100"
                                src={photo.resourcePath}
                                alt="First slide"
                                style={{
                                  maxHeight: IMAGE_HEIGHT,
                                  objectFit: "scale-down",
                                  backgroundColor: "black",
                                }}
                                onClick={() =>
                                  console.log("clicked", photo.resourcePath)
                                }
                              />
                            );
                          })}
                      </Carousel>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Table striped bordered hover size="sm">
                          <tbody>
                            <tr>
                              <td colSpan={3} style={{ fontWeight: "bold" }}>
                                CUSTOMER INFO
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Name: {activeCard?.sellerContactDetails?.name}{" "}
                                John Smith
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>

                      <Row>
                        <Table striped bordered hover size="sm">
                          <tbody>
                            <tr>
                              <td>
                                Phone:&nbsp;
                                <a
                                  href={`"tel:${activeCard?.sellerContactDetails?.contactMethods[0]?.value}"0412 123 456`}
                                >
                                  {
                                    activeCard?.sellerContactDetails
                                      ?.contactMethods[0]?.value
                                  }
                                  +61 412 123 456
                                </a>{" "}
                              </td>
                              <td>
                                State: {activeCard?.state} 3122 @TODO SUBURB
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                      <Row>
                        {/* <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                  width={300}
                  height={300}
                >
                  <XAxis dataKey="date" />
                  <YAxis
                    dataKey="soldPriceInc"
                    type="number"
                    domain={[0, "dataMax"]}
                  />
                  <ZAxis dataKey="dateSold" />

                  <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                  <Scatter data={pricingGraphDataDutton} fill="#8884d8" />
                  <Scatter data={pricingGraphDataAG} fill="#82ca9d" />
                </ScatterChart> */}
                        <Card>
                          <LineChart
                            width={200}
                            height={50}
                            data={[
                              {
                                y: activeCard?.advertisedPrice,
                              },
                              {
                                y:
                                  activeCard?.advertisedPrice *
                                  Math.random() *
                                  0.9,
                              },
                              {
                                y:
                                  activeCard?.advertisedPrice *
                                  Math.random() *
                                  0.9,
                              },
                              {
                                y:
                                  activeCard?.advertisedPrice *
                                  Math.random() *
                                  0.9,
                              },
                              {
                                y:
                                  activeCard?.advertisedPrice *
                                  Math.random() *
                                  0.9,
                              },
                              {
                                y:
                                  activeCard?.advertisedPrice *
                                  Math.random() *
                                  0.9,
                              },
                              {
                                y:
                                  activeCard?.advertisedPrice *
                                  Math.random() *
                                  0.9,
                              },
                              {
                                y:
                                  activeCard?.advertisedPrice *
                                  Math.random() *
                                  0.9,
                              },
                              {
                                y:
                                  activeCard?.advertisedPrice *
                                  Math.random() *
                                  0.9,
                              },
                            ]}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                          >
                            <YAxis dataKey="y" domain={[0, "dataMax"]} hide />
                            <Line
                              type="monotone"
                              dataKey="y"
                              stroke="#8884d8"
                              strokeWidth={2}
                              dot={true}
                            />
                          </LineChart>
                        </Card>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ButtonGroup className="mb-2">
                        <Button variant="success">Buy</Button>
                        <Button variant="danger">Dont Buy</Button>
                        <Button variant="warning">Pass</Button>
                        <Button variant="outline-warning">Allocation</Button>
                      </ButtonGroup>
                      <ButtonGroup className="mb-2">
                        <Button variant="outline-primary">-$3,000</Button>
                        <Button variant="outline-primary">-$4,000</Button>
                        <Button variant="outline-primary">-$5,000</Button>
                        <Button variant="outline-warning">Custom $</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={3}>
              <Card>
                <Card.Title>
                  {loading && <Spinner animation="border" variant="primary" />}

                  <h5>{`${activeCard?.specData?.year} ${activeCard?.specData?.make} ${activeCard?.specData?.model} ${activeCard?.specData?.description}`}</h5>
                  <h6>
                    {activeCard?.colour}&nbsp; | Adv:{" "}
                    <b>
                      <NumberFormat
                        value={activeCard?.advertisedPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </b>
                    | Odo: {activeCard?.odometer}| Age:{" "}
                    {activeCard?.daysOnMarket}| Views: {activeCard?.views} |
                    Leads: {activeCard?.leads} | Watched: {activeCard?.watches}{" "}
                    |{" "}
                    {activeCard?.tags?.map((tag) => {
                      const tagColour =
                        tag === "TOPSPOT" ? "success" : "warning";
                      return (
                        <Badge
                          style={{ marginRight: 5 }}
                          pill
                          variant={tagColour}
                          // text="light"
                        >
                          {tag}
                        </Badge>
                      );
                    })}
                    {/* <span style={{ float: "right", fontSize: 12 }}> */}
                    <a
                      href={`${carsalesStockLocatorUrlPrefix}${activeCard?.sourceId}`}
                      target="_blank"
                      style={{ fontSize: 12 }}
                    >
                      Open in Carsales
                    </a>
                    {/* </span> */}
                  </h6>
                </Card.Title>
                <Card.Body>
                  <Row>
                    <Col xs={6}>
                      <Carousel
                        infinite={true}
                        showDots={true}
                        responsive={responsive}
                        slidesToSlide={1}
                        style={{ backgroundColor: "black" }}
                        itemClass="carousel-item-padding-40-px"
                        containerClass="carousel-container"
                        focusOnSelect
                      >
                        {activeCard?.photos?.length > 0 &&
                          activeCard?.photos?.map((photo, idx) => {
                            return (
                              <Image
                                key={idx}
                                fluid
                                className="d-block w-100"
                                src={photo.resourcePath}
                                alt="First slide"
                                style={{
                                  maxHeight: IMAGE_HEIGHT,
                                  objectFit: "scale-down",
                                  backgroundColor: "black",
                                }}
                                onClick={() =>
                                  console.log("clicked", photo.resourcePath)
                                }
                              />
                            );
                          })}
                      </Carousel>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Table striped bordered hover size="sm">
                          <tbody>
                            <tr>
                              <td colSpan={3} style={{ fontWeight: "bold" }}>
                                CUSTOMER INFO
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Name: {activeCard?.sellerContactDetails?.name}{" "}
                                John Smith
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>

                      <Row>
                        <Table striped bordered hover size="sm">
                          <tbody>
                            <tr>
                              <td>
                                Phone:&nbsp;
                                <a
                                  href={`"tel:${activeCard?.sellerContactDetails?.contactMethods[0]?.value}"0412 123 456`}
                                >
                                  {
                                    activeCard?.sellerContactDetails
                                      ?.contactMethods[0]?.value
                                  }
                                  +61 412 123 456
                                </a>{" "}
                              </td>
                              <td>
                                State: {activeCard?.state} 3122 @TODO SUBURB
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                      <Row>
                        {/* <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                  width={300}
                  height={300}
                >
                  <XAxis dataKey="date" />
                  <YAxis
                    dataKey="soldPriceInc"
                    type="number"
                    domain={[0, "dataMax"]}
                  />
                  <ZAxis dataKey="dateSold" />

                  <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                  <Scatter data={pricingGraphDataDutton} fill="#8884d8" />
                  <Scatter data={pricingGraphDataAG} fill="#82ca9d" />
                </ScatterChart> */}
                        <LineChart
                          width={200}
                          height={50}
                          data={[
                            {
                              y: activeCard?.advertisedPrice,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                            {
                              y:
                                activeCard?.advertisedPrice *
                                Math.random() *
                                0.9,
                            },
                          ]}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <YAxis dataKey="y" domain={[0, "dataMax"]} hide />
                          <Line
                            type="monotone"
                            dataKey="y"
                            stroke="#8884d8"
                            strokeWidth={2}
                            dot={true}
                          />
                        </LineChart>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ButtonGroup className="mb-2">
                        <Button variant="success">Buy</Button>
                        <Button variant="danger">Dont Buy</Button>
                        <Button variant="warning">Pass</Button>
                        <Button variant="outline-warning">Allocation</Button>
                      </ButtonGroup>
                      <ButtonGroup className="mb-2">
                        <Button variant="outline-primary">-$3,000</Button>
                        <Button variant="outline-primary">-$4,000</Button>
                        <Button variant="outline-primary">-$5,000</Button>
                        <Button variant="outline-warning">Custom $</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Table striped bordered hover size="sm">
            <tbody>
              <tr>
                <td colSpan={3} style={{ fontWeight: "bold" }}>
                  RECENTLY SOLD IN DUTTON
                </td>
              </tr>
              {isLoadingRecentlySoldCars && (
                <tr>
                  <td colSpan={3}>
                    <Spinner animation="border" variant="primary" />
                  </td>
                </tr>
              )}
              {recentlySoldCarsForActiveCard
                ?.sort((a, b) => b["Sale Date EPOC"] - a["Sale Date EPOC"])
                ?.map((car) => {
                  return (
                    <tr>
                      <td>Drivecloud Image TODO</td>
                      <td>
                        {car.VEHMake} {car.VEHModel} {car.VEHModelYear}{" "}
                        {car["Variant & Series"]} {car.VEHColour1}
                      </td>
                      <td>
                        <NumberFormat
                          value={car["SalePriceInc"]}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                        |Age: {car.Age} days |Leads: {car.Leads}
                        |Branch: {car.Division}
                        |Odo: {car.VEHOdometer}
                        |Sold date:{" "}
                        {dayjs.unix(car["Sale Date EPOC"]).format("DD/MM/YYYY")}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td style={{ fontWeight: "bold" }}>
                  DEALER STOCK (
                  {
                    recentlySoldCarsCSForActiveCard?.filter(
                      (listing) => listing.dateDelisted === null
                    )?.length
                  }
                  )
                </td>
                <td style={{ fontWeight: "bold" }}>filtered by age</td>
              </tr>
              {isLoadingRecentlySoldCarsCS && (
                <tr>
                  <td colSpan={3}>
                    <Spinner animation="border" variant="primary" />
                  </td>
                </tr>
              )}
              {recentlySoldCarsCSForActiveCard
                ?.filter((car) => car.dateDelisted === null)

                ?.map((car) => {
                  const soldStatus =
                    car.dateDelisted === null ? "Active" : "Sold";
                  const soldStatusColor =
                    car.dateDelisted === null ? "green" : "red";
                  return (
                    // <tr style={{ backgroundColor: soldStatusColor }}>
                    <tr>
                      <td style={{ width: 100 }}>
                        {/* <Carousel
                          infinite={true}
                          showDots={true}
                          responsive={responsive}
                        >
                          {car.photos?.map((photo) => {
                            return (
                              <div>
                                <Image
                                  // className="d-block w-100"
                                  style={{
                                    alignItems: "center",
                                    contain: "contain",
                                  }}
                                  src={photo.resourcePath}
                                  alt="First slide"
                                  height={75}
                                />
                              </div>
                            );
                          })}
                        </Carousel> */}
                      </td>
                      <td>
                        {car.specData?.make} {car.specData?.model}{" "}
                        {car.specData?.year} {car.specData?.description}{" "}
                        {car.colour}
                      </td>
                      <td>
                        <NumberFormat
                          value={car.advertisedPrice}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                        |{car.daysOnMarket} days |Leads: {car.leads}
                        |Views: {car.views} |Watches: {car.watches}
                        <b>
                          {" "}
                          |Dealer: {car.dealerName}|
                          {car.sellerType === "F" ? "Franchise" : "Independent"}{" "}
                        </b>
                        |{car.state}
                        |Odo:
                        <NumberFormat
                          value={car.odometer}
                          thousandSeparator={true}
                          displayType="text"
                        />
                        |Delisted:{" "}
                        {car.dateDelisted === null
                          ? "In-Stock"
                          : dayjs(car.dateDelisted).format("DD/MM/YYYY")}
                      </td>
                    </tr>
                  );
                })}

              <tr>
                <td colSpan={3} style={{ fontWeight: "bold" }}>
                  DEALER DELISTED (
                  {
                    recentlySoldCarsCSForActiveCard
                      ?.filter((listing) => listing.dateDelisted !== null)
                      ?.filter((car) => {
                        const daysDelisted = dayjs().diff(
                          dayjs(car.dateDelisted),
                          "days"
                        );
                        return daysDelisted < 90;
                      })?.length
                  }
                  )
                </td>
              </tr>
              {isLoadingRecentlySoldCarsCS && (
                <tr>
                  <td colSpan={2}>
                    <Spinner animation="border" variant="primary" />
                  </td>
                </tr>
              )}
              {recentlySoldCarsCSForActiveCard
                ?.filter((car) => car.dateDelisted !== null)
                // Filter for daysDelisted < 90 days
                ?.filter((car) => {
                  const daysDelisted = dayjs().diff(
                    dayjs(car.dateDelisted),
                    "days"
                  );
                  return daysDelisted < 90;
                })
                ?.map((car) => {
                  const soldStatus =
                    car.dateDelisted === null ? "Active" : "Sold";
                  const soldStatusColor =
                    car.dateDelisted === null ? "green" : "red";
                  return (
                    // <tr style={{ backgroundColor: soldStatusColor }}>
                    <tr>
                      <td style={{ width: 100 }}>
                        {/* <Carousel
                          infinite={true}
                          showDots={true}
                          responsive={responsive}
                        >
                          {car.photos?.map((photo) => {
                            return (
                              <div>
                                <Image
                                  // className="d-block w-100"
                                  style={{
                                    alignItems: "center",
                                    contain: "contain",
                                  }}
                                  src={photo.resourcePath}
                                  alt="First slide"
                                  height={75}
                                />
                              </div>
                            );
                          })}
                        </Carousel> */}
                      </td>
                      <td>
                        {car.specData?.make} {car.specData?.model}{" "}
                        {car.specData?.year} {car.specData?.description}{" "}
                        {car.colour}
                      </td>
                      <td>
                        <NumberFormat
                          value={car.advertisedPrice}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                        |{car.daysOnMarket} days |Leads: {car.leads}
                        |Views: {car.views} |Watches: {car.watches}
                        <b>
                          {" "}
                          |Dealer: {car.dealerName}|
                          {car.sellerType === "F" ? "Franchise" : "Independent"}{" "}
                        </b>
                        |{car.state}
                        |Odo:
                        <NumberFormat
                          value={car.odometer}
                          thousandSeparator={true}
                          displayType="text"
                        />
                        |Delisted:{" "}
                        {car.dateDelisted === null
                          ? "In-Stock"
                          : dayjs(car.dateDelisted).format("DD/MM/YYYY")}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td colSpan={3} style={{ fontWeight: "bold" }}>
                  IN STOCK IN DUTTON
                </td>
              </tr>
              {isLoadingInStockCars && (
                <tr>
                  <td colSpan={3}>
                    <Spinner animation="border" variant="primary" />
                  </td>
                </tr>
              )}
              {carsInStock?.map((car) => {
                return (
                  <tr>
                    <td>Drivecloud Image TODO</td>
                    <td>
                      {car.VEHMake} {car.VEHModel} {car.VEHModelYear}{" "}
                      {car["Variant & Series"]} {car.VEHColour1}
                    </td>
                    <td>
                      <NumberFormat
                        value={car.TrueProfit}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                      |Age: {car.Age} days |Leads: {car.CountOfLeads}
                      |Branch: {car.branch_desc}
                      |Odo: {car.VEHOdo}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default DGBuyingBoard;
