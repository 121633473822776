import { useFunctions } from "reactfire";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dayjs from "dayjs";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";

import BackdropComponent from "../BackdropComponent";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Button, Modal } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import NumberFormat from "react-number-format";
import { httpsCallable } from "firebase/functions";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const cardColumnStyle = { padding: 20 };
const scrollOffset = 11; //Number of cards on a screen (offsetting the jump to next "fold")
const refreshRateInMs = 5 * 60 * 1000;

const cardVariants = {
  offscreen: {
    y: -300,
  },
  onscreen: {
    y: 0,
    rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const WallboardDGBuyingVehicleFlow = ({ buyer }) => {
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const { height, width } = useWindowDimensions();
  const [counter, setCounter] = useState(0);
  const [scrollSpeed, setScollSpeed] = useState(5000);
  const [nextRefresh, setNextRefresh] = useState(refreshRateInMs / 1000);
  const [veh, setVeh] = useState({});
  const [showVehModal, setShowVehModal] = useState(false);

  // const [scrollInterval, setScrollInterval] = useState(null);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const functions = useFunctions();

  const handleCloseVehModal = () => {
    console.log("close");
    setVeh({});
    setShowVehModal(false);
  };

  const handleShowVehModal = (stockId) => {
    console.log("open");
    getData(stockId);
    setShowVehModal(true);
  };

  const scrollToNextFold = () => {
    // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    // Make sure that there is no screen overflow, if not, scroll forward
    if (data?.datarows?.length > scrollOffset) {
      let scrollNext = 0;
      if (counter < data?.datarows?.length / scrollOffset) {
        scrollNext = scrollOffset * counter;
      } else {
        setCounter(0);
      }
      console.log("scroll", scrollNext);
      document
        .getElementById(`endRow-${scrollNext}`)
        .scrollIntoView({ behavior: "smooth" });
    } else {
      setCounter(0);
      // clearInterval(scrollInterval);
    }
  };

  // Get vehicle data
  const getData = async (stockId) => {
    var vehicles = null;
    // If not searching by stock id (searching by VIN)

    setVeh({});

    vehicles = firestore
      .collection("inventory-summary")
      .where("VEHStockNumber", "==", parseInt(stockId));

    await vehicles
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          // querySnapshot
          //   const vehData = doc.data();
          toast.success(`Found ${querySnapshot.size} vehicle in stock`);
          const vehTmp = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            vehTmp.push(data);
          });
          // Set photo array
          setVeh(vehTmp[0]);

          //   console.log(`${vehData}`);
        } else {
          toast.error("No vehicle found in stock, check stock id");
        }
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  // Get wallboard report data
  const getOpenSearchData = async () => {
    setData([]);
    setIsLoading(true);
    const searchOS = httpsCallable(functions, "searchOpenSearch");
    // var searchOS = firebase.functions().httpsCallable("searchOpenSearch");
    await searchOS()
      .then(async (result) => {
        // Read result of the Cloud Function.
        var sanitizedMessage = result.data;
        if (sanitizedMessage === false) {
          console.log("None");
        } else {
          if (sanitizedMessage.error?.length > 0) {
            toast.warning(sanitizedMessage.error);
            if (sanitizedMessage.errorCode === 933) {
            }
          } else {
            // console.log(sanitizedMessage);
            setData(sanitizedMessage);
          }
        }
      })
      .catch((e) => {
        toast.error(e);
        console.log(e);
      });
    setIsLoading(false);
  };

  // Loop to scroll page counter
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCounter((prevCounter) => prevCounter + 1);
  //   }, scrollSpeed);
  //   // setScrollInterval(interval);
  //   return () => clearInterval(interval);
  // }, [data.length, scrollSpeed]);

  // // Set scroll speed
  // useEffect(() => {
  //   const unsub = firestore
  //     .collection("settings")
  //     .doc("wallboard-scroll-speed")
  //     .onSnapshot((doc) => {
  //       var ss = 5000;
  //       if (doc.exists) {
  //         const data = doc.data();
  //         if (typeof data["dg-buying"] !== "undefined") {
  //           ss = data["dg-buying"];
  //         }
  //       }
  //       setScollSpeed(ss);
  //       console.log("updating scrollSpeed", ss);
  //     });
  //   return () => unsub();
  // }, []);

  // Initial data get
  useEffect(() => {
    getOpenSearchData();
    console.log("Initial refresh");
  }, []);

  // Data refresh
  useEffect(() => {
    let interval;

    const updateCounter = () => {
      setNextRefresh((currentValue) => currentValue - 1);
    };

    interval = setInterval(() => {
      getOpenSearchData();
      console.log("Refreshing");
      updateCounter();
    }, refreshRateInMs);

    return () => {
      // Clear the interval when component is unmounted
      clearInterval(interval);
    };
  }, []);

  if (width > 1000) {
    return (
      <>
        <div>
          <BackdropComponent loading={loading} />
          <h1>
            DG Buying Services - Vehicle Flow
            <Badge
              pill
              style={{ backgroundColor: "#198754", color: "#FFF" }}
              bg="primary"
            >
              {data?.datarows?.length}
            </Badge>
          </h1>
          <h6>Refreshes every {refreshRateInMs / 1000 / 60} minutes</h6>
          <Button
            style={{
              position: "fixed",
              top: height / 2,
              left: 0,
              zIndex: 99999,
              width: 40,
              height: 150,
              textOrientation: "upright",
              writingMode: "vertical-rl",
            }}
          >
            {`${counter + 1} of ${
              Math.floor(data?.datarows?.length / scrollOffset) + 1
            }`}
          </Button>
          <ToastContainer />
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              fontSize: "1.2em",
              fontWeight: "bolder",
            }}
          >
            <Row
              style={{
                width: "100%",
                height: 70,
                backgroundColor: "#161616",
                color: "#FFF",
                margin: 2,
                borderRadius: 2,
                position: "sticky",
              }}
            >
              {data?.schema?.map((s, idx) => {
                let jsx_col = <></>;
                switch (s.name) {
                  default:
                    jsx_col = (
                      <Col key={"header-" + idx}>
                        {s.alias?.replace(/'/g, "") ||
                          s.name?.replace(/'/g, "")}
                      </Col>
                    );
                }
                return jsx_col;
              })}
            </Row>

            {data?.datarows?.map((r, idx) => {
              let rowBGColour = "";
              const rowStatus =
                r[
                  data?.schema?.findIndex((i) => i.name === "i.current_status")
                ];
              switch (rowStatus) {
                case "DELIVERED":
                  rowBGColour = "#BF40BF";
                  break;

                case "SOLD - NOT DELIVERED":
                case "SOLD":
                case "PENDING":
                  rowBGColour = "#FFBF00";
                  break;

                default:
                  rowBGColour = "#161616";
              }

              return (
                <AnimatePresence>
                  <Row
                    key={`data-row-${idx}`}
                    style={{
                      width: "100%",
                      height: 50,
                      backgroundColor: rowBGColour,
                      color: "#FFF",
                      margin: 2,
                      borderRadius: 2,
                    }}
                  >
                    {r.map((f, idx_field) => {
                      const field_name = data?.schema[idx_field]?.name;
                      let jsx_col = <></>;

                      switch (field_name) {
                        case "i.asking_price":
                          jsx_col = (
                            <Col key={`field-cell-${idx_field}`}>
                              <NumberFormat
                                value={f}
                                thousandSeparator={true}
                                prefix="$"
                                displayType="text"
                              />
                            </Col>
                          );
                          break;

                        case "f.stockId":
                          jsx_col = (
                            <Col
                              key={`field-cell-${idx_field}`}
                              onClick={() => handleShowVehModal(f)}
                            >
                              {f}
                            </Col>
                          );
                          break;

                        default:
                          jsx_col = (
                            <Col key={`field-cell-${idx_field}`}>{f}</Col>
                          );
                      }
                      return jsx_col;
                    })}

                    <div id={`endRow-${idx}`} />
                  </Row>
                </AnimatePresence>
              );
            })}
          </div>
        </div>
        <Modal
          id={`vehicle-card-modal`}
          show={showVehModal}
          onHide={handleCloseVehModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>Stock Card : {veh.VEHStockNumber}</Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Property</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Stock No</td>
                  <td>{veh?.VEHStockNumber}</td>
                </tr>
                <tr>
                  <td>Current Staus</td>
                  <td>{veh?.CurrentStatus}</td>
                </tr>
                <tr>
                  <td>Year</td>
                  <td>{veh?.VEHModelYear}</td>
                </tr>
                <tr>
                  <td>Make</td>
                  <td>{veh?.VEHMake}</td>
                </tr>
                <tr>
                  <td>Model</td>
                  <td>{veh?.VEHModel}</td>
                </tr>
                <tr>
                  <td>Variant</td>
                  <td>{veh["Variant & Series"]}</td>
                </tr>
                <tr>
                  <td>Colour</td>
                  <td>{veh?.VEHColour1}</td>
                </tr>
                <tr>
                  <td>Odometer</td>
                  <td>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator={true}
                      value={veh?.VEHOdo}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Branch / Dealership</td>
                  <td>{veh?.branch_desc}</td>
                </tr>
                <tr>
                  <td>Current location / yard</td>
                  <td>{veh?.VEHYard}</td>
                </tr>
                <tr>
                  <td>Buyer</td>
                  <td>{veh?.Buyer}</td>
                </tr>
                <tr>
                  <td>VIN</td>
                </tr>
                <tr>
                  <td>Age in stock</td>
                  <td>
                    {dayjs().diff(
                      dayjs.unix(veh?.Purchase_Date?.seconds),
                      "day",
                      false
                    )}
                    &nbsp;day(s)
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseVehModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  } else {
    return (
      <h3 style={{ marginTop: 200 }}>
        This wallboard is only visible on screens greater than 1000px in width
      </h3>
      // <Table
      //   style={{
      //     color: "#FFF",
      //     borderColor: "#000",
      //     border: 0,
      //     fontSize: "1.5em",
      //   }}
      // >
      //   <thead>
      //     <tr>
      //       <th>Status</th>
      //       <th>Stock#</th>
      //       <th>Model</th>
      //       <th>Colour</th>
      //       <th>Carrier</th>
      //       <th>Buyer</th>
      //       <th>Booked by</th>
      //       <th>ETC</th>
      //       <th>ETD</th>
      //       <th>Time to ETD</th>
      //     </tr>
      //   </thead>
      //   <tbody>
      //     {data?.map((r) => {
      //       return r.vehicleItem.map((v) => {
      //         return (
      //           <>
      //             <tr>
      //               <td>{r.status}</td>
      //               <td>{v.EclipseStockNumber}</td>
      //               <td>{v.model}</td>
      //               <td>{v.colour}</td>
      //               <td>{r.carrierData?.carrier?.carrier}</td>
      //               <td>N/A</td>
      //               <td>{r.displayName}</td>
      //               <td>{r.carrierData?.estimatedCollectionDate}</td>
      //               <td>{r.carrierData?.estimatedDeliveryDate}</td>
      //               <td style={{ backgroundColor: r.overdue }}>
      //                 {r.relativeDays}
      //               </td>
      //             </tr>
      //           </>
      //         );
      //       });
      //     })}
      //   </tbody>
      // </Table>
    );
  }
};

export default WallboardDGBuyingVehicleFlow;
