import React from "react";
import { Spinner } from "react-bootstrap";
import useWindowDimensions from "../hooks/useWindowDimensions";

function BackdropComponent({ loading }) {
  const { width, height } = useWindowDimensions();
  return loading ? (
    <div
      id="backdropDiv"
      style={{
        backdropFilter: "blur(2px)",
        position: "absolute",
        width: width,
        height: height,
        top: 0,
        left: 0,
        zIndex: 9999,
      }}
    >
      <Spinner
        style={{
          position: "absolute",
          top: height / 2,
          left: width / 2,
          zIndex: 1000,
        }}
        animation="border"
      />
    </div>
  ) : null;
}

export default BackdropComponent;
