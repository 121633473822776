import { useEffect, useState } from "react";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export function useWebsiteRepairList() {
  const [repairList, setRepairList] = useState([]);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  // Get  list
  useEffect(() => {
    firestore
      .collection("website-inventory-repair")
      .where("completed", "==", false)
      .where("attempts", "<", 3)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          setRepairList([]);
          return;
        }
        const tmpList = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpList.push({ ...tmpData, id: doc.id });
        });
        setRepairList(tmpList);
      });
  }, []);

  return [repairList];
}
