import { useFunctions } from "reactfire";
import { groupBy as rowGrouper } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useMemo, useCallback } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContainer from "react-bootstrap/TabContainer";
import { Formik } from "formik";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import { useEffect } from "react";
import { useRef } from "react";
import NumberFormat from "react-number-format";
import DataGrid from "react-data-grid";
import dayjs from "dayjs";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import BookingCard from "./BookingCard";
import PageViewLoggerComponent from "../PageViewLoggerComponent";
// import { FaTruckMoving } from "react-icons/fa";
import { HiOutlineTruck } from "react-icons/hi";
import { BsBoxArrowInRight, BsExclamationCircle } from "react-icons/bs";
import {
  FaSearch,
  FaFilter,
  FaRegTimesCircle,
  FaQuestionCircle,
} from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory } from "react-router";
import { CgDetailsMore } from "react-icons/cg";
import TextField from "./TextField";
import { Vinyl } from "react-bootstrap-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import FreightListFilteredTableView from "./FreightListFilteredTableView";
import { useQuery } from "../../hooks/useQuery";
import BackdropComponent from "../BackdropComponent";
import { Alert } from "react-bootstrap";
import { httpsCallable } from "firebase/functions";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const numericalASCProfit = (a, b) => {
  if (a.TrueProfit < b.TrueProfit) {
    return -1;
  }
  if (a.TrueProfit > b.TrueProfit) {
    return 1;
  }
  return 0;
};

const style = {
  dataGridFill: {
    height: "70vh",
  },
  searchCard: {
    margin: 5,
    padding: 5,
    border: "1px solid #efefef",
    borderRadius: 3,
    cursor: "pointer",
  },
};

const SearcResultMakeFieldSID = (r) => {
  const [veh, setVeh] = useState({});

  useEffect(() => {
    const tmpVeh = r.vehicleItem.find(
      (v) => v.EclipseStockNumber === parseInt(r.serachQStockId)
    );

    if (tmpVeh) {
      setVeh(tmpVeh);
    }
  }, [r]);

  return <p>{veh?.make}</p>;
};

const SearcResultMakeFieldVIN = (r) => {
  const [veh, setVeh] = useState({});

  useEffect(() => {
    const tmpVeh = r.vehicleItem.find((v) => v.VIN === r.searchQVin);

    if (tmpVeh) {
      setVeh(tmpVeh);
    }
  }, [r]);

  return <p>{veh?.make}</p>;
};

const SearchResults = ({ results, searchQ }) => {
  const history = useHistory();
  return (
    <>
      <h5 style={{ marginTop: 15 }}>Search results</h5>
      <Table striped bordered hover responsive size="sm">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Created</th>
            <th>Make</th>
            <th>Status</th>
            {/* <th>StockID</th> */}
            {/* <th>From</th> */}
            {/* <th>To</th> */}
            {/* <th>Buyer</th> */}
            {/* <th>Branch</th> */}
          </tr>
        </thead>
        <tbody>
          {results.map((r) => {
            if (r.searchQOS) {
              return (
                <OverlayTrigger
                  placement="auto-start"
                  delay={{ show: 10, hide: 50 }}
                  overlay={
                    <Tooltip id="button-tooltip-2">{r.bookingId}</Tooltip>
                  }
                >
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <Link
                        target={"_blank"}
                        to={`/freight-booking-list/${r.bookingId}`}
                      >
                        <CgDetailsMore style={{ color: "green" }} />
                      </Link>
                    </td>
                    {/* <td>{dayjs.unix(r.createdAt?.seconds).format("DD-MM-YY")}</td> */}
                    {/* <td>{`Click here to open BID:${r.id}`}</td> */}
                    {/* <td>
                    {r.searchQVin === null
                      ? SearcResultMakeFieldSID(r)
                      : SearcResultMakeFieldVIN(r)}
                  </td> */}
                    <td>
                      {r.createdBy} {dayjs().to(r.createdAt)}
                    </td>
                    <td>{`${r.make} ${r.model}`}</td>
                    <td>{r.status}</td>
                    {/* <td>{r.stockId}</td> */}
                    {/* <td>{r.from}</td> */}
                    {/* <td>{r.to}</td> */}
                  </tr>
                </OverlayTrigger>
              );
            } else {
              return (
                <OverlayTrigger
                  placement="auto-start"
                  delay={{ show: 10, hide: 50 }}
                  overlay={
                    <Tooltip id="button-tooltip-2">{r.bookingId}</Tooltip>
                  }
                >
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <Link
                        target={"_blank"}
                        to={`/freight-booking-list/${r.id}`}
                      >
                        <CgDetailsMore style={{ color: "green" }} />
                      </Link>
                    </td>
                    <td>
                      {dayjs.unix(r.createdAt?.seconds).format("DD-MM-YY")}
                    </td>
                    {/* <td>{`Click here to open BID:${r.id}`}</td> */}
                    <td>
                      {r.searchQVin === null
                        ? SearcResultMakeFieldSID(r)
                        : SearcResultMakeFieldVIN(r)}
                    </td>
                    <td>{r.status}</td>
                  </tr>
                </OverlayTrigger>
              );
            }
          })}
        </tbody>
      </Table>
    </>
  );
};

const FreightBookingListWithRouting = () => {
  const [freightBookingList, setFreightBookingList] = useState([]);
  const [querySearchResults, setQuerySearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showSearchModal, setShowSearchModal] = useState(false);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const functions = useFunctions();

  const openSearchGlobal = async (v) => {
    console.log(v);
    setQuerySearchResults([]);
    const searchOS = httpsCallable(functions, "searchOpenSearchGlobal");
    // var searchOS = firebase.functions().httpsCallable("searchOpenSearchGlobal");
    return await searchOS({ idx: "freight_bookings", query: v })
      .then(async (result) => {
        // Read result of the Cloud Function.
        var sanitizedMessage = result.data;
        if (sanitizedMessage === false) {
          console.log("None");
        } else {
          if (sanitizedMessage.error?.length > 0) {
            toast.warning(sanitizedMessage.error);
            if (sanitizedMessage.errorCode === 933) {
            }
          } else {
            // console.log(sanitizedMessage);
            // setQuerySearchResults(sanitizedMessage);
            return sanitizedMessage;
          }
        }
      })
      .catch((e) => {
        toast.error(e);
        console.log(e);
        return [];
      });
  };

  return (
    <>
      <Button
        style={{
          position: "absolute",
          right: 0,
          top: 80,
          height: 38,
          width: 42,
          zIndex: 99,
          borderRadius: 0,
          boxShadow: "1px 0px 7px rgba(0, 0, 0, 0.5)",
        }}
        onClick={() => setShowSearchModal(!showSearchModal)}
      >
        <FaSearch />
      </Button>

      <Modal
        show={showSearchModal}
        onHide={() => setShowSearchModal(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="12">
              <Formik
                initialValues={{
                  searchVIN: "",
                  searchStockId: "",
                  searchGlobal: "",
                }}
                // validationSchema={validationSchema}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={async (values, { resetForm }) => {
                  if (
                    values.searchVIN.length > 15 ||
                    values.searchVIN.length === 6
                  ) {
                    //   // Search through all vehicles for a true find of full VIN
                    setIsLoading(true);
                    try {
                      await firestore
                        .collection("freight-bookings")
                        .where("indexVIN", "array-contains-any", [
                          values.searchVIN,
                        ])
                        .get()
                        .then((querySnapshot) => {
                          toast.success(`Found ${querySnapshot.size} items`);
                          setQuerySearchResults([]);
                          const tmpSearch = [];
                          querySnapshot.forEach((doc) => {
                            const data = doc.data();
                            const id = doc.id;
                            tmpSearch.push({
                              id,
                              ...data,
                              searchQVin: values.searchVIN,
                              serachQStockId: null,
                              searchQOS: null,
                            });
                          });
                          setQuerySearchResults(tmpSearch);
                        });
                    } catch (e) {
                      console.log(e);
                    }
                    setIsLoading(false);

                    return;
                  }
                  if (values.searchStockId.length >= 3) {
                    console.log(values.searchStockId);
                    setIsLoading(true);
                    // Search firestore
                    try {
                      await firestore
                        .collection("freight-bookings")
                        .where("indexStockId", "array-contains-any", [
                          parseInt(values.searchStockId),
                        ])
                        .get()
                        .then((querySnapshot) => {
                          toast.success(`Found ${querySnapshot.size} items`);
                          setQuerySearchResults([]);
                          const tmpSearch = [];
                          querySnapshot.forEach((doc) => {
                            const data = doc.data();
                            const id = doc.id;
                            tmpSearch.push({
                              id,
                              ...data,
                              searchQVin: null,
                              serachQStockId: values.searchStockId,
                              searchQOS: null,
                            });
                          });
                          setQuerySearchResults(tmpSearch);
                        });
                    } catch (e) {
                      console.log(e);
                    }
                    setIsLoading(false);
                    return;
                  }
                  if (values.searchGlobal.length >= 3) {
                    console.log(values.searchGlobal);
                    setIsLoading(true);
                    const response = await openSearchGlobal(
                      values.searchGlobal
                    );
                    // console.log(response);
                    toast.success(`Found ${response.size} items`);
                    const tmpSearch = [];
                    for (var i = 0; i < response?.datarows?.length; i++) {
                      const r = response?.datarows[i];
                      let output = {};

                      for (var j = 0; j < r.length; j++) {
                        const fieldName = response.schema[j]?.name;
                        output = { ...output, [fieldName]: r[j] };
                      }
                      tmpSearch.push({
                        ...output,
                        searchQVin: null,
                        serachQStockId: null,
                        searchQOS: true,
                      });
                    }
                    // console.log(tmpSearch);
                    setQuerySearchResults(tmpSearch);
                  }
                  setIsLoading(false);
                  return;
                }}
              >
                {/* Callback function containing Formik state and helpers that handle common form actions */}
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  isSubmitting,
                  resetForm,
                }) => (
                  <Form>
                    <Card>
                      <Card.Header>Old Search</Card.Header>
                      <Card.Body>
                        <Row>
                          <Col>
                            <TextField
                              fieldname={`searchStockId`}
                              label=""
                              placeholder="Search by StockId"
                              type="text"
                              values={values}
                              errors={errors}
                              touched={touched}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <TextField
                              fieldname={`searchVIN`}
                              label=""
                              placeholder="Search by VIN"
                              type="text"
                              values={values}
                              errors={errors}
                              touched={touched}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <br />
                    <Card border="warning">
                      <Card.Header>*Experimental* Global Search</Card.Header>
                      <Card.Body>
                        <Accordion>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                          >
                            <FaQuestionCircle /> Click to expand help
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Alert>
                              <Row>
                                <Col>
                                  <p>
                                    You can search across many fields using this
                                    search.{" "}
                                    <i>
                                      Plans are to expand this list as this
                                      feature moves out of Beta into Production
                                    </i>
                                  </p>
                                  <p>Fields you can search within</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <ul>
                                    <li>VIN</li>
                                    <li>Stock Id</li>
                                    <li>Carrier name</li>
                                  </ul>
                                </Col>
                                <Col>
                                  <ul>
                                    <li>Status</li>
                                    <li>Make</li>
                                    <li>Model</li>
                                  </ul>
                                </Col>
                              </Row>
                              {/* <Row>
                                <Col>
                                  <p>
                                    You can enter partial text like "Merc" and
                                    it will search for all instances of Mercedes
                                  </p>
                                </Col>
                              </Row> */}
                            </Alert>
                          </Accordion.Collapse>
                        </Accordion>
                        <Row>
                          <Col>
                            <TextField
                              fieldname={`searchGlobal`}
                              label=""
                              placeholder="Global search..."
                              type="text"
                              values={values}
                              errors={errors}
                              touched={touched}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <br />
                    <Row>
                      <Col>
                        <ButtonGroup>
                          <Button
                            variant="primary"
                            onClick={handleSubmit}
                            block
                          >
                            <FaSearch /> Search
                          </Button>
                          <Button
                            variant="outline-secondary"
                            onClick={() => {
                              resetForm();
                              setQuerySearchResults([]);
                            }}
                          >
                            Clear
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
            {isLoading ? <Spinner animation="border" /> : null}
            {querySearchResults.length > 0 ? (
              <Col>
                <SearchResults results={querySearchResults} />
              </Col>
            ) : null}
          </Row>
        </Modal.Body>
      </Modal>
      <Nav
        style={{ marginLeft: 10 }}
        variant="pills"
        defaultActiveKey="/freight-booking-list"
      >
        <Nav.Item>
          <Nav.Link as={NavLink} to={`/freight-booking/list/Draft`}>
            Draft
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to={`/freight-booking/list/Request`}>
            Open
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to={`/freight-booking/list/Booked`}>
            Booked
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to={`/freight-booking/list/Transit`}>
            In Transit
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to={`/freight-booking/list/Finalised`}>
            Finalised
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to={`/freight-booking/list/Cancelled`}>
            Cancelled
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <br />
      <ToastContainer />
      <PageViewLoggerComponent />
    </>
  );
};

export default FreightBookingListWithRouting;
