import { ToastContainer, toast } from "react-toastify";
import React, { useState, useEffect } from "react";

import Spinner from "react-bootstrap/Spinner";
import dayjs from "dayjs";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";

import {
  FaCalendarCheck,
  FaPlaneArrival,
  FaPlaneDeparture,
} from "react-icons/fa";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

// Changed address from Grieve PDE to Bridge St 210824
// "445 Grieve Parade, Altona North VIC 3025, Australia"
const WHOLESALE_ADDRESS = "339 Bridge Street, Port Melbourne VIC, Australia";

const WallboardWholesale = ({ type }) => {
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(false);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  useEffect(() => {
    setData([]);
    setIsLoading(true);
    let address = "";

    if (type === "arrivals") {
      address = "dropOff.location.address";
    } else if (type === "departures") {
      address = "pickup.location.address";
    }

    const unsubscribe = firestore
      .collection("freight-bookings")
      .where(address, "==", WHOLESALE_ADDRESS)
      .where("status", "in", ["Booked", "Transit"])
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          toast.info("No data found");
          setIsLoading(false);
          setData([]);
          return;
        }
        let tmpCarriers = [];

        snapshot.forEach((doc) => {
          let tmpData = doc.data();
          const d1 = tmpData.carrierData?.estimatedDeliveryDate;
          const now = dayjs();
          const diff = now.diff(d1, "days", true) * -1;
          let overdue = diff < 0 ? "red" : "";
          let relativeDays = dayjs().to(
            dayjs(tmpData.carrierData?.estimatedDeliveryDate)
          );

          const today = dayjs().format("YYYY-MM-DD");
          const tomorrow = dayjs().add(1, "day").format("YYYY-MM-DD");
          const yesterday = dayjs().add(-1, "day").format("YYYY-MM-DD");
          if (d1 === today) {
            relativeDays = "Today";
            overdue = "green";
          } else if (d1 === tomorrow) {
            relativeDays = "Tomorrow";
          } else if (d1 === yesterday) {
            relativeDays = "Yesterday";
          }

          tmpData = {
            ...tmpData,
            relativeDays,
            dayDiffNowETD: diff,
            overdue,
          };
          tmpCarriers.push({ ...tmpData, id: doc.id });
        });

        // Sort by days to arrive from ETD
        tmpCarriers = tmpCarriers.sort((a, b) => {
          return a.dayDiffNowETD - b.dayDiffNowETD;
        });

        setData(tmpCarriers);
        setIsLoading(false);
      });

    return () => unsubscribe;
  }, [type]);

  return (
    <>
      {loading ? <Spinner /> : null}
      <h1>
        {type === "arrivals" ? "Arrivals" : "Departues"}
        &nbsp;
        <Badge
          pill
          style={{ backgroundColor: "#198754", color: "#FFF" }}
          bg="primary"
        >
          {data?.length}
        </Badge>
      </h1>
      <h4>{WHOLESALE_ADDRESS}</h4>
      <h6>Last updated: now</h6>
      <ToastContainer />
      <Table striped>
        {/* @TODO FREEZE TOP PANE */}
        <thead>
          <tr>
            <th>Status</th>
            <th>Stock#</th>
            <th>Branch</th>
            <th>Make</th>
            <th>Model</th>
            <th>Variant</th>
            <th>Colour</th>
            <th>Carrier</th>
            <th>Carrier Ref</th>
            <th>{type === "arrivals" ? "Buyer" : "Buyer"}</th>
            <th>Booked by</th>
            <th>ETC</th>
            <th>ETD</th>
            <th>Time to ETD</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((r) => {
            return r.vehicleItem.map((v) => {
              return (
                <>
                  <tr>
                    {/* <td>{r.id}</td> */}
                    <td>
                      {r.status === "Booked" ? <FaCalendarCheck /> : null}
                      {r.status === "Transit" ? (
                        type === "arrivals" ? (
                          <FaPlaneArrival style={{ color: "orange" }} />
                        ) : (
                          <FaPlaneDeparture style={{ color: "orange" }} />
                        )
                      ) : null}
                      &nbsp;
                      {r.status}
                    </td>
                    <td>{v.EclipseStockNumber}</td>
                    <td>{v.branch}</td>
                    <td>{v.make}</td>
                    <td>{v.model}</td>
                    <td>{v.variant}</td>
                    <td>{v.colour}</td>
                    <td>{r.carrierData?.carrier?.carrier}</td>
                    <td>{r.carrierData?.carrierBookingReference}</td>
                    <td>
                      {/* USE VEHICLE BUYER  */}
                      {type === "arrivals" ? r.pickup?.name : r.dropOff?.name}
                    </td>
                    <td>{r.displayName}</td>
                    <td>{r.carrierData?.estimatedCollectionDate}</td>
                    <td>{r.carrierData?.estimatedDeliveryDate}</td>
                    <td style={{ backgroundColor: r.overdue }}>
                      {r.relativeDays}
                    </td>
                  </tr>
                </>
              );
            });
          })}
        </tbody>
      </Table>
    </>
  );
};

export default WallboardWholesale;
