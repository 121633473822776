import { groupBy as rowGrouper } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useMemo, useCallback } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import { Formik } from "formik";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import { useEffect } from "react";
import { useRef } from "react";
import NumberFormat from "react-number-format";
import DataGrid from "react-data-grid";
import dayjs from "dayjs";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import InsightFeatureCard from "./InsightFeatureCard";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// const topProfitDataSet = require("../../assets/testdata/top_profit_test.json");
// const topVolumeDataSet = require("../../assets/testdata/top_volume_test.json");
// const topLeadsDataSet = require("../../assets/testdata/top_leads_test.json");

const numericalASCProfit = (a, b) => {
  if (a.TrueProfit < b.TrueProfit) {
    return -1;
  }
  if (a.TrueProfit > b.TrueProfit) {
    return 1;
  }
  return 0;
};

const InsightsComponent = () => {
  const [topProfitDataSet, setTopProfitDataSet] = useState([]);
  const [topVolumeDataSet, setTopVolumeDataSet] = useState([]);
  // const [topProfitDataSet, setTopProfitDataSet] = useState([]);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  // Top Profit Data Set
  useEffect(() => {
    firestore
      .collection("Top_Profit")
      .where("saleDate", "==", dayjs().add(-1, "day").format("DDMMYYYY"))
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          toast.info("No summary available at this time");
          return;
        }
        const tmpProfit = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpProfit.push({ ...tmpData, id: doc.id });
        });
        tmpProfit.sort(numericalASCProfit).reverse();
        setTopProfitDataSet(tmpProfit);
      });

    // return () => unsubscribe;
  }, []);

  // Top Volume Data Set
  // useEffect(() => {
  //   firebase
  //     .collection("Top_Volume")
  //     .where("saleDate", "==", dayjs().add(-1, "day").format("DDMMYYYY"))
  //     .get()
  //     .then((snapshot) => {
  //       if (snapshot.empty) {
  //         toast.info("No summary available at this time");
  //         return;
  //       }
  //       const tmpVolume = [];
  //       snapshot.forEach((doc) => {
  //         const tmpData = doc.data();
  //         tmpVolume.push({ ...tmpData, id: doc.id });
  //       });
  //       tmpVolume.sort(numericalASCProfit).reverse();
  //       setTopVolumeDataSet(tmpVolume);
  //     });

  //   // return () => unsubscribe;
  // }, []);
  return (
    <>
      {/* START TOP PROFIT */}
      <Navbar
        // sticky="top"
        bg="dark"
        variant="dark"
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        <Navbar.Brand>
          Highest earning cars sold yesterday by branch
        </Navbar.Brand>
      </Navbar>
      <Container>
        {topProfitDataSet?.map((tp, idx) => {
          return (
            <Row style={{ paddingBottom: 10 }} key={idx}>
              <InsightFeatureCard data={tp} />
            </Row>
          );
        })}
      </Container>
      {/* END TOP PROFIT */}

      {/* START TOP VOLUME */}
      {/* <Navbar
        sticky="top"
        bg="dark"
        variant="dark"
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        <Navbar.Brand>Top volume last 30 days</Navbar.Brand>
      </Navbar>
      <Container>
        {topVolumeDataSet?.map((tp, idx) => {
          return (
            <Row style={{ paddingBottom: 10 }}>
              <InsightFeatureCard data={tp} />
            </Row>
          );
        })}
      </Container> */}
      {/* END TOP VOLUME */}

      {/* START TOP LEADS */}

      {/* <Navbar
        sticky="top"
        bg="dark"
        variant="dark"
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        <Navbar.Brand>Top Leads</Navbar.Brand>
      </Navbar>
      <Container>
        {topLeadsDataSet?.map((tp, idx) => {
          return (
            <Row style={{ paddingBottom: 10 }}>
              <InsightFeatureCard data={tp} />
            </Row>
          );
        })}
      </Container> */}
      {/* END TOP LEADS */}
      <ToastContainer />
    </>
  );
};

export default InsightsComponent;
