import { useStorageDownloadURL, useStorage, useUser } from "reactfire";
import { groupBy as rowGrouper } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useMemo, useCallback } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContainer from "react-bootstrap/TabContainer";
import { Formik } from "formik";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import { useEffect } from "react";
import { useRef } from "react";
import NumberFormat from "react-number-format";
import DataGrid from "react-data-grid";
import dayjs from "dayjs";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import BookingCard from "./BookingCard";
import PageViewLoggerComponent from "../PageViewLoggerComponent";
// import { FaTruckMoving } from "react-icons/fa";
import { HiOutlineTruck } from "react-icons/hi";
import { IoGridSharp } from "react-icons/io5";
import { IoListSharp } from "react-icons/io5";
import Badge from "react-bootstrap/Badge";
import MyBookingCard from "./MyBookingCard";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// const topProfitDataSet = require("../../assets/testdata/top_profit_test.json");
// const topVolumeDataSet = require("../../assets/testdata/top_volume_test.json");
// const topLeadsDataSet = require("../../assets/testdata/top_leads_test.json");

const numericalASCProfit = (a, b) => {
  if (a.TrueProfit < b.TrueProfit) {
    return -1;
  }
  if (a.TrueProfit > b.TrueProfit) {
    return 1;
  }
  return 0;
};

const tableColumns = [
  // {
  //   key: "actions",
  //   name: "Actions",
  //   formatter({ row }) {
  //     return (
  //       <>
  //         <Button
  //           variant="link"
  //           onClick={() => {
  //             toast.success(row.id);
  //           }}
  //         >
  //           <HiOutlineTruck style={{ color: "green" }} />
  //         </Button>
  //       </>
  //     );
  //   },
  // },
  { key: "id", name: "Booking Id" },
  {
    key: "createdAt",
    name: "Created",
    formatter({ row }) {
      return (
        <>{dayjs.unix(row["createdAt"].seconds).format("DD-MMM-YY HH:MM")}</>
      );
    },
    width: 160,
  },
  { key: "displayName", name: "Created By" },
  { key: "status", name: "Status" },
  {
    key: "pickup.location.address",
    name: "From",
    formatter({ row }) {
      return <>{row.pickup?.location?.address}</>;
    },
    width: 300,
  },
  {
    key: "dropOff.location.address",
    name: "To",
    formatter({ row }) {
      return <>{row.dropOff?.location?.address}</>;
    },
    width: 300,
  },

  {
    key: "vehicle.stock",
    name: "Stock #",
    formatter({ row }) {
      return <>{row.vehicleItem[0]?.EclipseStockNumber}</>;
    },
  },
  {
    key: "vehicle.branch",
    name: "Origin branch",
    formatter({ row }) {
      return <>{row.vehicleItem[0]?.branch}</>;
    },
    width: 150,
  },
  {
    key: "vehicle.make",
    name: "Make",
    formatter({ row }) {
      return <>{row.vehicleItem[0]?.make}</>;
    },
  },
  {
    key: "vehicle.model",
    name: "Model",
    formatter({ row }) {
      return <>{row.vehicleItem[0]?.model}</>;
    },
  },
];

const MyFreightBookingList = () => {
  const [freightBookingList, setFreightBookingList] = useState([]);
  const [stage, setStage] = useState("Request");
  const [viewTypeRequest, setViewTypeRequest] = useState(1); //0 LIST, 1 CARD
  const [viewTypeBooked, setViewTypeBooked] = useState(0); //0 LIST, 1 CARD
  const [viewTypeTransit, setViewTypeTransit] = useState(0); //0 LIST, 1 CARD
  const [viewTypeFinalised, setViewTypeFinalised] = useState(0); //0 LIST, 1 CARD
  const [isLoading, setIsLoading] = useState(false);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const { data } = useUser();

  const updateStage = (e) => {
    setStage(e);
  };
  // Top Profit Data Set
  useEffect(() => {
    setFreightBookingList([]);
    setIsLoading(true);
    if (data) {
      const unsubscribe = firestore
        .collection("freight-bookings")
        .where("status", "==", stage)
        .where("user", "==", data.uid)
        .orderBy("createdAt", "desc")
        //   .get()
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            toast.info("No open requests available at this time");
            setIsLoading(false);
            setFreightBookingList([]);
            return;
          }
          const tmpBookings = [];

          snapshot.forEach((doc) => {
            const tmpData = doc.data();
            tmpBookings.push({ ...tmpData, id: doc.id });
            //   console.log(`Getting bookings`);
          });
          // console.log(tmpBookings);
          setFreightBookingList(tmpBookings);
          setIsLoading(false);
        });

      return () => unsubscribe;
    }
  }, [stage]);

  return (
    <>
      <Navbar
        // sticky="top"
        bg="dark"
        variant="dark"
        style={{ marginBottom: 10, marginTop: 10 }}
      ></Navbar>

      <Tabs
        onSelect={updateStage}
        defaultActiveKey="Request"
        id="uncontrolled-tab-bookings-flow"
      >
        <Tab eventKey="Request" title="Open ">
          <Container>
            {isLoading ? <Spinner animation="grow" /> : null}

            {freightBookingList?.map((booking, idx) => {
              return (
                <Row style={{ paddingBottom: 10 }} key={idx}>
                  <MyBookingCard
                    key={idx}
                    data={booking}
                    stage="Request"
                    nextStage="Booked"
                  />
                </Row>
              );
            })}
          </Container>
        </Tab>
        <Tab eventKey="Booked" title="Booked">
          <Container>
            {isLoading ? <Spinner animation="grow" /> : null}
            {/* Switch between list and card */}
            <ButtonGroup>
              <Button
                onClick={() => {
                  setViewTypeBooked(0);
                }}
                variant={viewTypeBooked ? "outline-dark" : "dark"}
              >
                <IoListSharp size={30} />
              </Button>
              <Button
                onClick={() => {
                  setViewTypeBooked(1);
                }}
                variant={!viewTypeBooked ? "outline-dark" : "dark"}
              >
                <IoGridSharp size={30} />
              </Button>
            </ButtonGroup>
            {/* Switch between list and card */}
            <br />
            <br />
            {!viewTypeBooked ? (
              <DataGrid
                defaultColumnOptions={{ resizable: true }}
                columns={tableColumns}
                rows={freightBookingList}
              />
            ) : (
              freightBookingList?.map((booking, idx) => {
                return (
                  <Row style={{ paddingBottom: 10 }} key={idx}>
                    <MyBookingCard
                      key={idx}
                      data={booking}
                      stage="Booked"
                      nextStage="Transit"
                    />
                  </Row>
                );
              })
            )}
          </Container>
        </Tab>
        <Tab eventKey="Transit" title="In Transit">
          <Container>
            {isLoading ? <Spinner animation="grow" /> : null}
            {freightBookingList?.map((booking, idx) => {
              return (
                <Row style={{ paddingBottom: 10 }} key={idx}>
                  <MyBookingCard
                    key={idx}
                    data={booking}
                    stage="Transit"
                    nextStage="Finalised"
                  />
                </Row>
              );
            })}
          </Container>
        </Tab>
        <Tab eventKey="Finalised" title="Finalised">
          <Container>
            {isLoading ? <Spinner animation="grow" /> : null}
            {freightBookingList?.map((booking, idx) => {
              return (
                <Row style={{ paddingBottom: 10 }} key={idx}>
                  <MyBookingCard
                    key={idx}
                    data={booking}
                    stage={"Finalised"}
                    nextStage={"Archive"}
                  />
                </Row>
              );
            })}
          </Container>
        </Tab>
      </Tabs>

      <ToastContainer />
      <PageViewLoggerComponent />
    </>
  );
};

export default MyFreightBookingList;
