import { ErrorMessage, useField } from "formik";
import Form from "react-bootstrap/Form";

const TextField = (props) => {
  const [field, meta, helpers] = useField(props.fieldname);

  const { value } = meta;

  return (
    <>
      <Form.Group controlId={`form${props.fieldname}`}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control
          type={props.type || "text"}
          /* This name property is used to access the value of the form element via values.nameOfElement */
          name={props.fieldname}
          placeholder={props.placeholder}
          /* Set onChange to handleChange */
          onChange={props.handleChange}
          /* Set onBlur to handleBlur */
          onBlur={props.handleBlur}
          /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
          value={value}
          /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
          className={
            props.touched[props.fieldname] && props.errors[props.fieldname]
              ? "error"
              : null
          }
          {...props}
        />

        {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
        <ErrorMessage name={props.fieldname}>
          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
        </ErrorMessage>
      </Form.Group>
    </>
  );
};

export default TextField;
