import { useEffect, useState, useRef } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  FormControl,
  InputGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {
  HiClipboardCheck,
  HiInformationCircle,
  HiOutlinePlusCircle,
  HiPlusCircle,
} from "react-icons/hi";
import copy from "copy-to-clipboard";
import { useUser } from "reactfire";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";

// import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const imageB64Prefix = "data:image/png;base64,";

// const blobToImage = (blob) => {
//   return new Promise((resolve) => {
//     const url = URL.createObjectURL(blob);
//     let img = new Image();
//     img.onload = () => {
//       URL.revokeObjectURL(url);
//       resolve(img);
//     };
//     img.src = url;
//     console.log(img);
//   });
// };

function AccountsPayabalInvoiceAIComponent() {
  const [pageImages, setPageImages] = useState([]);
  const [image, setImage] = useState(`data:image/png;base64, `);
  const [blocks, setBlocks] = useState([]);
  const [responsiveInvoiceSize, setResponsiveInvoiceSize] = useState();
  const [errorStockSearch, setErrorStockSearch] = useState(false);
  const [stockSearchResults, setStockSearchResults] = useState([]);
  const [invoice_parser_data, setInvoiceParserData] = useState({});
  const [nextTicket, setNextTicket] = useState(null);
  const [isLoadingNextTicket, setIsLoadingNextTicket] = useState(false);
  const [isPageImagesLoading, setIsPageImagesLoading] = useState(false);
  const [pageImageIndex, setPageImageIndex] = useState(0);
  const [imageErrorState, setImageErrorState] = useState(false);
  const [queryCursor, setQueryCursor] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);
  const [allTickets, setAllTickets] = useState([]);
  const [isLoadingTickets, setIsLoadingTickets] = useState(false);
  const [queryCursorOutOfBounds, setQueryCursorOutOfBounds] = useState(false);
  const [vin, setVIN] = useState("");
  const [loadingVehicle, setLoadingVehicle] = useState(false);
  const [setVehicleManually, setSetVehicleManually] = useState(false);
  const [pickedUpTicket, setPickedUpTicket] = useState(false);
  const [page, setPage] = useState(50);
  const [manualCheckArray, setManualCheckArray] = useState({});

  const firestore = firebase.firestore(); // @COMPATIBILITY
  const user = useUser();

  const canvasRef = useRef(null);
  const invoiceColRef = useRef(null);
  const imageRef = useRef(null);

  const windowDimensions = useWindowDimensions();

  const copyToClipboard = (v) => {
    copy(v);
  };

  const handleSaveDataEntry = async () => {
    // Check that all manual check boxes are checked
    const manualCheckArrayTmp = { ...manualCheckArray };
    let allChecked = true;
    Object.keys(manualCheckArrayTmp).forEach((key) => {
      if (!manualCheckArrayTmp[key]) {
        allChecked = false;
      }
    });
    console.log(manualCheckArrayTmp);
    if (!allChecked) {
      console.log("not all fields checked");
      toast.error("Not all fields checked", {
        position: "top-center",
      });
      return;
    }
  };

  const handleReloadBlocks = () => {
    console.log("reloading blocks");
    setBlocks(invoice_parser_data?.entities);
  };

  const handleToggleManualCheck = (type, checked) => {
    const manualCheckArrayTmp = { ...manualCheckArray };
    manualCheckArrayTmp[type] = checked;
    setManualCheckArray(manualCheckArrayTmp);
  };

  const setImageFromBlob = async (blob) => {
    // const image = await blobToImage(blob);
    // console.log(image);
    // Convert blob to b64
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      // console.log(base64data);
      setImage(base64data);
    };
  };

  const handleAddNewInvoice = () => {
    console.log("@TODO Add new invoice");
    // Upload invoice

    // Convert to base64

    // Send to onCall Function to process
  };

  // START TEXT SEARCH FUNCTIONS
  const searchForVin = async (scope) => {
    const parseStringForVIN = (str) => {
      // console.log("searching string for VIN", str);
      if (str.length > 16) {
        // Split mention text into words
        const mentionTextWords = str.split("\n");
        // Check if any words are 17 characters long
        const word17 = mentionTextWords.find((word) => {
          // Split words by spaces
          const wordSplit = word.split(" ");

          // Check if any words are 17 characters long
          const wordsSplitSpace = wordSplit.find((word) => word.length === 17);

          if (wordsSplitSpace) return true;
          return false;
        });

        if (word17) return true;
      }
      return false;
    };

    const getVINStringFromBlock = (block) => {
      const str = block.mentionText;
      // console.log("searching string for VIN", str);
      let vinStr = "";
      if (str.length > 16) {
        // Split mention text into words
        const mentionTextWords = str.split("\n");
        // Check if any words are 17 characters long
        const word17 = mentionTextWords.find((word) => {
          // Split words by spaces
          const wordSplit = word.split(" ");

          // Check if any words are 17 characters long
          const wordsSplitSpace = wordSplit.find((word) => word.length === 17);

          if (wordsSplitSpace) {
            vinStr = wordsSplitSpace;
            return true;
          }
          return false;
        });
      }
      return vinStr;
    };
    if (scope === "current") {
      // console.log("searching current page for VIN");
      // console.log(blocks);
      const vinBlocks = blocks.filter((block) => {
        if (block.type === "line_item") {
          const blockData = block.properties;
          const vinBlock = blockData.find((lineItem) =>
            parseStringForVIN(lineItem.mentionText)
          );
          if (vinBlock) {
            // console.log("found vin block", vinBlock);
            const tmpVIN = getVINStringFromBlock(vinBlock);
            if (tmpVIN.length === 17) {
              setVIN(tmpVIN);
            }
          }
        } else {
          if (block.mentionText.length > 16) {
            const mentionTextWords = block.mentionText.split("\n");
            // Check if any words are 17 characters long
            const word17 = mentionTextWords.find((word) => {
              // Split words by spaces
              const wordSplit = word.split(" ");

              // Check if any words are 17 characters long
              const wordsSplitSpace = wordSplit.find(
                (word) => word.length === 17
              );

              if (wordsSplitSpace) return true;
              return false;
            });

            if (word17) return true;
          }
        }
        return false;
      });
      // console.log(vinBlocks);
      if (vinBlocks.length > 0) {
        console.log("blocks with > 16 string", vinBlocks);
        // const pageRef = vinBlock.pageAnchor.pageRefs[0];
        // console.log(pageRef);
        // const pageImageIndex = parseInt(pageRef.page);
        // console.log(pageImageIndex);
        // setPageImageIndex(pageImageIndex);
      }
    }
  };

  // END TEXT SEARCH FUNCTIONS

  const pickUpTicket = async (ticketId) => {
    const tmpTicket = { ...nextTicket.ticket };
    tmpTicket.pickedUp = true;
    tmpTicket.pickedUpBy = user.data.uid;
    tmpTicket.pickedUpATimestamp = dayjs().unix();
    tmpTicket.pickedUpByEmail = user.data.email;
    tmpTicket.pickedUpByName = user.data.displayName;

    await firestore
      .collection("invoices_parsed_by_ai")
      .doc(ticketId)
      .update(
        {
          ...tmpTicket,
        },
        { merge: true }
      );
    setPickedUpTicket(true);
  };

  const getAllTickets = async () => {
    setIsLoadingTickets(true);
    setNextTicket(null);
    setPageImageIndex(0);

    await firestore
      .collection("invoices_parsed_by_ai")
      .where("ticket.status", "==", "open")
      .where("ticket.pickedUp", "==", false)
      .where("ticket.closed", "==", false)
      .orderBy("ticket.created", "desc")
      .limit(page)
      .get()
      .then((querySnapshot) => {
        const ticketsTmp = [];
        if (querySnapshot.size > 0) {
          setTotalTickets(querySnapshot.size);
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            const id = doc.id;
            ticketsTmp.push({ id, ...data });
          });
        }
        // console.log(ticketsTmp);
        setAllTickets(ticketsTmp);
      });
    setIsLoadingTickets(false);
  };

  const getTicket = async () => {
    // console.log("getting  ticket");
    // console.log(queryCursor);
    setIsLoadingNextTicket(true);
    if (queryCursor < 0 || queryCursor > totalTickets) {
      setQueryCursorOutOfBounds(true);
      setNextTicket(null);
    } else {
      setQueryCursorOutOfBounds(false);
      const nextTicketTmp = allTickets[queryCursor];
      console.log(nextTicketTmp);
      setNextTicket(nextTicketTmp);
    }
    setIsLoadingNextTicket(false);
  };

  const handleQueryCursorIncremet = (inc) => {
    setQueryCursor(queryCursor + inc);
  };

  const getPageImages = async () => {
    if (!nextTicket) return;
    setIsPageImagesLoading(true);
    setImageErrorState(false);
    await firestore
      .collection("invoices_parsed_by_ai")
      .doc(nextTicket.id)
      .collection("page_images")
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          const pageImagesTmp = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            const id = doc.id;
            pageImagesTmp.push({ id, ...data });
          });
          setPageImages(pageImagesTmp);
        } else {
          setPageImages([]);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
    setIsPageImagesLoading(false);
  };

  const searchByVin = async (vin) => {
    var vehicles = null;
    var searchFullVIN = true;

    if (vin.length === 0 || vin.length < 15) {
      if (vin.length === 6) {
        // If 6 digits, search truncated VIN (last 6 digits)
        searchFullVIN = false;
      } else {
        setErrorStockSearch(true);
        return;
      }
    }
    setLoadingVehicle(true);
    setStockSearchResults([]);

    if (searchFullVIN) {
      vehicles = firestore
        .collection("inventory-summary")
        .where("VEHVIN", "==", vin);
    } else {
      vehicles = firestore
        .collection("inventory-summary")
        .where("VINTrunc", "==", vin);
    }
    await vehicles
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          // querySnapshot
          //   const vehData = doc.data();
          const vehTmp = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            vehTmp.push(data);
          });
          // Set photo array
          setStockSearchResults(vehTmp);

          //   console.log(`${vehData}`);
        } else {
          setErrorStockSearch(true);
        }
      })
      .catch((e) => {
        console.log(e.message);
        setErrorStockSearch(true);
      });
    setLoadingVehicle(false);
  };

  //   const { editor, onReady } = useFabricJSEditor();
  function resizeCanvas() {
    const width = invoice_parser_data?.pages[0]?.dimension?.width;
    const height = invoice_parser_data?.pages[0]?.dimension?.height;
    const canvas = canvasRef.current;

    if (canvas.width !== width || canvas.height !== height) {
      canvas.width = width;
      canvas.height = height;
      return true; // here you can return some usefull information like delta width and delta height instead of just true
      // this information can be used in the next redraw...
    }

    return false;
  }

  const drawBackgroundImage = ({ width, height, top, left }) => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.reset();
    // console.log(blocks);
    // Resize

    let frameCount = 0;
    let animationFrameId;

    // const imageSrc = `data:image/png;base64, ${image}`;
    const imageSrc = image;
    const imageTmp = new Image();

    imageTmp.src = imageSrc;

    // console.log("Image loaded", imageTmp.complete);
    imageTmp.onload = () => {
      // console.log("img loaded");
      // console.log("drawing on canvas", imageTmp);
      context.drawImage(imageTmp, 0, 0);
      drawBlocks({ width, height, top, left });
    };

    imageTmp.onerror = (e) => {
      console.log("error loading image", e);
      setImageErrorState(true);
    };
  };

  const drawBlocks = () => {
    // Draw line
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.beginPath();
    context.strokeStyle = "#4285f4";
    context.lineWidth = 5;

    // ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    // ctx.fillStyle = "#000000";
    // ctx.beginPath();

    // console.log("Drawing blocks");
    blocks.forEach((block) => {
      if (block.type === "invoice_type") return;
      // exit if block not on current page index
      if (parseInt(block?.pageAnchor?.pageRefs[0]?.page) !== pageImageIndex)
        return;
      const vertices =
        block?.pageAnchor?.pageRefs[0]?.boundingPoly?.normalizedVertices;
      // console.log(vertices);
      if (typeof vertices === "undefined") return;

      const x1 = vertices[0].x * canvas.width;
      const y1 = vertices[0].y * canvas.height;
      const x2 = vertices[1].x * canvas.width;
      const y2 = vertices[1].y * canvas.height;
      const y3 = vertices[2].y * canvas.height;
      const width = x2 - x1;
      const height = y3 - y2;
      // console.log("block coords", { x1, y1, width, height });
      context.strokeRect(x1, y1, width, height);
    });
  };

  //   console.log(invoice_parser_data?.pages[0]?.image.content);

  //   convert base64 to image
  useEffect(() => {
    // // Draw image onto canvas
    // const render = () => {
    //   frameCount++;
    //   context.drawImage(image, 0, 0);
    //   animationFrameId = window.requestAnimationFrame(render);
    // };
  }, [canvasRef]);

  useEffect(() => {
    if (!image) return;
    if (!nextTicket) return;
    if (invoiceColRef.current) {
      const width = invoiceColRef.current.offsetWidth;
      const height = invoiceColRef.current.offsetHeight;
      const top = invoiceColRef.current.offsetTop;
      const left = invoiceColRef.current.offsetLeft;
      // console.log("Invoice col ref", { width, height, top, left });
      // drawBackgroundImage({ width, height, top, left });
      resizeCanvas();
      setResponsiveInvoiceSize({ width, height, top, left });
    }
  }, [invoiceColRef, windowDimensions, image]);

  //   useEffect(() => {
  //     fabric.Image.fromURL(image, function (oImg) {
  //       editor?.canvas.add(oImg);
  //     });
  //   }, [fabric, editor]);

  // Load images pages in sub collection of next ticket
  useEffect(() => {
    if (nextTicket) {
      setInvoiceParserData(nextTicket);
      getPageImages();
      setBlocks(nextTicket?.entities);
      setStockSearchResults([]);
      setVIN("");
    }
  }, [JSON.stringify(nextTicket)]);

  // Draw blocks on canvas for first image if loaded
  useEffect(() => {
    if (pageImages.length > 0) {
      // console.log("loading page image", pageImageIndex);
      const pageImage = pageImages.find(
        (pi) => pi.pagePosition === pageImageIndex
      );
      // const pageImage = pageImages[pageImageIndex];
      // console.log("current page", pageImage);
      const blobImgContent = pageImage?.content?.toString("base64");
      // console.log(blobImgContent);

      // console.log(blobImg);
      setImage(imageB64Prefix + blobImgContent);
      // setImageFromBlob(blobImg);
    }
  }, [JSON.stringify(pageImages), pageImageIndex]);

  useEffect(() => {
    if (pageImages.length > 0) {
      const width = invoiceColRef.current.offsetWidth;
      const height = invoiceColRef.current.offsetHeight;
      const top = invoiceColRef.current.offsetTop;
      const left = invoiceColRef.current.offsetLeft;
      // console.log("Invoice col ref", { width, height, top, left });
      drawBackgroundImage({ width, height, top, left });
    }
  }, [image, invoiceColRef, windowDimensions]);

  useEffect(() => {
    setImageErrorState(false);
  }, [pageImageIndex]);

  useEffect(() => {
    getAllTickets();
  }, []);

  useEffect(() => {
    getTicket();
  }, [allTickets]);

  useEffect(() => {
    getTicket();
  }, [queryCursor]);

  useEffect(() => {
    searchForVin("current");
    // Set checked: false for all blocks in manualCheckArray
    const manualCheckArrayTmp = {};
    if (blocks.length === 0) return;
    blocks.forEach((block) => {
      manualCheckArrayTmp[block.type] = false;
      // if (block.type === "line_item") {
      //   block.properties.forEach((lineItem, idx) => {
      //     manualCheckArrayTmp[lineItem.type][idx] = false;
      //   });
      // }
    });
    // Custom fields
    manualCheckArrayTmp["branch_desc"] = false;
    manualCheckArrayTmp["invoice_description"] = false;
    manualCheckArrayTmp["database"] = false;
    manualCheckArrayTmp["vehicle_stock_number"] = false;

    setManualCheckArray(manualCheckArrayTmp);
  }, [JSON.stringify(blocks)]);

  useEffect(() => {
    searchByVin(vin);
  }, [vin]);

  const renderTableData = (type, blocks, showNormalisedValue = false) => {
    if (!showNormalisedValue) {
      let color =
        type === "currency" &&
        blocks?.find((b) => b.type === type)?.mentionText === "USD"
          ? "red"
          : "green";

      return (
        <>
          {color === "red" ? (
            <HiInformationCircle color={color} size={20} />
          ) : null}
          {blocks?.find((b) => b.type === type)?.mentionText}
          <span style={{ marginRight: 5, float: "right" }}>
            <Button
              onClick={() =>
                copyToClipboard(
                  blocks
                    ?.find((b) => b.type === type)
                    ?.mentionText.replace(/\n/g, " ")
                )
              }
              size="sm"
              variant="light"
            >
              <HiClipboardCheck size={20} />
            </Button>
          </span>
        </>
      );
    } else if (showNormalisedValue) {
      return (
        <>
          {blocks?.find((b) => b.type === type)?.normalizedValue?.text}
          <span style={{ marginRight: 5, float: "right" }}>
            <Button
              onClick={() =>
                copyToClipboard(
                  blocks?.find((b) => b.type === type)?.normalizedValue?.text
                )
              }
              size="sm"
              variant="light"
            >
              <HiClipboardCheck size={20} />
            </Button>
          </span>
        </>
      );
    }
  };

  const renderCheckBox = (type) => {
    return (
      <InputGroup.Checkbox
        aria-label="Checkbox for selection"
        checked={manualCheckArray[type] || false}
        onChange={(e) => {
          handleToggleManualCheck(type, e.target.checked);
        }}
      />
    );
  };

  return (
    <>
      <ToastContainer />
      {/* <Button
        style={{ borderRadius: 100, position: "absolute", top: 10, right: 10 }}
        variant="light"
        size="sm"
        onClick={handleAddNewInvoice}
      >
        <HiOutlinePlusCircle color="green" size={50} />
      </Button> */}
      <Row
        style={{ borderRadius: 100, position: "absolute", top: 10, right: 10 }}
      >
        <Col>
          <Alert variant="info" size="small">
            <Table
              size="sm"
              hover
              style={{ fontSize: 12, borderStyle: "hidden" }}
            >
              <thead>
                <tr>
                  <th>Ticket ID</th>
                  <th>Status</th>
                  <th>Created</th>
                  <th>Position</th>
                </tr>
              </thead>
              <tbody>
                <tr hidden={!nextTicket}>
                  <td>{nextTicket?.id}</td>
                  <td>{nextTicket?.ticket?.status}</td>
                  <td>
                    {dayjs
                      .unix(nextTicket?.ticket?.created)
                      .format("DD-MM-YYYY")}
                  </td>
                  <td>
                    {queryCursor} / {totalTickets}
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <ButtonGroup>
                      <Button
                        size="sm"
                        variant="outline-dark"
                        onClick={() => {
                          pickUpTicket(nextTicket.id);
                        }}
                      >
                        Pick-up
                      </Button>
                      <Button
                        size="sm"
                        variant="outline-dark"
                        onClick={handleSaveDataEntry}
                      >
                        Save
                      </Button>
                      <Button size="sm" variant="outline-dark">
                        Close
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <ButtonGroup>
                      <Button
                        size="sm"
                        variant="outline-primary"
                        onClick={() => handleQueryCursorIncremet(-1)}
                        disabled={queryCursor <= 0}
                      >
                        {" "}
                        {isLoadingNextTicket || isLoadingTickets ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Previous Ticket"
                        )}
                      </Button>
                      <Button
                        size="sm"
                        variant="outline-success"
                        onClick={() => handleQueryCursorIncremet(1)}
                        disabled={queryCursor >= totalTickets}
                      >
                        {isLoadingNextTicket || isLoadingTickets ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Next Ticket"
                        )}
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Alert>
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Col></Col>
        <Col>
          <Alert variant="warning" size="small" hidden={!pickedUpTicket}>
            You have picked up this ticket, please complete it and close
          </Alert>
        </Col>
        <Col></Col>
      </Row>

      <Row style={{ marginTop: 65 }}>
        <Col>
          <Row>
            <Col>Invoice</Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <ButtonGroup>
                <Button
                  size="sm"
                  variant="outline-warning"
                  onClick={() => {
                    if (pageImageIndex > 0) {
                      setPageImageIndex(pageImageIndex - 1);
                    }
                  }}
                >
                  Previous Page
                </Button>
                <Button variant="outline-success" size="sm" disabled>
                  {pageImageIndex + 1} / {pageImages.length}
                </Button>
                <Button
                  size="sm"
                  variant="outline-success"
                  onClick={() => {
                    if (pageImageIndex < pageImages.length - 1) {
                      setPageImageIndex(pageImageIndex + 1);
                    }
                  }}
                >
                  Next Page
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col ref={invoiceColRef}>
              {isPageImagesLoading ? <Spinner animation="border" /> : null}
              {imageErrorState ? (
                <Alert variant="danger">Error loading image</Alert>
              ) : null}
              {/* <Image
              src={`data:image/png;base64,${invoice_parser_data?.pages[0]?.image.content}`}
              alt="invoice"
              fluid
            /> */}
              {/* <InvoiceCanvasComponent draw={drawBlocks} height={600} /> */}
              <canvas style={{ width: "100%" }} ref={canvasRef}></canvas>
              {/* <FabricJSCanvas className="sample-canvas" onReady={onReady} /> */}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>Data</Col>
          </Row>
          <Row>
            <Table size="sm" hover striped responsive style={{ fontSize: 10 }}>
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Value</th>
                  <th>Normalized Value</th>
                  <th>Check</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "green", color: "white" }}>
                  <td colSpan={4}>AURA FIELDS IN ORDER</td>
                </tr>
                <tr>
                  <td
                    colSpan={4}
                    style={{ backgroundColor: "darkgray", color: "white" }}
                  >
                    General fields
                  </td>
                </tr>
                <tr>
                  <td>Invoice from:</td>
                  <td>{renderTableData("supplier_name", blocks)}</td>
                  <td></td>
                  <td>{renderCheckBox("supplier_name")}</td>
                </tr>
                <tr>
                  <td>Invoice date:</td>
                  <td>{renderTableData("invoice_date", blocks)}</td>
                  <td>{renderTableData("invoice_date", blocks, true)}</td>
                  <td>{renderCheckBox("invoice_date")}</td>
                </tr>
                <tr>
                  <td>Invoice #:</td>
                  <td>{renderTableData("invoice_id", blocks)}</td>
                  <td></td>
                  <td>{renderCheckBox("invoice_id")}</td>
                </tr>
                <tr>
                  <td>Amount:</td>
                  <td>{renderTableData("total_amount", blocks)}</td>
                  <td>{renderTableData("total_amount", blocks, true)}</td>
                  <td>{renderCheckBox("total_amount")}</td>
                </tr>
                <tr>
                  <td>Branch:</td>
                  <td>
                    <i>
                      {(stockSearchResults.length > 0 &&
                        stockSearchResults[0]?.branch_desc) ||
                        (loadingVehicle && (
                          <Spinner animation="border" size="sm" />
                        )) ||
                        "Not found"}
                    </i>
                    <span style={{ marginRight: 5, float: "right" }}>
                      <Button
                        onClick={() =>
                          copyToClipboard(
                            stockSearchResults.length > 0 &&
                              stockSearchResults[0]?.branch_desc
                          )
                        }
                        size="sm"
                        variant="light"
                      >
                        <HiClipboardCheck size={20} />
                      </Button>
                    </span>
                  </td>
                  <td></td>
                  <td>{renderCheckBox("branch_desc")}</td>
                </tr>
                <tr>
                  <td>Invoice description:</td>
                  <td>
                    {vin || "Not found"}
                    <span style={{ marginRight: 5, float: "right" }}>
                      <Button
                        onClick={() => copyToClipboard(vin)}
                        size="sm"
                        variant="light"
                      >
                        <HiClipboardCheck size={20} />
                      </Button>
                    </span>
                  </td>
                  <td></td>
                  <td>{renderCheckBox("invoice_description")}</td>
                </tr>
                <tr>
                  <td>Currency:</td>
                  <td>{renderTableData("currency", blocks)}</td>
                  <td></td>
                  <td>{renderCheckBox("currency")}</td>
                </tr>
                <tr>
                  <td>Due date:</td>
                  <td>{renderTableData("due_date", blocks)}</td>
                  <td>{renderTableData("due_date", blocks, true)}</td>
                  <td>{renderCheckBox("due_date")}</td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{ backgroundColor: "darkgray", color: "white" }}
                  >
                    Line items
                  </td>
                </tr>
                {blocks?.length > 0 &&
                  blocks?.map((lineItem, idx) => {
                    if (lineItem.type !== "line_item") return null;
                    const lineItemData = lineItem.properties;
                    return (
                      <>
                        <tr key={idx}>
                          <td>Line narration:</td>
                          <td>
                            {renderTableData(
                              "line_item/description",
                              lineItemData
                            )}
                          </td>
                          <td></td>
                        </tr>
                        <tr key={idx + "_1"}>
                          <td>Line amount:</td>
                          <td>
                            {renderTableData("line_item/amount", lineItemData)}
                          </td>
                          <td>
                            {renderTableData(
                              "line_item/amount",
                              lineItemData,
                              true
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                <tr style={{ backgroundColor: "green", color: "white" }}>
                  <td colSpan={3}>ALL FIELDS</td>
                </tr>
                {invoice_parser_data?.entities
                  ?.sort((a, b) => {
                    // Sort type alphabetically
                    if (a.type < b.type) {
                      return -1;
                    }
                    if (a.type > b.type) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((block, idx) => {
                    let blockData = block;
                    if (block.type === "line_item") {
                      blockData = block.properties;
                      return blockData.map((lineItem, jdx) => {
                        return (
                          <tr key={`${idx}-${jdx}`}>
                            <td>{lineItem.type}</td>
                            <td>
                              <span style={{ marginRight: 5 }}>
                                {lineItem.mentionText}
                              </span>
                              <span style={{ marginRight: 5, float: "right" }}>
                                <Button
                                  onClick={() =>
                                    copyToClipboard(lineItem.mentionText)
                                  }
                                  size="sm"
                                  variant="light"
                                >
                                  <HiClipboardCheck size={20} />
                                </Button>
                              </span>
                            </td>
                            <td>
                              <span style={{ marginRight: 5 }}>
                                {lineItem.normalizedValue?.text}
                              </span>
                              <span style={{ marginRight: 5, float: "right" }}>
                                <Button
                                  onClick={() =>
                                    copyToClipboard(
                                      lineItem.normalizedValue?.text
                                    )
                                  }
                                  size="sm"
                                  variant="light"
                                >
                                  <HiClipboardCheck size={20} />
                                </Button>
                              </span>
                            </td>
                          </tr>
                        );
                      });
                    } else {
                      return (
                        <tr key={`${idx}`}>
                          <td>{blockData.type}</td>
                          <td>
                            <span style={{ marginRight: 5 }}>
                              {blockData.mentionText}
                            </span>
                            <span style={{ marginRight: 5, float: "right" }}>
                              <Button
                                onClick={() =>
                                  copyToClipboard(blockData.mentionText)
                                }
                                size="sm"
                                variant="light"
                              >
                                <HiClipboardCheck size={20} />
                              </Button>
                            </span>
                          </td>
                          <td>
                            <span style={{ marginRight: 5 }}>
                              {blockData.normalizedValue?.text}
                            </span>
                            <span style={{ marginRight: 5, float: "right" }}>
                              <Button
                                onClick={() =>
                                  copyToClipboard(
                                    blockData.normalizedValue?.text
                                  )
                                }
                                size="sm"
                                variant="light"
                              >
                                <HiClipboardCheck size={20} />
                              </Button>
                            </span>
                          </td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </Table>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>Selections</Col>
          </Row>
          <Row>
            <Col>
              <Table size="sm" style={{ border: "none" }}>
                <tbody>
                  <tr>
                    <td>Vehicle Stock Number</td>
                    <td>
                      <InputGroup className="mb-3">
                        <FormControl
                          aria-label="vehicle_stock_number"
                          value={
                            (stockSearchResults.length > 0 &&
                              stockSearchResults[0]?.VEHStockNumber) ||
                            ""
                          }
                        />
                        <InputGroup.Append>
                          {renderCheckBox("vehicle_stock_number")}
                        </InputGroup.Append>
                      </InputGroup>
                    </td>
                  </tr>
                  <tr>
                    <td>Database</td>
                    <td>
                      <InputGroup className="mb-3">
                        <FormControl
                          aria-label="database"
                          value={
                            (stockSearchResults.length > 0 &&
                              stockSearchResults[0]?.db) ||
                            ""
                          }
                        />
                        <InputGroup.Append>
                          {renderCheckBox("database")}
                        </InputGroup.Append>
                      </InputGroup>
                    </td>
                  </tr>
                  <tr>
                    <td>Branch</td>
                    <td>
                      <InputGroup className="mb-3">
                        <FormControl
                          aria-label="branch_desc"
                          value={
                            (stockSearchResults.length > 0 &&
                              stockSearchResults[0]?.branch_desc) ||
                            ""
                          }
                        />
                        <InputGroup.Append>
                          {renderCheckBox("branch_desc")}
                        </InputGroup.Append>
                      </InputGroup>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title>
                    Inventory Search Results
                    {loadingVehicle ? (
                      <Spinner animation="border" size="sm" />
                    ) : null}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    {/* <pre>{JSON.stringify(stockSearchResults, null, 2)}</pre> */}
                    {stockSearchResults.map((veh, idx) => {
                      return (
                        <Col key={idx}>
                          <Card>
                            <Card.Header>
                              <Card.Title>
                                Found:{" "}
                                {`${veh.VEHYear} ${veh.VEHMake} ${veh.VEHModel}`}
                              </Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <Table striped size="sm">
                                <thead>
                                  <tr>
                                    <th>Field</th>
                                    <th>Value</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(veh).map((key, idx) => {
                                    if (key === "Purchase_Date") return;
                                    return (
                                      <tr key={idx}>
                                        <td>{key}</td>
                                        <td>{veh[key]}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>

                              <Row>
                                <Col>
                                  <Button
                                    disabled
                                    variant="outline-success"
                                    size="sm"
                                  >
                                    View Vehicle
                                  </Button>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default AccountsPayabalInvoiceAIComponent;
