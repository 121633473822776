import { useEffect, useRef, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Table,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import BackdropComponent from "../../BackdropComponent";
import dayjs from "dayjs";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  setDoc,
  doc,
  orderBy,
  limit,
} from "firebase/firestore";
import { useFunctions, useUser } from "reactfire";
import NumberFormat from "react-number-format";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import * as cheerio from "cheerio";

// Extend dayjs with custom plugins
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import userEvent from "@testing-library/user-event";
import { Check, ZoomIn } from "react-bootstrap-icons";
import { BsClockHistory } from "react-icons/bs";
import { BiSearchAlt, BiSearchAlt2 } from "react-icons/bi";
import { HiClipboardCheck } from "react-icons/hi";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

const dgCompanies = [
  "Dutton Retail 1",
  "Dutton Retail 2",
  "Dutton Garage",
  "Dutton Wholesale",
  "SYCF",
];

const stickyTableHeaderStyle = {
  position: "sticky",
  margin: "0 0 0 0",
  top: -26,
  backgroundColor: "#566573",
  color: "white",
  fontSize: 12,
  zIndex: 100,
};

const ledgerMap = {
  SN: "1-13100",
};

const XeroDataEntryComponent = () => {
  const [supplier, setSupplier] = useState("");
  const [xeroUrl, setXeroUrl] = useState("");
  const [xeroInvoiceJSON, setXeroInvoiceJSON] = useState([]);
  const [xeroInvoiceJSONBak, setXeroInvoiceJSONBak] = useState([]);
  const [loadingInvoiceData, setLoadingInvoiceData] = useState(false);
  const [vehicleInfoIndex, setVehicleInfoIndex] = useState([]);
  const [eclipseMetadata, setEclipseMetadata] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedDbName, setSelectedDbName] = useState("");
  const [selectedDbClientId, setSelectedDbClientId] = useState("");
  const [logger, setLogger] = useState([]);
  const [errors, setErrors] = useState([]);
  const [vehiclesFound, setVehiclesFound] = useState([]);
  const [eventTimestamps, setEventTimestamps] = useState({});
  const [xeroId, setXeroId] = useState("");
  const [highlightedRowArray, setHighlightedRowArray] = useState([]);

  //   HISTORY MODAL CONTROL STATES
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [myHistory, setMyHistory] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);

  //   VEH SEARCH CONTROL STATES
  const [showVehSearchModal, setShowVehSearchModal] = useState(false);
  const [vehSearchResults, setVehSearchResults] = useState([]);
  const [vehSearchLoading, setVehSearchLoading] = useState(false);
  const [searchVin, setSearchVin] = useState("");
  const [searchStockNumber, setSearchStockNumber] = useState("");
  const [searchRego, setSearchRego] = useState("");
  const [searchVinTrunc, setSearchVinTrunc] = useState("");
  const [searchIndex, setSearchIndex] = useState(null);
  const [
    selectedVehicleFromSearchResults,
    setSelectedVehicleFromSearchResults,
  ] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  // Ticket state
  const [pickedUpTicket, setPickedUpTicket] = useState(false);
  const [nextTicket, setNextTicket] = useState(null);
  const [isLoadingNextTicket, setIsLoadingNextTicket] = useState(false);
  const [totalTickets, setTotalTickets] = useState(0);
  const [queryCursor, setQueryCursor] = useState(0);
  const [linkFromEmail, setLinkFromEmail] = useState("");

  //   Hooks
  const functions = useFunctions();
  const firestore = getFirestore();
  const user = useUser();

  //   Refs
  const logRef = useRef(null);
  const errorLogRef = useRef(null);

  const handleCloseHistoryModal = () => setShowHistoryModal(false);

  const handleShowPDF = () => {
    const url = `https://in.xero.com/api/${xeroId}/getPdf`;
    window.open(url, "_blank");
  };

  const handleOpenHistoryModal = async () => {
    setShowHistoryModal(true);
    setLoadingHistory(true);
    // Search for history in database
    await getMyHistory();
    setLoadingHistory(false);
  };

  const handleCloseVehSearchModal = () => setShowVehSearchModal(false);

  const handleOpenVehSearchModal = (index) => {
    setSearchIndex(index);
    setShowVehSearchModal(true);
  };

  const searchForVehicles = async (mode) => {
    console.log("Searching for vehicles", mode);
    setVehSearchLoading(true);
    let searchResults = [];
    if (mode === "vin") {
      console.log(`Searching for VIN: ${searchVin}`);
      const vinRef = collection(firestore, "inventory-summary");
      const vinQuery = query(vinRef, where("VEHVIN", "==", searchVin));
      const vinQuerySnapshot = await getDocs(vinQuery);
      vinQuerySnapshot.forEach((doc) => {
        searchResults.push({ id: doc.id, ...doc.data() });
      });
    } else if (mode === "stock") {
      console.log(`Searching for Stock Number: ${searchStockNumber}`);
      const stockRef = collection(firestore, "inventory-summary");
      const stockQuery = query(
        stockRef,
        where("VEHStockNumber", "==", parseInt(searchStockNumber))
      );
      const stockQuerySnapshot = await getDocs(stockQuery);
      stockQuerySnapshot.forEach((doc) => {
        searchResults.push({ id: doc.id, ...doc.data() });
      });
    } else if (mode === "rego") {
      console.log(`Searching for Rego: ${searchRego}`);
      const regoRef = collection(firestore, "inventory-summary");
      const regoQuery = query(regoRef, where("VEHRegn", "==", searchRego));
      const regoQuerySnapshot = await getDocs(regoQuery);
      regoQuerySnapshot.forEach((doc) => {
        searchResults.push({ id: doc.id, ...doc.data() });
      });
    } else if (mode === "vintrunc") {
      console.log(`Searching for VIN Trunc: ${searchVinTrunc}`);
      const vinTruncRef = collection(firestore, "inventory-summary");
      const vinTruncQuery = query(
        vinTruncRef,
        where("VINTrunc", "==", searchVinTrunc)
      );
      const vinTruncQuerySnapshot = await getDocs(vinTruncQuery);
      vinTruncQuerySnapshot.forEach((doc) => {
        searchResults.push({ id: doc.id, ...doc.data() });
      });
    }
    console.log(`Found ${searchResults.length} vehicles`);
    console.log(searchResults);
    setVehSearchLoading(false);
    setVehSearchResults(searchResults);
  };

  const handleSetVehicleFromSearchResult = (vehicle, index) => {
    setSelectedVehicleFromSearchResults(vehicle);
    const tmpVehiclesFound = [...vehiclesFound];
    // Check if vehicle is already in the list
    const vehicleExists = tmpVehiclesFound.find(
      (veh) => veh.VEHStockNumber === vehicle.VEHStockNumber
    );
    if (vehicleExists) {
      toast.error("Vehicle already in list, please select from main screen");
      return;
    }
    tmpVehiclesFound.push(vehicle);
    setVehiclesFound(tmpVehiclesFound);
    // Set the selected stock number for index
    const vehicleInfo = [...vehicleInfoIndex];
    vehicleInfo[index] = {
      ...vehicleInfo[index],
      selectedStockNumber: vehicle.VEHStockNumber.toString(),
      selectedStockBranchId: vehicle.branch_id,
      manualSelection: true,
      referenceType: "SN", // Stock Number
    };
    setVehicleInfoIndex(vehicleInfo);
  };

  const handleClearSearchFields = () => {
    setSearchVin("");
    setSearchStockNumber("");
    setSearchRego("");
    setSearchVinTrunc("");
    setVehSearchResults([]);
  };

  const handleCompanyChange = (selected) => {
    setSelectedCompany(selected);
  };

  const clearStates = () => {
    setLogger([]);
    setErrors([]);
    setVehicleInfoIndex([]);
    setSelectedCompany([]);
    setSelectedDbClientId("");
    setSelectedDbName("");
    setXeroInvoiceJSON([]);
    setVehiclesFound([]);
    setSelectedVehicleFromSearchResults(null);
    setVehSearchResults([]);
    setHighlightedRowArray([]);
  };

  const resetForm = () => {
    setXeroUrl("");
    setXeroId("");
    clearStates();
  };

  const isStatesEmpty = () => {
    // Log all states with labels
    console.log("Logger: ", logger);
    console.log("Errors: ", errors);
    console.log("Vehicle Info Index: ", vehicleInfoIndex);
    console.log("Selected Company: ", selectedCompany);
    console.log("Selected DB Client ID: ", selectedDbClientId);
    console.log("Selected DB Name: ", selectedDbName);
    console.log("Xero Invoice JSON: ", xeroInvoiceJSON);
    console.log("Vehicles Found: ", vehiclesFound);

    if (
      logger.length === 0 &&
      errors.length === 0 &&
      vehicleInfoIndex.length === 0 &&
      selectedCompany.length === 0 &&
      selectedDbClientId === "" &&
      selectedDbName === "" &&
      xeroInvoiceJSON.length === 0 &&
      vehiclesFound.length === 0
    ) {
      return true;
    }
    return false;
  };

  const updateLogger = (message) => {
    const msgWithTime = `${dayjs().format("HH:mm:ss")} - ${message}`;
    setLogger((prev) => [...prev, msgWithTime]);
  };

  const updateErrors = (message) => {
    const msgWithTime = `${dayjs().format("HH:mm:ss")} - ${message}`;
    setErrors((prev) => [...prev, msgWithTime]);
  };

  const updateEventTimestamps = (event) => {
    setEventTimestamps((prev) => ({ ...prev, ...event }));
  };

  const getInvoiceTotals = (invoiceData = xeroInvoiceJSON) => {
    let totalAmount = 0;
    let totalGST = 0;
    console.log("id", invoiceData);
    for (let i = 0; i < invoiceData.length; i++) {
      const row = invoiceData[i];
      console.log(row);
      if (
        row.UnitAmount === undefined ||
        row.TaxAmount === undefined ||
        row.UnitAmount === null ||
        row.TaxAmount === null ||
        row.UnitAmount === "" ||
        row.TaxAmount === ""
      ) {
        continue;
      }
      totalAmount += parseFloat(row.UnitAmount);
      totalGST += parseFloat(row.TaxAmount);
    }
    totalAmount = totalAmount + totalGST;
    console.log(`Total Amount: ${totalAmount}`);
    console.log(`Total GST: ${totalGST}`);
    return { totalAmount, totalGST };
  };

  //   Get the last element from the description that contains the job number
  const getLastElementFromDescription = (description) => {
    if (description.length === 0) {
      console.log("No description found");
      return false;
    }
    if (description.includes("Job")) {
      // For lines that contain a job number
      const descriptionArray = description.split(" ");
      const lastElement = descriptionArray[descriptionArray.length - 1];
      console.log(`Last element: ${lastElement}`);
      return lastElement;
    } else {
      return false;
    }
  };

  const submitInvoiceDataToActionQueue = async () => {
    if (xeroInvoiceJSON.length === 0) {
      //   updateErrors(`No invoice data loaded`);
      toast.error("No invoice data loaded");
      return;
    }

    if (selectedDbClientId === "" || selectedDbName === "") {
      //   updateErrors(`Cannot submit invoice data without a company selected`);
      toast.error("Cannot submit invoice data without a company selected");
      return;
    }

    if (vehicleInfoIndex.length === 0) {
      //   updateErrors(`No vehicle information found, please resolve manually`);
      toast.error("No vehicle information found, please resolve manually");
      return;
    }
    setIsSaving(true);

    let totalPayload = {};
    let totalRows = 0;

    for (let i = 0; i < xeroInvoiceJSON.length; i++) {
      const invoiceRow = { ...xeroInvoiceJSON[i] };
      const vehicleRow = { ...vehicleInfoIndex[i] };
      const rowName = `row_${totalRows}`;

      //   Check if row is a job description row
      const isJobRow = invoiceRow.Description.includes("Job");

      if (!isJobRow) {
        // updateLogger(`Row ${i + 1} is not a job row, skipping`);
        continue;
      }

      //   Get next line item row
      const nextInvoiceRow = { ...xeroInvoiceJSON[i + 1] };

      if (nextInvoiceRow === undefined) {
        console.log("No next invoice row");
        break;
      }

      //   Check if the next row is a fuel levy
      const isFuelLevy = nextInvoiceRow.Description.includes("Fuel Levy");
      if (isFuelLevy) {
        // Add the fuel levy to the invoice amount
        invoiceRow.UnitAmount =
          parseFloat(nextInvoiceRow.UnitAmount) +
          parseFloat(invoiceRow.UnitAmount);
        invoiceRow.TaxAmount =
          parseFloat(nextInvoiceRow.TaxAmount) +
          parseFloat(invoiceRow.TaxAmount);
        // updateLogger(`Fuel levy found, adding to invoice amount`);
      }

      const invoiceDataEntryRow = {
        InvoiceNumber: invoiceRow.InvoiceNumber,
        InvoiceDate: dayjs(invoiceRow.InvoiceDate, "DD MMM YYYY").format(
          "YYYY-MM-DD"
        ),
        DueDate: dayjs(invoiceRow.DueDate, "DD MMM YYYY").format("YYYY-MM-DD"),

        Amount: invoiceRow.UnitAmount + invoiceRow.TaxAmount,
        GST: invoiceRow.TaxAmount,
        Narration: invoiceRow.Description,
        Reference: vehicleRow.selectedStockNumber,
        ReferenceType: vehicleRow.referenceType,

        AccountCode: ledgerMap[vehicleRow.referenceType],
        AccountId: -9999,

        EclipseClientId: selectedDbClientId,
        EclipseDBName: selectedDbName,
        EclipseBranchID: vehicleRow.selectedStockBranchId,
        createdAt: dayjs().format("YYYY-MM-DD"),
        createdBy: user.data.displayName,
        createdByUid: user.data.uid,
        createdTimestamp: dayjs().unix(),
      };

      //   console.log("invrow", invoiceRow);
      //   console.log("vehrow", vehicleRow);
      console.log("index", i);
      console.log("derow", invoiceDataEntryRow);
      const payload = {
        invoiceDataEntryRow,
        vehicleRow,
        invoiceRow,
        createdAt: dayjs().format("YYYY-MM-DD"),
        createdBy: user.data.displayName,
        createdByUid: user.data.uid,
        createdTimestamp: dayjs().unix(),
        rowOnInvoice: i,
      };
      totalRows++;
      totalPayload[rowName] = payload;
    }

    // Set event timestamp after invoice data is loaded
    const eTSPayload = {
      invoiceDataSubmitted: dayjs().unix(),
      invoiceDataSubmittedBy: user.data.displayName,
      invoiceDataSubmittedByUid: user.data.uid,
    };

    const totalTimeToSubmit =
      eTSPayload.invoiceDataSubmitted - eventTimestamps.invoiceDataLoaded;

    const ETS = { ...eTSPayload, ...eventTimestamps, totalTimeToSubmit };

    const dataEntryMetaData = {
      invoiceIdentifier: xeroUrl,
      supplier: supplier,
      supplierSystem: "Xero",
      supplierSystemId: 10,
    };

    const { totalAmount, totalGST } = getInvoiceTotals();

    totalPayload.totalAmount = totalAmount;
    totalPayload.totalGST = totalGST;

    totalPayload.totalRows = totalRows;
    totalPayload = {
      ...totalPayload,
      actioned: false,
      actionStatus: null,
      actionedTimestamp: null,
      action: "ACCOUNTS_PAYABLE_INVOICE_CREATION",
      ...dataEntryMetaData,
      ...ETS,
      invoiceUniqueId: xeroId,
      ticket: nextTicket,
    };
    // console.log("payload", totalPayload);

    const actionQueueRef = collection(firestore, "data-entry-queued-actions");
    const actionQueueDoc = await addDoc(actionQueueRef, {
      ...totalPayload,
    });

    await closeTicket(nextTicket);

    toast.success(
      `Invoice data submitted to action queue with ID: ${actionQueueDoc.id}`
    );
    resetForm();
    //   console.log(actionQueueDoc);
    setIsSaving(false);
  };

  // START Ticket management functions
  const pickUpTicket = async (ticket) => {
    if (ticket === null) {
      return;
    }
    const tmpTicket = { ...ticket.ticket };
    tmpTicket.pickedUp = true;
    tmpTicket.pickedUpBy = user.data.uid;
    tmpTicket.pickedUpTimestamp = dayjs().unix();
    tmpTicket.pickedUpByEmail = user.data.email;
    tmpTicket.pickedUpByName = user.data.displayName;

    await setDoc(
      doc(firestore, "invoices_email", ticket.id),
      {
        ticket: { ...tmpTicket },
      },
      { merge: true }
    );
  };

  const closeTicket = async (ticket) => {
    if (ticket === null) {
      return;
    }
    const tmpTicket = { ...ticket.ticket };
    tmpTicket.closed = true;
    tmpTicket.closedBy = user.data.uid;
    tmpTicket.closedTimestamp = dayjs().unix();
    tmpTicket.closedByEmail = user.data.email;
    tmpTicket.closedByName = user.data.displayName;
    tmpTicket.closedByUid = user.data.uid;
    tmpTicket.status = "closed";

    await setDoc(
      doc(firestore, "invoices_email", ticket.id),
      {
        ticket: { ...tmpTicket },
      },
      { merge: true }
    );
  };

  const skipThisTicket = async (ticket) => {
    if (ticket === null) {
      return;
    }
    // const tmpTicket = { ...ticket.ticket };
    // tmpTicket.skip = true;
    // tmpTicket.skipBy = user.data.uid;
    // tmpTicket.skipTimestamp = dayjs().unix();
    // tmpTicket.skipByEmail = user.data.email;
    // tmpTicket.skipByName = user.data.displayName;

    // await setDoc(
    //   doc(firestore, "invoices_email", ticket.id),
    //   {
    //     ticket: { ...tmpTicket },
    //   },
    //   { merge: true }
    // );
    ticket.ticket.skip = true;

    await closeTicket(ticket);
  };

  const getNextTicket = async () => {
    // console.log("getting  ticket");
    // console.log(queryCursor);
    setIsLoadingNextTicket(true);
    const ticketRef = collection(firestore, "invoices_email");
    const q = query(
      ticketRef,
      // where("ticket.pickedUp", "==", false),
      where("ticket.closed", "==", false),
      where("ticket.status", "==", "open"),
      orderBy("ticket.created", "asc"),
      limit(1)
    );

    const querySnapshot = await getDocs(q);
    let ticket = null;
    querySnapshot.forEach((doc) => {
      ticket = { id: doc.id, ...doc.data() };
    });
    if (ticket === null) {
      setNextTicket(null);
      setIsLoadingNextTicket(false);
      toast.info("No more tickets to process");
      return;
    }
    pickUpTicket(ticket);
    setNextTicket(ticket);
    setIsLoadingNextTicket(false);
  };

  // END Ticket management functions

  const searchForVinInDescription = async (description) => {
    if (description.length === 0) {
      return false;
    }
    const vinRegex = /[A-HJ-NPR-Z0-9]{17}/g;
    const vin = description.match(vinRegex);
    if (vin === null) {
      return false;
    }
    console.log(vin);

    return vin[0];
  };

  const searchForReferences = async () => {
    if (xeroInvoiceJSON.length === 0) {
      console.log("No invoice data found");
      return;
    }

    updateLogger("Looking for registration numbers in invoice data");
    const regoFoundIndex = [...vehicleInfoIndex];
    const globalVehicleFoundList = [];
    setVehiclesFound(globalVehicleFoundList);
    // Loop through the description field to find the VIN
    for (let i = 0; i < xeroInvoiceJSON.length; i++) {
      //  Get last element from description
      const lE = getLastElementFromDescription(xeroInvoiceJSON[i].Description);
      if (lE === false) {
        updateLogger(`Element does not have a reference on line ${i + 1}`);
        continue;
      } else {
        updateLogger(`A reference number was found on line ${i + 1}: ${lE}`);
        // For a large (17) reference string search for full VIN
        if (lE.length === 17) {
          updateLogger(`Found a VIN, searching for vehicle`);
          const vinRef = collection(firestore, "inventory-summary");
          const vinQuery = query(vinRef, where("VEHVIN", "==", lE));

          const vinQuerySnapshot = await getDocs(vinQuery);
          const vehiclesFound = [];
          if (vinQuerySnapshot.size === 0) {
            // No VIN found
            updateLogger(`No vehicles found with VIN: ${lE}`);
            regoFoundIndex[i] = {
              ...regoFoundIndex[i],
              vin: lE,
              vinFound: false,
              vehiclesFound,
              selectedStockNumber: null,
              selectedStockBranchId: null,
            };
          } else {
            // VIN found
            vinQuerySnapshot.forEach((doc) => {
              updateLogger(`1 vehicle found in ${doc.data().branch_desc}`);
              vehiclesFound.push({ id: doc.id, ...doc.data() });
            });
            updateLogger(`Found a total of ${vehiclesFound.length} vehicle(s)`);

            if (vehiclesFound.length > 1) {
              regoFoundIndex[i] = {
                ...regoFoundIndex[i],
                vin: lE,
                vinFound: true,
                vehiclesFound,
                selectedStockNumber: null,
                selectedStockBranchId: null,
              };
            } else if (vehiclesFound.length === 1) {
              regoFoundIndex[i] = {
                ...regoFoundIndex[i],
                vin: lE,
                vinFound: true,
                vehiclesFound,
                selectedStockNumber: vehiclesFound[0].VEHStockNumber,
                referenceType: "SN", // Stock Number
                selectedStockBranchId: vehiclesFound[0].branch_id,
              };
            }
          }

          globalVehicleFoundList.push(...vehiclesFound);
        } else {
          // Check if the last element is a registration
          // Search for the last element in the database
          // If found, set the registration number

          updateLogger(`Scanning for registrations in database`);
          updateLogger(
            "Searching for vehicles with registration number: " + lE
          );
          const regoRef = collection(firestore, "inventory-summary");
          const q = query(regoRef, where("VEHRegn", "==", lE));
          const querySnapshot = await getDocs(q);
          const vehiclesFound = [];
          if (querySnapshot.size === 0) {
            // No rego found
            updateLogger(`No vehicles found with registration number: ${lE}`);
            //   Check for VIN (Truncated)
            updateLogger(`Checking for a match on VIN`);
            const vinRef = collection(firestore, "inventory-summary");
            const vinQuery = query(vinRef, where("VINTrunc", "==", lE));
            const vinQuerySnapshot = await getDocs(vinQuery);
            if (vinQuerySnapshot.size === 0) {
              // No VIN found
              updateLogger(`No vehicles found with VIN: ${lE}`);
              regoFoundIndex[i] = {
                ...regoFoundIndex[i],
                rego: lE,
                regoFound: false,
                vehiclesFound,
                vinFound: false,
                selectedStockNumber: null,
                selectedStockBranchId: null,
              };
            } else {
              // VIN found
              vinQuerySnapshot.forEach((doc) => {
                updateLogger(`1 vehicle found in ${doc.data().branch_desc}`);
                vehiclesFound.push({ id: doc.id, ...doc.data() });
              });
              updateLogger(
                `Found a total of ${vehiclesFound.length} vehicle(s)`
              );
              if (vehiclesFound.length > 1) {
                regoFoundIndex[i] = {
                  ...regoFoundIndex[i],
                  vin: lE,
                  vinFound: true,
                  vehiclesFound,
                  selectedStockNumber: null,
                  selectedStockBranchId: null,
                };
              } else if (vehiclesFound.length === 1) {
                regoFoundIndex[i] = {
                  ...regoFoundIndex[i],
                  vin: lE,
                  vinFound: true,
                  vehiclesFound,
                  selectedStockNumber: vehiclesFound[0].VEHStockNumber,
                  referenceType: "SN", // Stock Number
                  selectedStockBranchId: vehiclesFound[0].branch_id, // Stock Branch ID
                };
              }
            }
          } else {
            querySnapshot.forEach((doc) => {
              updateLogger(`1 vehicle found in ${doc.data().branch_desc}`);
              vehiclesFound.push({ id: doc.id, ...doc.data() });
            });
            updateLogger(`Found a total of ${vehiclesFound.length} vehicle(s)`);
            if (vehiclesFound.length > 1) {
              regoFoundIndex[i] = {
                ...regoFoundIndex[i],
                vin: lE,
                vinFound: true,
                vehiclesFound,
                selectedStockNumber: null,
                selectedStockBranchId: null,
              };
            } else if (vehiclesFound.length === 1) {
              regoFoundIndex[i] = {
                ...regoFoundIndex[i],
                vin: lE,
                vinFound: true,
                vehiclesFound,
                selectedStockNumber: vehiclesFound[0].VEHStockNumber,
                referenceType: "SN", // Stock Number
                selectedStockBranchId: vehiclesFound[0].branch_id, // Stock Branch ID
              };
            }
          }
          globalVehicleFoundList.push(...vehiclesFound);
        }
      }
    }

    //   If no vehicles are found for the line that is a JOB line, search for the VIN in the whole description
    for (let i = 0; i < regoFoundIndex.length; i++) {
      const vehicle = regoFoundIndex[i];
      const index = i;

      if (
        !vehicle?.regoFound &&
        !vehicle?.vinFound &&
        vehicle?.vehiclesFound?.length === 0
      ) {
        const vinFound = await searchForVinInDescription(
          xeroInvoiceJSON[index].Description
        );
        if (vinFound) {
          updateLogger(`Found VIN in description: ${vinFound}`);
          // Search for the VIN in the database
          const vinRef = collection(firestore, "inventory-summary");
          const vinQuery = query(vinRef, where("VEHVIN", "==", vinFound));

          const vinQuerySnapshot = await getDocs(vinQuery);
          const vehiclesFound = [];
          if (vinQuerySnapshot.size === 0) {
            // No VIN found
            updateLogger(`No vehicles found with VIN: ${vinFound}`);
            regoFoundIndex[index] = {
              ...regoFoundIndex[index],
              vin: vinFound,
              vinFound: false,
              vehiclesFound,
              selectedStockNumber: null,
              selectedStockBranchId: null,
            };
          } else {
            // VIN found
            vinQuerySnapshot.forEach((doc) => {
              updateLogger(`1 vehicle found in ${doc.data().branch_desc}`);
              vehiclesFound.push({ id: doc.id, ...doc.data() });
            });
            updateLogger(`Found a total of ${vehiclesFound.length} vehicle(s)`);

            if (vehiclesFound.length > 1) {
              regoFoundIndex[index] = {
                ...regoFoundIndex[index],
                vin: vinFound,
                vinFound: true,
                vehiclesFound,
                selectedStockNumber: null,
                selectedStockBranchId: null,
              };
            } else if (vehiclesFound.length === 1) {
              regoFoundIndex[index] = {
                ...regoFoundIndex[index],
                vin: vinFound,
                vinFound: true,
                vehiclesFound,
                selectedStockNumber: vehiclesFound[0].VEHStockNumber,
                referenceType: "SN", // Stock Number
                selectedStockBranchId: vehiclesFound[0].branch_id, // Stock Branch ID
              };
            }
          }

          globalVehicleFoundList.push(...vehiclesFound);
        }
      }
    }

    // Check for vehicles with different db in regoFoundIndex
    for (let i = 0; i < regoFoundIndex.length; i++) {
      const vehicle = regoFoundIndex[i];
      if (vehicle?.vehiclesFound?.length > 0) {
        const vehicleDb = vehicle.vehiclesFound[i]?.db;
        for (let j = 0; j < vehicle.vehiclesFound.length; j++) {
          const veh = vehicle.vehiclesFound[j];
          if (veh.db !== vehicleDb) {
            setHighlightedRowArray((prev) => [...prev, i]);
            toast.info(
              `Vehicle on invoice line ${
                i + 1
              } is in a different company to the rest, please resolve manually`
            );
            updateErrors(`Please check with AP team for advice`);
            toast.error(`Please check with AP team for advice`);
            return;
          }
        }
      }
    }

    setVehicleInfoIndex(regoFoundIndex);
    setVehiclesFound(globalVehicleFoundList);
  };

  const getMyHistory = async () => {
    const historyRef = collection(firestore, "data-entry-queued-actions");
    let q = "";
    if (user.data.email === "j.jayaram@duttongroup.com.au") {
      q = query(
        historyRef,
        where("invoiceDataSubmitted", ">", dayjs().subtract(5, "day").unix())
      );
    } else {
      q = query(
        historyRef,
        where("invoiceDataSubmittedByUid", "==", user.data.uid),
        where("invoiceDataSubmitted", ">", dayjs().subtract(1, "month").unix())
      );
    }

    const querySnapshot = await getDocs(q);
    const myHistory = [];
    querySnapshot.forEach((doc) => {
      myHistory.push({ id: doc.id, ...doc.data() });
    });
    setMyHistory(myHistory);
  };

  const checkUrlIsXero = (url) => {
    if (url === "") {
      return;
    }
    const xeroUrlLower = url.toLowerCase();

    if (xeroUrlLower.includes("in.xero.com")) {
      return true;
    } else {
      return false;
    }
  };

  const checkInvoiceExists = async (invoiceId, xeroUrl) => {
    if (invoiceId === "") {
      return;
    }

    //    Read from firestore
    console.log(`Checking if invoice with identifier ${xeroUrl} exists`);
    const invoiceRef = collection(firestore, "data-entry-queued-actions");
    const q = query(invoiceRef, where("invoiceUniqueId", "==", invoiceId));

    const querySnapshot = await getDocs(q);
    let outcome = false;
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const id = doc.id;
      const actioned = data.actioned;
      if (doc.exists) {
        console.log("Invoice exists");
        outcome = true;
      }
      //   if (actioned) {
      //     console.error(
      //       `Invoice with identifier ${xeroUrl} has already been processed`
      //     );
      //     outcome = true;
      //   } else {
      //     console.error(
      //       `Invoice with identifier ${xeroUrl} has been entered, but is not yet processed`
      //     );
      //   }
    });

    // Check with url as well
    const q2 = query(invoiceRef, where("invoiceIdentifier", "==", xeroUrl));
    const querySnapshot2 = await getDocs(q2);
    querySnapshot2.forEach((doc) => {
      const data = doc.data();
      const id = doc.id;
      const actioned = data.actioned;
      if (doc.exists) {
        console.log("Invoice exists");
        outcome = true;
      }
      //   if (actioned) {
      //     console.error(
      //       `Invoice with identifier ${xeroUrl} has already been processed`
      //     );
      //     outcome = true;
      //   } else {
      //     console.error(
      //       `Invoice with identifier ${xeroUrl} has been entered, but is not yet processed`
      //     );
      //   }
    });
    return outcome;
  };

  const loadXeroInvoiceJSON = async () => {
    setLoadingInvoiceData(true);
    // Paste from clipboard into the input field
    let xeroUrl = "";
    await navigator.clipboard.readText().then((clipText) => {
      xeroUrl = clipText;
      setXeroUrl(clipText);
    });
    // sleep for 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 3000));

    // Check if invoice URL is from xero domain
    const isXeroUrl = checkUrlIsXero(xeroUrl);
    if (!isXeroUrl) {
      toast.error("Please enter a valid system URL");
      setLoadingInvoiceData(false);
      return;
    }

    // Check if URL has id part

    // Remove new line characters
    xeroUrl = xeroUrl.replace(/(\r\n|\n|\r)/gm, "");

    const invoiceUrlFull = xeroUrl;
    // Process the url to split by / and get last element

    const urlParts = invoiceUrlFull?.split("/");
    const invoiceId = urlParts[urlParts?.length - 1];
    // Remove everything after the ?
    const invoiceIdParts = invoiceId?.split("?");
    const invoiceIdOnly = invoiceIdParts[0] || "";
    if (invoiceIdOnly === "") {
      toast.error("Please enter a valid id URL");
      setLoadingInvoiceData(false);
      return;
    }
    setXeroId(invoiceIdOnly);

    if (xeroUrl === "") {
      toast.error("Please enter a URL");
      setLoadingInvoiceData(false);
      return;
    }

    // Check if invoice has already been processed
    console.log(
      `Checking if invoice with id ${invoiceIdOnly} exists, ${xeroUrl}`
    );
    const invoiceExists = await checkInvoiceExists(invoiceIdOnly, xeroUrl);
    if (invoiceExists) {
      toast.error("Invoice has already been processed");
      setLoadingInvoiceData(false);
      return;
    }

    clearStates();

    await new Promise((resolve) => setTimeout(resolve, 3000));
    // Loop until states are empty
    if (!isStatesEmpty()) {
      while (isStatesEmpty()) {
        console.log("Clearing states");
        //   Delay 1000
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }

    updateLogger("Loading invoice data");

    const getInvoiceDataFromXero = httpsCallable(
      functions,
      "convertXeroInvoiceToJson"
    );

    await getInvoiceDataFromXero({ xeroUrl })
      .then((result) => {
        console.log(`data from xero: ${result.data}`);
        setXeroInvoiceJSON(result.data);
        setXeroInvoiceJSONBak(result.data);
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          "Error loading invoice data, please check URL and try again"
        );
      });
    setLoadingInvoiceData(false);

    // Set event timestamp after invoice data is loaded
    const eTSPayload = {
      invoiceDataLoaded: dayjs().unix(),
      invoiceDataLoadedBy: user.data.displayName,
      invoiceDataLoadedByUid: user.data.uid,
    };

    updateEventTimestamps(eTSPayload);

    // Delay for testing purposes
    // setTimeout(async () => {
    //   updateLogger("Loading invoice data");
    //   setXeroInvoiceJSON(testInvoiceJSON);
    //   setLoadingInvoiceData(false);
    // }, 1000);
  };

  //   Get the metadata from the database
  const getDBMetadata = async () => {
    updateLogger("Getting company metadata from database");
    const metadataRef = collection(firestore, "freight-carriers");
    const q = query(metadataRef, where("ezyauto_eclipseId", "==", "1114"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log({ ...doc.data(), id: doc.id });
      setEclipseMetadata({ [supplier]: { ...doc.data(), id: doc.id } });
    });
  };

  const updateVehicleInfoWithStockNumber = (selected, index) => {
    console.log("Selected: ", selected);
    const selectedVehicle = vehiclesFound.find(
      (vehicle) =>
        vehicle?.VEHStockNumber?.toString() === selected[0]?.toString()
    );

    console.log("Selected vehicle: ", selectedVehicle);

    if (typeof selectedVehicle === "undefined") {
      updateErrors(
        `Vehicle not found in database - something went wrong, please resolve manually`
      );

      const vehicleInfo = [...vehicleInfoIndex];
      vehicleInfo[index] = {
        ...vehicleInfo[index],
        selectedStockNumber: null,
        selectedStockBranchId: null,
        manualSelection: false,
      };
      setVehicleInfoIndex(vehicleInfo);
      return;
    }

    //  Check if selected vehicle is in a different db to the rest
    vehicleInfoIndex.forEach((vehicle, i) => {
      if (i !== index) {
        if (vehicle?.vehiclesFound?.length > 0) {
          const vehicleDb = vehicle.vehiclesFound[i]?.db;
          if (vehicleDb !== selectedVehicle.db) {
            setHighlightedRowArray((prev) => [...prev, i]);
            toast.info(
              `Vehicle on invoice line ${
                i + 1
              } is in a different company to the rest, please resolve manually`
            );
            updateErrors(`Please check with AP team for advice`);
            toast.error(`Please check with AP team for advice`);
            return;
          }
        }
      }
    });

    const vehicleInfo = [...vehicleInfoIndex];
    vehicleInfo[index] = {
      ...vehicleInfo[index],
      selectedStockNumber: selected[0],
      selectedStockBranchId: selectedVehicle?.branch_id,
      manualSelection: true,
      referenceType: "SN", // Stock Number
    };
    setVehicleInfoIndex(vehicleInfo);

    // Update dbClientId and dbClientName
    const tmpDbName = selectedVehicle.db;
    const tmpDbClientId = eclipseMetadata[supplier][tmpDbName + "_eclipseId"];
    setSelectedDbClientId(tmpDbClientId);
    setSelectedDbName(tmpDbName);
  };

  const getCompanyNameFromDbName = (dbName) => {
    let tmpCompanyName = "";
    switch (dbName) {
      case "ezyauto":
        tmpCompanyName = "Dutton Retail 1";
        break;
      case "NorthshoreAuto":
        tmpCompanyName = "Dutton Retail 2";
        break;
      case "duttongarage":
        tmpCompanyName = "Dutton Garage";
        break;
      case "duttongwa":
        tmpCompanyName = "Dutton Wholesale";
        break;
      case "sycf":
        tmpCompanyName = "SYCF";
        break;

      default:
        break;
    }

    return tmpCompanyName;
  };

  const getDbNameFromCompanyName = (companyName) => {
    let tmpDbName = "";
    switch (companyName) {
      case "Dutton Retail 1":
        tmpDbName = "ezyauto";
        break;
      case "Dutton Retail 2":
        tmpDbName = "NorthshoreAuto";
        break;

      case "Dutton Garage":
        tmpDbName = "duttongarage";
        break;

      case "Dutton Wholesale":
        tmpDbName = "duttongwa";
        break;

      case "SYCF":
        tmpDbName = "sycf";
        break;

      default:
        break;
    }
    return tmpDbName;
  };

  // When ticket is loaded for Xero, parse the body and get invoice url
  useEffect(() => {
    if (nextTicket) {
      const ticketBody = nextTicket.body;
      const $ = cheerio.load(ticketBody);
      const linkText = $("link[itemprop='target']").attr("href");
      if (!linkText) {
        console.log("No link found in ticket body");
        toast.error("No link found in ticket body");
        return;
      }
      console.log("Link: ", linkText);
      try {
        // Copy to clipboard
        setLinkFromEmail(linkText);
        navigator.clipboard.writeText(linkText);
      } catch (e) {
        console.error(e);
        toast.error("Error copying to clipboard, please try manually");
      }

      // if (ticketBody && ticketBody.length > 0) {
      //   // Match for link tag with itemprop="target"
      //   const linkRegex =
      //     /<link.*?itemprop="\"target\"".*?href="(.*?)".*?>(.*?)<\/link>/g;
      //   const linkMatch = ticketBody.match(linkRegex);
      //   if (linkMatch) {
      //     const link = linkMatch[0].match(/href="(.*?)"/)[1];
      //     console.log("Link: ", link);
      //   }
      // }
    }
  }, [JSON.stringify(nextTicket)]);

  useEffect(() => {
    if (xeroInvoiceJSON.length > 0) searchForReferences();
  }, [JSON.stringify(xeroInvoiceJSON)]);

  //   Set the metadata for Ace Car Freighters
  useEffect(() => {
    if (supplier === "ace") {
      getDBMetadata();
    }
  }, [supplier]);

  useEffect(() => {
    if (vehicleInfoIndex.length > 0) {
      for (let i = 0; i < vehicleInfoIndex.length; i++) {
        const vehicle = vehicleInfoIndex[i];
        console.log("vii", vehicleInfoIndex[i]);

        if (vehicle?.regoFound || vehicle?.vinFound || false) {
          if (vehicle.vehiclesFound.length === 1) {
            updateLogger(`Only one vehicle found, setting company`);
            console.log(
              `${vehicle.vehiclesFound[0].VEHRegn} is in ${vehicle.vehiclesFound[0].db}`
            );
            setSelectedDbName(vehicle.vehiclesFound[0].db);
          }
          if (vehicle.vehiclesFound.length > 1 && !vehicle.manualSelection) {
            updateLogger(`Multiple vehicles found`);
            // Check if the vehicles are in the same company
            const companyList = vehicle.vehiclesFound.map(
              (vehicle) => vehicle.db
            );
            const uniqueCompanies = [...new Set(companyList)];
            if (uniqueCompanies.length === 1) {
              updateLogger(`All vehicles are in the same company`);
              setSelectedDbName(uniqueCompanies[0]);
            } else {
              updateLogger(
                `Vehicles are in different companies, please resolve manually`
              );
              //   Update errors state
              //   updateErrors(
              //     `Vehicles are in different companies, please resolve manually`
              //   );
              toast.warning(
                `Vehicles are in different companies, please resolve manually`
              );
            }
          }
        }
      }
    } else {
      updateErrors(`No vehicle information found, please resolve manually`);
    }
  }, [JSON.stringify(vehicleInfoIndex)]);

  //   Set the selectedDbName when the selectedCompany changes
  useEffect(() => {
    if (selectedCompany.length > 0) {
      console.log(eclipseMetadata);
      const tmpCompanyName = selectedCompany[0];
      const tmpDbName = getDbNameFromCompanyName(tmpCompanyName);
      setSelectedDbName(tmpDbName);
    } else {
      setSelectedDbName("");
      setSelectedDbClientId("");
    }
  }, [selectedCompany]);

  //   update the selectedDbClientId when the selectedDbName changes
  useEffect(() => {
    if (supplier === "ace") {
      if (selectedDbName.length > 0 && eclipseMetadata !== null) {
        updateLogger(`Setting dbClientId for ${selectedDbName}`);
        const tmpDbClientId =
          eclipseMetadata[supplier][selectedDbName + "_eclipseId"];
        setSelectedDbClientId(tmpDbClientId);
        const tmpCompanyName = getCompanyNameFromDbName(selectedDbName);

        updateLogger(`Setting company to ${tmpCompanyName}`);
        setSelectedCompany([tmpCompanyName]);
      }
    }
  }, [selectedDbName]);

  //   Update scroll to bottom of logs
  useEffect(() => {
    logRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [JSON.stringify(logger), logRef]);

  //   Update scroll for errors
  useEffect(() => {
    console.log("Errors: ", errors);
    errorLogRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [JSON.stringify(errors), errorLogRef]);

  return (
    <>
      <BackdropComponent loading={loadingInvoiceData || isSaving} />
      <ToastContainer />
      <Row
        style={{
          borderRadius: 100,
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 999,
        }}
      >
        <Col>
          <Alert variant="info" size="small">
            <Table
              size="sm"
              hover
              style={{ fontSize: 12, borderStyle: "hidden" }}
            >
              <thead>
                <tr>
                  <th>Ticket ID</th>
                  <th>Status</th>
                  <th>Created</th>
                  <th>Position</th>
                </tr>
              </thead>
              <tbody>
                <tr hidden={!nextTicket}>
                  <td>{nextTicket?.id}</td>
                  <td>{nextTicket?.ticket?.status}</td>
                  <td>
                    {dayjs
                      .unix(nextTicket?.ticket?.created)
                      .format("DD-MM-YYYY")}
                  </td>
                  <td>
                    {queryCursor} / {totalTickets}
                  </td>
                </tr>
                <tr hidden={!nextTicket}>
                  <td colSpan={4}>{nextTicket?.subject}</td>
                </tr>
                <tr hidden={!nextTicket}>
                  <td colSpan={3}>{linkFromEmail}</td>
                  <td>
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={() => {
                        navigator.clipboard.writeText(linkFromEmail);
                        toast.success("Copied to clipboard");
                      }}
                    >
                      <HiClipboardCheck />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td colSpan={4}>
                    <ButtonGroup>
                      <Button
                        size="sm"
                        variant="outline-success"
                        onClick={() => getNextTicket()}
                        // disabled={queryCursor >= totalTickets}
                      >
                        {isLoadingNextTicket ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Get next ticket"
                        )}
                      </Button>
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => skipThisTicket(nextTicket)}
                        disabled={isLoadingNextTicket || !nextTicket}
                      >
                        Skip this ticket
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Alert>
        </Col>
      </Row>
      <Container>
        <Card
          style={{
            fontSize: 10,
            marginBottom: 50,
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            alignItems: "left",
            maxWidth: "30%",
          }}
        >
          <ToggleButtonGroup
            type="radio"
            name="supplier_name"
            defaultValue={""}
            onChange={(val) => setSupplier(val)}
          >
            <ToggleButton variant="outline-primary" value={"ace"}>
              Ace Car Freighters
            </ToggleButton>
            {/* <ToggleButton variant="outline-primary" value={"other"}>
              Other
            </ToggleButton> */}
          </ToggleButtonGroup>
        </Card>
      </Container>
      {/* <Row>
        <Col>
          <pre>{JSON.stringify(nextTicket, null, 2)}</pre>
        </Col>
      </Row> */}

      <Container
        style={{
          minWidth: "95%",
          display: supplier === "ace" ? "block" : "none",
        }}
      >
        <Row>
          <Col xs={3}>
            <Row style={{ fontSize: 10 }}>
              <Col>
                <FormGroup>
                  <h6
                    style={{
                      backgroundColor: "#58D68D",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    Enter invoice link from ticket
                  </h6>
                  <Form.Control
                    type="text"
                    value={xeroUrl}
                    onChange={(e) => setXeroUrl(e.target.value)}
                    disabled
                    style={{ fontSize: 10 }}
                  />
                  <ButtonGroup style={{ marginTop: 10 }}>
                    <Button
                      size="sm"
                      disabled={loadingInvoiceData}
                      onClick={loadXeroInvoiceJSON}
                      variant="outline-primary"
                    >
                      {loadingInvoiceData ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Paste from clipboard and load invoice"
                      )}
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => setXeroUrl("")}
                    >
                      Clear
                    </Button>
                  </ButtonGroup>
                </FormGroup>
              </Col>
            </Row>
            <Card
              style={{
                fontSize: 14,
                marginBottom: 25,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              }}
            >
              <Card.Header>Directions</Card.Header>
              <Card.Body>
                <ol style={{ textAlign: "left", fontSize: 12 }}>
                  <li>Search for the car by either VIN, or registration</li>
                  <li>Select the correct company from dropdown list.</li>
                </ol>
              </Card.Body>
              <Card.Footer>
                <ButtonGroup>
                  <Button
                    disabled={xeroId.length === 0}
                    variant="outline-info"
                    onClick={handleShowPDF}
                  >
                    Show PDF
                  </Button>
                  <Button
                    onClick={() => {
                      window.open("/vin-rego-search", "_blank");
                    }}
                    variant="outline-info"
                  >
                    VIN search
                  </Button>
                </ButtonGroup>
              </Card.Footer>
            </Card>
            <Card
              style={{
                fontSize: 14,
                marginBottom: 25,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              }}
            >
              <Card.Header>Log</Card.Header>
              <Card.Body style={{ height: 150, overflow: "scroll" }}>
                <ul
                  ref={logRef}
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    listStyleType: "none",
                  }}
                >
                  {logger?.map((log, index) => (
                    <li key={index} style={{}}>
                      {log}
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
            <Card
              style={{
                fontSize: 14,
                marginBottom: 25,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              }}
            >
              <Card.Header>Errors</Card.Header>
              <Card.Body style={{ height: 150, overflow: "scroll" }}>
                <ul
                  ref={errorLogRef}
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    listStyleType: "none",
                  }}
                >
                  {errors?.map((error, index) => (
                    <li key={index} style={{ color: "red" }}>
                      {error}
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6}>
            <Card
              style={{
                fontSize: 14,
                marginBottom: 25,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              }}
            >
              <Card.Header>Invoice details</Card.Header>
              <Card.Body>
                <Table size="sm">
                  <thead>
                    <tr>
                      <th>Invoice From</th>
                      <th>Invoice #</th>
                      <th>Invoice Date</th>
                      <th>Due Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {xeroInvoiceJSON[0]?.ContactName}
                        {xeroInvoiceJSON[0]?.ContactName.includes(
                          "Ace Car Freighters"
                        ) ? (
                          <IoCheckmarkDoneCircleSharp
                            style={{ color: "green", fontSize: 20 }}
                          />
                        ) : null}
                      </td>
                      <td>{xeroInvoiceJSON[0]?.InvoiceNumber}</td>
                      <td>{xeroInvoiceJSON[0]?.InvoiceDate}</td>
                      <td>{xeroInvoiceJSON[0]?.DueDate}</td>
                    </tr>
                  </tbody>
                </Table>
                <Table size="sm">
                  <thead>
                    <tr>
                      <th>Company</th>
                      <th>Eclipse DB Name</th>
                      <th>Eclipse Client ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Typeahead
                          id="dbCode"
                          name={"dbCode"}
                          options={dgCompanies}
                          selected={selectedCompany}
                          placeholder="Select Dutton company to invoice"
                          onChange={(selected) => handleCompanyChange(selected)}
                        >
                          {({ onClear, selected }) => (
                            <div className="rbt-aux">
                              {!!selected.length && (
                                <ClearButton onClick={onClear} />
                              )}
                              {!selected.length && (
                                <Spinner
                                  animation="grow"
                                  size="sm"
                                  variant="warning"
                                />
                              )}
                            </div>
                          )}
                        </Typeahead>
                      </td>
                      <td>{selectedDbName}</td>
                      <td>{selectedDbClientId}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Card
              style={{
                fontSize: 14,
                marginBottom: 25,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              }}
            >
              <Card.Body style={{ height: 350, overflow: "scroll" }}>
                <Table size="sm">
                  <thead
                    style={{
                      ...stickyTableHeaderStyle,
                    }}
                  >
                    <tr>
                      <th style={{ textAlign: "left" }}>Description</th>
                      <th>Stock #</th>
                      <th>Type</th>
                      <th>Quantity</th>
                      <th>Unit Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {xeroInvoiceJSON.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          textAlign: "right",
                          border: highlightedRowArray.includes(index)
                            ? "3px solid red"
                            : "none",
                        }}
                      >
                        <td style={{ textAlign: "left" }}>
                          {item.Description}
                        </td>
                        <td>
                          {getLastElementFromDescription(item.Description) ===
                          false ? null : (
                            <>
                              <InputGroup>
                                <Typeahead
                                  id={`stockNumber-${index}`}
                                  name={`stockNumber-${index}`}
                                  options={vehiclesFound.map((vehicle) =>
                                    vehicle.VEHStockNumber.toString()
                                  )}
                                  onChange={(selected) =>
                                    updateVehicleInfoWithStockNumber(
                                      selected,
                                      index
                                    )
                                  }
                                  selected={
                                    vehicleInfoIndex[index]?.selectedStockNumber
                                      ? [
                                          vehicleInfoIndex[
                                            index
                                          ]?.selectedStockNumber.toString(),
                                        ]
                                      : []
                                  }
                                  placeholder="Select stock #"
                                >
                                  {({ onClear, selected }) => (
                                    <div className="rbt-aux">
                                      {!!selected.length && (
                                        <ClearButton onClick={onClear} />
                                      )}
                                      {!selected.length && (
                                        <Spinner
                                          animation="grow"
                                          size="sm"
                                          variant="warning"
                                        />
                                      )}
                                    </div>
                                  )}
                                </Typeahead>
                                <InputGroup.Append>
                                  <Button
                                    onClick={() =>
                                      handleOpenVehSearchModal(index)
                                    }
                                  >
                                    <BiSearchAlt2 />
                                  </Button>
                                </InputGroup.Append>
                              </InputGroup>
                            </>
                          )}
                        </td>
                        <td>{vehicleInfoIndex[index]?.referenceType || ""}</td>
                        <td>
                          <NumberFormat
                            value={item.Quantity}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={1}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={item.UnitAmount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        </td>

                        <td>
                          <NumberFormat
                            value={
                              parseFloat(item.UnitAmount) *
                              parseFloat(item.Quantity)
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        colSpan="5"
                        style={{ textAlign: "right", fontWeight: "bold" }}
                      >
                        Sub Total
                      </td>
                      <td style={{ textAlign: "right", fontWeight: "bold" }}>
                        <NumberFormat
                          value={xeroInvoiceJSON?.reduce((acc, item) => {
                            if (
                              isNaN(acc) ||
                              isNaN(parseFloat(item.UnitAmount))
                            ) {
                              return acc;
                            }
                            return (
                              parseFloat(acc) + parseFloat(item.UnitAmount)
                            );
                          }, 0.0)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="5"
                        style={{ textAlign: "right", fontWeight: "bold" }}
                      >
                        Total GST
                      </td>
                      <td style={{ textAlign: "right", fontWeight: "bold" }}>
                        <NumberFormat
                          value={xeroInvoiceJSON?.reduce((acc, item) => {
                            if (
                              isNaN(acc) ||
                              isNaN(parseFloat(item.TaxAmount))
                            ) {
                              return acc;
                            }
                            return parseFloat(acc) + parseFloat(item.TaxAmount);
                          }, 0.0)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="5"
                        style={{ textAlign: "right", fontWeight: "bold" }}
                      >
                        Total
                      </td>
                      <td style={{ textAlign: "right", fontWeight: "bold" }}>
                        <NumberFormat
                          value={xeroInvoiceJSON[0]?.Total}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer style={{ textAlign: "right" }}>
                <ButtonGroup>
                  {/* <Button onClick={() => getInvoiceTotals()}>
                    Calc totals
                  </Button> */}
                  <Button
                    onClick={submitInvoiceDataToActionQueue}
                    variant="primary"
                  >
                    Create Invoice in Eclipse
                  </Button>
                  <Button variant="outline-danger" onClick={() => resetForm()}>
                    Clear and restart
                  </Button>
                </ButtonGroup>
              </Card.Footer>
            </Card>
            {/* <pre>{JSON.stringify(xeroInvoiceJSON, null, 2)}</pre> */}
          </Col>
          <Col xs={3}>
            <Card
              style={{
                fontSize: 14,
                marginBottom: 25,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              }}
            >
              <Card.Header>
                Vehicles found{" "}
                <Badge variant="success">{vehiclesFound.length}</Badge>
              </Card.Header>
              <Card.Body style={{ height: 655, overflow: "scroll" }}>
                <sup>
                  <i>orderd by Purchase Date ascending</i>
                </sup>
                {vehiclesFound
                  .sort((a, b) => a.Purchase_Date - b.Purchase_Date)
                  .map((vehicle, index) => (
                    <Card key={index} style={{ marginBottom: 5 }}>
                      <Card.Body>
                        <Table size="sm" style={{ fontSize: 12 }}>
                          <tbody>
                            <tr>
                              <td
                                colSpan={2}
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                {`${vehicle.VEHYear} ${vehicle.VEHMake} ${vehicle.VEHModel} ${vehicle["Variant & Series"]}`}
                              </td>
                            </tr>
                            <tr>
                              <td>Stock Number</td>
                              <td>{vehicle.VEHStockNumber}</td>
                            </tr>

                            <tr>
                              <td>Registration</td>
                              <td>{vehicle.VEHRegn}</td>
                            </tr>
                            <tr>
                              <td>Branch</td>
                              <td>{vehicle.branch_desc}</td>
                            </tr>
                            <tr>
                              <td>Database</td>
                              <td>{getCompanyNameFromDbName(vehicle.db)}</td>
                            </tr>
                            <tr>
                              <td>Purchase Date</td>
                              <td>
                                {dayjs
                                  .unix(vehicle?.Purchase_Date?.seconds)
                                  .format("DD-MM-YYYY")}
                              </td>
                            </tr>
                            <tr>
                              <td>VIN</td>
                              <td
                                style={{
                                  cursor: "pointer",
                                  color: "blue",
                                  textDecoration: "underline",
                                }}
                                onClick={() =>
                                  window.open(
                                    `/vin/${vehicle?.VEHVIN}`,
                                    "_blank"
                                  )
                                }
                              >
                                {vehicle.VEHVIN}
                              </td>
                            </tr>
                            <tr>
                              <td>Status</td>
                              <td>
                                <Badge
                                  variant={`${
                                    vehicle.CurrentStatus === "IN-STOCK"
                                      ? "success"
                                      : "danger"
                                  }`}
                                >
                                  {vehicle.CurrentStatus}
                                </Badge>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  ))}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* START OF HISTORY MODAL */}
      <Modal size="lg" show={showHistoryModal} onHide={handleCloseHistoryModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            History
            {loadingHistory ? (
              <Spinner animation="border" size="sm" />
            ) : null}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Table of history */}
          <Table size="sm">
            <thead
              style={{
                ...stickyTableHeaderStyle,
              }}
            >
              <tr>
                <th>ID</th>
                <th>Supplier</th>
                <th>Submitted</th>
                <th>Submitted by</th>
                <th>TTDE (s)</th>
                <th>Actioned</th>
                <th>Queue Status</th>
                <th>Eclipse Actioned Time</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 10 }}>
              {myHistory?.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.supplier.toUpperCase()}</td>
                  <td>
                    {item.invoiceDataSubmitted
                      ? dayjs
                          .unix(item.invoiceDataSubmitted)
                          .format("DD-MM-YYYY HH:mm:ss")
                      : ""}
                  </td>
                  <td>{item.invoiceDataSubmittedBy}</td>
                  <td>{item.totalTimeToSubmit}</td>
                  <td>{item.actioned ? "Yes" : "No"}</td>
                  <td>{item.actionStatus}</td>
                  <td>
                    {item.actionedTimestamp
                      ? dayjs
                          .unix(item.actionedTimestamp)
                          .format("DD-MM-YYYY HH:mm:ss")
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      {/* History open modal button FAB */}
      <Button
        variant="primary"
        style={{
          position: "fixed",
          bottom: 10,
          right: 10,
          zIndex: 999,
        }}
        onClick={handleOpenHistoryModal}
      >
        {/* Icon history */}
        <BsClockHistory />
      </Button>
      {/* END OF HISTORY MODAL */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/* START OF VEH SEARCH MODAL */}
      <Modal
        size="lg"
        show={showVehSearchModal}
        onHide={handleCloseVehSearchModal}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em" }}>
            Search vehicles for row: {searchIndex + 1}
            <br />
            <span style={{ color: "#8E44AD" }}>
              {xeroInvoiceJSON[searchIndex]?.Description}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <i>
            Use this search to add vehicles that don't automatically show in the
            list
          </i>
          <br />
          <br />
          {/* Table search */}
          <Table size="sm">
            <tbody>
              <tr>
                <td>VIN</td>
                <td>
                  <FormControl
                    id="search-vin"
                    name="search-vin"
                    type="text"
                    value={searchVin}
                    onChange={(e) => setSearchVin(e.target.value)}
                  />
                </td>
                <td>
                  <Button onClick={() => searchForVehicles("vin")}>
                    {vehSearchLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Search"
                    )}
                  </Button>
                </td>
              </tr>
              <tr>
                <td>Registration</td>
                <td>
                  <FormControl
                    id="search-rego"
                    name="search-rego"
                    type="text"
                    value={searchRego}
                    onChange={(e) => setSearchRego(e.target.value)}
                  />
                </td>
                <td>
                  <Button onClick={() => searchForVehicles("rego")}>
                    {vehSearchLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Search"
                    )}
                  </Button>
                </td>
              </tr>
              <tr>
                <td>Stock #</td>
                <td>
                  <FormControl
                    id="search-stock-number"
                    name="search-stock-number"
                    type="text"
                    value={searchStockNumber}
                    onChange={(e) => setSearchStockNumber(e.target.value)}
                  />
                </td>
                <td>
                  <Button onClick={() => searchForVehicles("stock")}>
                    {vehSearchLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Search"
                    )}
                  </Button>
                </td>
              </tr>
              <tr>
                <td>VIN (last 6 digits)</td>
                <td>
                  <FormControl
                    id="search-vin-truncated"
                    name="search-vin-truncated"
                    type="text"
                    value={searchVinTrunc}
                    onChange={(e) => setSearchVinTrunc(e.target.value)}
                  />
                </td>
                <td>
                  <Button onClick={() => searchForVehicles("vintrunc")}>
                    {vehSearchLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Search"
                    )}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          {/* Table of vehicles */}
          <Table size="sm">
            <thead style={{ ...stickyTableHeaderStyle }}>
              <tr>
                <th>Description</th>
                <th>Stock #</th>
                <th>VIN</th>
                <th>Rego</th>
                <th>Branch</th>
                <th>Status</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 12 }}>
              {vehSearchResults.length === 0 ? (
                <tr>
                  <td style={{ textAlign: "center" }} colSpan="5">
                    No vehicles found
                  </td>
                </tr>
              ) : null}
              {vehSearchResults.map((vehicle, index) => (
                <tr
                  key={index}
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  <td>{`${vehicle.VEHModelYear} ${vehicle.VEHMake} ${vehicle.VEHModel} ${vehicle["Variant & Series"]}`}</td>
                  <td>{vehicle.VEHStockNumber}</td>
                  <td>{vehicle.VEHVIN}</td>
                  <td>{vehicle.VEHRegn}</td>
                  <td>{vehicle.branch_desc}</td>
                  <td>{vehicle.CurrentStatus}</td>
                  <td>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() =>
                        handleSetVehicleFromSearchResult(vehicle, searchIndex)
                      }
                    >
                      <Check />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button
              variant="outline-warning"
              onClick={handleCloseVehSearchModal}
            >
              Back to main screen
            </Button>
            <Button variant="outline-danger" onClick={handleClearSearchFields}>
              Clear search results
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
      {/* END OF VEH SEARCH MODAL */}
    </>
  );
};

export default XeroDataEntryComponent;
