import { ErrorMessage, Formik } from "formik";
import { useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Modal,
  Row,
  Table,
  Form,
} from "react-bootstrap";
import { FaSave, FaSearch } from "react-icons/fa";
import { useUser } from "reactfire";
import TextField from "../FreightBooking/TextField";
import { ToastContainer, toast } from "react-toastify";
import PageViewLoggerComponent from "../PageViewLoggerComponent";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";
import dayjs from "dayjs";
import useFirestoreQuery from "../../hooks/useFirestoreQuery";
import NumberFormat from "react-number-format";
import { useEffect } from "react";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const validationSchema = Yup.object().shape({
  carsalesUrl: Yup.string().required("Required"),
  notes: Yup.string().required("Required"),

  submittedPriceMin: Yup.number()
    .required("Required")
    .lessThan(Yup.ref("submittedPriceMax"), "Must be less than maximum price"),
  submittedPriceMax: Yup.number()
    .required("Required")
    .moreThan(
      Yup.ref("submittedPriceMin"),
      "Must be greater than minimum price"
    ),
});

const validationSchemaPA = Yup.object().shape({
  approvedPriceMin: Yup.number()
    .required("Required")
    .lessThan(Yup.ref("approvedPriceMax"), "Must be less than maximum price"),
  approvedPriceMax: Yup.number()
    .required("Required")
    .moreThan(
      Yup.ref("approvedPriceMin"),
      "Must be greater than minimum price"
    ),
});

const PricingApprovalCard = ({ pa }) => {
  const [showAlterFields, setShowAlterFields] = useState(false);

  const user = useUser();
  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const updatePricingRequest = (mode, params) => {};

  useEffect(() => {
    if (pa.id) {
      // const unsub = db.collection('pricing-request').doc(pa.id).collection('approvals-log').onSnapshot(snapshot)
    }
  }, []);

  return (
    <>
      <Card style={{ marginTop: 10 }}>
        <Card.Header>New pricing request</Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Table>
                <tr>
                  <td>Id</td>
                  <td>{pa.id}</td>
                </tr>
                <tr>
                  <td>Url</td>
                  <td>
                    <a href={`${pa.carsalesUrl}`} target="_blank">
                      {pa.carsalesUrl}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td>Submitted Price Min</td>
                  <td>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix="$"
                      value={pa.submittedPriceMin}
                    />
                  </td>
                </tr>

                <tr>
                  <td>Submitted Price Max</td>
                  <td>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix="$"
                      value={pa.submittedPriceMax}
                    />
                  </td>
                </tr>

                <tr>
                  <td>Notes</td>
                  <td>{pa.notes}</td>
                </tr>
              </Table>
            </Col>
          </Row>

          {/* {showAlterFields ? ( */}
          {/* START Approval form */}
          <Row>
            <Col>
              <Formik
                initialValues={{
                  approvedPriceMin: pa.approvedPriceMin,
                  approvedPriceMax: pa.approvedPriceMax,
                  approvedNotes: "",
                  approvedUser: user.data.uid,
                  approvedUserName: user.data.displayName,
                  approvedUserEmail: user.data.email,
                  responded: true,
                  approved: false,
                }}
                validationSchema={validationSchemaPA}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={async (values, { resetForm }) => {
                  const payload = {
                    ...values,
                    timestampUpdated: dayjs().unix(),
                  };
                  console.log(payload);
                  console.log(pa.id);
                  await firestore
                    .collection("pricing-approvals")
                    .doc(pa.id)
                    .set(payload, { merge: true })
                    .then((r) => {
                      toast.success(`Approval sent back to ${pa.userName}`);
                      return;
                    })
                    .catch((e) => {
                      toast.error(e.message);
                      return;
                    });

                  await firestore
                    .collection("pricing-approvals")
                    .doc(pa.id)
                    .collection("approvals-log")
                    .add({ ...payload, pricingRequestId: pa.id });
                  resetForm();
                  //   setShowAlterFields(false);
                }}
              >
                {/* Callback function containing Formik state and helpers that handle common form actions */}
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  setFieldValue,
                  isSubmitting,
                  resetForm,
                }) => (
                  <Form>
                    <Table>
                      <tr>
                        <td>Approved Price Min</td>
                        <td>
                          <NumberFormat
                            className="form-control"
                            /* This name property is used to access the value of the form element via values.nameOfElement */
                            name={`approvedPriceMin`}
                            /* Set onChange to handleChange */
                            onValueChange={(v) => {
                              setFieldValue("approvedPriceMin", v.value);
                            }}
                            /* Set onBlur to handleBlur */
                            onBlur={handleBlur}
                            /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                            value={values.approvedPriceMin}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                          <ErrorMessage name="approvedPriceMin">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </td>
                      </tr>
                      <tr>
                        <td>Approved Price Max</td>
                        <td>
                          <NumberFormat
                            className="form-control"
                            /* This name property is used to access the value of the form element via values.nameOfElement */
                            name={`approvedPriceMax`}
                            /* Set onChange to handleChange */
                            onValueChange={(v) => {
                              setFieldValue("approvedPriceMax", v.value);
                            }}
                            /* Set onBlur to handleBlur */
                            onBlur={handleBlur}
                            /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                            value={values.approvedPriceMax}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                          <ErrorMessage name="approvedPriceMax">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <ButtonGroup>
                            <Button
                              variant="primary"
                              onClick={handleSubmit}
                              block
                              disabled={isSubmitting}
                            >
                              Submit approved pricing suggestions &nbsp;
                              <FaSave />
                            </Button>
                            <Button variant="outline-danger">Reject</Button>
                            <Button
                              variant="outline-secondary"
                              onClick={() => {
                                resetForm();
                                //   setQuerySearchResults([]);
                              }}
                              disabled={isSubmitting}
                            >
                              Clear
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    </Table>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
          {/* END Approval form */}
          {/* ) : null} */}
        </Card.Body>
        <Card.Footer>
          {dayjs().to(dayjs.unix(pa.timestampCreated))}
          &nbsp;by&nbsp;
          {pa.userName}
        </Card.Footer>
      </Card>
    </>
  );
};

const PricingApprovalComponent = () => {
  const [showNewRequestModal, setShowNewRequestModal] = useState(false);
  const [requestExists, setRequestExists] = useState(false);
  const [duplicateRequest, setDuplicateRequest] = useState({});

  const user = useUser();

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const { data, status, error } = useFirestoreQuery(
    firestore
      .collection("pricing-approvals")
      .where("branch", "==", "AMW_BUYING_SERVICES")
  );

  const checkIfUrlPreviouslySubmitted = async (url) => {
    // Check if carsales url has been previously submitted
    // If so show warning
    console.log(url);
    setRequestExists(false);
    setDuplicateRequest({});

    await firestore
      .collection("pricing-approvals")
      .where("carsalesUrl", "==", url)
      .where("responded", "==", false)
      .get()
      .then((snapshot) => {
        console.log(snapshot.size);
        if (snapshot.size > 0) {
          let doc = null;
          snapshot.forEach((document) => {
            const d = document.data();
            doc = d;
          });

          setRequestExists(true);
          setDuplicateRequest(doc);
        }
      });
  };

  return (
    <>
      <h4>To approve</h4>
      <Row>
        <Col style={{ margin: 15 }}>
          {data?.map((p) => {
            return <PricingApprovalCard key={p.id} pa={p} />;
          })}
        </Col>
      </Row>
      <Button
        variant="success"
        style={{
          position: "fixed",
          bottom: 50,
          right: 50,
          fontSize: 20,
          zIndex: 9999,
        }}
        onClick={() => setShowNewRequestModal(!showNewRequestModal)}
      >
        {showNewRequestModal ? "-" : "+"}
      </Button>
      {/* START NEW PRICING REQUEST MODAL */}
      <Modal
        show={showNewRequestModal}
        onHide={() => setShowNewRequestModal(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add new pricing request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="12">
              <Formik
                initialValues={{
                  carsalesUrl: "",
                  submittedNotes: "",
                  submittedPriceMin: "",
                  submittedPriceMax: "",
                  approvedPriceMin: "",
                  approvedPriceMax: "",
                  approvedNotes: "",
                  user: user.data.uid,
                  userName: user.data.displayName,
                  userEmail: user.data.email,
                  branch: "AMW_BUYING_SERVICES",
                  responded: false,
                  approved: false,
                }}
                validationSchema={validationSchema}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={async (values, { resetForm }) => {
                  const payload = {
                    ...values,
                    timestampCreated: dayjs().unix(),
                  };
                  await firestore
                    .collection("pricing-approvals")
                    .add(payload)
                    .then((r) => {
                      toast.success(`Added new request`);
                      return;
                    })
                    .catch((e) => {
                      toast.error(e.message);
                      return;
                    });
                  setRequestExists(false);
                  setDuplicateRequest({});
                  resetForm();
                  setShowNewRequestModal(false);
                }}
              >
                {/* Callback function containing Formik state and helpers that handle common form actions */}
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  setFieldValue,
                  isSubmitting,
                  resetForm,
                }) => (
                  <Form>
                    <Row>
                      <Col>
                        {requestExists ? (
                          <Alert variant="warning">
                            A request with this url has already been submitted
                            by {duplicateRequest?.userName}{" "}
                            {dayjs().to(
                              dayjs.unix(duplicateRequest?.timestampCreated)
                            )}
                            . Please consider this before submitting this
                            request
                          </Alert>
                        ) : null}
                        <TextField
                          fieldname={`carsalesUrl`}
                          label="Carsales Url"
                          placeholder="https://carsales.com..."
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={(e) => {
                            checkIfUrlPreviouslySubmitted(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId={`form-maxPrice`}>
                          <Form.Label>Max Price</Form.Label>
                          <NumberFormat
                            className="form-control"
                            /* This name property is used to access the value of the form element via values.nameOfElement */
                            name={`submittedPriceMin`}
                            /* Set onChange to handleChange */
                            onValueChange={(v) => {
                              setFieldValue("submittedPriceMin", v.value);
                            }}
                            /* Set onBlur to handleBlur */
                            onBlur={handleBlur}
                            /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                            value={values.submittedPriceMin}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                          <ErrorMessage name="submittedPriceMin">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId={`form-minPrice`}>
                          <Form.Label>Min Price</Form.Label>
                          <NumberFormat
                            className="form-control"
                            /* This name property is used to access the value of the form element via values.nameOfElement */
                            name={`submittedPriceMax`}
                            /* Set onChange to handleChange */
                            onValueChange={(v) => {
                              setFieldValue("submittedPriceMax", v.value);
                            }}
                            /* Set onBlur to handleBlur */
                            onBlur={handleBlur}
                            /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                            value={values.submittedPriceMax}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                          <ErrorMessage name="submittedPriceMax">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextField
                          fieldname={`notes`}
                          label="Notes"
                          placeholder=""
                          type="textarea"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ButtonGroup>
                          <Button
                            variant="primary"
                            onClick={handleSubmit}
                            block
                            disabled={isSubmitting}
                          >
                            <FaSave /> Submit
                          </Button>
                          <Button
                            variant="outline-secondary"
                            onClick={() => {
                              resetForm();
                              //   setQuerySearchResults([]);
                            }}
                            disabled={isSubmitting}
                          >
                            Clear
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      {/* END NEW PRICING REQUEST MODAL */}

      <ToastContainer />
      <PageViewLoggerComponent />
    </>
  );
};

export default PricingApprovalComponent;
