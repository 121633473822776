import * as cheerio from "cheerio";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  Row,
  Container,
  Table,
  ListGroup,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const ROWS = 10;
const COLS = 5;

const headers = {
  year: "Year",
  badge: "Badge",
  seller: "Seller",
  state: "State",
  colour: "Colour",
  dap: "DAP",
  egc: "EGC",
  kms: "Kms",
  delisted: "Delisted",
  watched: "Watched",
  leadratio: "Lead Ratio",
  programs: "Programs",
  tags: "Tags",
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const DailyActions = () => {
  return (
    <Container
      style={{ backgroundColor: "#D6DBDF", borderRadius: 5, marginBottom: 15 }}
    >
      <i>** Ignore this table **</i>
      <Row>
        <Col>Searches to complete for: {dayjs().format("DD-MM-YYYY")}</Col>
      </Row>
      <Row>
        <Table>
          <thead>
            <tr>
              <th>Make</th>
              <th>Model</th>
              <th>Items loaded today</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td>Suzuki</td>
              <td>Jimny</td>
              <td>0</td>
            </tr> */}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};

const CompetitiveSet = () => {
  const [src, setSrc] = useState("");
  const [output, setOutput] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStage, setStage] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [countArray, setCountArray] = useState({});

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const setCurrentStage = async (val) => {
    await sleep(300);
    setStage(val);
  };

  const convertToCSV = (objArray) => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  };

  // const exportCSVFile = (headers, items, fileTitle) => {
  //   if (headers) {
  //     items.unshift(headers);
  //   }

  //   // Convert Object to JSON
  //   var jsonObject = JSON.stringify(items);

  //   var csv = convertToCSV(jsonObject);

  //   var exportedFilenmae = fileTitle + ".csv" || "export.csv";

  //   var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //   if (navigator.msSaveBlob) {
  //     // IE 10+
  //     navigator.msSaveBlob(blob, exportedFilenmae);
  //   } else {
  //     var link = document.createElement("a");
  //     if (link.download !== undefined) {
  //       // feature detection
  //       // Browsers that support HTML5 download attribute
  //       var url = URL.createObjectURL(blob);
  //       link.setAttribute("href", url);
  //       link.setAttribute("download", exportedFilenmae);
  //       link.style.visibility = "hidden";
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   }
  // };

  const convertTable = async () => {
    if (src.length <= 100) {
      toast.error("Source code not long enough");
      await setCurrentStage(99);
      return;
    }
    try {
      const $ = cheerio.load(src);
      await setCurrentStage(2);
      const trow = $("table > tbody > tr");
      let compSet = [];
      trow.each((index, element) => {
        const tds = $(element).find("td");

        const imgTd = $(tds[0]);
        const year = $(tds[1]).text();
        const badge = $(tds[2]).text();
        const seller = $(tds[3]).text();
        const state = $(tds[4]).text();
        const colour = $(tds[5]).text();
        const dap = $(tds[6]).text();
        const egc = $(tds[7]).text();
        const kms = $(tds[8]).text();
        const age = $(tds[9]).text();
        const delisted = $(tds[10]).text();
        const watched = $(tds[11]).text();
        const egcNumercial = parseInt(
          egc
            .replace("$", "")
            .replace("*", "")
            .replace(",", "")
            .replace(" ", "")
        );
        const dapNumercial = parseInt(
          dap
            .replace("$", "")
            .replace("*", "")
            .replace(",", "")
            .replace(" ", "")
        );
        const kmsNumerical = parseInt(
          kms
            .replace("$", "")
            .replace("*", "")
            .replace(",", "")
            .replace(" ", "")
        );

        const leadratio = $(tds[12]).text();
        const programs = $(tds[13]).text();
        const tags = $(tds[14]).text();

        const imgTag = imgTd.find("img");

        const payload = {
          carsalesId: imgTag["0"]["attribs"]["id"],
          imageSrc: imgTag["0"]["attribs"]["src"],
          year,
          badge,
          seller,
          state,
          colour,
          dap,
          egc,
          egcNumercial,
          dapNumercial,
          kmsNumerical,
          kms,
          delisted,
          age,
          watched,
          leadratio,
          programs,
          tags,
        };
        compSet.push(payload);
      });
      setOutput(compSet);
      return compSet;
    } catch (e) {
      await setCurrentStage(99);
      return false;
    }
  };

  // Bulk set stage based on selectedRowIds
  const saveToDbInBulk = async (data) => {
    setIsLoading(true);
    // Get a new write batch
    try {
      await setCurrentStage(4);
      var batch = firestore.batch();
      for (var i = 0; i < data.length; i++) {
        const d = data[i];
        const ref = firestore.collection("competitive-set").doc(d.carsalesId);
        batch.set(ref, d, { merge: true });
      }
      // Commit the batch
      await batch.commit();
      await setCurrentStage(5);
    } catch (e) {
      await setCurrentStage(99);
      console.log(e);
      setErrorMessage(e.message);
    }

    setIsLoading(false);
  };

  const handleConvert = async () => {
    const compSet = await convertTable();
    // console.log(compSet);
    if (!compSet) {
      toast.error("Malformed source, try copying again");
      await setCurrentStage(99);
      return;
    }
    // Save to database
    if (compSet.length > 0) {
      await setCurrentStage(3);
      await saveToDbInBulk(compSet);
    } else {
      toast.warning("No data points found, please try copying again");
      await setCurrentStage(99);
    }

    // exportCSVFile(headers, compSet, "output");
  };

  const pasteFromClipboard = async () => {
    await navigator.clipboard
      .readText()
      .then((t) => setSrc(t))
      .catch((e) => {
        setCurrentStage(99);
      });
    // wait 10 seconds
    // await sleep(10000);
    await setCurrentStage(1);
  };

  const resetProcess = () => {
    setCurrentStage(0);
    setSrc("");
    setOutput([]);
    setIsLoading(false);
  };

  // const getTotalCount = async () => {
  //   const coll = collection(firestore, "competitive-set");
  //   const snapshot = await getCountFromServer(coll);
  //   console.log("count: ", snapshot.data().count);
  // };

  const generateReport = async () => {
    const collectionRef = firestore.collection("competitive-set");
    const output = await collectionRef
      .get()
      .then((querySnapshot) => {
        const output = [];
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          output.push({ id, ...data });
        });
        return output;
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        return [];
      });
    const totalItems = output.length;
    const dayConcat = output.map((item) =>
      dayjs.unix(item.createdDate).format("DDMMYY")
    );
    console.log(totalItems);
  };

  useEffect(() => {
    if (src.length > 100) {
      handleConvert();
    }
  }, [src]);

  useEffect(() => {
    // generateReport();
  }, []);

  return (
    <Container>
      <ToastContainer />
      <Row>
        <DailyActions />
      </Row>
      <Row>
        <Col>Total count</Col>
        <Col>
          {/* <pre>{JSON.stringify(countArray, null, 2)}</pre> */}
          N/A
        </Col>
      </Row>

      <Row>
        <Col>
          <ListGroup>
            <ListGroup.Item active={currentStage === 0}>
              Step 1: Use chrome extention to copy to clipboard
            </ListGroup.Item>
            <ListGroup.Item active={currentStage === 1}>
              Step 2: Click{" "}
              <Button
                variant="outline-success"
                disabled={isLoading}
                onClick={pasteFromClipboard}
              >
                Convert clipboard data
              </Button>{" "}
              button
            </ListGroup.Item>
            <ListGroup.Item active={currentStage === 2}>
              Confirming number of data points derived from copy
            </ListGroup.Item>
            <ListGroup.Item active={currentStage === 3}>
              Confirmed: {output.length} data points found
            </ListGroup.Item>
            <ListGroup.Item
              variant={currentStage === 4 ? "warning" : "outline-warning"}
            >
              Saving to database
            </ListGroup.Item>
            <ListGroup.Item
              variant={currentStage === 5 ? "success" : "outline-success"}
              // active={currentStage === 5}
            >
              Saved to database
            </ListGroup.Item>
            <ListGroup.Item
              variant={currentStage === 99 ? "danger" : "outline-danger"}
            >
              Failed{" "}
              {errorMessage.length > 0
                ? `: ${errorMessage}`
                : currentStage === 99
                ? ": Other"
                : null}
            </ListGroup.Item>
            <ListGroup.Item>
              <Button block onClick={resetProcess} variant="outline-danger">
                Reset{" "}
              </Button>{" "}
              <small>
                <i>
                  click this if you get stuck on any of these steps and try
                  again
                </i>
              </small>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      {/* <Row>
        <Col></Col>
      </Row> */}
      {/* <Row>
        <Col>
          <pre>{JSON.stringify(output, null, 2)}</pre>
        </Col>
      </Row> */}
      {output.length > 0 ? (
        <Row style={{ fontWeight: "bolder", marginTop: 10 }}>
          <Col>Image</Col>
          <Col>ID</Col>
          <Col>Badge</Col>
        </Row>
      ) : null}
      {output.map((el, idx) => {
        return (
          <Row key={idx}>
            <Col>
              <img src={el.imageSrc} width={100} alt={`veh-${idx}`} />
            </Col>
            <Col>{el.carsalesId}</Col>
            <Col>{el.badge}</Col>
          </Row>
        );
      })}
    </Container>
  );
};

export default CompetitiveSet;
