import { groupBy as rowGrouper } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useMemo, useCallback } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContainer from "react-bootstrap/TabContainer";
import { Formik } from "formik";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import { useEffect } from "react";
import { useRef } from "react";
import NumberFormat from "react-number-format";
import DataGrid from "react-data-grid";
import dayjs from "dayjs";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import BookingCard from "./BookingCard";
import PageViewLoggerComponent from "../PageViewLoggerComponent";
// import { FaTruckMoving } from "react-icons/fa";
import { HiOutlineTruck } from "react-icons/hi";
import { BsBoxArrowInRight } from "react-icons/bs";
import { FaSearch, FaFilter, FaRegTimesCircle } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory } from "react-router";
import { CgDetailsMore } from "react-icons/cg";
import TextField from "./TextField";
import Accordion from "react-bootstrap/Accordion";
import { Vinyl } from "react-bootstrap-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// const topProfitDataSet = require("../../assets/testdata/top_profit_test.json");
// const topVolumeDataSet = require("../../assets/testdata/top_volume_test.json");
// const topLeadsDataSet = require("../../assets/testdata/top_leads_test.json");

const numericalASCProfit = (a, b) => {
  if (a.TrueProfit < b.TrueProfit) {
    return -1;
  }
  if (a.TrueProfit > b.TrueProfit) {
    return 1;
  }
  return 0;
};

const style = {
  dataGridFill: {
    height: "70vh",
  },
  searchCard: {
    margin: 5,
    padding: 5,
    border: "1px solid #efefef",
    borderRadius: 3,
    cursor: "pointer",
  },
};

const SearcResultMakeFieldSID = (r) => {
  const [veh, setVeh] = useState({});

  useEffect(() => {
    const tmpVeh = r.vehicleItem.find(
      (v) => v.EclipseStockNumber === parseInt(r.serachQStockId)
    );

    if (tmpVeh) {
      setVeh(tmpVeh);
    }
  }, [r]);

  return <p>{veh?.make}</p>;
};

const SearcResultMakeFieldVIN = (r) => {
  const [veh, setVeh] = useState({});

  useEffect(() => {
    const tmpVeh = r.vehicleItem.find((v) => v.VIN === r.searchQVin);

    if (tmpVeh) {
      setVeh(tmpVeh);
    }
  }, [r]);

  return <p>{veh?.make}</p>;
};

const SearchResults = ({ results, searchQ }) => {
  const history = useHistory();
  return (
    <>
      <h4>Search results</h4>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Created</th>
            <th>Make</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {results.map((r) => {
            return (
              <OverlayTrigger
                placement="auto-start"
                delay={{ show: 10, hide: 50 }}
                overlay={<Tooltip id="button-tooltip-2">{r.id}</Tooltip>}
              >
                <tr
                  onClick={() => history.push(`/freight-booking-list/${r.id}`)}
                >
                  <td>{dayjs.unix(r.createdAt?.seconds).format("DD-MM-YY")}</td>
                  {/* <td>{`Click here to open BID:${r.id}`}</td> */}
                  <td>
                    {r.searchQVin === null
                      ? SearcResultMakeFieldSID(r)
                      : SearcResultMakeFieldVIN(r)}
                  </td>
                  <td>{r.status}</td>
                </tr>
              </OverlayTrigger>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const FreightBookingListWholesale = () => {
  const [freightBookingList, setFreightBookingList] = useState([]);
  const [querySearchResults, setQuerySearchResults] = useState([]);
  const [stage, setStage] = useState("Request");
  const [createdOriginSlice, setCreatedOriginSlice] = useState("");
  const [viewTypeRequest, setViewTypeRequest] = useState(1); //0 LIST, 1 CARD
  const [viewTypeBooked, setViewTypeBooked] = useState(0); //0 LIST, 1 CARD
  const [viewTypeTransit, setViewTypeTransit] = useState(0); //0 LIST, 1 CARD
  const [viewTypeFinalised, setViewTypeFinalised] = useState(0); //0 LIST, 1 CARD
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [[sortColumn, sortDirection], setSort] = useState(["id", "NONE"]);

  // CONST has to be in component to access history
  const tableColumns = [
    {
      key: "actions",
      name: "Actions",
      formatter({ row }) {
        return (
          <>
            {row.vehicleItem.length > 1 ? (
              <Badge
                pill
                bg="danger"
                style={{ backgroundColor: "#6371de", color: "#FFF" }}
              >
                {row.vehicleItem.length}
              </Badge>
            ) : null}
            <Button
              variant="link"
              onClick={() => {
                history.push(`/freight-booking-list/${row.id}`, {
                  origin: "ws",
                });
              }}
            >
              <CgDetailsMore style={{ color: "green" }} />
            </Button>
          </>
        );
      },
    },

    { key: "id", name: "Booking Id" },
    {
      key: "createdAt",
      name: "Created",
      formatter({ row }) {
        return (
          <>{dayjs.unix(row["createdAt"].seconds).format("DD-MMM-YY HH:MM")}</>
        );
      },
      width: 160,
    },
    { key: "displayName", name: "Created By" },
    { key: "status", name: "Status" },
    {
      key: "pickup.location.address",
      name: "From",
      formatter({ row }) {
        return <>{row.pickup?.location?.address}</>;
      },
      width: 300,
    },
    {
      key: "dropOff.location.address",
      name: "To",
      formatter({ row }) {
        return <>{row.dropOff?.location?.address}</>;
      },
      width: 300,
    },

    {
      key: "vehicle.stock",
      name: "Stock #",
      formatter({ row }) {
        return <>{row.vehicleItem[0]?.EclipseStockNumber}</>;
      },
    },
    {
      key: "vehicle.branch",
      name: "Origin branch",
      formatter({ row }) {
        return <>{row.vehicleItem[0]?.branch}</>;
      },
      width: 150,
    },
    {
      key: "vehicle.make",
      name: "Make",
      formatter({ row }) {
        return <>{row.vehicleItem[0]?.make}</>;
      },
    },
    {
      key: "vehicle.model",
      name: "Model",
      formatter({ row }) {
        return <>{row.vehicleItem[0]?.model}</>;
      },
    },
    {
      key: "carrierData.carrier.carrier",
      name: "Carrier",
      formatter({ row }) {
        return <>{row.carrierData?.carrier?.carrier}</>;
      },
    },
    {
      key: "carrierData.estimatedCost",
      name: "Carrier $EST",
      formatter({ row }) {
        return (
          <>
            <NumberFormat
              thousandSeparator={true}
              displayType="text"
              prefix={`$ `}
              value={row.carrierData?.estimatedCost}
            />
          </>
        );
      },
    },
    {
      key: "carrierData.estimatedCollectionDate",
      name: "ETC",
      formatter({ row }) {
        return <>{row.carrierData?.estimatedCollectionDate}</>;
      },
      width: 100,
    },
    {
      key: "carrierData.estimatedDeliveryDate",
      name: "ETD",
      formatter({ row }) {
        return <>{row.carrierData?.estimatedDeliveryDate}</>;
      },
      width: 100,
    },
    {
      key: "carrierData.actualDeliveredDate",
      name: "ADD",
      formatter({ row }) {
        return <>{row.carrierData?.actualDeliveredDate}</>;
      },
      width: 100,
    },
    {
      key: "carrierData.daysToDelivered",
      name: "DTD",
      formatter({ row }) {
        const ad = dayjs(
          row.carrierData?.actualDeliveredDate || undefined,
          "YYYY-MM-DD"
        );
        const cdu = row.createdAt.seconds;
        const cd = dayjs.unix(cdu);

        const dtd = ad.diff(cd, "day");
        if (!isNaN(dtd)) {
          return <>{dtd}</>;
        } else {
          return <>N/A</>;
        }
      },
      width: 100,
    },
  ];

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const history = useHistory();

  // SORT rows
  const sortedRows = useMemo(() => {
    if (sortDirection === "NONE") return rows;

    let sortedRows = [...rows];

    console.log(sortColumn);

    switch (sortColumn) {
      case "createdAt":
        console.log(sortedRows[0].createdAt.seconds);
        sortedRows = sortedRows.sort(
          (a, b) => a[sortColumn].seconds > b[sortColumn].seconds
        );
        break;

      case "id":
      case "status":
      case "displayName":
      case "carrierData.carrier.carrier":
        sortedRows = sortedRows.sort((a, b) => a[sortColumn] - b[sortColumn]);
        console.log(sortedRows);
        break;
      default:
    }

    return sortDirection === "DESC" ? sortedRows.reverse() : sortedRows;
  }, [rows, sortDirection, sortColumn]);

  const handleSort = useCallback((columnKey, direction) => {
    console.log("s", columnKey, direction);
    setSort([columnKey, direction]);
  }, []);

  const updateStage = (e) => {
    setStage(e);
  };
  // Load dataset on mount
  useEffect(() => {
    // Filter by created origin (SugarCRM ...)
    if (createdOriginSlice === "SugarCRM") {
      setFreightBookingList([]);
      setIsLoading(true);
      const unsubscribe = firestore
        .collection("freight-bookings")
        .where("status", "==", stage)
        .where("extraDetails.apiOrigin", "==", createdOriginSlice)
        .orderBy("createdAt", "asc")
        //   .get()
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            toast.info("No open requests available at this time");
            setIsLoading(false);
            setFreightBookingList([]);
            return;
          }
          const tmpBookings = [];

          snapshot.forEach((doc) => {
            const tmpData = doc.data();
            tmpBookings.push({ ...tmpData, id: doc.id });
            //   console.log(`Getting bookings`);
          });
          // console.log(tmpBookings);
          setFreightBookingList(tmpBookings);
          setIsLoading(false);
        });

      return () => unsubscribe;
    } else {
      setFreightBookingList([]);
      setIsLoading(true);
      const unsubscribe = firestore
        .collection("freight-bookings")
        .where("status", "==", stage)
        .where("indexBranch", "array-contains-any", [
          "WS QLD",
          "WS VIC",
          "Corporate Buying",
        ])
        .orderBy("createdAt", "asc")
        //   .get()
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            toast.info("No open requests available at this time");
            setIsLoading(false);
            setFreightBookingList([]);
            return;
          }
          const tmpBookings = [];

          snapshot.forEach((doc) => {
            const tmpData = doc.data();
            tmpBookings.push({ ...tmpData, id: doc.id });
            //   console.log(`Getting bookings`);
          });
          // console.log(tmpBookings);
          setFreightBookingList(tmpBookings);
          setIsLoading(false);
        });

      return () => unsubscribe;
    }
  }, [stage, createdOriginSlice]);

  useEffect(() => {
    setRows(freightBookingList);
  }, [freightBookingList]);

  return (
    <>
      <Button
        style={{
          position: "absolute",
          right: 0,
          top: 80,
          height: 38,
          width: 42,
          zIndex: 99,
          borderRadius: 0,
          boxShadow: "1px 0px 7px rgba(0, 0, 0, 0.5)",
        }}
        onClick={() => setShowSearchModal(!showSearchModal)}
      >
        <FaSearch />
      </Button>
      {/* <Button
        style={{
          position: "absolute",
          backgroundColor: "#f38120",
          right: 0,
          top: 80 + 40,
          height: 38,
          width: createdOriginSlice === "SugarCRM" ? 65 : 42,
          zIndex: 99,
          borderRadius: 0,
          borderColor: "#f38120",
          boxShadow:
            createdOriginSlice === "SugarCRM"
              ? "1px 1px 7px rgba(0, 0, 0, 0.6)"
              : "1px 0px 7px rgba(0, 0, 0, 0.5)",
        }}
        onClick={() => setCreatedOriginSlice("SugarCRM")}
      >
        {createdOriginSlice === "SugarCRM" ? "S" : "S"}
      </Button> */}
      <Button
        variant="success"
        style={{
          position: "absolute",
          right: 0,
          top: 80 + 40 + 40,
          height: 38,
          width: createdOriginSlice === "" ? 65 : 42,
          zIndex: 99,
          borderRadius: 0,
          boxShadow:
            createdOriginSlice === ""
              ? "1px 5px 7px rgba(0, 0, 0, 0.5)"
              : "1px 0px 7px rgba(0, 0, 0, 0.5)",
        }}
        onClick={() => setCreatedOriginSlice("")}
      >
        {createdOriginSlice === "" ? "All" : "A"}
      </Button>
      <Modal
        show={showSearchModal}
        onHide={() => setShowSearchModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Formik
                initialValues={{ searchVIN: "", searchStockId: "" }}
                // validationSchema={validationSchema}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={async (values, { resetForm }) => {
                  if (
                    values.searchVIN.length > 15 ||
                    values.searchVIN.length === 6
                  ) {
                    //   // Search through all vehicles for a true find of full VIN

                    await firestore
                      .collection("freight-bookings")
                      .where("indexVIN", "array-contains-any", [
                        values.searchVIN,
                      ])
                      .get()
                      .then((querySnapshot) => {
                        toast.success(`Found ${querySnapshot.size} items`);

                        const tmpSearch = [];
                        querySnapshot.forEach((doc) => {
                          const data = doc.data();
                          const id = doc.id;
                          tmpSearch.push({
                            id,
                            ...data,
                            searchQVin: values.searchVIN,
                            serachQStockId: null,
                          });
                        });
                        setQuerySearchResults(tmpSearch);
                      });
                  }
                  if (values.searchStockId.length >= 3) {
                    console.log(values.searchStockId);
                    // Search firestore
                    await firestore
                      .collection("freight-bookings")
                      .where("indexStockId", "array-contains-any", [
                        parseInt(values.searchStockId),
                      ])
                      .get()
                      .then((querySnapshot) => {
                        toast.success(`Found ${querySnapshot.size} items`);

                        const tmpSearch = [];
                        querySnapshot.forEach((doc) => {
                          const data = doc.data();
                          const id = doc.id;
                          tmpSearch.push({
                            id,
                            ...data,
                            searchQVin: null,
                            serachQStockId: values.searchStockId,
                          });
                        });
                        setQuerySearchResults(tmpSearch);
                      });
                  }
                }}
              >
                {/* Callback function containing Formik state and helpers that handle common form actions */}
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  isSubmitting,
                  resetForm,
                }) => (
                  <Form>
                    <Row>
                      <Col>
                        <TextField
                          fieldname={`searchStockId`}
                          label=""
                          placeholder="Search by StockId"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextField
                          fieldname={`searchVIN`}
                          label=""
                          placeholder="Search by VIN"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ButtonGroup>
                          <Button
                            variant="primary"
                            onClick={handleSubmit}
                            block
                          >
                            <FaSearch /> Search
                          </Button>
                          <Button
                            variant="outline-secondary"
                            onClick={() => {
                              resetForm();
                              setRows(freightBookingList);
                              setQuerySearchResults([]);
                            }}
                          >
                            Clear
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
            {querySearchResults.length > 0 ? (
              <Col>
                <SearchResults results={querySearchResults} />
              </Col>
            ) : null}
          </Row>
        </Modal.Body>
      </Modal>
      <Tabs
        onSelect={updateStage}
        defaultActiveKey="Request"
        id="uncontrolled-tab-bookings-flow"
      >
        <Tab eventKey="Request" title="Open">
          {isLoading ? <Spinner animation="grow" /> : null}
          <br />
          <DataGrid
            defaultColumnOptions={{ resizable: true, sortable: true }}
            columns={tableColumns}
            style={style.dataGridFill}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            rows={sortedRows}
            onSort={handleSort}
          />
        </Tab>
        <Tab eventKey="Booked" title="Booked">
          {isLoading ? <Spinner animation="grow" /> : null}
          {/* Switch between list and card */}
          <br />
          <DataGrid
            defaultColumnOptions={{ resizable: true, sortable: true }}
            columns={tableColumns}
            style={style.dataGridFill}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            rows={sortedRows}
            onSort={handleSort}
          />
        </Tab>
        <Tab eventKey="Transit" title="In Transit">
          {isLoading ? <Spinner animation="grow" /> : null}
          <br />
          <DataGrid
            defaultColumnOptions={{ resizable: true, sortable: true }}
            columns={tableColumns}
            style={style.dataGridFill}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            rows={sortedRows}
            onSort={handleSort}
          />
        </Tab>
        <Tab eventKey="Finalised" title="Finalised">
          {isLoading ? <Spinner animation="grow" /> : null}
          <br />
          <DataGrid
            defaultColumnOptions={{ resizable: true, sortable: true }}
            columns={tableColumns}
            style={style.dataGridFill}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            rows={sortedRows}
            onSort={handleSort}
          />
        </Tab>
        <Tab
          eventKey="origin"
          title={createdOriginSlice === "SugarCRM" ? "SugarCRM" : "All"}
          disabled
        ></Tab>
      </Tabs>
      <ToastContainer />
      <PageViewLoggerComponent />
    </>
  );
};

export default FreightBookingListWholesale;
