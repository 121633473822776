import React, { useEffect, useRef } from "react";
import {
  Accordion,
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";

import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// Sort functions
const numericalASCProfit = (a, b) => {
  if (a.TrueProfit < b.TrueProfit) {
    return -1;
  }
  if (a.TrueProfit > b.TrueProfit) {
    return 1;
  }
  return 0;
};

const sortDESCDate = (a, b) => {
  if (a["Sale Date EPOC"] < b["Sale Date EPOC"]) {
    return 1;
  }
  if (a["Sale Date EPOC"] > b["Sale Date EPOC"]) {
    return -1;
  }
  return 0;
};

const sortASCDivision = (a, b) => {
  if (a["Division"] > b["Division"]) {
    return 1;
  }
  if (a["Division"] < b["Division"]) {
    return -1;
  }
  return 0;
};

const divisions = [
  "D1 MELBOURNE",
  "D1 WESTGATE",
  "D1 MOORABBIN",
  "D1 BOX HILL",
  "D1 FAIRFIELD",
  "D1 MULGRAVE",
  "D1 ESSENDON FIELDS",
];

const MAX_DATE_RANGE_SELECTION = 31;

const stickyTableHeaderStyle = {
  position: "sticky",
  margin: "0 0 0 0",
  top: -5,
  backgroundColor: "#566573",
  color: "white",
  fontSize: 12,
  border: 0,
};

const sectionHeaderStyle = {
  marginTop: 10,
  fontWeight: "bold",
  textDecoration: "underline",
  backgroundColor: "#566573",
  fontSize: 12,
};

function DuttonOneSoldCalendarComponent() {
  // STATE
  const [toggleRange, setToggleRange] = React.useState(false);
  const [range, setRange] = React.useState([
    dayjs().add(-1, "day").format("YYYY-MM-DD"),
    dayjs().format("YYYY-MM-DD"),
  ]);
  const [waitingForSecondDate, setWaitingForSecondDate] = React.useState(false);
  const [firstDateSelected, setFirstDateSelected] = React.useState(null);
  const [dataSet, setDataSet] = React.useState([]);
  const [groupedData, setGroupedData] = React.useState([]);
  const [groupedBySellerData, setGroupedBySellerData] = React.useState([]);

  const [loadingDelivered, setLoadingDelivered] = React.useState(false);
  const [loadingSoldNotDelivered, setLoadingSoldNotDelivered] =
    React.useState(false);
  const [loadingPendingSales, setLoadingPendingSales] = React.useState(false);

  const [totalDeliveredProfit, setTotalDeliveredProfit] = React.useState(0);
  const [totalDelivered, setTotalDelivered] = React.useState(0);
  const [totalSoldNotDelivered, setTotalSoldNotDelivered] = React.useState(0);
  const [totalPendingSales, setTotalPendingSales] = React.useState(0);
  const [totalPendingSalesProfit, setTotalPendingSalesProfit] =
    React.useState(0);
  const [totalSalesProfit, setTotalSalesProfit] = React.useState(0);

  //   HOOKS
  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const calendarRef = useRef();

  //   ACTION HANDLERS
  //   Toggle selecing a range of dates
  const toggleRangeHandler = (e) => {
    if (e.target.checked === true) {
      calendarRef.current.value = null;
    }
    setToggleRange(e.target.checked);
  };

  const clearValues = () => {
    setDataSet([]);
    setGroupedData([]);
    setTotalDeliveredProfit(0);
    setTotalDelivered(0);
    setTotalSoldNotDelivered(0);
    setTotalPendingSales(0);
    setTotalPendingSalesProfit(0);
    setTotalSalesProfit(0);
  };

  //   Handle date selection
  const handleChange = (value) => {
    // Check if a range is selected or just one date
    const isRange = Array.isArray(value);
    if (isRange && value[1] === null) {
      setWaitingForSecondDate(true);
      setFirstDateSelected(value[0]);
      return;
    }
    setWaitingForSecondDate(false);
    setFirstDateSelected(null);

    if (isRange) {
      // if range is > 30 days, return
      if (
        dayjs(value[1]).diff(dayjs(value[0]), "day") > MAX_DATE_RANGE_SELECTION
      ) {
        toast.info(
          `Please select a range of less than ${MAX_DATE_RANGE_SELECTION} days`
        );
        return;
      }
      const startDate = dayjs(value[0]).format("YYYY-MM-DD");
      const endDate = dayjs(value[1]).format("YYYY-MM-DD");
      setRange([startDate, endDate]);
    } else {
      const startDate = dayjs(value).format("YYYY-MM-DD");
      const endDate = dayjs(value).add(1, "day").format("YYYY-MM-DD");
      setRange([startDate, endDate]);
    }
  };

  const getSalesSoldNotDelivered = async () => {
    return await firestore
      .collection("inventory-summary")
      .where("CurrentStatus", "==", "SOLD - NOT DELIVERED")
      .where("branch_desc", "in", divisions)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          toast.info("No summary available for this date or range");
          return;
        }
        const tmpDataSet = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpDataSet.push({ ...tmpData, id: doc.id });
        });

        return tmpDataSet;
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
        return false;
      });
  };

  const getSalesDelivered = async (saleClass) => {
    return await firestore
      .collection("salesSummary")
      .where("Sale Date EPOC", ">=", dayjs(range[0]).unix())
      .where("Sale Date EPOC", "<=", dayjs(range[1]).unix())
      .where("Division", "in", divisions)
      .where("sale_class", "==", saleClass)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          toast.info("No summary available for this date or range");
          return;
        }
        const tmpDataSet = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpDataSet.push({ ...tmpData, id: doc.id, sale_type: "DELIVERED" });
        });
        tmpDataSet.sort(numericalASCProfit).reverse();
        tmpDataSet.sort(sortDESCDate);
        return tmpDataSet;
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
        return false;
      });
  };

  const getPendingSaleByStockNumberAndVIN = async (
    stockNo,
    vin,
    saleClass,
    vehicleSale
  ) => {
    return await firestore
      .collection("pendingSalesSummary")
      .where("Stock No", "==", stockNo)
      .where("VEHVIN", "==", vin)
      .where("sale_class", "==", saleClass)
      .limit(1)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return false;
        }
        const tmpDataSet = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          // Check SaleDate is within the range
          const saleDate = tmpData["SaleDate"]?.seconds;
          const rangeStart = dayjs(range[0]).unix();
          const rangeEnd = dayjs(range[1]).unix();
          // if (saleDate >= rangeStart && saleDate <= rangeEnd) {
          const profit =
            Math.floor(parseInt(tmpData?.SalePriceInc / 1.1)) -
            parseInt(vehicleSale["Inventory_Amount"]);
          tmpDataSet.push({
            ...tmpData,
            id: doc.id,
            sale_type: "PENDING",
            TrueProfit: profit,
          });
          // }
        });
        return tmpDataSet;
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
        return false;
      });
  };

  const getAllData = async () => {
    clearValues();
    const saleClass = "R";
    setLoadingDelivered(true);
    const salesDelivered = await getSalesDelivered(saleClass);
    setLoadingDelivered(false);
    setLoadingSoldNotDelivered(true);
    const salesSoldNotDelivered = await getSalesSoldNotDelivered();
    setLoadingSoldNotDelivered(false);
    if (!salesDelivered || !salesSoldNotDelivered) {
      return;
    }

    const pendingSales = [];
    setLoadingPendingSales(true);
    for (let i = 0; i < salesSoldNotDelivered.length; i++) {
      const vehicleSale = salesSoldNotDelivered[i];
      const stockNo = salesSoldNotDelivered[i]["VEHStockNumber"];
      const vin = salesSoldNotDelivered[i]["VEHVIN"];

      const pendingSale = await getPendingSaleByStockNumberAndVIN(
        stockNo,
        vin,
        saleClass,
        vehicleSale
      );
      if (pendingSale) {
        pendingSales.push(pendingSale);
      }
    }
    setLoadingPendingSales(false);

    const allData = [...salesDelivered, ...pendingSales.flat()];

    return allData;
  };

  const groupBySeller = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const key = item["VEH_Seller"] || "Pending Sales ( Grouped )";
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
    return groupedData;
  };

  const groupByDivision = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const key = item["Division"];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
    return groupedData;
  };

  //   EFFECTS
  // Get sales and deliveries and pending sales for the selected date(s)
  useEffect(() => {
    setDataSet([]);

    const fetchData = async () => {
      // console.log("Fetching data for range: ", range);
      const data = await getAllData();
      if (!data) {
        return;
      }
      // Calculate total delivered
      const tmpTotalDelivered = data?.filter(
        (d) => d["sale_type"] === "DELIVERED"
      );
      const tmpTotalDeliveredProfit = tmpTotalDelivered?.reduce(
        (acc, item) => acc + item["TrueProfit"],
        0
      );

      setTotalDelivered(tmpTotalDelivered);
      setTotalDeliveredProfit(tmpTotalDeliveredProfit);

      // Calculate total pending sales
      const tmpTotalPendingSales = data?.filter(
        (d) => d["sale_type"] === "PENDING"
      );
      const tmpTotalPendingSalesProfit = tmpTotalPendingSales?.reduce(
        (acc, item) => acc + item["TrueProfit"],
        0
      );

      setTotalPendingSales(tmpTotalPendingSales);
      setTotalPendingSalesProfit(tmpTotalPendingSalesProfit);

      // Calculate total pending sales

      const tmpSalesProfit = data?.reduce(
        (acc, item) => acc + item["TrueProfit"],
        0
      );

      setTotalSalesProfit(tmpSalesProfit);

      setDataSet(data);
    };

    if (range[0] && range[1]) {
      fetchData();
    }
  }, [range]);

  //   When new data is available, group by division
  useEffect(() => {
    let groupedData = groupByDivision(dataSet);
    let groupedBySellerData = groupBySeller(dataSet);

    setGroupedData(groupedData);
    setGroupedBySellerData(groupedBySellerData);
  }, [JSON.stringify(dataSet)]);

  return (
    <Container>
      <ToastContainer />
      <Row>
        <Col>
          <Alert variant="info" dismissible>
            Choose a date or a range of dates to view the sales and deliveries
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="">
            <Card.Header>
              <Card.Title style={{ fontSize: 14 }}>
                <Row>
                  <Col>Start: {range[0]}</Col>
                  <Col>End: {range[1]}</Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <Form.Check
                      type="switch"
                      label="Select range"
                      id="toggle-range"
                      onChange={toggleRangeHandler}
                    />
                  </Col>
                </Row>
              </Card.Title>
            </Card.Header>
            <Card.Body className="align-self-center">
              <Calendar
                selectRange={toggleRange}
                maxDate={new Date()}
                onChange={handleChange}
                defaultValue={new Date(Date.now() - 864e5)}
                allowPartialRange={true}
                inputRef={calendarRef}
              />
            </Card.Body>
            <Card.Footer>
              <i>
                {waitingForSecondDate ? (
                  <Badge style={{ padding: 10 }} variant="warning">
                    Select end date in range
                  </Badge>
                ) : null}
              </i>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          {loadingDelivered && `Loading delivered sales...   `}
          {loadingPendingSales && `Loading pending sales...   `}
          {loadingDelivered && (
            <Spinner animation="border" variant="success" size="sm" />
          )}
          {loadingPendingSales && (
            <Spinner animation="border" variant="success" size="sm" />
          )}

          {dataSet.length === 0 &&
            !loadingDelivered &&
            !loadingPendingSales && (
              <Alert variant="warning">
                No sales or deliveries for the selected date or range
              </Alert>
            )}
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <i>
            Showing sales between: {range[0]} to {range[1]}
          </i>
        </Col>
      </Row>
      {/* TOTALS TABLE */}
      <Row className="mt-2">
        <Col>
          <Table striped bordered hover size="sm">
            <thead
              style={{
                ...stickyTableHeaderStyle,
              }}
            >
              <tr>
                <th>Status</th>
                <th>Total #</th>
                <th>Total True Profit (Metal and Warranty)</th>
              </tr>
            </thead>

            <tbody
              style={{
                fontSize: 12,
              }}
            >
              <tr>
                <td>Total Delivered</td>
                <td>{totalDelivered?.length || 0}</td>
                <td>
                  <NumberFormat
                    value={totalDeliveredProfit}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={0}
                  />
                </td>
              </tr>

              <tr>
                <td>Total Pending</td>
                <td>{totalPendingSales?.length || 0}</td>
                <td>
                  <NumberFormat
                    value={totalPendingSalesProfit}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={0}
                  />
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td>{dataSet?.length || 0}</td>
                <td>
                  <NumberFormat
                    value={totalSalesProfit}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={0}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      {/* UNITS TABLE BY BRANCH */}
      <Row className="mt-2">
        <Col>
          <Table striped bordered hover size="sm">
            <thead
              style={{
                ...stickyTableHeaderStyle,
              }}
            >
              <tr>
                <th colSpan={divisions.length + 1}>Units</th>
              </tr>
              <tr>
                <td>&nbsp;</td>
                {divisions.map((key, divisionIdx) => {
                  return <th key={divisionIdx}>{key}</th>;
                })}
              </tr>
            </thead>
            <tbody
              style={{
                fontSize: 12,
              }}
            >
              <tr>
                <td>Delivered</td>
                {divisions.map((key, divisionIdx) => {
                  return (
                    <td key={divisionIdx}>
                      {groupedData[key]?.filter(
                        (d) => d?.sale_type === "DELIVERED"
                      ).length || 0}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>Pending</td>
                {divisions.map((key, divisionIdx) => {
                  return (
                    <td key={divisionIdx}>
                      {groupedData[key]?.filter(
                        (d) => d?.sale_type === "PENDING"
                      ).length || 0}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>Total</td>
                {divisions.map((key, divisionIdx) => {
                  return (
                    <td key={divisionIdx}>{groupedData[key]?.length || 0}</td>
                  );
                })}
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      {/* PROFIT TABLE BY BRANCH */}
      <Row className="mt-2">
        <Col>
          <Table striped bordered hover size="sm">
            <thead
              style={{
                ...stickyTableHeaderStyle,
              }}
            >
              <tr>
                <th colSpan={divisions.length + 1}>Profit</th>
              </tr>
              <tr>
                <td>&nbsp;</td>
                {divisions.map((key, divisionIdx) => {
                  return <th key={divisionIdx}>{key}</th>;
                })}
              </tr>
            </thead>
            <tbody
              style={{
                fontSize: 12,
              }}
            >
              <tr>
                <td>Delivered</td>
                {divisions.map((key, divisionIdx) => {
                  return (
                    <td key={divisionIdx}>
                      <NumberFormat
                        value={
                          groupedData[key]
                            ?.filter((d) => d?.sale_type === "DELIVERED")
                            ?.reduce(
                              (acc, item) => acc + item["TrueProfit"],
                              0
                            ) || 0
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={0}
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>Pending</td>
                {divisions.map((key, divisionIdx) => {
                  return (
                    <td key={divisionIdx}>
                      <NumberFormat
                        value={
                          groupedData[key]
                            ?.filter((d) => d?.sale_type === "PENDING")
                            ?.reduce(
                              (acc, item) => acc + item["TrueProfit"],
                              0
                            ) || 0
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={0}
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>Total</td>
                {divisions.map((key, divisionIdx) => {
                  return (
                    <td key={divisionIdx}>
                      <NumberFormat
                        value={
                          groupedData[key]?.reduce(
                            (acc, item) => acc + item["TrueProfit"],
                            0
                          ) || 0
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={0}
                      />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      {/* SELLER SUMMARY */}

      <Row className="mt-2">
        <Col>
          <Table striped bordered hover size="sm">
            <thead
              style={{
                ...stickyTableHeaderStyle,
              }}
            >
              <tr>
                <th colSpan={divisions.length + 1}>Sales person summary</th>
              </tr>
              <tr>
                <td>&nbsp;</td>
                {divisions.map((key, divisionIdx) => {
                  return <th key={divisionIdx}>{key}</th>;
                })}
              </tr>
            </thead>
            <tbody
              style={{
                fontSize: 12,
              }}
            >
              {groupedBySellerData &&
                Object.keys(groupedBySellerData)
                  .sort()
                  .map((key, sellerIdx) => {
                    return (
                      <tr key={sellerIdx}>
                        <td>{key}</td>
                        {divisions.map((division, divisionIdx) => {
                          return (
                            <td
                              key={divisionIdx}
                              style={{
                                color:
                                  groupedBySellerData[key]?.filter(
                                    (d) => d["Division"] === division
                                  )?.length > 0
                                    ? "green"
                                    : null,
                              }}
                            >
                              <NumberFormat
                                value={
                                  groupedBySellerData[key]
                                    ?.filter((d) => d["Division"] === division)
                                    ?.reduce(
                                      (acc, item) => acc + item["TrueProfit"],
                                      0
                                    ) || 0
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={0}
                              />{" "}
                              /{" "}
                              {groupedBySellerData[key]?.filter(
                                (d) => d["Division"] === division
                              )?.length || 0}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              {/* <tr>
                <td>Pending</td>
                {divisions.map((key, divisionIdx) => {
                  return (
                    <td key={divisionIdx}>
                      <NumberFormat
                        value={
                          groupedData[key]
                            ?.filter((d) => d?.sale_type === "PENDING")
                            ?.reduce(
                              (acc, item) => acc + item["TrueProfit"],
                              0
                            ) || 0
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={0}
                      />
                    </td>
                  );
                })}
              </tr> */}
              {/* <tr>
                <td>Total</td>
                {divisions.map((key, divisionIdx) => {
                  return (
                    <td key={divisionIdx}>
                      <NumberFormat
                        value={
                          groupedData[key]?.reduce(
                            (acc, item) => acc + item["TrueProfit"],
                            0
                          ) || 0
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={0}
                      />
                    </td>
                  );
                })}
              </tr> */}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <Row>
            <Col>
              <Accordion id={`total-delivered`} defaultActiveKey={-1}>
                <Accordion.Toggle
                  as={ListGroup.Item}
                  eventKey={-1}
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    backgroundColor: "#3f3f3f",
                    color: "white",
                  }}
                >
                  {`Total Vehicles `}
                  <Badge variant={dataSet?.length || 0 ? "primary" : "warning"}>
                    {dataSet?.length || 0}
                    {/* Total TrueProfit */}
                    <span>
                      {" "}
                      @
                      <NumberFormat
                        value={
                          dataSet?.reduce(
                            (acc, item) => acc + item["TrueProfit"],
                            0
                          ) || 0
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        suffix=" TP"
                        decimalScale={0}
                      />
                    </span>
                  </Badge>
                  <span style={{ float: "right" }}>
                    <sup>Click branch to toggle visibility</sup>
                  </span>
                </Accordion.Toggle>
              </Accordion>
              {divisions.map((key, divisionIdx) => {
                return (
                  <Accordion
                    id={divisionIdx + 1}
                    key={divisionIdx}
                    // defaultActiveKey={divisionIdx + 1}
                  >
                    <Accordion.Toggle
                      as={ListGroup.Item}
                      eventKey={divisionIdx + 1}
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        backgroundColor: "#9f9f9f",
                        color: "white",
                      }}
                    >
                      {key}{" "}
                      <Badge
                        variant={
                          groupedData[key]?.length || 0 ? "primary" : "warning"
                        }
                      >
                        {groupedData[key]?.length || 0}
                        {/* Total TrueProfit */}
                        <span>
                          {" "}
                          @
                          <NumberFormat
                            value={
                              groupedData[key]?.reduce(
                                (acc, item) => acc + item["TrueProfit"],
                                0
                              ) || 0
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            suffix=" TP"
                            decimalScale={0}
                          />
                        </span>
                      </Badge>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={divisionIdx + 1}>
                      <Table striped bordered hover size="sm">
                        <thead
                          style={{
                            ...stickyTableHeaderStyle,
                          }}
                        >
                          <tr>
                            <th>Status</th>
                            <th>Sale date</th>
                            <th>Stock No</th>
                            <th>Description</th>
                            <th>Buyer</th>
                            <th>Seller</th>
                            <th>Profit</th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {groupedData[key]?.length === 0 && (
                            <tr>
                              <td colSpan={5}>No data available</td>
                            </tr>
                          )}
                          {groupedData[key]?.map((d, itemIdx) => {
                            return (
                              <tr key={itemIdx}>
                                <td>{d?.sale_type}</td>
                                <td>
                                  {d["sale_type"] === "DELIVERED"
                                    ? dayjs
                                        .unix(d["Sale Date EPOC"])
                                        .format("DD-MM-YYYY")
                                    : dayjs
                                        .unix(d["SaleDate"]?.seconds)
                                        .format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  <Link to={`/vin/${d["VEHVIN"]}`}>
                                    {d["Stock No"]}
                                  </Link>
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {` ${d["VEHModelYear"]}
                                  ${d["VEHMake"]} ${d["VEHModel"]}`}
                                </td>
                                <td>{d["Veh_Buyer"] || ""}</td>
                                <td>{d["VEH_Seller"] || ""}</td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color:
                                      parseInt(d["TrueProfit"]) < 0
                                        ? "red"
                                        : null,
                                  }}
                                >
                                  <NumberFormat
                                    value={d["TrueProfit"]}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    decimalScale={0}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Accordion.Collapse>
                  </Accordion>
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default DuttonOneSoldCalendarComponent;
