import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { Card, Container, FormLabel, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import BackdropComponent from "./BackdropComponent";
import { ToastContainer, toast } from "react-toastify";
import { useUser } from "reactfire";
import dayjs from "dayjs";
import { useWebsiteRepairList } from "../hooks/useWebsiteRepairList";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const RepairItem = ({ item, setAsComplete }) => {
  return (
    <Card style={{ marginTop: 20 }}>
      <Card.Header>
        <div style={{ float: "left" }}>
          {item.brand.split(".")[0].toUpperCase()}
        </div>
        <div style={{ float: "right" }}>
          Status:{" "}
          {item.processing ? (
            <>
              Processing ... <Spinner animation="border" />
            </>
          ) : (
            "Waiting in queue"
          )}
          &nbsp;
          <Button
            variant="outline-danger"
            onClick={() => setAsComplete(item.id)}
          >
            x
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        <a href={item.url} target="_blank">
          {item.url.split("/")[item.url.split("/").length - 1]}
        </a>
      </Card.Body>

      <Card.Footer>
        Reported by: {item.addedByName}
        <br />
        Attempts to repair: {item.attempts}
        <br />
        Added: {dayjs.unix(item.createdDate).format("YYYY/MM/DD HH:mm:ss")}
      </Card.Footer>
    </Card>
  );
};

const WebsiteInventoryRepair = () => {
  const [newUrlToRepair, setNewUrlToRepair] = useState("");
  const [loading, setLoading] = useState(false);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const user = useUser();
  const [repairList] = useWebsiteRepairList();

  // TEST ONLY

  async function deleteCollection() {
    const collectionRef = firestore.collection("website-inventory-repair");

    console.log("Clearing collections");

    const snapshot = await collectionRef.get();

    const batchSize = snapshot.size;
    console.log("Batch size", batchSize);
    if (batchSize === 0) {
      // When there are no documents left, we are done
      return;
    }

    // Delete documents in a batch
    const batch = firestore.batch();
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();
  }
  // END TEST ONLY

  // FOR SU only ** Clear any currently processing jobs **
  const stopProcessingJobs = async () => {
    const currentlyProcessingJobs = await firestore
      .collection("website-inventory-repair")
      .where("processing", "==", true)
      .where("brand", "==", "duttonone.com.au")
      .get()
      .then((r) => {
        const jobs = [];
        if (r.empty) {
          console.log("no jobs in process");
        } else {
          r.forEach((doc) => {
            const id = doc.id;
            const data = doc.data();
            jobs.push({ id, ...data });
          });
        }
        return jobs;
      })
      .catch((e) => {
        console.log(e);
        return [];
      });

    for (var i = 0; i < currentlyProcessingJobs.length; i++) {
      // Set job to not processing
      const job = currentlyProcessingJobs[i];
      console.log("stopping job", job.id);
      await firestore
        .collection("website-inventory-repair")
        .doc(job.id)
        .set({ processing: false }, { merge: true });
    }
  };

  const setAsComplete = async (id) => {
    console.log("setting job ot complete", id);
    await firestore.collection("website-inventory-repair").doc(id).set(
      {
        processing: false,
        completed: true,
        completedDate: dayjs().unix(),
        completedBy: user.data.uid,
        completedEmail: user.data.email,
        completedName: user.data.displayName,
      },
      { merge: true }
    );
  };

  // https://duttonone.com.au/products/
  const addNewItemToRepairQueues = async () => {
    if (newUrlToRepair.length < 40) {
      toast.error("Please enter a valid url");
      return;
    }
    // Check if url exists in queue not completed < 3 attempts
    const existingItem = repairList.find(
      (r) => r.url === newUrlToRepair && !r.completed && r.attempts < 3
    );

    if (existingItem) {
      if (existingItem?.processing) {
        toast.warning(
          "Not adding to queue, this url is currently being processed"
        );
      } else {
        toast.warning(
          "Not adding to queue, this url is still awaiting processing"
        );
      }
      return;
    }

    const handle =
      newUrlToRepair?.split("/")[newUrlToRepair?.split("/").length - 1];
    const brand = newUrlToRepair?.split("/")[2];

    // Push to queue
    const payload = {
      url: newUrlToRepair,
      brand,
      handle,
      createdDate: dayjs().unix(),
      processing: false,
      completed: false,
      attempts: 0,
      addedByUid: user.data.uid,
      addedByName: user.data.displayName,
      addedByEmail: user.data.email,
    };
    console.log(payload);
    firestore
      .collection("website-inventory-repair")
      .add(payload)
      .then((r) => {
        toast.success("Added");
        setNewUrlToRepair("");
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  return (
    <>
      <BackdropComponent loading={loading} />
      <ToastContainer />
      <Container>
        <Row style={{ textAlign: "left" }}>
          <Col>
            <h3>Website inventory repair tool</h3>
            <p>
              This tool can be used when you notice that images on the website
              are not syncronising. Add the url to the repair queue and the
              system will attempt to repair the images.
            </p>
            <p>
              If the images are not showing correctly after multiple attempts,
              please contact the IT department by lodging a supposrt ticket on
              it@duttongroup.com.au
            </p>
          </Col>
        </Row>
        <Row xs={12} md={8} style={{ textAlign: "left" }}>
          <Col>
            <h4>Website url to repair</h4>
            <InputGroup className="mb-3">
              <FormControl
                aria-label="url"
                type="text"
                block
                name="url"
                onChange={(e) => setNewUrlToRepair(e.target.value)}
                value={newUrlToRepair}
              />
              <Button variant="danger" onClick={() => setNewUrlToRepair("")}>
                Clear
              </Button>
            </InputGroup>
          </Col>
        </Row>
        <Row xs={12} md={8} className="justify-content-md-center">
          <Col>
            <InputGroup>
              <Button
                name="submit-btn"
                variant="dark"
                onClick={addNewItemToRepairQueues}
                block
              >
                Add to repair queue
              </Button>
              {user.data.email === "j.jayaram@duttongroup.com.au" ? (
                <Button variant="warning" onClick={stopProcessingJobs} block>
                  Stop processing all open jobs
                </Button>
              ) : null}
            </InputGroup>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row xs={12} md={12} lg={12}>
          <Col>
            {repairList.map((r, idx) => {
              return (
                <RepairItem item={r} key={idx} setAsComplete={setAsComplete} />
              );
            })}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WebsiteInventoryRepair;
