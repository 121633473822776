import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Alert, Badge, Button, Form, Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";
import { HiClipboardCheck } from "react-icons/hi";
import copy from "copy-to-clipboard";
import { useFunctions, useUser } from "reactfire";
import { httpsCallable } from "firebase/functions";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// import { useFirestoreCollectionData } from "reactfire";
// import { collection, query, orderBy } from "firebase/firestore";

const styles = {
  card: {
    marginBottom: 10,
    fontSize: 12,
  },
  formInput: {
    fontSize: 12,
  },
};

const ViewUsersComponent = (props) => {
  const [userRecord, setUserRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [editClaims, setEditClaims] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [editQuickActions, setEditQuickActions] = useState(false);
  const [claimsList, setClaimsList] = useState([]);
  const [claimsListLoading, setClaimsListLoading] = useState(false);

  const functions = useFunctions();
  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const { data } = useUser();

  //   Get individual user from Firebase via callable function
  const getUser = async () => {
    const userId = props.match.params.userid;
    if (userId.length > 0) {
      setIsLoading(true);
      const getUser = httpsCallable(functions, "getAuthUserDetails");
      // var getUser = functions.httpsCallable("getAuthUserDetails");
      await getUser({ uid: userId })
        .then((result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result.data;
          if (sanitizedMessage === null) {
            throw "No user found, check UID";
          } else {
            setUserRecord(sanitizedMessage);
          }
        })
        .catch((e) => {
          toast.error(e);
          setIsError(true);
          console.log(e);
        });
      setIsLoading(false);
    }
  };

  const enableUser = async () => {
    const userId = props.match.params.userid;
    if (userId.length > 0) {
      setIsLoading(true);
      const enableUser = httpsCallable(functions, "toggleUserDisabled");
      // var enableUser = firebase.functions().httpsCallable("toggleUserDisabled");
      const payload = {
        uid: userId,
        disabled: false,
      };
      await enableUser({ payload: payload })
        .then((result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result.data;
          if (sanitizedMessage === null) {
            throw "No user found, check UID";
          } else {
            setUserRecord(sanitizedMessage);
            getUser();
          }
        })
        .catch((e) => {
          toast.error(e);
          setIsError(true);
          console.log(e);
        });
      setIsLoading(false);
    }
  };

  const disableUser = async () => {
    const userId = props.match.params.userid;
    if (userId.length > 0) {
      setIsLoading(true);
      const enableUser = httpsCallable(functions, "toggleUserDisabled");
      // var enableUser = firebase.functions().httpsCallable("toggleUserDisabled");
      const payload = {
        uid: userId,
        disabled: true,
      };
      await enableUser({ payload: payload })
        .then((result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result.data;
          if (sanitizedMessage === null) {
            throw "No user found, check UID";
          } else {
            setUserRecord(sanitizedMessage);
            getUser();
          }
        })
        .catch((e) => {
          toast.error(e);
          setIsError(true);
          console.log(e);
        });
      setIsLoading(false);
    }
  };

  const revokeUserToken = async () => {
    const userId = props.match.params.userid;
    if (userId.length > 0) {
      setIsLoading(true);
      const revokeUserToken = httpsCallable(
        functions,
        "revokeUserRefreshToken"
      );

      // var revokeUserToken = firebase
      //   .functions()
      //   .httpsCallable("revokeUserRefreshToken");

      await revokeUserToken({ uid: userId })
        .then((result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result.data;
          console.log(result);
          if (sanitizedMessage === null) {
            throw "No user found, check UID";
          } else {
            toast.success(
              `Refresh token revoked at ${dayjs
                .unix(sanitizedMessage)
                .format("YYYY-MM-DD HH:mm:ss")}`
            );
            getUser();
          }
        })
        .catch((e) => {
          toast.error(e);
          setIsError(true);
          console.log(e);
        });
      setIsLoading(false);
    }
  };

  const toggleRole = async (role, value) => {
    if (role === "su" && data.phoneNumber !== "+61415447605") {
      toast.error("Not authorised to perform this function");
      return;
    }
    const userId = props.match.params.userid;
    if (userId.length > 0) {
      setClaimsListLoading(true);
      setIsLoading(true);
      const updateClaim = httpsCallable(functions, "updateUserCustomClaim");
      // var updateClaim = firebase
      //   .functions()
      //   .httpsCallable("updateUserCustomClaim");
      const currentClaims = userRecord.customClaims;
      const newClaim = { [role]: value };

      const mergedClaims = { ...currentClaims, ...newClaim };

      const payload = {
        uid: userId,
        claimsObject: mergedClaims,
      };
      await updateClaim({ payload: payload })
        .then((result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result.data;
          if (sanitizedMessage === null) {
            throw "No user found, check UID";
          } else {
            toast.info(
              "User will need be forced to log back in so changes can take effect"
            );
          }
        })
        .catch((e) => {
          toast.error(e);
          setIsError(true);
          console.log(e);
        });
    }
    getUser();
    setClaimsListLoading(false);
    setIsLoading(false);
  };

  const setDefaultRole = async (role) => {
    const userId = props.match.params.userid;
    if (userId.length > 0) {
      setClaimsListLoading(true);
      const updateClaim = httpsCallable(functions, "updateUserCustomClaim");
      // var updateClaim = firebase
      //   .functions()
      //   .httpsCallable("updateUserCustomClaim");
      let mergedClaims = null;

      switch (role) {
        case "private_buyer_role":
          mergedClaims = {
            freight_c: true,
            vinsearch_r: true,
            decodeVIN: true,
          };
          break;

        case "remove_all_roles":
          mergedClaims = null;
          break;

        default:
          mergedClaims = null;
          break;
      }

      const payload = {
        uid: userId,
        claimsObject: mergedClaims,
      };
      await updateClaim({ payload: payload })
        .then((result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result.data;
          if (sanitizedMessage === null) {
            throw "No user found, check UID";
          } else {
            toast.info(
              "User will need be forced to log back in so changes can take effect"
            );
          }
        })
        .catch((e) => {
          toast.error(e);
          setIsError(true);
          console.log(e);
        });
    }
    getUser();
    setClaimsListLoading(false);
  };

  const updateUserProfile = async () => {
    const userId = props.match.params.userid;
    if (userId.length > 0) {
      setIsLoading(true);
      const updateUserProfile = httpsCallable(functions, "updateUserProfile");
      // var updateUserProfile = firebase
      //   .functions()
      //   .httpsCallable("updateUserProfile");
      const payload = {
        uid: userId,
        displayName: userRecord.displayName,
        email: userRecord.email,
      };
      await updateUserProfile({ payload: payload })
        .then((result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result;
          if (sanitizedMessage.data.error) {
            throw sanitizedMessage.data.error;
          }
          setIsLoading(false);
          toast.success("User profile updated, refreshing data...");
          getUser();
        })
        .catch((e) => {
          toast.error(e);
          setIsError(true);
          console.log(e);
        });
    }
  };

  //   Load claim defaults from Firestore and set in state on component mount

  useEffect(() => {
    const getClaimDefaults = async () => {
      setIsLoading(true);

      // // write using reactfire and firestore modular

      // // Query
      // const claimsCollection = collection(
      //   firestore,
      //   "settings",
      //   "claims_defaults",
      //   "all"
      // );

      // const claimsQuery = query(claimsCollection, orderBy("claim", "asc"));
      // const {status, data:tmpList} = useFirestoreCollectionData(claimsQuery);

      await firestore
        .collection("settings")
        .doc("claims_defaults")
        .collection("all")
        .orderBy("claim", "asc")
        .get()
        .then((snapshot) => {
          const tmpList = [];
          snapshot.forEach((doc) => {
            const tmpdata = doc.data();
            tmpList.push({
              id: doc.id,
              ...tmpdata,
            });
          });
          setClaimsList(tmpList);
        })
        .catch((e) => {
          toast.error(e);
          setIsError(true);
          console.log(e);
        });
      setIsLoading(false);
    };
    getClaimDefaults();
  }, []);

  //   Load user data from Firestore and set in state on component mount

  useEffect(() => {
    getUser();
  }, [props.match.params.userid]);

  const copyToClipboard = (v) => {
    copy(v);
  };
  return (
    <>
      <ToastContainer />
      <Container>
        {/* {isLoading ? <Spinner animation="border" /> : null} */}
        <Row>
          <Col>
            {userRecord || true ? (
              <Button
                style={styles.card}
                // disabled
                variant="outline-success"
                onClick={getUser}
              >
                {isLoading ? (
                  <Spinner animation="border" />
                ) : (
                  `Loaded data for user with id: ${props.match.params.userid}`
                )}
              </Button>
            ) : (
              <>
                <Card style={styles.card}>
                  <Card.Body>
                    <Alert variant="danger">
                      User profile not loaded, please load to see details
                    </Alert>
                  </Card.Body>
                </Card>
              </>
            )}
          </Col>
        </Row>

        {userRecord?.disabled ? (
          <Row>
            <Col>
              <Card style={styles.card}>
                <Card.Header>
                  <Alert variant="danger">
                    User needs to be enabled first. Please confirm that this
                    user is within the Dutton Group prior to enabling.
                  </Alert>
                  <Button
                    disabled={isLoading || !userRecord.disabled}
                    variant="primary"
                    onClick={enableUser}
                  >
                    {isLoading ? <Spinner animation="border" /> : "Enable User"}
                  </Button>
                </Card.Header>
                <Card.Body>
                  {/* Table of information */}
                  <Table>
                    <thead>
                      <tr>
                        <th>Property</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>UID</td>
                        <td>
                          {userRecord.uid}
                          &nbsp;&nbsp;
                          <Button
                            onClick={() => copyToClipboard(userRecord.uid)}
                            size="sm"
                            variant="outline-dark"
                          >
                            <HiClipboardCheck size={15} />
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>Display Name</td>

                        <td>{userRecord.displayName}</td>
                      </tr>
                      <tr>
                        <td>Email</td>

                        <td>
                          {userRecord.email}
                          &nbsp;&nbsp;
                          <Button
                            onClick={() => copyToClipboard(userRecord?.email)}
                            size="sm"
                            variant="outline-dark"
                          >
                            <HiClipboardCheck size={15} />
                          </Button>
                        </td>
                      </tr>

                      <tr>
                        <td>Phone Number</td>

                        <td>{userRecord.phoneNumber}</td>
                      </tr>

                      <tr>
                        <td>Creation Time</td>
                        <td>
                          {dayjs(userRecord.metadata.creationTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Last Sign In Time</td>
                        <td>
                          {dayjs(userRecord.metadata.lastSignInTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Enabled</td>
                        <td>
                          {userRecord.disabled ? (
                            <Badge style={{ fontSize: 14 }} variant="danger">
                              Disabled
                            </Badge>
                          ) : (
                            <Badge style={{ fontSize: 14 }} variant="success">
                              Enabled
                            </Badge>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : null}

        {userRecord && !userRecord.disabled ? (
          <>
            <Row>
              <Col>
                <Card style={styles.card}>
                  <Card.Header>
                    User Details
                    <span style={{ float: "right" }}>
                      <Form.Check
                        type="switch"
                        name={"edit_user_details"}
                        id={"edit_user_details"}
                        checked={editProfile}
                        label="Edit"
                        variant="success"
                        onChange={() => setEditProfile(!editProfile)}
                      />
                    </span>
                  </Card.Header>
                  <Card.Body>
                    {editProfile ? (
                      <Alert variant="warning">
                        NOTE: Now editing this section
                      </Alert>
                    ) : null}
                    {/* Table of information */}
                    <Table>
                      <thead>
                        <tr>
                          <th>Property</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>UID</td>
                          <td>
                            {userRecord.uid}
                            &nbsp;&nbsp;
                            <Button
                              onClick={() => copyToClipboard(userRecord.uid)}
                              size="sm"
                              variant="outline-dark"
                            >
                              <HiClipboardCheck size={15} />
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td>Display Name</td>
                          {editProfile ? (
                            <td>
                              <Form.Control
                                type="text"
                                style={styles.formInput}
                                name="displayName"
                                value={userRecord?.displayName}
                                onChange={(e) =>
                                  setUserRecord({
                                    ...userRecord,
                                    displayName: e.target.value,
                                  })
                                }
                              />
                            </td>
                          ) : (
                            <td>{userRecord.displayName}</td>
                          )}
                        </tr>
                        <tr>
                          <td>Email</td>
                          {editProfile ? (
                            <td>
                              <Form.Control
                                type="text"
                                style={styles.formInput}
                                name="email"
                                value={userRecord?.email}
                                onChange={(e) =>
                                  setUserRecord({
                                    ...userRecord,
                                    email: e.target.value,
                                  })
                                }
                              />
                            </td>
                          ) : (
                            <td>
                              {userRecord.email}
                              &nbsp;&nbsp;
                              <Button
                                onClick={() =>
                                  copyToClipboard(userRecord?.email)
                                }
                                size="sm"
                                variant="outline-dark"
                              >
                                <HiClipboardCheck size={15} />
                              </Button>
                            </td>
                          )}
                        </tr>
                        <tr>
                          {editProfile ? (
                            <td colSpan={2}>
                              <Button
                                variant="outline-primary"
                                onClick={updateUserProfile}
                                disabled={isLoading}
                              >
                                {isLoading ? (
                                  <Spinner animation="border" />
                                ) : (
                                  "Update User Profile"
                                )}
                              </Button>
                            </td>
                          ) : null}
                        </tr>
                        <tr>
                          <td>Phone Number</td>

                          <td>{userRecord.phoneNumber}</td>
                        </tr>

                        <tr>
                          <td>Creation Time</td>
                          <td>
                            {dayjs(userRecord.metadata.creationTime).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Last Sign In Time</td>
                          <td>
                            {dayjs(userRecord.metadata.lastSignInTime).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Enabled</td>
                          <td>
                            {userRecord.disabled ? (
                              <Badge style={{ fontSize: 14 }} variant="danger">
                                Disabled
                              </Badge>
                            ) : (
                              <Badge style={{ fontSize: 14 }} variant="success">
                                Enabled
                              </Badge>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={styles.card}>
                  <Card.Header>
                    Permissions
                    <span style={{ float: "right" }}>
                      {claimsListLoading ? (
                        <Spinner animation="border" />
                      ) : (
                        <Form.Check
                          type="switch"
                          name={"edit_claims"}
                          id={"edit_claims"}
                          checked={editClaims}
                          label="Edit"
                          variant="success"
                          onChange={() => setEditClaims(!editClaims)}
                        />
                      )}
                    </span>
                  </Card.Header>
                  {userRecord.customClaims &&
                  Object.keys(userRecord.customClaims).length > 0 ? (
                    <Card.Body>
                      <Table
                        striped
                        style={{
                          fontSize: 12,
                          opacity: claimsListLoading ? 0.6 : 1,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Role</th>
                            <th>Role Id</th>
                            <th>Description</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* Add rows for each claimsList */}
                          {claimsList.map((claim, idx) => (
                            <tr key={idx}>
                              <td>{claim?.name}</td>
                              <td>{claim?.claim}</td>
                              <td>{claim?.description}</td>
                              <td>
                                <Form.Check
                                  type="switch"
                                  name={`claim_${claim.claim}`}
                                  id={`claim_${claim.claim}`}
                                  checked={userRecord.customClaims[claim.claim]}
                                  //   label={claim.name}
                                  disabled={isLoading || !editClaims}
                                  onChange={(e) => {
                                    console.log(e.target.checked);
                                    setUserRecord({
                                      ...userRecord,
                                      customClaims: {
                                        ...userRecord.customClaims,
                                        [claim.claim]: e.target.checked,
                                      },
                                    });
                                    toggleRole(claim.claim, e.target.checked);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  ) : (
                    <Card.Body>
                      <Alert variant="info">
                        No permissions have been set for this user
                      </Alert>
                      {editClaims ? (
                        <Alert variant="warning">
                          NOTE: Now editing this section
                        </Alert>
                      ) : null}
                      <Table>
                        <thead>
                          <tr>
                            <th>Defaults</th>
                            <th>Permissions</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>IT</td>
                            <td>
                              admin | su | freight_c | freight_cru | insights |
                              vinsearch_r | decodeVIN
                            </td>
                            <td>
                              <Button
                                variant="outline-info"
                                // disabled={isLoading || !editClaims}
                                disabled
                                onClick={() => setDefaultRole("it_role")}
                              >
                                {isLoading ? (
                                  <Spinner animation="border" />
                                ) : (
                                  "Set default"
                                )}
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td>Private Buyer</td>
                            <td>freight_c | vinsearch_r | decodeVIN</td>
                            <td>
                              <Button
                                variant="outline-info"
                                disabled={isLoading || !editClaims}
                                onClick={() =>
                                  setDefaultRole("private_buyer_role")
                                }
                              >
                                {isLoading ? (
                                  <Spinner animation="border" />
                                ) : (
                                  "Set default"
                                )}
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td>Freight Admin Team</td>
                            <td>
                              freight_c | freight_cru | vinsearch_r | decodeVIN
                            </td>
                            <td>
                              <Button
                                variant="outline-info"
                                disabled={isLoading || !editClaims}
                                onClick={() =>
                                  setDefaultRole("freight_admin_team_role")
                                }
                              >
                                {isLoading ? (
                                  <Spinner animation="border" />
                                ) : (
                                  "Set default"
                                )}
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  )}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={styles.card}>
                  <Card.Header>
                    Quick Actions
                    <span style={{ float: "right" }}>
                      <Form.Check
                        type="switch"
                        name={"edit_quick_actions"}
                        id={"edit_quick_actions"}
                        checked={editQuickActions}
                        label="Edit"
                        variant="success"
                        onChange={() => setEditQuickActions(!editQuickActions)}
                      />
                    </span>
                  </Card.Header>
                  <Card.Body>
                    <Table striped style={{ fontSize: 12 }}>
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "normal",
                              maxWidth: "150px",
                            }}
                          >
                            <b>
                              <i>Disable User</i>
                            </b>
                            <p>
                              This will disable the user's profile. This has the
                              same effect as disabling the user from the
                              Firebase admin console
                            </p>
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            <Button
                              disabled={
                                isLoading ||
                                userRecord.disabled ||
                                !editQuickActions
                              }
                              variant="outline-danger"
                              onClick={disableUser}
                            >
                              {isLoading ? (
                                <Spinner animation="border" />
                              ) : (
                                "Disable User"
                              )}
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "normal",
                              maxWidth: "150px",
                            }}
                          >
                            <b>
                              <i>Enable User</i>
                            </b>
                            <p>
                              This will enable the user's profile. This has the
                              same effect as enabling the user from the Firebase
                              admin console
                            </p>
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            <Button
                              disabled={
                                isLoading ||
                                !userRecord.disabled ||
                                !editQuickActions
                              }
                              variant="outline-success"
                              onClick={enableUser}
                            >
                              {isLoading ? (
                                <Spinner animation="border" />
                              ) : (
                                "Enable User"
                              )}
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "normal",
                              maxWidth: "150px",
                            }}
                          >
                            <b>
                              <i>Revoke Access Token</i>
                            </b>
                            <p>
                              This will revoke the user's current access token.
                              This action will force the user to re-authenticate
                              and log in again.
                            </p>
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            <Button
                              disabled={
                                isLoading ||
                                userRecord.disabled ||
                                !editQuickActions
                              }
                              variant="outline-primary"
                              onClick={revokeUserToken}
                            >
                              {isLoading ? (
                                <Spinner animation="border" />
                              ) : (
                                "Revoke Refresh Token"
                              )}
                            </Button>
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "normal",
                              maxWidth: "150px",
                            }}
                          >
                            <b>
                              <i>Remove all permissions</i>
                            </b>
                            <p>
                              This will revoke all permissions for the user and
                              set them to 'null' The user will be forced to log
                              in again immediately.
                            </p>
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            <Button
                              disabled={
                                isLoading ||
                                userRecord.disabled ||
                                !editQuickActions
                              }
                              variant="outline-danger"
                              onClick={() => setDefaultRole("remove_all_roles")}
                            >
                              {isLoading ? (
                                <Spinner animation="border" />
                              ) : (
                                "Remove all permissions"
                              )}
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "normal",
                              maxWidth: "150px",
                            }}
                          >
                            <b>
                              <i>Add to group</i>
                            </b>
                            <p>
                              This will add this user to a group. Groups are
                              used to control access to certain features of the
                              app. For example, the DGBuying Wallboard.
                            </p>
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            <i>Coming soon...</i>
                            {/* <Button
                              disabled={
                                isLoading ||
                                userRecord.disabled ||
                                !editQuickActions
                              }
                              variant="outline-primary"
                              onClick={revokeUserToken}
                            >
                              Revoke Refresh Token
                            </Button> */}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {/* Add to DBuying Team (firestore settings document teamList) */}
          </>
        ) : null}
      </Container>
    </>
  );
};

export default ViewUsersComponent;
