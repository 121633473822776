import { ToastContainer, toast } from "react-toastify";
import BackdropComponent from "../BackdropComponent";
import {
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  Container,
  Spinner,
  Table,
  Badge,
  ButtonGroup,
  Card,
  Alert,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  doc,
  orderBy,
  limit,
  collectionGroup,
} from "firebase/firestore";
import { useFunctions, useUser, useFirestore } from "reactfire";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { parse } from "postcss/lib/postcss";

const stickyTableHeaderStyle = {
  position: "sticky",
  margin: "0 0 0 0",
  top: -1,
  backgroundColor: "#566573",
  color: "white",
  fontSize: 12,
};

const TEST_AUCTION_VEHICLES = require("../../assets/testdata/auction_internal_tender_vehicles_test_060924.json");
const TEST_AUCTION_MEMBERS = [
  {
    uid: "JknoVlb6Uic1SSjouHBqp2mjNH92",
    displayName: "Juv J",
    email: "j.jayaram@duttongroup.com.au",
    phoneNumber: "+61415447065",
    role: "BIDDER",
    status: "active",
    ownBranches: ["D1 MELBOURNE"],
  },
  {
    uid: "cnWHzVNEWtSJeOSEgsHMDueAH5l2",
    displayName: "TEST SELLER",
    email: "j.jayaram@duttongroup.com.au",
    phoneNumber: "+61415447065",
    role: "SELLER",
    status: "active",
    ownBranches: ["DG VIC"],
  },
];

const TEST_AUCTION_DATA = {
  auctionType: "INTERNAL_TENDER",
  auctionStatus: "LIVE",
  auctionTitle: "Internal tender test",
  auctionDescription: "This is a test auction",
  auctionStartDate: dayjs().unix(),
  auctionEndDate: dayjs().add(1, "day").unix(),
  numberOfAuctionVehicles: TEST_AUCTION_VEHICLES.length,
  timeExtensionAllowed: true,
  auctionMembers: TEST_AUCTION_MEMBERS,
};

const InternalTendersVehicleBidder = () => {
  const [auctionVehicle, setAuctionVehicle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bidAmount, setBidAmount] = useState(0);
  const [auctionFinished, setAuctionFinished] = useState(false);
  const [auctionStarted, setAuctionStarted] = useState(false);
  const [auctionTimeToStart, setAuctionTimeToStart] = useState(0);
  const [auctionTimeToFinish, setAuctionTimeToFinish] = useState(0);
  const [existingBid, setExistingBid] = useState(false);
  const [markedAsNotInterested, setMarkedAsNotInterested] = useState(false);
  const [existingBidHistory, setExistingBidHistory] = useState([]);
  const [userType, setUserType] = useState(null);

  //   Hooks
  //   const functions = useFunctions();
  const firestore = useFirestore();
  const user = useUser();
  const history = useHistory();
  const { auctionId, vehicleId } = useParams();

  //   Get all auction vehicles (Collection group) in live auctionStatusCar
  const getAuctionVehicle = async (auctionId, vehicleId) => {
    setLoading(true);
    // Get collection group auction-vehicles
    console.log(auctionId, vehicleId);
    const auctionVehicleRef = doc(
      firestore,
      "auctions",
      auctionId,
      "auction-vehicles",
      vehicleId
    );
    const docSnap = await getDoc(auctionVehicleRef);

    setLoading(false);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      const id = docSnap.id;
      const data = docSnap.data();
      return { id, ...data };
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
      return false;
    }
  };

  const addBidHistory = async (
    auctionId,
    vehicleId,
    bidId,
    bidData,
    historyType
  ) => {
    // Add document bid-history
    const bidHistoryData = {
      ...bidData,
      historyType,
      historyDate: dayjs().unix(),
      historyBy: user.data.uid,
      bidId,
    };

    const bidHistoryRef = collection(
      firestore,
      "auctions",
      auctionId,
      "auction-vehicles",
      vehicleId,
      "auction-bids",
      bidId,
      "auction-bids-history"
    );

    return await addDoc(bidHistoryRef, bidHistoryData);
  };

  const getBidHistory = async (bidId) => {
    const q = query(
      collectionGroup(firestore, "auction-bids-history"),
      where("bidId", "==", bidId),
      orderBy("historyDate", "desc")
    );
    const querySnapshot = await getDocs(q);
    let bidHistory = [];
    querySnapshot.forEach((doc) => {
      const id = doc.id;
      const data = doc.data();
      bidHistory.push({ id, ...data });
    });
    return bidHistory;
  };

  const placeBid = async () => {
    if (bidAmount <= 0) {
      toast.error("Bid amount must valid");
      return;
    }
    const now = dayjs().unix();
    if (now > auctionVehicle.auctionEndDate) {
      toast.error("Auction has ended");
      setAuctionFinished(true);
      return;
    }
    setLoading(true);
    const bidData = {
      auctionVehicle,
      auctionId,
      vehicleId,
      bidAmount: parseFloat(bidAmount),
      bidBy: user.data.uid,
      bidDate: dayjs().unix(),
      bidStatus: "PENDING",
      displayName: user.data.displayName,
      email: user.data.email,
    };
    console.log(bidData);

    try {
      const placeBidRef = collection(
        firestore,
        "auctions",
        auctionId,
        "auction-vehicles",
        vehicleId,
        "auction-bids"
      );
      const docSnap = await addDoc(placeBidRef, bidData);
      toast.success("Bid placed successfully");

      // Add bid history
      await addBidHistory(auctionId, vehicleId, docSnap.id, bidData, "PLACED");

      getExistingBid();
    } catch (e) {
      console.log(e);
      toast.error("Error placing bid");
    }

    setLoading(false);
  };

  const widthdrawBid = async () => {
    if (!existingBid) {
      toast.error("No existing bid found");
      return;
    }

    setLoading(true);
    try {
      await setDoc(
        doc(
          firestore,
          "auctions",
          auctionId,
          "auction-vehicles",
          vehicleId,
          "auction-bids",
          existingBid.id
        ),
        { bidStatus: "WITHDRAWN" },
        { merge: true }
      );
      toast.success("Bid withdrawn successfully");

      // Add bid history
      await addBidHistory(
        auctionId,
        vehicleId,
        existingBid.id,
        existingBid,
        "WITHDRAWN"
      );
      getExistingBid();
    } catch (e) {
      console.log(e);
      toast.error("Error withdrawing bid");
    }
    setLoading(false);
  };

  // Get existing bid for this user
  const getExistingBid = async () => {
    if (!user.data) {
      return;
    }
    const q = query(
      collection(
        firestore,
        "auctions",
        auctionId,
        "auction-vehicles",
        vehicleId,
        "auction-bids"
      ),
      where("bidBy", "==", user.data.uid),
      orderBy("bidDate", "desc"),
      limit(1)
    );
    const querySnapshot = await getDocs(q);
    let bids = [];
    querySnapshot.forEach((doc) => {
      const id = doc.id;
      const data = doc.data();
      bids.push({ id, ...data });
    });
    if (bids.length > 0) {
      setExistingBid(bids[0]);
    }
  };

  const setExistingBidById = async (bidId) => {
    const bidRef = doc(
      firestore,
      "auctions",
      auctionId,
      "auction-vehicles",
      vehicleId,
      "auction-bids",
      bidId
    );
    const docSnap = await getDoc(bidRef);
    if (docSnap.exists()) {
      const id = docSnap.id;
      const data = docSnap.data();
      setExistingBid({ id, ...data });
    } else {
      setExistingBid(false);
    }
  };

  const isVehicleNotInterested = (vehicle) => {
    if (vehicle) {
      console.log("Vehicle", vehicle);
      // Check if current user has set this vehicle as not interested
      const auctionMemberIndex = vehicle?.auctionMembers?.findIndex(
        (member) => member.role === "BIDDER" && member.uid === user.data.uid
      );
      console.log("Auction member index", auctionMemberIndex);
      if (auctionMemberIndex === -1) {
        return false;
      }
      return (
        vehicle?.auctionMembers[auctionMemberIndex]?.notInterestedInVehicle ||
        false
      );
    } else {
      return false;
    }
  };

  // Accept counter offer
  const acceptCounterOffer = async () => {
    if (!existingBid) {
      toast.error("No existing bid found");
      return;
    }

    const acceptBidData = {
      bidStatus: "ACCEPTED",
      acceptedBy: user.data.uid,
      acceptedDate: dayjs().unix(),
      winningBid: true,
      winningBidAmount: existingBid.counterAmount,
    };

    setLoading(true);
    try {
      await setDoc(
        doc(
          firestore,
          "auctions",
          auctionId,
          "auction-vehicles",
          vehicleId,
          "auction-bids",
          existingBid.id
        ),
        acceptBidData,
        { merge: true }
      );
      toast.success("Counter accepted successfully");

      // Update auction vehicle with winning bid
      await setDoc(
        doc(firestore, "auctions", auctionId, "auction-vehicles", vehicleId),
        {
          winningBid: existingBid.id,
          winningBidAmount: existingBid.counterAmount,
          winningBidType: "COUNTER_OFFER",
        },
        { merge: true }
      );

      // Add bid history
      await addBidHistory(
        auctionId,
        vehicleId,
        existingBid.id,
        existingBid,
        "ACCEPTED_COUNTER"
      );
      setExistingBidById(existingBid.id);
    } catch (e) {
      console.log(e);
      toast.error("Error accepting counter offer");
    }
    setLoading(false);
  };

  // Reject counter offer
  const rejectCounterOffer = async () => {
    if (!existingBid) {
      toast.error("No existing bid found");
      return;
    }

    const rejectBidData = {
      bidStatus: "REJECTED",
      rejectedBy: user.data.uid,
      rejectedDate: dayjs().unix(),
    };

    setLoading(true);
    try {
      await setDoc(
        doc(
          firestore,
          "auctions",
          auctionId,
          "auction-vehicles",
          vehicleId,
          "auction-bids",
          existingBid.id
        ),
        rejectBidData,
        { merge: true }
      );
      toast.success("Counter rejected successfully");

      // Add bid history
      await addBidHistory(
        auctionId,
        vehicleId,
        existingBid.id,
        existingBid,
        "REJECTED_COUNTER"
      );
      setExistingBidById(existingBid.id);
    } catch (e) {
      console.log(e);
      toast.error("Error rejecting counter offer");
    }
    setLoading(false);
  };

  // Set vehicle as not interested
  const setVehicleAsNotInterested = async (vehicle) => {
    console.log("Setting vehicle as not interested", vehicle);

    // If bid exists then disallow setting as not interested

    if (existingBid) {
      toast.error(
        "Cannot set as not interested, you have already placed a bid"
      );
      return;
    }

    // Check if user has already set as not interested
    // Get auction member index
    const auctionMemberIndex = vehicle?.auctionMembers?.findIndex(
      (member) => member.role === "BIDDER" && member.uid === user.data.uid
    );
    if (auctionMemberIndex === -1) {
      toast.error("You are not a bidder for this auction");
      return;
    }
    if (vehicle?.auctionMembers[auctionMemberIndex]?.notInterestedInVehicle) {
      toast.error("You have already set this vehicle as not interested");
      return;
    }

    // Set vehicle as not interested
    const tmpMember = vehicle.auctionMembers[auctionMemberIndex];
    tmpMember.notInterestedInVehicle = true;
    const auctionMembers = [...vehicle["auctionMembers"]];

    await setDoc(
      doc(
        firestore,
        "auctions",
        vehicle.auctionId,
        "auction-vehicles",
        vehicle.id
      ),
      { auctionMembers },
      { merge: true }
    );
    console.log("Auction member", auctionMembers);
  };

  // Set setMarkedAsNotInterested
  useEffect(() => {
    if (auctionVehicle) {
      const notInterested = isVehicleNotInterested(auctionVehicle);
      console.log("Not interested", notInterested);
      setMarkedAsNotInterested(notInterested);
    }
  }, [JSON.stringify(auctionVehicle)]);

  // Load test
  useEffect(() => {
    if (auctionId && vehicleId) {
      console.log(auctionId, vehicleId);
      getAuctionVehicle(auctionId, vehicleId).then((data) => {
        console.log("AVd", data);
        setAuctionVehicle(data);
        if (!data) {
          history.push("/auctions/internal-tenders");
          toast.error("No auction vehicle found");
        }
      });
    } else {
      toast.error("No auction or vehicle id found");
      history.push("/auctions/internal-tenders");
    }
  }, [auctionId, vehicleId]);

  useEffect(() => {
    if (auctionVehicle) {
      const now = dayjs().unix();
      if (now >= auctionVehicle.auctionEndDate) {
        setAuctionFinished(true);
      }
    }
  }, [JSON.stringify(auctionVehicle)]);

  useEffect(() => {
    if (auctionVehicle) {
      const now = dayjs().unix();
      if (now >= auctionVehicle.auctionStartDate) {
        setAuctionStarted(true);
      }
    }
  }, [JSON.stringify(auctionVehicle)]);

  // Live counter for auction that has yet to start
  useEffect(() => {
    if (auctionVehicle) {
      const now = dayjs().unix();
      if (now < auctionVehicle.auctionStartDate) {
        const interval = setInterval(() => {
          const now = dayjs().unix();
          setAuctionTimeToStart(auctionVehicle.auctionStartDate - now);
          if (now >= auctionVehicle.auctionStartDate) {
            setAuctionStarted(true);
            clearInterval(interval);
          }
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  }, [JSON.stringify(auctionVehicle)]);

  // Live counter for auction that is live
  useEffect(() => {
    if (auctionVehicle) {
      const now = dayjs().unix();
      if (
        now >= auctionVehicle.auctionStartDate &&
        now < auctionVehicle.auctionEndDate
      ) {
        const interval = setInterval(() => {
          const now = dayjs().unix();
          setAuctionTimeToFinish(auctionVehicle.auctionEndDate - now);
          if (now >= auctionVehicle.auctionEndDate) {
            setAuctionFinished(true);
            clearInterval(interval);
          }
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  }, [JSON.stringify(auctionVehicle)]);

  // Format seconds to human readable time
  const formatSecondsToTime = (seconds) => {
    if (seconds <= 0) {
      return "00:00:00";
    }
    let hours = Math.floor(seconds / 3600);
    if (hours < 10) {
      hours = `0${hours}`;
    }
    let minutes = Math.floor((seconds % 3600) / 60);
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    let remainingSeconds = Math.floor(seconds % 60);
    if (remainingSeconds < 10) {
      remainingSeconds = `0${remainingSeconds}`;
    }
    return `${hours}:${minutes}:${remainingSeconds}`;
  };

  // Get any existing bid for this user
  useEffect(() => {
    if (auctionVehicle) {
      getExistingBid();
    }
  }, [JSON.stringify(auctionVehicle)]);

  const bidStatusColour = (status) => {
    switch (status) {
      case "PENDING":
        return "warning";
      case "ACCEPTED":
        return "success";
      case "REJECTED":
        return "danger";
      case "WITHDRAWN":
        return "secondary";
      default:
        return "warning";
    }
  };

  // Update bid hisotry when bid is placed or changes
  useEffect(() => {
    if (existingBid) {
      getBidHistory(existingBid.id).then((bidHistory) => {
        console.log("Bid history", bidHistory);
        console.log("Existing bid", existingBid);
        setExistingBidHistory(bidHistory);
      });
    }
  }, [JSON.stringify(existingBid)]);

  // Get user type
  useEffect(() => {
    if (user.data && auctionVehicle) {
      const userTypeIndex = auctionVehicle?.auctionMembers?.findIndex(
        (member) => member.uid === user.data.uid
      );
      if (userTypeIndex !== -1) {
        setUserType(auctionVehicle?.auctionMembers[userTypeIndex].role);
      }
    }
  }, [user.data, JSON.stringify(auctionVehicle)]);

  if (userType === null) {
    return (
      <>
        <BackdropComponent loading={true} />
      </>
    );
  }

  if (userType === "SELLER") {
    return (
      <Container>
        <Row>
          <Col>
            <Alert variant="danger">
              You are not authorised to view this page as you are a seller in
              this auction
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  if (
    auctionVehicle?.auctionMembers
      ?.map((member) => member.uid)
      .includes(user.data.uid) === false
  ) {
    return (
      <Container>
        <Row>
          <Col>
            <Alert variant="danger">
              You are not authorised to view this page
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <BackdropComponent loading={loading} />
      <ToastContainer />
      <Container>
        <Row>
          <Col>
            <Alert variant="info">
              You are viewing this page as a {userType}
            </Alert>
          </Col>
        </Row>
        <Row>
          {auctionVehicle && (
            <Col>
              <Card style={{ marginBottom: 5, textAlign: "left" }}>
                <Card.Header>
                  <Card.Text>
                    {`${auctionVehicle.VEHModelYear} ${auctionVehicle.VEHMake} ${auctionVehicle.VEHModel} ${auctionVehicle["Variant & Series"]}`}
                    &nbsp;
                    {auctionStarted && !auctionFinished && (
                      <Badge pill variant="success">
                        Auction Live -{" "}
                        {formatSecondsToTime(auctionTimeToFinish)} left
                      </Badge>
                    )}
                    {!auctionStarted && !auctionFinished && (
                      <Badge pill variant="warning">
                        Auction not started -{" "}
                        {formatSecondsToTime(auctionTimeToStart)} before it
                        starts
                      </Badge>
                    )}
                    {auctionFinished ? (
                      <Badge pill variant="danger">
                        Auction Ended
                      </Badge>
                    ) : (
                      ""
                    )}
                    &nbsp;
                    {markedAsNotInterested && (
                      <Badge pill variant="danger">
                        You have marked this vehicle as not interested
                      </Badge>
                    )}
                  </Card.Text>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={12} sm={6}>
                      <Card style={{ marginBottom: 5 }}>
                        <Card.Body>
                          <Card.Title>
                            <Row>
                              <Col>Stock #</Col>
                              <Col>{auctionVehicle?.VEHStockNumber}</Col>
                            </Row>
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">
                            <Row>
                              <Col>Location</Col>
                              <Col>{auctionVehicle?.branch_desc}</Col>
                            </Row>
                          </Card.Subtitle>

                          <Card.Text>
                            <Row>
                              <Col>Odo</Col>
                              <Col>
                                <NumberFormat
                                  value={auctionVehicle.VEHOdo}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </Col>
                            </Row>
                          </Card.Text>
                          <Card.Text>
                            <Row>
                              <Col>Colour</Col>
                              <Col>{auctionVehicle?.VEHColour1}</Col>
                            </Row>
                          </Card.Text>

                          <Card.Text>
                            <Row>
                              <Col>VIN</Col>
                              <Col>{auctionVehicle.VEHVIN}</Col>
                            </Row>
                          </Card.Text>

                          <Card.Text>
                            <Row style={{ color: "green" }}>
                              <Col>Auction Start</Col>
                              <Col>
                                {dayjs
                                  .unix(auctionVehicle.auctionStartDate)
                                  .format("DD/MM/YYYY HH:mm")}
                              </Col>
                            </Row>
                          </Card.Text>
                          <Card.Text>
                            <Row style={{ color: "orange" }}>
                              <Col>Auction End</Col>
                              <Col>
                                {dayjs
                                  .unix(auctionVehicle.auctionEndDate)
                                  .format("DD/MM/YYYY HH:mm")}
                              </Col>
                            </Row>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    {/* START VIEW FOR BUYER */}
                    {userType === "BIDDER" && (
                      <Col>
                        <Card style={{ marginBottom: 5 }}>
                          <Card.Body>
                            <Card.Text>
                              <Row>
                                <Col>
                                  <Card.Title>My bid</Card.Title>
                                  {existingBid && (
                                    <Row>
                                      <Col>
                                        <NumberFormat
                                          value={existingBid.bidAmount}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                        />{" "}
                                        placed{" "}
                                        {dayjs
                                          .unix(existingBid.bidDate)
                                          .fromNow()}{" "}
                                        <Badge
                                          pill
                                          variant={bidStatusColour(
                                            existingBid.bidStatus
                                          )}
                                        >
                                          {existingBid.bidStatus}
                                          {auctionVehicle?.won && (
                                            <NumberFormat
                                              value={
                                                auctionVehicle?.winningBidAmount
                                              }
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={"$"}
                                            />
                                          )}
                                        </Badge>
                                      </Col>
                                    </Row>
                                  )}
                                  {auctionVehicle?.won && (
                                    <Row>
                                      <Col>
                                        Winning bid amount:{" "}
                                        <NumberFormat
                                          value={
                                            auctionVehicle?.winningBidAmount
                                          }
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                  {!existingBid && (
                                    <Form.Group
                                      type="text"
                                      placeholder="Enter your bid"
                                    >
                                      <NumberFormat
                                        style={{
                                          padding: 5,
                                          // Green border
                                          border: "1px solid #198754",
                                          borderRadius: 5,
                                        }}
                                        as={Form.Control}
                                        value={auctionVehicle.VEHAskingPrice}
                                        placeholder="Enter your bid"
                                        displayType={"input"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        disabled={
                                          auctionFinished || !auctionStarted
                                        }
                                        allowNegative={false}
                                        onChange={(e) =>
                                          setBidAmount(
                                            e.target.value
                                              .replace("$", "")
                                              .replace(",", "")
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  )}
                                </Col>
                              </Row>
                            </Card.Text>
                            <Row>
                              <Col>
                                <ButtonGroup>
                                  <Button
                                    onClick={placeBid}
                                    size="sm"
                                    variant="outline-success"
                                    disabled={
                                      auctionFinished ||
                                      !auctionStarted ||
                                      existingBid ||
                                      markedAsNotInterested
                                    }
                                  >
                                    Place bid
                                  </Button>
                                  <Button
                                    onClick={widthdrawBid}
                                    size="sm"
                                    variant="outline-warning"
                                    disabled={
                                      auctionFinished ||
                                      !auctionStarted ||
                                      !existingBid ||
                                      markedAsNotInterested ||
                                      [
                                        "ACCEPTED",
                                        "REJECTED",
                                        "WITHDRAWN",
                                        "COUNTER_OFFER",
                                      ].includes(existingBid.bidStatus)
                                    }
                                  >
                                    Withdraw bid
                                  </Button>
                                  <Button
                                    size="sm"
                                    variant="outline-danger"
                                    disabled={
                                      auctionFinished ||
                                      !auctionStarted ||
                                      existingBid ||
                                      markedAsNotInterested
                                    }
                                    onClick={() =>
                                      setVehicleAsNotInterested(auctionVehicle)
                                    }
                                  >
                                    Not interested
                                  </Button>
                                </ButtonGroup>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: 15 }}>
                              <Col>
                                <Card.Title>Seller's counter offer:</Card.Title>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                {existingBid?.bidStatus === "COUNTER_OFFER" ? (
                                  <NumberFormat
                                    value={existingBid?.counterAmount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                ) : (
                                  <span>
                                    <i>No counter offer submitted</i>
                                  </span>
                                )}
                              </Col>
                            </Row>
                            {existingBid?.bidStatus === "COUNTER_OFFER" && (
                              <>
                                <Row className="mt-2">
                                  <Col>
                                    <ButtonGroup>
                                      <Button
                                        onClick={acceptCounterOffer}
                                        size="sm"
                                        variant="outline-success"
                                        disabled={
                                          auctionFinished ||
                                          !auctionStarted ||
                                          !existingBid ||
                                          markedAsNotInterested ||
                                          [
                                            "ACCEPTED",
                                            "REJECTED",
                                            "WITHDRAWN",
                                          ].includes(existingBid.bidStatus)
                                        }
                                      >
                                        Accept counter offer
                                      </Button>
                                      <Button
                                        onClick={rejectCounterOffer}
                                        size="sm"
                                        variant="outline-danger"
                                        disabled={
                                          auctionFinished ||
                                          !auctionStarted ||
                                          !existingBid ||
                                          markedAsNotInterested ||
                                          [
                                            "ACCEPTED",
                                            "REJECTED",
                                            "WITHDRAWN",
                                          ].includes(existingBid.bidStatus)
                                        }
                                      >
                                        Reject counter offer
                                      </Button>
                                    </ButtonGroup>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                    )}
                    {/* END VIEW FOR BUYER */}
                  </Row>
                  <Row>
                    <Col>
                      {/* Display bid history in timeline format */}
                      <Card style={{ marginBottom: 5 }}>
                        <Card.Body>
                          <Card.Title>Bid history</Card.Title>
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr style={stickyTableHeaderStyle}>
                                <th>Date ↑</th>
                                <th>Bid/Counter Amount</th>
                                <th>Status</th>
                                <th>By</th>
                              </tr>
                            </thead>
                            <tbody style={{ fontSize: 12 }}>
                              {existingBidHistory?.map((bid, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {dayjs
                                        .unix(bid.historyDate)
                                        .format("DD/MM/YYYY HH:mm:ss")}
                                    </td>
                                    <td>
                                      <NumberFormat
                                        value={
                                          bid.bidStatus === "COUNTER_OFFER"
                                            ? bid.counterAmount
                                            : bid.bidAmount
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                      />
                                    </td>
                                    <td>
                                      <Badge
                                        pill
                                        variant={bidStatusColour(
                                          bid.historyType
                                        )}
                                      >
                                        {bid.historyType}
                                      </Badge>
                                    </td>
                                    <td>{bid.displayName}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Button
                    style={{ float: "right" }}
                    variant="outline-primary"
                    onClick={() => history.push("/auctions/internal-tenders")}
                  >
                    Back to list
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default InternalTendersVehicleBidder;
