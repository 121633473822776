import React, { useState, useEffect } from "react";
import {
  DirectionsRenderer,
  GoogleMap,
  useJsApiLoader,
} from "@react-google-maps/api";
import {
  Form,
  Button,
  Container,
  ButtonGroup,
  Spinner,
  Col,
  Row,
  Card,
  Table,
} from "react-bootstrap";
import NumberFormat from "react-number-format";

const google = window.google;
const RATE_PER_KM = 5;

const containerStyle = {
  width: "100%",
  height: "300px",
  borderRadius: 5,
};

const sectionHeaderStyle = {
  margin: 10,
  fontWeight: "bold",
  textDecoration: "underline",
  fontSize: 12,
};

function GoogleMapComponent(props) {
  const { origin, destination, center, updateDirectionsHO } = { ...props };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "YOUR_API_KEY",
  });

  const [map, setMap] = React.useState(null);
  const [directions, setDirections] = useState(null);

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds();
      map.fitBounds(bounds);
      setMap(map);
    },
    [origin, destination]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    const directionsService = new google.maps.DirectionsService();
    // const origin = { lat: 40.756795, lng: -73.954298 };
    // const destination = {};
    // const destination = { lat: 41.756795, lng: -78.954298 };
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }, [origin, destination, center]);

  useEffect(() => {
    updateDirectionsHO(directions);
  }, [directions]);

  return isLoaded ? (
    <>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs="12" md="12" lg="12">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onLoad={onLoad}
              onUnmount={onUnmount}
              mapTypeId="roadmap"
            >
              {/* Child components, such as markers, info windows, etc. */}

              <>
                {directions && <DirectionsRenderer directions={directions} />}
              </>
            </GoogleMap>
          </Col>
          <Col style={{ marginTop: 15 }} xs="12" md="12" lg="12">
            {directions?.routes[0]?.legs?.map((leg, idx) => {
              return (
                <>
                  <Row style={sectionHeaderStyle}>Distance</Row>
                  <Row style={{ margin: 10 }}>
                    <Table striped bordered hover size="sm">
                      <tbody>
                        <tr>
                          <td>Leg</td>
                          <td>{idx + 1}</td>
                        </tr>

                        <tr>
                          <td>Start Address</td>
                          <td>{leg.start_address}</td>
                        </tr>

                        <tr>
                          <td>End Address</td>
                          <td>{leg.end_address}</td>
                        </tr>

                        <tr>
                          <td>Duration</td>
                          <td>{leg.duration.text}</td>
                        </tr>

                        <tr>
                          <td>Distance</td>
                          <td>{leg.distance.text}</td>
                        </tr>

                        <tr>
                          <td>Estimated rate</td>
                          <td>
                            {" "}
                            TBA $/km
                            {/* <NumberFormat
                            prefix={"**Coming Soon***"}
                            displayType="text"
                            thousandSeparator={true}
                            decimalScale={0}
                            value={0}
                          /> */}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Row>
                </>
              );
            })}
          </Col>
        </Row>
      </Container>
    </>
  ) : (
    <></>
  );
}

export default React.memo(GoogleMapComponent);

// const { DirectionsRenderer } = require("react-google-maps");
