import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Button,
  Container,
  FormGroup,
  ButtonGroup,
  Spinner,
  Col,
  Row,
  Card,
  InputGroup,
  Table,
  Alert,
} from "react-bootstrap";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import {
  Formik,
  ErrorMessage,
  FieldArray,
  useFormikContext,
  useField,
  Field,
} from "formik";
import _ from "lodash";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import { useUser } from "reactfire";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import FocusError from "./FocusError";
import FormikAutoCompleteComponent from "../FormikAutoCompleteComponent";
import GoogleMapComponent from "./GoogleMapComponent";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { useClientList } from "../../hooks/useClientList";
import PageViewLoggerComponent from "../PageViewLoggerComponent";
import vision from "react-cloud-vision-api";
import { Camera } from "react-bootstrap-icons";
import { BsCheck } from "react-icons/bs";
import { BsSearch } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { serverTimestamp } from "firebase/firestore";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// RegEx for phone number validation
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const MAX_VEHICLES = 0;

const classes = {
  card: {
    marginBottom: "3rem",
  },
};
// Schema for yup
const validationSchema = Yup.object().shape({
  // pickupType: Yup.string()
  //   .oneOf(["Customer", "Dealer"], "*required")
  //   .required("Required"),

  // Pickup info
  pickup: Yup.object().shape({
    name: Yup.string()
      .min(3, "Must be at least 3 characters")
      .required("Must enter a first name"),
    mobile: Yup.string()
      .min(8, "Enter a valid number")
      .required("Must enter a contact number"),
    location: Yup.object().shape({
      address: Yup.string()
        .min(3, "Must be at least 3 characters")
        // .max(16, "Must be 16 characters")
        .required("Must choose a location"),
    }),
  }),

  // Dropoff info
  dropOff: Yup.object().shape({
    name: Yup.string()
      .min(3, "Must be at least 3 characters")
      .required("Must enter a first name"),
    mobile: Yup.string()
      .min(8, "Enter a valid number")
      .required("Must enter a contact number"),
    location: Yup.object().shape({
      address: Yup.string()
        .min(3, "Must be at least 3 characters")
        // .max(16, "Must be 16 characters")
        .required("Must choose a location"),
    }),
  }),

  // // Vehicles
  vehicleItem: Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        VIN: Yup.string()
          // .min(16, "Must be 16 characters")
          // .max(16, "Must be 16 characters")
          .required("Must enter at least one VIN"),
      })
    ),

  // Charge to
  chargeTo: Yup.object().shape({
    name: Yup.string()
      .min(3, "Must be at least 3 characters")
      .required("Must enter information"),
  }),
});

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const initialValuesDefault = {
  pickupType: "",
  pickup: {
    name: "",
    clientClass: "",
    comments: "",
    clientObj: {},
    location: {
      value: "",
      address: "",
      coordinates: {},
    },
    mobile: "",
    email: "",
  },
  dropOff: {
    name: "",
    clientClass: "",
    comments: "",
    clientObj: {},
    location: {
      value: "",
      address: "",
      coordinates: {},
    },
    mobile: "",
    email: "",
  },
  vehicleItem: [
    {
      VIN: "",
      branch: "",
      EclipseStockNumber: "",
      make: "",
      model: "",
      variant: "",
      colour: "",
      year: "",
      comments: "",
    },
  ],
  comments: "",
  user: "",
  username: "",
  status: "Request",
  chargeTo: {
    name: "",
    id: "",
    db: "",
  },
};

function ChooseVinModal(props) {
  const {
    vehicles,
    selectVehicle,
    show,
    setShowChooseVehicleModal,
    existingBooking,
  } = props;
  const [hasSelected, setHasSelected] = useState(false);

  const closeModalOnConfirm = () => {
    if (hasSelected) {
      setShowChooseVehicleModal(false);
    }
  };

  const selectVehicleAction = (v) => {
    setHasSelected(true);
    selectVehicle(v);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShowChooseVehicleModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose vehicle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {existingBooking !== null
            ? existingBooking.map((eb, idx) => {
                return (
                  <Alert variant="warning">
                    This VIN has been booked already, please check before
                    proceeding
                    <br />
                    Booked by: {eb?.displayName}
                    <br />
                    Booking created:
                    {dayjs.unix(eb?.createdAt?.seconds).format("DD MMM")}
                    <br />
                    Current status: {eb?.status}
                  </Alert>
                );
              })
            : null}
          More than 1 vehicle was found in the system for the VIN you searched
          for. Please choose the correct vehicle by Stock ID
          <Table striped bordered hover>
            <thead>
              <tr>
                <td>Stock ID</td>
                <td>Branch</td>
                <td>Status</td>
                <td>Choose</td>
              </tr>
            </thead>
            <tbody>
              {vehicles.map((v) => {
                return (
                  <tr>
                    <td>{v.VEHStockNumber}</td>
                    <td>{v.branch_desc}</td>
                    <td>{v.Status}</td>
                    <td>
                      <Button
                        variant="outline-dark"
                        onClick={() => selectVehicleAction(v)}
                      >
                        <BsCheck />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="secondary"
            onClick={() => setShowChooseVehicleModal(false)}
          >
            Close
          </Button> */}
          <Button
            disabled={!hasSelected}
            variant="primary"
            onClick={closeModalOnConfirm}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const VINTextField = (props) => {
  const [vin, setVin] = useState("");
  const [multipleVehiclesChoiceArray, setMultipleVehiclesChoiceArray] =
    useState([]);

  const [searchResults, setSearchResults] = useState([]);
  const [isLoadingVinImageRecog, setIsLoadingVinImageRecog] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [showChooseVehicleModal, setShowChooseVehicleModal] = useState(false);
  const [existingBooking, setExistingBooking] = useState(null);

  const { values, setFieldValue } = useFormikContext();

  const vinImageRef = useRef(null);

  const fieldNameMake = `vehicleItem.${props.index}.make`;
  const fieldNameESno = `vehicleItem.${props.index}.EclipseStockNumber`;
  const fieldNameModel = `vehicleItem.${props.index}.model`;
  const fieldNameVariant = `vehicleItem.${props.index}.variant`;
  const fieldNameColour = `vehicleItem.${props.index}.colour`;
  const fieldNameYear = `vehicleItem.${props.index}.year`;
  const fieldNameBranch = `vehicleItem.${props.index}.branch`;
  const fieldVIN = `vehicleItem.${props.index}.VIN`;

  const [field, meta, helpers] = useField(fieldVIN);

  const { value } = meta;
  const { setValue } = helpers;

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const selectVehicle = (v) => {
    toast.info(`Selected stock: ${v.VEHStockNumber} from ${v.branch_desc}`);
    updateVehDetailFields(v);
  };

  const handleChangeVin = (e) => {
    // e.preventDefault();
    setVin(e.target.value);
    setFieldValue(props.fieldname, e.target.value);
  };

  const vinImageRefClick = () => {
    if (vinImageRef !== null) {
      vinImageRef.current.click();
    }
  };

  const handleVinImageChange = async (event) => {
    setVin("");
    setFieldValue(props.fieldname, "");
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    // console.log(file);
    let fileB64 = null;
    setIsLoadingVinImageRecog(true);
    // Send to GV to detect text
    const ocrResult = null;

    const reader = new FileReader();
    reader.onloadend = async () => {
      // Load img for canvas

      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      // log to console
      // logs wL2dvYWwgbW9yZ...
      // console.log("converted to base64");
      fileB64 = base64String;
      setModalImage(URL.createObjectURL(file));
      let req = new vision.Request({
        image: new vision.Image({
          base64: fileB64,
        }),
        features: [new vision.Feature("TEXT_DETECTION")],
      });

      await vision.annotate(req).then((res) => {
        // console.log(res);

        let VINLabelIdx = 0;
        let VINValueIdx = 0;
        let VINValue = "";

        res.responses[0].textAnnotations?.forEach(async (ta, idx) => {
          // Search by VIN label order
          // if (ta.description === "VIN") {
          //   VINLabelIdx = idx;
          //   VINValueIdx = idx + 1;
          //   VINValue = res.responses[0].textAnnotations[idx + 1].description;
          // }

          // Search by finding WDC1660242A704804 (17 digit)
          if (ta?.description?.length === 17) {
            VINValue = ta.description;
            console.log(`vin: ${VINValue}`);
            // console.log(props.fieldname);
            // console.log(fieldVIN);
            setVin(VINValue);
            getDataByVIN(VINValue);
            setFieldValue(fieldVIN, VINValue);
            // setCVBoundinPoly(ta.boundingPoly.vertices);
          }
        });
        if (VINValue.length > 0) {
          toast.success(
            "Converted image to VIN, please check and then hit Search"
          );
        }
        setIsLoadingVinImageRecog(false);
        if (VINValue === "") {
          toast.warning(
            "Could not find VIN in image, please try again or enter manually"
          );
        }
      });
    };
    reader.readAsDataURL(file);
  };

  const updateVehDetailFields = (data) => {
    setFieldValue(fieldNameESno, data.VEHStockNumber);
    setFieldValue(fieldNameMake, data.VEHMake);
    setFieldValue(fieldNameModel, data.VEHModel);
    setFieldValue(fieldNameVariant, data["Variant & Series"]);
    setFieldValue(fieldNameColour, data.VEHColour1);
    setFieldValue(fieldNameYear, data.VEHModelYear);
    setFieldValue(fieldNameBranch, data.branch_desc);
    setFieldValue(fieldVIN, data.VEHVIN);
  };

  const getDataByVIN = async (vinManual = "") => {
    var vehicles = null;
    let vinLocal = "";
    // Clear other field values
    setFieldValue(fieldNameESno, "");
    setFieldValue(fieldNameMake, "");
    setFieldValue(fieldNameModel, "");
    setFieldValue(fieldNameVariant, "");
    setFieldValue(fieldNameYear, "");
    setFieldValue(fieldNameBranch, "");

    if (vinManual.length > 0) {
      vinLocal = vinManual;
    } else {
      vinLocal = vin;
    }

    var searchFullVIN = true;
    if (vinLocal.length === 0 || vinLocal.length < 15) {
      if (vinLocal.length === 6) {
        // If 6 digits, search truncated VIN (last 6 digits)
        searchFullVIN = false;
      } else {
        toast.warning("VIN doesn't seem correct, please check");
        return;
      }
    }

    setSearchResults([]);
    setExistingBooking(null);

    if (searchFullVIN) {
      vehicles = firestore
        .collection("inventory-summary")
        .where("VEHVIN", "==", vinLocal);
    } else {
      vehicles = firestore
        .collection("inventory-summary")
        .where("VINTrunc", "==", vinLocal);
    }

    await vehicles
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.size > 0) {
          // querySnapshot
          //   const vehData = doc.data();
          toast.success(`Found ${querySnapshot.size} vehicle in stock`);
          const vehTmp = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            vehTmp.push(data);
          });
          // Set  array
          setSearchResults(vehTmp);
          console.log(vehTmp);
          if (vehTmp.length > 0) {
            // Check for exisitng bookings that are using this VIN (bookings not in Finalised or Archived)
            const searchVIN = vehTmp[0]?.VEHVIN;
            const existingBookingTmp = await firestore
              .collection("freight-bookings")
              .where("indexVIN", "array-contains-any", [searchVIN])
              // .where("status", "not-in", ["Finalised", "Archive"])
              .get()
              .then((querySnapshot) => {
                const tmpSearch = [];
                querySnapshot.forEach((doc) => {
                  const data = doc.data();
                  const id = doc.id;
                  if (!["Finalised", "Archive"].includes(data?.status))
                    tmpSearch.push({
                      id,
                      ...data,
                    });
                });
                if (tmpSearch.length > 0) {
                  toast.success(
                    `Found ${querySnapshot.size} bookings with the same VIN`
                  );
                }
                setExistingBooking(tmpSearch);
              });

            if (vehTmp.length === 1) {
              updateVehDetailFields(vehTmp[0]);
              toast.info(
                `${vehTmp[0].VEHStockNumber} - ${vehTmp[0].VEHMake} - ${vehTmp[0].VEHModel}`
              );
            } else if (vehTmp.length > 1) {
              setMultipleVehiclesChoiceArray(vehTmp);
              setShowChooseVehicleModal(true);
              return;
            }
            // setValue(vehTmp[0].VEHStockNumber);
          }

          //   console.log(`${vehData}`);
        } else {
          toast.error("No vehicle found in stock, check VIN");
        }
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  useEffect(() => {
    vision.init({ auth: "AIzaSyBYIpmrTo_qzFvAiG4FAZA4J9AhWGUS4AQ" });
  });

  return (
    <>
      <Form.Label>{props.label}</Form.Label>
      <ChooseVinModal
        show={showChooseVehicleModal}
        vehicles={multipleVehiclesChoiceArray}
        selectVehicle={selectVehicle}
        setShowChooseVehicleModal={setShowChooseVehicleModal}
        existingBooking={existingBooking}
      />
      {/* <Form.Group controlId={`form${props.fieldname}`}> */}
      {existingBooking !== null
        ? existingBooking.map((eb, idx) => {
            return (
              <Alert variant="warning">
                This VIN has been booked already, please check before proceeding
                <br />
                Booked by: {eb?.displayName}
                <br />
                Booking created:
                {dayjs.unix(eb?.createdAt?.seconds).format("DD MMM")}
                <br />
                Current status: {eb?.status}
              </Alert>
            );
          })
        : null}
      <Form.Control
        type={props.type || "text"}
        /* This name property is used to access the value of the form element via values.nameOfElement */
        name={props.fieldname}
        placeholder={props.placeholder}
        /* Set onChange to handleChange */
        onChange={handleChangeVin}
        /* Set onBlur to handleBlur */
        onBlur={props.handleBlur}
        /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
        value={value}
        /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
        className={
          props.touched[props.fieldname] && props.errors[props.fieldname]
            ? "error"
            : null
        }
        {...props}
      />

      {/* <Form.Group> */}
      <ButtonGroup>
        {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
        <Button variant="info" onClick={vinImageRefClick}>
          {isLoadingVinImageRecog ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              <Camera />
            </>
          )}
          <input
            disabled={isLoadingVinImageRecog}
            type="file"
            // id="vin-image-{props.index}"
            id={`vin-image-${props.index}`}
            ref={vinImageRef}
            style={{ display: "none" }}
            onChange={handleVinImageChange}
          />
        </Button>
        <Button block variant="dark" onClick={getDataByVIN}>
          <BsSearch />
        </Button>
      </ButtonGroup>
      {/* </Form.Group> */}
      {/* </InputGroup> */}
      <ErrorMessage name={props.fieldname}>
        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
      </ErrorMessage>
    </>
  );
};

const TextFieldVEH = (props) => {
  const [field, meta, helpers] = useField(props.fieldname);

  const { value } = meta;

  return (
    <>
      <Form.Group controlId={`form${props.fieldname}`}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control
          type={props.type || "text"}
          /* This name property is used to access the value of the form element via values.nameOfElement */
          name={props.fieldname}
          placeholder={props.placeholder}
          /* Set onChange to handleChange */
          onChange={props.handleChange}
          /* Set onBlur to handleBlur */
          onBlur={props.handleBlur}
          /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
          value={value}
          /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
          className={
            props.touched[props.fieldname] && props.errors[props.fieldname]
              ? "error"
              : null
          }
          {...props}
        />

        {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
        <ErrorMessage name={props.fieldname}>
          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
        </ErrorMessage>
      </Form.Group>
    </>
  );
};

const TextField = (props) => {
  const [field, meta, helpers] = useField(props.fieldname);

  const { value } = meta;
  return (
    <>
      <Form.Group controlId={`form${props.fieldname}`}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control
          type={props.type || "text"}
          /* This name property is used to access the value of the form element via values.nameOfElement */
          name={props.fieldname}
          placeholder={props.placeholder}
          /* Set onChange to handleChange */
          onChange={props.handleChange}
          /* Set onBlur to handleBlur */
          onBlur={props.handleBlur}
          /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
          value={value}
          /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
          className={
            props.touched[props.fieldname] && props.errors[props.fieldname]
              ? "error"
              : null
          }
          {...props}
        />

        {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
        <ErrorMessage name={props.fieldname}>
          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
        </ErrorMessage>
      </Form.Group>
    </>
  );
};

const TransactionTypeComponent = (props) => {
  const {
    values: { pickupType },
    setFieldValue,
  } = useFormikContext();

  const handleChange = (e) => {
    setFieldValue(props.name, e.target.value);
  };
  // Runs everytime values changes for mode to clear out
  // the tradein array if buying is selected
  useEffect(() => {
    if (pickupType === "Customer") {
      // setFieldValue("tradeinArray", []);
    }
  }, [pickupType]);

  return (
    <Form.Group controlId="formmode">
      <Form.Row>
        <Col xs={6}>
          <Form.Check
            inline
            // custom
            type="radio"
            label="Customer"
            name="pickupType"
            onChange={handleChange}
            value="Customer"
          />
        </Col>
        <Col xs={6}>
          <Form.Check
            inline
            // custom
            type="radio"
            label="Dealer"
            name="pickupType"
            onChange={handleChange}
            value="Dealer"
          />
        </Col>
      </Form.Row>
      {props.touched.pickupType && props.errors.pickupType ? (
        <div className="error-message" style={{ color: "red" }}>
          {props.errors.pickupType}
        </div>
      ) : null}
    </Form.Group>
  );
};

const ClientClassComponent = (props) => {
  const {
    // values: { props.name.clientClass },
    setFieldValue,
  } = useFormikContext();

  const handleChange = (e) => {
    setFieldValue(props.name, e.target.value);
  };

  return (
    <Form.Group controlId="formSaleClassPickup">
      <Form.Row>
        {/* <Col xs={6}>
          <Form.Check
            inline
            // custom
            type="radio"
            label="Internal"
            name={props.name}
            onChange={handleChange}
            value="Internal"
          />
        </Col>
        <Col xs={6}>
          <Form.Check
            inline
            // custom
            type="radio"
            label="External"
            name={props.name}
            onChange={handleChange}
            value="External"
          />
        </Col> */}
      </Form.Row>
      {props.touched.saleClassPickup && props.errors.saleClassPickup ? (
        <div className="error-message" style={{ color: "red" }}>
          {props.errors.saleClassPickup}
        </div>
      ) : null}
    </Form.Group>
  );
};

const ClientSelectListComponent = (props) => {
  const {
    // values: { props.name.clientClass },
    setFieldValue,
  } = useFormikContext();

  // Get field context for name, mobile and address
  const fieldNameAddress = `${props.object}.location`;
  const fieldNameContact = `${props.object}.name`;
  const fieldNameMobile = `${props.object}.mobile`;
  const fieldNameEmail = `${props.object}.email`;

  const [clientList] = useClientList();

  return (
    <Form.Group controlId={props.name}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Row>
        <Col>
          <Typeahead
            id="onclear-client-list"
            name={props.name}
            options={clientList || []}
            // onChange={props.handleChange}
            /* Set onBlur to handleBlur */
            onBlur={props.handleBlur}
            /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
            // value={props.values[props.fieldname]}
            labelKey="name"
            onChange={(selected) => {
              if (selected.length > 0) {
                console.log("selected", selected);
                const currentSelection = selected[0];
                setFieldValue(props.name, currentSelection);

                // Set related fields to selected value
                setFieldValue(fieldNameContact, currentSelection.Contact);
                setFieldValue(fieldNameMobile, currentSelection.Phone);
                setFieldValue(fieldNameEmail, currentSelection.Email);
                setFieldValue(fieldNameAddress, currentSelection.location);
              } else if (selected.length === 0) {
                setFieldValue(props.name, {});
                // Set related fields to empty
                setFieldValue(fieldNameContact, "");
                setFieldValue(fieldNameMobile, "");
                setFieldValue(fieldNameEmail, "");
                setFieldValue(fieldNameAddress, "");
              }
            }}
            // value={values.}
            placeholder="Choose internal dealer to auto populate fields..."
            {...props}
          >
            {({ onClear, selected }) => (
              <div className="rbt-aux">
                {!!selected.length && <ClearButton onClick={onClear} />}
                {!selected.length && <Spinner animation="grow" size="sm" />}
              </div>
            )}
          </Typeahead>
        </Col>
      </Form.Row>
    </Form.Group>
  );
};

const FreightBookingForm = () => {
  const [nowTime, setNowTime] = useState(dayjs().format("YYYY-MM-DD HH:mm"));
  const [showMap, setShowMap] = useState(false);
  const [directions, setDirectionInformation] = useState({});
  const [localFreightAlert, setLocalFreightAlert] = useState(false);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const user = useUser();
  const history = useHistory();
  const formRef = useRef();
  const [clientList] = useClientList();

  const goBack = () => {
    history.push("/");
  };

  useEffect(() => {
    if (directions) {
      if (typeof directions.routes !== "undefined") {
        const kms = directions?.routes[0]?.legs[0].distance?.value / 1000;
        if (kms <= 25) {
          setLocalFreightAlert(true);
        } else {
          setLocalFreightAlert(false);
        }
      }
    }
  }, [JSON.stringify(directions)]);

  return (
    <Container>
      <br />
      <ToastContainer />
      {localFreightAlert ? (
        <Alert variant="warning">
          Local trip detected, does this require external freight?
        </Alert>
      ) : null}

      <Formik
        initialValues={initialValuesDefault}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        innerRef={formRef}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          toast.info("Submitting, please wait");
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);

          // Simulate submitting to database, shows us values submitted, resets form
          // alert(JSON.stringify(values, null, 2));
          // Add user details to value
          values.user = user.data.uid;
          values.displayName = user.data.displayName;
          values.userPhone = user.data.phoneNumber;
          values.email = user.data.email;
          values.createdAt = serverTimestamp();
          // values.createdAt =
          //   firebaseconst.firestore.FieldValue.serverTimestamp();
          values.status = "Request";
          values.directions = directions?.routes[0]?.legs.map((l) => {
            return {
              start_address: l.start_address,
              end_address: l.end_address,
              duration: l.duration.text,
              distance: l.distance.text,
              distanceKm: l.distance.value,
              durationSeconds: l.duration.value,
            };
          });

          firestore
            .collection("freight-bookings")
            .add(values)
            .then(async (r) => {
              toast.success("New booking request created");
              resetForm();
              setSubmitting(false);
              await sleep(2500);
              history.push(`/freight-booking-list/${r.id}`);
            })
            .catch((e) => {
              toast.error(`Failed to save: ${e.message}`);
              // toast.error(e.message);
              console.log(e);

              setSubmitting(false);
            });
        }}
      >
        {/* Callback function containing Formik state and helpers that handle common form actions */}
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          isSubmitting,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            {/* <div>{JSON.stringify(values, null, 2)}</div> */}
            {/* <Card style={{ marginBottom: "3rem" }}>
              <Card.Body>
                <h4>Pickup type</h4>
                <TransactionTypeComponent
                  name="pickupType"
                  touched={touched}
                  errors={errors}
                />
              </Card.Body>
            </Card> */}
            {/* START PICKUP INFO */}
            <Card style={{ marginBottom: "3rem" }}>
              <Card.Body>
                <h4>
                  {values.pickupType === "Customer"
                    ? "Customer Pickup "
                    : "Dealer Pickup "}
                  Information
                </h4>
                <h4>Client type</h4>
                <ClientClassComponent
                  name="pickup.clientClass"
                  touched={touched}
                  errors={errors}
                />
                <ClientSelectListComponent
                  name="pickup.clientObj"
                  fieldname="pickup.clientObj"
                  label="Pickup client"
                  object="pickup"
                  placeholder=""
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                {/* <p>
                  If internal, have Select of internal defsalesclass === O,
                  Select value is CLISurname (Typeahead) Then populate address,
                  hide Full name, get input from mobile
                </p> */}
                {values.pickup.clientObj?.name?.length > 0 ? null : (
                  <Field
                    name="pickup.location"
                    component={FormikAutoCompleteComponent}
                  />
                )}

                <TextField
                  fieldname="pickup.name"
                  label="Contact name"
                  placeholder="John Smith"
                  type="text"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                {/* <TextField
                  fieldname="pickup.email"
                  label="Email address"
                  placeholder="customer@email.com"
                  type="email"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                /> */}
                <TextField
                  fieldname="pickup.mobile"
                  label="Mobile"
                  placeholder="0412 123 456"
                  type="tel"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TextField
                  fieldname="pickup.comments"
                  label="Comments"
                  // placeholder="0412 123 456"
                  type="text"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Card.Body>
            </Card>
            {/* END PICKUP INFO */}
            {/* START DROPOFF INFO */}
            {localFreightAlert ? (
              <Alert variant="warning">
                Local trip detected, does this require external freight?
              </Alert>
            ) : null}
            <Card style={{ marginBottom: "3rem" }}>
              <Card.Body>
                <h4>
                  {values.pickupFrom === "Customer"
                    ? "Dealer Dropoff "
                    : "Dealer Dropoff "}
                  Information
                </h4>
                <ClientClassComponent
                  name="dropOff.clientClass"
                  touched={touched}
                  errors={errors}
                />
                {/* {values.dropOff.clientClass === "Internal" ? ( */}
                <ClientSelectListComponent
                  name="dropOff.clientObj"
                  fieldname="dropOff.clientObj"
                  label="Dropoff client"
                  object="dropOff"
                  placeholder=""
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                {/* ) : null} */}
                {/* <p>
                  If internal, have Select of internal defsalesclass === O,
                  Select value is CLISurname (Typeahead) Then populate address,
                  hide Full name, get input from mobile If external, leave the
                  standard fields
                </p> */}

                {values.dropOff.clientObj?.name?.length > 0 ? null : (
                  <Field
                    name="dropOff.location"
                    component={FormikAutoCompleteComponent}
                  />
                )}
                {/* <TextField
                  fieldname="pickup.address"
                  label="Address"
                  placeholder="38 Princess Hwy, Vermont VIC 3212"
                  type="text"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                /> */}
                <TextField
                  fieldname="dropOff.name"
                  label="Contact name"
                  placeholder="John Smith"
                  type="text"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                {/* <TextField
                  fieldname="pickup.email"
                  label="Email address"
                  placeholder="customer@email.com"
                  type="email"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                /> */}
                <TextField
                  fieldname="dropOff.mobile"
                  label="Mobile"
                  placeholder="0412 123 456"
                  type="tel"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TextField
                  fieldname="dropOff.comments"
                  label="Comments"
                  // placeholder="0412 123 456"
                  type="text"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Card.Body>
            </Card>
            {/* END DROPOFF INFO */}

            {/* START DISTANCE INFO */}
            {(_.isEmpty(values.pickup?.location.coordinates) === false) &
            (_.isEmpty(values.dropOff?.location.coordinates) === false) ? (
              <>
                {localFreightAlert ? (
                  <Alert variant="warning">
                    Local trip detected, does this require external freight?
                  </Alert>
                ) : null}
                <Card style={{ marginBottom: "3rem" }}>
                  <Card.Body>
                    <h4>Directions</h4>
                    <GoogleMapComponent
                      origin={values.pickup?.location.coordinates}
                      destination={values.dropOff?.location.coordinates}
                      center={values.pickup?.location.coordinates}
                      updateDirectionsHO={setDirectionInformation}
                    />
                  </Card.Body>
                </Card>
              </>
            ) : null}
            {/* END DISTANCE INFO */}
            {/* START VEHICLE DETAILS ARRAY */}
            <Card
              style={{ marginBottom: "3rem" }}
              //   hidden={values.mode === "Buying"}
            >
              <Card.Body>
                <h4>
                  Vehicle Details [{values.vehicleItem.length} item
                  {values.vehicleItem.length > 1 ? "s" : null}]
                </h4>
                {/* <p>
                  Pass VIN to Gez proc. If null return, then open make / model
                  for manual entry. Using Select data from In-Stock route
                  keeping
                </p> */}
                {/* <ul>
                  <li>Add Purchase price(s)</li>
                </ul> */}
                {/* START VEH ARRAY SECTION  */}
                {values.vehicleItem ? (
                  <FieldArray name="vehicleItem">
                    {({ insert, remove, push }) => (
                      <>
                        {values.vehicleItem.length > 0 &&
                          values.vehicleItem.map((vehicleItem, index) => (
                            <Card style={{ marginBottom: "3rem" }} key={index}>
                              <Card.Body>
                                <h5>Vehicle {index + 1}</h5>
                                <VINTextField
                                  fieldname={`vehicleItem.${index}.VIN`}
                                  label="VIN"
                                  placeholder="VIN"
                                  type="text"
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  index={index}
                                />
                                <TextFieldVEH
                                  fieldname={`vehicleItem.${index}.branch`}
                                  label="Branch"
                                  disabled
                                  // placeholder="510123"
                                  type="text"
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                />
                                <TextFieldVEH
                                  fieldname={`vehicleItem.${index}.EclipseStockNumber`}
                                  label="EclipseStockNumber"
                                  disabled
                                  // placeholder="510123"
                                  type="text"
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                />
                                <TextFieldVEH
                                  fieldname={`vehicleItem.${index}.make`}
                                  label="Make"
                                  disabled
                                  placeholder="Make"
                                  type="text"
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                />
                                <TextFieldVEH
                                  fieldname={`vehicleItem.${index}.model`}
                                  label="Model"
                                  disabled
                                  placeholder="Model"
                                  type="text"
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                />

                                <TextFieldVEH
                                  fieldname={`vehicleItem.${index}.variant`}
                                  label="Variant"
                                  disabled
                                  placeholder="Variant"
                                  type="text"
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                />

                                <TextFieldVEH
                                  fieldname={`vehicleItem.${index}.colour`}
                                  label="Colour"
                                  disabled
                                  placeholder="Colour"
                                  type="text"
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                />

                                <TextFieldVEH
                                  fieldname={`vehicleItem.${index}.year`}
                                  label="Year"
                                  disabled
                                  placeholder="Year"
                                  type="text"
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                />

                                <TextFieldVEH
                                  fieldname={`vehicleItem.${index}.comments`}
                                  label="Comments"
                                  placeholder="Comments"
                                  type="textarea"
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                />
                              </Card.Body>
                              <Card.Footer
                                style={{ textAlign: "right", color: "red" }}
                              >
                                <Button
                                  variant="secondary"
                                  style={{ color: "red" }}
                                  onClick={() => remove(index)}
                                >
                                  X
                                </Button>
                              </Card.Footer>
                            </Card>
                          ))}
                        {/* <Container
                          style={{ textAlign: "right", marginBottom: "1rem" }}
                        >
                          <Button
                            variant="success"
                            // style={{ color: "red" }}
                            onClick={() => {
                              if (values.vehicleItem.length > MAX_VEHICLES) {
                                toast.info("Maximum number of vehicles added");
                                return;
                              }
                              push(initialValuesDefault.vehicleItem[0]);
                            }}
                          >
                            + Add another vehicle
                          </Button>
                        </Container> */}
                      </>
                    )}
                  </FieldArray>
                ) : null}
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <h4>Charge</h4>
                <TextField
                  fieldname="chargeTo.name"
                  label="Who pays for freight?"
                  placeholder="e.g. Dutton One Docklands"
                  type="text"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Card.Body>
            </Card>
            <br />
            <br />

            {/* END VEHICLE DETAILS ARRAY */}
            <Card style={{ marginBottom: "3rem" }}>
              <Card.Body>
                <h4>Office use only</h4>

                <Form.Label>Entered by:</Form.Label>
                <Form.Control
                  disabled
                  name="displayName"
                  value={user && user.data.displayName}
                ></Form.Control>
                {/* <br />
                <Form.Control
                  disabled
                  name="username"
                  value={user && user.data.phoneNumber}
                ></Form.Control>
                <br />
                <Form.Control
                  disabled
                  name="email"
                  value={user && user.data.email}
                ></Form.Control> */}

                <Form.Label>Created on:</Form.Label>
                <Form.Control
                  disabled
                  name="dateCreated"
                  value={nowTime}
                ></Form.Control>

                <Form.Label>Status:</Form.Label>
                <Form.Control
                  disabled
                  name="status"
                  value={"Request"}
                ></Form.Control>
              </Card.Body>
            </Card>
            <ButtonGroup>
              <Button
                disabled={isSubmitting}
                variant="dark"
                type="submit"
                onClick={handleSubmit}
              >
                {isSubmitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Submit"
                )}
              </Button>
              <Button variant="light" onClick={goBack}>
                Back to home
              </Button>
            </ButtonGroup>
            <FocusError />
          </Form>
        )}
      </Formik>
      <br />
      <br />
      <br />
      <PageViewLoggerComponent />
    </Container>
  );
};

export default FreightBookingForm;
