import { useUser } from "reactfire";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import filter from "@inovua/reactdatagrid-community/filter";

import "@inovua/reactdatagrid-community/index.css";
import { groupBy as rowGrouper } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useMemo, useCallback } from "react";

import Table from "react-bootstrap/Table";

import { useEffect } from "react";

import NumberFormat from "react-number-format";
import dayjs from "dayjs";

import { useHistory } from "react-router";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  useParams,
} from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import BackdropComponent from "../BackdropComponent";
import { CgDetailsMore } from "react-icons/cg";
import { BsExclamationCircle } from "react-icons/bs";
import { Badge, Button, ButtonGroup, Dropdown } from "react-bootstrap";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const numericalASCProfit = (a, b) => {
  if (a.TrueProfit < b.TrueProfit) {
    return -1;
  }
  if (a.TrueProfit > b.TrueProfit) {
    return 1;
  }
  return 0;
};

const statusList = [
  { name: "Request", title: "Open" },
  { name: "Booked", title: "Booked" },
  { name: "Transit", title: "In Transit" },
  { name: "Finalised", title: "Finalised" },
  { name: "Archive", title: "Archive" },
];

const style = {
  dataGridFill: {
    height: "70vh",
  },
  searchCard: {
    margin: 5,
    padding: 5,
    border: "1px solid #efefef",
    borderRadius: 3,
    cursor: "pointer",
  },
};

const defaultFilterValue = [
  {
    name: "status",
    operator: "inlist",
    type: "select",
  },
  { name: "id", operator: "contains", type: "string" },
  { name: "createdBy", operator: "contains", type: "string" },
  { name: "sugar_assigned_user_name", operator: "contains", type: "string" },
  { name: "from", operator: "contains", type: "string" },
  { name: "to", operator: "contains", type: "string" },
  { name: "sugar_bu", operator: "contains", type: "string" },
  { name: "stockId", operator: "gte", type: "number" },
  { name: "originBranch", operator: "contains", type: "string" },
  { name: "make", operator: "contains", type: "string" },
  { name: "model", operator: "contains", type: "string" },
  { name: "distance", operator: "contains", type: "string" },
  { name: "carrier", operator: "contains", type: "string" },
  { name: "carrierEstimateCost", operator: "gte", type: "number" },
  // { name: "assigned_user_name", operator: "contains", type: "string" },
  // { name: "age", operator: "gte", type: "number", value: 21 },
];

const gridStyle = { minHeight: 700 };

const SearcResultMakeFieldSID = (r) => {
  const [veh, setVeh] = useState({});

  useEffect(() => {
    const tmpVeh = r.vehicleItem.find(
      (v) => v.EclipseStockNumber === parseInt(r.serachQStockId)
    );

    if (tmpVeh) {
      setVeh(tmpVeh);
    }
  }, [r]);

  return <p>{veh?.make}</p>;
};

const SearcResultMakeFieldVIN = (r) => {
  const [veh, setVeh] = useState({});

  useEffect(() => {
    const tmpVeh = r.vehicleItem.find((v) => v.VIN === r.searchQVin);

    if (tmpVeh) {
      setVeh(tmpVeh);
    }
  }, [r]);

  return <p>{veh?.make}</p>;
};

const checkboxColumn = {
  renderCheckbox: (checkboxProps, cellProps) => {
    const { onChange, checked } = checkboxProps;

    const background = !checked ? "#ABEBC6" : "#7986cb";
    const border =
      checked === false ? "2px solid #7C8792" : "2px solid #7986CB";

    if (cellProps.headerCell) {
      return null;
    }
    return undefined;

    // return (
    //   <div
    //     style={{
    //       cursor: "pointer",
    //       background,
    //       borderRadius: "50%",
    //       height: "24px",
    //       width: "24px",
    //       display: "flex",
    //       alignItems: "center",
    //       justifyContent: "center",
    //       border,
    //       fontSize: 12,
    //       color: checked === false ? "inherit" : "#E8E8E8",
    //     }}
    //     onClick={(e) => {
    //       e.stopPropagation();
    //       onChange(!checked);
    //     }}
    //   >
    //     {checked === false ? "O" : checked === true ? "X" : "--"}
    //   </div>
    // );
  },
};

const SearchResults = ({ results, searchQ }) => {
  const history = useHistory();
  return (
    <>
      <h4>Search results</h4>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Created</th>
            <th>Make</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {results.map((r) => {
            return (
              <OverlayTrigger
                placement="auto-start"
                delay={{ show: 10, hide: 50 }}
                overlay={<Tooltip id="button-tooltip-2">{r.id}</Tooltip>}
              >
                <tr
                  onClick={() => history.push(`/freight-booking-list/${r.id}`)}
                >
                  <td>{dayjs.unix(r.createdAt?.seconds).format("DD-MM-YY")}</td>
                  {/* <td>{`Click here to open BID:${r.id}`}</td> */}
                  <td>
                    {r.searchQVin === null
                      ? SearcResultMakeFieldSID(r)
                      : SearcResultMakeFieldVIN(r)}
                  </td>
                  <td>{r.status}</td>
                </tr>
              </OverlayTrigger>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

function FreightListFilteredTableViewNew() {
  const [freightBookingList, setFreightBookingList] = useState([]);
  const [querySearchResults, setQuerySearchResults] = useState([]);
  const [createdOriginSlice, setCreatedOriginSlice] = useState("");
  const [viewTypeRequest, setViewTypeRequest] = useState(1); //0 LIST, 1 CARD
  const [viewTypeBooked, setViewTypeBooked] = useState(0); //0 LIST, 1 CARD
  const [viewTypeTransit, setViewTypeTransit] = useState(0); //0 LIST, 1 CARD
  const [viewTypeFinalised, setViewTypeFinalised] = useState(0); //0 LIST, 1 CARD
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [[sortColumn, sortDirection], setSort] = useState(["id", "NONE"]);
  const [filterValue, setFilterValue] = useState(defaultFilterValue);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowIDs, setSelectedRowIDs] = useState([]);

  const user = useUser();

  const columns = [
    {
      name: "actions",
      header: "Actions",
      width: 100,

      render({ data }) {
        return (
          <>
            {data.queuedActionsOutstanding ? (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id={data.id}>
                    There are queued actions currently processing in Eclipse
                  </Tooltip>
                }
              >
                <BsExclamationCircle style={{ backgroundColor: "yellow" }} />
              </OverlayTrigger>
            ) : null}

            {/* <Button
              variant="link"
              onClick={() => {
                history.push(`/freight-booking-list/${row.id}`);
              }}
            >
              <CgDetailsMore style={{ color: "green" }} />
            </Button> */}
            <Link target={"_blank"} to={`/freight-booking-list/${data.id}`}>
              <CgDetailsMore style={{ color: "green" }} />
            </Link>
          </>
        );
      },
    },

    { name: "id", header: "Booking Id" },
    {
      name: "createdAt",
      header: "Created",

      // width: 160,
    },
    { name: "createdBy", header: "Created By" },
    {
      name: "sugar_assigned_user_name",
      header: "Sugar Buyer",

      // width: 300,
    },
    {
      name: "sugar_bu",
      header: "Sugar BU",

      // width: 300,
    },
    { name: "status", header: "Status" },
    {
      name: "from",
      header: "From",

      width: 300,
    },
    {
      name: "to",
      header: "To",

      width: 300,
    },

    {
      name: "stockId",
      header: "Stock #",
    },
    {
      name: "originBranch",
      header: "Origin branch",

      width: 150,
    },
    {
      name: "make",
      header: "Make",
    },
    {
      name: "model",
      header: "Model",
    },
    {
      name: "distance",
      header: "Distance (km)",
    },
    {
      name: "carrier",
      header: "Carrier",
    },

    {
      name: "carrierEstimateCost",
      header: "Carrier $EST",
      render({ value }) {
        return (
          <>
            <NumberFormat
              thousandSeparator={true}
              displayType="text"
              prefix={`$ `}
              value={value}
            />
          </>
        );
      },
    },
    {
      name: "carrierEstimateCollectionDate",
      header: "ETC",
      width: 100,
    },
    {
      name: "carrierEstimateDeliveryDate",
      header: "ETD",
      width: 100,
    },
    {
      name: "carrierActualDeliveredDate",
      header: "ADD",
      width: 100,
    },
    {
      name: "carrierEclipseArrivalDate",
      header: "EAD",
      width: 100,
    },
    {
      name: "carrierDaysToDelivered",
      header: "DTD",
      width: 100,
    },
  ];

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const history = useHistory();

  let { stage } = useParams();

  const onSelectionChange = useCallback(
    ({ selected: selectedMap, data }) => {
      const selectedIds = Object.keys(selectedMap).map((d) => d);

      setSelectedRows(selectedMap);
      setSelectedRowIDs(selectedIds);
    },
    [stage]
  );

  // const onSelectionChange = ({ selected: selectedMap, data }) => {
  //   const selectedIds = Object.keys(selectedMap).map((d) => d);

  //   console.log(selectedIds);
  //   setSelectedRows(selectedMap);
  //   setSelectedRowIDs(selectedIds);
  // };

  // Bulk set stage based on selectedRowIds
  const setStageBulk = async (stageName) => {
    if (selectedRowIDs.length === 0) {
      toast.warning("No rows selected");
      return;
    }
    if (selectedRowIDs.length >= 500) {
      toast.error(
        "Too many records selected, select less records and try again"
      );
      return;
    }
    if (selectedRowIDs.length < 500) {
      setIsLoading(true);
      // Batch update stage to stagename
      // Get a new write batch
      var batch = firestore.batch();
      for (var i = 0; i < selectedRowIDs.length; i++) {
        const id = selectedRowIDs[i];
        const ref = firestore.collection("freight-bookings").doc(id);
        batch.set(
          ref,
          {
            status: stageName,
            lastUpdatedDate: dayjs().unix(),
            lastUpdatedBy: user.data.uid,
          },
          { merge: true }
        );
      }
      // Commit the batch
      await batch.commit().then(() => {
        toast.success(`Updated statuses in bulk to ${stageName}`);
        clearSelectedRows();
      });

      setIsLoading(false);
    }
  };

  const clearSelectedRows = () => {
    setSelectedRowIDs([]);
    setSelectedRows([]);
  };

  // Load dataset on mount
  useEffect(() => {
    // Filter by created origin (SugarCRM ...)
    // Clear selected rows
    clearSelectedRows();
    if (createdOriginSlice === "SugarCRM") {
      setFreightBookingList([]);
      setIsLoading(true);
      const unsubscribe = firestore
        .collection("freight-bookings")
        .where("status", "==", stage)
        .where("extraDetails.apiOrigin", "==", createdOriginSlice)
        .orderBy("createdAt", "asc")
        //   .get()
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            toast.info("No open requests available at this time");
            setIsLoading(false);
            setFreightBookingList([]);
            return;
          }
          const tmpBookings = [];

          snapshot.forEach((doc) => {
            const tmpData = doc.data();
            tmpBookings.push({ ...tmpData, id: doc.id });
            //   console.log(`Getting bookings`);
          });
          // console.log(tmpBookings);
          setFreightBookingList(tmpBookings);
          setIsLoading(false);
        });

      return () => unsubscribe;
    } else {
      setFreightBookingList([]);
      setIsLoading(true);
      if (stage === "Finalised") {
      } else {
        const unsubscribe = firestore
          .collection("freight-bookings")
          .where("status", "==", stage)
          .orderBy("createdAt", "asc")
          //   .get()
          .onSnapshot((snapshot) => {
            if (snapshot.empty) {
              toast.info("No open requests available at this time");
              setIsLoading(false);
              setFreightBookingList([]);
              return;
            }
            let tmpBookings = [];

            snapshot.forEach((doc) => {
              const data = doc.data();
              // console.log(data);
              // Flatten to help with filtering in data grid
              const createdAt = dayjs
                .unix(data?.createdAt?.seconds)
                .format("DD-MMM-YY HH:MM");
              data.carrierEstimateCost = parseFloat(
                data.carrierData?.estimatedCost
              );
              const ad = dayjs(
                data.carrierData?.actualDeliveredDate || undefined,
                "YYYY-MM-DD"
              );
              const cdu = data.createdAt?.seconds || 0;
              const cd = dayjs.unix(cdu);

              let dtd = ad.diff(cd, "day");
              if (isNaN(dtd)) {
                dtd = "N/A";
              }
              const carrierDaysToDelivered = dtd;
              const carrierEstimateCollectionDate =
                data.carrierData?.estimatedCollectionDate;
              const carrierEstimateDeliveryDate =
                data.carrierData?.estimatedDeliveryDate;

              const tmpData = {
                createdAt: createdAt,
                createdBy: data.displayName,
                status: data.status,
                from: data.pickup?.location?.value || null,
                to: data.dropOff?.location?.value || null,
                distance: data.directions[0]?.distance || null,
                pickupLatitude: data.pickup?.location?.coordinates?.lat || null,
                pickupLongitude:
                  data.pickup?.location?.coordinates?.lng || null,
                dropoffLatitude:
                  data.dropOff?.location?.coordinates?.lat || null,
                dropoffLongitude:
                  data.dropOff?.location?.coordinates?.lng || null,
                stockId: parseInt(data.vehicleItem[0]?.EclipseStockNumber || 0),

                make: data.vehicleItem[0]?.make || null,
                model: data.vehicleItem[0]?.model || null,
                year: data.vehicleItem[0]?.year || null,
                colour: data.vehicleItem[0]?.colour || null,
                variant: data.vehicleItem[0]?.variant || null,
                VIN: data.vehicleItem[0]?.VIN || null,
                originBranch: data.vehicleItem[0]?.branch || null,
                carrier: data.carrierData?.carrier?.carrier || null,
                carrierEstimateCost: data.carrierEstimateCost || null,
                carrierEstimateCollectionDate,
                carrierEstimateDeliveryDate,
                carrierActualDeliveredDate:
                  data.carrierData?.actualDeliveredDate,
                carrierEclipseArrivalDate: data.carrierData?.eclipseArrivalDate,
                carrierDaysToDelivered,
                carrierBookingReferenceId:
                  data.carrierData?.carrierBookingReference || null,
                userId: data.user,
                sugar_assigned_user_name:
                  data.extraDetails?.sugarCrmLeadData?.assigned_user_name,
                sugar_bu: data.extraDetails?.sugarCrmLeadData?.business_unit_c,
              };
              tmpBookings.push({ ...tmpData, id: doc.id });
              //   console.log(`Getting bookings`);
            });

            // TEMPORARY 02082022 IF Sam Turton or Dimi is logged in, then show them bookings
            // creatd by them only
            // Furthermore, their premission include freight_cru so they can edit bookings

            const uid_sam_turton = "UpuMLllaNaQkecCKrnVSQA1lhZN2";
            const uid_dimi = "tcm3vP1cK7Zb8Xy5EZOyPxqqMvs1";

            let filteredBookings = [];
            // console.log(user.data.uid);

            if ([uid_sam_turton, uid_dimi].includes(user.data.uid)) {
              filteredBookings = tmpBookings.filter(
                (b) => b.userId === user.data.uid
              );
            } else {
              filteredBookings = [...tmpBookings];
            }

            // console.log(tmpBookings);
            // console.log(filteredBookings);
            setFreightBookingList(filteredBookings);
            setIsLoading(false);
          });

        return () => unsubscribe;
      }
    }
  }, [stage, createdOriginSlice]);

  useEffect(() => {
    setRows(freightBookingList);
  }, [freightBookingList, stage]);
  return (
    <>
      <BackdropComponent loading={isLoading} />
      {/* <DataGrid
        defaultColumnOptions={{ resizable: true, sortable: true }}
        columns={tableColumns}
        style={style.dataGridFill}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        rows={sortedRows}
        onSort={handleSort}
      /> */}
      <p style={{ textAlign: "left" }}>{`Selected ${selectedRowIDs.length} row${
        selectedRowIDs.length === 1 ? "" : "s"
      }`}</p>
      <Dropdown as={ButtonGroup} style={{ marginBottom: 10 }}>
        <Button
          size="sm"
          variant={
            selectedRowIDs.length === 0
              ? "outline-secondary"
              : "outline-success"
          }
        >
          Change status{" "}
        </Button>

        <Dropdown.Toggle
          disabled={selectedRowIDs.length === 0}
          split
          variant={selectedRowIDs.length === 0 ? "secondary" : "success"}
          id="dropdown-split-basic"
        />
        <Dropdown.Menu>
          {statusList.map((s, idx) => {
            if (s.name === stage) {
              return null;
            }
            return (
              <Dropdown.Item
                key={idx}
                onClick={() => {
                  setStageBulk(s.name);
                }}
              >
                {s.title}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
      <ReactDataGrid
        key={`data-grid-${stage}`}
        idProperty="id"
        // idProperty={`data-grid-main-${stage}`}
        style={gridStyle}
        // activateRowOnFocus={activateRowOnFocus}
        pagination={true}
        defaultFilterValue={filterValue}
        // filterValue={filterValue}
        columns={columns}
        dataSource={rows}
        // onFilterValueChange={onFilterValueChange}
        checkboxColumn={checkboxColumn}
        checkboxOnlyRowSelect={true}
        enableSelection
        multiSelect
        onSelectionChange={onSelectionChange}
      />
    </>
  );
}

export default FreightListFilteredTableViewNew;
