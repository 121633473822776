import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";

import caricon from "../assets/images/carlogo.svg";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useRouteMatch,
} from "react-router-dom";
import InStockSummaryComponent from "./InStockSummaryComponent";
import { useAuth, useUser } from "reactfire";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import OffcanvasHeader from "react-bootstrap/OffcanvasHeader";
// import OffcanvasTitle from "react-bootstrap/OffcanvasTitle";
// import OffcanvasBody from "react-bootstrap/OffcanvasBody";
import InsightsComponent from "./Insights/InsightsComponent";
import RecentlySoldComponent from "./RecentySoldComponent";
import VehicleSearchComponent from "./VehicleSearchComponent";
import FreightBookingForm from "./FreightBooking/FreightBookingForm";
import FreightBookingList from "./FreightBooking/FreightBookingList";
import NotPublishedIndex from "./NotPublished/NotPublishedIndex";
import NoAuth from "./NoAuth";
import { AuthCheckCustom } from "./Settings/AuthCheckCustom";
import UpdateUserComponent from "./Settings/UpdateUserComponent";
import MyFreightBookingList from "./FreightBooking/MyFreightBookingList";
import FreightBookingItem from "./FreightBooking/FreightBookingItem";
import ListCarriers from "./FreightBooking/Options/ListCarriers";
import ListMyAddressBook from "./FreightBooking/Options/ListMyAddressBook";
import { BsList } from "react-icons/bs";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useEffect, useState } from "react";
import Start from "./Start";
import useComponentVisible from "../hooks/useComponentVisible";
import MyConciergeRequests from "./RetailConcierge/MyConciergeRequests";
import FreightBookingListWholesale from "./FreightBooking/FreightBookingListWholesale";
import InventoryHealth from "./Insights/InventoryHealth";
import WallboardWholesale from "./FreightBooking/WallboardWholesale";
import FreightBookingListWithRouting from "./FreightBooking/FreightBookingListWithRouting";
import FreightListFilteredTableView from "./FreightBooking/FreightListFilteredTableView";
import VINDecodeComponent from "./VINDecodeComponent";
import WallboardDGBuying from "./FreightBooking/WallboardDGBuying";
import WallboardWholesaleNew from "./FreightBooking/WallboardWholesaleNew";
import WebsiteInventoryRepair from "./WebsiteInventoryRepair";
import WallboardDGBuyingVehicleFlow from "./FreightBooking/WallboardDGBuyingVehicleFlow";
import PricingApprovalComponent from "./Pricing/PricingApprovalComponent";
import FreightListFilteredTableViewNew from "./FreightBooking/FreightListFilteredTableViewNew";
import FreightDashboardSummary from "./FreightBooking/Reports/FreightDashboardSummary";
import InsightsAMWComponent from "./Insights/InsightsAMWComponent";
import PaymentRequestDeposit from "./PaymentRequestDeposit";
import ReconStockLocationAMW from "./Insights/ReconStockLocationAMW";
import InventoryHealthDuttonOne from "./Insights/InventoryHealthDuttonOne";
import StockCardComoponent from "./StockCardComponent";
import StockCardByVinComponent from "./StockCardByVinComponent";
import CompetitiveSet from "./Insights/CompetitiveSet";
import DGBuyingBoard from "./Buying/DGBuyingBoard";
import ViewUsersComponent from "./Settings/ViewUsersComponent";
import DuttonOneSoldCalendarComponent from "./Insights/DuttonOneSoldCalendarComponent";
import CollateralReportsComponent from "./Finance/CBA/CollateralReportsComponent";
import RetailSalesKPIComponent from "./Insights/RetailSalesKPIComponent";
import InventoryHealthDuttonOneQLD from "./Insights/InventoryHealthDuttonOneQLD";
import InventoryHealthDuttonOneNSW from "./Insights/InventoryHealthDuttonOneNSW";
import RetailSalesKPID1VICComponent from "./Insights/RetailSalesKPID1VICComponent";
import AutogateListingsComponent from "./Insights/AutogateListingsComponent";
import BuyingDataComponent from "./Insights/BuyingDataComponent";
import AccountsPayabalInvoiceAIComponent from "./Finance/Accounts/AccountsPayableInvoiceAIComponent";
import DABuyingBoard from "./Buying/DABuyingBoard";
import ReconStockLocationD1QLD from "./Insights/ReconStockLocationD1QLD";
import InventoryManagementComponent from "./Insights/InventoryManagementComponent";
import XeroDataEntryComponent from "./Finance/Accounts/XeroDataEntryComponent";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import SugarLeadGenerationComponent from "./Buying/SugarLeadGenerationComponent";
import InternalTenders from "./Auctions/InternalTenders";
import InternalTendersVehicleBidder from "./Auctions/InternalTendersVehicleBidder";
import InternalTendersVehicleSeller from "./Auctions/InternalTendersVehicleSeller";

// import UploadVideoForm from "./VideoPresentation/UploadVideoForm";
// import VideoEditor from "./VideoPresentation/VideoEditor";
// eslint-disable-next-line no-restricted-globals

const RouterComponent = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isDev, setIsDev] = useState(false);
  const [showNewView, setShowNewView] = useState(true);

  const auth = useAuth();
  const firestore = getFirestore();
  const functions = getFunctions();
  const { data } = useUser();
  // const analytics = useAnalytics();
  const { ref, clickedInside, setIsClickedInside } = useComponentVisible(true);

  const drawerClosedStyle = {
    zIndex: 200,
    position: "fixed",
    left: 0,
    top: 0,
    paddingTop: 50,
    paddingLeft: 10,
    display: "block",
    flexWrap: "wrap",
    textAlign: "left",
    justifyContent: "space-between",
    transform: "translateX(-100%)",
    height: "100%",
    width: 220,
    transition: "transform 0.3s ease-out",
    background: "white",
  };

  const drawerOpenStyle = {
    zIndex: 200,
    position: "fixed",
    left: 0,
    top: 0,
    paddingTop: 50,
    paddingLeft: 10,
    display: "block",
    flexWrap: "wrap",
    textAlign: "left",
    justifyContent: "space-between",
    transform: "translateX(0)",
    width: 220,
    height: "100%",
    transition: "transform 0.3s ease-out",
    background: "white",
    boxShadow: "1px 0px 7px rgba(0, 0, 0, 0.5)",
  };

  const menuButtonPosition = {
    top: 0,
    left: 0,
    position: "fixed",
    zIndex: 10000,
    margin: 5,
    marginTop: 15,
  };

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (location.hostname === "localhost") {
      setIsDev(true);
      // Database
      // connectFirestoreEmulator(firestore, "localhost", 8080);
      // Functions
      // functions.useEmulator("localhost", 5000);
      // connectFunctionsEmulator(functions, "localhost", 5001);
      // Authentication
      // auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
    }
  }, []);

  useEffect(() => {
    if (clickedInside === false) {
      setDrawerOpen(false);
    }
  }, [clickedInside]);

  useEffect(() => {
    if (drawerOpen) {
      setIsClickedInside(true);
    }
  });

  return (
    <>
      <Router>
        <Button
          style={menuButtonPosition}
          variant={drawerOpen ? "dark" : "outline-secondary"}
          size="md"
          onClick={(e) => {
            e.preventDefault();
            setDrawerOpen(!drawerOpen);
          }}
        >
          <BsList />
        </Button>
        <Navbar
          collapseOnSelect
          expand="md"
          bg="light"
          variant="light"
          className="justify-content-between"
          style={{ fontSize: "0.8rem" }}
          // sticky="top"
        >
          <Navbar.Brand>
            <Nav className="justify-content-end">
              <Nav.Item>
                <Nav.Link as={NavLink} to="/" style={{ marginLeft: "25%" }}>
                  Dutton Data Portal {isDev ? "**DEV**" : null}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Brand>
          <Nav
            style={drawerOpen ? drawerOpenStyle : drawerClosedStyle}
            sticky="top"
            className="flex-column"
            ref={ref}
          >
            {/* <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/insights">
                Insights
              </Nav.Link>
            </AuthCheckCustom> */}
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/autogate-listings">
                AG Listings
              </Nav.Link>
            </AuthCheckCustom>

            <AuthCheckCustom
              requiredClaims={{
                su: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/sugar-lead-gen/new">
                Sugar Lead Gen
              </Nav.Link>
            </AuthCheckCustom>
            <AuthCheckCustom
              requiredClaims={{
                insights_amw: true,
              }}
              fallback={null}
            >
              <NavDropdown
                title="AMW Insights"
                id="amw-retail-insights-dropdown"
              >
                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/insights-amw">
                    Insights - AMW
                  </Nav.Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/inventory-health">
                    Inventory health
                  </Nav.Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/recon-stock-movements">
                    Recon stock location
                  </Nav.Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/retail-sales-kpi-amw">
                    Sales KPI - AMW
                  </Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>
            </AuthCheckCustom>

            <AuthCheckCustom
              requiredClaims={{
                buying_view: true,
              }}
              fallback={null}
            >
              <NavDropdown title="Buying" id="buying-dropdown">
                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/buying/dg-buying-board">
                    DG Buying Board
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/buying/da-buying-board">
                    DA Buying Board
                  </Nav.Link>
                </NavDropdown.Item>

                {/* <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/inventory-health">
                    Inventory health
                  </Nav.Link>
                </NavDropdown.Item> */}
              </NavDropdown>
            </AuthCheckCustom>

            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={null}
            >
              <NavDropdown
                title="Dutton One Insights"
                id="duttonone-retail-insights-dropdown"
              >
                {/* <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/insights-duttone">
                    Insights - Dutton One
                  </Nav.Link>
                </NavDropdown.Item> */}
                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/inventory-management-national">
                    Inventory Management - National
                  </Nav.Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/inventory-health-duttonone">
                    Inventory health VIC
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/sales-kpi-duttonone-vic">
                    Sales KPI VIC
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/inventory-health-duttonone-qld">
                    Inventory health QLD
                  </Nav.Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/recon-stock-movements-duttonone-qld"
                  >
                    Recon lanes QLD
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/inventory-health-duttonone-nsw">
                    Inventory health NSW
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/sold-calendar-duttonone">
                    Sold and Delivered Calendar - VIC
                  </Nav.Link>
                </NavDropdown.Item>

                {/* <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/recon-stock-movements-duttonone">
                    Recon stock location
                  </Nav.Link>
                </NavDropdown.Item> */}
              </NavDropdown>
            </AuthCheckCustom>

            <AuthCheckCustom
              requiredClaims={{
                labs_lk_data_entry: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/buying/buying-data-entry">
                Data Entry
              </Nav.Link>
            </AuthCheckCustom>

            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/in-stock">
                In Stock Summary
              </Nav.Link>
            </AuthCheckCustom>
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/recently-sold">
                Recently Sold
              </Nav.Link>
            </AuthCheckCustom>
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/not-published">
                Not Published
              </Nav.Link>
            </AuthCheckCustom>

            <AuthCheckCustom
              requiredClaims={{
                retail_concierge_c: true,
              }}
              fallback={null}
            >
              <NavDropdown
                title="Retail Conceirge"
                id="retail-concierge-dropdown"
              >
                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/retail-concierge/my-requests">
                    My Requests
                  </Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>
            </AuthCheckCustom>
            <AuthCheckCustom
              requiredClaims={{
                payment_request_deposit: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/deposit-payment-request">
                Payment request - Deposit
              </Nav.Link>
            </AuthCheckCustom>
            <AuthCheckCustom
              requiredClaims={{
                vinsearch_r: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/vin-rego-search">
                VIN Search
              </Nav.Link>
            </AuthCheckCustom>

            <AuthCheckCustom
              requiredClaims={{
                decodeVIN: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/vin-decode">
                VIN Decode
              </Nav.Link>
            </AuthCheckCustom>
            <AuthCheckCustom fallback={null}>
              <Nav.Link as={NavLink} to="/website-inventory-repair">
                Website Inventory Repair
              </Nav.Link>
            </AuthCheckCustom>

            <AuthCheckCustom
              requiredClaims={{
                video_c: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/video-presentation/upload-form">
                Upload Video
              </Nav.Link>
            </AuthCheckCustom>

            <AuthCheckCustom
              requiredClaims={{
                freight_c: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/freight-booking-form">
                New Freight Booking
              </Nav.Link>
            </AuthCheckCustom>
            <AuthCheckCustom
              requiredClaims={{
                freight_report: true,
              }}
              fallback={null}
            >
              <NavDropdown
                title="Freight Wallboards"
                id="freight-bookings-wallboards"
              >
                <Nav.Link
                  as={NavLink}
                  to="/freight-booking/wholesale-wallboard-arrivals"
                >
                  Wholesale Arrivals
                </Nav.Link>

                <Nav.Link
                  as={NavLink}
                  to="/freight-booking/wholesale-wallboard-new"
                >
                  Wholesale Arrivals (New)
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  to="/freight-booking/dg-buying-wallboard"
                >
                  DG Buying
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  to="/freight-booking/dg-buying-wallboard-vehicle-flow"
                >
                  DG Buying - Vehicle Flow
                </Nav.Link>
              </NavDropdown>
            </AuthCheckCustom>
            <AuthCheckCustom
              requiredClaims={{
                freight_list_ro: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/freight-booking-list-wholesale">
                Freight Bookings - Wholesale
              </Nav.Link>
            </AuthCheckCustom>
            <AuthCheckCustom
              requiredClaims={{
                freight_cru: true,
              }}
              fallback={null}
            >
              <NavDropdown
                title="Freight Bookings"
                id="freight-bookings-dropdown"
              >
                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/freight-booking-list">
                    List
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/freight-booking-list-wholesale">
                    Wholesale List
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/freight-booking-options/list-carriers"
                  >
                    Carriers
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/freight-address-book">
                    Freight Address Book
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/reports/freight-user-activity">
                    Reports - User activity
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/reports/freight-dashboard-summary"
                  >
                    Reports - Freight dashboard
                  </Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>
            </AuthCheckCustom>
            <AuthCheckCustom
              requiredClaims={{
                freight_c: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/my-freight-booking-list">
                My Freight Bookings
              </Nav.Link>
            </AuthCheckCustom>

            {/* <AuthCheckCustom
              requiredClaims={{
                freight_c: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/pricing/pricing-approval">
                Pricing Approval
              </Nav.Link>
            </AuthCheckCustom> */}
            <AuthCheckCustom
              requiredClaims={{
                labs_lk_data_entry: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/finance/accounts/apai">
                Accounts Payable Invoicing AI
              </Nav.Link>
            </AuthCheckCustom>

            <AuthCheckCustom
              requiredClaims={{
                finance_secure: true,
              }}
              fallback={null}
            >
              <NavDropdown title="Finance" id="finance-dropdown">
                <NavDropdown.Item>
                  <Nav.Link as={NavLink} to="/finance/cba-collateral-reports">
                    CBA Collateral Reports
                  </Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>
            </AuthCheckCustom>

            <AuthCheckCustom
              requiredClaims={{
                su: true,
              }}
              fallback={null}
            >
              <Nav.Link as={NavLink} to="/settings">
                Settings
              </Nav.Link>
            </AuthCheckCustom>
            <Nav.Link
              onClick={() => {
                auth.signOut();
              }}
            >
              Logout
            </Nav.Link>
          </Nav>
        </Navbar>

        <Switch>
          <Route exact path="/">
            {/* <h3>VIN / Rego Search</h3> */}
            {/* <VehicleComponent /> */}
            {/* <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={<NoAuth />}
            > */}
            <Breadcrumb>
              <Breadcrumb.Item active>Home</Breadcrumb.Item>
            </Breadcrumb>

            <Start />
            {/* </AuthCheckCustom> */}
          </Route>
          <Route exact path="/insights">
            {/* <h3>VIN / Rego Search</h3> */}
            {/* <VehicleComponent /> */}
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Insights</Breadcrumb.Item>
              </Breadcrumb>

              <InsightsComponent />
            </AuthCheckCustom>
          </Route>
          <Route exact path="/insights-amw">
            {/* <h3>VIN / Rego Search</h3> */}
            {/* <VehicleComponent /> */}
            <AuthCheckCustom
              requiredClaims={{
                insights_amw: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Insights / AMW</Breadcrumb.Item>
              </Breadcrumb>

              <InsightsAMWComponent />
            </AuthCheckCustom>
          </Route>

          <Route exact path="/retail-sales-kpi-amw">
            <AuthCheckCustom
              requiredClaims={{
                insights_amw: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Retail Sales KPI / AMW</Breadcrumb.Item>
              </Breadcrumb>

              <RetailSalesKPIComponent brand="AMW" />
            </AuthCheckCustom>
          </Route>

          <Route exact path="/recon-stock-movements">
            {/* <h3>VIN / Rego Search</h3> */}
            {/* <VehicleComponent /> */}
            <AuthCheckCustom
              requiredClaims={{
                insights_amw: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>
                  Insights / Recon Stock Location
                </Breadcrumb.Item>
              </Breadcrumb>

              <ReconStockLocationAMW />
            </AuthCheckCustom>
          </Route>

          <Route exact path="/recon-stock-movements-duttonone-qld">
            {/* <h3>VIN / Rego Search</h3> */}
            {/* <VehicleComponent /> */}
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>
                  Insights / Recon Stock Location D1 QLD
                </Breadcrumb.Item>
              </Breadcrumb>

              <ReconStockLocationD1QLD />
            </AuthCheckCustom>
          </Route>

          <Route path="/in-stock">
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>In Stock Summary</Breadcrumb.Item>
              </Breadcrumb>
              <InStockSummaryComponent />
            </AuthCheckCustom>
          </Route>

          <Route path="/autogate-listings">
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Autogate Listings</Breadcrumb.Item>
              </Breadcrumb>
              <AutogateListingsComponent />
            </AuthCheckCustom>
          </Route>

          <Route path="/inventory-health">
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Inventory health</Breadcrumb.Item>
              </Breadcrumb>
              <InventoryHealth />
            </AuthCheckCustom>
          </Route>

          <Route path="/inventory-management-national">
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>
                  Inventory management - National
                </Breadcrumb.Item>
              </Breadcrumb>
              <InventoryManagementComponent />
            </AuthCheckCustom>
          </Route>

          <Route exact path="/insights/competitiveset">
            <AuthCheckCustom
              requiredClaims={{
                labs_lk_data_entry: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Competitive Set</Breadcrumb.Item>
              </Breadcrumb>
              <CompetitiveSet />
            </AuthCheckCustom>
          </Route>

          <Route path="/inventory-health-duttonone">
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>
                  Inventory health Dutton One - VIC
                </Breadcrumb.Item>
              </Breadcrumb>
              <InventoryHealthDuttonOne />
            </AuthCheckCustom>
          </Route>

          <Route path="/sales-kpi-duttonone-vic">
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>
                  Sales KPI Dutton One - VIC
                </Breadcrumb.Item>
              </Breadcrumb>
              <RetailSalesKPID1VICComponent brand="D1VIC" />
            </AuthCheckCustom>
          </Route>

          <Route path="/inventory-health-duttonone-qld">
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>
                  Inventory health Dutton One - NSW
                </Breadcrumb.Item>
              </Breadcrumb>
              <InventoryHealthDuttonOneQLD />
            </AuthCheckCustom>
          </Route>
          <Route path="/inventory-health-duttonone-nsw">
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>
                  Inventory health Dutton One - NSW
                </Breadcrumb.Item>
              </Breadcrumb>
              <InventoryHealthDuttonOneNSW />
            </AuthCheckCustom>
          </Route>

          <Route path="/sold-calendar-duttonone">
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>
                  Sold and Delivered Calendar - VIC
                </Breadcrumb.Item>
              </Breadcrumb>
              <DuttonOneSoldCalendarComponent />
            </AuthCheckCustom>
          </Route>

          <Route path="/recently-sold">
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Recently Sold</Breadcrumb.Item>
              </Breadcrumb>
              <RecentlySoldComponent />
            </AuthCheckCustom>
          </Route>

          <Route path="/retail-concierge/my-requests">
            <AuthCheckCustom
              requiredClaims={{
                retail_concierge_c: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Retail Conceirge</Breadcrumb.Item>
                <Breadcrumb.Item active>My Requests</Breadcrumb.Item>
              </Breadcrumb>
              <MyConciergeRequests />
            </AuthCheckCustom>
          </Route>

          <Route path="/buying/dg-buying-board">
            <AuthCheckCustom
              requiredClaims={{
                buying_view: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Buying</Breadcrumb.Item>
                <Breadcrumb.Item active>DG Buying Board</Breadcrumb.Item>
              </Breadcrumb>
              <DGBuyingBoard />
            </AuthCheckCustom>
          </Route>

          <Route path="/buying/da-buying-board">
            <AuthCheckCustom
              requiredClaims={{
                buying_view: true,
              }}
              fallback={<NoAuth />}
            >
              {/* <Breadcrumb>
                <Breadcrumb.Item active>Buying</Breadcrumb.Item>
                <Breadcrumb.Item active>DA Buying Board</Breadcrumb.Item>
              </Breadcrumb> */}
              <DABuyingBoard />
            </AuthCheckCustom>
          </Route>

          <Route path="/buying/buying-data-entry">
            <AuthCheckCustom
              requiredClaims={{
                labs_lk_data_entry: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Buying</Breadcrumb.Item>
                <Breadcrumb.Item active>Data entry</Breadcrumb.Item>
              </Breadcrumb>
              <BuyingDataComponent />
            </AuthCheckCustom>
          </Route>

          <Route path="/deposit-payment-request">
            <AuthCheckCustom
              requiredClaims={{
                payment_request_deposit: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>
                  Payment request / Deposit
                </Breadcrumb.Item>
              </Breadcrumb>
              <PaymentRequestDeposit />
            </AuthCheckCustom>
          </Route>

          <Route path="/vin-decode">
            <AuthCheckCustom
              requiredClaims={{
                decodeVIN: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>VIN Decode</Breadcrumb.Item>
              </Breadcrumb>
              <VINDecodeComponent />
            </AuthCheckCustom>
          </Route>

          <Route path="/vin-rego-search">
            <AuthCheckCustom
              requiredClaims={{
                vinsearch_r: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>
                  VIN and Stock ID Search
                </Breadcrumb.Item>
              </Breadcrumb>
              <VehicleSearchComponent />
            </AuthCheckCustom>
          </Route>
          <Route path="/video-presentation/upload-form">
            <AuthCheckCustom
              requiredClaims={{
                video_c: true,
              }}
              fallback={<NoAuth />}
            >
              {/* <UploadVideoForm /> */}
            </AuthCheckCustom>
          </Route>

          <Route path="/reconditioning/yardlocation-changer">
            <AuthCheckCustom
              requiredClaims={{
                insights_amw: true,
              }}
              fallback={<NoAuth />}
            >
              {/* <YardLocationChange /> */}
            </AuthCheckCustom>
          </Route>

          <Route exact path="/video-presentation/editor">
            <AuthCheckCustom
              requiredClaims={{
                video_c: true,
              }}
              fallback={<NoAuth />}
            >
              {/* <VideoEditor /> */}
            </AuthCheckCustom>
          </Route>

          <Route path="/freight-booking-form">
            <AuthCheckCustom
              requiredClaims={{
                freight_c: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Freight Booking Form</Breadcrumb.Item>
              </Breadcrumb>
              <FreightBookingForm />
            </AuthCheckCustom>
          </Route>

          <Route path="/freight-booking/wholesale-wallboard-new">
            <AuthCheckCustom
              requiredClaims={{
                freight_report: true,
              }}
              fallback={<NoAuth />}
            >
              <WallboardWholesaleNew type="arrivals" />
            </AuthCheckCustom>
          </Route>

          <Route path="/freight-booking/wholesale-wallboard-arrivals">
            <AuthCheckCustom
              requiredClaims={{
                freight_report: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Freight Bookings</Breadcrumb.Item>
                <Breadcrumb.Item active>Wallboards</Breadcrumb.Item>
                <Breadcrumb.Item active>Wholesale - Arrivals</Breadcrumb.Item>
              </Breadcrumb>
              <WallboardWholesale type="arrivals" />
            </AuthCheckCustom>
          </Route>

          <Route path="/freight-booking/dg-buying-wallboard">
            <AuthCheckCustom
              requiredClaims={{
                freight_report: true,
              }}
              fallback={<NoAuth />}
            >
              <WallboardDGBuying buyer="all" />
            </AuthCheckCustom>
          </Route>

          <Route path="/freight-booking/dg-buying-wallboard-vehicle-flow">
            <AuthCheckCustom
              requiredClaims={{
                freight_report: true,
              }}
              fallback={<NoAuth />}
            >
              <WallboardDGBuyingVehicleFlow buyer="all" />
            </AuthCheckCustom>
          </Route>

          <Route path="/freight-booking/list/:stage">
            <AuthCheckCustom
              requiredClaims={{
                freight_cru: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>
                  Freight Booking List - Filtered
                </Breadcrumb.Item>
              </Breadcrumb>
              <Button
                onClick={() => {
                  setShowNewView(!showNewView);
                }}
                variant={showNewView ? "warning" : "success"}
              >
                {showNewView ? "Switch back to old view" : "Show new view"}
              </Button>
              <FreightBookingListWithRouting />
              {showNewView ? (
                <FreightListFilteredTableViewNew />
              ) : (
                <FreightListFilteredTableView />
              )}
            </AuthCheckCustom>
          </Route>
          <Route exact path="/freight-booking/list">
            <AuthCheckCustom
              requiredClaims={{
                freight_cru: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Freight Booking List</Breadcrumb.Item>
              </Breadcrumb>
              <FreightBookingListWithRouting />
            </AuthCheckCustom>
          </Route>
          <Route exact path="/freight-booking-list">
            <AuthCheckCustom
              requiredClaims={{
                freight_cru: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Freight Booking List</Breadcrumb.Item>
              </Breadcrumb>
              {/* <FreightBookingList /> */}

              <FreightBookingListWithRouting />
              <h4>Please choose a status</h4>
            </AuthCheckCustom>
          </Route>
          <Route exact path="/freight-booking-list-wholesale">
            <AuthCheckCustom
              requiredClaims={{
                freight_list_ro: true,
              }}
              fallback={
                <AuthCheckCustom
                  requiredClaims={{
                    freight_cru: true,
                  }}
                  fallback={<NoAuth />}
                >
                  <Breadcrumb>
                    <Breadcrumb.Item active>
                      Freight Booking List - Wholesale
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <FreightBookingListWholesale />
                </AuthCheckCustom>
              }
            >
              <Breadcrumb>
                <Breadcrumb.Item active>
                  Freight Booking List - Wholesale
                </Breadcrumb.Item>
              </Breadcrumb>
              <FreightBookingListWholesale />
            </AuthCheckCustom>
          </Route>
          <Route
            path="/freight-booking-list/:bookingId"
            component={FreightBookingItem}
          />
          <Route path="/my-freight-booking-list">
            <AuthCheckCustom
              requiredClaims={{
                freight_c: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>
                  My Freight Booking List
                </Breadcrumb.Item>
              </Breadcrumb>
              <MyFreightBookingList />
            </AuthCheckCustom>
          </Route>

          <Route path="/freight-address-book">
            <AuthCheckCustom
              requiredClaims={{
                freight_cru: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Freight Addres Book</Breadcrumb.Item>
              </Breadcrumb>
              <ListMyAddressBook />
            </AuthCheckCustom>
          </Route>

          <Route exact path="/freight-booking-options/list-carriers">
            <AuthCheckCustom
              requiredClaims={{
                freight_cru: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>List Carriers</Breadcrumb.Item>
              </Breadcrumb>
              <ListCarriers />
            </AuthCheckCustom>
          </Route>

          <Route exact path="/reports/freight-user-activity">
            <AuthCheckCustom
              requiredClaims={{
                freight_cru: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Reports</Breadcrumb.Item>
                <Breadcrumb.Item active>Freight</Breadcrumb.Item>
                <Breadcrumb.Item active>User activity</Breadcrumb.Item>
              </Breadcrumb>
              {/* <UserActivity /> */}
            </AuthCheckCustom>
          </Route>

          <Route exact path="/reports/freight-dashboard-summary">
            <AuthCheckCustom
              requiredClaims={{
                freight_cru: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Reports</Breadcrumb.Item>
                <Breadcrumb.Item active>Freight</Breadcrumb.Item>
                <Breadcrumb.Item active>Dashboard summary</Breadcrumb.Item>
              </Breadcrumb>
              <FreightDashboardSummary />
            </AuthCheckCustom>
          </Route>

          <Route path="/pricing/pricing-approval">
            <AuthCheckCustom
              requiredClaims={{
                freight_c: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Pricing Approval</Breadcrumb.Item>
              </Breadcrumb>
              <PricingApprovalComponent />
            </AuthCheckCustom>
          </Route>

          <Route path="/not-published">
            <AuthCheckCustom
              requiredClaims={{
                insights: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Not Published</Breadcrumb.Item>
              </Breadcrumb>
              <NotPublishedIndex />
            </AuthCheckCustom>
          </Route>

          <Route path="/settings">
            <AuthCheckCustom
              requiredClaims={
                {
                  // su: true,
                }
              }
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>User Permissions</Breadcrumb.Item>
              </Breadcrumb>
              <UpdateUserComponent />
            </AuthCheckCustom>
          </Route>

          <Route path="/finance/cba-collateral-reports">
            <AuthCheckCustom
              requiredClaims={{
                finance_secure: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>CBA Collateral Reports</Breadcrumb.Item>
              </Breadcrumb>
              <CollateralReportsComponent />
            </AuthCheckCustom>
          </Route>

          <Route path="/finance/accounts/apai">
            <AuthCheckCustom
              requiredClaims={{
                labs_lk_data_entry: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>
                  Accounts Payable Invoicing AI
                </Breadcrumb.Item>
              </Breadcrumb>
              <XeroDataEntryComponent />
              {/* <AccountsPayabalInvoiceAIComponent /> */}
            </AuthCheckCustom>
          </Route>

          <Route
            exact
            path="/viewUsers/:userid"
            render={(props) => (
              <AuthCheckCustom
                requiredClaims={
                  {
                    // su: true,
                  }
                }
                fallback={<NoAuth />}
              >
                <Breadcrumb>
                  <Breadcrumb.Item active>
                    Stock Card - Search by Stock #
                  </Breadcrumb.Item>
                </Breadcrumb>
                <ViewUsersComponent {...props} />
              </AuthCheckCustom>
            )}
          />

          <Route
            exact
            path="/stock/:stocknumber"
            render={(props) => (
              <AuthCheckCustom
                requiredClaims={{
                  vinsearch_r: true,
                }}
                fallback={<NoAuth />}
              >
                <Breadcrumb>
                  <Breadcrumb.Item active>
                    Stock Card - Search by Stock #
                  </Breadcrumb.Item>
                </Breadcrumb>
                <StockCardComoponent {...props} />
              </AuthCheckCustom>
            )}
          />
          <Route
            exact
            path="/vin/:vin"
            render={(props) => (
              <AuthCheckCustom
                requiredClaims={{
                  vinsearch_r: true,
                }}
                fallback={<NoAuth />}
              >
                <Breadcrumb>
                  <Breadcrumb.Item active>
                    Stock Card - Search by VIN
                  </Breadcrumb.Item>
                </Breadcrumb>
                <StockCardByVinComponent {...props} />
              </AuthCheckCustom>
            )}
          />

          <Route path="/website-inventory-repair">
            <AuthCheckCustom fallback={<NoAuth />}>
              <WebsiteInventoryRepair />
            </AuthCheckCustom>
          </Route>

          <Route path="/sugar-lead-gen/new">
            <AuthCheckCustom
              requiredClaims={{
                su: true,
              }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item active>Sugar lead generation</Breadcrumb.Item>
              </Breadcrumb>
              <SugarLeadGenerationComponent />
            </AuthCheckCustom>
          </Route>

          <Route exact path="/auctions/internal-tenders">
            <AuthCheckCustom
              // requiredClaims={{
              //   su: true,
              //   labs_lk_data_entry: true,
              // }}
              fallback={<NoAuth />}
            >
              <Breadcrumb>
                <Breadcrumb.Item>Auctions</Breadcrumb.Item>
                <Breadcrumb.Item active>Internal Tenders</Breadcrumb.Item>
              </Breadcrumb>
              <InternalTenders />
            </AuthCheckCustom>
          </Route>

          <Route
            exact
            path="/auctions/internal-tenders/bidder/:auctionId/:vehicleId"
            render={(props) => (
              <AuthCheckCustom
                // requiredClaims={{
                //   su: true,
                //   labs_lk_data_entry: true,
                // }}
                fallback={<NoAuth />}
              >
                <Breadcrumb>
                  <Breadcrumb.Item>Auctions</Breadcrumb.Item>
                  <Breadcrumb.Item>Internal Tenders</Breadcrumb.Item>
                  <Breadcrumb.Item active>Vehicle - Bidder</Breadcrumb.Item>
                </Breadcrumb>
                <InternalTendersVehicleBidder />
              </AuthCheckCustom>
            )}
          />

          <Route
            exact
            path="/auctions/internal-tenders/seller/:auctionId/:vehicleId"
            render={(props) => (
              <AuthCheckCustom
                // requiredClaims={{
                //   su: true,
                //   labs_lk_data_entry: true,
                // }}
                fallback={<NoAuth />}
              >
                <Breadcrumb>
                  <Breadcrumb.Item>Auctions</Breadcrumb.Item>
                  <Breadcrumb.Item>Internal Tenders</Breadcrumb.Item>
                  <Breadcrumb.Item active>Vehicle - Seller</Breadcrumb.Item>
                </Breadcrumb>
                <InternalTendersVehicleSeller />
              </AuthCheckCustom>
            )}
          />
        </Switch>
        <p style={{ textAlign: "right", fontSize: 12, marginRight: 5 }}>
          Signed in as {data?.displayName}
        </p>
      </Router>
    </>
  );
};

export default RouterComponent;
