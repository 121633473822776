import { useEffect, useState } from "react";
import { useUser } from "reactfire";
import { ToastContainer, toast } from "react-toastify";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import * as Yup from "yup";
import TextField from "../TextField";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  Formik,
  ErrorMessage,
  FieldArray,
  useFormikContext,
  useField,
  Field,
} from "formik";
import { FaAddressBook, FaPencilAlt } from "react-icons/fa";
import { Card } from "react-bootstrap";
import FormikAutoCompleteComponentNewLocation from "../../FormikAutoCompleteComponentNewLocation";
import { serverTimestamp } from "firebase/firestore";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const initialValues = {
  name: "",
  label: "",
  id: "",
  Address: "",
  Contact: "",
  Phone: "",
  Email: "",
  location: { value: "", address: "", coordinates: {} },
  type: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  Phone: Yup.string().required("Required"),
  Contact: Yup.string().required("Required"),
  location: Yup.object().shape({
    address: Yup.string().required("Required"),
  }),
  // Email: Yup.string().email().required("Required"),
});

const CarrierTypeComponent = (props) => {
  const {
    values: { type },
    setFieldValue,
  } = useFormikContext();

  const handleChange = (e) => {
    setFieldValue(props.name, e.target.value);
  };

  return (
    <Form.Group controlId="form-carrier-type">
      <Form.Row>
        <Col xs={6}>
          <Form.Check
            inline
            // custom
            checked={type === "Internal"}
            type="radio"
            label="Internal"
            name="type"
            onChange={handleChange}
            value="Internal"
          />
        </Col>
        <Col xs={6}>
          <Form.Check
            inline
            // custom
            type="radio"
            label="External"
            checked={type === "External"}
            name="type"
            onChange={handleChange}
            value="External"
          />
        </Col>
      </Form.Row>
      {props.touched.pickupType && props.errors.pickupType ? (
        <div className="error-message" style={{ color: "red" }}>
          {props.errors.pickupType}
        </div>
      ) : null}
    </Form.Group>
  );
};

const CarrierStatusComponent = (props) => {
  const {
    values: { active },
    setFieldValue,
  } = useFormikContext();

  const handleChange = (val) => {
    setFieldValue(props.name, val);
  };

  return (
    <Form.Group controlId="form-carrier-status">
      <Form.Row>
        <Col xs={6}>
          <Form.Check
            inline
            id="carrier-status"
            // custom
            checked={active === true}
            type="radio"
            label="Active"
            name="active"
            onChange={() => handleChange(true)}
            value={true}
          />
        </Col>
        <Col xs={6}>
          <Form.Check
            inline
            // custom
            id="carrier-status"
            type="radio"
            checked={active === false}
            label="In-active"
            name="active"
            onChange={() => handleChange(false)}
            value={false}
          />
        </Col>
      </Form.Row>
      {props.touched.pickupType && props.errors.pickupType ? (
        <div className="error-message" style={{ color: "#EC7063" }}>
          {props.errors.pickupType}
        </div>
      ) : null}
    </Form.Group>
  );
};

const ListMyAddressBook = () => {
  const [carrierList, setCarrierList] = useState([]);
  const [q, setQ] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showNewAddressModal, setShowNewAddressModal] = useState(false);
  const [showUpdateAddressModal, setShowUpdateAddressModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [data, setData] = useState([]);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const { data: user } = useUser();

  const handleCloseNewAddressModal = () => setShowNewAddressModal(false);
  const handleShowNewAddressModal = () => setShowNewAddressModal(true);

  const handleCloseUpdateAddressModal = () => setShowUpdateAddressModal(false);
  const handleShowUpdateAddressModal = () => setShowUpdateAddressModal(true);

  // FS query
  useEffect(() => {
    const unsub = firestore
      .collection("freight-location-addressbook")
      .onSnapshot((snap) => {
        const tmpData = [];
        snap.forEach((d) => {
          const data = d.data();
          tmpData.push({ docid: d.id, ...data });
        });
        setData(tmpData);
      });
    return unsub;
  }, []);

  // //   Load all carriers
  useEffect(() => {
    setCarrierList([]);
    setIsLoading(true);
    // var t0 = performance.now();

    let tmpFilter = [];
    if (data?.length > 0) {
      if (q.length > 0) {
        tmpFilter = data?.filter((c) => {
          let foundObj = false;
          const arrKeys = Object.keys(c);
          for (var i = 0; i < arrKeys.length; i++) {
            const f = arrKeys[i];
            if (c[f].length > 0) {
              const arrayValue = c[f].toLowerCase();
              const searchpos = arrayValue.search(q);
              if (searchpos >= 0) {
                foundObj = true;
                break;
              }
            }
          }

          return foundObj;
        });
      } else {
        tmpFilter = data;
      }
      tmpFilter.sort((a, b) => a.name.localeCompare(b.name));
      setCarrierList(tmpFilter);
    }
    // var t1 = performance.now();
    // console.log("Filter  took " + (t1 - t0) + " milliseconds.");

    setIsLoading(false);
  }, [data, q]);

  return (
    <>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      {/* {status === "loading" ? "Loading" : error?.message} */}
      <Container fluid>
        <Button
          block
          variant="outline-primary"
          onClick={handleShowNewAddressModal}
        >
          <FaAddressBook />
          &nbsp; Add new address
        </Button>
        <br />
        <Form.Control
          type="text"
          name="q"
          placeholder="Search"
          onChange={(e) => setQ(e.target.value.toLowerCase())}
        ></Form.Control>
        <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              <th>Actions</th>
              <th>Name</th>
              <th>Type</th>
              <th>Address</th>
              <th>Contact Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {carrierList.map((c, idx) => {
              return (
                <tr
                  key={c.docid}
                  style={{ backgroundColor: !c.active ? "#EC7063" : null }}
                >
                  <td>
                    <Button
                      variant="outline-info"
                      onClick={() => {
                        setSelectedAddress(c);
                        setShowUpdateAddressModal(true);
                      }}
                    >
                      <FaPencilAlt />
                    </Button>
                  </td>
                  <td>{c.name}</td>
                  <td>{c.type}</td>
                  <td>{c.location?.value}</td>
                  <td>{c.Contact}</td>
                  <td>{c.Phone}</td>
                  <td>{c.Email}</td>
                  <td>{c.active ? "Active" : "In-active"}</td>
                  {/* <td>{JSON.stringify(c.routes, null, 2)}</td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
      {/* START NEW CARRIER MODAL */}
      <Modal
        show={showNewAddressModal}
        onHide={handleCloseNewAddressModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add new item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            // validateOnChange={true}
            // validateOnBlur={true}
            onSubmit={async (values, { resetForm, errors }) => {
              values.Address = values.location.address;
              values.label = values.name;
              values.createdBy = user.uid;
              values.createdAt = serverTimestamp();
              // values.createdAt =
              // firebaseconst.firestore.FieldValue.serverTimestamp();
              values.active = true;
              firestore
                .collection("freight-location-addressbook")
                .add(values)
                .then((r) => {
                  resetForm();
                  handleCloseNewAddressModal();
                  toast.success(
                    `New address book item created with id ${r.id}`
                  );
                });
            }}
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              isSubmitting,
              resetForm,
            }) => (
              <Form autocomplete="off">
                <>
                  {/* <pre>
                    <code></code> {JSON.stringify(values, null, 2)}
                  </pre> */}
                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  <TextField
                    fieldname={`name`}
                    label="Business Name"
                    placeholder="ABC Dealership"
                    type="text"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <Field
                    name="location"
                    component={FormikAutoCompleteComponentNewLocation}
                  />
                  {/* <ErrorMessage name={`location.address`}>
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage> */}

                  <TextField
                    fieldname={`Contact`}
                    label="Contact"
                    placeholder="James Smith"
                    type="text"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />

                  <TextField
                    fieldname={`Phone`}
                    label="Phone"
                    placeholder="(03) 8722 1234"
                    type="tel"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <TextField
                    fieldname={`Email`}
                    label="Email"
                    // placeholder="510123"
                    type="text"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />

                  <CarrierTypeComponent
                    name="type"
                    touched={touched}
                    errors={errors}
                  />

                  {/* <TextField
                    fieldname={`eclipseId`}
                    label="Eclipse Reference ID"
                    // placeholder="510123"
                    type="tel"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  /> */}

                  <ButtonGroup block>
                    <Button
                      variant="outline-danger"
                      type="reset"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                    <Button variant="outline-success" onClick={handleSubmit}>
                      Create{" "}
                      {isSubmitting ? <Spinner animation="grow" /> : null}
                    </Button>
                  </ButtonGroup>
                </>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNewAddressModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* END NEW CARRIER MODAL */}

      {/* START UPDATE CARRIER MODAL */}
      <Modal
        show={showUpdateAddressModal}
        onHide={handleCloseUpdateAddressModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={selectedAddress}
            // validationSchema={validationSchema}
            // validateOnChange={true}
            enableReinitialize={true}
            // validateOnBlur={true}
            onSubmit={async (values, { resetForm, errors }) => {
              values.Address = values.location.address;
              values.label = values.name;
              values.updatedBy = user.uid;
              values.lastModifiedDate = serverTimestamp();
              // values.lastModifiedDate =
              // firebaseconst.firestore.FieldValue.serverTimestamp();
              await firestore
                .collection("freight-location-addressbook")
                .doc(values.docid)
                .set(values, { merge: true })
                .then((r) => {
                  console.log(r);
                  resetForm();
                  handleCloseUpdateAddressModal();
                  toast.success(`Updated address book`);
                });
            }}
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              isSubmitting,
              resetForm,
            }) => (
              <Form autocomplete="off">
                <>
                  {/* <pre>
                    <code></code> {JSON.stringify(values, null, 2)}
                  </pre> */}
                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  <TextField
                    fieldname={`name`}
                    label="Business Name"
                    placeholder="ABC Dealership"
                    type="text"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <Field
                    name="location"
                    component={FormikAutoCompleteComponentNewLocation}
                  />
                  {/* <ErrorMessage name={`location.address`}>
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage> */}

                  <TextField
                    fieldname={`Contact`}
                    label="Contact"
                    placeholder="James Smith"
                    type="text"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />

                  <TextField
                    fieldname={`Phone`}
                    label="Phone"
                    placeholder="(03) 8722 1234"
                    type="tel"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <TextField
                    fieldname={`Email`}
                    label="Email"
                    // placeholder="510123"
                    type="text"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />

                  <CarrierTypeComponent
                    name="type"
                    touched={touched}
                    errors={errors}
                  />

                  <CarrierStatusComponent
                    name="active"
                    touched={touched}
                    errors={errors}
                  />
                  {/* <TextField
                    fieldname={`eclipseId`}
                    label="Eclipse Reference ID"
                    // placeholder="510123"
                    type="tel"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  /> */}

                  <ButtonGroup block>
                    <Button variant="outline-success" onClick={handleSubmit}>
                      Update
                      {isSubmitting ? <Spinner animation="grow" /> : null}
                    </Button>
                  </ButtonGroup>
                </>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdateAddressModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* END UPDATE CARRIER MODAL */}
      <ToastContainer />
    </>
  );
};

export default ListMyAddressBook;
