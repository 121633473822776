import Board from "react-trello";
import React, { useState, useEffect, useRef } from "react";
import { useFunctions, useUser } from "reactfire";
import { Formik, ErrorMessage } from "formik";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import TextField from "../FreightBooking/TextField";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
  Table,
  Tabs,
  Tab,
  Collapse,
  InputGroup,
  Container,
} from "react-bootstrap";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Legend,
  CartesianGrid,
  Label,
  Area,
  ComposedChart,
  Bar,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Cell,
  ZAxis,
} from "recharts";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  HiOutlinePlus,
  HiOutlinePlusCircle,
  HiOutlineSearch,
  HiPlusCircle,
} from "react-icons/hi";
import * as Yup from "yup";
import { useVEHMakeModel } from "../../hooks/useVEHMakeModel";
import { useYearsList } from "../../hooks/useYearsList";
import { filter } from "cheerio/lib/api/traversing";
import { httpsCallable } from "firebase/functions";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const dabs_vic_trello_checklist = require("../../assets/json/dabs_vic_trello_checklists.json");
const makeModelList = require("../../assets/json/mm.json");

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const IMAGE_HEIGHT = 250;
const DEFAULT_DAYS_TO_LOAD = 3;
const DEFAULT_DAYS_ON_MARKET = 3;

const stickyTableHeaderStyle = {
  position: "sticky",
  margin: "0 0 0 0",
  top: -1,
  backgroundColor: "#566573",
  color: "white",
  fontSize: 12,
};

const carsalesStockLocatorUrlPrefix =
  "https://stocklocator.carsalesnetwork.com.au/details/";
const carsalesImageSuffix =
  "?pxc_size=500,380&pxc_method=fitfill&pxc_bgcolor=000000";

const SUGAR_CRM_BASE_LEAD_URL = "https://crm.sellyourcarfast.com.au/#Leads/";

const TrelloCardsListByUser = ({ userId }) => {
  const [trelloCards, setTrelloCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  useEffect(() => {
    const fetchTrelloCards = async () => {
      const collectionRef = firestore.collection("buying-trello-cards");
      let docRef;
      if (userId === "JknoVlb6Uic1SSjouHBqp2mjNH92") {
        docRef = collectionRef;
      } else {
        docRef = collectionRef.where("createdByUid", "==", userId);
      }

      docRef.get().then((snapshot) => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          setLoading(false);
          return;
        }

        let tmpCards = [];
        snapshot.forEach((doc) => {
          tmpCards.push(doc.data());
        });

        // Sort by created date desc
        tmpCards.sort((a, b) => b.created - a.created);

        setTrelloCards(tmpCards);
        setLoading(false);
      });
    };

    fetchTrelloCards();
  }, []);

  return (
    <>
      {!loading && trelloCards.length === 0 && <p>No cards found</p>}
      {loading && <Spinner animation="border" size="sm" />}
      {trelloCards.length > 0 && (
        <Table striped bordered hover size="sm">
          <thead
            style={{
              ...stickyTableHeaderStyle,
            }}
          >
            <tr>
              {/* <th>Card ID</th> */}
              <th>Card Name</th>
              <th>Created At</th>
              <th>By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody
            style={{
              fontSize: 12,
            }}
          >
            {trelloCards.map((card, idx) => {
              return (
                <tr key={idx}>
                  {/* <td>{card.cardId}</td> */}
                  <td style={{ textAlign: "left" }}>{card.cardName}</td>
                  <td>{dayjs.unix(card.created).fromNow()}</td>
                  <td>{card.createdBy}</td>
                  <td>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => {
                        window.open(card.cardUrl, "_blank");
                      }}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

const CreateSearchTicket = () => {
  const [showNewRequestModal, setShowNewRequestModal] = useState(false);
  const [selectedMake, setSelectedMake] = useState({});
  const [dependentModels, setDependentModels] = useState([]);
  const [selectedMinYear, setSelectedMinYear] = useState();
  const [filteredMinYearList, setFilteredMinYearList] = useState();
  const [selectedMaxYear, setSelectedMaxYear] = useState();
  const [filteredMaxYearList, setFilteredMaxYearList] = useState([]);

  const handleCloseNewRequestModal = () => setShowNewRequestModal(false);
  const handleShowNewRequestModal = () => setShowNewRequestModal(true);

  const { data: user } = useUser();

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const [makes, models] = useVEHMakeModel();
  const [yearsList] = useYearsList();

  let initialValues = {
    name: "",
    phone: "",
    email: "",
    sfLeadID: "",
    make: "",
    model: "",
    minYear: 0,
    maxYear: 0,
    minPrice: 0,
    maxPrice: 0,
    notes: "",
    status: "open",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    // email: Yup.string().required("Required"),
    make: Yup.string().required("Required"),
    model: Yup.string().required("Required"),

    minYear: Yup.number()
      .required("Required")
      .moreThan(0, "Must not be zero")
      .lessThan(Yup.ref("maxYear", "Must be less than Max Year")),

    maxYear: Yup.number()
      .required("Required")
      .moreThan(0, "Must not be zero")
      .moreThan(Yup.ref("minYear", "Must be greater than Min Year")),

    minPrice: Yup.number()
      .required("Required")
      .lessThan(Yup.ref("maxPrice"), "Must be less than maximum price"),
    maxPrice: Yup.number()
      .required("Required")
      .moreThan(Yup.ref("minPrice"), "Must be greater than minimum price"),
  });

  useEffect(() => {
    // Update Max Year list to be > selected min year
    if (selectedMinYear >= 0) {
      const filteredList = yearsList.filter(
        (y) => parseInt(y.id) > selectedMinYear
      );
      setFilteredMaxYearList(filteredList);
    }
  }, [selectedMinYear, selectedMaxYear]);

  useEffect(() => {
    setFilteredMinYearList(yearsList);
    setFilteredMaxYearList(yearsList);
  }, [yearsList]);

  useEffect(() => {
    // Update Min Year list to be < selected max year
    if (selectedMaxYear >= 0) {
      const filteredList = yearsList.filter(
        (y) => parseInt(y.id) < selectedMaxYear
      );
      setFilteredMinYearList(filteredList);
    }
  }, [selectedMaxYear, selectedMinYear]);

  // Update when make changes to filter dependent dropdown for model
  useEffect(() => {
    // Has a selected make
    if (selectedMake && models.length > 0) {
      const tmpModelFilter = models.filter((model) => {
        return model.MakeCode === selectedMake.MakeCode ? true : false;
      });

      setDependentModels(tmpModelFilter);
    } else {
    }
  }, [JSON.stringify(selectedMake)]);
  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 9999,
          // Border raised effect shadow black
          boxShadow: "0 0 10px rgba(0,0,0,0.5)",
          borderRadius: 50,
        }}
      >
        <Button
          style={{
            borderRadius: 50,
            backgroundColor: "#52BE80",
            border: 0,
            padding: 10,
          }}
          onClick={handleShowNewRequestModal}
        >
          <HiOutlinePlus /> Add search request
        </Button>
      </div>
      {/* START NEW REQUEST MODAL */}
      <Modal
        show={showNewRequestModal}
        onHide={handleCloseNewRequestModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add vehicle search request
            <br />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <sup>
            *Your search request will be actioned within 30 minutes during
            business hours, and within 15 hours when outside of business hours
          </sup>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            // validateOnChange={true}
            // validateOnBlur={true}
            onSubmit={async (values, { resetForm, errors }) => {
              // values.Address = values.location.address;
              // values.label = values.name;
              values.numberOfMatchedVehicles = 0;
              values.createdBy = user.uid;
              values.createdUserEmail = user.email;
              values.createdUserDisplayName = user.displayName;
              values.createdUserPhone = user.phoneNumber;
              values.createdAt = dayjs().unix();
              // firestore
              //   .collection("retail-concierge-requests")
              //   .add(values)
              //   .then((r) => {
              //     resetForm();
              //     handleCloseNewRequestModal();
              //     toast.success(`New request created.`);
              //   });
            }}
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              isSubmitting,
              setFieldValue,
              resetForm,
            }) => (
              <Form autocomplete="off">
                <>
                  {/* <pre>
                    <code></code> {JSON.stringify(values, null, 2)}
                  </pre> */}
                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  <Card>
                    <Card.Header>Vehicle information</Card.Header>
                    <Card.Body>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Make<sup>*</sup>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Typeahead
                          id="onclear-make"
                          name="make"
                          options={makes}
                          onChange={(selected) => {
                            if (selected.length > 0) {
                              setFieldValue("make", selected[0].label);
                              setSelectedMake(selected[0]);
                            } else if (selected.length === 0) {
                              setFieldValue("make", "");
                              setSelectedMake([]);
                            }
                          }}
                          value={values.make}
                          placeholder="Choose a make..."
                        >
                          {({ onClear, selected }) => (
                            <div className="rbt-aux">
                              {!!selected.length && (
                                <ClearButton onClick={onClear} />
                              )}
                              {!selected.length && (
                                <Spinner animation="grow" size="sm" />
                              )}
                            </div>
                          )}
                        </Typeahead>
                        <ErrorMessage name="make">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </InputGroup>
                      <br />
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon2">
                            Model<sup>*</sup>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Typeahead
                          id="onclear-model"
                          name="model"
                          options={dependentModels}
                          onChange={(selected) => {
                            if (selected.length > 0) {
                              setFieldValue("model", selected[0].label);
                            } else if (selected.length === 0) {
                              setFieldValue("model", "");
                            }
                          }}
                          value={values.model}
                          placeholder="Choose a model..."
                        >
                          {({ onClear, selected }) => (
                            <div className="rbt-aux">
                              {!!selected.length && (
                                <ClearButton onClick={onClear} />
                              )}
                              {!selected.length && (
                                <Spinner animation="grow" size="sm" />
                              )}
                            </div>
                          )}
                        </Typeahead>
                        <ErrorMessage name="model">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </InputGroup>
                      <br />
                      <Row>
                        <Col>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon3">
                                Year (min)<sup>*</sup>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Typeahead
                              id="onclear-minyear"
                              name="minYear"
                              options={filteredMinYearList}
                              // labelKey="label"
                              onChange={(selected) => {
                                if (selected.length > 0) {
                                  setFieldValue(
                                    "minYear",
                                    parseInt(selected[0].label)
                                  );
                                  setSelectedMinYear(
                                    parseInt(selected[0].label)
                                  );
                                } else if (selected.length === 0) {
                                  setFieldValue("minYear", 0);
                                  setSelectedMinYear(0);
                                }
                              }}
                              value={values.minYear}
                              placeholder="select..."
                            >
                              {({ onClear, selected }) => (
                                <div className="rbt-aux">
                                  {!!selected.length && (
                                    <ClearButton onClick={onClear} />
                                  )}
                                  {!selected.length && (
                                    <Spinner animation="grow" size="sm" />
                                  )}
                                </div>
                              )}
                            </Typeahead>
                            <ErrorMessage name="minYear">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </InputGroup>
                        </Col>
                        <Col>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon4">
                                Year (max)<sup>*</sup>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Typeahead
                              id="onclear-maxyear"
                              name="maxYear"
                              options={filteredMaxYearList}
                              // labelKey="label"
                              onChange={(selected) => {
                                if (selected.length > 0) {
                                  setFieldValue(
                                    "maxYear",
                                    parseInt(selected[0].label)
                                  );
                                  setSelectedMaxYear(
                                    parseInt(selected[0].label)
                                  );
                                } else if (selected.length === 0) {
                                  setFieldValue("maxYear", 0);
                                  setSelectedMaxYear(0);
                                }
                              }}
                              value={values.minYear}
                              placeholder="select..."
                            >
                              {({ onClear, selected }) => (
                                <div className="rbt-aux">
                                  {!!selected.length && (
                                    <ClearButton onClick={onClear} />
                                  )}
                                  {!selected.length && (
                                    <Spinner animation="grow" size="sm" />
                                  )}
                                </div>
                              )}
                            </Typeahead>
                            <br />
                            <ErrorMessage name="maxYear">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </InputGroup>
                        </Col>
                      </Row>

                      <br />

                      <Row>
                        <Col>
                          <Form.Group controlId={`form-minPrice`}>
                            <Form.Label>Min Price</Form.Label>
                            <NumberFormat
                              className="form-control"
                              /* This name property is used to access the value of the form element via values.nameOfElement */
                              name={`minPrice`}
                              /* Set onChange to handleChange */
                              onValueChange={(v) => {
                                setFieldValue("minPrice", v.value);
                              }}
                              /* Set onBlur to handleBlur */
                              onBlur={handleBlur}
                              /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                              value={values.minPrice}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                            <ErrorMessage name="minPrice">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId={`form-maxPrice`}>
                            <Form.Label>Max Price</Form.Label>
                            <NumberFormat
                              className="form-control"
                              /* This name property is used to access the value of the form element via values.nameOfElement */
                              name={`maxPrice`}
                              /* Set onChange to handleChange */
                              onValueChange={(v) => {
                                setFieldValue("maxPrice", v.value);
                              }}
                              /* Set onBlur to handleBlur */
                              onBlur={handleBlur}
                              /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                              value={values.maxPrice}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                            <ErrorMessage name="maxPrice">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <br />

                  <br />
                  <Card>
                    <Card.Header>Other information</Card.Header>
                    <Card.Body>
                      <TextField
                        fieldname={`notes`}
                        label="Notes"
                        placeholder=""
                        type="textarea"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </Card.Body>
                  </Card>
                  <br />
                  <ButtonGroup block style={{ float: "right" }}>
                    <Button
                      variant="outline-danger"
                      type="reset"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                    <Button
                      disabled
                      variant="outline-success"
                      onClick={handleSubmit}
                    >
                      Submit (You do not have access to this feature)
                      {isSubmitting ? <Spinner animation="grow" /> : null}
                    </Button>
                  </ButtonGroup>
                </>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNewRequestModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* END NEW REQUEST MODAL */}
    </>
  );
};

const VehicleSelectDropdownComponent = ({
  make,
  model,
  year,
  description,
  makeModelList,
  handleSelect,
  toast,
  resetSearchToInitialConditions,
  targetedSearchResults,
}) => {
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedVehicleCode, setSelectedVehicleCode] = useState(null);
  const [selectedMinYear, setSelectedMinYear] = useState();
  const [filteredMinYearList, setFilteredMinYearList] = useState();
  const [selectedMaxYear, setSelectedMaxYear] = useState();
  const [filteredMaxYearList, setFilteredMaxYearList] = useState([]);

  const [dependentModels, setDependentModels] = useState([]);
  const [makes, setMakes] = useState([]);
  const [yearsList] = useYearsList();

  const initState = {
    make: selectedMake,
    model: selectedModel,
    variant: "",
  };

  const makeTypeAheadRef = useRef();
  const modelTypeAheadRef = useRef();
  const minYearTypeAheadRef = useRef();

  const submitForm = (values, setSubmitting) => {
    console.log("Form submitted", values);
    if (
      values.make === targetedSearchResults.make &&
      values.model === targetedSearchResults.model &&
      values.minYear === targetedSearchResults.year
    ) {
      toast.info(
        "You have just searched for this vehicle. To conduct another search press Clear or select different vehicle parameters.",
        {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setSubmitting(false);
      return;
    }
    handleSelect(values.make, values.model, values.minYear);
    setSubmitting(false);
  };

  useEffect(() => {
    // De duplicate makes
    const tmpMakes = makeModelList.map((m) => m.MakeDescription);
    const uniqueMakes = tmpMakes.filter(
      (v, i, a) => a.findIndex((t) => t === v) === i
    );

    // Order alphabetically
    uniqueMakes.sort();

    setMakes(uniqueMakes);
  }, [makeModelList]);

  // Set dependend models based on the change in makes
  useEffect(() => {
    if (selectedMake.length === 0) {
      setDependentModels([]);
      return;
    }
    const tmpModelsFiltered = makeModelList.filter(
      (m) => m.MakeDescription === selectedMake
    );
    const tmpModels = tmpModelsFiltered.map((m) => m.Description);
    // De duplicate models
    const uniqueModels = tmpModels.filter(
      (v, i, a) => a.findIndex((t) => t === v) === i
    );
    // Order alphabetically
    uniqueModels.sort();

    setDependentModels(uniqueModels);
  }, [selectedMake]);

  useEffect(() => {
    // Update Max Year list to be > selected min year
    if (selectedMinYear >= 0) {
      const filteredList = yearsList.filter(
        (y) => parseInt(y.id) > selectedMinYear
      );
      setFilteredMaxYearList(filteredList);
    }
  }, [selectedMinYear, selectedMaxYear]);

  useEffect(() => {
    setFilteredMinYearList(yearsList);
    setFilteredMaxYearList(yearsList);
  }, [yearsList]);

  useEffect(() => {
    // Update Min Year list to be < selected max year
    if (selectedMaxYear >= 0) {
      const filteredList = yearsList.filter(
        (y) => parseInt(y.id) < selectedMaxYear
      );
      setFilteredMinYearList(filteredList);
    }
  }, [selectedMaxYear, selectedMinYear]);

  return (
    <>
      <Container>
        <Formik
          initialValues={initState}
          onReset={(values, { setSubmitting }) => {
            console.log(`Form reset`, values);
            makeTypeAheadRef.current.clear();
            modelTypeAheadRef.current.clear();
            minYearTypeAheadRef.current.clear();
            setDependentModels([]);
            setSelectedMake("");
            setSelectedModel("");
            setSelectedMinYear("");
            setSubmitting(false);
            resetSearchToInitialConditions();
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (
              values.make.length > 0 &&
              values.model.length > 0 &&
              values.minYear > 0
            ) {
              console.log(`Form submitted`, values);
              submitForm(values, setSubmitting);
            } else {
              console.log(`Form not submitted`, values);
              toast.error("Please select make, model and year when searching", {
                position: "bottom-left",

                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            isSubmitting,
            handleSubmit,
            handleChange,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col style={{ padding: 10 }}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        Make<sup>*</sup>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Typeahead
                      id="onclear-make"
                      name="make"
                      ref={makeTypeAheadRef}
                      options={makes || []}
                      onChange={(selected) => {
                        if (selected.length > 0) {
                          setFieldValue("make", selected[0]);
                          setSelectedMake(selected[0]);
                        } else if (selected.length === 0) {
                          setFieldValue("make", "");
                          setFieldValue("model", "");
                          setSelectedMake([]);
                        }
                      }}
                      value={values.make || ""}
                      placeholder="Choose a make..."
                    >
                      {({ onClear, selected }) => (
                        <div className="rbt-aux">
                          {!!selected.length && (
                            <ClearButton onClick={onClear} />
                          )}
                          {/* {!selected.length && (
                            <Spinner animation="grow" size="sm" />
                          )} */}
                        </div>
                      )}
                    </Typeahead>
                  </InputGroup>
                </Col>
                <Col style={{ padding: 10 }}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        Model<sup>*</sup>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Typeahead
                      id="onclear-model"
                      name="model"
                      options={dependentModels || []}
                      ref={modelTypeAheadRef}
                      onChange={(selected) => {
                        if (selected.length > 0) {
                          setFieldValue("model", selected[0]);
                        } else if (selected.length === 0) {
                          setFieldValue("model", "");
                        }
                      }}
                      value={values.model || ""}
                      placeholder="Choose a model..."
                    >
                      {({ onClear, selected }) => (
                        <div className="rbt-aux">
                          {!!selected.length && (
                            <ClearButton onClick={onClear} />
                          )}
                          {/* {!selected.length && (
                            <Spinner animation="grow" size="sm" />
                          )} */}
                        </div>
                      )}
                    </Typeahead>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col style={{ padding: 10 }}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        Variant
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      placeholder="Coming soon..."
                      name="variant"
                      disabled
                      onChange={handleChange}
                      value={values.variant}
                    ></Form.Control>
                    <InputGroup.Append>
                      <Button
                        variant="outline-secondary"
                        onClick={() => {
                          setFieldValue("variant", "");
                        }}
                      >
                        x
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
                <Col style={{ padding: 10 }}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon3">
                        Year<sup>*</sup>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Typeahead
                      id="onclear-minyear"
                      name="minYear"
                      options={filteredMinYearList || []}
                      ref={minYearTypeAheadRef}
                      // labelKey="label"
                      onChange={(selected) => {
                        if (selected.length > 0) {
                          setFieldValue("minYear", parseInt(selected[0].label));
                          setSelectedMinYear(parseInt(selected[0].label));
                        } else if (selected.length === 0) {
                          setFieldValue("minYear", 0);
                          setSelectedMinYear(0);
                        }
                      }}
                      value={values.minYear || ""}
                      placeholder="select..."
                    >
                      {({ onClear, selected }) => (
                        <div className="rbt-aux">
                          {!!selected.length && (
                            <ClearButton onClick={onClear} />
                          )}
                          {/* {!selected.length && (
                            <Spinner animation="grow" size="sm" />
                          )} */}
                        </div>
                      )}
                    </Typeahead>
                    <ErrorMessage name="minYear">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ButtonGroup style={{ width: "100%", paddingTop: 10 }}>
                    <Button
                      variant="outline-secondary"
                      type="reset"
                      id="frm-reset-btn"
                      disabled={isSubmitting}
                      size="md"
                      onClick={resetForm}
                    >
                      Clear
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#52BE80",
                        border: 0,
                        color: "black",
                      }}
                      type="submit"
                      id="frm-submit-btn"
                      disabled={isSubmitting}
                      size="md"
                    >
                      Search
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>

              <Collapse in={isSubmitting}>
                <Row style={{ marginTop: 10 }}>
                  <Col>
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </Col>
                </Row>
              </Collapse>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
};

const MyRecentList = ({ userId }) => {
  const { data: user } = useUser();
  return (
    <>
      <h6
        style={{
          backgroundColor: stickyTableHeaderStyle.backgroundColor,
          color: stickyTableHeaderStyle.color,
          margin: 0,
          padding: 5,
          border: 0,
        }}
      >
        My recently added listings to Trello
      </h6>
      <TrelloCardsListByUser userId={user.uid} />
    </>
  );
};

const DuttonInventoryPhoto = ({ stockNumber }) => {
  const [photoUrl, setPhotoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  useEffect(() => {
    const fetchPhoto = async () => {
      if (!stockNumber) return;
      // Get photo url from Firestore based on stock number in inventory-photos
      const collectionRef = firestore.collection("inventory-photos");
      const docRef = collectionRef.doc(stockNumber.toString());

      docRef.get().then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          console.log("Image data found", data.vin);
          if (data?.photos?.length > 0) {
            const tmpPhotoUrl = data.photos[0] + carsalesImageSuffix;
            setPhotoUrl(tmpPhotoUrl);
          } else {
            setError("No photo found");
          }
        } else {
          // doc.data() will be undefined in this case
          setError("No photo found");
        }
        setLoading(false);
      });
    };

    fetchPhoto();
  }, []);

  return (
    <>
      {loading && <Spinner animation="border" />}
      {error && (
        <p style={{ backgroundColor: "#E8DAEF", padding: 5 }}>{error}</p>
      )}
      {photoUrl && (
        <Image
          src={photoUrl}
          // className="d-block w-100"
          style={{
            alignItems: "center",
            contain: "contain",
          }}
          alt="First slide"
          height={75}
        />
      )}
    </>
  );
};

const BuyingCardDecision = ({ card, active }) => {
  const [trelloCard, setTrelloCard] = useState(null);
  const [trelloChecklists, setTrelloChecklists] = useState([]);
  const [trelloChecklistItems, setTrelloChecklistItems] = useState([]);
  const [trelloComments, setTrelloComments] = useState([]);
  const [crunchRequired, setCrunchRequired] = useState(0);
  const [buyAction, setBuyAction] = useState("");
  const [allocation, setAllocation] = useState("");
  const [showCustomCrunchModal, setShowCustomCrunchModal] = useState(false);
  const [sugarLead, setSugarLead] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleBuy = () => {
    setBuyAction("Buy");
  };

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const functions = useFunctions();
  const { data: user } = useUser();
  // console.log(user);

  const getSugarLeadFromFirebase = async (sourceId) => {
    console.log(`Searching in SugarCRM for sourceId: ${sourceId}`);
    // Where carsales_ad_id_c = sourceId
    const docRef = firestore
      .collection("sugarcrm-leads")
      .where("carsales_ad_id_c", "==", sourceId)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          setSugarLead(null);
          return;
        }
        console.log(`Found ${snapshot.size} matching documents in SugarCRM`);

        snapshot.forEach((doc) => {
          setSugarLead(doc.data());
        });
      });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const addCardToTrello = async () => {
    if (trelloCard) {
      window.open(trelloCard.shortUrl, "_blank");
      return;
    }
    if (dabs_vic_trello_checklist.length === 0) {
      toast.error("Checklist not found");
      return;
    }
    console.log(dabs_vic_trello_checklist);

    if (buyAction.length === 0) {
      toast.warning("Please select an action: Buy, Pass or otherwise");
      return;
    }
    if (buyAction === "Buy") {
      if (crunchRequired === 0) {
        toast.warning("Please enter a crunch amount");
        return;
      }
      setLoading(true);
      toast.info(
        `Adding BUY card to Trello for ${card.advertisedPrice - crunchRequired}`
      );
      const today = dayjs().format("DD/MM/YYYY");

      // Call createTrelloCardDABS firebase function
      const createTrelloCardDABS = httpsCallable(
        functions,
        "createTrelloCardDABS"
      );
      // const createTrelloCardDABS = firebase
      //   .functions()
      //   .httpsCallable("createTrelloCardDABS");
      const cardDescription = `${user?.displayName}'s Price: $${
        card.advertisedPrice - crunchRequired
      }
      \nListed price at ${today}: $${card.advertisedPrice}
      \n\nLink: ${carsalesStockLocatorUrlPrefix}${card.sourceId}
      \nCustomer: ${card.sellerContactDetails?.name} - ${
        card.sellerContactDetails?.contactMethods[0]?.value
      }
      \nModel: ${card.specData?.model}
      \nKm's: ${card.odometer}
      \nLocation: ${card.state} ${card.region}
      \nBuild date: ${card.specData.year}
      \nCompliance date: 
      \nExtras:
      \nService history:
      \nKeys:
      \nFaults:
      \nCurrent purchased price:
      \nAzza priced:
      \nBuyer:
      \n
      `;
      try {
        const result = await createTrelloCardDABS({
          cardName: `${card.specData.year} ${card.specData.make} ${card.specData.model} - ${card.colour}`,
          cardDesc: cardDescription,
          carsalesSourceId: card.sourceId,
          checklists: dabs_vic_trello_checklist,
        });
        console.log(result.data);
        if (result.data.error) {
          toast.error(result.data.error);
        } else {
          toast.success("Card added to Trello");
          setTrelloCard(result.data);
        }
      } catch (e) {
        console.log(e);
        toast.error("Error adding card to Trello");
      }
      setLoading(false);
    } else {
      toast.info("Updating in sourcing board");
    }
  };

  const getTrelloCardInfo = async (cardId) => {
    // Get from firebase
    const getTrelloCardInfo = httpsCallable(firebase, "getTrelloCard");
    // const getTrelloCardInfo = firebase
    //   .functions()
    //   .httpsCallable("getTrelloCard");
  };

  useEffect(() => {
    if (card) {
      getSugarLeadFromFirebase(card.sourceId);
      setTrelloCard(null);
    }
  }, [JSON.stringify(card)]);

  return (
    <>
      <ToastContainer />

      <Card
        style={{
          backgroundColor: active ? "#AEB6BF " : "#EAECEE",
          color: active ? "#2C3E50" : "#2C3E50",
          marginBottom: 15,
          border: active ? "2px solid #ff6b00" : "1px solid #AEB6BF",
        }}
      >
        <Card.Title style={{ marginTop: 5 }}>
          {sugarLead && (
            <Alert variant="warning">
              <Badge variant="danger">Lead exists in SugarCRM</Badge>
              <br />
              <span style={{ fontSize: 12, textAlign: "left" }}>
                Added {dayjs(sugarLead?.date_entered).fromNow()} by{" "}
                {sugarLead?.assigned_user_link?.full_name}
                <br />
                Status: {sugarLead?.lead_status_text_c}
                <br />
                Business Unit: {sugarLead?.bu_divisions_leads_1_name}
                <br />
                <a
                  href={`${SUGAR_CRM_BASE_LEAD_URL}${sugarLead?.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Open in SugarCRM*
                </a>
              </span>
            </Alert>
          )}
          <h6 style={{ textAlign: "left", paddingLeft: 20 }}>
            {card?.dateDelisted && (
              <Badge variant="danger">
                Delisted: {dayjs(card?.dateDelisted).format("DD/MM/YYYY")}
              </Badge>
            )}{" "}
            {`${card?.specData?.year} ${card?.specData?.make} ${card?.specData?.model}`}
            <br />
            <span
              style={{ fontSize: "0.9em" }}
            >{`${card?.specData?.description}`}</span>
          </h6>
          <h6 style={{ textAlign: "left", paddingLeft: 20 }}>
            {card?.colour}&nbsp; | Adv:{" "}
            <b>
              <NumberFormat
                value={card?.advertisedPrice}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </b>
            | Odo:{" "}
            <NumberFormat
              value={card?.odometer}
              displayType={"text"}
              thousandSeparator={true}
            />
            | Age: {card?.daysOnMarket}| Views: {card?.views} | Leads:{" "}
            {card?.leads} | Watched: {card?.watches} |{" "}
            {card?.tags?.map((tag, idx) => {
              const tagColour = tag === "TOPSPOT" ? "success" : "warning";
              return (
                <Badge
                  style={{ marginRight: 5 }}
                  pill
                  variant={tagColour}
                  // text="light"
                  key={idx}
                >
                  {tag}
                </Badge>
              );
            })}
            {/* <span style={{ float: "right", fontSize: 12 }}> */}
            <a
              href={`${carsalesStockLocatorUrlPrefix}${card?.sourceId}`}
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: 12, color: "#3498DB" }}
            >
              Open in Carsales
            </a>
            {/* </span> */}
          </h6>
        </Card.Title>
        <Card.Body>
          <Row>
            <Col xs={6}>
              <Carousel
                infinite={true}
                showDots={true}
                responsive={responsive}
                slidesToSlide={1}
                style={{ backgroundColor: "black" }}
                itemClass="carousel-item-padding-40-px"
                containerClass="carousel-container"
                focusOnSelect
              >
                {card?.photos?.length > 0 &&
                  card?.photos?.slice(0, 1)?.map((photo, idx) => {
                    return (
                      <Image
                        key={idx}
                        fluid
                        className="d-block w-100"
                        src={photo.resourcePath}
                        alt="First slide"
                        style={{
                          maxHeight: IMAGE_HEIGHT,
                          objectFit: "scale-down",
                          backgroundColor: "black",
                        }}
                        onClick={() =>
                          console.log("clicked", photo.resourcePath)
                        }
                      />
                    );
                  })}
              </Carousel>
            </Col>
            <Col xs={6}>
              <Row>
                <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  style={{ backgroundColor: "#AEB6BF", color: "white" }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <b>Name:</b>
                        <br />
                        {card?.sellerContactDetails?.name}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Row>

              <Row>
                <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  style={{ backgroundColor: "#AEB6BF", color: "white" }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <a
                          style={{ color: "white" }}
                          href={`"tel:${card?.sellerContactDetails?.contactMethods[0]?.value}`}
                        >
                          {card?.sellerContactDetails?.contactMethods[0]?.value}
                        </a>
                      </td>
                      <td>{card?.state}</td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
              <Row>
                {/* <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                  width={300}
                  height={300}
                >
                  <XAxis dataKey="date" />
                  <YAxis
                    dataKey="soldPriceInc"
                    type="number"
                    domain={[0, "dataMax"]}
                  />
                  <ZAxis dataKey="dateSold" />

                  <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                  <Scatter data={pricingGraphDataDutton} fill="#8884d8" />
                  <Scatter data={pricingGraphDataAG} fill="#82ca9d" />
                </ScatterChart> */}
                <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  style={{ backgroundColor: "#AEB6BF", color: "white" }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <h6>
                          Price changes: <i>coming soon</i>
                        </h6>
                        {/* <LineChart
                          width={200}
                          height={85}
                          data={[
                            {
                              y: card?.advertisedPrice,
                            },
                            {
                              y: card?.advertisedPrice * Math.random() * 0.9,
                            },
                            {
                              y: card?.advertisedPrice * Math.random() * 0.9,
                            },
                            {
                              y: card?.advertisedPrice * Math.random() * 0.9,
                            },
                            {
                              y: card?.advertisedPrice * Math.random() * 0.9,
                            },
                            {
                              y: card?.advertisedPrice * Math.random() * 0.9,
                            },
                            {
                              y: card?.advertisedPrice * Math.random() * 0.9,
                            },
                            {
                              y: card?.advertisedPrice * Math.random() * 0.9,
                            },
                            {
                              y: card?.advertisedPrice * Math.random() * 0.9,
                            },
                          ]}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <YAxis dataKey="y" domain={[0, "dataMax"]} hide />
                          <Line
                            type="monotone"
                            dataKey="y"
                            stroke="#FFF"
                            strokeWidth={2}
                            dot={true}
                          />
                        </LineChart> */}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <ButtonGroup className="mb-2">
                <Button
                  disabled={loading}
                  style={{
                    border: "1px solid white",
                    width: 105,
                    backgroundColor:
                      buyAction === "Buy" ? "#2ECC71" : "#6c757d",
                  }}
                  onClick={() => setBuyAction("Buy")}
                >
                  Buy
                </Button>
                <Button
                  disabled={loading}
                  style={{
                    border: "1px solid white",
                    width: 105,
                    backgroundColor:
                      buyAction === "NoBuy" ? "#E74C3C" : "#6c757d",
                  }}
                  onClick={() => setBuyAction("NoBuy")}
                >
                  Dont Buy
                </Button>
                <Button
                  disabled={loading}
                  style={{
                    border: "1px solid white",
                    width: 105,
                    backgroundColor:
                      buyAction === "Pass" ? "#3498DB" : "#6c757d",
                  }}
                  onClick={() => setBuyAction("Pass")}
                >
                  Pass
                </Button>
                <Button
                  disabled={loading}
                  style={{
                    border: "1px solid white",
                    width: 105,
                    backgroundColor:
                      buyAction === "Allocation" ? "#3498DB" : "#6c757d",
                  }}
                  onClick={() => setBuyAction("Allocation")}
                >
                  Allocation
                </Button>
              </ButtonGroup>
            </Col>
            <Col>
              <ButtonGroup className="mb-2">
                <Button
                  disabled={loading}
                  style={{
                    width: 105,
                    backgroundColor: "#6c757d",
                    backgroundColor:
                      crunchRequired === 3000 ? "#3498DB" : "#6c757d",
                  }}
                  onClick={() => setCrunchRequired(3000)}
                >
                  -$3,000
                </Button>
                <Button
                  disabled={loading}
                  style={{
                    border: "1px solid white",
                    width: 105,
                    backgroundColor:
                      crunchRequired === 4000 ? "#3498DB" : "#6c757d",
                  }}
                  onClick={() => setCrunchRequired(4000)}
                >
                  -$4,000
                </Button>
                <Button
                  disabled={loading}
                  style={{
                    border: "1px solid white",
                    width: 105,
                    backgroundColor:
                      crunchRequired === 5000 ? "#3498DB" : "#6c757d",
                  }}
                  onClick={() => setCrunchRequired(5000)}
                >
                  -$5,000
                </Button>
                <Button
                  disabled={loading}
                  style={{
                    border: "1px solid orange",
                    width: 105,
                    backgroundColor:
                      crunchRequired === 0 ? "#3498DB" : "#6c757d",
                  }}
                  onClick={() => setShowCustomCrunchModal(true)}
                >
                  {crunchRequired === 0 ? (
                    "Manual"
                  ) : (
                    <NumberFormat
                      value={crunchRequired}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"-$"}
                    />
                  )}
                </Button>
              </ButtonGroup>
              <Button
                disabled={loading}
                variant={trelloCard ? "primary" : "success"}
                block
                onClick={addCardToTrello}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : trelloCard ? (
                  "Open in Trello"
                ) : (
                  "Add to Trello"
                )}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal
        show={showCustomCrunchModal}
        onHide={() => setShowCustomCrunchModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Custom Crunch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Enter amount to discount</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter crunch amount"
                value={crunchRequired}
                onChange={(e) => setCrunchRequired(e.target.value)}
              />
              <br />
              <h6>Advertised price - crunch $: </h6>
              <NumberFormat
                value={card?.advertisedPrice - crunchRequired}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowCustomCrunchModal(false)}
          >
            Save and go back
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const DABuyingBoard = () => {
  const [loading, setLoading] = useState(true);
  const [isLoadingLogs, setLoadingLogs] = useState(true);
  const [isLoadingRecentlySoldCars, setIsLoadingRecentlySoldCars] =
    useState(false);
  const [isLoadingRecentlySoldCarsCS, setIsLoadingRecentlySoldCarsCS] =
    useState(false);
  const [isLoadingInStockCars, setIsLoadingInStockCars] = useState(false);
  const [isLoadingSimilarCarsCS, setIsLoadingSimilarCarsCS] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [activeCardSimilars, setActiveCardSimilars] = useState([]);
  const [recentlySoldCarsForActiveCard, setRecentlySoldCarsForActiveCard] =
    useState([]);

  const [recentlySoldCarsCSForActiveCard, setRecentlySoldCarsCSForActiveCard] =
    useState([]);

  const [daysToLoadConst, setDaysToLoadConst] = useState(DEFAULT_DAYS_TO_LOAD);
  const [daysOnMarketConst, setDaysOnMarketConst] = useState(
    DEFAULT_DAYS_ON_MARKET
  );
  const [
    forceResetSearchToInitialConditions,
    setForceResetSearchToInitialConditions,
  ] = useState(false);

  const [pricingGraphDataDutton, setPricingGraphDataDutton] = useState([]);
  const [pricingGraphDataAG, setPricingGraphDataAG] = useState([]);
  const [pricingGraphData, setPricingGraphData] = useState([]);

  const [searchData, setSearchData] = useState([]);
  const [data, setData] = useState([]);
  const [filteredSearchData, setFilteredSearchData] = useState([]);

  const [searchDataRecentChanges, setSearchDataRecentChanges] = useState([]);
  const [filteredSearchDataRecentChanges, setFilteredSearchDataRecentChanges] =
    useState([]);
  const [dropdownVehileFilters, setDropdownVehicleFilters] = useState({
    make: "",
    model: "",
    year: "",
    description: "",
  });
  const [targetedSearchResults, setTargetedSearchResults] = useState({
    make: "",
    model: "",
    year: "",
    description: "",
    vehicleCode: "",
  });

  const [currentIndex, setCurrentIndex] = useState(1);

  const [carsInStock, setCarsInStock] = useState([]);

  const [showDetails, setShowDetails] = useState("none");
  const [limitToLoad, setLimitToLoad] = useState(5);
  const [showCSImages, setShowCSImages] = useState(true);

  const [kmsVsPriceGraphData, setKmsVsPriceGraphData] = useState([]);

  const [streamData, setStreamData] = useState(false);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const handleClose = () => {
    setShowDetails("none");
  };
  const handleShow = () => setShowDetails("block");

  const toggleShowCSImages = () => {
    setShowCSImages(!showCSImages);
  };

  const toggleStreamData = () => {
    setStreamData(!streamData);
  };

  const loadAdFromDBBySourceId = (sourceId) => {
    const collectionRef = firestore.collection("competitive-set-ag");
    const docRef = collectionRef.doc(sourceId);
    docRef.get().then((doc) => {
      if (doc.exists) {
        setActiveCard(doc.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    });
  };

  const handleIncrementCurrentIndex = () => {
    if (currentIndex === data.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
    console.log("currentIndex", currentIndex);
  };

  const handleVehicleSelect = (make, model, year) => {
    console.log(
      "Vehicle selected",
      make,
      model,
      year
      // description,
      // vehicleCode
    );
    // setDropdownVehicleFilters({
    //   make: make,
    //   model: model,
    //   // year: year,
    //   // description: description,
    // });
    setTargetedSearchResults({
      make: make,
      model: model,
      year: year,
      // description: description,
      // vehicleCode: vehicleCode,
    });
  };

  const handleDecrementCurrentIndex = () => {
    if (currentIndex === 0) {
      setCurrentIndex(data.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const getListedCarsCS = async (
    make,
    model,
    year,
    description,
    vehicleCode
  ) => {
    if (!make || !model || !year) return;
    setIsLoadingSimilarCarsCS(true);
    const collectionRef = firestore.collection("competitive-set-ag");
    const yearMinusOne = parseInt(year) - 1;
    const yearPlusOne = parseInt(year) + 1;
    const tmpRecentlyListedCars = await collectionRef
      // .where("specData.make", "==", make)
      // .where("specData.model", "==", model)
      // .where("specData.year", ">=", yearMinusOne)
      // .where("specData.year", "<=", yearPlusOne)
      // .where("specData.description", "==", description)
      .where("specData.vehicleCode", "==", vehicleCode)
      .where("sellerType", "==", "P")
      .get()
      .then((querySnapshot) => {
        let documents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Filter out if dateDelisted is not null
        documents = documents.filter((doc) => doc.dateDelisted === null);

        console.log("Similar cars", documents.length);
        return documents;
      });

    setActiveCardSimilars(tmpRecentlyListedCars);
    setIsLoadingSimilarCarsCS(false);
  };

  const getCarsCS = async (make, model, year) => {
    if (!make || !model || !year) {
      console.log("No make, model or year");
      return;
    } else {
      console.log("Make, model and year", make, model, year);
    }
    const collectionRef = firestore.collection("competitive-set-ag");
    const yearMinusOne = parseInt(year) - 1;
    const yearPlusOne = parseInt(year) + 1;
    const tmpRecentlyListedCars = await collectionRef
      .where("specData.make", "==", make)
      .where("specData.model", "==", model)
      .where("specData.year", ">=", yearMinusOne)
      .where("specData.year", "<=", yearPlusOne)
      // .where("specData.description", "==", description)
      // .where("specData.vehicleCode", "==", vehicleCode)
      // .where("sellerType", "==", "P")
      .get()
      .then((querySnapshot) => {
        let documents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          kms: doc.data().odometer,
          price: doc.data().advertisedPrice,
        }));
        // Filter out if dateDelisted is not null
        // documents = documents.filter((doc) => doc.dateDelisted === null);

        console.log("Graph data", documents.length);
        // console.log("Graph data", documents);
        return documents;
      });
    setKmsVsPriceGraphData(tmpRecentlyListedCars);
  };

  const getRecentlySoldCars = async (make, model, year) => {
    if (!make || !model || !year) return;
    setIsLoadingRecentlySoldCars(true);
    setRecentlySoldCarsForActiveCard([]);
    const collectionRef = firestore.collection("salesSummary");
    const yearMinusOne = parseInt(year) - 1;
    const yearPlusOne = parseInt(year) + 1;
    const soldDateMinusConstant = 90;
    const soldDateMinusXdays = dayjs()
      .subtract(soldDateMinusConstant, "days")
      .format("X");
    console.log("soldDateMinus180days", soldDateMinusXdays);
    let tmpRecentlySoldCars = await collectionRef
      .where("VEHMake", "==", make)
      .where("VEHModel", "==", model)
      // .where("VEHModelYear", ">=", yearMinusOne)
      // .where("VEHModelYear", "<=", yearPlusOne)
      .where("Sale Date EPOC", ">=", parseInt(soldDateMinusXdays))
      .where("sale_class", "==", "R")
      // .limit(10)
      .get()
      .then((querySnapshot) => {
        const documents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("recently sold", documents.length);
        return documents;
      });

    // Sort by days on market
    tmpRecentlySoldCars = tmpRecentlySoldCars.sort((a, b) => {
      return b.daysOnMarket < a.daysOnMarket;
    });
    console.log("tmpRecentlySoldCars", tmpRecentlySoldCars.length);
    // Filter for year >= year - 1 && year <= year + 1
    tmpRecentlySoldCars = tmpRecentlySoldCars.filter((car) => {
      return (
        car.VEHModelYear >= yearMinusOne && car.VEHModelYear <= yearPlusOne
      );
    });
    console.log("tmpRecentlySoldCars", tmpRecentlySoldCars.length);

    setRecentlySoldCarsForActiveCard(tmpRecentlySoldCars);
    setIsLoadingRecentlySoldCars(false);
  };

  const getRecentlySoldCarsCS = async (make, model, year, description) => {
    if (!make || !model || !year) return;
    setIsLoadingRecentlySoldCarsCS(true);
    setRecentlySoldCarsCSForActiveCard([]);
    const collectionRef = firestore.collection("competitive-set-ag");
    const yearMinusOne = parseInt(year) - 1;
    const yearPlusOne = parseInt(year) + 1;
    let tmpRecentlySoldCars = await collectionRef
      .where("specData.make", "==", make)
      .where("specData.model", "==", model)
      .where("specData.year", ">=", yearMinusOne)
      .where("specData.year", "<=", yearPlusOne)
      .where("specData.description", "==", description)
      .where("sellerType", "in", ["I", "F"])
      // .limit(100)
      .get()
      .then((querySnapshot) => {
        const documents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("CS", documents.length);
        return documents;
      });

    // Sort by days on market
    tmpRecentlySoldCars = tmpRecentlySoldCars.sort((a, b) => {
      if (a.daysOnMarket === null || b.daysOnMarket === null) return 0;

      return a.daysOnMarket - b.daysOnMarket;
    });
    setRecentlySoldCarsCSForActiveCard(tmpRecentlySoldCars);
    setIsLoadingRecentlySoldCarsCS(false);
  };

  const getCarsInStock = async (make, model, year) => {
    if (!make || !model || !year) return;
    setIsLoadingInStockCars(true);
    const collectionRef = firestore.collection("inventory-summary");
    const yearMinusOne = parseInt(year) - 1;
    const yearPlusOne = parseInt(year) + 1;
    const tmpRecentlySoldCars = await collectionRef
      .where("VEHMake", "==", make)
      .where("VEHModel", "==", model)
      .where("VEHModelYear", ">=", yearMinusOne)
      .where("VEHModelYear", "<=", yearPlusOne)
      .where("CurrentStatus", "==", "IN-STOCK")
      // .limit(10)
      .get()
      .then((querySnapshot) => {
        const documents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("In stock", documents.length);
        return documents;
      });
    setCarsInStock(tmpRecentlySoldCars);
    setIsLoadingInStockCars(false);
  };

  const calculatePricingGraphData = () => {
    if (!activeCard) return;
    // [
    //   {
    //     name: "priceChange",
    //     price: activeCard?.advertisedPrice,
    //     duttonSoldPriceInc: 46880,
    //     date: "Jan",
    //   },
    //   {
    //     name: "priceChange",
    //     price: activeCard?.advertisedPrice * 0.98,
    //     duttonSoldPriceInc: 42200,
    //     date: "Feb",
    //   },
    //   {
    //     name: "priceChange",
    //     price: activeCard?.advertisedPrice * 0.88,
    //     date: "Feb",
    //   },
    //   {
    //     name: "priceChange",
    //     price: activeCard?.advertisedPrice * 0.76,
    //     date: "Feb",
    //   },
    //   {
    //     name: "priceChange",
    //     price: activeCard?.advertisedPrice * 0.71,
    //     date: "Feb",
    //   },
    //   {
    //     name: "priceChange",
    //     price: activeCard?.advertisedPrice * 0.88,
    //     date: "Feb",
    //   },
    //   {
    //     name: "priceChange",
    //     price: activeCard?.advertisedPrice * 0.75,
    //     date: "Feb",
    //   },
    // ]

    const tmpPricingGraphDataDutton = [];
    const tmpPricingGraphData = [];
    if (recentlySoldCarsForActiveCard.length > 0) {
      recentlySoldCarsForActiveCard
        .sort((a, b) => {
          return a.Age - b.Age;
        })
        // .reverse()
        .forEach((car) => {
          const duttonSoldPriceInc = car["SalePriceInc"];
          const date = car["Age"];
          tmpPricingGraphDataDutton.push({
            name: "Dutton Sold Price Inc",
            soldPriceInc: duttonSoldPriceInc,
            colour: "#82ca9d",
            date: date,
            dateSold: dayjs.unix(car["Sale Date EPOC"]).format("DD/MM/YYYY"),
          });
        });
    }
    const tmpPricingGraphDataAG = [];
    if (recentlySoldCarsCSForActiveCard.length > 0) {
      recentlySoldCarsCSForActiveCard
        .sort((a, b) => {
          return a.daysOnMarket - b.daysOnMarket;
        })
        .reverse()
        .filter((car) => car.dateDelisted !== null)
        .forEach((car) => {
          const csSoldPrice = car.advertisedPrice;
          const date = car.daysOnMarket;
          tmpPricingGraphDataAG.push({
            name: "AG Adv Price Inc",
            colour: "#8884d8",
            soldPriceInc: csSoldPrice,
            date: date,
          });
        });
    }

    setPricingGraphDataDutton(tmpPricingGraphDataDutton);
    setPricingGraphDataAG(tmpPricingGraphDataAG);
    // setPricingGraphData(tmpPricingGraphData);
  };

  const resetSearchToInitialConditions = () => {
    // Reset search to initial conditions
    setForceResetSearchToInitialConditions(true);
    setActiveCard(null);
    setActiveCardSimilars([]);
    handleClose();
  };

  useEffect(() => {
    // Get vehicles that have been recently searched for by the DE team
    if (!forceResetSearchToInitialConditions && searchData.length > 0) return;
    const collectionName = "competitive-set-ag-logs";
    const daysToLoad = dayjs().subtract(daysToLoadConst, "days").format("X");
    console.log("Loading logs from", daysToLoad, collectionName);

    setLoadingLogs(true);
    setSearchData([]);
    firestore
      .collection(collectionName)
      .where("createdDate", ">=", parseInt(daysToLoad))
      // .where("newData.daysOnMarket", "<", daysToLoadConst)

      .where("logType", "==", "create-record")
      .where("newData.sellerType", "==", "P")
      .get()
      .then((snapshot) => {
        // .onSnapshot((snapshot) => {
        console.log("snapshot size", snapshot.size);
        if (snapshot.size === 0) {
          toast.info(
            `No new records found in the last ${daysToLoadConst} days`
          );
          setLoadingLogs(false);
          return;
        }

        const documents = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        //  Remove duplicates by id
        let uniqueDocuments = documents.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        );

        // Filter out if odometer > 150000
        uniqueDocuments = uniqueDocuments.filter(
          (doc) => doc.newData.odometer <= 150000
        );

        // Filter dateDelisted is not null
        uniqueDocuments = uniqueDocuments.filter(
          (doc) => doc.newData.dateDelisted === null
        );

        console.log("Unique documents", uniqueDocuments.length);
        setSearchData(uniqueDocuments);

        setLoadingLogs(false);
        setForceResetSearchToInitialConditions(false);
      });
  }, [daysToLoadConst, forceResetSearchToInitialConditions]);
  // }, []);

  // Filter data based on days on market
  useEffect(() => {
    if (searchData.length === 0) return;
    // if (searchData.length > 0 && filteredSearchData.length > 0 && !streamData) {
    //   // Search data already contains information and filtered data is not empty so assume that the first load has occurred and dont continue to stream
    //   return;
    // }
    const filteredData = searchData.filter((doc) => {
      // return doc.newData.daysOnMarket < daysOnMarketConst;
      return true;
    });

    // Order by days on market
    filteredData.sort((a, b) => {
      return a.newData.daysOnMarket - b.newData.daysOnMarket;
    });

    // Order by createdDate
    filteredData.sort((a, b) => {
      return b.createdDate - a.createdDate;
    });

    console.log("filteredData", filteredData.length);
    setFilteredSearchData(filteredData);
  }, [daysOnMarketConst, searchData, streamData]);

  useEffect(() => {
    // Get vehicles that have been recently searched for and have changes
    // delisted, price change
    if (!forceResetSearchToInitialConditions && searchData.length > 0) return;

    const collectionName = "competitive-set-ag-logs";
    const daysToLoad = dayjs().subtract(daysToLoadConst, "days").format("X");
    console.log("Loading change logs from", daysToLoad, collectionName);

    setLoadingLogs(true);
    setSearchDataRecentChanges([]);
    firestore
      .collection(collectionName)
      .where("createdDate", ">=", parseInt(daysToLoad))
      // .where("newData.daysOnMarket", "<", daysToLoadConst)

      .where("logType", "==", "update-record")
      .where("oldData.sellerType", "==", "P")
      .where("changedFields", "array-contains-any", [
        "advertisedPrice",
        "dateDelisted",
      ])
      .get()
      .then((snapshot) => {
        // .onSnapshot((snapshot) => {
        console.log("snapshot size", snapshot.size);
        if (snapshot.size === 0) {
          toast.info(
            `No new records found in the last ${daysToLoadConst} days`
          );
          setFilteredSearchDataRecentChanges([]);
          setLoadingLogs(false);
          return;
        }

        let documents = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Remove odometer > 150000
        documents = documents.filter((doc) => doc.oldData.odometer <= 150000);

        setSearchDataRecentChanges(documents);

        console.log("changes", documents.length);

        setLoadingLogs(false);
        setForceResetSearchToInitialConditions(false);
      });
  }, [daysToLoadConst, forceResetSearchToInitialConditions]);
  // }, []);

  // Search for vehicles via the search form that have been recently added to the database
  useEffect(() => {
    if (targetedSearchResults.make === "") {
      console.log("No targeted search results");
      return;
    }
    const collectionName = "competitive-set-ag-logs";
    const daysToLoad = dayjs().subtract(200, "days").format("X");

    console.log(
      "Loading targeted search results from",
      daysToLoad,
      collectionName
    );

    setLoadingLogs(true);
    // Clear vehicle data set
    setSearchData([]);
    setActiveCard(null);
    setActiveCardSimilars([]);
    handleClose();
    firestore
      .collection(collectionName)
      .where("createdDate", ">=", parseInt(daysToLoad))
      .where(
        "newData.specData.make",
        "==",
        targetedSearchResults.make.toUpperCase()
      )
      .where(
        "newData.specData.model",
        "==",
        targetedSearchResults.model.toUpperCase()
      )
      .where(
        "newData.specData.year",
        "==",
        parseInt(targetedSearchResults.year)
      )
      // .where(
      //   "newData.specData.description",
      //   "==",
      //   targetedSearchResults.description
      // )
      // .where(
      //   "newData.specData.vehicleCode",
      //   "==",
      //   targetedSearchResults.vehicleCode
      // )
      .where("logType", "==", "create-record")
      .where("newData.sellerType", "==", "P")
      .get()
      .then((snapshot) => {
        // .onSnapshot((snapshot) => {
        console.log("ts - snapshot size", snapshot.size);
        if (snapshot.size === 0) {
          toast.warning(`No new records found within search paramaters`);
          setFilteredSearchData([]);
          setLoadingLogs(false);

          return;
        }

        const documents = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        //  Remove duplicates by id
        let uniqueDocuments = documents.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        );

        // Filter out if odometer > 150000
        uniqueDocuments = uniqueDocuments.filter(
          (doc) => doc.newData.odometer <= 150000
        );

        // Filter dateDelisted is not null
        uniqueDocuments = uniqueDocuments.filter(
          (doc) => doc.newData.dateDelisted === null
        );

        console.log("ts - Unique documents", uniqueDocuments.length);
        setSearchData(uniqueDocuments);
        setLoadingLogs(false);
      });
  }, [JSON.stringify(targetedSearchResults)]);

  // Search for vehicles via the search form that have been recently added to the database
  // With price changes and delistings as a change field
  useEffect(() => {
    if (targetedSearchResults.make === "") {
      console.log("No targeted search results");
      return;
    }
    const collectionName = "competitive-set-ag-logs";
    const daysToLoad = dayjs().subtract(200, "days").format("X");

    console.log(
      "Loading targeted search results from",
      daysToLoad,
      collectionName
    );

    setLoadingLogs(true);
    setSearchDataRecentChanges([]);
    firestore
      .collection(collectionName)
      .where("logType", "==", "update-record")
      .where("createdDate", ">=", parseInt(daysToLoad))
      .where("oldData.sellerType", "==", "P")
      .where("changedFields", "array-contains-any", [
        "advertisedPrice",
        "dateDelisted",
      ])
      .where(
        "oldData.specData.make",
        "==",
        targetedSearchResults.make.toUpperCase()
      )
      .where(
        "oldData.specData.model",
        "==",
        targetedSearchResults.model.toUpperCase()
      )
      .where(
        "oldData.specData.year",
        "==",
        parseInt(targetedSearchResults.year)
      )
      // .where(
      //   "newData.specData.description",
      //   "==",
      //   targetedSearchResults.description
      // )
      // .where(
      //   "newData.specData.vehicleCode",
      //   "==",
      //   targetedSearchResults.vehicleCode
      // )
      .get()
      .then((snapshot) => {
        // .onSnapshot((snapshot) => {
        console.log("ts changes - snapshot size", snapshot.size);
        if (snapshot.size === 0) {
          toast.warning(
            `No price change records found within search paramaters`
          );
          setLoadingLogs(false);
          setFilteredSearchDataRecentChanges([]);
          return;
        }

        let documents = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Remove odometer > 150000
        documents = documents.filter((doc) => doc.oldData.odometer <= 150000);

        setSearchDataRecentChanges(documents);

        console.log("ts recent changes", documents.length);

        setLoadingLogs(false);
        setForceResetSearchToInitialConditions(false);
      });
  }, [JSON.stringify(targetedSearchResults)]);

  useEffect(() => {
    if (searchDataRecentChanges.length === 0) return;
    const filteredData = [...searchDataRecentChanges];

    // // Order by days on market
    // filteredData.sort((a, b) => {
    //   return a.oldData.daysOnMarket - b.oldData.daysOnMarket;
    // });

    // // Order by createdDate
    filteredData.sort((a, b) => {
      return b.createdDate - a.createdDate;
    });

    // // Order by id
    // filteredData.sort((a, b) => {
    //   // String comparison
    //   return a.id.localeCompare(b.id);
    // });

    // Order by change in price
    // filteredData.sort((a, b) => {
    //   // Check for undefined
    //   if (
    //     typeof a.changedFieldsDataOld === "undefined" ||
    //     typeof a.changedFieldsDataNew === "undefined" ||
    //     typeof b.changedFieldsDataOld === "undefined" ||
    //     typeof b.changedFieldsDataNew === "undefined"
    //   )
    //     return 0;
    //   if (
    //     typeof a.changedFieldsDataOld.advertisedPrice === "undefined" ||
    //     typeof a.changedFieldsDataNew.advertisedPrice === "undefined" ||
    //     typeof b.changedFieldsDataOld.advertisedPrice === "undefined" ||
    //     typeof b.changedFieldsDataNew.advertisedPrice === "undefined"
    //   )
    //     return 0;

    //   return a.changedFieldsDataOld.advertisedPrice -
    //     a.changedFieldsDataNew.advertisedPrice -
    //     b.changedFieldsDataOld.advertisedPrice -
    //     b.changedFieldsDataNew.advertisedPrice
    //     ? 1
    //     : -1;
    // });

    console.log("filteredData", filteredData.length);
    setFilteredSearchDataRecentChanges(filteredData);
  }, [searchDataRecentChanges]);

  useEffect(() => {
    if (activeCard === null) return;
    getListedCarsCS(
      activeCard?.specData?.make,
      activeCard?.specData?.model,
      activeCard?.specData?.year,
      activeCard?.specData?.description,
      activeCard?.specData?.vehicleCode
    );
    getRecentlySoldCars(
      activeCard?.specData?.make,
      activeCard?.specData?.model,
      activeCard?.specData?.year
    );
    getRecentlySoldCarsCS(
      activeCard?.specData?.make,
      activeCard?.specData?.model,
      activeCard?.specData?.year,
      activeCard?.specData?.description
    );
    getCarsInStock(
      activeCard?.specData?.make,
      activeCard?.specData?.model,
      activeCard?.specData?.year
    );
  }, [JSON.stringify(activeCard)]);

  // useEffect(() => {
  //   calculatePricingGraphData();
  // }, [
  //   activeCard,
  //   currentIndex,
  //   recentlySoldCarsCSForActiveCard,
  //   recentlySoldCarsForActiveCard,
  //   carsInStock,
  // ]);

  // Load Graph Data for Scatter Plot
  useEffect(() => {
    console.log("Getting graph data");
    if (activeCard) {
      getCarsCS(
        activeCard?.specData?.make,
        activeCard?.specData?.model,
        activeCard?.specData?.year
      );
    }
  }, [activeCard]);

  // Update filtered list when vehicle select dropdown changes
  useEffect(() => {
    if (dropdownVehileFilters.make === "") return;
    console.log("Dropdown filters", dropdownVehileFilters);
    const filteredData = searchData.filter((doc) => {
      return (
        doc.newData.specData.make === dropdownVehileFilters.make &&
        doc.newData.specData.model === dropdownVehileFilters.model
        // doc.newData.specData.year === dropdownVehileFilters.year &&
        // doc.newData.specData.description === dropdownVehileFilters.description
      );
    });

    // // Order by days on market
    // filteredData.sort((a, b) => {
    //   return a.newData.daysOnMarket - b.newData.daysOnMarket;
    // });

    // // Order by createdDate
    // filteredData.sort((a, b) => {
    //   return b.createdDate - a.createdDate;
    // });

    console.log("dd filter nlv", filteredData.length);
    setFilteredSearchData(filteredData);

    // Set recent change data
    const filteredDataRecentChanges = searchDataRecentChanges.filter((doc) => {
      return (
        doc.oldData.specData.make === dropdownVehileFilters.make &&
        doc.oldData.specData.model === dropdownVehileFilters.model
        // doc.oldData.specData.year === dropdownVehileFilters.year &&
        // doc.oldData.specData.description === dropdownVehileFilters.description
      );
    });

    // // Order by days on market
    // filteredDataRecentChanges.sort((a, b) => {
    //   return a.oldData.daysOnMarket - b.oldData.daysOnMarket;
    // });

    // // Order by createdDate
    // filteredDataRecentChanges.sort((a, b) => {
    //   return b.createdDate - a.createdDate;
    // });

    console.log("dd filter pcv", filteredDataRecentChanges.length);
    setFilteredSearchDataRecentChanges(filteredDataRecentChanges);
  }, [JSON.stringify(dropdownVehileFilters)]);

  return (
    <>
      <ToastContainer />
      {/* A floating action button using div and styles */}

      <CreateSearchTicket />
      <Card
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          zIndex: 9999,
          display: "none",
        }}
      >
        <Card.Body>
          <Row>
            <Col>
              <h6>Admin filters</h6>
            </Col>
          </Row>
          <Row>
            <Col>CRD</Col>
            <Col>
              <ButtonGroup>
                <Button
                  disabled={isLoadingLogs}
                  size="sm"
                  variant={
                    daysToLoadConst === 2 ? "primary" : "outline-secondary"
                  }
                  onClick={() => setDaysToLoadConst(2)}
                >
                  2
                </Button>
                <Button
                  size="sm"
                  // disabled
                  variant={
                    daysToLoadConst === 7 ? "primary" : "outline-secondary"
                  }
                  onClick={() => setDaysToLoadConst(7)}
                >
                  7
                </Button>
                <Button
                  size="sm"
                  // disabled
                  variant={
                    daysToLoadConst === 14 ? "primary" : "outline-secondary"
                  }
                  onClick={() => setDaysToLoadConst(14)}
                >
                  14
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col>DOM</Col>
            <Col>
              <ButtonGroup>
                <Button
                  disabled={isLoadingLogs}
                  size="sm"
                  variant={
                    daysOnMarketConst === 2 ? "primary" : "outline-secondary"
                  }
                  onClick={() => setDaysOnMarketConst(2)}
                >
                  2
                </Button>
                <Button
                  size="sm"
                  disabled={isLoadingLogs}
                  variant={
                    daysOnMarketConst === 7 ? "primary" : "outline-secondary"
                  }
                  onClick={() => setDaysOnMarketConst(7)}
                >
                  7
                </Button>
                <Button
                  size="sm"
                  disabled={isLoadingLogs}
                  variant={
                    daysOnMarketConst === 14 ? "primary" : "outline-secondary"
                  }
                  onClick={() => setDaysOnMarketConst(14)}
                >
                  14
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col>SCSI</Col>
            <Col>
              <ButtonGroup>
                <Button
                  size="sm"
                  variant={showCSImages ? "primary" : "outline-secondary"}
                  onClick={toggleShowCSImages}
                >
                  {showCSImages ? "Hide" : "Show"}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col>Stream</Col>
            <Col>
              <ButtonGroup>
                <Button
                  size="sm"
                  variant={streamData ? "primary" : "outline-secondary"}
                  onClick={toggleStreamData}
                >
                  {streamData ? "Pause" : "Start"}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          Sourcing Intelligence Dashboard (SID){" "}
          {isLoadingLogs ? <Spinner size="sm" animation="border" /> : null}{" "}
        </Card.Header>
        <Card.Body>
          <Row style={{ marginBottom: 15, marginTop: 15 }}>
            <Col xs={6}>
              <h6
                style={{
                  backgroundColor: stickyTableHeaderStyle.backgroundColor,
                  color: stickyTableHeaderStyle.color,
                  margin: 0,
                  padding: 5,
                  border: 0,
                }}
              >
                Search for specific vehicles
                {/* <sub>
                  <i>(dropdown only shows available data)</i>
                </sub> */}
              </h6>
              <VehicleSelectDropdownComponent
                make=""
                model=""
                year=""
                description=""
                makeModelList={makeModelList}
                handleSelect={handleVehicleSelect}
                toast={toast}
                targetedSearchResults={targetedSearchResults}
                resetSearchToInitialConditions={resetSearchToInitialConditions}
              />
            </Col>
            <Col xs={6}>
              <Row style={{ height: 300, overflow: "scroll" }}>
                <Col>
                  <MyRecentList />
                </Col>
              </Row>
            </Col>
          </Row>
          <Tabs defaultActiveKey="nlv" id="noanim-tab-example">
            <Tab
              eventKey="nlv"
              title={
                <span>
                  Newly listed vehicles{" "}
                  <Badge variant="secondary">{filteredSearchData.length}</Badge>
                </span>
              }
            >
              <Row style={{ height: 300, overflow: "scroll", marginTop: 15 }}>
                <Col>
                  <h6
                    style={{
                      backgroundColor: stickyTableHeaderStyle.backgroundColor,
                      color: stickyTableHeaderStyle.color,
                      margin: 0,
                      padding: 5,
                      border: 0,
                    }}
                  >
                    Private listings {`(showing vehicles < 150,000 kms)`}{" "}
                    {isLoadingLogs ? (
                      <Spinner size="sm" animation="border" />
                    ) : null}{" "}
                  </h6>
                  <Table striped bordered hover size="sm">
                    <thead
                      style={{
                        ...stickyTableHeaderStyle,
                      }}
                    >
                      <tr>
                        <th>Source</th>
                        <th>
                          Description{" "}
                          <i>(click to load detailed intelligence</i>)
                        </th>
                        <th>Kms</th>
                        <th>Price</th>
                        <th>Days on market*</th>
                        <th>Views</th>
                        <th>Leads</th>
                        <th>Watches</th>
                        <th>State</th>
                        <th>Last updated</th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {filteredSearchData?.length === 0 && (
                        <tr>
                          <td colSpan={10}>No results found</td>
                        </tr>
                      )}
                      {filteredSearchData?.map((document, idx) => {
                        const doc = document.newData;
                        const isRowSelected =
                          activeCard?.sourceId === document.id;

                        return (
                          <tr
                            key={idx}
                            style={{
                              border: isRowSelected
                                ? "2px solid #ff6b00"
                                : null,
                            }}
                          >
                            <td>
                              <Badge
                                variant="secondary"
                                style={{
                                  padding: 5,
                                  backgroundColor: "#007cc5",
                                }}
                              >
                                CS - {document.id.split("-").pop()}
                              </Badge>
                            </td>
                            <td
                              style={{ textAlign: "left", cursor: "pointer" }}
                              onClick={() => {
                                loadAdFromDBBySourceId(document.id);
                                handleShow();
                              }}
                            >
                              {`${doc.specData.year} ${doc.specData.make} ${doc.specData.model} ${doc.specData.description}`}
                              {doc.dateDelisted !== null ? (
                                <Badge variant="danger"> Delisted</Badge>
                              ) : null}
                            </td>
                            <td>
                              <NumberFormat
                                value={doc.odometer}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={doc.advertisedPrice}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                            <td>{doc.daysOnMarket}</td>
                            <td>{doc.views}</td>
                            <td>{doc.leads}</td>
                            <td>{doc.watches}</td>
                            <td>{doc.region}</td>
                            <td>
                              {dayjs.unix(document.createdDate).fromNow()}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={10} style={{ fontSize: 10 }}>
                          *Days on market is the number of days the vehicle has
                          been listed for sale as of the last update date
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey="pcv"
              title={
                <span>
                  Price changed vehicles{" "}
                  <Badge variant="secondary">
                    {filteredSearchDataRecentChanges?.length}
                  </Badge>
                </span>
              }
            >
              <Row style={{ height: 300, overflow: "scroll", marginTop: 15 }}>
                <Col>
                  <h6
                    style={{
                      backgroundColor: stickyTableHeaderStyle.backgroundColor,
                      color: stickyTableHeaderStyle.color,
                      margin: 0,
                      padding: 5,
                      border: 0,
                    }}
                  >
                    Private listings
                  </h6>
                  <Table striped bordered hover size="sm">
                    <thead
                      style={{
                        ...stickyTableHeaderStyle,
                      }}
                    >
                      <tr>
                        <th>Source</th>
                        <th>
                          Description{" "}
                          <i>(click to load detailed intelligence</i>)
                        </th>
                        <th>Changed date</th>
                        <th>Change $</th>
                        <th>Change From</th>
                        <th>Change To</th>
                        <th>Days on market</th>
                        <th>Views</th>
                        <th>Leads</th>
                        <th>Watches</th>
                        <th>State</th>
                        <th>Last updated</th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {filteredSearchDataRecentChanges?.length === 0 && (
                        <tr>
                          <td colSpan={10}>No results found</td>
                        </tr>
                      )}
                      {filteredSearchDataRecentChanges?.map((document, idx) => {
                        const doc = document.oldData;
                        const isRowSelected =
                          activeCard?.sourceId === document.id;

                        return (
                          <tr
                            key={idx}
                            style={{
                              border: isRowSelected
                                ? "2px solid #ff6b00"
                                : null,
                            }}
                          >
                            <td>
                              <Badge
                                variant="secondary"
                                style={{
                                  padding: 5,
                                  backgroundColor: "#007cc5",
                                }}
                              >
                                CS - {document.id.split("-").pop()}
                              </Badge>
                            </td>
                            <td
                              style={{ textAlign: "left", cursor: "pointer" }}
                              onClick={() => {
                                loadAdFromDBBySourceId(document.id);
                                handleShow();
                              }}
                            >
                              {`${doc.specData.year} ${doc.specData.make} ${doc.specData.model} ${doc.specData.description}`}
                              {"  "}
                              {document.changedFields.find(
                                (field) => field === "dateDelisted"
                              ) && (
                                <Badge variant="danger">
                                  Delisted{" "}
                                  {dayjs(
                                    document.changedFieldsDataNew[
                                      "dateDelisted"
                                    ]
                                  ).format("DD/MM/YYYY")}
                                </Badge>
                              )}
                            </td>
                            <td>
                              {dayjs.unix(document.createdDate).fromNow()}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {/* Up/down arrows based on change $ */}
                              {parseInt(
                                document.changedFieldsDataOld["advertisedPrice"]
                              ) >=
                              parseInt(
                                document.changedFieldsDataNew["advertisedPrice"]
                              ) ? (
                                <span style={{ color: "green" }}>
                                  &#8595;
                                  <NumberFormat
                                    value={
                                      document.changedFieldsDataOld[
                                        "advertisedPrice"
                                      ] -
                                      document.changedFieldsDataNew[
                                        "advertisedPrice"
                                      ]
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </span>
                              ) : (
                                <span style={{ color: "red" }}>
                                  {document.changedFields.find(
                                    (field) => field === "dateDelisted"
                                  ) ? (
                                    ""
                                  ) : (
                                    <>
                                      &#8593;
                                      <NumberFormat
                                        value={
                                          document.changedFieldsDataOld[
                                            "advertisedPrice"
                                          ] -
                                          document.changedFieldsDataNew[
                                            "advertisedPrice"
                                          ]
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                      />
                                    </>
                                  )}
                                </span>
                              )}
                            </td>
                            <td>
                              <NumberFormat
                                value={
                                  document.changedFieldsDataOld[
                                    "advertisedPrice"
                                  ]
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={
                                  document.changedFieldsDataNew[
                                    "advertisedPrice"
                                  ]
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                            <td>{doc.daysOnMarket}</td>
                            <td>{doc.views}</td>
                            <td>{doc.leads}</td>
                            <td>{doc.watches}</td>
                            <td>{doc.region}</td>
                            <td>
                              {dayjs.unix(document.createdDate).fromNow()}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
      <Card
        style={{ display: showDetails, position: "relative", marginTop: 15 }}
      >
        <Card.Header>
          Detailed Insights for {activeCard?.specData?.make}{" "}
          {activeCard?.specData?.model} {activeCard?.specData?.year}{" "}
          {activeCard?.specData?.description}
        </Card.Header>
        {/* <Row>
          <ButtonGroup style={{ display: showDetails }}>
            <Button
              variant={limitToLoad === 20 ? "primary" : "outline-primary"}
              onClick={handleDecrementCurrentIndex}
            >
              Previous
            </Button>
            <Button
              variant={limitToLoad === 50 ? "primary" : "outline-primary"}
              onClick={handleIncrementCurrentIndex}
            >
              Next
            </Button>
          </ButtonGroup>
        </Row> */}
        <Card.Body>
          <Row>
            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
              <h6
                style={{
                  backgroundColor: stickyTableHeaderStyle.backgroundColor,
                  color: stickyTableHeaderStyle.color,
                  margin: 0,
                  padding: 5,
                  border: 0,
                }}
              >
                Private listings
              </h6>
              <Row>
                <Col>
                  <h6
                    style={{
                      backgroundColor: "#ff6b00",
                      color: "black",
                      margin: 0,
                      padding: 5,
                      border: "2px solid #ff6b00",
                      opacity: 0.8,
                    }}
                  >
                    Selected listing
                  </h6>
                  <BuyingCardDecision card={activeCard} active={true} />
                </Col>
              </Row>
              <Row
                style={{
                  height: 680,
                  overflowY: "scroll",
                  marginTop: 30,
                }}
              >
                <Col>
                  <h6
                    style={{
                      backgroundColor: stickyTableHeaderStyle.backgroundColor,
                      color: stickyTableHeaderStyle.color,
                      margin: 0,
                      padding: 5,
                      border: 0,
                    }}
                  >
                    Private listings (similar vehicles)
                  </h6>
                  {activeCardSimilars.length > 0 &&
                    activeCardSimilars.map((card, idx) => {
                      if (activeCard?.sourceId === card.sourceId) return null;
                      return (
                        <Row key={card.sourceId}>
                          <Col>
                            <BuyingCardDecision card={card} active={false} />
                          </Col>
                        </Row>
                      );
                    })}
                  {isLoadingSimilarCarsCS && (
                    <Row>
                      <Col>
                        <Spinner animation="border" size="sm" />
                      </Col>
                    </Row>
                  )}
                  {activeCardSimilars.length === 0 && (
                    <Row>
                      <Col>
                        <h6>No similar vehicles found</h6>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
              <Row
                style={{
                  height: 600,
                  overflowY: "scroll",
                  border: "1px solid #AEB6BF",
                  marginLeft: 1,
                }}
              >
                <Col>
                  <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    style={{ fontSize: 12, marginTop: 15 }}
                  >
                    <thead style={stickyTableHeaderStyle}>
                      <tr>
                        <th colSpan={3} style={{ fontWeight: "bold" }}>
                          DUTTON RECENTLY SOLD IN (
                          {recentlySoldCarsForActiveCard.length}){" "}
                          {isLoadingRecentlySoldCars && (
                            <Spinner
                              animation="border"
                              size="sm"
                              style={{ color: "white" }}
                            />
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentlySoldCarsForActiveCard
                        ?.sort(
                          (a, b) => b["Sale Date EPOC"] - a["Sale Date EPOC"]
                        )
                        ?.map((car) => {
                          return (
                            <tr key={car.VEHStockNumber}>
                              <td>
                                <DuttonInventoryPhoto
                                  stockNumber={car["Stock No"] || ""}
                                />
                              </td>
                              <td>
                                {car.VEHMake} {car.VEHModel} {car.VEHModelYear}{" "}
                                {car["Variant & Series"]} {car.VEHColour1}
                              </td>
                              <td>
                                <NumberFormat
                                  value={car["SalePriceInc"]}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                                |Age: {car.Age} days |Leads: {car.Leads}
                                |Branch: {car.Division}
                                |Odo:
                                <NumberFormat
                                  value={car.VEHOdoOut}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                |Sold date:{" "}
                                {dayjs
                                  .unix(car["Sale Date EPOC"])
                                  .format("DD/MM/YYYY")}
                                |True profit inc.:{" "}
                                <NumberFormat
                                  value={Math.floor(car.TrueProfit * 1.1)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  style={{
                                    color: car.TrueProfit > 0 ? "green" : "red",
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row
                style={{
                  height: 600,
                  overflowY: "scroll",
                  marginTop: 30,
                  border: "1px solid #AEB6BF",
                  marginLeft: 1,
                }}
              >
                <Col>
                  <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    style={{ fontSize: 12, marginTop: 15 }}
                  >
                    <thead style={stickyTableHeaderStyle}>
                      <tr>
                        <th colSpan={3} style={{ fontWeight: "bold" }}>
                          DUTTON IN STOCK ({carsInStock.length}){" "}
                          {isLoadingInStockCars && (
                            <Spinner
                              animation="border"
                              size="sm"
                              style={{ color: "white" }}
                            />
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {carsInStock?.map((car) => {
                        return (
                          <tr key={car.VEHStockNumber}>
                            <td>
                              <DuttonInventoryPhoto
                                stockNumber={car["VEHStockNumber"] || ""}
                              />
                            </td>
                            <td>
                              {car.VEHMake} {car.VEHModel} {car.VEHModelYear}{" "}
                              {car["Variant & Series"]} {car.VEHColour1}
                            </td>
                            <td>
                              <NumberFormat
                                value={car.TrueProfit}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                              |Age:{" "}
                              {dayjs().diff(
                                dayjs.unix(car?.Purchase_Date?.seconds),
                                "day"
                              )}{" "}
                              days |Leads: {car.CountOfLeads}
                              <br />
                              Branch: {car.branch_desc}
                              |Odo:{" "}
                              <NumberFormat
                                value={car.VEHOdo}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                              <br />
                              Cost price:{" "}
                              <NumberFormat
                                style={{ color: "red" }}
                                value={Math.round(car.Inventory_Amount)}
                                displayType={"text"}
                                prefix={"$"}
                                thousandSeparator={true}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
              <Row
                style={{
                  height: 600,
                  overflowY: "scroll",
                  border: "1px solid #AEB6BF",
                  marginLeft: 1,
                }}
              >
                <Col>
                  <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    style={{ fontSize: 12, marginTop: 15 }}
                  >
                    <thead style={stickyTableHeaderStyle}>
                      <tr>
                        <th colSpan={3} style={{ fontWeight: "bold" }}>
                          DEALER RECENTLY DELISTED/SOLD (
                          {
                            recentlySoldCarsCSForActiveCard
                              ?.filter(
                                (listing) => listing.dateDelisted !== null
                              )
                              ?.filter((car) => {
                                const daysDelisted = dayjs().diff(
                                  dayjs(car.dateDelisted),
                                  "days"
                                );
                                return daysDelisted < 90;
                              })?.length
                          }
                          ){" "}
                          {isLoadingRecentlySoldCarsCS && (
                            <Spinner
                              animation="border"
                              size="sm"
                              style={{ color: "white" }}
                            />
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentlySoldCarsCSForActiveCard
                        ?.filter((car) => car.dateDelisted !== null)
                        // Filter for daysDelisted < 90 days
                        ?.filter((car) => {
                          const daysDelisted = dayjs().diff(
                            dayjs(car.dateDelisted),
                            "days"
                          );
                          return daysDelisted < 90;
                        })
                        ?.map((car) => {
                          const soldStatus =
                            car.dateDelisted === null ? "Active" : "Sold";
                          const soldStatusColor =
                            car.dateDelisted === null ? "green" : "red";
                          return (
                            // <tr style={{ backgroundColor: soldStatusColor }}>
                            <tr key={`${car.id}_dealer_delisted`}>
                              <td style={{ width: 50 }}>
                                {showCSImages && (
                                  <Image
                                    // className="d-block w-100"
                                    style={{
                                      alignItems: "center",
                                      contain: "contain",
                                    }}
                                    src={
                                      car?.photos?.length > 0 &&
                                      `${car?.photos[0]?.resourcePath}${carsalesImageSuffix}`
                                    }
                                    alt="First slide"
                                    height={75}
                                  />
                                )}
                                {/* <Carousel
                                  infinite={true}
                                  showDots={true}
                                  responsive={responsive}
                                >
                                  {car.photos?.map((photo) => {
                                    return (
                                      <div>
                                        <Image
                                          // className="d-block w-100"
                                          style={{
                                            alignItems: "center",
                                            contain: "contain",
                                          }}
                                          src={photo.resourcePath}
                                          alt="First slide"
                                          height={75}
                                        />
                                      </div>
                                    );
                                  })}
                                </Carousel> */}
                              </td>
                              <td>
                                {car.specData?.make} {car.specData?.model}{" "}
                                {car.specData?.year} {car.specData?.description}{" "}
                                {car.colour}
                              </td>
                              <td>
                                <NumberFormat
                                  value={car.advertisedPrice}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                                |{car.daysOnMarket} days |Leads: {car.leads}
                                |Views: {car.views} |Watches: {car.watches}
                                <b>
                                  {" "}
                                  |Dealer: {car.dealerName}|
                                  {car.sellerType === "F"
                                    ? "Franchise"
                                    : "Independent"}{" "}
                                </b>
                                |{car.state}
                                |Odo:
                                <NumberFormat
                                  value={car.odometer}
                                  thousandSeparator={true}
                                  displayType="text"
                                />
                                |Delisted:{" "}
                                {car.dateDelisted === null
                                  ? "In-Stock"
                                  : dayjs(car.dateDelisted).format(
                                      "DD/MM/YYYY"
                                    )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row
                style={{
                  height: 600,
                  overflowY: "scroll",
                  marginTop: 30,
                  marginLeft: 1,
                  border: "1px solid #AEB6BF",
                }}
              >
                <Col>
                  <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    style={{ fontSize: 12, marginTop: 15 }}
                  >
                    <thead style={stickyTableHeaderStyle}>
                      <tr>
                        <th colSpan={3} style={{ fontWeight: "bold" }}>
                          DEALER IN STOCK (Active listings) (
                          {
                            recentlySoldCarsCSForActiveCard?.filter(
                              (listing) => listing.dateDelisted === null
                            )?.length
                          }
                          ){" "}
                          {isLoadingRecentlySoldCarsCS && (
                            <Spinner
                              animation="border"
                              size="sm"
                              style={{ color: "white" }}
                            />
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentlySoldCarsCSForActiveCard
                        ?.filter((car) => car.dateDelisted === null)

                        ?.map((car) => {
                          const soldStatus =
                            car.dateDelisted === null ? "Active" : "Sold";
                          const soldStatusColor =
                            car.dateDelisted === null ? "green" : "red";
                          return (
                            // <tr style={{ backgroundColor: soldStatusColor }}>
                            <tr key={`${car.id}_dealer_in_stock`}>
                              <td style={{ width: 50 }}>
                                {showCSImages && (
                                  <Image
                                    // className="d-block w-100"
                                    style={{
                                      alignItems: "center",
                                      contain: "contain",
                                    }}
                                    src={
                                      car?.photos?.length > 0 &&
                                      `${car?.photos[0]?.resourcePath}${carsalesImageSuffix}`
                                    }
                                    alt="First slide"
                                    height={75}
                                  />
                                )}
                                {/* <Carousel
                          infinite={true}
                          showDots={true}
                          responsive={responsive}
                        >
                          {car.photos?.map((photo) => {
                            return (
                              <div>
                                <Image
                                  // className="d-block w-100"
                                  style={{
                                    alignItems: "center",
                                    contain: "contain",
                                  }}
                                  src={photo.resourcePath}
                                  alt="First slide"
                                  height={75}
                                />
                              </div>
                            );
                          })}
                        </Carousel> */}
                              </td>
                              <td>
                                {car.specData?.make} {car.specData?.model}{" "}
                                {car.specData?.year} {car.specData?.description}{" "}
                                {car.colour}
                              </td>
                              <td>
                                <NumberFormat
                                  value={car.advertisedPrice}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                                |{car.daysOnMarket} days |Leads: {car.leads}
                                |Views: {car.views} |Watches: {car.watches}
                                <b>
                                  {" "}
                                  |Dealer: {car.dealerName}|
                                  {car.sellerType === "F"
                                    ? "Franchise"
                                    : "Independent"}{" "}
                                </b>
                                |{car.state}
                                |Odo:
                                <NumberFormat
                                  value={car.odometer}
                                  thousandSeparator={true}
                                  displayType="text"
                                />
                                |Delisted:{" "}
                                {car.dateDelisted === null
                                  ? "In-Stock"
                                  : dayjs(car.dateDelisted).format(
                                      "DD/MM/YYYY"
                                    )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Clear selected
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Card.Footer>
      </Card>
      <h6 style={{ display: activeCard ? "block" : "none", marginTop: 15 }}>
        {`Kms vs Adv Price for ${activeCard?.specData?.make} ${activeCard?.specData?.model} ${activeCard?.specData?.year}`}
      </h6>
      <ResponsiveContainer width="100%" height={400}>
        <ScatterChart
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          style={{ display: activeCard ? "block" : "none" }}
        >
          <CartesianGrid />
          <XAxis type="number" dataKey="kms" name="kms" unit="km" />
          <YAxis type="number" dataKey="price" name="price" unit="$" />
          <Tooltip cursor={{ strokeDasharray: "3 3" }} />
          <Legend verticalAlign="top" height={36} />
          <Scatter
            name="Dealer"
            data={kmsVsPriceGraphData.filter((r) => r.sellerType !== "P")}
            fill="#8884d8"
          />
          <Scatter
            name="Private"
            data={kmsVsPriceGraphData.filter((r) => r.sellerType === "P")}
            fill="#82ca9d"
          />
        </ScatterChart>
      </ResponsiveContainer>
    </>
  );
};

export default DABuyingBoard;
