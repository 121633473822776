import Board from "react-trello";
import React, { useState, useEffect, useRef } from "react";
import useFirestoreQuery from "../../hooks/useFirestoreQuery";

import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const refreshRateInMs = 5 * 60 * 1000;
const laneBase = require("../../assets/json/lane_base_d1_qld.json");

const ReconStockLocationD1QLD = () => {
  const [reconTrello, setReconTrello] = useState(laneBase);
  const [nextRefresh, setNextRefresh] = useState(refreshRateInMs / 1000);

  const boardRef = useRef(null);
  const history = useHistory();

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const { data, status, error } = useFirestoreQuery(
    firestore
      .collection("vehicle-health-d1-qld")
      .where("CurrentStatus", "==", "IN-STOCK")
  );

  const boardScroll = (scrollOffset) => {
    const br = document.getElementsByClassName("react-trello-board")[0];
    br.scrollLeft += scrollOffset;
  };

  const boardScrollMax = (direction) => {
    const br = document.getElementsByClassName("react-trello-board")[0];
    if (direction > 0) {
      // Right
      br.scrollLeft = br.scrollWidth;
    } else {
      // Left
      br.scrollLeft = 0;
    }
  };

  //   Transform to trello board
  useEffect(() => {
    if (data) {
      //   clear all cards
      let tmpLaneArray = laneBase.lanes.map((l) => {
        l.cards = [];
        if (l.category !== "0") {
          l.style = {
            backgroundColor: laneBase?.categoryColours[l.category].laneBgColour,
            color: laneBase?.categoryColours[l.category].laneFontColour,
          };
        }
        return l;
      });

      // Filter active only
      tmpLaneArray = tmpLaneArray.filter((l) => l.active);

      // Sort by category
      tmpLaneArray = tmpLaneArray.sort((a, b) => a.categoryId - b.categoryId);

      // Get list of yards
      for (var i = 0; i < data.length; i++) {
        const veh = data[i];

        // console.log(veh);
        const daysInYard = Math.ceil(
          (dayjs().unix() - dayjs.unix(veh.Yard_Change_Date_EPOC) / 1000) /
            60 /
            60 /
            24
        );

        // Show branch if in Retail branches
        let currentBranch = "";
        if (veh.Branch !== "AMW - BUYING/RECONDITIONING") {
          currentBranch = veh.Branch.replace("AMW - ", "");
        }

        let tmpCard = {
          id: `${veh.VEHStockNumber}`,
          title: `${veh.VEHMake} ${veh.VEHmodel}`,
          description: `${veh.VEHStockNumber} ${currentBranch} ${
            veh.Yard_Change_Date_EPOC
              ? ": " +
                dayjs.unix(veh.Yard_Change_Date_EPOC).format("DD/MM") +
                " - " +
                daysInYard
              : ""
          }`,
          label: `${veh.Age}`,
          draggable: false,
          metadata: {
            veh,
          },
          // Add tags when yard logs are added to show progress
          tags: [],
        };

        // Get active yards
        const activeYards = laneBase.lanes.filter((l) => l.active);

        // Order yard logs by date changed
        // Order by date
        veh.yardLogs = veh.yardLogs?.sort((a, b) => {
          return a.Yard_Change_Date_EPOC - b.Yard_Change_Date_EPOC;
        });

        for (var j = 0; j < veh?.yardLogs?.length; j++) {
          const log = veh.yardLogs[j];
          let logNext = null;
          if (j < veh?.yardLogs?.length - 1) {
            logNext = veh.yardLogs[j + 1];
          }

          const isReconYard = activeYards.find((y) => y.id === log.VEHYard);

          if (isReconYard) {
            // Don't add tag for yard log for the current yard
            // Add tag if same yard as current yard but different time stamp (went into yard previously)
            if (
              log.VEHYard?.toLowerCase() !== veh.VEHYard?.toLowerCase() ||
              log.VEHYard?.length === 0 ||
              (log.VEHYard?.toLowerCase() === veh.VEHYard?.toLowerCase() &&
                log.Yard_Change_Date_EPOC !== veh.Yard_Change_Date_EPOC)
              //  && log.VEHYard !== "AMWRecon"
            ) {
              let daysBetweenYards = "";
              if (logNext !== null) {
                daysBetweenYards = Math.ceil(
                  (logNext.Yard_Change_Date_EPOC - log.Yard_Change_Date_EPOC) /
                    60 /
                    60 /
                    24
                );
              }

              const title = `${log.VEHYard} (${daysBetweenYards})`;

              let tmpTag = {
                bgcolor:
                  laneBase.categoryColours[isReconYard.category]?.tagBgColour,
                color:
                  laneBase.categoryColours[isReconYard.category]?.tagFontColour,
                title: title,
              };

              tmpCard.tags.push(tmpTag);
            }
          }
        }

        const laneIndex = tmpLaneArray.findIndex(
          (l) => l.id === veh.VEHYard?.replace(/^\s+|\s+$/gm, "")
        );

        tmpLaneArray[laneIndex]?.cards.push(tmpCard);
      }

      // Set lane labels with count
      tmpLaneArray = tmpLaneArray.map((l) => {
        return { ...l, label: `${l.cards.length}` };
      });
      setReconTrello({ lanes: tmpLaneArray });
    }
  }, [JSON.stringify(data)]);

  // // Data refresh
  // useEffect(() => {
  //   let interval;

  //   const updateCounter = () => {
  //     setNextRefresh((currentValue) => currentValue - 1);
  //   };

  //   interval = setInterval(() => {
  //     console.log("Refreshing");
  //     updateCounter();
  //   }, refreshRateInMs);

  //   return () => {
  //     // Clear the interval when component is unmounted
  //     clearInterval(interval);
  //   };
  // }, []);

  return (
    <>
      <ToastContainer />

      {/* <Button onClick={() => boardScroll(200)}>Scroll</Button> */}

      <Row>
        <Col>
          <ButtonGroup
            style={{
              // position: "relative",
              zIndex: 9999,
              marginBottom: 10,
              // top: 0,
              // left: window.innerHeight,
            }}
          >
            <Button onClick={() => boardScrollMax(-1)}>&lt;&lt;</Button>
            <Button onClick={() => boardScroll(-300)}>&lt;</Button>
            <Button disabled>Scroll</Button>
            <Button onClick={() => boardScroll(300)}>&gt;</Button>
            <Button onClick={() => boardScrollMax(1)}>&gt;&gt;</Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Refreshes every {refreshRateInMs / 1000 / 60} minutes</h6>
        </Col>
      </Row>
      <Board
        style={{ backgroundColor: "#E9F7EF", scrollBehavior: "smooth" }}
        onCardClick={(cardId, metadata, laneId) => {
          // console.log(metadata);
          window.open(`/vin/${metadata?.veh?.VEHVIN}`, "_blank");
          // history.push(`/vin/${metadata?.veh?.VEHVIN}`);
        }}
        data={reconTrello}
      />
    </>
  );
};

export default ReconStockLocationD1QLD;
