import { useEffect } from "react";
import { useState } from "react";

const VEMake = require("../assets/json/VEMake.json");
const VEModel = require("../assets/json/VEFamily.json");

export function useVEHMakeModel() {
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);

  useEffect(() => {
    // MAKE
    const tmpMake = VEMake.map((m) => {
      m["label"] = m.Description;
      m["value"] = m.MakeCode;
      return m;
    });
    setMakes(tmpMake);

    // MODELS
    const tmpModel = VEModel.map((m) => {
      m["label"] = m.Description;
      m["value"] = m.FamilyCode;
      return m;
    });
    setModels(tmpModel);
  }, [VEMake, VEModel]);

  return [makes, models];
}
