import React, { useState, useEffect, useMemo } from "react";
import { Badge, ToggleButtonGroup, ToggleButton } from "react-bootstrap";

const styles = {
  card: {
    fontSize: 14,
    marginBottom: 25,
  },
  toggleButton: {
    borderColor: "black",
    color: "black",
    maxWidth: 250,
  },
  toggleButtonGroup: {
    width: "100%",
    marginBottom: 10,
    justifyContent: "center",
  },
  cardHeader: {
    color: "white",
    backgroundColor: "#566573",
    fontWeight: "bold",
    paddingTop: 5,
    paddingBottom: 5,
  },
};

const BranchFilterComponent = ({
  handleBranchFilter,
  branchesList,
  branchFilter,
}) => {
  const [filteredBranchList, setFilteredBranchList] = useState(branchesList);
  const [filteredBranchListAtStateLevel, setFilteredBranchListAtStateLevel] =
    useState(branchesList);
  const [selectedState, setSelectedState] = useState("");
  const [stateList, setStateList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [branchBatchRows, setBranchBatchRows] = useState([]);
  const [branchKeys, setBranchKeys] = useState([]);

  const handleStateFilter = (state) => {
    setSelectedState(state);
    // Clear brand filter if state is changed
    setSelectedBrand("");
  };

  const handleBrandFilter = (brand) => {
    setSelectedBrand(brand);
  };

  // Filter branches by state then by brand
  useEffect(() => {
    let tmpBranchList = [];
    if (selectedState.length > 0) {
      tmpBranchList = branchesList.filter((b) => b.state === selectedState);
    } else {
      tmpBranchList = [...branchesList];
    }

    if (selectedBrand.length > 0) {
      tmpBranchList = tmpBranchList.filter((b) => b.brand === selectedBrand);
    }

    // Order by branch name alphabetically
    tmpBranchList = tmpBranchList.sort((a, b) =>
      a.branch.localeCompare(b.branch)
    );

    setFilteredBranchList(tmpBranchList);
    setFilteredBranchListAtStateLevel(tmpBranchList);
  }, [selectedState, selectedBrand]);

  // Filter state list to be unique
  useEffect(() => {
    const tmpStateList = [];
    branchesList.forEach((b) => {
      if (!tmpStateList.includes(b.state)) {
        tmpStateList.push(b.state);
      }
    });
    setStateList(tmpStateList);
  }, [branchesList]);

  // Filter brand list to be unique
  useEffect(() => {
    const tmpBrandList = [];
    branchesList.forEach((b) => {
      if (!tmpBrandList.includes(b.brand)) {
        tmpBrandList.push(b.brand);
      }
    });
    setBrandList(tmpBrandList);
  }, [branchesList]);

  useEffect(() => {
    if (filteredBranchList.length > 0) {
      const rows = Math.ceil(filteredBranchList.length / 4);
      setBranchBatchRows([...Array(rows).keys()]);
    }
  }, [JSON.stringify(filteredBranchList)]);

  return (
    <>
      {/* STATE FILTER */}
      <h6 style={{ ...styles["cardHeader"], fontSize: "0.9em" }}>State</h6>
      <ToggleButtonGroup
        style={styles.toggleButtonGroup}
        name="state_filter"
        size="sm"
        onChange={handleStateFilter}
        value={selectedState}
      >
        {stateList.map((s, idx) => (
          <ToggleButton
            style={styles.toggleButton}
            value={s}
            variant="outline-warning"
            key={idx}
          >
            {s}
          </ToggleButton>
        ))}
        <ToggleButton
          style={styles.toggleButton}
          value={""}
          variant="outline-warning"
        >
          All
        </ToggleButton>
      </ToggleButtonGroup>

      {/* BRAND FILTER */}
      <h6 style={{ ...styles["cardHeader"], fontSize: "0.9em" }}>Brand</h6>
      <ToggleButtonGroup
        style={styles.toggleButtonGroup}
        name="state_filter"
        size="sm"
        onChange={handleBrandFilter}
        value={selectedBrand}
      >
        {brandList.map((b, idx) => (
          <ToggleButton
            style={styles.toggleButton}
            value={b}
            variant="outline-warning"
            key={idx}
          >
            {b}
          </ToggleButton>
        ))}
        <ToggleButton
          style={styles.toggleButton}
          value={""}
          variant="outline-warning"
        >
          All
        </ToggleButton>
      </ToggleButtonGroup>

      {/* BRANCH FILTER */}
      <h6 style={{ ...styles["cardHeader"], fontSize: "0.9em" }}>Branch</h6>

      {branchBatchRows.map((row, jdx) => {
        const GRID_SIZE = 8;
        // console.log("grouprow", row * GRID_SIZE + GRID_SIZE);
        // console.log("list length", filteredBranchList.length);
        // console.log(row * GRID_SIZE + GRID_SIZE > filteredBranchList.length);
        if (row * GRID_SIZE + GRID_SIZE - 1 > filteredBranchList.length) {
          // return null;
        }
        return (
          <ToggleButtonGroup
            style={styles.toggleButtonGroup}
            name="site_filter"
            size="sm"
            onChange={(value) => {
              handleBranchFilter(value);
            }}
            value={branchFilter}
            key={jdx}
          >
            {filteredBranchList
              .slice(
                row * GRID_SIZE,
                Math.min(row * GRID_SIZE + GRID_SIZE, filteredBranchList.length)
              )
              .map((b, idx) => {
                // console.log(
                //   "gridpos",
                //   jdx,
                //   idx,
                //   row * GRID_SIZE,
                //   row * GRID_SIZE + GRID_SIZE,
                //   filteredBranchList.length
                // );
                if (b.branch?.length > 0) {
                  return (
                    <ToggleButton
                      style={styles.toggleButton}
                      value={b.branch}
                      variant="outline-success"
                      key={idx}
                    >
                      {b.branch}
                    </ToggleButton>
                  );
                }
              })}
          </ToggleButtonGroup>
        );
      })}
    </>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.branchFilter === nextProps.branchFilter &&
    prevProps.branchesList === nextProps.branchesList
  );
}

export const BranchFilterComponentMemo = React.memo(
  BranchFilterComponent,
  areEqual
);
