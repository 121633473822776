export const AMWRetailKPITargets = {
  "D1 MELBOURNE": {
    division: "D1 MELBOURNE",
    totalSaleUnits: 45,
    totalSaleDollars: 0,
    leadConversionPercentage: 15,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 30,
    totalGrossProfitDollars: 220000,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 220000,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "D1 WESTGATE": {
    division: "D1 WESTGATE",
    totalSaleUnits: 45,
    totalSaleDollars: 0,
    leadConversionPercentage: 15,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 30,
    totalGrossProfitDollars: 220000,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 220000,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "D1 DOCKLANDS": {
    division: "D1 DOCKLANDS",
    totalSaleUnits: 45,
    totalSaleDollars: 0,
    leadConversionPercentage: 15,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 30,
    totalGrossProfitDollars: 220000,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 220000,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "D1 MOORABBIN": {
    division: "D1 MOORABBIN",
    totalSaleUnits: 45,
    totalSaleDollars: 0,
    leadConversionPercentage: 15,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 30,
    totalGrossProfitDollars: 200000,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 200000,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "D1 BOX HILL": {
    division: "D1 BOX HILL",
    totalSaleUnits: 45,
    totalSaleDollars: 0,
    leadConversionPercentage: 15,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 30,
    totalGrossProfitDollars: 220000,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 220000,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "D1 FAIRFIELD": {
    division: "D1 FAIRFIELD",
    totalSaleUnits: 45,
    totalSaleDollars: 0,
    leadConversionPercentage: 15,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 30,
    totalGrossProfitDollars: 150000,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 150000,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "D1 MULGRAVE": {
    division: "D1 MULGRAVE",
    totalSaleUnits: 45,
    totalSaleDollars: 0,
    leadConversionPercentage: 15,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 30,
    totalGrossProfitDollars: 200000,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 200000,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "D1 ESSENDON FIELDS": {
    division: "D1 ESSENDON FIELDS",
    totalSaleUnits: 45,
    totalSaleDollars: 0,
    leadConversionPercentage: 15,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 30,
    totalGrossProfitDollars: 200000,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 200000,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
  "D1 All": {
    division: "D1 All",
    totalSaleUnits: 270,
    totalSaleDollars: 0,
    leadConversionPercentage: 15,
    totalFinancePenetrationPercentage: 30,
    totalInsurancePenetrationPercentage: 0,
    totalWarrantyPenetrationPercentage: 30,
    totalGrossProfitDollars: 1390000,
    totalGrossProfitPercentage: 0,
    totalTrueProfitDollars: 1390000,
    totalTrueProfitPercentage: 0,
    vehiclesUnder90DaysOldPercentage: 10,
  },
};
