import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import NumberFormat from "react-number-format";
import { useEffect, useState } from "react";
import { ButtonGroup, Spinner } from "react-bootstrap";
import { useFunctions, useUser } from "reactfire";
import { ToastContainer, toast } from "react-toastify";
import BarChartDash from "./ChartComponents/BarChartDash";
import CompoundChartDash from "./ChartComponents/CompoundChartDash";
import PieChartDash from "./ChartComponents/PieChartDash";
import { httpsCallable } from "firebase/functions";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const chartInfoBookingsYTD = {
  title: "Bookings per month YTD",
  xaxis: {
    type: "date",
    format: "MMM",
    name: "Month",
    series: [
      {
        name: "Bookings",
        colour: "#8884d8",
      },
    ],
  },
  yaxis: {
    name: "Bookings",
    type: "number",
    format: null,
  },
};

const chartInfoOpenStatusesMTD = {
  title: "Open statuses",
  xaxis: {
    type: "text",
    format: "",
    name: "Status",
    pieColours: ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"],
    series: [
      {
        name: "Status",
        colour: "#8884d8",
      },
    ],
  },
  yaxis: {
    name: "Status",
    type: "number",
    format: null,
  },
};

const chartInfoRunrateMTD = {
  title: "Runrate MTD",
  xaxis: {
    type: "date",
    format: "DD",
    name: "Month",
    totalSeries: [
      {
        name: "Daily Bookings",
        colour: "#6f87d8",
        chartType: "Bar",
        yAxisId: "left",
      },
      {
        name: "Cumulative Bookings",
        colour: "#57c17b",
        chartType: "Line",
        yAxisId: "left",
      },
    ],
    series: [
      {
        name: "Daily Bookings",
        colour: "#6f87d8",
        chartType: "Bar",
        series: [
          {
            name: "Cumulative Bookings",
            colour: "#57c17b",
            chartType: "Line",
          },
        ],
      },
    ],
  },
  yaxis: {
    name: "Bookings",
    type: "number",
    format: null,
    axese: [
      { name: "Bookings", yAxisId: "left" },
      { name: "Cumulative Bookings", yAxisId: "left" },
    ],
  },
};

const FreightDashboardSummary = ({ data, nextStage }) => {
  const [mailToString, setMailToString] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [d, setD] = useState(null);
  const [call, setCall] = useState(false);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const functions = useFunctions();
  const user = useUser();

  const getHistory = async () => {
    firestore
      .collection("freight-bookings")
      .doc(data?.id)
      .collection("audit-log");
  };

  useEffect(() => {
    if (call) {
      var freightDashboardOnCall = httpsCallable(
        functions,
        "freightDashboardOnCall"
      );
      // var freightDashboardOnCall = firebase
      //   .functions()
      //   .httpsCallable("freightDashboardOnCall");
      freightDashboardOnCall()
        .then((result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result.data;
          if (sanitizedMessage === null) {
            throw "No data found";
          } else {
            toast.success("Loaded graph");
            console.log(sanitizedMessage);
            setD(sanitizedMessage);
          }
          setCall(false);
        })
        .catch((e) => {
          toast.error(e);
          console.log(e);
          setCall(false);
        });
    }
  }, [call]);

  return (
    <>
      <Container fluid style={{ paddingTop: 15 }}>
        <ToastContainer />
        <h2>Freight Dashboard</h2>
        <Button onClick={() => setCall(true)} variant="outline-primary">
          {call ? <Spinner animation="border" /> : "Get data"}
        </Button>

        <Row style={{ marginTop: 20 }}>
          <Col>
            <BarChartDash
              os_graph_data={d?.bookings_ytd}
              chartInfo={chartInfoBookingsYTD}
            />
          </Col>
          <Col>
            <CompoundChartDash
              os_graph_data={d?.runrate_mtd}
              chartInfo={chartInfoRunrateMTD}
            />
          </Col>
          <Col>
            <PieChartDash
              os_graph_data={d?.openstatuses_ytd}
              chartInfo={chartInfoOpenStatusesMTD}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FreightDashboardSummary;
