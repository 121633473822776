import { Formik, Field, ErrorMessage } from "formik";
import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import { useFunctions, useUser } from "reactfire";
import {
  Alert,
  Col,
  Dropdown,
  FormControl,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import BackdropComponent from "../BackdropComponent";
import { FaUserEdit, FaUnlock } from "react-icons/fa";
import dayjs from "dayjs";
import copy from "copy-to-clipboard";
import { HiClipboardCheck } from "react-icons/hi";
import { httpsCallable } from "firebase/functions";
import { Pencil } from "react-bootstrap-icons";
import { BiPencil } from "react-icons/bi";

const initialValues = {
  searchField: "",
  searchValue: "",
};

const RETAIL_SITES = [
  "D1 ADELAIDE",
  "D1 BOX HILL",
  "D1 BRISBANE",
  "D1 BROOKVALE",
  "D1 CANBERRA",
  "D1 CHATSWOOD",
  "D1 DOCKLANDS",
  "D1 FAIRFIELD",
  "D1 MASCOT",
  "D1 MELBOURNE",
  "D1 MOORABBIN",
  "D1 SPRINGWOOD",
  "D1 WESTGATE",
  "D1 WINDSOR",
  "CTS",
  "DG VIC",
];

const validationSchema = Yup.object().shape(
  {
    searchField: Yup.string().required(),

    searchValue: Yup.string().required().email(),
  }[["searchField", "searchValue"]]
);

function HistoricalLeadMappingComponent(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isNewMap, setIsNewMap] = useState(false);
  const [newMap, setNewMap] = useState({});
  const [showModal, setShowModal] = useState(false);

  const functions = useFunctions();

  const editRow = (row) => {
    setSelectedRow(row);
    setIsEditing(true);
    setShowModal(true);
    setIsNewMap(false);
  };

  const { historicalLeadMapping } = props;
  return (
    <>
      {/* <pre>{JSON.stringify(historipayload: calLeadMapping, null, 2)}</pre> */}
      {/* New record button */}
      <Button
        variant="outline-dark"
        onClick={() => {
          setSelectedRow({
            original_branch: "",
            original_sales_person: "",
            new_branch: "",
            new_branch_lead_record_type: "",
            new_sales_person: "",
            new_sales_person_sf_id: "",
          });
          setIsNewMap(true);
          setIsEditing(true);
          setShowModal(true);
        }}
      >
        Add new record
      </Button>

      <Table
        responsive
        style={{ marginTop: 10, fontSize: 12 }}
        striped
        bordered
        hover
      >
        <thead>
          <tr>
            <td>Action</td>
            <td>Original Branch</td>
            <td>Original SP</td>
            <td>New Branch</td>
            <td>New Branch LRT</td>
            <td>New Branch SP</td>
            <td>New Branch SP SFID</td>
          </tr>
        </thead>
        <tbody>
          {historicalLeadMapping
            .sort((a, b) => a.original_branch.localeCompare(b.original_branch))
            .map((mp, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <BiPencil
                      onClick={() => {
                        editRow(mp);
                      }}
                    />
                  </td>
                  <td>{mp.original_branch}</td>
                  <td>{mp.original_sales_person}</td>
                  <td>{mp.new_branch}</td>
                  <td>{mp.new_branch_lead_record_type}</td>
                  <td>{mp.new_sales_person}</td>
                  <td>{mp.new_sales_person_sf_id}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isNewMap ? "New " : "Edit "} Historical Lead Mapping
            <h5> {isNewMap ? null : selectedRow?.id}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={selectedRow}
            onSubmit={async (values) => {
              setIsSaving(true);
              // save to firebase function updateHistoricalLeadMapping

              if (isNewMap) {
                // Check through list to ensure that the new mapping does not already exist
                const existingMap = historicalLeadMapping.find(
                  (mp) =>
                    mp.original_branch === values.original_branch &&
                    mp.original_sales_person === values.original_sales_person
                );
                if (existingMap) {
                  toast.error("Mapping already exists");
                  toast.error(
                    `Original Branch: ${existingMap.original_branch}`
                  );
                  toast.error(
                    `Original SP: ${existingMap.original_sales_person}`
                  );
                  setIsEditing(false);
                  setIsSaving(false);
                  setShowModal(false);
                  setIsNewMap(false);
                  return;
                }

                const addNewHistoricalLeadMapping = httpsCallable(
                  functions,
                  "addNewHistoricalLeadMapping"
                );

                await addNewHistoricalLeadMapping({ ...values })
                  .then((result) => {
                    // Read result of the Cloud Function.
                    var sanitizedMessage = result;
                    toast.success("Updated");
                  })
                  .catch((e) => {
                    console.log(e);
                    toast.error(e.message);
                  });
              } else {
                const updateHistoricalLeadMapping = httpsCallable(
                  functions,
                  "updateHistoricalLeadMapping"
                );

                await updateHistoricalLeadMapping({ ...values })
                  .then((result) => {
                    // Read result of the Cloud Function.
                    var sanitizedMessage = result;
                    toast.success("Updated");
                  })
                  .catch((e) => {
                    console.log(e);
                    toast.error(e.message);
                  });
              }
              setIsEditing(false);
              setIsSaving(false);
              setShowModal(false);
              setIsNewMap(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              isSubmitting,
              resetForm,
            }) => (
              <>
                <Form onSubmit={handleSubmit} className="mx-auto">
                  <Table>
                    <tr>
                      <td>Original Branch</td>
                      <td>
                        <Form.Control
                          as={Field}
                          name="original_branch"
                          type="text"
                          value={values.original_branch}
                          disabled={!isEditing}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Original SP</td>
                      <td>
                        <Form.Control
                          as={Field}
                          name="original_sales_person"
                          type="text"
                          value={values.original_sales_person}
                          disabled={!isEditing}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>New Branch</td>
                      <td>
                        <Form.Control
                          as={Field}
                          name="new_branch"
                          type="text"
                          value={values.new_branch}
                          disabled={!isEditing}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>New Branch LRT</td>
                      <td>
                        <Form.Control
                          as={Field}
                          name="new_branch_lead_record_type"
                          type="text"
                          value={values.new_branch_lead_record_type}
                          disabled={!isEditing}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>New Branch SP</td>
                      <td>
                        <Form.Control
                          as={Field}
                          name="new_sales_person"
                          type="text"
                          value={values.new_sales_person}
                          disabled={!isEditing}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>New Branch SP SFID</td>
                      <td>
                        <Form.Control
                          as={Field}
                          name="new_sales_person_sf_id"
                          type="text"
                          value={values.new_sales_person_sf_id}
                          disabled={!isEditing}
                        />
                      </td>
                    </tr>
                  </Table>
                </Form>
                <ButtonGroup>
                  <Button
                    variant="outline-danger"
                    onClick={() => {
                      setShowModal(false);
                      setIsEditing(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant="success" onClick={handleSubmit}>
                    {isSaving ? <Spinner animation="grow" size="sm" /> : "Save"}
                  </Button>
                </ButtonGroup>
              </>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

function AllUsersTable(props) {
  const { allUsers } = props;
  return (
    <>
      <p style={{ marginTop: 5 }}>
        <i>
          Enabled users: {allUsers.filter((u) => u.disabled === false)?.length}
        </i>
        , <i>Total users: {allUsers.length}</i>
      </p>
      <Table
        responsive
        style={{ marginTop: 10, fontSize: 12 }}
        striped
        bordered
        hover
      >
        <thead>
          <tr>
            <th>UID</th>
            <th>Display Name</th>
            <th>Contact details</th>
            <th>Created</th>
            <th>Last signed in</th>
            <th>Access</th>
          </tr>
        </thead>
        <tbody>
          {allUsers.map((user, idx) => {
            return (
              <tr
                key={idx}
                style={{
                  backgroundColor: user.disabled ? "#E74C3C" : "",
                }}
              >
                <td>
                  <span
                    onClick={() => {
                      window.open(`/viewUsers/${user?.uid}`, "_blank");
                    }}
                  >
                    <FaUserEdit />
                  </span>
                </td>
                <td>{user.displayName}</td>
                <td style={{ textAlign: "left", paddingLeft: 50 }}>
                  {user.email}
                  <br />
                  {user.phoneNumber}
                </td>
                <td>{user.metadata?.creationTime}</td>
                <td>{user.metadata?.lastSignInTime}</td>
                <td style={{ textAlign: "left", paddingLeft: 50 }}>
                  {Object.keys(user.customClaims || []).map((key, idx_k) => {
                    return (
                      <span key={idx_k}>
                        <i>{key}</i>
                        <br />
                      </span>
                    );
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

function ProfileInformation(props) {
  const [isEditing, setIsEditing] = useState(false);

  const { profile } = props;

  const functions = useFunctions();

  const submitForm = async (values, resetForm) => {
    const payload = {
      displayName: values.displayName,
      email: values.email,
      disabled: values.disabled,
      uid: values.uid,
    };

    const updateProfile = httpsCallable(functions, "updateUserProfile");
    // var updateProfile = firebase.functions().httpsCallable("updateUserProfile");
    await updateProfile({ payload: payload })
      .then((result) => {
        // Read result of the Cloud Function.
        var sanitizedMessage = result;
        toast.success("Updated");
        setIsEditing(false);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.message);
        setIsEditing(false);
      });
  };

  useEffect(() => {
    setIsEditing(false);
  }, [profile]);

  return (
    <>
      <Container style={{ marginBottom: 10 }}>
        <Formik
          initialValues={profile}
          onSubmit={submitForm}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            isSubmitting,
            resetForm,
          }) => (
            <>
              <Form onSubmit={handleSubmit} className="mx-auto">
                <Card>
                  <Card.Header>User Profile</Card.Header>
                  <Card.Body>
                    <Table>
                      <tr>
                        <td>Display Name</td>
                        <td>
                          <Form.Control
                            as={Field}
                            name="displayName"
                            type="text"
                            value={values.displayName}
                            disabled={!isEditing}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>
                          <Form.Control
                            as={Field}
                            name="email"
                            type="email"
                            value={values.email}
                            disabled={!isEditing}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td>{profile?.phoneNumber}</td>
                      </tr>
                      <tr>
                        <td>UID</td>
                        <td>
                          <Button
                            variant="outline-primary"
                            onClick={() => {
                              window.open(
                                `/viewUsers/${profile?.uid}`,
                                "_blank"
                              );
                            }}
                          >
                            {profile?.uid || "Not available"}
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>In-Active</td>
                        <td>
                          <Form.Check
                            as={Field}
                            type="switch"
                            name="disabled"
                            id="disabled"
                            // value={values.disabled}
                            disabled={!isEditing}
                            checked={values.disabled}
                          />
                        </td>
                      </tr>
                    </Table>
                  </Card.Body>
                </Card>
                <BackdropComponent loading={isSubmitting} />
              </Form>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
}

function AccessInformation(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [newClaimKey, setNewClaimKey] = useState("");
  const [isUpdatingNewClaim, setIsUpdatingNewClaim] = useState(false);

  const { customClaims, uid } = props;
  const { data } = useUser();
  const functions = useFunctions();

  const addClaim = async (values) => {
    if (newClaimKey.length === 0) {
      toast.error("No key entered");
      return;
    }
    if (newClaimKey === "su" && data.phoneNumber !== "+61415447605") {
      toast.error("Not authorised to perform this function");
      return;
    }

    setIsUpdatingNewClaim(true);
    values = { ...values, [newClaimKey]: false };
    toast.info(`Adding new claim: ${newClaimKey}`);
    await submitForm(values);
    setNewClaimKey("");
    setIsUpdatingNewClaim(false);
  };

  const submitForm = async (values) => {
    setIsEditing(true);
    if (uid?.length > 0) {
      const payload = { claimsObject: values, uid };
      const updateClaim = httpsCallable(functions, "updateUserCustomClaim");
      // var updateClaim = firebase
      //   .functions()
      //   .httpsCallable("updateUserCustomClaim");
      await updateClaim({ payload: payload })
        .then((result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result;
          toast.success("Updated");
          setIsEditing(false);
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.message);
          setIsEditing(false);
        });
    }
  };

  useEffect(() => {
    setIsEditing(false);
  }, [customClaims]);

  return (
    <>
      <Container>
        <Formik
          initialValues={customClaims}
          onSubmit={submitForm}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            isSubmitting,
            resetForm,
            setFieldValue,
          }) => (
            <>
              <Form onSubmit={handleSubmit} className="mx-auto">
                <Card>
                  <Card.Header>Access Information</Card.Header>
                  <Card.Body>
                    <Table>
                      {customClaims ? (
                        Object.keys(customClaims).map((key) => {
                          return (
                            <tr>
                              <td>{key}</td>
                              <td>
                                <Form.Check
                                  as={Field}
                                  type="switch"
                                  name={key}
                                  id={key}
                                  checked={values[key]}
                                  disabled={!isEditing}
                                />
                              </td>
                            </tr>
                          );
                        })
                      ) : isEditing ? (
                        <tr>
                          <td>Actions:</td>
                          <td>
                            <Button
                              onClick={submitForm({
                                admin: false,
                                freight_c: false,
                                freight_cru: false,
                                insights: false,
                                vinsearch_r: false,
                              })}
                            >
                              Set Default
                            </Button>
                          </td>
                        </tr>
                      ) : null}
                      {isEditing && data.phoneNumber === "+61415447605" ? (
                        <tr>
                          <td>Actions:</td>
                          <td>
                            <InputGroup>
                              <Form.Control
                                type="input"
                                value={newClaimKey}
                                onChange={(e) => {
                                  setNewClaimKey(e.target.value);
                                }}
                              ></Form.Control>
                              <Button
                                variant="danger"
                                disabled={isUpdatingNewClaim}
                                onClick={() => addClaim(values)}
                              >
                                Add new claim type
                              </Button>
                              {isUpdatingNewClaim ? (
                                <Spinner animation="grow" />
                              ) : null}
                            </InputGroup>
                          </td>
                        </tr>
                      ) : null}
                    </Table>
                  </Card.Body>
                  <Card.Footer>
                    <ButtonGroup>
                      {isEditing ? (
                        <>
                          <Button
                            variant="outline-danger"
                            onClick={() => setIsEditing(false)}
                          >
                            Cancel
                          </Button>
                          <Button variant="success" type="submit">
                            Update
                          </Button>
                          &nbsp;
                          {isSubmitting ? <Spinner animation="grow" /> : null}
                        </>
                      ) : (
                        <Button onClick={() => setIsEditing(true)}>Edit</Button>
                      )}
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
                <BackdropComponent loading={isSubmitting} />
              </Form>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
}

const initialValuesUserData = { customClaims: {} };

function UpdateUserComponent() {
  const [userData, setUserData] = useState(initialValuesUserData);
  const [tabKey, setTabKey] = useState("search_users");
  const [isLoading, setIsLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [allUsersSorted, setAllUsersSorted] = useState([]);
  const [currentSortKey, setCurrentSortKey] = useState("displayName");
  const [currentSortOrder, setCurrentSortOrder] = useState("asc");
  const [decLap, setDecLap] = useState("");
  const [encLap, setEncLap] = useState("RR8596EA75");
  const [historicalLeadMapping, setHistoricalLeadMapping] = useState([]);

  const functions = useFunctions();

  const copyToClipboard = (v) => {
    copy(v);
  };

  const getAllUsers = async () => {
    setIsLoading(true);
    const getAllUserList = httpsCallable(functions, "getAllUserList");
    // var getAllUserList = firebase.functions().httpsCallable("getAllUserList");
    await getAllUserList()
      .then((result) => {
        var sanitizedMessage = result.data;
        setAllUsers(sanitizedMessage);
      })
      .catch((e) => {
        console.log(e);
      });
    setIsLoading(false);
  };

  const getHistoricalLeadMapping = async () => {
    setIsLoading(true);
    const listHistoricalLeadMapping = httpsCallable(
      functions,
      "listHistoricalLeadMapping"
    );

    await listHistoricalLeadMapping()
      .then((result) => {
        var sanitizedMessage = result.data;
        setHistoricalLeadMapping(sanitizedMessage);
      })
      .catch((e) => {
        console.log(e);
      });
    setIsLoading(false);
  };

  const handleGenerateLeads = async (division, leadsToGenerate) => {
    setIsLoading(true);
    toast.success(`Generating ${leadsToGenerate} leads for ${division}`);
    // Call changeSalesSummaryFlagTriggerExistingCustomerLeadCreation
    const generateLeads = httpsCallable(
      functions,
      "changeSalesSummaryFlagTriggerExistingCustomerLeadCreation"
    );

    await generateLeads({ division, leadsToGenerate })
      .then((result) => {
        var sanitizedMessage = result.data;
        console.log(sanitizedMessage);
        toast.info(sanitizedMessage);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.message);
      });

    // Confirmation dialog popup

    setIsLoading(false);
  };

  const sortAllUsers = (key, order) => {
    if (allUsers.length > 0) {
      if (currentSortKey === key) {
        if (currentSortOrder === "asc") {
          order = "desc";
        } else {
          order = "asc";
        }
      } else {
        order = "asc";
      }
      setCurrentSortOrder(order);
      setCurrentSortKey(key);
      let tmpData = [...allUsers];
      if (key === "displayName") {
        tmpData.sort((a, b) => {
          if (a.displayName === null) {
            return 1;
          }
          if (b.displayName === null) {
            return -1;
          }
          return a.displayName.localeCompare(b.displayName);
        });
      } else if (key === "creationTime") {
        tmpData.sort((a, b) => {
          const aDate = dayjs(a.metadata.creationTime).unix();
          const bDate = dayjs(b.metadata.creationTime).unix();

          return aDate - bDate;
        });
      }
      if (order === "desc") {
        tmpData.reverse();
      }
      setAllUsersSorted(tmpData);
    }
  };

  const submitForm = async (values, setSubmitting) => {
    setUserData(initialValuesUserData);
    if (values.searchField === "uid") {
      const getUser = httpsCallable(functions, "getAuthUserDetails");
      // var getUser = firebase.functions().httpsCallable("getAuthUserDetails");
      await getUser({ uid: values.searchValue })
        .then((result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result.data;
          if (sanitizedMessage === null) {
            throw "No user found, check UID";
          } else {
            toast.success("Returned user card");
            setUserData(sanitizedMessage);
          }
        })
        .catch((e) => {
          toast.error(e);
          console.log(e);
        });
    } else if (values.searchField === "phone") {
      const getUser = httpsCallable(functions, "getAuthUserDetailsByPhone");
      // var getUser = firebase
      //   .functions()
      //   .httpsCallable("getAuthUserDetailsByPhone");
      await getUser({ phone: values.searchValue })
        .then((result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result.data;
          if (sanitizedMessage === null) {
            throw "No user found, check phone number and format is +61";
          } else {
            toast.success("Returned user card");
            setUserData(sanitizedMessage);
          }
        })
        .catch((e) => {
          toast.error(e);
          console.log(e);
        });
    } else if (values.searchField === "email") {
      const getUser = httpsCallable(functions, "getAuthUserDetailsByEmail");
      // var getUser = firebase
      //   .functions()
      //   .httpsCallable("getAuthUserDetailsByEmail");
      await getUser({ email: values.searchValue })
        .then((result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result.data;
          if (sanitizedMessage === null) {
            throw "No user found, check email";
          } else {
            toast.success("Returned user card");
            setUserData(sanitizedMessage);
          }
        })
        .catch((e) => {
          toast.error(e);
          console.log(e);
        });
    }
  };

  function tr(text, fromChars, toChars) {
    const map = {};
    for (let i = 0; i < fromChars.length; i++) {
      map[fromChars.charAt(i)] = toChars.charAt(i) || "";
    }

    let result = "";
    for (let i = 0; i < text.length; i++) {
      const char = text.charAt(i);
      result += map[char] || char;
    }

    return result;
  }

  function generateAlphabet(range) {
    const [start, end] = range.split("-").map((char) => char.charCodeAt(0));
    let alphabet = "";
    for (let i = start; i <= end; i++) {
      alphabet += String.fromCharCode(i);
    }
    return alphabet;
  }

  function generateAlphabetWrap(range) {
    const parts = range.match(/([A-Z])-([A-Z])([A-Z])-([A-Z])/);
    if (!parts || parts.length !== 5) {
      throw new Error(
        "Invalid input range. The range should be in the format 'A-Z'."
      );
    }

    const [start1, end1, start2, end2] = parts
      .slice(1)
      .map((char) => char.charCodeAt(0));
    let alphabet = "";
    for (let i = start1; i <= end1; i++) {
      alphabet += String.fromCharCode(i);
    }
    for (let i = start2; i <= end2; i++) {
      alphabet += String.fromCharCode(i);
    }
    return alphabet;
  }

  function generateAlphabetNumberic(range) {
    const parts = range.match(/([A-Za-z0-9]+)-([A-Za-z0-9]+)-([A-Za-z0-9]+)/);
    if (!parts || parts.length !== 4) {
      throw new Error(
        "Invalid input range. The range should be in the format 'A-Z', '0-9', or 'A-Za-z0-9'."
      );
    }

    const [start1, end1, start2] = parts.slice(1);
    const alphabet = [];

    for (let i = parseInt(start1); i <= parseInt(end1); i++) {
      alphabet.push(i.toString());
    }

    // Check if start2 is greater than end1, in which case, wraparound occurs
    if (parseInt(start2) > parseInt(end1)) {
      for (let i = parseInt(start2); i <= parseInt(end1); i++) {
        alphabet.push(i.toString());
      }
      // Add numbers from 0 to 9 to complete the wraparound
      for (let i = 0; i <= 9; i++) {
        alphabet.push(i.toString());
      }
    }

    return alphabet.join("");
  }

  const getLAPDec = () => {
    setIsLoading(true);
    if (encLap.length > 0) {
      try {
        let originalLap = encLap;
        let decTmp = tr(
          originalLap,
          generateAlphabet("A-Z"),
          generateAlphabetWrap("X-ZA-W")
        );
        setDecLap(decTmp);
      } catch (e) {
        toast.error(e.message);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setAllUsersSorted(allUsers);
  }, [JSON.stringify(allUsers)]);

  return (
    <>
      <BackdropComponent loading={isLoading} />
      <ToastContainer />
      <Tabs
        defaultActiveKey="search_users"
        activeKey={tabKey}
        onSelect={(k) => setTabKey(k)}
        id="controlled-tab-settings"
      >
        <Tab eventKey="list_users" title="List users">
          <Button
            variant="outline-dark"
            style={{ marginTop: 10 }}
            onClick={getAllUsers}
          >
            Get all users from auth server
          </Button>
          <br />
          <br />

          <ButtonGroup>
            <Button
              size="sm"
              variant={
                currentSortKey === "displayName" ? "success" : "outline-dark"
              }
              onClick={() => sortAllUsers("displayName")}
            >
              Sort by name{" "}
              {currentSortKey === "displayName"
                ? currentSortOrder === "asc"
                  ? "▲"
                  : "▼"
                : ""}
            </Button>
            <Button
              size="sm"
              variant={
                currentSortKey === "creationTime" ? "success" : "outline-dark"
              }
              onClick={() => sortAllUsers("creationTime")}
            >
              Sort by creation date
              {currentSortKey === "creationTime"
                ? currentSortOrder === "asc"
                  ? "▲"
                  : "▼"
                : ""}
            </Button>
          </ButtonGroup>
          {allUsers.length > 0 ? (
            <AllUsersTable allUsers={allUsersSorted} />
          ) : (
            <p>No users found</p>
          )}
        </Tab>
        <Tab eventKey="search_users" title="Search users">
          <Card>
            <Card.Body>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitForm}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  isSubmitting,
                  resetForm,
                  setFieldValue,
                }) => (
                  <>
                    <Form onSubmit={handleSubmit} className="mx-auto">
                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                      <Container>
                        <Row style={{ marginBottom: 20 }}>
                          <InputGroup>
                            <Dropdown as={ButtonGroup}>
                              <Button variant="outline-success">
                                {values.searchField?.length === 0
                                  ? "Search Field"
                                  : values.searchField?.toUpperCase()}
                              </Button>

                              <Dropdown.Toggle
                                split
                                variant="success"
                                id="dropdown-split-basic"
                              />

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    setFieldValue("searchValue", "");
                                    setFieldValue("searchField", "uid");
                                  }}
                                >
                                  UID
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setFieldValue("searchValue", "");
                                    setFieldValue("searchField", "phone");
                                  }}
                                >
                                  Mobile
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setFieldValue("searchValue", "");
                                    setFieldValue("searchField", "email");
                                  }}
                                >
                                  Email
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Form.Control
                              label="Search"
                              as={Field}
                              id="searchValue"
                              name="searchValue"
                              type={
                                values.searchField === "phone" ? "tel" : "text"
                              }
                              placeholder={`Please enter ${values.searchField}`}
                            />

                            <ButtonGroup>
                              <Button
                                variant="outline-dark"
                                onClick={() => setFieldValue("searchValue", "")}
                              >
                                x
                              </Button>
                              <Button type="submit">Search</Button>
                            </ButtonGroup>
                          </InputGroup>
                          <ErrorMessage name="searchValue" />
                        </Row>
                      </Container>
                    </Form>
                    <BackdropComponent loading={isSubmitting} />
                  </>
                )}
              </Formik>
            </Card.Body>
          </Card>
          <ProfileInformation
            profile={{
              displayName: userData.displayName,
              email: userData.email,
              phoneNumber: userData.phoneNumber,
              uid: userData.uid,
              disabled: userData.disabled,
            }}
          />
        </Tab>
        <Tab eventKey="lap" title="Get LAP">
          {/* Input */}
          <Card>
            <Card.Title>
              <Alert variant="warning">
                Do not use: This feature is not fully functional yet
              </Alert>
            </Card.Title>
            <Card.Body>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="lap-enc">Serial</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="RR8596EA75"
                  aria-label="enc"
                  aria-describedby="lap-enc"
                  onChange={(e) => setEncLap(e.target.value)}
                  value={generateAlphabetNumberic("3-9-0-2")}
                />
              </InputGroup>
              <Button variant="outline-primary" onClick={getLAPDec}>
                <FaUnlock />
              </Button>
              <br />
              <br />
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="lap-dec">@clean-pass</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="####"
                  aria-label="dec"
                  aria-describedby="lap-dec"
                  value={decLap}
                  disabled
                />
                <InputGroup.Append>
                  <Button
                    onClick={() => copyToClipboard(decLap)}
                    size="sm"
                    variant="outline-dark"
                  >
                    <HiClipboardCheck size={20} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Card.Body>
          </Card>
        </Tab>
        <Tab
          eventKey="list_historical_lead_mapping"
          title="Historical Lead Mapping"
        >
          <Button
            variant="outline-dark"
            style={{ marginTop: 10 }}
            onClick={getHistoricalLeadMapping}
          >
            Get historical lead mapping list
          </Button>
          <br />
          <br />

          {/* <ButtonGroup>
            <Button
              size="sm"
              variant={
                currentSortKey === "displayName" ? "success" : "outline-dark"
              }
              onClick={() => sortAllUsers("displayName")}
            >
              Sort by name{" "}
              {currentSortKey === "displayName"
                ? currentSortOrder === "asc"
                  ? "▲"
                  : "▼"
                : ""}
            </Button>
            <Button
              size="sm"
              variant={
                currentSortKey === "creationTime" ? "success" : "outline-dark"
              }
              onClick={() => sortAllUsers("creationTime")}
            >
              Sort by creation date
              {currentSortKey === "creationTime"
                ? currentSortOrder === "asc"
                  ? "▲"
                  : "▼"
                : ""}
            </Button>
          </ButtonGroup> */}
          {historicalLeadMapping.length > 0 ? (
            <HistoricalLeadMappingComponent
              historicalLeadMapping={historicalLeadMapping}
            />
          ) : (
            <p>No list found</p>
          )}
        </Tab>
        <Tab eventKey="generate_historical_leads" title="Generate Leads">
          {/* <ButtonGroup>
            <Button
              size="sm"
              variant={
                currentSortKey === "displayName" ? "success" : "outline-dark"
              }
              onClick={() => sortAllUsers("displayName")}
            >
              Sort by name{" "}
              {currentSortKey === "displayName"
                ? currentSortOrder === "asc"
                  ? "▲"
                  : "▼"
                : ""}
            </Button>
            <Button
              size="sm"
              variant={
                currentSortKey === "creationTime" ? "success" : "outline-dark"
              }
              onClick={() => sortAllUsers("creationTime")}
            >
              Sort by creation date
              {currentSortKey === "creationTime"
                ? currentSortOrder === "asc"
                  ? "▲"
                  : "▼"
                : ""}
            </Button>
          </ButtonGroup> */}
          <Container>
            <Row>
              <Col>
                <Table
                  responsive
                  style={{ marginTop: 10, fontSize: 12 }}
                  striped
                  bordered
                  hover
                >
                  <thead>
                    <tr>
                      <td>Branch</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {RETAIL_SITES.map((branch, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{branch}</td>
                          <td>
                            <ButtonGroup>
                              <Button
                                variant="outline-dark"
                                onClick={() => handleGenerateLeads(branch, 1)}
                              >
                                G1
                              </Button>
                              <Button
                                variant="outline-dark"
                                onClick={() => handleGenerateLeads(branch, 5)}
                              >
                                G5
                              </Button>
                              <Button
                                variant="outline-dark"
                                onClick={() => handleGenerateLeads(branch, 10)}
                              >
                                G10
                              </Button>
                              <Button
                                variant="outline-dark"
                                onClick={() => handleGenerateLeads(branch, 20)}
                              >
                                G20
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </Tab>
      </Tabs>
    </>
  );
}
export default UpdateUserComponent;
