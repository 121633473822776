import { ToastContainer, toast } from "react-toastify";
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import InsightFeatureCard from "./InsightFeatureCard";
import { useRecentlySoldCars } from "../../hooks/useRecentlySoldCars";

// const topProfitDataSet = require("../../assets/testdata/top_profit_test.json");
// const topVolumeDataSet = require("../../assets/testdata/top_volume_test.json");
// const topLeadsDataSet = require("../../assets/testdata/top_leads_test.json");

const numericalASCProfit = (a, b) => {
  if (a.TrueProfit < b.TrueProfit) {
    return -1;
  }
  if (a.TrueProfit > b.TrueProfit) {
    return 1;
  }
  return 0;
};

const sortDESCDate = (a, b) => {
  if (a["Sale Date EPOC"] < b["Sale Date EPOC"]) {
    return 1;
  }
  if (a["Sale Date EPOC"] > b["Sale Date EPOC"]) {
    return -1;
  }
  return 0;
};

const sortASCDivision = (a, b) => {
  if (a["Division"] > b["Division"]) {
    return 1;
  }
  if (a["Division"] < b["Division"]) {
    return -1;
  }
  return 0;
};

const InsightsAMWComponent = () => {
  const [topProfitDataSet, inventoryImages, inventoryData] =
    useRecentlySoldCars({
      branches: [
        "AMW - MELBOURNE",
        "AMW - FAIRFIELD",
        "AMW - WESTGATE",
        "AMW - BRISBANE",
        "AMW - ALBION",
        "AMW - PLYMPTON",
        "AMW - DANDENONG",
      ],
    });

  // const [topProfitDataSet, setTopProfitDataSet] = useState([]);
  // const [inventoryImages, setInventoryImages] = useState([]);
  // const [inventoryData, setInventoryData] = useState([]);
  // const [topVolumeDataSet, setTopVolumeDataSet] = useState([]);
  // // const [topProfitDataSet, setTopProfitDataSet] = useState([]);

  // const firebase = useFirestore();

  // // Top Profit Data Set
  // useEffect(() => {
  //   firebase
  //     .collection("salesSummary")
  //     .where("Sale Date EPOC", ">", dayjs().add(-14, "day").unix())
  //     .where("Division", "in", [
  //       "AMW - MELBOURNE",
  //       "AMW - FAIRFIELD",
  //       "AMW - WESTGATE",
  //       "AMW - BRISBANE",
  //       "AMW - ALBION",
  //       "AMW - PLYMPTON",
  //       "AMW - DANDENONG",
  //     ])
  //     .where("sale_class", "==", "R")
  //     .get()
  //     .then((snapshot) => {
  //       if (snapshot.empty) {
  //         toast.info("No summary available at this time");
  //         return;
  //       }
  //       const tmpProfit = [];

  //       snapshot.forEach((doc) => {
  //         const tmpData = doc.data();
  //         tmpProfit.push({ ...tmpData, id: doc.id });
  //       });
  //       tmpProfit.sort(numericalASCProfit).reverse();
  //       tmpProfit.sort(sortDESCDate);
  //       // tmpProfit.sort(sortASCDivision);
  //       setTopProfitDataSet(tmpProfit);
  //     });

  //   // return () => unsubscribe;
  // }, []);

  // // Get instock inventory
  // useEffect(() => {
  //   firebase
  //     .collection("vehicle-health-amw")
  //     .where("CurrentStatus", "==", "IN-STOCK")
  //     .get()
  //     .then((snapshot) => {
  //       if (snapshot.empty) {
  //         toast.info("No summary available at this time");
  //         return;
  //       }
  //       const tmpInventory = [];
  //       snapshot.forEach((doc) => {
  //         const tmpData = doc.data();
  //         tmpInventory.push({ ...tmpData, id: doc.id });
  //       });
  //       setInventoryData(tmpInventory);
  //     });
  // }, []);

  // // Get Images For Top Profit Data
  // useEffect(() => {
  //   let tmpImages = {};
  //   topProfitDataSet.forEach((tp) => {
  //     firebase
  //       .collection("inventory-photos")
  //       .doc(tp["Stock No"].toString())
  //       .get()
  //       .then((doc) => {
  //         if (doc.exists) {
  //           const tmpData = doc.data();
  //           const id = doc.id;

  //           tmpImages[id] = tmpData;
  //         }
  //       });
  //   });
  //   console.log(tmpImages);
  //   setInventoryImages(tmpImages);
  // }, [topProfitDataSet]);

  // Top Volume Data Set
  // useEffect(() => {
  //   firebase
  //     .collection("Top_Volume")
  //     .where("saleDate", "==", dayjs().add(-1, "day").format("DDMMYYYY"))
  //     .get()
  //     .then((snapshot) => {
  //       if (snapshot.empty) {
  //         toast.info("No summary available at this time");
  //         return;
  //       }
  //       const tmpVolume = [];
  //       snapshot.forEach((doc) => {
  //         const tmpData = doc.data();
  //         tmpVolume.push({ ...tmpData, id: doc.id });
  //       });
  //       tmpVolume.sort(numericalASCProfit).reverse();
  //       setTopVolumeDataSet(tmpVolume);
  //     });

  //   // return () => unsubscribe;
  // }, []);
  return (
    <>
      {/* START TOP PROFIT */}
      <Navbar
        // sticky="top"
        bg="dark"
        variant="dark"
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        <Navbar.Brand>Cars sold: last 14 days by branch</Navbar.Brand>
      </Navbar>
      <Container>
        {topProfitDataSet?.map((tp, idx) => {
          return (
            <Row style={{ paddingBottom: 10 }} key={idx}>
              <InsightFeatureCard
                data={tp}
                images={inventoryImages}
                inventoryData={inventoryData}
              />
            </Row>
          );
        })}
      </Container>
      {/* END TOP PROFIT */}

      {/* START TOP VOLUME */}
      {/* <Navbar
        sticky="top"
        bg="dark"
        variant="dark"
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        <Navbar.Brand>Top volume last 30 days</Navbar.Brand>
      </Navbar>
      <Container>
        {topVolumeDataSet?.map((tp, idx) => {
          return (
            <Row style={{ paddingBottom: 10 }}>
              <InsightFeatureCard data={tp} />
            </Row>
          );
        })}
      </Container> */}
      {/* END TOP VOLUME */}

      {/* START TOP LEADS */}

      {/* <Navbar
        sticky="top"
        bg="dark"
        variant="dark"
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        <Navbar.Brand>Top Leads</Navbar.Brand>
      </Navbar>
      <Container>
        {topLeadsDataSet?.map((tp, idx) => {
          return (
            <Row style={{ paddingBottom: 10 }}>
              <InsightFeatureCard data={tp} />
            </Row>
          );
        })}
      </Container> */}
      {/* END TOP LEADS */}
      <ToastContainer />
    </>
  );
};

export default InsightsAMWComponent;
