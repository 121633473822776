import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// 516640

const VehiclePhotos = ({ stockNumber }) => {
  const [vehiclePhotoRecord, setVehiclePhotoRecord] = useState([]);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  // Get photos of selected vehicle
  const getPhotos = async () => {
    console.log(stockNumber);
    if (stockNumber > 0) {
      await firestore
        .collection("inventory-photos")
        .doc(stockNumber.toString())
        .get()
        .then((doc) => {
          if (!doc.empty) {
            setVehiclePhotoRecord(doc.data());
          }
        });
    }
  };

  useEffect(() => {
    getPhotos();
  }, [stockNumber]);

  return (
    <>
      <Carousel
        slide
        style={{
          borderRadius: 3,
          maxWidth: 600,
        }}
      >
        {vehiclePhotoRecord?.photos?.map((p, idx) => {
          return (
            <Carousel.Item
              key={idx}
              style={{
                maxWidth: 600,
              }}
            >
              <img
                className="d-block w-100"
                src={p}
                alt={`vehicle-${idx}`}
                style={{
                  borderRadius: 10,
                  maxWidth: 600,
                }}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
};

export default VehiclePhotos;
