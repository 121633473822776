import { ToastContainer, toast } from "react-toastify";
import BackdropComponent from "../BackdropComponent";
import {
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  Container,
  Spinner,
  Table,
  Badge,
  ButtonGroup,
  Card,
  Alert,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  doc,
  orderBy,
  limit,
  collectionGroup,
} from "firebase/firestore";
import { useFunctions, useUser, useFirestore } from "reactfire";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { Input, parse } from "postcss/lib/postcss";

const stickyTableHeaderStyle = {
  position: "sticky",
  margin: "0 0 0 0",
  top: -1,
  backgroundColor: "#566573",
  color: "white",
  fontSize: 12,
};

const TEST_AUCTION_VEHICLES = require("../../assets/testdata/auction_internal_tender_vehicles_test_060924.json");
const TEST_AUCTION_MEMBERS = [
  {
    uid: "JknoVlb6Uic1SSjouHBqp2mjNH92",
    displayName: "Juv J",
    email: "j.jayaram@duttongroup.com.au",
    phoneNumber: "+61415447065",
    role: "BIDDER",
    status: "active",
    ownBranches: ["D1 MELBOURNE"],
  },
  {
    uid: "cnWHzVNEWtSJeOSEgsHMDueAH5l2",
    displayName: "TEST SELLER",
    email: "j.jayaram@duttongroup.com.au",
    phoneNumber: "+61415447065",
    role: "SELLER",
    status: "active",
    ownBranches: ["DG VIC"],
  },
];

const TEST_AUCTION_DATA = {
  auctionType: "INTERNAL_TENDER",
  auctionStatus: "LIVE",
  auctionTitle: "Internal tender test",
  auctionDescription: "This is a test auction",
  auctionStartDate: dayjs().unix(),
  auctionEndDate: dayjs().add(1, "day").unix(),
  numberOfAuctionVehicles: TEST_AUCTION_VEHICLES.length,
  timeExtensionAllowed: true,
  auctionMembers: TEST_AUCTION_MEMBERS,
};

const InternalTendersVehicleSeller = () => {
  const [auctionVehicle, setAuctionVehicle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [counterAmount, setCounterAmount] = useState(null);
  const [bidAmount, setBidAmount] = useState(0);
  const [auctionFinished, setAuctionFinished] = useState(false);
  const [auctionStarted, setAuctionStarted] = useState(false);
  const [auctionTimeToStart, setAuctionTimeToStart] = useState(0);
  const [auctionTimeToFinish, setAuctionTimeToFinish] = useState(0);
  // const [existingBid, setExistingBid] = useState(false);
  const [markedAsNotInterested, setMarkedAsNotInterested] = useState(false);
  const [existingBidHistory, setExistingBidHistory] = useState([]);
  const [userType, setUserType] = useState(null);

  const [highestBid, setHighestBid] = useState(null);

  //   Hooks
  //   const functions = useFunctions();
  const firestore = useFirestore();
  const user = useUser();
  const history = useHistory();
  const { auctionId, vehicleId } = useParams();

  //   Get all auction vehicles (Collection group) in live auctionStatusCar
  const getAuctionVehicle = async (auctionId, vehicleId) => {
    setLoading(true);
    // Get collection group auction-vehicles
    console.log(auctionId, vehicleId);
    const auctionVehicleRef = doc(
      firestore,
      "auctions",
      auctionId,
      "auction-vehicles",
      vehicleId
    );
    const docSnap = await getDoc(auctionVehicleRef);

    setLoading(false);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      const id = docSnap.id;
      const data = docSnap.data();
      return { id, ...data };
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
      return false;
    }
  };

  const addBidHistory = async (
    auctionId,
    vehicleId,
    bidId,
    bidData,
    historyType
  ) => {
    // Add document bid-history
    const bidHistoryData = {
      ...bidData,
      historyType,
      historyDate: dayjs().unix(),
      historyBy: user.data.uid,
      bidId,
    };

    const bidHistoryRef = collection(
      firestore,
      "auctions",
      auctionId,
      "auction-vehicles",
      vehicleId,
      "auction-bids",
      bidId,
      "auction-bids-history"
    );

    return await addDoc(bidHistoryRef, bidHistoryData);
  };

  const getBidHistory = async (bidId) => {
    const q = query(
      collectionGroup(firestore, "auction-bids-history"),
      where("bidId", "==", bidId),
      orderBy("historyDate", "desc")
    );
    const querySnapshot = await getDocs(q);
    let bidHistory = [];
    querySnapshot.forEach((doc) => {
      const id = doc.id;
      const data = doc.data();
      bidHistory.push({ id, ...data });
    });
    return bidHistory;
  };

  const rejectBid = async () => {
    if (!highestBid) {
      toast.error("No existing bid found");
      return;
    }

    setLoading(true);
    try {
      await setDoc(
        doc(
          firestore,
          "auctions",
          auctionId,
          "auction-vehicles",
          vehicleId,
          "auction-bids",
          highestBid.id
        ),
        { bidStatus: "REJECTED" },
        { merge: true }
      );
      toast.success("Bid rejected successfully");

      // Add bid history
      await addBidHistory(
        auctionId,
        vehicleId,
        highestBid.id,
        highestBid,
        "REJECTED"
      );
      setHighestBidByBidId(highestBid.id);
    } catch (e) {
      console.log(e);
      toast.error("Error rejecting bid");
    }
    setLoading(false);
  };

  const acceptBid = async () => {
    if (!highestBid) {
      toast.error("No existing bid found");
      return;
    }

    const acceptBidData = {
      bidStatus: "ACCEPTED",
      acceptedBy: user.data.uid,
      acceptedDate: dayjs().unix(),
      winningBid: true,
      winningBidAmount: highestBid.bidAmount,
      won: true,
    };

    setLoading(true);
    try {
      await setDoc(
        doc(
          firestore,
          "auctions",
          auctionId,
          "auction-vehicles",
          vehicleId,
          "auction-bids",
          highestBid.id
        ),
        acceptBidData,
        { merge: true }
      );
      toast.success("Bid accepted successfully");

      // Update auction vehicle with winning bid
      await setDoc(
        doc(firestore, "auctions", auctionId, "auction-vehicles", vehicleId),
        {
          winningBid: highestBid.id,
          winningBidAmount: highestBid.bidAmount,
          winningBidType: "BUYER_BID",
          winningBidStatus: "ACCEPTED",
          won: true,
          lost: false,
        },
        { merge: true }
      );

      // Add bid history
      await addBidHistory(
        auctionId,
        vehicleId,
        highestBid.id,
        highestBid,
        "ACCEPTED"
      );
      setHighestBidByBidId(highestBid.id);
    } catch (e) {
      console.log(e);
      toast.error("Error accepting bid");
    }
    setLoading(false);
  };

  const submitCounterOffer = async () => {
    if (!counterAmount) {
      toast.error("Please enter a counter offer amount");
      return;
    }
    if (!highestBid) {
      toast.error("No existing bid found");
      return;
    }

    if (counterAmount <= highestBid.bidAmount) {
      toast.error("Counter offer must be higher than the current bid");
      return;
    }

    if (highestBid?.bidStatus === "COUNTER_OFFER") {
      toast.error(
        "You cannot place a counter offer on an existing counter offer"
      );
      return;
    }

    setLoading(true);
    try {
      const counterData = {
        counterAmount: counterAmount,
        bidStatus: "COUNTER_OFFER",
        counterDate: dayjs().unix(),
        counterBy: user.data.uid,

        displayName: user.data.displayName,
        counterEmail: user.data.email,
        counterPhoneNumber: user.data.phoneNumber,
        counterRole: "SELLER",
        counterStatus: "active",
      };

      await setDoc(
        doc(
          firestore,
          "auctions",
          auctionId,
          "auction-vehicles",
          vehicleId,
          "auction-bids",
          highestBid.id
        ),
        counterData,
        { merge: true }
      );
      toast.success("Counter submitted successfully");

      // Add bid history
      await addBidHistory(
        auctionId,
        vehicleId,
        highestBid.id,
        counterData,
        "COUNTER_OFFER"
      );
      setHighestBidByBidId(highestBid.id);
    } catch (e) {
      console.log(e);
      toast.error("Error submitting counter offer");
    }
    setLoading(false);
  };

  const setHighestBidByBidId = async (bidId) => {
    const bidRef = doc(
      firestore,
      "auctions",
      auctionId,
      "auction-vehicles",
      vehicleId,
      "auction-bids",
      bidId
    );
    const docSnap = await getDoc(bidRef);
    if (docSnap.exists()) {
      const id = docSnap.id;
      const data = docSnap.data();
      setHighestBid({ id, ...data });
    } else {
      setHighestBid(null);
    }
  };

  const getHighestBids = async () => {
    // Get highest bid for this vehicle from all bids
    const highestVehicleBidRef = collectionGroup(firestore, "auction-bids");
    const q = query(
      highestVehicleBidRef,
      where("auctionId", "==", auctionId),
      where("vehicleId", "==", vehicleId),
      where("bidStatus", "in", ["ACCEPTED", "PENDING", "COUNTER_OFFER"]),
      orderBy("bidAmount", "desc"),
      limit(1)
    );

    const querySnapshot = await getDocs(q);

    console.log(`Found ${querySnapshot.size} bids for vehicle ${vehicleId}`);
    if (querySnapshot.size === 0) {
      return null;
    }

    let highestBid = null;
    querySnapshot.forEach((doc) => {
      if (!doc.exists()) {
        return;
      }
      const data = doc.data();
      highestBid = { id: doc.id, ...data };
    });

    console.log("Highest bid", highestBid, auctionId, vehicleId);
    return highestBid;
  };

  // Load test
  useEffect(() => {
    if (auctionId && vehicleId) {
      console.log(auctionId, vehicleId);
      getAuctionVehicle(auctionId, vehicleId).then((data) => {
        console.log("AVd", data);
        setAuctionVehicle(data);
        if (!data) {
          history.push("/auctions/internal-tenders");
          toast.error("No auction vehicle found");
        }
      });
    } else {
      toast.error("No auction or vehicle id found");
      history.push("/auctions/internal-tenders");
    }
  }, [auctionId, vehicleId]);

  useEffect(() => {
    if (auctionVehicle) {
      const now = dayjs().unix();
      if (now >= auctionVehicle.auctionEndDate) {
        setAuctionFinished(true);
      }
    }
  }, [JSON.stringify(auctionVehicle)]);

  useEffect(() => {
    if (auctionVehicle) {
      const now = dayjs().unix();
      if (now >= auctionVehicle.auctionStartDate) {
        setAuctionStarted(true);
      }
    }
  }, [JSON.stringify(auctionVehicle)]);

  // Load highest bids
  useEffect(() => {
    if (auctionVehicle) {
      getHighestBids().then((data) => {
        console.log("Highest bids", data);
        setHighestBid(data);
      });
    }
  }, [JSON.stringify(auctionVehicle)]);

  // Live counter for auction that has yet to start
  useEffect(() => {
    if (auctionVehicle) {
      const now = dayjs().unix();
      if (now < auctionVehicle.auctionStartDate) {
        const interval = setInterval(() => {
          const now = dayjs().unix();
          setAuctionTimeToStart(auctionVehicle.auctionStartDate - now);
          if (now >= auctionVehicle.auctionStartDate) {
            setAuctionStarted(true);
            clearInterval(interval);
          }
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  }, [JSON.stringify(auctionVehicle)]);

  // Live counter for auction that is live
  useEffect(() => {
    if (auctionVehicle) {
      const now = dayjs().unix();
      if (
        now >= auctionVehicle.auctionStartDate &&
        now < auctionVehicle.auctionEndDate
      ) {
        const interval = setInterval(() => {
          const now = dayjs().unix();
          setAuctionTimeToFinish(auctionVehicle.auctionEndDate - now);
          if (now >= auctionVehicle.auctionEndDate) {
            setAuctionFinished(true);
            clearInterval(interval);
          }
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  }, [JSON.stringify(auctionVehicle)]);

  // Format seconds to human readable time
  const formatSecondsToTime = (seconds) => {
    if (seconds <= 0) {
      return "00:00:00";
    }
    let hours = Math.floor(seconds / 3600);
    if (hours < 10) {
      hours = `0${hours}`;
    }
    let minutes = Math.floor((seconds % 3600) / 60);
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    let remainingSeconds = Math.floor(seconds % 60);
    if (remainingSeconds < 10) {
      remainingSeconds = `0${remainingSeconds}`;
    }
    return `${hours}:${minutes}:${remainingSeconds}`;
  };

  const bidStatusColour = (status) => {
    switch (status) {
      case "PENDING":
        return "warning";
      case "ACCEPTED":
        return "success";
      case "REJECTED":
        return "danger";
      case "WITHDRAWN":
        return "secondary";
      default:
        return "warning";
    }
  };

  // Get user type
  useEffect(() => {
    if (user.data && auctionVehicle) {
      const userTypeIndex = auctionVehicle?.auctionMembers?.findIndex(
        (member) => member.uid === user.data.uid
      );
      if (userTypeIndex !== -1) {
        setUserType(auctionVehicle?.auctionMembers[userTypeIndex].role);
      }
    }
  }, [user.data, JSON.stringify(auctionVehicle)]);

  if (userType === null) {
    return (
      <>
        <BackdropComponent loading={true} />
      </>
    );
  }

  if (userType === "BIDDER") {
    return (
      <Container>
        <Row>
          <Col>
            <Alert variant="danger">
              You are not authorised to view this page as you are a seller in
              this auction
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  if (
    auctionVehicle?.auctionMembers
      ?.map((member) => member.uid)
      .includes(user.data.uid) === false
  ) {
    return (
      <Container>
        <Row>
          <Col>
            <Alert variant="danger">
              You are not authorised to view this page
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <BackdropComponent loading={loading} />
      <ToastContainer />
      <Container>
        <Row>
          <Col>
            <Alert variant="info">
              You are viewing this page as a {userType}
            </Alert>
          </Col>
        </Row>
        <Row>
          {auctionVehicle && (
            <Col>
              <Card style={{ marginBottom: 5, textAlign: "left" }}>
                <Card.Header>
                  <Card.Text>
                    {`${auctionVehicle.VEHModelYear} ${auctionVehicle.VEHMake} ${auctionVehicle.VEHModel} ${auctionVehicle["Variant & Series"]}`}
                    &nbsp;
                    {auctionStarted && !auctionFinished && (
                      <Badge pill variant="success">
                        Auction Live -{" "}
                        {formatSecondsToTime(auctionTimeToFinish)} left
                      </Badge>
                    )}
                    {!auctionStarted && !auctionFinished && (
                      <Badge pill variant="warning">
                        Auction not started -{" "}
                        {formatSecondsToTime(auctionTimeToStart)} before it
                        starts
                      </Badge>
                    )}
                    {auctionFinished ? (
                      <Badge pill variant="danger">
                        Auction Ended
                      </Badge>
                    ) : (
                      ""
                    )}
                    &nbsp;
                    {markedAsNotInterested && (
                      <Badge pill variant="danger">
                        You have marked this vehicle as not interested
                      </Badge>
                    )}
                  </Card.Text>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={12} sm={6}>
                      <Card style={{ marginBottom: 5 }}>
                        <Card.Body>
                          <Card.Title>
                            <Row>
                              <Col>Stock #</Col>
                              <Col>{auctionVehicle?.VEHStockNumber}</Col>
                            </Row>
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">
                            <Row>
                              <Col>Location</Col>
                              <Col>{auctionVehicle?.branch_desc}</Col>
                            </Row>
                          </Card.Subtitle>

                          <Card.Text>
                            <Row>
                              <Col>Odo</Col>
                              <Col>
                                <NumberFormat
                                  value={auctionVehicle.VEHOdo}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </Col>
                            </Row>
                          </Card.Text>
                          <Card.Text>
                            <Row>
                              <Col>Colour</Col>
                              <Col>{auctionVehicle?.VEHColour1}</Col>
                            </Row>
                          </Card.Text>

                          <Card.Text>
                            <Row>
                              <Col>VIN</Col>
                              <Col>{auctionVehicle.VEHVIN}</Col>
                            </Row>
                          </Card.Text>

                          <Card.Text>
                            <Row style={{ color: "green" }}>
                              <Col>Auction Start</Col>
                              <Col>
                                {dayjs
                                  .unix(auctionVehicle.auctionStartDate)
                                  .format("DD/MM/YYYY HH:mm")}
                              </Col>
                            </Row>
                          </Card.Text>
                          <Card.Text>
                            <Row style={{ color: "orange" }}>
                              <Col>Auction End</Col>
                              <Col>
                                {dayjs
                                  .unix(auctionVehicle.auctionEndDate)
                                  .format("DD/MM/YYYY HH:mm")}
                              </Col>
                            </Row>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    {/* START VIEW FOR BUYER */}
                    {userType === "SELLER" && (
                      <Col>
                        <Card style={{ marginBottom: 5 }}>
                          <Card.Body>
                            <Card.Text>
                              <Row>
                                <Col>
                                  <Card.Title>
                                    Highest bid{" "}
                                    <Badge
                                      pill
                                      variant={bidStatusColour(
                                        highestBid?.bidStatus
                                      )}
                                    >
                                      {highestBid?.bidStatus === "ACCEPTED" ? (
                                        <>
                                          Accepted{" "}
                                          <NumberFormat
                                            value={highestBid?.winningBidAmount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                          />
                                        </>
                                      ) : (
                                        highestBid?.bidStatus
                                      )}
                                    </Badge>
                                  </Card.Title>
                                  {highestBid && (
                                    <Row>
                                      <Col>
                                        <NumberFormat
                                          value={highestBid?.bidAmount}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                        />{" "}
                                        bid placed{" "}
                                        {dayjs
                                          .unix(highestBid?.bidDate)
                                          .fromNow()}{" "}
                                        {highestBid?.bidStatus ===
                                          "COUNTER_OFFER" ||
                                          (highestBid?.counterAmount?.length >
                                            0 && (
                                            <>
                                              <br />
                                              <NumberFormat
                                                value={
                                                  highestBid?.counterAmount
                                                }
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                              />{" "}
                                              counter offered{" "}
                                              {dayjs
                                                .unix(highestBid?.counterDate)
                                                .fromNow()}{" "}
                                            </>
                                          ))}
                                      </Col>
                                    </Row>
                                  )}
                                  {!highestBid && (
                                    <Row>
                                      <Col>No active bids placed</Col>
                                    </Row>
                                  )}
                                </Col>
                              </Row>
                            </Card.Text>
                            <Row>
                              <Col>
                                <ButtonGroup>
                                  <Button
                                    onClick={acceptBid}
                                    size="sm"
                                    variant="outline-success"
                                    disabled={
                                      auctionFinished ||
                                      !auctionStarted ||
                                      markedAsNotInterested ||
                                      !highestBid ||
                                      [
                                        "ACCEPTED",
                                        "REJECTED",
                                        "WITHDRAWN",
                                        "COUNTER_OFFER",
                                      ].includes(highestBid.bidStatus)
                                    }
                                  >
                                    Accept bid
                                  </Button>
                                  <Button
                                    onClick={rejectBid}
                                    size="sm"
                                    variant="outline-danger"
                                    disabled={
                                      auctionFinished ||
                                      !auctionStarted ||
                                      markedAsNotInterested ||
                                      !highestBid ||
                                      [
                                        "ACCEPTED",
                                        "REJECTED",
                                        "WITHDRAWN",
                                        "COUNTER_OFFER",
                                      ].includes(highestBid.bidStatus)
                                    }
                                  >
                                    Reject bid
                                  </Button>
                                </ButtonGroup>

                                <Row className="mt-2">
                                  <Col>
                                    <Form.Group
                                      type="text"
                                      placeholder="Enter your counter offer"
                                    >
                                      <InputGroup>
                                        <NumberFormat
                                          style={{
                                            padding: 5,
                                            // // Green border
                                            // border: "1px solid #198754",
                                            // borderRadius: 5,
                                          }}
                                          as={Form.Control}
                                          value={highestBid?.counterAmount}
                                          placeholder="Enter your counter offer"
                                          displayType={"input"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                          disabled={
                                            auctionFinished ||
                                            !auctionStarted ||
                                            markedAsNotInterested ||
                                            !highestBid ||
                                            [
                                              "ACCEPTED",
                                              "REJECTED",
                                              "WITHDRAWN",
                                              "COUNTER_OFFER",
                                            ].includes(highestBid.bidStatus)
                                          }
                                          allowNegative={false}
                                          onChange={(e) =>
                                            setCounterAmount(
                                              e.target.value
                                                .replace("$", "")
                                                .replace(",", "")
                                            )
                                          }
                                        />
                                        <InputGroup.Append>
                                          <ButtonGroup>
                                            <Button
                                              size="sm"
                                              variant="warning"
                                              disabled={
                                                auctionFinished ||
                                                !auctionStarted ||
                                                markedAsNotInterested ||
                                                !highestBid ||
                                                [
                                                  "ACCEPTED",
                                                  "REJECTED",
                                                  "WITHDRAWN",
                                                  "COUNTER_OFFER",
                                                ].includes(highestBid.bidStatus)
                                              }
                                              onClick={() =>
                                                submitCounterOffer(
                                                  auctionVehicle
                                                )
                                              }
                                            >
                                              Submit counter offer
                                            </Button>
                                            <Button
                                              size="sm"
                                              variant="outline-danger"
                                              disabled={
                                                auctionFinished ||
                                                !auctionStarted ||
                                                markedAsNotInterested ||
                                                !highestBid ||
                                                [
                                                  "ACCEPTED",
                                                  "REJECTED",
                                                  "WITHDRAWN",
                                                ].includes(highestBid.bidStatus)
                                              }
                                              onClick={() =>
                                                submitCounterOffer(
                                                  auctionVehicle
                                                )
                                              }
                                            >
                                              Withdraw counter offer
                                            </Button>
                                          </ButtonGroup>
                                        </InputGroup.Append>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            {/* <Row style={{ marginTop: 15 }}>
                              <Col>
                                <Card.Title>Seller's counter offer:</Card.Title>
                              </Col>
                            </Row>
                            <Row>
                              <Col>-</Col>
                            </Row>
                            <Row>
                              <Col>
                                <ButtonGroup>
                                  <Button
                                    // onClick={placeBid}
                                    size="sm"
                                    variant="outline-success"
                                    disabled={true}
                                  >
                                    Accept offer
                                  </Button>
                                  <Button
                                    size="sm"
                                    variant="outline-danger"
                                    disabled={true}
                                  >
                                    Reject offer
                                  </Button>
                                </ButtonGroup>
                              </Col>
                            </Row> */}
                          </Card.Body>
                        </Card>
                      </Col>
                    )}
                    {/* END VIEW FOR SELLER */}
                  </Row>
                  <Row>
                    <Col>
                      {/* Display bid history in timeline format */}
                      <Card style={{ marginBottom: 5 }}>
                        <Card.Body>
                          <Card.Title>Bid history</Card.Title>
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr style={stickyTableHeaderStyle}>
                                <th>Date ↑</th>
                                <th>Bid Amount</th>
                                <th>Status</th>
                                <th>By</th>
                              </tr>
                            </thead>
                            <tbody style={{ fontSize: 12 }}>
                              {existingBidHistory?.map((bid, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {dayjs
                                        .unix(bid.historyDate)
                                        .format("DD/MM/YYYY HH:mm:ss")}
                                    </td>
                                    <td>
                                      <NumberFormat
                                        value={bid.bidAmount}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                      />
                                    </td>
                                    <td>
                                      <Badge
                                        pill
                                        variant={bidStatusColour(
                                          bid.historyType
                                        )}
                                      >
                                        {bid.historyType}
                                      </Badge>
                                    </td>
                                    <td>{bid.displayName}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Button
                    style={{ float: "right" }}
                    variant="outline-primary"
                    onClick={() => history.push("/auctions/internal-tenders")}
                  >
                    Back to list
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default InternalTendersVehicleSeller;
