import { useEffect, useState } from "react";
import { useUser } from "reactfire";
import { ToastContainer, toast } from "react-toastify";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import * as Yup from "yup";
import TextField from "../TextField";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaRegEdit } from "react-icons/fa";

import {
  Formik,
  ErrorMessage,
  FieldArray,
  useFormikContext,
  useField,
  Field,
} from "formik";
import { serverTimestamp } from "firebase/firestore";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const initialValues = {
  carrier: "",
  name: "",
  email: "",
  phone: "",
  routes: {},
  ezyauto_eclipseId: "",
  NorthshoreAuto_eclipseId: "",
  sycf_eclipseId: "",
  duttongwa_eclipseId: "",
  duttongarage_eclipseId: "",
};

const validationSchema = Yup.object().shape({
  carrier: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  phone: Yup.number().required("Required"),
  // eclipseId: Yup.number().required("Required"),
});

const ListCarriers = () => {
  const [carrierList, setCarrierList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewCarrierModal, setShowNewCarrierModal] = useState(false);
  const [showUpdateCarrierModal, setShowUpdateCarrierModal] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState({});

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const { data: user } = useUser();

  const handleCloseNewCarrierModal = () => setShowNewCarrierModal(false);
  const handleShowNewCarrierModal = () => setShowNewCarrierModal(true);

  const handleShowUpdateCarrierModal = (carrier) => {
    setSelectedCarrier(carrier);
    setShowUpdateCarrierModal(true);
  };
  const handleCloseUpdateCarrierModal = () => {
    setSelectedCarrier({});
    setShowUpdateCarrierModal(false);
  };

  //   Load all carriers
  useEffect(() => {
    setCarrierList([]);
    setIsLoading(true);
    const unsubscribe = firestore
      .collection("freight-carriers")
      .where("active", "==", true)
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          toast.info("No carriers found");
          setIsLoading(false);
          setCarrierList([]);
          return;
        }
        const tmpCarriers = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpCarriers.push({ ...tmpData, id: doc.id });
        });
        setCarrierList(tmpCarriers);
        setIsLoading(false);
      });

    return () => unsubscribe;
  }, []);

  return (
    <>
      <Container fluid>
        <Button
          block
          variant="outline-primary"
          onClick={handleShowNewCarrierModal}
        >
          Add new carrier
        </Button>
        <br />
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Carrier</th>
              <th>Contact Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Dutton Retail 1 EId</th>
              <th>Dutton Retail 2 EId</th>
              <th>SYCF EId</th>
              <th>Wholesale EId</th>
              <th>Garage EId</th>
            </tr>
          </thead>
          <tbody>
            {carrierList.map((c, idx) => {
              return (
                <>
                  <OverlayTrigger
                    placement="auto-start"
                    delay={{ show: 10, hide: 50 }}
                    overlay={<Tooltip id="button-tooltip-2">{c.id}</Tooltip>}
                  >
                    <tr>
                      <td>
                        <Button
                          block
                          variant="outline-warning"
                          onClick={() => handleShowUpdateCarrierModal(c)}
                        >
                          <FaRegEdit />
                        </Button>
                      </td>
                      <td>{c.carrier}</td>
                      <td>{c.name}</td>
                      <td>{c.email}</td>
                      <td>{c.phone}</td>
                      <td
                        style={{
                          backgroundColour:
                            c.ezyauto_eclipseId?.length === 0 ? "orange" : null,
                        }}
                      >
                        {c.ezyauto_eclipseId}
                      </td>
                      <td>{c.NorthshoreAuto_eclipseId}</td>
                      <td>{c.sycf_eclipseId}</td>
                      <td>{c.duttongwa_eclipseId}</td>
                      <td>{c.duttongarage_eclipseId}</td>
                      {/* <td>{JSON.stringify(c.routes, null, 2)}</td> */}
                    </tr>
                  </OverlayTrigger>
                </>
              );
            })}
          </tbody>
        </Table>
      </Container>

      {/* START NEW CARRIER MODAL */}
      <Modal
        show={showNewCarrierModal}
        onHide={handleCloseNewCarrierModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add new carrier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={async (values, { resetForm }) => {
              values.createdBy = user.uid;
              values.createdAt = serverTimestamp();
              // values.createdAt =
              //   firebaseconst.firestore.FieldValue.serverTimestamp();
              values.active = true;
              firestore
                .collection("freight-carriers")
                .add(values)
                .then((r) => {
                  resetForm();
                  handleCloseNewCarrierModal();
                  toast.success(`New carrier created with id ${r.id}`);
                });
            }}
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              isSubmitting,
              resetForm,
            }) => (
              <Form>
                <TextField
                  fieldname={`carrier`}
                  label="Carrier"
                  // placeholder="510123"
                  type="text"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <TextField
                  fieldname={`name`}
                  label="Contact name"
                  // placeholder="510123"
                  type="text"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TextField
                  fieldname={`phone`}
                  label="Phone"
                  // placeholder="510123"
                  type="tel"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <TextField
                  fieldname={`email`}
                  label="Email"
                  // placeholder="510123"
                  type="text"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TextField
                  fieldname={`ezyauto_eclipseId`}
                  label="Dutton Retail 1: Eclipse ID"
                  // placeholder="510123"
                  type="tel"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TextField
                  fieldname={`NorthshoreAuto_eclipseId`}
                  label="Dutton Retail 2: Eclipse ID"
                  // placeholder="510123"
                  type="tel"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TextField
                  fieldname={`sycf_eclipseId`}
                  label="SYCF: Eclipse ID"
                  // placeholder="510123"
                  type="tel"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TextField
                  fieldname={`duttongwa_eclipseId`}
                  label="Wholesale: Eclipse ID"
                  // placeholder="510123"
                  type="tel"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TextField
                  fieldname={`duttongarage_eclipseId`}
                  label="Dutton Garage: Eclipse ID"
                  // placeholder="510123"
                  type="tel"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <ButtonGroup block>
                  <Button
                    variant="outline-danger"
                    type="reset"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                  <Button variant="outline-success" onClick={handleSubmit}>
                    Create {isSubmitting ? <Spinner animation="grow" /> : null}
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNewCarrierModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* END NEW CARRIER MODAL */}

      {/* START UPDATE CARRIER MODAL */}
      <Modal
        show={showUpdateCarrierModal}
        onHide={handleCloseUpdateCarrierModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update carrier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={selectedCarrier}
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
              values.updatedBy = user.uid;
              values.lastModified = serverTimestamp();
              // values.lastModified =
              //   firebaseconst.firestore.FieldValue.serverTimestamp();
              values.active = true;
              firestore
                .collection("freight-carriers")
                .doc(selectedCarrier.id)
                .set(values, { merge: true })
                .then((r) => {
                  resetForm();
                  handleCloseUpdateCarrierModal();
                  toast.success(`Updated carrier ${values.carrier}`);
                });
            }}
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              isSubmitting,
              resetForm,
            }) => (
              <Form>
                <TextField
                  fieldname={`carrier`}
                  label="Carrier"
                  // placeholder="510123"
                  type="text"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <TextField
                  fieldname={`name`}
                  label="Contact name"
                  // placeholder="510123"
                  type="text"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TextField
                  fieldname={`phone`}
                  label="Phone"
                  // placeholder="510123"
                  type="tel"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <TextField
                  fieldname={`email`}
                  label="Email"
                  // placeholder="510123"
                  type="text"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TextField
                  fieldname={`ezyauto_eclipseId`}
                  label="Dutton Retail 1: Eclipse ID"
                  // placeholder="510123"
                  type="tel"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TextField
                  fieldname={`NorthshoreAuto_eclipseId`}
                  label="Dutton Retail 2: Eclipse ID"
                  // placeholder="510123"
                  type="tel"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TextField
                  fieldname={`sycf_eclipseId`}
                  label="SYCF: Eclipse ID"
                  // placeholder="510123"
                  type="tel"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TextField
                  fieldname={`duttongwa_eclipseId`}
                  label="Wholesale: Eclipse ID"
                  // placeholder="510123"
                  type="tel"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <TextField
                  fieldname={`duttongarage_eclipseId`}
                  label="Dutton Garage: Eclipse ID"
                  // placeholder="510123"
                  type="tel"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <ButtonGroup block>
                  <Button variant="outline-success" onClick={handleSubmit}>
                    Update {isSubmitting ? <Spinner animation="grow" /> : null}
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNewCarrierModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* END UPDATE CARRIER MODAL */}
      <ToastContainer />
    </>
  );
};

export default ListCarriers;
