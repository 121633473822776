import { ToastContainer, toast } from "react-toastify";
import BackdropComponent from "../BackdropComponent";
import {
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  Container,
  Spinner,
  Table,
  Badge,
  ButtonGroup,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  setDoc,
  doc,
  orderBy,
  getDoc,
  limit,
  collectionGroup,
  onSnapshot,
} from "firebase/firestore";
import { useFunctions, useUser, useFirestore } from "reactfire";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  useParams,
} from "react-router-dom";

const stickyTableHeaderStyle = {
  position: "sticky",
  margin: "0 0 0 0",
  top: -1,
  backgroundColor: "#566573",
  color: "white",
  fontSize: 12,
};

const TEST_AUCTION_VEHICLES = require("../../assets/testdata/auction_internal_tender_vehicles_test_060924.json");
const TEST_AUCTION_MEMBERS = [
  {
    uid: "IlBn3zUjpfYiqjnVi7e464OCbV13",
    displayName: "Kaneko",
    email: "kaneko@duttongroup.com.au",
    phoneNumber: "+61415447065",
    role: "BIDDER",
    status: "active",
    ownBranches: ["D1 MELBOURNE"],
  },

  {
    uid: "JknoVlb6Uic1SSjouHBqp2mjNH92",
    displayName: "TEST SELLER",
    email: "j.jayaram@duttongroup.com.au",
    phoneNumber: "+61489903845",
    role: "SELLER",
    status: "active",
    ownBranches: ["DG VIC"],
  },
];

// Unix timestamp for tomorrow 10am from now
const tomorrow10am = dayjs().add(1, "day").hour(10).minute(0).unix();
const today6pm = dayjs().hour(18).minute(0).unix();
const now = dayjs().unix();

const TEST_AUCTION_DATA = {
  auctionType: "INTERNAL_TENDER",
  auctionStatus: "LIVE",
  auctionTitle: "Internal tender test",
  auctionDescription: "This is a test auction",
  auctionStartDate: now,
  // auctionStartDate: today6pm,
  auctionEndDate: tomorrow10am,
  numberOfAuctionVehicles: TEST_AUCTION_VEHICLES.length,
  timeExtensionAllowed: true,
  auctionMembers: TEST_AUCTION_MEMBERS,
};

const InternalTenders = () => {
  const [auctionVehicles, setAuctionVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [myBids, setMyBids] = useState([]);
  const [activeAuctions, setActiveAuctions] = useState([]);
  const [activeAuctionsData, setActiveAuctionsData] = useState([]);
  const [highestBids, setHighestBids] = useState([]);
  const [userType, setUserType] = useState(null);
  //   Hooks
  //   const functions = useFunctions();
  const firestore = useFirestore();
  const user = useUser();

  const getTestAuctionVehicles = async () => {
    const yesterdayUnix = dayjs().subtract(1, "day").unix();

    const auctionVehiclesRef = collection(firestore, "inventory-summary");
    const q = query(
      auctionVehiclesRef,
      where("CurrentStatus", "==", "IN-STOCK"),
      where("db", "in", ["sycf", "duttongwa"])
      //   where("Purchase_Date", ">=", yesterdayUnix)
    );
    const querySnapshot = await getDocs(q);
    let auctionVehicles = [];
    // log size of snapshot
    console.log("Found:", querySnapshot.size);
    querySnapshot.forEach((doc) => {
      const id = doc.id;
      const data = doc.data();
      auctionVehicles.push({ id, ...data });
    });

    if (auctionVehicles.length === 0) {
      return [];
    }
    // // Filter null values for Purchase_Date
    // auctionVehicles = auctionVehicles.filter((vehicle) => {
    //   return vehicle.Purchase_Date !== null;
    // });

    // Filer for vehicles that arrived today

    let auctionVehiclesFromToday = auctionVehicles.filter((vehicle) => {
      return vehicle.Purchase_Date?.seconds >= yesterdayUnix;
    });

    // Add DD/MM/YYYY format for Purchase_Date to array
    auctionVehiclesFromToday = auctionVehiclesFromToday.map((vehicle) => {
      return {
        ...vehicle,
        Purchase_Date_Formatted: dayjs
          .unix(vehicle.Purchase_Date.seconds)
          .format("DD/MM/YYYY"),
      };
    });

    return auctionVehiclesFromToday;
  };

  //   Get all auction vehicles (Collection group) in live auctionStatusCar
  const getAuctionVehicles = async () => {
    setLoading(true);
    // Get collection group auction-vehicles

    const auctionVehicless = collectionGroup(firestore, "auction-vehicles");
    const q = query(auctionVehicless, where("auctionStatusCar", "==", "LIVE"));
    const querySnapshot = await getDocs(q);
    let auctionVehicles = [];

    // log size of snapshot
    console.log("Found live vehicles:", querySnapshot.size);
    querySnapshot.forEach((doc) => {
      const id = doc.id;
      const data = doc.data();
      auctionVehicles.push({ id, ...data });
    });

    setLoading(false);
    if (auctionVehicles.length === 0) {
      return [];
    }

    // Get unique auction IDs
    const uniqueAuctionIds = [
      ...new Set(auctionVehicles.map((vehicle) => vehicle.auctionId)),
    ];

    // Get active auctions data
    const activeAuctionsData = await Promise.all(
      uniqueAuctionIds.map(async (auctionId) => {
        const auctionRef = doc(firestore, "auctions", auctionId);
        const auctionData = await getDoc(auctionRef);
        return { id: auctionId, ...auctionData.data() };
      })
    );

    setActiveAuctionsData(activeAuctionsData);

    setActiveAuctions(uniqueAuctionIds);

    return auctionVehicles;
  };

  const createNewAuction = async (
    auctionData,
    auctionVehicles,
    auctionMembers
  ) => {
    console.log("Creating new auction");
    console.log(auctionData, auctionVehicles, auctionMembers);
    setLoading(true);
    let testBidFlag = false;
    try {
      // Create auction in firestore
      const createAuctionRef = collection(firestore, "auctions");
      const auctionDocRef = await addDoc(createAuctionRef, auctionData);
      const auctionId = auctionDocRef.id;
      console.log("Auction created with ID:", auctionId);

      // Add auction vehicles
      const auctionVehiclesRef = collection(
        firestore,
        "auctions",
        auctionId,
        "auction-vehicles"
      );

      auctionVehicles.forEach(async (vehicle) => {
        const vehiclePayload = {
          ...vehicle,
          auctionStatusCar: auctionData.auctionStatus,
          auctionId: auctionId,
          auctionType: auctionData.auctionType,
          auctionStartDate: auctionData.auctionStartDate,
          auctionEndDate: auctionData.auctionEndDate,
          auctionTitle: auctionData.auctionTitle,
          auctionDescription: auctionData.auctionDescription,
          auctionMembers: auctionMembers,
          auctionStatus: auctionData.auctionStatus,
          timeExtensionAllowed: auctionData.timeExtensionAllowed,
          auctionExtendedTime: 0,
          auctionExtendedBy: "",
          auctionExtendedReason: "",
          auctionExtendedTimeEnd: 0,
          auctionExtendedTimeStart: 0,
          reservePrice: vehicle["Asking Price"],
          winningBidStatus: "",
          won: false,
        };

        await setDoc(
          doc(firestore, "auctions", auctionId, "auction-vehicles", vehicle.id),
          vehiclePayload,
          { merge: true }
        );

        if (vehicle.id === "0bd2b4e2537973d6d83109361630b8ee") {
          console.log("Adding test bid", vehicle.id);

          const bidPayload = {
            auctionVehicle: vehiclePayload,
            auctionId: auctionId,
            vehicleId: vehicle.id,
            bidAmount: 23123,
            bidBy: "JknoVlb6Uic1SSjouHBqp2mjNH92",
            bidDate: 1726466016,
            bidStatus: "PENDING",
            displayName: "Juv Jay",
            email: "j.jayaram@duttongroup.com.au",
          };

          const bidPayload_higher = {
            auctionVehicle: vehiclePayload,
            auctionId: auctionId,
            vehicleId: vehicle.id,
            bidAmount: 26555,
            bidBy: "JknoVlb6Uic1SSjouHBqp2mjNH92",
            bidDate: 1726466019,
            bidStatus: "PENDING",
            displayName: "Juv Jay",
            email: "j.jayaram@duttongroup.com.au",
          };

          await addDoc(
            collection(
              firestore,
              "auctions",
              auctionId,
              "auction-vehicles",
              vehicle.id,
              "auction-bids"
            ),
            bidPayload
          );

          await addDoc(
            collection(
              firestore,
              "auctions",
              auctionId,
              "auction-vehicles",
              vehicle.id,
              "auction-bids"
            ),
            bidPayload_higher
          );
          testBidFlag = true;
        }
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      toast.error("Error creating auction vehicles");
    }

    setLoading(false);
    console.log("Auction vehicles added");

    toast.success("Auction created successfully");
  };

  const getMyBidByAuctionByVehicle = (auctionId, vehicleId) => {
    if (myBids.length === 0) {
      return "-";
    }
    // Filter myBids for this auction and vehicle
    const myBid = myBids.find((bid) => {
      return bid.auctionId === auctionId && bid.vehicleId === vehicleId;
    });
    if (!myBid) {
      return "No bid";
    }
    return myBid?.bidAmount;
  };

  const getHighestBidByAuctionByVehicle = (auctionId, vehicleId) => {
    if (highestBids?.length === 0) {
      return "-";
    }
    // Filter myBids for this auction and vehicle
    const highestBid = highestBids?.find((bid) => {
      return bid.auctionId === auctionId && bid.vehicleId === vehicleId;
    });
    if (!highestBid) {
      return "No bid";
    }
    return highestBid?.bidAmount;
  };

  const getHighestBidStatusByAuctionByVehicle = (auctionId, vehicleId) => {
    if (highestBids?.length === 0) {
      return "-";
    }
    // Filter myBids for this auction and vehicle
    const highestBid = highestBids?.find((bid) => {
      return bid.auctionId === auctionId && bid.vehicleId === vehicleId;
    });
    if (!highestBid) {
      return "No bid";
    }
    return highestBid?.bidStatus;
  };

  const getHighestBids = async () => {
    const tmpHighestBids = [];

    const getHighestBidByAuctionByVehicleFromDB = async (
      auctionId,
      vehicleId
    ) => {
      // Get highest bid for this vehicle from all bids
      const highestVehicleBidRef = collectionGroup(firestore, "auction-bids");
      const q = query(
        highestVehicleBidRef,
        where("auctionId", "==", auctionId),
        where("vehicleId", "==", vehicleId),
        where("bidStatus", "in", ["ACCEPTED", "PENDING"]),
        orderBy("bidAmount", "desc"),
        limit(1)
      );

      return new Promise((resolve, reject) => {
        const unsubscribe = onSnapshot(
          q,
          (querySnapshot) => {
            console.log(
              `Found ${querySnapshot.size} bids for vehicle ${vehicleId}`
            );
            if (querySnapshot.size === 0) {
              resolve({});
              return;
            }

            let highestBid = [];
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              highestBid.push({ id: doc.id, ...data });
            });

            console.log("Highest bid", highestBid, auctionId, vehicleId);
            resolve(highestBid[0]);
          },
          (error) => {
            console.error("Error fetching highest bid: ", error);
            reject(error);
          }
        );

        // Cleanup listener when done
        return () => unsubscribe();
      });
    };

    for (let i = 0; i < activeAuctions.length; i++) {
      const auctionId = activeAuctions[i];
      console.log("Getting highest bids for auction", auctionId);
      const auctionVehiclesInAuction = auctionVehicles.filter(
        (vehicle) => vehicle.auctionId === auctionId
      );
      for (let j = 0; j < auctionVehiclesInAuction.length; j++) {
        const vehicleId = auctionVehiclesInAuction[j].id;
        const highestBid = await getHighestBidByAuctionByVehicleFromDB(
          auctionId,
          vehicleId
        );
        const { bidAmount, bidBy, bidDate, bidStatus } = highestBid;

        tmpHighestBids.push({
          auctionId,
          vehicleId,
          bidAmount,
          bidBy,
          bidDate,
          bidStatus,
        });
      }
    }

    return tmpHighestBids;
  };

  const getMyBidStatusByAuctionByVehicle = (auctionId, vehicleId) => {
    if (myBids.length === 0) {
      return "-";
    }
    // Filter myBids for this auction and vehicle
    const myBid = myBids.find((bid) => {
      return bid.auctionId === auctionId && bid.vehicleId === vehicleId;
    });
    if (!myBid) {
      return "";
    }
    return myBid?.bidStatus;
  };

  const isVehicleNotInterested = (vehicle) => {
    // Check if current user has set this vehicle as not interested
    const auctionMemberIndex = vehicle?.auctionMembers?.findIndex(
      (member) => member.role === "BIDDER" && member.uid === user.data.uid
    );
    if (auctionMemberIndex === -1) {
      return false;
    }
    return vehicle?.auctionMembers[auctionMemberIndex]?.notInterestedInVehicle;
  };

  // Set vehicle as not interested
  const setVehicleAsNotInterested = async (vehicle) => {
    console.log("Setting vehicle as not interested", vehicle);

    // If bid exists then disallow setting as not interested
    const myBid = myBids.find((bid) => {
      return (
        bid.auctionId === vehicle.auctionId && bid.vehicleId === vehicle.id
      );
    });
    if (myBid) {
      toast.error(
        "Cannot set as not interested, you have already placed a bid"
      );
      return;
    }

    // Check if user has already set as not interested
    // Get auction member index
    const auctionMemberIndex = vehicle?.auctionMembers?.findIndex(
      (member) => member.role === "BIDDER" && member.uid === user.data.uid
    );
    if (auctionMemberIndex === -1) {
      toast.error("You are not a bidder for this vehicle");
      return;
    }
    // if (vehicle?.auctionMembers[auctionMemberIndex]?.notInterestedInVehicle) {
    //   toast.error("You have already set this vehicle as not interested");
    //   return;
    // }

    // Set vehicle as not interested
    const tmpMember = vehicle.auctionMembers[auctionMemberIndex];
    tmpMember.notInterestedInVehicle = true;
    const auctionMembers = [...vehicle["auctionMembers"]];

    await setDoc(
      doc(
        firestore,
        "auctions",
        vehicle.auctionId,
        "auction-vehicles",
        vehicle.id
      ),
      { auctionMembers },
      { merge: true }
    );
    console.log("Auction member", auctionMembers);
  };

  // Set vehicle as not interested
  const setVehicleAsInterested = async (vehicle) => {
    console.log("Setting vehicle as  interested", vehicle);

    // If bid exists then disallow setting as not interested
    const myBid = myBids.find((bid) => {
      return (
        bid.auctionId === vehicle.auctionId && bid.vehicleId === vehicle.id
      );
    });
    if (myBid) {
      toast.error(
        "Cannot set as not interested, you have already placed a bid"
      );
      return;
    }

    // Check if user has already set as not interested
    // Get auction member index
    const auctionMemberIndex = vehicle?.auctionMembers?.findIndex(
      (member) => member.role === "BIDDER" && member.uid === user.data.uid
    );
    if (auctionMemberIndex === -1) {
      toast.error("You are not a bidder for this vehicle");
      return;
    }
    if (vehicle?.auctionMembers[auctionMemberIndex]?.notInterestedInVehicle) {
      toast.error("You have already set this vehicle as not interested");
      return;
    }

    // Set vehicle as not interested
    const tmpMember = vehicle.auctionMembers[auctionMemberIndex];
    tmpMember.notInterestedInVehicle = false;
    const auctionMembers = [...vehicle["auctionMembers"]];

    await setDoc(
      doc(
        firestore,
        "auctions",
        vehicle.auctionId,
        "auction-vehicles",
        vehicle.id
      ),
      { auctionMembers },
      { merge: true }
    );
    console.log("Auction member", auctionMembers);
  };

  // Get user type
  const getUserType = (auctionMembers, userId) => {
    const userTypeIndex = auctionMembers?.findIndex(
      (member) => member.uid === userId
    );
    if (userTypeIndex !== -1) {
      return auctionMembers[userTypeIndex].role;
    }
    return null;
  };

  // Get details link
  const getDetailsLink = (auctionId, vehicleId, auctionMembers) => {
    const bidderLink = `/auctions/internal-tenders/bidder/${auctionId}/${vehicleId}`;
    const sellerLink = `/auctions/internal-tenders/seller/${auctionId}/${vehicleId}`;
    const userType = getUserType(auctionMembers, user.data.uid);
    if (userType === "BIDDER") {
      return bidderLink;
    }
    if (userType === "SELLER") {
      return sellerLink;
    }
    return bidderLink;
  };

  // Load vehicles
  useEffect(() => {
    const auctionVehicless = collectionGroup(firestore, "auction-vehicles");
    const q = query(auctionVehicless, where("auctionStatusCar", "==", "LIVE"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let auctionVehicles = [];
      // log size of snapshot
      console.log("Found live vehicles:", querySnapshot.size);
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        auctionVehicles.push({ id, ...data });
      });

      console.log("Auction vehicles", auctionVehicles);
      setAuctionVehicles(auctionVehicles);

      // Get unique auction IDs
      const uniqueAuctionIds = [
        ...new Set(auctionVehicles.map((vehicle) => vehicle.auctionId)),
      ];

      // Get active auctions data
      const activeAuctionsDataPromise = Promise.all(
        uniqueAuctionIds.map(async (auctionId) => {
          const auctionRef = doc(firestore, "auctions", auctionId);
          const auctionData = await getDoc(auctionRef);
          return { id: auctionId, ...auctionData.data() };
        })
      );
      activeAuctionsDataPromise.then((activeAuctionsData) => {
        setActiveAuctionsData(activeAuctionsData);
        setActiveAuctions(uniqueAuctionIds);

        setLoading(false);
      });
    });

    // Cleanup listener on unmount
    return () => unsubscribe();
    // getAuctionVehicles().then((data) => {
    //   console.log(data);
    //   setAuctionVehicles(data);
    // });
  }, []);

  // Load user bids
  useEffect(() => {
    if (!user.data) {
      return;
    }
    const auctionBidsRef = collectionGroup(firestore, "auction-bids");
    const q = query(auctionBidsRef, where("bidBy", "==", user.data.uid));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let auctionBids = [];
      // log size of snapshot
      console.log("Found bids:", querySnapshot.size);
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        auctionBids.push({ id, ...data });
      });

      if (auctionBids.length === 0) {
        return [];
      }

      // Filter bids for vehicles that are still live
      console.log("Unique auction IDs", activeAuctions);
      auctionBids = auctionBids.filter((bid) => {
        return activeAuctions.includes(bid.auctionId);
      });

      setMyBids(auctionBids);
    });

    return () => unsubscribe();
  }, [JSON.stringify(activeAuctions)]);

  // Load highest bids
  useEffect(() => {
    if (auctionVehicles?.length > 0) {
      getHighestBids().then((data) => {
        console.log("Highest bids", data);
        setHighestBids(data);
      });
    }
  }, [JSON.stringify(activeAuctions), JSON.stringify(auctionVehicles)]);

  // Get user type
  // useEffect(() => {
  //   if (user.data && act) {
  //     const userTypeIndex = auctionVehicles?.auctionMembers?.findIndex(
  //       (member) => member.uid === user.data.uid
  //     );
  //     if (userTypeIndex !== -1) {
  //       setUserType(auctionVehicles?.auctionMembers[userTypeIndex].role);
  //     }
  //   }
  // }, [user.data, JSON.stringify(auctionVehicles)]);

  return (
    <>
      <BackdropComponent loading={loading} />
      <ToastContainer />
      <Container>
        {activeAuctions?.map((auctionId, aidkey) => (
          <Row key={`auction-header-${auctionId}-${aidkey}`}>
            <Col>
              <Table
                striped
                bordered
                hover
                size="sm"
                responsive
                style={{ marginTop: 10 }}
              >
                <thead style={stickyTableHeaderStyle}>
                  <tr>
                    <th
                      colSpan={2}
                      style={{
                        textAlign: "left",
                        verticalAlign: "top",
                        borderRight: "0px",
                      }}
                    >
                      Auction ID: {auctionId}
                    </th>
                    <th
                      colSpan={1}
                      style={{
                        textAlign: "left",
                        verticalAlign: "top",
                        borderRight: "0px",
                      }}
                    >
                      {`Number of vehicles: ${
                        auctionVehicles?.filter(
                          (vehicle) => vehicle.auctionId === auctionId
                        )?.length
                      }`}
                    </th>
                    <th
                      colSpan={3}
                      style={{ textAlign: "left", verticalAlign: "top" }}
                    >
                      Starting:{" "}
                      {dayjs
                        .unix(
                          activeAuctionsData.find(
                            (auction) => auction.id === auctionId
                          )?.auctionStartDate
                        )
                        .format("DD/MM/YYYY HH:mm")}
                      <br />
                      Ending:{" "}
                      {dayjs
                        .unix(
                          activeAuctionsData.find(
                            (auction) => auction.id === auctionId
                          )?.auctionEndDate
                        )
                        .format("DD/MM/YYYY HH:mm")}
                    </th>

                    <th
                      colSpan={2}
                      style={{ textAlign: "left", verticalAlign: "top" }}
                    >
                      {`Auction type: ${
                        activeAuctionsData.find(
                          (auction) => auction.id === auctionId
                        )?.auctionType
                      }`}
                    </th>
                  </tr>
                  <tr>
                    <th>Stock#</th>
                    <th>Branch</th>
                    <th>Description</th>
                    <th>Auction End</th>
                    <th>Bid</th>
                    <th>Bid status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: 12 }}>
                  {auctionVehicles?.filter(
                    (vehicle) => vehicle.auctionId === auctionId
                  )?.length === 0 && (
                    <tr>
                      <td colSpan={8}>No vehicles for tender found</td>
                    </tr>
                  )}
                  {auctionVehicles
                    ?.filter((vehicle) => vehicle.auctionId === auctionId)
                    ?.map((vehicle) => {
                      return (
                        <tr
                          key={`${vehicle.auctionId}-${vehicle.id}`}
                          style={{
                            backgroundColor: isVehicleNotInterested(vehicle)
                              ? "#f8d7da"
                              : "",
                          }}
                        >
                          <td>{vehicle.VEHStockNumber}</td>
                          <td>{vehicle.branch_desc}</td>
                          <td style={{ textAlign: "left" }}>
                            {`${vehicle.VEHModelYear} ${vehicle.VEHMake} ${vehicle.VEHModel} ${vehicle["Variant & Series"]}`}
                            <br />
                            <NumberFormat
                              value={vehicle.VEHOdo}
                              displayType={"text"}
                              thousandSeparator={true}
                            />{" "}
                            kms
                            <br />
                            {vehicle.VEHColour1}
                          </td>
                          <td>
                            {dayjs
                              .unix(vehicle.auctionEndDate)
                              .format("DD/MM/YYYY")}
                          </td>
                          <td>
                            {/* Return info based on user role */}
                            {getUserType(
                              vehicle?.auctionMembers,
                              user.data.uid
                            ) === "BIDDER" && (
                              <NumberFormat
                                value={
                                  vehicle?.won
                                    ? vehicle?.winningBidAmount
                                    : getMyBidByAuctionByVehicle(
                                        vehicle.auctionId,
                                        vehicle.id
                                      )
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            )}
                            {getUserType(
                              vehicle?.auctionMembers,
                              user.data.uid
                            ) === "SELLER" && (
                              <NumberFormat
                                value={
                                  vehicle?.won
                                    ? vehicle?.winningBidAmount
                                    : getHighestBidByAuctionByVehicle(
                                        vehicle.auctionId,
                                        vehicle.id
                                      )
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            )}
                          </td>
                          <td>
                            {getUserType(
                              vehicle?.auctionMembers,
                              user.data.uid
                            ) === "BIDDER" && (
                              <>
                                {getMyBidStatusByAuctionByVehicle(
                                  vehicle.auctionId,
                                  vehicle.id
                                )}

                                {getMyBidStatusByAuctionByVehicle(
                                  vehicle.auctionId,
                                  vehicle.id
                                ) === "COUNTER_OFFER" && (
                                  <NumberFormat
                                    value={
                                      getMyBidByAuctionByVehicle(
                                        vehicle.auctionId,
                                        vehicle.id
                                      )?.counterAmount
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                )}
                              </>
                            )}

                            {getUserType(
                              vehicle?.auctionMembers,
                              user.data.uid
                            ) === "SELLER" && (
                              <>
                                {getHighestBidStatusByAuctionByVehicle(
                                  vehicle.auctionId,
                                  vehicle.id
                                )}
                              </>
                            )}
                          </td>
                          <td>
                            <ButtonGroup>
                              <Link
                                to={getDetailsLink(
                                  vehicle.auctionId,
                                  vehicle.id,
                                  vehicle.auctionMembers
                                )}
                              >
                                <Button size="sm" variant="outline-primary">
                                  {getUserType(
                                    vehicle?.auctionMembers,
                                    user.data.uid
                                  ) === "BIDDER" && <>Info / Bid</>}

                                  {getUserType(
                                    vehicle?.auctionMembers,
                                    user.data.uid
                                  ) === "SELLER" && <>Details</>}
                                </Button>
                              </Link>
                              {getUserType(
                                vehicle?.auctionMembers,
                                user.data.uid
                              ) === "BIDDER" && (
                                <Button
                                  size="sm"
                                  variant="outline-danger"
                                  onClick={() => {
                                    if (isVehicleNotInterested(vehicle)) {
                                      setVehicleAsInterested(vehicle);
                                    } else {
                                      setVehicleAsNotInterested(vehicle);
                                    }
                                  }}
                                >
                                  {isVehicleNotInterested(vehicle)
                                    ? "Interested"
                                    : "Not Interested"}
                                </Button>
                              )}
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
        ))}

        <Row>
          <Col>
            <Button
              onClick={() =>
                createNewAuction(
                  TEST_AUCTION_DATA,
                  TEST_AUCTION_VEHICLES,
                  TEST_AUCTION_MEMBERS
                )
              }
            >
              Create new auction
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InternalTenders;
