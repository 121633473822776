import { Cell, PieChart, Pie } from "recharts";
import useTransformToRechartsFormat from "../../../../hooks/useTransformToRechartsFormat";

const renderCustomizedLabel = (index, chartData, chartInfo) => {
  const labelString = `${chartData[index].name} - ${
    chartData[index][chartInfo.xaxis.name]
  }`;
  return labelString;
};

const PieChartDash = ({ os_graph_data, chartInfo }) => {
  const chartData = useTransformToRechartsFormat(os_graph_data, chartInfo);

  return (
    // <ResponsiveContainer width={500} height={400}>
    <PieChart width={500} height={500} data={chartData}>
      <Pie
        data={chartData}
        label={({ index }) =>
          renderCustomizedLabel(index, chartData, chartInfo)
        }
        legendType="rect"
        cx={120}
        cy={200}
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"
        paddingAngle={5}
        dataKey={chartInfo.xaxis.name}
      >
        {chartData.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={
              chartInfo.xaxis.pieColours[
                index % chartInfo?.xaxis.pieColours?.length
              ]
            }
          />
        ))}
      </Pie>
    </PieChart>
    // </ResponsiveContainer>
  );
};

export default PieChartDash;
