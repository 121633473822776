import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import NumberFormat from "react-number-format";
import { useEffect, useState } from "react";
import {
  ButtonGroup,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useFunctions, useUser } from "reactfire";
import { ToastContainer, toast } from "react-toastify";
import Table from "react-bootstrap/Table";
import dayjs from "dayjs";
import TextField from "./TextField";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import Switch from "react-switch";

import * as Yup from "yup";
import {
  Formik,
  ErrorMessage,
  FieldArray,
  useFormikContext,
  useField,
  Field,
} from "formik";
import { useCarrierList } from "../../hooks/useCarrierList";
import axios from "axios";
import { HiRefresh } from "react-icons/hi";
import { BsPencil } from "react-icons/bs";
import { FaRegWindowClose, FaHistory, FaRegSave, FaRedo } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import { BsCheck } from "react-icons/bs";
import { useClientList } from "../../hooks/useClientList";
import FormikAutoCompleteComponent from "../FormikAutoCompleteComponent";
import Pagination from "react-bootstrap/Pagination";
import { MdClear, MdSpeakerNotes } from "react-icons/md";
import { BiMessageRoundedAdd } from "react-icons/bi";
import DropdownButton from "react-bootstrap/DropdownButton";
import GoogleMapComponent from "./GoogleMapComponent";
import _ from "lodash";
import BackdropComponent from "../BackdropComponent";
import { serverTimestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const branchesArray = require("../../assets/json/branches-erp.json");

const statusList = [
  { name: "Request", title: "Open" },
  { name: "Booked", title: "Booked" },
  { name: "Transit", title: "In Transit" },
  { name: "Finalised", title: "Finalised" },
  { name: "Archive", title: "Archive" },
];

const validationSchemaCarrierInfo = Yup.object().shape({
  // carrier: Yup.object().required("Required"),
  // name: Yup.string().required("Required"),
  // phone: Yup.number().required("Required"),
  // eclipseId: Yup.number().required("Required"),
});

const initialValuesCarrierInfo = {
  carrier: { carrier: "", id: "" },
  estimatedCost: "",
  estimatedCollectionDate: "",
  estimatedDeliveryDate: "",
  actualDeliveredDate: "",
  comments: "",
  bookingId: "",
};

const initialValuesContactForm = {
  name: "",
  clientClass: "",
  comments: "",
  clientObj: {},
  location: {
    value: "",
    address: "",
    coordinates: {},
  },
  mobile: "",
  email: "",
};

const sectionHeaderStyle = {
  margin: 10,
  fontWeight: "bold",
  textDecoration: "underline",
  fontSize: 12,
};

const DIALATOWAPIURL = "https://hooks.zapier.com/hooks/catch/4611412/bykc4nt/";

const ClientSelectListComponent = (props) => {
  const {
    // values: { props.name.clientClass },
    setFieldValue,
  } = useFormikContext();

  // Get field context for name, mobile and address
  const fieldNameAddress = `location`;
  const fieldNameContact = `name`;
  const fieldNameMobile = `mobile`;
  const fieldNameEmail = `email`;
  const fieldNameComments = `comments`;

  // console.log(props.values[props.name].name);
  // console.log(props.values[props.name]);
  // console.log(props.values);

  // console.log(props.name);

  const [clientList] = useClientList();

  return (
    <Form.Group controlId={props.name}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Row>
        <Col>
          <Typeahead
            id="onclear-client-list"
            name={props.name}
            options={clientList}
            // onChange={props.handleChange}
            /* Set onBlur to handleBlur */
            onBlur={props.handleBlur}
            /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
            value={props.values[props.name].name}
            labelKey="name"
            {...props}
            onChange={(selected) => {
              if (selected.length > 0) {
                // console.log("selected", selected);
                const currentSelection = selected[0];
                setFieldValue(props.name, currentSelection);

                // Set related fields to selected value
                setFieldValue(fieldNameContact, currentSelection.Contact);
                setFieldValue(fieldNameMobile, currentSelection.Phone);
                setFieldValue(fieldNameEmail, currentSelection.Email);
                setFieldValue(fieldNameAddress, currentSelection.location);
                setFieldValue(fieldNameComments, currentSelection.comments);
              } else if (selected.length === 0) {
                setFieldValue(props.name, {});
                // Set related fields to empty
                setFieldValue(fieldNameContact, "");
                setFieldValue(fieldNameMobile, "");
                setFieldValue(fieldNameEmail, "");
                setFieldValue(fieldNameAddress, {});
                setFieldValue(fieldNameComments, "");
              }
            }}
            // value={values.}
            placeholder="Choose internal dealer to auto populate fields..."
          >
            {({ onClear, selected }) => (
              <div className="rbt-aux">
                {!!selected.length && <ClearButton onClick={onClear} />}
                {!selected.length && <Spinner animation="grow" size="sm" />}
              </div>
            )}
          </Typeahead>
        </Col>
      </Form.Row>
    </Form.Group>
  );
};

const CarrierSelectListComponent = (props) => {
  const {
    // values: { props.name.clientClass },
    setFieldValue,
  } = useFormikContext();
  const [field, meta, helpers, setValue] = useField(props.fieldname);

  const fieldCarrier = `carrier`;

  const { value } = meta;

  const [carrierList] = useCarrierList();

  return (
    <Form.Group controlId={`${props.key}-${props.name}`}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Row>
        <Col>
          <Typeahead
            id={`onclear-carrier-list-${props.values.bookingId}`}
            name={props.name}
            options={carrierList}
            // onChange={props.handleChange}
            /* Set onBlur to handleBlur */
            onBlur={props.handleBlur}
            /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
            // value={value}
            labelKey="carrier"
            selected={[value]}
            // selected={value}
            onChange={(selected) => {
              if (selected.length > 0) {
                // console.log("selected", selected[0]);
                const currentSelection = selected[0];
                setFieldValue(props.name, currentSelection);
              } else if (selected.length === 0) {
                setFieldValue(props.name, { carrier: "", id: "" });
              }
            }}
            // value={values.}
            placeholder="Choose carrier..."
          >
            {({ onClear, selected }) => (
              <div className="rbt-aux">
                {!!selected.length && <ClearButton onClick={onClear} />}
                {!selected.length && <Spinner animation="grow" size="sm" />}
              </div>
            )}
          </Typeahead>
        </Col>
      </Form.Row>
    </Form.Group>
  );
};

const BookingDesktopView = ({ data, nextStage, idx }) => {
  const [mailToString, setMailToString] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [directions, setDirectionInformation] = useState({});
  const [activeNotesPage, setActiveNotesPage] = useState(1);
  const [notesPageSize, setNotesPageSize] = useState(4);
  const [notesCollection, setNotesCollection] = useState([]);
  const [pendingQueuedActions, setPendingQueuedActions] = useState([]);
  const [queueLog, setQueueLog] = useState([]);
  const [yardLocation, setYardLocation] = useState("");
  const [yardLocationComments, setYardLocationComments] = useState("");
  const [isLoadingYardLocationUpdate, setIsLoadingYardLocationUpdate] =
    useState(false);
  const [isLoadingCostUpdate, setIsLoadingCostUpdate] = useState(false);

  const [contactUpdateType, setContactUpdateType] = useState("");
  const [toggleCollectedInSugar, setToggleCollectedInSugar] = useState(false);
  const [eclipseDbToCost, setEclipseDbToCost] = useState({ name: "", id: "" });

  // --- MODAL STATES

  const [showCarrierInfoModal, setShowCarrierInfoModal] = useState(false);
  const [showChooseVehicleModal, setShowChooseVehicleModal] = useState(false);
  const [showChooseContactModal, setShowChooseContactModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showNoteCreteModal, setShowNoteCreateModal] = useState(false);
  const [showYardLocationModal, setShowYardLocationModal] = useState(false);
  const [showCheckBoxActionsModal, setShowCheckBoxActionsModal] =
    useState(false);
  const [showSendCostToEclipseModal, setShowSendCostToEclipseModal] =
    useState(false);

  const [isEditingVin, setIsEditingVIN] = useState([]);
  const [isEditingChargeTo, setIsEditingChargeTo] = useState(false);

  const [isSubmittingToFreightApi, setIsSubmittingToFreightApi] =
    useState(false);
  const [carrierFormValues, setCarrierFormValues] = useState(
    initialValuesCarrierInfo
  );
  const [localVIN, setLocalVIN] = useState([]);
  const [localChargeTo, setLocalChargeTo] = useState("");

  const [localVehiclesFromSearch, setLocalVehiclesFromSearch] = useState([]);
  const [localVehiclesIndexToUpdate, setLocalVehicleIndexToUpdate] =
    useState(0);
  const [hasSelected, setHasSelected] = useState(false);

  // --- HOOKS
  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const functions = useFunctions();
  const user = useUser();
  const [carrierList] = useCarrierList();
  const [loading, setLoading] = useState(false);

  const handleCloseCarrierInfoModal = () => setShowCarrierInfoModal(false);
  const handleShowCarrierInfoModal = () => setShowCarrierInfoModal(true);

  const getBranchObjFromBranches = (branch) => {
    const output = branchesArray.find((b) => b.branch === branch);
    if (typeof output === "undefined") {
      return { db: null };
    } else {
      return output;
    }
  };

  const getCarrierObjectFromId = (id) => {
    const output = carrierList.find((c) => c.id === id);
    if (typeof output === "undefined") {
      return { db: null };
    } else {
      return output;
    }
  };

  // Push yard location to action queue which is picked up in Eclipse
  const pushYardLocationToEclipse = async () => {
    // Validate

    if (yardLocation.length === 0) {
      toast.warning("Please choose yard location");
      return;
    }
    if (yardLocationComments.length === 0) {
      toast.warning("Please enter comments");
      return;
    }
    setIsLoadingYardLocationUpdate(true);

    // Check if pending yard log update is already queued
    const queueEmpty = await firestore
      .collection("freight-bookings")
      .doc(data.id)
      .collection("queued-actions")
      .where("actioned", "==", false)
      .where("action", "==", "FREIGHT_YARD_LOG_UPDATE")
      .get()
      .then((doc) => {
        console.log(doc.empty);

        return doc.empty;
      });

    if (queueEmpty === false) {
      toast.error(
        "An update to Eclipse is still pending, please wait for this to finish and trying again"
      );
      setIsLoadingYardLocationUpdate(false);
      return;
    }

    let branchObj = getBranchObjFromBranches(data.vehicleItem[0]?.branch);
    // let carrierIdObj = getCarrierObjectFromId(data.carrierData?.carrier.id);

    // Add data to insert queue

    const payload = {
      bookingId: data.id,
      comments: yardLocationComments,
      bookingStatus: data.status,
      createdAt: dayjs().format("YYYY-MM-DD"),
      createdBy: user.data.displayName,
      pickup: data.pickup?.location.address,
      dropOff: data.dropOff?.location.address,
      distance: data.directions[0]?.distanceKm / 1000,
      VEHStockNumber: data.vehicleItem[0]?.EclipseStockNumber,
      VIN: data.vehicleItem[0]?.VIN,
      originalBranch: data.vehicleItem[0]?.branch,
      yardLocation: yardLocation,
      EclipseDBName: branchObj.db,
      EclipseBranchID: branchObj.branch_id,
      carrierEstimateCollection: data.carrierData?.estimatedCollectionDate,
      carrierEstimateDelivery: data.carrierData?.estimatedDeliveryDate,

      actioned: false,
      actionStatus: null,
      actionedTimestamp: null,
      action: "FREIGHT_YARD_LOG_UPDATE",
    };

    // Save to queue
    await firestore
      .collection("freight-bookings")
      .doc(data.id)
      .collection("queued-actions")
      .add(payload)
      .then((r) => {
        toast.success("Queued to process in Eclipse");
      })
      .catch((e) => {
        toast.warning(e.message);
      });

    setIsLoadingYardLocationUpdate(false);
    setShowYardLocationModal(false);
  };

  const getHistory = async () => {
    firestore
      .collection("freight-bookings")
      .doc(data?.id)
      .collection("audit-log");
  };

  const setVinToEdit = (idx, vin) => {
    // console.log("set", idx);
    // console.log("set", localVIN);
    // console.log("set", isEditingVin);
    let tmpLocalVin = [...localVIN];
    let tmpIsEditingVin = [...isEditingVin];
    tmpLocalVin[idx] = vin;
    tmpIsEditingVin[idx] = true;
    // console.log("editing", tmpIsEditingVin);

    setLocalVIN(tmpLocalVin);
    setIsEditingVIN(tmpIsEditingVin);
  };

  const setVinToReadOnly = (idx) => {
    let tmpIsEditingVin = [...isEditingVin];
    tmpIsEditingVin[idx] = false;
    setIsEditingVIN(tmpIsEditingVin);
  };

  const editVinHandler = (idx, vin) => {
    // console.log("edit", idx);
    // console.log("edit", vin);
    let tmpLocalVin = [...localVIN];
    tmpLocalVin[idx] = vin;
    setLocalVIN(tmpLocalVin);
  };

  const setStage = async (stage) => {
    // console.log(`user: ${user.data.uid}`);
    // console.log(`Change status to ${nextStage}`);
    setIsUpdating(true);
    await firestore
      .collection("freight-bookings")
      .doc(data?.id)
      .set(
        {
          status: stage,
          lastUpdatedDate: dayjs().unix(),
          lastUpdatedBy: user.data.uid,
        },
        { merge: true }
      )
      .then((r) => {
        toast.success(`Updated status to ${stage}`);
      });

    setIsUpdating(false);
  };

  const printCarrierId = () => {
    if (typeof data === "undefined") {
      return 0;
    }
    let branchObj = getBranchObjFromBranches(data.vehicleItem[0]?.branch);
    if (typeof branchObj === "undefined") {
      return 0;
    }

    let carrierIdObj = getCarrierObjectFromId(data.carrierData?.carrier.id);
    if (typeof carrierIdObj === "undefined") {
      return 0;
    }
    const carrierIdFieldKey = `${branchObj.db}_eclipseId`;
    const id = carrierIdObj[carrierIdFieldKey];
    return id;
  };

  // Push cost to action queue to be processed in Eclipse
  const addCostToEclipse = async () => {
    const carrierId = printCarrierId();
    if (
      parseInt(carrierId) === 0 ||
      isNaN(carrierId) ||
      carrierId.length === 0
    ) {
      toast.error(
        "Carrier ID missing for this database in the Carrier list, please update and try again"
      );
      return;
    }

    if (
      data.carrierData?.carrierBookingReference.length === 0 ||
      typeof data.carrierData?.carrierBookingReference === "undefined"
    ) {
      toast.error(
        "Carrier booking reference missing, please enter this and try again"
      );
      return;
    }
    setIsLoadingCostUpdate(true);

    // Check vehicle status - show status
    //

    // Check if pending cost update is already queued
    const queueEmpty = await firestore
      .collection("freight-bookings")
      .doc(data.id)
      .collection("queued-actions")
      .where("actioned", "==", false)
      .where("action", "==", "FREIGHT_COST_UPDATE")
      .get()
      .then((doc) => {
        console.log(doc.empty);

        return doc.empty;
      });

    if (queueEmpty === false) {
      toast.error(
        "An update to Eclipse is still pending, please wait for this to finish and trying again"
      );
      setIsLoadingCostUpdate(false);
      return;
    }

    let branchObj = getBranchObjFromBranches(data.vehicleItem[0]?.branch);

    //Agreed upon CONST to identify audit log in Eclipse for freight cost only
    // Using local yard location rather than STATE
    const yardLocation = "COST_ONLY";

    // Add data to insert queue

    const payload = {
      bookingId: data.id,
      comments: data.carrierData?.comments,
      bookingStatus: data.status,
      createdAt: dayjs().format("YYYY-MM-DD"),
      createdBy: user.data.displayName,
      pickup: data.pickup?.location.address,
      dropOff: data.dropOff?.location.address,
      distance: data.directions[0]?.distanceKm / 1000,
      VEHStockNumber: data.vehicleItem[0]?.EclipseStockNumber,
      VIN: data.vehicleItem[0]?.VIN,
      originalBranch: data.vehicleItem[0]?.branch,
      carrierEstimateCost: data.carrierData?.estimatedCost,
      carrierEstimateCollection: data.carrierData?.estimatedCollectionDate,
      carrierEstimateDelivery: data.carrierData?.estimatedDeliveryDate,
      carrierBookingID: data.carrierData?.carrierBookingReference,

      yardLocation: yardLocation,
      EclipseDBName: branchObj.db,
      EclipseBranchID: branchObj.branch_id,
      CarrierSupplierID: printCarrierId(),

      actioned: false,
      actionStatus: null,
      actionedTimestamp: null,
      action: "FREIGHT_COST_UPDATE",
    };

    console.log(payload);

    // Save to queue
    const actionPushResponse = await firestore
      .collection("freight-bookings")
      .doc(data.id)
      .collection("queued-actions")
      .add(payload)
      .then((r) => {
        toast.success("Queued to process in Eclipse");
      })
      .catch((e) => {
        toast.warning(e.message);
      });

    setIsLoadingCostUpdate(false);
    setShowSendCostToEclipseModal(false);
  };

  const updateChargeToField = async () => {
    setIsUpdating(true);
    let currentData = data;
    currentData.chargeTo.name = localChargeTo;
    await firestore
      .collection("freight-bookings")
      .doc(data.id)
      .set(currentData, { merge: true })
      .then((r) => {
        toast.warning("Updated");
      });
    setIsUpdating(false);
  };

  const reloadVehicleInfo = async (item, vin) => {
    setIsUpdating(true);

    const vehicles = await getDataByVIN(vin);

    if (vehicles === null) {
      setIsUpdating(false);
      return;
    } else {
      if (vehicles.length === 1) {
        await updateVehicle(item, vehicles[0]);
      } else if (vehicles.length > 1) {
        // Choose which
        // Set temp local search results
        // Open modal to choose
        setLocalVehiclesFromSearch(vehicles);
        setLocalVehicleIndexToUpdate(item);
        setShowChooseVehicleModal(true);
      }
    }
    setIsUpdating(false);
  };

  const updateVehicle = async (item, selectedVeh) => {
    // Save back to db with updated information
    console.log("Saving");
    const currentData = data;
    const payload = {
      branch: selectedVeh.branch_desc,
      EclipseStockNumber: selectedVeh.VEHStockNumber,
      make: selectedVeh.VEHMake,
      model: selectedVeh.VEHModel,
      variant: selectedVeh["Variant & Series"],
      colour: selectedVeh.VEHColour1,
      year: selectedVeh.VEHModelYear,
      VIN: selectedVeh.VEHVIN,
      comments: currentData.vehicleItem[item].comments,
    };

    currentData.vehicleItem[item] = payload;

    await firestore
      .collection("freight-bookings")
      .doc(data.id)
      .set(currentData, { merge: true })
      .then((r) => {
        toast.warning(`Updated`);
        setShowChooseVehicleModal(false);
      });
  };

  const closeShowVehicleModalOnConfirm = () => {
    if (hasSelected) {
      setShowChooseVehicleModal(false);
    }
  };

  const getDataByVIN = async (vinManual = "") => {
    var vehicles = null;
    let vinLocal = "";
    // Clear other field values

    if (vinManual.length > 0) {
      vinLocal = vinManual;
    } else {
      vinLocal = vinManual;
    }

    var searchFullVIN = true;
    if (vinLocal.length === 0 || vinLocal.length < 15) {
      if (vinLocal.length === 6) {
        // If 6 digits, search truncated VIN (last 6 digits)
        searchFullVIN = false;
      } else {
        toast.warning("VIN doesn't seem correct, please check");
        return null;
      }
    }

    if (searchFullVIN) {
      vehicles = firestore
        .collection("inventory-summary")
        .where("VEHVIN", "==", vinLocal);
    } else {
      vehicles = firestore
        .collection("inventory-summary")
        .where("VINTrunc", "==", vinLocal);
    }

    return await vehicles
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          // querySnapshot
          //   const vehData = doc.data();
          toast.success(`Found ${querySnapshot.size} vehicle in stock`);
          const vehTmp = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            vehTmp.push(data);
          });
          // Set  array
          if (vehTmp.length > 0) {
            if (vehTmp.length === 1) {
              toast.info(
                `${vehTmp[0].VEHStockNumber} - ${vehTmp[0].VEHMake} - ${vehTmp[0].VEHModel}`
              );
            } else if (vehTmp.length > 1) {
              toast.success(
                `Found ${vehTmp.length} stock items with the same VIN`
              );
              // setMultipleVehiclesChoiceArray(vehTmp);
              // setShowChooseVehicleModal(true);
            }
          }
          return vehTmp;

          //   console.log(`${vehData}`);
        } else {
          toast.error(
            "Nothing to update: No vehicle found in stock, check VIN"
          );
          return null;
        }
      })
      .catch((e) => {
        toast.error(e.message);
        return null;
      });
  };

  const setCheckBoxActionsModal = (open) => {
    setShowCheckBoxActionsModal(true);
  };

  // Send API request to dial a tow with booking data
  const sendAPITriggerToDialATow = async () => {
    if (data.sentDataToFreightApi) {
      toast.warning("Already sent to Dial A Tow, not sending again");
      return;
    }
    // Send for each vehicle in data set
    for (var i = 0; i < data?.vehicleItem.length; i++) {
      // Setup payload
      setIsSubmittingToFreightApi(true);
      const payload = {
        duttonId: data?.id,
        duttonCarrierId: data?.carrierData?.carrier?.id,

        originAddress: data?.pickup?.location?.address,
        originContactName: data?.pickup?.name,
        originContactPhone: data?.pickup?.mobile,
        originContactEmail: data?.pickup?.email,

        destinationAddress: data?.dropOff?.location?.address,
        destinationContactName: data?.dropOff?.name,
        destinationContactPhone: data?.dropOff?.mobile,
        destinationContactEmail: data?.dropOff?.email,

        vehicleVIN: `${data?.vehicleItem[i]?.VIN}--TEST`,
        vehicleStockId: data?.vehicleItem[i]?.EclipseStockNumber,
        vehicleMake: data?.vehicleItem[i]?.make,
        vehicleModel: data?.vehicleItem[i]?.model,
        vehicleComments: data?.vehicleItem[i]?.comments,
      };
      // console.log(payload);
      toast.warning("TEST ONLY FOR NOW");

      // Send request form decoded
      const encodeForm = (data) => {
        return Object.keys(data)
          .map(
            (key) =>
              encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
          )
          .join("&");
      };

      await axios
        .post(DIALATOWAPIURL, encodeForm(payload), {
          headers: { Accept: "application/json" },
        })
        .then(async function (r) {
          // console.log("sent");
          console.log(r.data);
          let res = r.data;
          if (res.status === "success") {
            // Add response info to firestore document
            let logData = {};
            logData.user = user.data.uid;
            logData.displayName = user.data.displayName;
            logData.userPhone = user.data.phoneNumber;
            logData.createdAt = serverTimestamp();
            // logData.createdAt =
            // firebaseconst.firestore.FieldValue.serverTimestamp();
            logData.carrierId = data?.carrierData?.carrier?.id;

            res.logData = logData;

            await firestore
              .collection("freight-bookings")
              .doc(data.id)
              .collection("apiLogs")
              .add(res);

            await firestore
              .collection("freight-bookings")
              .doc(data.id)
              .set({ sentDataToFreightApi: true }, { merge: true });
            toast.success("Sent to Dial a Tow");
          }
          setIsSubmittingToFreightApi(false);
        })
        .catch(function (error) {
          console.log(error);
          setIsSubmittingToFreightApi(false);
          toast.error(error.message);
        });
    }
  };

  const reloadYardLocationComments = () => {
    const tmpString = `ETC: ${data?.carrierData?.estimatedCollectionDate}, ETD: ${data?.carrierData?.estimatedDeliveryDate}, ${data?.carrierData?.carrier.carrier}`;
    setYardLocationComments(tmpString);
  };

  const paginationItemsForNotes = () => {
    let numberOfNotes = notesCollection.length;
    let active = activeNotesPage;
    let items = [];
    let pageCount = 1;
    for (let number = 1; number <= numberOfNotes; number++) {
      if (
        (number % notesPageSize === 0 && numberOfNotes > notesPageSize) ||
        number === 1
      ) {
        items.push(pageCount);
        pageCount++;
      }
    }
    return items.map((number) => {
      return (
        <Pagination.Item
          key={number}
          active={number === active}
          onClick={(e) => {
            setActiveNotesPage(parseInt(e.target.text));
          }}
        >
          {number}
        </Pagination.Item>
      );
    });
  };

  // Toggle freight collected in Sugar
  const updateLeadWithFreightCollectedInSugar = async (collection_status) => {
    if (data?.extraDetails?.sugarCrmId?.length > 0) {
      // Update
      const payload = {
        leadData: {
          id: data?.extraDetails?.sugarCrmId,
          collection_done: collection_status,
        },
      };

      setLoading(true);
      var updateLead = httpsCallable(
        functions,
        "toggleFreightCollectedInSugarCRM"
      );
      // var updateLead = firebase
      // .functions()
      // .httpsCallable("toggleFreightCollectedInSugarCRM");
      await updateLead(payload)
        .then(async (result) => {
          // Read result of the Cloud Function.
          var sanitizedMessage = result.data;
          if (sanitizedMessage === false) {
            throw "Error";
          } else {
            if (sanitizedMessage.error?.length > 0) {
              toast.warning(sanitizedMessage.error);
              if (sanitizedMessage.errorCode === 933) {
                //Multiple Redbook codes found
                // setMultipleRedbookFound(sanitizedMessage.rbcodes);
              }
            } else {
              await firestore
                .collection("freight-bookings")
                .doc(data.id)
                .set(
                  {
                    extraDetails: {
                      collection_done: payload.leadData.collection_done,
                    },
                  },
                  { merge: true }
                )
                .then((r) => {
                  toast.success("Update lead in SugarCRM");
                });
              // console.log(sanitizedMessage);
              // setSearchLeadResults(sanitizedMessage.records);
            }
          }
        })
        .catch((e) => {
          toast.error(e);
          console.log(e);
        });
      setLoading(false);
    } else {
      toast.warning("This is not a Sugar CRM lead, nothing to do here");
    }
  };

  // Load notes collection
  useEffect(() => {
    const unsub = firestore
      .collection("freight-bookings")
      .doc(data.id)
      .collection("notes")
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          // toast.info("No notes available");
          setNotesCollection([]);
          return;
        }
        const tmpBookings = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpBookings.push({ ...tmpData, id: doc.id });
          //   console.log(`Getting bookings`);
        });
        // console.log(tmpBookings);
        setNotesCollection(tmpBookings);
        // setIsLoading(false);
      });
    return () => unsub();
  }, []);

  // Load pending actions collection
  useEffect(() => {
    const unsub = firestore
      .collection("freight-bookings")
      .doc(data.id)
      .collection("queued-actions")
      .where("actionStatus", "!=", "Complete")
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          // toast.info("No notes available");
          setPendingQueuedActions([]);
          return;
        }
        const tmpBookings = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpBookings.push({ ...tmpData, id: doc.id });
          //   console.log(`Getting bookings`);
        });
        // console.log(tmpBookings);
        setPendingQueuedActions(tmpBookings);
        // setIsLoading(false);
      });
    return () => unsub();
  }, []);

  // Load pending actions collection
  useEffect(() => {
    const unsub = firestore
      .collection("freight-bookings")
      .doc(data.id)
      .collection("queued-actions")
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          // toast.info("No notes available");
          setQueueLog([]);
          return;
        }
        let tmpBookings = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();
          tmpBookings.push({ ...tmpData, id: doc.id });
          //   console.log(`Getting bookings`);
        });
        tmpBookings = tmpBookings.sort((a, b) => {
          return a.actionedTimestamp?.seconds - b.actionedTimestamp?.seconds;
        });
        // console.log(tmpBookings);
        setQueueLog(tmpBookings);
        // setIsLoading(false);
      });
    return () => unsub();
  }, []);

  // update booking card form initial values
  useEffect(() => {
    console.log("Data change");
    // Reset carrier form values
    setCarrierFormValues(initialValuesCarrierInfo);
    if (data?.carrierData) {
      const cd = data.carrierData;
      setCarrierFormValues({ ...cd });
    } else {
      // Set booking id
      let tmpcarrierFV = carrierFormValues;
      tmpcarrierFV.bookingId = data.id;
      setCarrierFormValues(tmpcarrierFV);
    }
  }, [JSON.stringify(data)]);

  // Update localVIN array and isEditingVin array to have info from database
  useEffect(() => {
    for (var i = 0; i < data.vehicleItem.length; i++) {
      localVIN[i] = data.vehicleItem[i].VIN;
      // Set is editing VIN to false for all vehicles in dataset
      setIsEditingVIN[i] = false;
    }
  }, [JSON.stringify(data.vehicleItem)]);

  useEffect(() => {
    var tmpStr = `Hi%0D%0A%0D%0APlease book the following for pickup and delivery ASAP. Charged to: ${data.chargeTo?.name}%0D%0A%0D%0A`;
    tmpStr = `${tmpStr}%0D%0APickup%0D%0A${data.pickup?.location?.address}%0D%0A${data.pickup?.clientObj?.name}%0D%0A${data.pickup?.name} ${data.pickup?.mobile}%0D%0A${data.pickup?.comments}`;
    tmpStr = `${tmpStr}%0D%0A%0D%0ADelivery%0D%0A${data.dropOff?.location?.address}%0D%0A${data.dropOff?.clientObj?.name}%0D%0A${data.dropOff?.name} ${data.dropOff?.mobile}%0D%0A${data.dropOff?.comments}`;

    data.vehicleItem.forEach((v, idx) => {
      tmpStr = `${tmpStr}%0D%0A%0D%0AVehicle ${idx + 1}%0D%0A${v.make} ${
        v.model
      } ${v.VIN}`;
    });

    tmpStr = `${tmpStr}%0D%0A%0D%0AHow much will this cost and when do you intend to collect/deliver?%0D%0A%0D%0AThank you.`;
    tmpStr = tmpStr.replace(/&/g, "and");

    setMailToString(`ENTEREMAIL?subject=NEW BOOKING CHARGED TO&body=${tmpStr}`);
  }, [JSON.stringify(data)]);

  return (
    <>
      <BackdropComponent loading={loading} />
      <ToastContainer />

      {/* START BOOKING SECTION */}
      <Container>
        <Card
          style={{
            fontSize: 14,
            marginBottom: 25,
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
          }}
        >
          <Table size="sm">
            <thead>
              <tr>
                <th>Booking ID</th>
                <th>Stock #</th>
                <th>Booking Created</th>

                <th>Current Status</th>
                <th>Created By</th>

                <th>Origin</th>
                {data.extraDetails?.sugarCrmLeadData?.assigned_user_name
                  .length > 0 ? (
                  <th>Buyer</th>
                ) : null}
                <th>Link to CRM</th>
              </tr>
            </thead>
            <tbody>
              <td>{data.id}</td>
              <td>{data.indexStockId?.map((s) => s).join(",")}</td>
              <td>
                {dayjs.unix(data.createdAt?.seconds).format("DD-MM-YYYY HH:MM")}
              </td>
              <td>
                <Badge variant="success" style={{ padding: 5 }}>
                  {data.status}
                </Badge>
              </td>
              <td>{data.displayName}</td>
              <td>
                {data.fromAPI ? (
                  <Badge
                    pill
                    bg="danger"
                    style={{
                      backgroundColor: "#f38120",
                      color: "#FFF",
                      padding: 10,
                    }}
                  >
                    SugarCRM
                  </Badge>
                ) : (
                  "Booking form"
                )}
              </td>
              {data.extraDetails?.sugarCrmLeadData?.assigned_user_name.length >
              0 ? (
                <td>
                  {data.extraDetails?.sugarCrmLeadData?.assigned_user_name}
                </td>
              ) : null}
              <td>
                <a
                  href={data.extraDetails?.externalCrmLink}
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                    textDecorationColor: "blue",
                  }}
                >
                  Open
                </a>
              </td>
            </tbody>
          </Table>
        </Card>
      </Container>
      {/* END BOOKING SECTION */}
      {/* START Eclipse Actions Section */}
      <Container>
        {pendingQueuedActions.length > 0 ? (
          <Alert variant="warning">
            <br />
            {`${pendingQueuedActions.length} queued action(s) waiting for Eclipse to process`}
          </Alert>
        ) : null}
      </Container>
      {/* END Eclipse Actions Section */}
      {/* START ACTION BUTTONS SECTION */}
      <Container style={{ marginBottom: 10 }}>
        <ButtonGroup size="sm">
          <a
            href={`mailto:${mailToString}`}
            target="_blank"
            className="btn btn-outline-primary"
          >
            Open template
          </a>
          <Dropdown as={ButtonGroup}>
            <Button size="sm" variant="outline-success">
              Change status
            </Button>

            <Dropdown.Toggle
              split
              variant="success"
              id="dropdown-split-basic"
            />
            <Dropdown.Menu>
              {statusList.map((s, idx) => {
                if (data.status === s.name) {
                  return (
                    <Dropdown.Item
                      disabled={true}
                      onClick={() => {
                        setStage(s.name);
                      }}
                    >
                      {s.title}
                    </Dropdown.Item>
                  );
                } else {
                  return (
                    <Dropdown.Item
                      onClick={() => {
                        setStage(s.name);
                      }}
                    >
                      {s.title}
                    </Dropdown.Item>
                  );
                }
              })}
            </Dropdown.Menu>
          </Dropdown>
          <Button
            onClick={handleShowCarrierInfoModal}
            variant="outline-primary"
          >
            {data?.carrierData?.carrier ? "Update" : "Add"} carrier info
          </Button>
          {/* If DialATow is selected, then show API trigger button */}
          {data?.carrierData?.carrier?.carrier === "Dial a Tow" ? (
            <Button
              onClick={sendAPITriggerToDialATow}
              variant="outline-primary"
              disabled={isSubmittingToFreightApi}
            >
              Send to {data?.carrierData?.carrier?.carrier}
              {isSubmittingToFreightApi ? <Spinner type="grow" /> : null}
            </Button>
          ) : null}

          {/* <Button onClick={setStage} variant="warning">
                    Cancel
                  </Button> */}
          <Button
            onClick={() => setShowHistoryModal(true)}
            variant="outline-primary"
          >
            <FaHistory />
          </Button>
          <Button
            onClick={() => setShowSendCostToEclipseModal(true)}
            variant="outline-primary"
          >
            Send cost to Eclipse
          </Button>
          <Button
            onClick={() => setShowYardLocationModal(true)}
            variant="outline-primary"
          >
            Change yard location
          </Button>
          <Button
            onClick={() => setCheckBoxActionsModal(true)}
            variant="outline-primary"
          >
            Additional actions
          </Button>
        </ButtonGroup>
      </Container>
      {/* END ACTION BUTTONS SECTION */}
      <Container
        style={{
          backgroundColor: "#fefefe",
          border: "1px solid rgba(0,0,0,.125)",
          fontSize: 12,
          borderRadius: 5,
        }}
      >
        <Row>
          <Col xs="6" md="6" lg="6">
            {/* START DELIVERY SECTION */}
            <Row style={sectionHeaderStyle}>Delivery</Row>
            <Row style={{ margin: 10 }}>
              <Table
                striped
                bordered
                hover
                size="sm"
                style={{
                  whiteSpace: "wrap",
                  textOverflow: "ellipsis",
                  // wordWrap: "break-all",
                  wordBreak: "break-all",
                  overflow: "auto",
                  // display: "flex",
                  width: "100%",
                  // tableLayout: "auto",
                }}
              >
                <thead>
                  {/* <tr>
                      <th>Property</th>
                      <th>Value</th>
                    </tr> */}
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <b>PICKUP</b>&nbsp; &nbsp;
                      <Button
                        onClick={() => {
                          setContactUpdateType("Pickup");
                          setShowChooseContactModal(true);
                        }}
                        // disabled
                        variant="outline-dark"
                      >
                        <BsPencil />
                      </Button>
                    </td>
                  </tr>

                  <tr>
                    <td>Address</td>
                    <td>
                      <a
                        target="_blank"
                        href={`https://www.google.com/maps/place/${data.pickup?.location.address}/@${data.pickup?.location?.coordinates?.lat},${data.pickup?.location?.coordinates?.lng},15z/`}
                      >
                        {data.pickup?.location.address}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Client</td>
                    <td>{data.pickup?.clientObj?.name}</td>
                  </tr>
                  <tr>
                    <td>Contact</td>
                    <td>
                      {data.pickup?.name} -&nbsp;
                      <a href={`tel:${data.pickup?.mobile}`}>
                        {data.pickup?.mobile}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Comments</td>
                    <td>{data.pickup?.comments}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <b>DROP OFF</b>&nbsp; &nbsp;
                      <Button
                        onClick={() => {
                          setContactUpdateType("Dropoff");
                          setShowChooseContactModal(true);
                        }}
                        // disabled
                        variant="outline-dark"
                      >
                        <BsPencil />
                      </Button>
                    </td>
                  </tr>

                  <tr>
                    <td>Address</td>
                    <td>
                      <a
                        target="_blank"
                        href={`https://www.google.com/maps/place/${data.dropOff?.location.address}/@${data.dropOff?.location?.coordinates?.lat},${data.dropOff?.location?.coordinates?.lng},15z/`}
                      >
                        {data.dropOff?.location.address}
                      </a>
                    </td>
                  </tr>
                  {data.dropOff?.clientObj?.label?.length > 0 ? (
                    <tr>
                      <td>Dealership</td>
                      <td>{data.dropOff?.clientObj?.label}</td>
                    </tr>
                  ) : null}
                  <tr>
                    <td>Contact</td>
                    <td>
                      {data.dropOff?.name} -&nbsp;
                      <a href={`tel:${data.dropOff?.mobile}`}>
                        {data.dropOff?.mobile}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Comments</td>
                    <td>
                      {data.dropOff?.comments?.length > 0
                        ? data.dropOff?.comments
                        : data.extraDetails?.collectionNotes}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
            {/* END DELIVERY SECTION */}

            {/* START CARRIER SECTION */}
            <Row style={sectionHeaderStyle}>Carrier</Row>
            <Row style={{ margin: 10 }}>
              <Table
                striped
                bordered
                hover
                size="sm"
                style={{
                  whiteSpace: "wrap",
                  textOverflow: "ellipsis",
                  // wordWrap: "break-all",
                  wordBreak: "break-all",
                  overflow: "auto",
                  // display: "flex",
                  width: "100%",
                  // tableLayout: "auto",
                }}
              >
                <thead>
                  {/* <tr>
                      <th>Property</th>
                      <th>Value</th>
                    </tr> */}
                </thead>
                <tbody>
                  {data.carrierData ? (
                    <>
                      <tr>
                        <td>Carrier</td>
                        <td>{data.carrierData?.carrier?.carrier}</td>
                      </tr>
                      <tr>
                        <td>Estimated Cost (inc GST)</td>
                        <td>
                          <NumberFormat
                            displayType="text"
                            thousandSeparator={true}
                            prefix={`$ `}
                            value={data.carrierData?.estimatedCost}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Estimated Collection Date</td>
                        <td>{data.carrierData?.estimatedCollectionDate}</td>
                      </tr>
                      <tr>
                        <td>Estimated Delivery Date</td>
                        <td>{data.carrierData?.estimatedDeliveryDate}</td>
                      </tr>
                      <tr>
                        <td>Actual Delivered Date</td>
                        <td>{data.carrierData?.actualDeliveredDate}</td>
                      </tr>
                      <tr>
                        <td>Carrier Booking Reference</td>
                        <td>{data.carrierData?.carrierBookingReference}</td>
                      </tr>
                      <tr>
                        <td>Carrier comments</td>
                        <td>{data.carrierData?.comments}</td>
                      </tr>
                      {data?.sentDataToFreightApi ? (
                        <tr>
                          <td>Pushed to Freight API?</td>
                          <td>{data?.sentDataToFreightApi ? "YES" : "NO"}</td>
                        </tr>
                      ) : null}
                    </>
                  ) : (
                    <tr>
                      <td>No carrier selected</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Row>
            {/* END CARRIER SECTION */}
          </Col>

          <Col xs="6" md="6" lg="6">
            {/* START DISTANCE INFO */}
            {(_.isEmpty(data.pickup?.location.coordinates) === false) &
            (_.isEmpty(data.dropOff?.location.coordinates) === false) ? (
              <>
                <Row style={sectionHeaderStyle}>Directions</Row>
                <GoogleMapComponent
                  origin={data.pickup?.location.coordinates}
                  destination={data.dropOff?.location.coordinates}
                  center={data.pickup?.location.coordinates}
                  updateDirectionsHO={setDirectionInformation}
                />
              </>
            ) : null}
            {/* END DISTANCE INFO */}
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12">
            {/* START VEHICLES SECTION */}
            <Row style={sectionHeaderStyle}>Vehicles</Row>
            <Row style={{ margin: 10 }}>
              <Table
                striped
                bordered
                hover
                size="sm"
                style={{
                  whiteSpace: "wrap",
                  textOverflow: "ellipsis",
                  // wordWrap: "break-all",
                  wordBreak: "break-all",
                  overflow: "auto",
                  // display: "flex",
                  width: "100%",
                  // tableLayout: "auto",
                }}
              >
                <thead>
                  {/* <tr>
                      <th>Property</th>
                      <th>Value</th>
                    </tr> */}
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <Badge
                        pill
                        bg="danger"
                        style={{
                          backgroundColor: "#6371de",
                          color: "#FFF",
                        }}
                      >
                        {data.vehicleItem.length} car
                        {data.vehicleItem.length > 1 ? "s" : ""} in booking
                      </Badge>
                    </td>
                  </tr>
                  {data.vehicleItem?.map((veh, idx) => {
                    return (
                      <>
                        <tr>
                          <td>Eclipse Stock Number</td>
                          <td>{veh.EclipseStockNumber}</td>
                        </tr>
                        <tr>
                          <td>
                            VIN&nbsp;
                            <Button
                              onClick={() => reloadVehicleInfo(idx, veh.VIN)}
                              variant="outline-primary"
                            >
                              <HiRefresh />
                            </Button>
                          </td>
                          <td>
                            {isEditingVin[idx] === true ? (
                              <>
                                <FormControl
                                  type="text"
                                  name={`${idx}-vin-edit`}
                                  value={localVIN[idx]}
                                  onChange={(e) =>
                                    editVinHandler(idx, e.target.value)
                                  }
                                />
                                <ButtonGroup>
                                  <Button
                                    onClick={(e) => {
                                      reloadVehicleInfo(idx, localVIN[idx]);
                                      setVinToReadOnly(idx);
                                    }}
                                    block
                                    variant="outline-info"
                                    style={{ marginTop: 5 }}
                                  >
                                    <FaRegSave />
                                  </Button>
                                  <Button
                                    variant="outline-danger"
                                    style={{ marginTop: 5 }}
                                    onClick={() => {
                                      setVinToReadOnly(idx);
                                    }}
                                  >
                                    <FaRegWindowClose />
                                  </Button>
                                </ButtonGroup>
                              </>
                            ) : (
                              <>
                                {veh.VIN} &nbsp; &nbsp;
                                <Button
                                  onClick={() => setVinToEdit(idx, veh.VIN)}
                                  variant="outline-info"
                                >
                                  <BsPencil />
                                </Button>
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Make</td>
                          <td>{veh.make}</td>
                        </tr>
                        <tr>
                          <td>Model</td>
                          <td>{veh.model}</td>
                        </tr>
                        <tr>
                          <td>Variant</td>
                          <td>{veh.variant}</td>
                        </tr>
                        <tr>
                          <td>Colour</td>
                          <td>{veh.colour}</td>
                        </tr>
                        <tr>
                          <td>Year</td>
                          <td>{veh.year}</td>
                        </tr>
                        <tr>
                          <td>Comments</td>
                          <td>{veh.comments}</td>
                        </tr>
                        <tr>
                          <td colspan={2}>
                            <Button
                              size="sm"
                              block
                              style={{ borderRadius: 0 }}
                              variant="outline-primary"
                              onClick={() => {
                                window.open(`/vin/${veh?.VIN}`, "_blank");
                              }}
                            >
                              Show current stock card
                            </Button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  <tr>
                    <td>Charge To</td>
                    <td>
                      {isEditingChargeTo ? (
                        <>
                          <FormControl
                            type="text"
                            value={localChargeTo}
                            onChange={(e) => setLocalChargeTo(e.target.value)}
                          />
                          <ButtonGroup>
                            <Button
                              onClick={async () => {
                                await updateChargeToField();
                                setIsEditingChargeTo(false);
                              }}
                              block
                              variant="outline-info"
                              style={{ marginTop: 5 }}
                            >
                              <FaRegSave />
                            </Button>
                            <Button
                              variant="outline-danger"
                              style={{ marginTop: 5 }}
                              onClick={() => {
                                setIsEditingChargeTo(false);
                              }}
                            >
                              <FaRegWindowClose />
                            </Button>
                          </ButtonGroup>
                        </>
                      ) : (
                        <>
                          {data.chargeTo?.name} &nbsp; &nbsp;
                          <Button
                            onClick={() => {
                              setLocalChargeTo(data.chargeTo?.name);
                              setIsEditingChargeTo(true);
                            }}
                            variant="outline-info"
                          >
                            <BsPencil />
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
            {/* END VEHICLES SECTION */}

            {/* START NOTES SECTION */}
            <Row style={sectionHeaderStyle}>Notes</Row>
            <Row style={{ margin: 10 }}>
              <Button
                size="sm"
                style={{ marginBottom: 10, marginTop: 10 }}
                variant="outline-success"
                onClick={() => setShowNoteCreateModal(true)}
              >
                <BiMessageRoundedAdd /> Add new note
              </Button>
            </Row>
            <Row style={{ margin: 10 }}>
              <Table
                striped
                bordered
                hover
                size="sm"
                style={{
                  whiteSpace: "wrap",
                  textOverflow: "ellipsis",
                  // wordWrap: "break-all",
                  wordBreak: "break-all",
                  overflow: "auto",
                  // display: "flex",
                  width: "100%",
                  // tableLayout: "auto",
                }}
              >
                <thead>
                  <tr>
                    <th>Added by</th>
                    <th>Note</th>
                    <th>When</th>
                  </tr>
                </thead>
                <tbody>
                  {notesCollection.length === 0 ? (
                    <tr>
                      <td colSpan={3}>No notes found</td>
                    </tr>
                  ) : null}
                  {notesCollection
                    ?.sort((a, b) => {
                      if (a.timeStamp > b.timeStamp) {
                        return -1;
                      } else if (a.timeStamp < b.timeStamp) {
                        return 1;
                      } else if (a.timeStamp === b.timeStamp) {
                        return 0;
                      }
                    })
                    .map((n, idx) => {
                      if (
                        idx + 1 <= activeNotesPage * notesPageSize &&
                        idx + 1 > (activeNotesPage - 1) * notesPageSize
                      )
                        return (
                          <tr key={idx}>
                            <td>{n.displayName || ""}</td>
                            <td>
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id={`notes-tooltip-${idx}`}>
                                    {n.note}
                                  </Tooltip>
                                }
                              >
                                <span
                                  style={{
                                    width: 200,
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {n.note || ""}
                                </span>
                              </OverlayTrigger>
                            </td>
                            <td>
                              {" "}
                              {dayjs
                                .unix(n.timeStamp)
                                .format("YYYY-MM-DD HH:mm")}
                            </td>
                          </tr>
                        );
                    })}
                </tbody>
              </Table>
            </Row>
            <Row className="justify-content-md-center">
              <Pagination>{paginationItemsForNotes()}</Pagination>
            </Row>

            {/* END NOTES SECTION */}

            {/* START LOG SECTION */}
            <Row style={sectionHeaderStyle}>Posting Log</Row>
            <Row style={{ margin: 10 }}>
              <Table
                striped
                bordered
                hover
                size="sm"
                style={{
                  whiteSpace: "wrap",
                  textOverflow: "ellipsis",
                  // wordWrap: "break-all",
                  wordBreak: "break-all",
                  overflow: "auto",
                  // display: "flex",
                  width: "100%",
                  // tableLayout: "auto",
                }}
              >
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Added to Eclipse</th>
                    <th>Posting Id</th>
                    <th>Created By</th>
                    <th>Cost</th>
                    <th>Yard location</th>
                    <th>Comments</th>
                    <th>Action Status</th>
                  </tr>
                </thead>
                <tbody>
                  {queueLog.length === 0 ? (
                    <tr>
                      <td colSpan={6}>No actions found</td>
                    </tr>
                  ) : null}
                  {queueLog.map((n, idx) => {
                    let bgc = null;
                    switch (n.actionStatus) {
                      case "Failed":
                        bgc = "#FF3D33";
                        break;

                      case "Processing":
                        bgc = "#FFD65C";
                        break;

                      default:
                        bgc = null;
                    }
                    return (
                      <tr key={idx} style={{ backgroundColor: bgc }}>
                        <td>{n.action}</td>
                        <td>
                          {dayjs
                            .unix(n?.actionedTimestamp?.seconds)
                            .format("YYYY-MM-DD HH:mm")}
                        </td>
                        <td>{n.eclipsePostingId}</td>
                        <td>{n.createdBy}</td>
                        <td>
                          <NumberFormat
                            thousandSeparator={true}
                            prefix={"$"}
                            displayType="text"
                            name={`carrierEstimateCost`}
                            placeholder="$"
                            value={n.carrierEstimateCost}
                          />
                        </td>
                        <td>{n.yardLocation}</td>
                        <td>{n.comments}</td>
                        <td>{n.actionStatus || "Not started"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* START CARRIER INFO MODAL */}
      <Modal
        id={`${idx}-modal`}
        show={showCarrierInfoModal}
        onHide={handleCloseCarrierInfoModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {data?.carrierData?.carrier ? "Update" : "Add"} carrier info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            key={`${idx}-form-carrier-update`}
            initialValues={carrierFormValues}
            validationSchema={validationSchemaCarrierInfo}
            validateOnChange={true}
            enableReinitialize={true}
            // validateOnBlur={true}
            onSubmit={(values, { resetForm }) => {
              let payload = {
                lastUpdatedBy: user.data.uid,
                lastUpdatedDate: serverTimestamp(),
                // lastUpdatedDate:
                //   firebaseconst.firestore.FieldValue.serverTimestamp(),
                carrierData: values,
              };

              // values.active = true;
              firestore
                .collection("freight-bookings")
                .doc(data.id)
                .set(payload, { merge: true })
                .then((r) => {
                  resetForm();
                  handleCloseCarrierInfoModal();
                  toast.success(`Saved`);
                });
            }}
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              isSubmitting,
              resetForm,
            }) => (
              <Form>
                <CarrierSelectListComponent
                  name="carrier"
                  fieldname="carrier"
                  label="Carrier"
                  // object="carrier"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <Form.Group controlId="estimatecostprice">
                  <Form.Label>Estimated cost (inc GST)</Form.Label>
                  <NumberFormat
                    thousandSeparator={true}
                    prefix={"$"}
                    customInput={Form.Control}
                    type="text"
                    name={`estimatedCost`}
                    placeholder="$"
                    onValueChange={(v) => {
                      values.estimatedCost = v.floatValue;
                    }}
                    onBlur={handleBlur}
                    value={values.estimatedCost}
                  />
                </Form.Group>
                <TextField
                  fieldname={`estimatedCollectionDate`}
                  label="Estimated collection date"
                  // placeholder="510123"
                  type="date"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <TextField
                  fieldname={`estimatedDeliveryDate`}
                  label="Estimated delivery date"
                  // placeholder="510123"
                  type="date"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <TextField
                  fieldname={`actualDeliveredDate`}
                  label="Actual delivered date"
                  // placeholder="510123"
                  type="date"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <TextField
                  fieldname={`carrierBookingReference`}
                  label="Carrier booking ref"
                  type="text"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <TextField
                  fieldname={`comments`}
                  label="Carrier comments"
                  type="text"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <ButtonGroup block>
                  <Button variant="outline-success" onClick={handleSubmit}>
                    {data?.carrierData?.carrier ? "Update" : "Add"} carrier to
                    booking {isSubmitting ? <Spinner animation="grow" /> : null}
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCarrierInfoModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* END  CARRIER INFO MODAL  */}
      {/* START CHOOSE VEHICLE MODAL */}
      <Modal
        show={showChooseVehicleModal}
        onHide={() => setShowChooseVehicleModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Choose vehicle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          More than 1 vehicle was found in the system for the VIN you searched
          for. Please choose the correct vehicle by Stock ID
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <td>Stock ID</td>
                <td>Branch</td>
                <td>Status</td>
                <td>Choose</td>
              </tr>
            </thead>
            <tbody>
              {localVehiclesFromSearch.map((v) => {
                return (
                  <tr>
                    <td>{v.VEHStockNumber}</td>
                    <td>{v.branch_desc}</td>
                    <td>{v.Status}</td>
                    <td>
                      <Button
                        variant="outline-dark"
                        onClick={() => {
                          setHasSelected(true);
                          updateVehicle(localVehiclesIndexToUpdate, v);
                        }}
                      >
                        <BsCheck />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      {/* END CHOOSE VEHICLE MODAL */}
      {/* START UPDATE PICKUP/DROPOFF MODAL */}
      <Modal
        show={showChooseContactModal}
        onHide={() => setShowChooseContactModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update {contactUpdateType} Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={
              contactUpdateType === "Pickup" ? data.pickup : data.dropOff
            }
            // validationSchema={validationSchema}
            // validateOnChange={true}
            // validateOnBlur={true}
            // innerRef={formRef}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              toast.info("Submitting, please wait");
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);
              let objectName = "";
              switch (contactUpdateType) {
                case "Pickup":
                  objectName = "pickup";
                  break;

                case "Dropoff":
                  objectName = "dropOff";
                  break;

                default:
                  toast.warning("Error, not saving");
                  return;
                  break;
              }
              // Simulate submitting to database, shows us values submitted, resets form
              // alert(JSON.stringify(values, null, 2));
              // Add user details to value
              let changeLog = {};
              changeLog.user = user.data.uid;
              changeLog.displayName = user.data.displayName;
              changeLog.userPhone = user.data.phoneNumber;
              changeLog.email = user.data.email;
              changeLog.type = objectName;
              changeLog.updatedDate = serverTimestamp();
              // changeLog.updatedDate =
              //   firebaseconst.firestore.FieldValue.serverTimestamp();
              changeLog.status = data.status;
              // values.directions = directions?.routes[0]?.legs.map((l) => {
              //   return {
              //     start_address: l.start_address,
              //     end_address: l.end_address,
              //     duration: l.duration.text,
              //     distance: l.distance.text,
              //     distanceKm: l.distance.value,
              //     durationSeconds: l.duration.value,
              //   };
              // });
              // console.log(changeLog);
              // console.log(values);

              // react_devtools_backend.js:2850 Warning: An unhandled error was caught from submitForm() TypeError: data is not iterable
              const payload = { ...data };
              const oldData = { ...data };
              payload[objectName] = values;

              // Update document
              await firestore
                .collection("freight-bookings")
                .doc(data.id)
                .set(payload, { merge: true })
                .then(async (r) => {
                  toast.warning("Updated");
                })
                .catch((e) => {
                  toast.error(`Failed to save: ${e.message}`);
                  // toast.error(e.message);
                  console.log(e);
                });

              // Add audit log to document
              const auditLogPayload = {
                new: payload[objectName],
                old: oldData[objectName],
                ...changeLog,
              };
              await firestore
                .collection("freight-bookings")
                .doc(data.id)
                .collection("address-change-audit-log")
                .add(auditLogPayload)
                .catch((e) => {
                  toast.error(`Failed to save: ${e.message}`);
                  // toast.error(e.message);
                  console.log(e);
                });
              resetForm();
              setShowChooseContactModal(false);
              setSubmitting(false);
            }}
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleReset,
              isSubmitting,
              resetForm,
            }) => (
              <Form onSubmit={handleSubmit} className="mx-auto" disabled>
                {/* <pre>
                  <code></code> {JSON.stringify(values, null, 2)}
                </pre> */}
                <Card style={{ marginBottom: "3rem" }}>
                  <Card.Body>
                    <h4>
                      {values.pickupType === "Customer"
                        ? "Customer Pickup "
                        : "Dealer Pickup "}
                      Information
                    </h4>
                    <h4>Client type</h4>
                    <Card>
                      <Card.Body>
                        <ClientSelectListComponent
                          name="clientObj"
                          fieldname="clientObj"
                          label="Pickup client"
                          object={
                            contactUpdateType === "Pickup"
                              ? "pickup"
                              : "dropOff"
                          }
                          placeholder=""
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                        {values.clientObj?.name?.length > 0 ? (
                          <Form.Control
                            // name="location"
                            value={values.clientObj?.name}
                            disabled
                            // component={FormikAutoCompleteComponent}
                          />
                        ) : null}
                        <br />

                        {/* <p>
                  If internal, have Select of internal defsalesclass === O,
                  Select value is CLISurname (Typeahead) Then populate address,
                  hide Full name, get input from mobile
                </p> */}
                        {values.clientObj?.name?.length > 0 ? (
                          <Form.Control
                            // name="location"
                            value={values.location.address}
                            disabled
                            // component={FormikAutoCompleteComponent}
                          />
                        ) : (
                          <Field
                            name="location"
                            component={FormikAutoCompleteComponent}
                          />
                        )}
                      </Card.Body>
                      {/* <Card.Footer>
                        <Button
                          onClick={() => {
                            // Clear address object only, leave contact details the same
                            // So that a manual address can be entered
                            // setFieldValue("location.value", "");
                            const obj =
                              contactUpdateType === "Pickup"
                                ? "pickup"
                                : "dropOff";
                            console.log(data[obj]);
                            data[obj].location = {};
                            // setFieldValue("location", {});
                          }}
                          variant="outline-danger"
                        >
                          x Clear address
                        </Button>
                      </Card.Footer> */}
                    </Card>
                    <br />

                    <TextField
                      fieldname="name"
                      label="Contact name"
                      placeholder="John Smith"
                      type="text"
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    {/* <TextField
                  fieldname="pickup.email"
                  label="Email address"
                  placeholder="customer@email.com"
                  type="email"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                /> */}
                    <TextField
                      fieldname="mobile"
                      label="Mobile"
                      placeholder="0412 123 456"
                      type="tel"
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />

                    <TextField
                      fieldname="comments"
                      label="Comments"
                      // placeholder="0412 123 456"
                      type="text"
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </Card.Body>
                </Card>
                <ButtonGroup>
                  <Button
                    disabled={isSubmitting}
                    variant="primary"
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                  <Button
                    onClick={() => setShowChooseContactModal(false)}
                    variant="outline-secondary"
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
              variant="secondary"
              onClick={() => setShowChooseVehicleModal(false)}
            >
              Close
            </Button> */}
          {/* <Button
            disabled={!hasSelected}
            variant="primary"
            onClick={closeShowVehicleModalOnConfirm}
          >
            Confirm
          </Button> */}
        </Modal.Footer>
      </Modal>
      {/* END UPDATE PICKUP/DROPOFF MODAL */}
      {/* START NOTE CREATE MODAL  */}
      <Modal
        show={showNoteCreteModal}
        onHide={() => setShowNoteCreateModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add new note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            key={`${idx}-form-note-create`}
            initialValues={{
              note: "",
              who: user.data.displayName,
              whoId: user.data.uid,
              time: dayjs().format("HH:mm:ss DD-MM-YYYY"),
            }}
            // validationSchema={validationSchemaCarrierInfo}
            // validateOnChange={true}
            enableReinitialize={true}
            // validateOnBlur={true}
            onSubmit={async (values, { resetForm }) => {
              let payload = {
                who: user.data.uid,
                displayName: user.data.displayName,
                timeStamp: dayjs().unix(),
                note: values.note,
              };
              const dTmp = { ...data };
              if (dTmp.notes?.length > 0) {
                dTmp.notes.push(payload);
              } else {
                dTmp.notes = [payload];
              }
              console.log(dTmp);
              // toast.info("pre end");
              // return;

              // values.active = true;
              await firestore
                .collection("freight-bookings")
                .doc(data.id)
                .collection("notes")
                .add(payload)
                // .set(dTmp, { merge: true })
                .then((r) => {
                  console.log("saved");
                  resetForm();
                  setShowNoteCreateModal(false);
                  toast.success(`Note added`);
                })
                .catch((e) => {
                  console.log(e);
                  toast.error(e.message);
                });
              console.log("done");
            }}
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              isSubmitting,
              resetForm,
            }) => (
              <Form>
                <Form.Group controlId={`form-notes`}>
                  <Form.Label>Note</Form.Label>
                  <textarea
                    className="form-control"
                    /* This name property is used to access the value of the form element via values.nameOfElement */
                    name="note"
                    // placeholder={props.placeholder}
                    /* Set onChange to handleChange */
                    onChange={handleChange}
                    /* Set onBlur to handleBlur */
                    onBlur={handleBlur}
                    /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                    value={values.note}
                    /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
                  />

                  {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
                  <ErrorMessage name="note">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </Form.Group>
                {/* <TextField
                  fieldname={`note`}
                  label="Note"
                  // placeholder="510123"
                  type="textarea"
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                /> */}

                <ButtonGroup block>
                  <Button variant="outline-success" onClick={handleSubmit}>
                    Save note
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* END NOTES MODAL  */}

      {/* START HISTORY MODAL  */}
      <Modal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <i>Address change history coming soon...</i>
        </Modal.Body>
      </Modal>
      {/* END HISTORY MODAL  */}

      {/* START COST MODAL  */}
      <Modal
        show={showSendCostToEclipseModal}
        onHide={() => setShowSendCostToEclipseModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send cost to Eclipse - Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="info">
            <i>Please review before proceeding</i>
          </Alert>
          <Table striped size="sm">
            <tr>
              <td>Stock #</td>
              <td>{data.vehicleItem[0].EclipseStockNumber}</td>
            </tr>
            <tr>
              <td>VIN</td>
              <td>{data.vehicleItem[0].VIN}</td>
            </tr>
            <tr>
              <td>Make / Model</td>
              <td>
                {data.vehicleItem[0].make} {data.vehicleItem[0].model}
              </td>
            </tr>
            <tr>
              <td>Branch</td>
              <td>{data.vehicleItem[0]?.branch}</td>
            </tr>
            <tr>
              <td>Eclipse database</td>
              <td>
                {getBranchObjFromBranches(data.vehicleItem[0]?.branch).db}
              </td>
            </tr>
            <tr>
              <td>Carrier</td>
              <td>{data.carrierData?.carrier?.carrier}</td>
            </tr>
            <tr>
              <td>Carrier Id</td>
              <td
                style={{
                  backgroundColor:
                    printCarrierId() === "" ||
                    printCarrierId() === 0 ||
                    typeof printCarrierId() === "undefined"
                      ? "red"
                      : null,
                }}
              >
                {printCarrierId()}
              </td>
            </tr>
            <tr>
              <td>Estiamted carrier cost</td>
              <td>
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  prefix={`$ `}
                  value={data.carrierData?.estimatedCost}
                />
              </td>
            </tr>

            <tr>
              <td>Estimated Collection Date</td>
              <td>{data.carrierData?.estimatedCollectionDate}</td>
            </tr>
            <tr>
              <td>Estimated Delivery Date</td>
              <td>{data.carrierData?.estimatedDeliveryDate}</td>
            </tr>
            <tr>
              <td>Actual Delivered Date</td>
              <td>{data.carrierData?.actualDeliveredDate}</td>
            </tr>
            <tr>
              <td>Carrier Booking Reference</td>
              <td
                style={{
                  backgroundColor:
                    data.carrierData?.carrierBookingReference === "" ||
                    typeof data.carrierData?.carrierBookingReference ===
                      "undefined"
                      ? "red"
                      : null,
                }}
              >
                {data.carrierData?.carrierBookingReference}
              </td>
            </tr>
            <tr>
              <td>Carrier comments</td>
              <td>{data.carrierData?.comments}</td>
            </tr>
          </Table>
          <ButtonGroup style={{ float: "right" }}>
            <Button
              disabled={isLoadingCostUpdate}
              onClick={() => addCostToEclipse()}
            >
              Push to Eclipse&nbsp;
              {isLoadingCostUpdate ? (
                <Spinner size="sm" animation="border" />
              ) : null}
            </Button>
            <Button
              variant="outline-danger"
              onClick={() => {
                setShowSendCostToEclipseModal(false);
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Modal.Body>
      </Modal>
      {/* END COST MODAL  */}

      {/* START YARD LOCATION MODAL  */}
      <Modal
        show={showYardLocationModal}
        onHide={() => setShowYardLocationModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update yard location in Eclipse</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="info">
            <i>Please review before proceeding</i>
          </Alert>
          <Table size="sm">
            <tr>
              <td>Yard location</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-yard-location"
                  >
                    {yardLocation.length === 0
                      ? "Choose yard location"
                      : yardLocation}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        setYardLocation("FRTBooked");
                      }}
                    >
                      FRTBooked
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => {
                        setYardLocation("FreightIn");
                      }}
                    >
                      FreightIn
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setYardLocation("FreightOut");
                      }}
                    >
                      FreightOut
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setYardLocation("DLRDirect");
                      }}
                    >
                      DLRDirect
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            <tr>
              <td>Comments</td>
              <td>
                <Form.Group controlId={`yard-location-comments-update`}>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      /* This name property is used to access the value of the form element via values.nameOfElement */
                      name="yard-loc-comments"
                      placeholder=""
                      /* Set onChange to handleChange */
                      onChange={(e) => {
                        setYardLocationComments(e.target.value);
                      }}
                      /* Set onBlur to handleBlur */
                      // onBlur={props.handleBlur}
                      /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                      value={yardLocationComments}
                    />
                    <ButtonGroup>
                      <Button
                        variant="outline-warning"
                        onClick={reloadYardLocationComments}
                      >
                        <FaRedo />
                      </Button>

                      <Button
                        variant="outline-danger"
                        onClick={() => setYardLocationComments("")}
                      >
                        <MdClear />
                      </Button>
                    </ButtonGroup>
                  </InputGroup>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td>Branch</td>
              <td>{data.vehicleItem[0]?.branch}</td>
            </tr>
            <tr>
              <td>Database</td>
              <td>
                {getBranchObjFromBranches(data.vehicleItem[0]?.branch).db}
              </td>
            </tr>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup style={{ float: "right" }}>
            <Button
              disabled={isLoadingYardLocationUpdate}
              onClick={() => pushYardLocationToEclipse()}
            >
              Push to Eclipse&nbsp;
              {isLoadingYardLocationUpdate ? (
                <Spinner size="sm" animation="border" />
              ) : null}
            </Button>
            <Button
              variant="outline-danger"
              onClick={() => {
                setShowYardLocationModal(false);
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
      {/* END YARD LOCATION  MODAL  */}

      {/* START CHECKBOX / ADDITIONAL ACTIONS MODAL  */}
      <Modal
        show={showCheckBoxActionsModal}
        onHide={() => setShowCheckBoxActionsModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Additional Actions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Set collected in Sugar CRM</Form.Label>
            <Form.Row>
              <Switch
                checked={data?.extraDetails?.collection_done || false}
                disabled={
                  data?.extraDetails?.sugarCrmId?.length > 0 ||
                  data?.status !== "Finalised"
                    ? false
                    : true
                }
                onChange={() => {
                  setToggleCollectedInSugar(!toggleCollectedInSugar);
                  updateLeadWithFreightCollectedInSugar(
                    !toggleCollectedInSugar
                  );
                }}
              />
            </Form.Row>
          </Form.Group>
        </Modal.Body>
      </Modal>
      {/* END CHECKBOX ACTIONS MODAL  */}
    </>
  );
};

export default BookingDesktopView;
