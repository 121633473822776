import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import useTransformToRechartsFormat from "../../../../hooks/useTransformToRechartsFormat";

const BarChartDash = ({ os_graph_data, chartInfo }) => {
  const chartData = useTransformToRechartsFormat(os_graph_data, chartInfo);

  return (
    // <ResponsiveContainer width={500} height={400}>
    <BarChart
      width={500}
      height={300}
      data={chartData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 15,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={"name"} />
      <YAxis />
      <Tooltip />
      <Legend verticalAlign="top" align="right" />
      {chartInfo.xaxis.series.map((s, idx) => {
        return (
          <Bar dataKey={s.name} fill={s.colour} key={idx}>
            <LabelList dataKey={s.name} position="top" />
          </Bar>
        );
      })}
    </BarChart>
    // </ResponsiveContainer>
  );
};

export default BarChartDash;
